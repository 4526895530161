import { Button, LayoutContainer, Logo, Paragraph } from 'common-components'
import { useNavigate } from 'react-router'
import { useTranslation } from '@hooks'
import { PageLayout } from '@components/layout'

export const ErrorPage = () => {
  const navigate = useNavigate()
  const { translate } = useTranslation()

  return (
    <PageLayout>
      <LayoutContainer
        style={{
          minHeight: '100vh',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Logo preview={false} width={100} style={{ marginBottom: 20 }} />

        <Paragraph preset={'pageHeader'}>{translate('notFoundPage')}</Paragraph>

        <Button type={'primary'} style={{ width: 'fit-content' }} onClick={() => navigate(-1)}>
          {translate('common.buttons.goBack')}
        </Button>
      </LayoutContainer>
    </PageLayout>
  )
}
