import { GetProjectsPayloadType } from '@api'
import { useDelayedEffect, useRequestParams } from '@hooks'
import { useEffect } from 'react'
import { ProjectType } from '@pages/types'

import { ListProject } from '@/views/project'
import { store } from '@/store'

const ProjectsPage = () => {
  const projects = store.use.projects()

  const getProjectsAction = store.use.getProjectsAction().call
  const isLoadingGetProjectsAction = store.use.getProjectsAction().status === 'LOADING'

  const { afterPageChange, setParams, reqParams, pagination, onTableStateChange } =
    useRequestParams<GetProjectsPayloadType, ProjectType>()

  useDelayedEffect(() => {
    getProjectsAction(reqParams)
  }, [JSON.stringify(reqParams)])

  useEffect(() => {
    afterPageChange(projects.length)
  }, [projects.length])

  return (
    <ListProject
      isLoading={isLoadingGetProjectsAction}
      projects={projects}
      onFiltersChange={setParams}
      tableProps={{ pagination, onChange: onTableStateChange }}
    />
  )
}

export default ProjectsPage
