const en = {
  addSegment: 'Add a segment',
  createContributorSegment: 'Create a contributor segment',
  icon: 'Icon',
  name: 'Name',
  exampleEnglishLevel1: 'Example: English Level 1',
  socio: 'Socio',
  manual: 'Manual',
  competence: 'Competence',
  defineSelectionCriteria: 'You define socio-demographic selection criteria',
  manualContributorSelection: 'You manually select contributors who have certain skills',
  specificCompetenceSelection: 'You select the contributor based on a specific competence.',
  back: 'Back',
  next: 'Next',
  outOf: 'out of',
  users: 'Users',
  import: 'Import',
  addUsers: 'Add users',
  search: 'Search',
  add: 'Add',
  creator: 'Creator',
  audience: 'Audience',
  listSizeVaries:
    'The size of the list can vary over time depending on your settings and available data',
  validate: 'Validate',
  addAll: 'Add all',
  chooseManagers: 'Choose managers',
  ownerAndAdministrators: 'Owner & Administrators',
  usagePermissions: 'Usage permissions',
  openToAll: 'Open to all',
  finish: 'Finish',
  groupMarseille: 'Group Marseille',
  socioDemographic: 'Socio-demographic',
  parameter: 'Parameter',
  country: 'Country',
  criterion: 'Criterion',
  usersMustRespect: 'Users must respect at least one of the chosen criteria.',
  gender: 'Gender',
  man: 'Man',
  age: 'Age',
  seniority: 'Seniority',
  skillCriterion: 'Skill criterion',
  searchSkill: 'Search for a skill',
  usersAreEvaluated: 'Users are evaluated on all of the following skills',
  entry: 'Entry',
  cutout: 'Cutout',
  minimumNumberOfTreatmentsEvaluated: 'Minimum number of treatments evaluated',
  minimumQuality: 'Minimum quality',
}

export default en
export type Translations = typeof en
