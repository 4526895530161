import { DELAY_DURATION_NOTIFICATION } from '@utils'
import { v4 as uuidv4 } from 'uuid'

import { SetState } from '../store.type'

import { NotificationSliceType } from './notification.slice'

/****************************************************************
 * ADD ERROR NOTIFICATION
 *****************************************************************/

type AddErrorNotificationActionStatusType = 'addErrorNotification/succeeded'
export type AddErrorNotificationActionPayloadType = {
  description: string
}

export const addErrorNotificationAction =
  (set: SetState<Partial<NotificationSliceType>, AddErrorNotificationActionStatusType>) =>
  (payload?: AddErrorNotificationActionPayloadType) => {
    set(
      (state) => {
        state.error = {
          id: uuidv4(),
          messageTranslate: 'notifications.error',
          description: payload?.description,
          duration: DELAY_DURATION_NOTIFICATION,
        }
      },
      false,
      { type: 'addErrorNotification/succeeded' },
    )
  }

/****************************************************************
 * DELETE ERROR NOTIFICATION
 *****************************************************************/

type DeleteErrorNotificationActionStatusType = 'deleteErrorNotification/succeeded'

export const deleteErrorNotificationAction =
  (set: SetState<Partial<NotificationSliceType>, DeleteErrorNotificationActionStatusType>) =>
  () => {
    set(
      (state) => {
        state.error = undefined
      },
      false,
      { type: 'deleteErrorNotification/succeeded' },
    )
  }

/****************************************************************
 * DELETE SUCCESS NOTIFICATION
 *****************************************************************/

type DeleteSuccessNotificationActionStatusType = 'deleteSuccessNotification/succeeded'

export const deleteSuccessNotificationAction =
  (set: SetState<Partial<NotificationSliceType>, DeleteSuccessNotificationActionStatusType>) =>
  () => {
    set(
      (state) => {
        state.success = undefined
      },
      false,
      { type: 'deleteSuccessNotification/succeeded' },
    )
  }
