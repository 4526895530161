/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Content, Pills, Row } from 'common-components'
import { useState } from 'react'
import { useTranslation } from '@hooks'
import { ContributorType } from '@pages/types'

import HeaderContributor from './HeaderContributor'
import { MetricsContributor } from './metrics'
import { InformationContributor } from './information'
import { SegmentsContributor } from './segments'
import { CertificationsContributor } from './certifications'
import { ActivityContributor } from './activity'

export type ContributorPropsType = {
  contributor: ContributorType
}

type PillType = 'métriques' | 'informations' | 'segments' | 'certifications' | 'activité'

const Contributor = ({ contributor }: ContributorPropsType) => {
  const [pillValue, setPillValue] = useState<PillType>('informations')
  const { translate } = useTranslation()

  const pillsOption: { value: PillType; label: string }[] = [
    /* HIDE
		 * {
			value: "métriques",
			label: translate("contributor.metrics"),
		},*/
    {
      value: 'informations',
      label: translate('contributor.information'),
    },
    {
      value: 'segments',
      label: translate('contributor.segments'),
    },
    {
      value: 'certifications',
      label: translate('contributor.certifications'),
    },
    /* HIDE
		{
			value: "activité",
			label: translate("contributor.activity"),
		},*/
  ]

  return (
    <Content>
      <HeaderContributor userContributor={contributor.userData} />
      <Row
        css={css`
          margin: 24px 0;
          max-height: 44px;
        `}
      >
        <Pills
          options={pillsOption}
          onChange={(e) => {
            setPillValue(e.target.value)
          }}
          value={pillValue}
          buttonProps={'test' as any}
        />
      </Row>
      {pillValue === 'métriques' && <MetricsContributor />}
      {pillValue === 'informations' && (
        <InformationContributor userContributor={contributor.userData} />
      )}
      {pillValue === 'segments' && <SegmentsContributor contributor={contributor} />}
      {pillValue === 'certifications' && <CertificationsContributor contributor={contributor} />}
      {pillValue === 'activité' && <ActivityContributor />}
    </Content>
  )
}

export default Contributor
