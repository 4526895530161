/** @jsxImportSource @emotion/react */

import { ISelectInputProps, ISelectOptionProps, SelectInput, SelectOption } from 'common-components'
import { getManagers } from '@api'
import { ManagerType } from '@pages/types'
import { useEffect, useState } from 'react'

import { userDataApiMapper } from '@/mappers'

type SelectManagersPropsType = ISelectInputProps & {
  managers?: ManagerType['userData'][]
  optionProps?: Partial<ISelectOptionProps>
}
const SelectManagers = function ({ optionProps, ...rest }: SelectManagersPropsType) {
  const [userData, setUserData] = useState<ManagerType['userData'][]>([])

  useEffect(() => {
    getManagers({ model: {} }).then((usersApi) => {
      const userDataResult: ManagerType['userData'][] = usersApi?.map((userApi) => {
        return userDataApiMapper(userApi)
      })
      setUserData(userDataResult)
    })
  }, [])

  return (
    <SelectInput mode={'tags'} {...rest}>
      {userData.map((user, i: number) => (
        <SelectOption
          key={`${i}-${user.idUser}`}
          value={user.idUser}
          title={`${user.firstName} ${user.lastName}`}
          {...optionProps}
        >
          {user.firstName} {user.lastName}
        </SelectOption>
      ))}
    </SelectInput>
  )
}

export default SelectManagers
