/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import {
  Col,
  colors,
  DropInput,
  IColProps,
  IconButton,
  IDropInputProps,
  IModalProps,
  Modal,
  Row,
  useDisclosure,
} from 'common-components'
import styled from '@emotion/styled'
import { IColumn } from '@types'
import { useState } from 'react'
import { UploadChangeParam } from 'antd/es/upload/interface'

import CellTableInput from './CellTableInput'
import HeaderTableInput from './HeaderTableInput'

export interface IFieldCreateData {
  value?: string
  file?: any
  template: IColumn
  type?: 'field-update' | 'field-create' | 'column-upload'
}

export interface IFieldUpdateData<T> extends IFieldCreateData {
  field?: T
}

type EditingField = { rowIndex: number; colIndex: number }

type TableInputPropsType<T> = {
  templateColumns: IColumn[]
  data: T[]
  onUpdate?: (data: IFieldUpdateData<T>) => Promise<any>
  onCreate?: (data: IFieldCreateData) => Promise<any>
  keys: {
    columnKey: 'inputs' | 'columns'
    rowKey: 'idCertificationQuestion' | 'idTask'
  }
  uploadModalProps?: IModalProps
  dropInputProps?: IDropInputProps
  onColumnUpload?: (data: IFieldCreateData) => Promise<any>
  onRowDelete?: (id: string) => void
}

const StyledCell = styled(Col)`
  padding: 8px 12px;
  border-right: 1px solid ${colors.gray200};
  position: relative;
`

const Container = styled.div`
  border: 1px solid ${colors.gray200};
  border-radius: 10px;
`
const HeadersContainer = styled(Row)``
const TableInput = function <
  TData extends {
    [x: string]: any
  },
>({
  templateColumns = [],
  data = [],
  onUpdate,
  keys,
  onCreate,
  onColumnUpload,
  uploadModalProps,
  dropInputProps,
  onRowDelete,
}: TableInputPropsType<TData>) {
  const { open, isOpen, close } = useDisclosure()

  const [uploadFieldData, setUploadFieldData] = useState<IFieldUpdateData<TData> | null>(null)
  const [hoveredRow, setHoveredRow] = useState<number | null>(null)
  const [loading, setLoading] = useState(false)
  const [editingField, setEditingField] = useState<EditingField | null>(null)

  const onUpload = async (ev: UploadChangeParam) => {
    try {
      setLoading(true)
      setUploadFieldData({
        ...uploadFieldData,
        file: ev.fileList[0]?.originFileObj,
      })
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  const onFieldUpdate = (data: IFieldUpdateData<TData>) => onUpdate(data)

  const onFieldCreate = (data: IFieldCreateData) => onCreate(data)

  const onUploadConfirm = async (data: IFieldUpdateData<TData>) => {
    try {
      setLoading(true)
      if (data.type === 'field-create') {
        await onFieldCreate(data)
      }
      if (data.type === 'field-update') {
        await onFieldUpdate(data)
      }
      if (data.type === 'column-upload') {
        onColumnUpload && (await onColumnUpload(data))
      }
      onUploadClose()
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
      throw e
    } finally {
      setLoading(false)
    }
  }

  const onUploadClose = () => {
    setUploadFieldData(null)
    close()
  }

  // const deleteRow = (rowId: string) => {
  // 	onRowDelete && onRowDelete(rowId);
  // };
  const Cell = ({ span, ...rest }: IColProps) => {
    const evenSpan = Math.floor(23 / (templateColumns?.length || 1))
    return <StyledCell span={span || evenSpan} {...rest} />
  }

  return (
    <Container>
      {/* HEADERS */}
      <HeadersContainer>
        {/*empty col for indexes of rows*/}
        <Cell span={1} />
        {templateColumns.map((templateColumn) => (
          <HeaderTableInput
            CellComponent={(props) => (
              <Cell
                key={templateColumn.name}
                css={css`
                  padding-bottom: 12px;
                `}
                flex={1}
                {...props}
              />
            )}
            onUploadOpen={() => {
              setUploadFieldData({
                template: templateColumn,
                type: 'column-upload',
              })
              open()
            }}
            template={templateColumn}
          />
        ))}
      </HeadersContainer>

      {/* CREATE ROW */}
      <Row>
        {/* CELLS */}
        <Cell span={1} />
        {templateColumns.map((fieldTemplate, i) => {
          return (
            <CellTableInput
              template={fieldTemplate}
              placeholder={'Saisir un texte...'}
              onUploadOpen={() => {
                setUploadFieldData({
                  template: fieldTemplate,
                  type: 'field-create',
                })
                open()
              }}
              onInputDone={(value, shouldTriggerNext) => {
                onFieldCreate({ value, template: fieldTemplate })
                shouldTriggerNext && setEditingField({ rowIndex: data.length, colIndex: i + 1 })
              }}
              CellComponent={(props) => (
                <Cell flex={templateColumns.length - 1 < i ? 1 : 'auto'} {...props} />
              )}
            />
          )
        })}
      </Row>

      {data.map((item, rowIndex) => (
        // ROWS
        <Row
          key={`${rowIndex}-${item[keys.rowKey]}`}
          onMouseOver={() => setHoveredRow(rowIndex)}
          onMouseLeave={() => setHoveredRow(null)}
          style={{
            background: hoveredRow === rowIndex ? colors.gray100 : colors.gray25,
            transition: 'all 0.3s ease-in-out',
          }}
        >
          {/* CELLS */}
          <Cell span={1}>
            {hoveredRow === rowIndex ? (
              <IconButton
                type={'text'}
                icon={'Trash01'}
                iconProps={{ size: 8 }}
                size={'xs'}
                onClick={() => onRowDelete && onRowDelete(item[keys.rowKey])}
              />
            ) : (
              rowIndex + 1
            )}
          </Cell>
          {templateColumns.map((fieldTemplate, i) => {
            const col = item[keys.columnKey]?.find(
              // TODO fix this and remove any !!!
              (input: any) => fieldTemplate.name === input?.name,
            )
            return (
              <CellTableInput
                key={`${i}-${rowIndex}-${fieldTemplate.type}-${col?.name || ''}`}
                cellData={col}
                template={fieldTemplate}
                isEditing={editingField?.colIndex === i && editingField?.rowIndex === rowIndex}
                onUploadOpen={() => {
                  setUploadFieldData({
                    field: item,
                    template: fieldTemplate,
                    type: 'field-update',
                  })
                  open()
                }}
                onInputDone={(val, shouldTriggerNext) => {
                  onFieldUpdate({
                    value: val,
                    field: item,
                    template: fieldTemplate,
                  })
                  shouldTriggerNext && setEditingField({ rowIndex, colIndex: i + 1 })
                }}
                CellComponent={(props) => (
                  <Cell flex={templateColumns.length - 1 < i ? 1 : 'auto'} {...props} />
                )}
              />
            )
          })}
        </Row>
      ))}
      <Modal
        open={isOpen}
        onOk={() => onUploadConfirm(uploadFieldData)}
        onCancel={onUploadClose}
        okButtonProps={{
          disabled: !uploadFieldData?.file && !loading,
          loading,
        }}
        {...uploadModalProps}
      >
        <DropInput
          beforeUpload={() => false}
          maxCount={1}
          onUpload={onUpload}
          {...dropInputProps}
        />
      </Modal>
    </Container>
  )
}

export default TableInput
