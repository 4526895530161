import {
  Button,
  Divider,
  Icon,
  LayoutContainer,
  Sider,
  Steps,
  Title,
  Space,
} from 'common-components'
import { TxKeyPath } from '@i18n'
import { useNavigate } from 'react-router'
import { routes } from '@navigation/routes'
import { ProjectType } from '@pages/types'
import { PageLayout } from '@components/layout'
import { useTranslation } from '@hooks'

import { Step1CreateProject } from './step1'
import { Step2CreateProject } from './step2'
import { Step3CreateProject } from './step3'
import { Step4CreateProject } from './step4'

import { AudienceProvider } from '@/context'
import { store } from '@/store'

type CreateProjectPropsType = {
  currentStep: number
  project: ProjectType
  // onRefetchProject?: () => Promise<unknown>
}
const CreateProject = ({
  project,
  currentStep,
}: // onRefetchProject,
CreateProjectPropsType) => {
  const { translate } = useTranslation()
  const navigate = useNavigate()

  const updateNextStepProjectAction = store.use.updateNextStepProjectAction().call

  const isLoadingUpdateNextStepProjectAction =
    store.use.updateNextStepProjectAction().status === 'LOADING'

  const setCurrentStep = (step: number) =>
    navigate(routes.projectCreation.navigate(project.idProject, step))

  const redirectToProjectPage = (id: string) => navigate(routes.projectTasks.navigate(id))

  /* HIDE 
	 * const redirectToProjectPage = (id: string) =>
		navigate(routes.projectDashboard.navigate(id))
		*/

  const onNextStepMutation = () => {
    updateNextStepProjectAction({ idProject: project.idProject }, (nextStep) => {
      switch (nextStep) {
        case 1:
        case 2:
        case 3:
        case 4:
          setCurrentStep(nextStep)
          break
        default:
          redirectToProjectPage(project.idProject)
      }
    })
  }

  return (
    <LayoutContainer>
      <LayoutContainer style={{ padding: '10px 16px', justifyContent: 'space-between' }}>
        <Sider collapsedWidth={54} collapsed theme={'light'}>
          <Space align='center' style={{ height: '100%' }}>
            <Button size='sm' onClick={() => navigate(routes.projects.navigate())}>
              <Icon icon='FlipBackward' />
            </Button>
          </Space>
        </Sider>
        <Steps
          type='navigation'
          current={currentStep - 1}
          items={[
            {
              title: translate('projectCreatePage.titles.1'),
            },
            {
              title: translate('projectCreatePage.titles.2'),
            },
            {
              title: translate('projectCreatePage.titles.3'),
            },
            {
              title: translate('projectCreatePage.titles.4'),
            },
          ]}
          style={{ maxWidth: 792, width: '100%', alignSelf: 'center' }}
          size='small'
        />
        <div />
      </LayoutContainer>
      <Divider style={{ margin: '0 0 24px' }} />
      <LayoutContainer style={{ maxWidth: 1216, width: '100%', alignSelf: 'center' }}>
        <Title preset='singlePageTitle'>
          {translate(`projectCreatePage.titles.${currentStep}` as TxKeyPath)}
        </Title>
        <Title preset='tablePageSubtitle' style={{ fontSize: 14, marginBottom: 16 }}>
          {translate(`projectCreatePage.descriptions.${currentStep}` as TxKeyPath)}
        </Title>
        <PageLayout style={{ width: '100%', paddingBottom: 12 }}>
          {currentStep === 1 && (
            <Step1CreateProject
              isLoadingNextStep={isLoadingUpdateNextStepProjectAction}
              project={project}
              nextStep={onNextStepMutation}
            />
          )}
          {currentStep === 2 && (
            <Step2CreateProject
              isLoadingNextStep={isLoadingUpdateNextStepProjectAction}
              project={project}
              nextStep={onNextStepMutation}
            />
          )}
          {currentStep === 3 && (
            <AudienceProvider>
              <Step3CreateProject
                isLoadingNextStep={isLoadingUpdateNextStepProjectAction}
                project={project}
                nextStep={onNextStepMutation}
              />
            </AudienceProvider>
          )}
          {currentStep === 4 && (
            <Step4CreateProject
              isLoadingNextStep={isLoadingUpdateNextStepProjectAction}
              project={project}
              nextStep={onNextStepMutation}
            />
          )}
        </PageLayout>
      </LayoutContainer>
    </LayoutContainer>
  )
}

export default CreateProject
