/** @jsxImportSource @emotion/react */

import { useOutletContext } from 'react-router'
import { RespondentsCertification } from '@views/certification'
import { RespondentsCertificationPropsType } from '@views/certification/respondent/RespondentsCertification'
import { CertificationUserTestType } from '@pages/types'

const RespondentsCertificationPage = () => {
  const { pagination, onChange, respondentsFiltered } = useOutletContext<
    {
      respondentsFiltered: CertificationUserTestType[]
    } & RespondentsCertificationPropsType['tableProps']
  >()

  return (
    <RespondentsCertification
      respondentsFiltered={respondentsFiltered}
      tableProps={{ pagination, onChange }}
    />
  )
}

export default RespondentsCertificationPage
