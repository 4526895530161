/** @jsxImportSource @emotion/react */
import { Button, Space, Title } from 'common-components'
import { useTranslation } from '@hooks'
import { useQuery } from 'react-query'
import { getWirkUrl, mutationKeys } from '@definitions'
import { getWorkflows } from '@api'
import { IWorkflow } from '@types'
import openLink from '@utils/openLink'
import { ListWorkflow } from '@views/workflow'

import { store } from '@/store'

const CatalogueWorkflowsPage = () => {
  const { translate } = useTranslation()
  const accessToken = store.use.me().accessToken

  const { data: workflows = [] } = useQuery({
    queryKey: mutationKeys.workflows({ model: {} }),
    queryFn: () => getWorkflows({ model: {} }),
  })

  const onWorkflowSelect = (workflow: IWorkflow) =>
    openLink(`${getWirkUrl('Manager')}/${workflow.idWorkflow}?token=${accessToken}`)
  const onWorkflowListSelect = (workflow: IWorkflow) =>
    openLink(`${getWirkUrl('Manager')}/${workflow.idWorkflow}/executions?token=${accessToken}`)

  return (
    <>
      <Space style={{ width: '100%', justifyContent: 'space-between' }} align='center'>
        <Title preset='singlePageTitle'>{translate(`worklfows.listTitle`)}</Title>
        <Button type='primary' iconLeft='Plus'>
          {translate(`worklfows.addButton`)}
        </Button>
      </Space>
      <ListWorkflow
        data={workflows}
        onWorkflowSelect={onWorkflowSelect}
        onWorkflowListSelect={onWorkflowListSelect}
      />
    </>
  )
}

export default CatalogueWorkflowsPage
