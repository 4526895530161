import { useOutletContext } from 'react-router'
import { SettingsProject } from '@views/project'
import { ProjectType } from '@pages/types'

const SettingsProjectPage = () => {
  const { project } = useOutletContext<{ project: ProjectType }>()

  return <SettingsProject project={project} />
}

export default SettingsProjectPage
