/** @jsxImportSource @emotion/react */

import { useOutletContext, useParams } from 'react-router'
import { GetCertificationUserTestQuestionsPayloadType } from '@api'
import { QuestionsRespondentCertification } from '@views/certification'
import { CertificationUserTestQuestionType, CertificationUserTestType } from '@pages/types'
import { useEffect } from 'react'
import { useDelayedEffect, useRequestParams } from '@hooks'
import { Loader } from '@components/utils'

import { store } from '@/store'

const QuestionsRespondentCertificationPage = () => {
  const { id: idCertification, respondentTestId } = useParams()

  const { respondentsFiltered } = useOutletContext<{
    respondentsFiltered: CertificationUserTestType[]
  }>()

  const questions = respondentsFiltered?.find(
    (respondent) => respondent.idCertificationUserTest === respondentTestId,
  )?.questions

  const getQuestionsRespondentCertificationAction =
    store.use.getQuestionsRespondentCertificationAction().call

  const isLoading = store.use.getQuestionsRespondentCertificationAction().status === 'LOADING'
  const isSucceeded = store.use.getQuestionsRespondentCertificationAction().status === 'SUCCEEDED'

  const { afterPageChange, pagination, onTableStateChange } = useRequestParams<
    GetCertificationUserTestQuestionsPayloadType,
    CertificationUserTestQuestionType
  >()

  useDelayedEffect(() => {
    getQuestionsRespondentCertificationAction({
      idCertification,
      model: { idCertificationUserTest: respondentTestId },
    })
  }, [respondentTestId])

  useEffect(() => {
    afterPageChange(questions?.length)
  }, [questions?.length])

  return (
    <>
      {isLoading && <Loader />}
      {isSucceeded && (
        <QuestionsRespondentCertification
          certificationUserTestQuestion={questions}
          tableProps={{ pagination, onChange: onTableStateChange }}
        />
      )}
    </>
  )
}

export default QuestionsRespondentCertificationPage
