/** @jsxImportSource @emotion/react */

import { GetCertificationsPayloadType } from '@api'
import { Content, ITableProps } from 'common-components'
import { CertificationType } from '@pages/types'
import { PaginationProps } from 'antd'
import { Loader } from '@components/utils'

import HeaderCertifications from './HeaderCertifications'
import TableCertifications from './TableCertifications'

import { store } from '@/store'

type ListCertificationPropsType = {
  certifications: CertificationType[]
  certificationIsLoading: boolean
  onTableStateChange: (...params: Parameters<ITableProps<CertificationType>['onChange']>) => void
  pagination: PaginationProps
  setParams: (filters: GetCertificationsPayloadType) => void
}

const ListCertification = ({
  certifications,
  certificationIsLoading,
  pagination,
  onTableStateChange,
  setParams,
}: ListCertificationPropsType) => {
  const uploadAccessCertificationAction = store.use.uploadAccessCertificationAction().call

  return (
    <Content>
      <HeaderCertifications onFiltersChange={setParams} />
      {certificationIsLoading && <Loader />}
      {!certificationIsLoading && (
        <TableCertifications
          onAccessRequest={(idCertification) =>
            uploadAccessCertificationAction({ idCertification })
          }
          certifications={certifications}
          tableProps={{ pagination, onChange: onTableStateChange }}
        />
      )}
    </Content>
  )
}

export default ListCertification
