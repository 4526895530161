import { IRadioButtonGroupProps, RadioButtonGroup } from 'common-components'
import { useEffect, useState } from 'react'
import { RadioChangeEvent } from 'antd'

type RadioGroupPropsType = Partial<IRadioButtonGroupProps> & {
  onChange: (mark: number) => void
  value?: number
}

const RadioGroup = ({
  onChange: onChangeProp,
  value: valueProp,
  defaultValue,
  ...rest
}: RadioGroupPropsType) => {
  const [value, setValue] = useState(defaultValue)

  useEffect(() => {
    !!valueProp && setValue(valueProp)
  }, [valueProp])

  const onChange = (ev: RadioChangeEvent) => {
    const { value } = ev.target
    setValue(value)
    onChangeProp(value)
  }

  return (
    <RadioButtonGroup
      value={value}
      onChange={onChange}
      items={[
        {
          value: 0,
          label: '0',
          iconLeft: 'ThumbsDown',
        },
        {
          value: 1,
          label: '1',
        },
        {
          value: 2,
          label: '2',
        },
        {
          value: 3,
          label: '3',
        },
        {
          value: 4,
          label: '4',
        },
        {
          value: 5,
          label: '5',
          iconLeft: 'ThumbsUp',
        },
      ]}
      {...rest}
    />
  )
}

export default RadioGroup
