import { useContext, useEffect, useState } from 'react'
import { useTranslation } from '@hooks'
import { UpdateAudienceReviewersPayloadType, UpdateAudiencePayloadType } from '@api/project'
import { getForm } from '@api/form'
import { FormType, ProjectType } from '@pages/types'
import { Button, Form, FormElements, Space } from 'common-components'
import { LoadingOutlined } from '@ant-design/icons'

import { AudienceCollapse } from '../../shared'

import { formApiMapper } from '@/mappers'
import { AudienceContext } from '@/context'
import { store } from '@/store'

type Step3CreateProjectPropsType = {
  project: ProjectType
  isLoadingNextStep: boolean
  nextStep: () => void
}

const Step3CreateProject = ({
  project,
  isLoadingNextStep,
  nextStep,
}: Step3CreateProjectPropsType) => {
  const { translate } = useTranslation()
  const [form] = FormElements.useForm()
  const [projectForm, setProjectForm] = useState<FormType>()

  const updateAudienceProjectAction = store.use.updateAudienceProjectAction().call
  const updateAudienceReviewersProjectAction = store.use.updateAudienceReviewersProjectAction().call

  const isLoadingUpdateAudienceProjectAction =
    store.use.updateAudienceProjectAction().status === 'LOADING'
  const isLoadingUpdateAudienceReviewersProjectAction =
    store.use.updateAudienceReviewersProjectAction().status === 'LOADING'

  const {
    audience: contributorsAudience,
    reviewersAudience,
    setAudience,
  } = useContext(AudienceContext)

  useEffect(() => {
    getForm(project?.form?.idForm).then((formApi) => {
      setProjectForm(formApiMapper(formApi))
    })
  }, [project?.form?.idForm])

  useEffect(() => {
    if (project?.audience) {
      setAudience('contributors', {
        audienceGroups: project.audience.audienceGroups,
        combination: project.audience.combination,
      })
      setAudience('reviewers', {
        audienceGroups: project.audience.reviewersAudienceGroups,
        combination: project.audience.reviewersCombination,
      })
    }
  }, [project?.audience])

  const handleSubmit = async (values: ProjectType['audience']) => {
    // TODO - Do not delete "values", for future use
    const updateAudiencePayload: UpdateAudiencePayloadType = {
      idProject: project.idProject,
      audience: {
        audienceType: values.audienceType,
        audienceGroups: contributorsAudience.audienceGroups,
        combination: contributorsAudience.combination,
        supplierEarning: values.supplierEarning,
      },
    }
    const updateAudienceReviewersPayload: UpdateAudienceReviewersPayloadType = {
      idProject: project.idProject,
      audience: {
        reviewerAudienceType: values.audienceType,
        reviewersAudienceGroups: reviewersAudience.audienceGroups,
        reviewersCombination: reviewersAudience.combination,
        reviewerEarning: values.reviewerEarning,
      },
    }

    updateAudienceProjectAction(updateAudiencePayload, () => {
      updateAudienceReviewersProjectAction(updateAudienceReviewersPayload, () => {
        nextStep()
      })
    })
  }

  return (
    <Form form={form} onFinish={handleSubmit}>
      <Space direction='vertical' style={{ width: '100%', gap: 16 }}>
        <AudienceCollapse
          type='contributors'
          projectId={project?.idProject}
          audience={contributorsAudience.audienceGroups}
          combination={contributorsAudience.combination}
          audienceType={project?.audience?.audienceType}
          selectedEarnings={project?.audience?.supplierEarning}
          timePerTask={projectForm?.timePerTask}
        />
        {projectForm?.quality === 40 ||
          (projectForm?.quality === 20 &&
            projectForm?.template?.template?.quality.indeterminateMode === 'manual' && (
              <AudienceCollapse
                type='reviewers'
                projectId={project?.idProject}
                audience={reviewersAudience.audienceGroups}
                combination={reviewersAudience.combination}
                audienceType={project?.audience?.reviewerAudienceType}
                selectedEarnings={project?.audience?.reviewerEarning}
                timePerTask={projectForm?.timePerTask}
              />
            ))}
        <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
          <Button
            disabled={
              isLoadingNextStep ||
              isLoadingUpdateAudienceProjectAction ||
              isLoadingUpdateAudienceReviewersProjectAction
            }
            type='primary'
            htmlType='submit'
            style={{
              width: '150px',
            }}
          >
            {isLoadingUpdateAudienceProjectAction ||
            isLoadingNextStep ||
            isLoadingUpdateAudienceReviewersProjectAction ? (
              <LoadingOutlined />
            ) : (
              translate('projectCreatePage.submitButton.step1')
            )}
          </Button>
        </Space>
      </Space>
    </Form>
  )
}

export default Step3CreateProject
