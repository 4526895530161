/** @jsxImportSource @emotion/react */

import { useNavigate, useOutletContext } from 'react-router'
import { routes } from '@navigation/routes'
import { CreateDraftQuestionsCertification } from '@views/shared/certification'
import { CertificationType } from '@pages/types'

const CreateDraftQuestionsCertificationPage = () => {
  const { certification } = useOutletContext<{ certification: CertificationType }>()

  const navigate = useNavigate()

  const onClickNext = () => {
    navigate(routes.certificationQuestions.navigate(certification.idCertification))
  }

  return (
    <>
      <CreateDraftQuestionsCertification certification={certification} onNextStep={onClickNext} />
    </>
  )
}

export default CreateDraftQuestionsCertificationPage
