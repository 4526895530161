import { FilterPayloadType } from '@types'
import {
  axiosClient,
  TAlertType,
  TAudienceCombinationType,
  TAudienceType,
  TProjectState,
  TSlaType,
} from '@definitions'
import { AxiosResponse } from 'axios'
import { catchApiError, downloadBlob } from '@utils'

import {
  ProjectApiType,
  ProjectAudienceReviewerGroupApiType,
  ProjectSlaLeverageHourApiType,
  FormShortApiType,
  UserShortApiType,
  PrefillSettingsApiType,
  AiGenerativeResourceApiType,
} from '@/api/types'

export type GetProjectsPayloadType = Pick<
  FilterPayloadType,
  'endDate' | 'entriesPerPage' | 'index' | 'search' | 'startDate'
> & {
  model: {
    managers?: string[]
    models?: string[]
    segments?: string[]
    state?: TProjectState[]
    taskOnProgress?: boolean
    activeAlerts?: boolean
  }
}
export const getProjects = async (payload: GetProjectsPayloadType): Promise<ProjectApiType[]> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<ProjectApiType[]> = await axios.post(
      '/ProjectService/Project/GetProjects',
      payload,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export type UploadTaskFileInputColumnPayloadType = {
  idProject: string
  columnName: string
  file: string
}
export const uploadTaskFileInputColumn = async ({
  idProject,
  file,
  columnName,
}: UploadTaskFileInputColumnPayloadType): Promise<void> => {
  try {
    const axios = axiosClient({
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    const formData = new FormData()
    formData.append('IdProject', idProject)
    formData.append('ColumnName', columnName)
    formData.append('file', file)

    const resp: AxiosResponse<void> = await axios.post(
      `/ProjectService/Project/UploadTaskFileInputColumn`,
      formData,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export const getProject = async (id: string): Promise<ProjectApiType> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<ProjectApiType> = await axios.get(
      `/ProjectService/Project/${id}/GetProject`,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export type GetAiGenerativeResourcePayloadType = {
  type: AiGenerativeResourceApiType['type']
}

export const getAiGenerativeResource = async ({
  type,
}: GetAiGenerativeResourcePayloadType): Promise<AiGenerativeResourceApiType[]> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<AiGenerativeResourceApiType[]> = await axios.get(
      `ResourceService/AIGenerative?type=${type}`,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export type UpdatePrefillProjectPayloadType = {
  idProject: string
  prefillSettings: PrefillSettingsApiType
}

export const updatePrefillProject = async ({
  idProject,
  prefillSettings,
}: UpdatePrefillProjectPayloadType): Promise<ProjectApiType> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<ProjectApiType> = await axios.put(
      `ProjectService/Project/${idProject}/UpdatePrefillProject`,
      { prefillSettings },
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

// TODO HIDE HIDE , not remove
// export const duplicateProject = async (
// 	id: string | number,
// ): Promise<ProjectApiType> => {
// 	try {
// 		const axios = axiosClient();
// 		const resp: AxiosResponse<ProjectApiType> = await axios.post(
// 			`/ProjectService/Project/${id}/DuplicateProject`,
// 		);
// 		return resp.data;
// 	} catch (err: any) {
// 		throw err.response;
// 	}
// };

export type BoostProjectPayloadType = {
  idProject: string
  nbHours: number
}
export const boostProject = async ({
  idProject,
  nbHours,
}: BoostProjectPayloadType): Promise<ProjectApiType> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<ProjectApiType> = await axios.put(
      `/ProjectService/Project/${idProject}/BoostProject/${nbHours}`,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export const stopBoost = async (idProject: string): Promise<ProjectApiType> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<ProjectApiType> = await axios.put(
      `/ProjectService/Project/${idProject}/BoostProject/Stop`,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export const stopProject = async (idProject: string): Promise<ProjectApiType> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<ProjectApiType> = await axios.put(
      `/ProjectService/Project/${idProject}/StopProject`,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export const archiveProject = async (idProject: string): Promise<ProjectApiType> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<ProjectApiType> = await axios.delete(
      `/ProjectService/Project/${idProject}/ArchiveProject`,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export const downloadSampleFileProject = async (idProject: string): Promise<void> => {
  try {
    const axios = axiosClient()
    const response: AxiosResponse<Blob> = await axios.post(
      `/ProjectService/Project/${idProject}/DownloadSampleFile`,
      {},
      {
        responseType: 'blob',
      },
    )
    downloadBlob([response.data], `InputFileSample_Project_${idProject}.xlsx`)
  } catch (e) {
    catchApiError(e)
  }
}

type DownloadProjectsPayloadType = GetProjectsPayloadType
export const downloadProjects = async (filters: DownloadProjectsPayloadType = { model: {} }) => {
  try {
    const axios = axiosClient()
    const response: AxiosResponse<Blob> = await axios.post(
      '/ProjectService/Project/GetProjects/Download',
      filters,
      {
        responseType: 'blob',
      },
    )
    return response.data
  } catch (e) {
    catchApiError(e)
  }
}

type CreateProjectPayloadType = {
  name: string
}
export const createProject = async ({
  name,
}: CreateProjectPayloadType): Promise<ProjectApiType> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<ProjectApiType> = await axios.post(
      `/ProjectService/Project/CreateProject`,
      { name },
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export type UpdateProjectSettingsPayloadType = {
  id: string
  name: string
  sla: {
    type: TSlaType
    delay?: string
    leverageHours?: ProjectSlaLeverageHourApiType[]
    averageDeleveringTimeGoal: string
    effectiveTimeTaskGoal: string
    effectiveTimeHitGoal: string
    qualityExpected: number
  }
  settings: {
    diversification: number
    nbMinEvaluation: number
    expectedQualityMin: number
    urlWebhook: string
    smsAlert: TAlertType
    emailAlert: TAlertType
  }
}
export const updateProjectSettings = async ({
  id,
  settings,
  sla,
  name,
}: UpdateProjectSettingsPayloadType): Promise<ProjectApiType> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<ProjectApiType> = await axios.put(
      `/ProjectService/Project/${id}/UpdateSettings`,
      { settings, sla, name },
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export type UpdateProjectManagersPayloadType = {
  idProject: string
  managers: UserShortApiType[]
}
export const updateProjectManagers = async ({
  idProject,
  managers,
}: UpdateProjectManagersPayloadType): Promise<ProjectApiType> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<ProjectApiType> = await axios.put(
      `/ProjectService/Project/${idProject}/UpdateManagers`,
      { managers },
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

type NextStepPayloadType = {
  idProject: string
}
export const nextStepProject = async ({
  idProject,
}: NextStepPayloadType): Promise<TProjectState> => {
  try {
    const axios = axiosClient()
    const { data: nextStep }: AxiosResponse<number> = await axios.put(
      `/ProjectService/Project/${idProject}/NextStep`,
    )
    return Number(nextStep) as TProjectState
  } catch (e) {
    catchApiError(e)
  }
}

export type UpdateAudiencePayloadType = {
  idProject: string
  audience: {
    audienceType: TAudienceType
    audienceGroups: ProjectAudienceReviewerGroupApiType[]
    combination: TAudienceCombinationType
    supplierEarning?: number
  }
}
export const updateAudience = async ({
  idProject,
  audience,
}: UpdateAudiencePayloadType): Promise<ProjectApiType> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<ProjectApiType> = await axios.put(
      `/ProjectService/Project/${idProject}/UpdateAudience`,
      { audience },
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export type UpdateAudienceReviewersPayloadType = {
  idProject: string
  audience: {
    reviewerAudienceType: TAudienceType
    reviewersAudienceGroups: ProjectAudienceReviewerGroupApiType[]
    reviewersCombination: TAudienceCombinationType
    reviewerEarning?: number
  }
}
export const updateAudienceReviewers = async ({
  idProject,
  audience,
}: UpdateAudienceReviewersPayloadType): Promise<ProjectApiType> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<ProjectApiType> = await axios.put(
      `/ProjectService/Project/${idProject}/UpdateAudienceReviewer`,
      { audience },
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

type AttachTasksToProjectPayloadType = {
  idProject: string
}
export const attachTasksToProject = async ({
  idProject,
}: AttachTasksToProjectPayloadType): Promise<ProjectApiType> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<ProjectApiType> = await axios.put(
      `/ProjectService/Project/${idProject}/AttachTasksToProject`,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export type UpdateFormProjectPayloadType = {
  idProject: string
  form: FormShortApiType
}
export const updateFormProject = async ({
  idProject,
  form,
}: UpdateFormProjectPayloadType): Promise<ProjectApiType> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<ProjectApiType> = await axios.put(
      `/ProjectService/Project/${idProject}/UpdateFormProject`,
      { form },
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}
