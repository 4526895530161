/** @jsxImportSource @emotion/react */

import { IModel } from '@api/types'
import styled from '@emotion/styled'
import { getStateKeyByValue, dateTime } from '@utils'
import { useTranslation } from '@hooks'
import { ModelState } from '@definitions/states'
import { PresetTag, PresetTagPropsType } from '@components/display'
import {
  Avatar,
  Checkbox,
  Col,
  colors,
  HoverCard,
  IAvailableIcons,
  Icon,
  IconButton,
  IHoverCardProps,
  ITagProps,
  Row,
  Space,
  spacing,
  Tag,
  Text,
} from 'common-components'

type CardModelPropsType = IHoverCardProps & {
  model: IModel
  tags?: (ITagProps & { label: string; icon?: IAvailableIcons })[]
  headerTags?: (PresetTagPropsType & { label: string })[]
  onBookmarkToggle?: (model: IModel) => void
  onModelOpen?: (model: IModel) => void
  onModelSelect?: (model: IModel) => void
  canSelect?: boolean
}
const HeaderContainer = styled(Row)`
  padding-top: ${spacing.padding.card.default}px;
  padding-left: ${spacing.padding.card.default}px;
  padding-right: ${spacing.padding.card.default}px;
  min-height: 39px;
`
const ContentContainer = styled(Col)`
  padding: ${spacing.padding.card.default}px;
  min-height: 99px;
`
const FooterContainer = styled(Row)`
  width: 100%;
  height: 56px;
`
const HoverContentContainer = styled(Row)`
  width: 100%;
`
const DraftBadgeContainer = styled(Row)`
  position: absolute;
  height: 50px;
  width: 110px;
  padding: 5px;
  top: -43px;
  right: -35px;
  transform: rotate(45deg);
  background-color: ${colors.gray600};
  align-items: flex-end;
  justify-content: center;
`
const CardModel = ({
  model,
  tags = [],
  headerTags = [],
  onBookmarkToggle,
  onModelOpen,
  onModelSelect,
  canSelect,
  ...rest
}: CardModelPropsType) => {
  const { translate } = useTranslation()
  const isCreate = model?.state === getStateKeyByValue(ModelState, 'create')
  const isTrain = model?.state === getStateKeyByValue(ModelState, 'train')
  const isError = model?.state === getStateKeyByValue(ModelState, 'error')
  const isArchived = model?.state === getStateKeyByValue(ModelState, 'archived')

  return (
    <HoverCard
      style={{ height: 200 }}
      HeaderComponent={
        <HeaderContainer align={'middle'} justify={'space-between'}>
          {!!headerTags.length &&
            headerTags.map(({ label, ...props }) => <PresetTag {...props}>{label}</PresetTag>)}
          {canSelect && <Checkbox key={model.idModel} value={model.idModel} />}
          {onBookmarkToggle && (
            <div style={{ cursor: 'pointer' }} onClick={() => onBookmarkToggle(model)}>
              <Icon
                icon={'Heart'}
                color={model?.isBookrmarked ? colors.error500 : colors.gray300}
                svgProps={{
                  fill: model?.isBookrmarked ? colors.error500 : colors.white,
                }}
                size={20}
              />
            </div>
          )}
        </HeaderContainer>
      }
      ContentComponent={
        <ContentContainer>
          <DraftBadgeContainer>
            <Text color={colors.white}>
              {isCreate && translate('model.draftLabel')}
              {isTrain && translate('model.trainLabel')}
              {isError && translate('model.errorLabel')}
              {isArchived && translate('model.archivedLabel')}
            </Text>
          </DraftBadgeContainer>
          <Space direction='vertical'>
            <Text strong size='md'>
              {model.name}
            </Text>
            {tags.map((tag) => (
              <Tag color={colors.gray700} {...tag}>
                {tag.icon && <Icon icon={tag.icon} />}
                {tag.label}
              </Tag>
            ))}
            <Text size='xs' color={colors.gray400}>
              {model.updateDate &&
                `${translate('model.lastModified')} ${dateTime(model.updateDate)}`}
            </Text>
          </Space>
        </ContentContainer>
      }
      footerContent={
        <FooterContainer align={'middle'} justify={'space-between'}>
          {/*FIXME: no avatar data*/}
          <Avatar size='xs' />
          <Space size={5}>
            <Space size={5} align={'start'}>
              <Icon icon={'FileSearch02'} color={colors.gray400} size={16} />
              {/*TODO: verify*/}
              <Text size='xs'>{model.nbDocsAnnotated}</Text>
            </Space>
            {!!model?.quality?.length && (
              <Space size={5} align={'start'}>
                <Icon icon={'Target04'} color={colors.gray400} size={16} />
                {/*TODO: verify*/}
                <Text size='xs'>{model.quality[0]?.accuracy}</Text>
              </Space>
            )}
          </Space>
        </FooterContainer>
      }
      hoverFooterContent={
        <HoverContentContainer align={'middle'} justify={'center'}>
          {onModelOpen && (
            <IconButton
              icon={'Eye'}
              type={'text'}
              iconProps={{ color: colors.white }}
              onClick={() => onModelOpen(model)}
            />
          )}
          {onModelSelect && (
            <IconButton
              icon={'ArrowCircleBrokenRight'}
              type={'text'}
              iconProps={{ color: colors.white }}
              onClick={() => onModelSelect(model)}
            />
          )}
        </HoverContentContainer>
      }
      {...rest}
    />
  )
}

export default CardModel
