type TTasklineView = 'hit' | 'task' | 'wirk-model'
export const tasklineHitViews: TTasklineView[] = ['hit', 'task', 'wirk-model']

export type TTasklineHitNavParams = {
  id: string
  taskId: string
  hitId?: string
  projectId?: string
  tenant: string
  view: TTasklineView
}
