import { ProjectApiType } from '@api/types'
import { ProjectType } from '@pages/types'
import { dateTime } from '@utils'

import { removeNullAndUndefinedMapper } from './helper.mapper'

import { managerShortApiMapper } from '@/mappers/user.mapper'

export const projectApiMapper = (projectApi: ProjectApiType): ProjectType => {
  try {
    if (!projectApi.idProject) {
      throw new Error('empty project (id not found)')
    }

    const result = {
      idProject: projectApi.idProject,
      name: projectApi.name,
      managers: projectApi?.managers?.map(managerShortApiMapper) || [],
      audience: projectApi?.audience,
      state: projectApi?.state,
      updateDate: dateTime(projectApi?.updateDate),
      nbActiveSupplier: projectApi?.nbActiveSupplier,
      nbTasks: projectApi?.nbTasks,
      nbAlerts: projectApi?.nbAlerts,
      endBoost: projectApi?.endBoost,
      prefillSettings: projectApi?.prefillSettings,
      form: projectApi?.form, // TODO formShortApiMapper(projectApi?.form) ???
      settings: projectApi?.settings,
      sla: projectApi?.sla,
      spendTime: projectApi?.spendTime,
    }
    return removeNullAndUndefinedMapper<ProjectType>(result)
  } catch (e) {
    throw e
  }
}
