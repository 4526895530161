import { TAudienceCollapseType } from '@views/project/shared'
import { TAudienceCombinationType } from '@definitions/states'
import React, { ReactNode, useState } from 'react'
import {
  ProjectAudienceReviewerGroupSegmentType,
  ProjectAudienceReviewerGroupType,
} from '@pages/types'
import cloneDeep from 'lodash/cloneDeep'

type ContextAudienceType = {
  audienceGroups: ProjectAudienceReviewerGroupType[]
  combination: TAudienceCombinationType
}

type AudienceContextValueType = {
  audience: ContextAudienceType
  reviewersAudience: ContextAudienceType
  setAudience: (audienceType: TAudienceCollapseType, audience: ContextAudienceType) => void
  addGroup: (audienceType: TAudienceCollapseType) => void
  deleteGroup: (audienceType: TAudienceCollapseType, groupIdx: number) => void
  addSegment: (
    audienceType: TAudienceCollapseType,
    groupIdx: number,
    newSegment: ProjectAudienceReviewerGroupSegmentType,
  ) => void
  deleteSegment: (
    audienceType: TAudienceCollapseType,
    groupIdx: number,
    semgentToDelete: ProjectAudienceReviewerGroupSegmentType,
  ) => void
  changeTreeCombination: (
    audienceType: TAudienceCollapseType,
    newCombnation: TAudienceCombinationType,
  ) => void
  changeGroupCombination: (
    audienceType: TAudienceCollapseType,
    groupIdx: number,
    newCombnation: TAudienceCombinationType,
  ) => void
}

export const AudienceContext = React.createContext<AudienceContextValueType>({
  audience: {
    audienceGroups: [],
    combination: null,
  },
  reviewersAudience: {
    audienceGroups: [],
    combination: null,
  },
  setAudience: () => null,
  addGroup: () => null,
  deleteGroup: () => null,
  addSegment: () => null,
  deleteSegment: () => null,
  changeTreeCombination: () => null,
  changeGroupCombination: () => null,
})

const { Provider } = AudienceContext

export const AudienceProvider = ({ children }: { children: ReactNode }) => {
  const [audience, setContributorsAudience] = useState<ContextAudienceType>({
    audienceGroups: [],
    combination: null,
  })
  const [reviewersAudience, setReviewersAudience] = useState<ContextAudienceType>({
    audienceGroups: [],
    combination: null,
  })

  const getAudienceToUpdate = (audienceType: TAudienceCollapseType): ContextAudienceType => {
    return audienceType === 'contributors'
      ? JSON.parse(JSON.stringify(audience))
      : JSON.parse(JSON.stringify(reviewersAudience))
  }
  const updateAudience = (
    audienceType: TAudienceCollapseType,
    updatedAudience: ContextAudienceType,
  ) => {
    audienceType === 'contributors'
      ? setContributorsAudience(updatedAudience)
      : setReviewersAudience(updatedAudience)
  }

  const setAudience = (audienceType: TAudienceCollapseType, audience: ContextAudienceType) =>
    audienceType === 'contributors'
      ? setContributorsAudience(audience)
      : setReviewersAudience(audience)

  const addGroup = (audienceType: TAudienceCollapseType) => {
    const audienceToUpdate = getAudienceToUpdate(audienceType)
    const updatedAudience: ContextAudienceType = {
      audienceGroups: [
        ...(audienceToUpdate.audienceGroups || []),
        { segments: [], combination: 10 },
      ],
      combination: audienceToUpdate.combination || 10,
    }
    updateAudience(audienceType, updatedAudience)
  }

  const deleteGroup = (audienceType: TAudienceCollapseType, groupIdx: number) => {
    const audienceToUpdate = getAudienceToUpdate(audienceType)
    const updatedAudience: ContextAudienceType = {
      audienceGroups: audienceToUpdate.audienceGroups.filter((_, idx) => idx !== groupIdx),
      combination: audienceToUpdate.combination,
    }
    updateAudience(audienceType, updatedAudience)
  }

  const addSegment = (
    audienceType: TAudienceCollapseType,
    groupIdx: number,
    newSegment: ProjectAudienceReviewerGroupSegmentType,
  ) => {
    const handleAddSegment = (prev: ContextAudienceType) => {
      const updatedGroups = prev.audienceGroups
      if (
        updatedGroups[groupIdx].segments.find(
          (segment) => segment.idSegment === newSegment.idSegment,
        )
      )
        return prev
      updatedGroups[groupIdx].segments.push(newSegment)
      return {
        audienceGroups: updatedGroups,
        combination: prev.combination,
      }
    }
    audienceType === 'contributors'
      ? setContributorsAudience((prev) => handleAddSegment(cloneDeep(prev)))
      : setReviewersAudience((prev) => handleAddSegment(cloneDeep(prev)))
  }

  const deleteSegment = (
    audienceType: TAudienceCollapseType,
    groupIdx: number,
    semgentToDelete: ProjectAudienceReviewerGroupSegmentType,
  ) => {
    const handleDeleteSegment = (prev: ContextAudienceType) => {
      const updatedGroups = prev.audienceGroups
      updatedGroups[groupIdx].segments = updatedGroups[groupIdx].segments.filter(
        (segment) => segment.idSegment !== semgentToDelete.idSegment,
      )
      return {
        audienceGroups: updatedGroups,
        combination: prev.combination,
      }
    }
    audienceType === 'contributors'
      ? setContributorsAudience((prev) => handleDeleteSegment(cloneDeep(prev)))
      : setReviewersAudience((prev) => handleDeleteSegment(cloneDeep(prev)))
  }

  const changeTreeCombination = (
    audienceType: TAudienceCollapseType,
    newCombnation: TAudienceCombinationType,
  ) => {
    const audienceToUpdate = getAudienceToUpdate(audienceType)
    audienceToUpdate.combination = newCombnation
    updateAudience(audienceType, audienceToUpdate)
  }

  const changeGroupCombination = (
    audienceType: TAudienceCollapseType,
    groupIdx: number,
    newCombnation: TAudienceCombinationType,
  ) => {
    const handleChangeCombination = (prev: ContextAudienceType) => {
      prev.audienceGroups[groupIdx].combination = newCombnation
      return prev
    }
    audienceType === 'contributors'
      ? setContributorsAudience((prev) => handleChangeCombination(cloneDeep(prev)))
      : setReviewersAudience((prev) => handleChangeCombination(cloneDeep(prev)))
  }

  return (
    <Provider
      value={{
        audience,
        reviewersAudience,
        setAudience,
        addGroup,
        deleteGroup,
        addSegment,
        deleteSegment,
        changeTreeCombination,
        changeGroupCombination,
      }}
    >
      {children}
    </Provider>
  )
}
