const fr = {
  title: {
    questions: 'Questions',
    respondents: 'Respondents',
  },
  createButtonLabel: 'Ajouter des questions',
  deleteButtonLabel: 'Supprimer les questions',
  exportButtonLabel: 'Export',
  filtersButtonLabel: 'Filter',
  publishButtonLabel: 'Publier',
  onlineButtonLabel: 'Mettre en ligne',
  offlineButtonLabel: 'Mettre hors ligne',
  archiveButtonLabel: 'Archiver',
  respondentsHeaderTitle: 'Répondants',
  respondentHeaderTitle: 'Réponses de {{name}}',
  questionHeaderTitle: 'Question',
  notifications: {
    trainingStatusChangeSuccess: 'Training status changed',
    trainingStatusChangeError: 'Training status failed to change',
  },
  questionsTable: {
    actions: 'Actions',
    questionId: 'ID Question',
    participantsNumber: 'Number of participants',
    timeAverage: 'Average time',
    training: 'For training?',
    state: 'Status',
    successRate: 'Success rate',
  },
  deleteQuestionModal: {
    deleteQuestionTitle: 'Supprimer une question de certification',
    deleteQuestionDescription:
      'Lorsque vous modifiez une certification, cela fausse les résultats de ceux qui l’ont déjà obtenue ou ratée. ',
    checkboxLabel: 'Permettre de repasser la certification à ceux qui y avait répondu.',
    deleteButtonLabel: 'Supprimer',
    cancelButtonLabel: 'Annuler',
  },
  deleteRespondentQuestionModal: {
    title: 'Supprimer le passage de la certification',
    description:
      'Vous êtes sur le point de supprimer le passage de la certification. Le contributeur aura la possibilité de la repasser ultérieurement.',
  },
  respondentsTable: {
    actions: 'Actions',
    date: 'Date',
    respondent: 'Respondent',
    score: 'Score',
    state: 'Status',
    time: 'Time',
    type: 'Type',
  },
  respondentQuestionsTable: {
    actions: 'ID Question',
    time: 'Temps de passage',
    score: 'Score',
  },
}

export default fr
