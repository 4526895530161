/** @jsxImportSource @emotion/react */

import { Outlet, useLocation, useNavigate, useParams } from 'react-router'
import { PresetTag } from '@components/display'
import { ISideBarProps, SideBar, TMenuBarItem } from '@components/navigation'
import { routes } from '@navigation/routes'
import { getStateKeyByValue } from '@utils'
import { CertificationState } from '@definitions'
import { useDelayedEffect, useTranslation } from '@hooks'
import { LoadingOutlined } from '@ant-design/icons'
import {
  Button,
  Col,
  colors,
  Content,
  Icon,
  LayoutContainer,
  ProgressIndicator,
  Row,
  Space,
  Tag,
  Title,
  Text,
} from 'common-components'
import { Loader } from '@components/utils'
import { PageLayout } from '@components/layout'
import { css } from '@emotion/react'

import { store } from '@/store'

const CertificationContainer = () => {
  const { id: idCertification } = useParams()

  const certification = store.use
    .certifications()
    ?.find((certification) => certification.idCertification === idCertification)

  const getCertificationAction = store.use.getCertificationAction().call
  const updateStateCertificationAction = store.use.updateStateCertificationAction().call

  const isLoadingUpdateStateCertificationAction =
    store.use.updateStateCertificationAction().status === 'LOADING'
  const isLoadingGetCertificationAction = store.use.getCertificationAction().status === 'LOADING'
  const isSucceededGetCertificationAction =
    store.use.getCertificationAction().status === 'SUCCEEDED'

  const navigate = useNavigate()
  const location = useLocation()
  const { translate } = useTranslation()

  const notPublishedStateKeys = Object.keys(CertificationState).filter(
    (state) =>
      state !== getStateKeyByValue(CertificationState, 'online') &&
      state !== getStateKeyByValue(CertificationState, 'archived'),
  )

  const isInEditState = notPublishedStateKeys.includes(certification?.state?.toString())
  const isOfflineCertification =
    certification?.state === getStateKeyByValue(CertificationState, 'offline')
  const isOnlineCertification =
    certification?.state === getStateKeyByValue(CertificationState, 'online')
  const isDraftCertification =
    certification?.state === getStateKeyByValue(CertificationState, 'extractionParameter') ||
    certification?.state === getStateKeyByValue(CertificationState, 'settingsDefinition') ||
    certification?.state === getStateKeyByValue(CertificationState, 'addData') ||
    certification?.state === getStateKeyByValue(CertificationState, 'addAnswers')

  const onSidebarClick: ISideBarProps['onClick'] = (e) => {
    navigate(e.key)
  }

  useDelayedEffect(() => {
    getCertificationAction({ idCertification })
  }, [idCertification])

  const items: TMenuBarItem[] = (() => {
    const { state } = certification || {}
    const baseItems = [
      {
        key: routes.certificationQuestions.navigate(idCertification),
        icon: <Icon icon={'MessageQuestionSquare'} />,
        label: 'Questions',
      },
      {
        key: routes.certificationRespondents.navigate(idCertification),
        icon: <Icon icon={'Users02'} />,
        label: 'Respondents',
      },
      {
        key: routes.certificationParameters.navigate(idCertification),
        icon: <Icon icon={'Settings01'} />,
        label: 'Settings',
        disabled: false,
      },
    ]

    if (state || !!isInEditState) {
      baseItems.splice(2, 0, {
        key: routes.certificationSegments.navigate(idCertification),
        icon: <Icon icon={'Target02'} />,
        label: 'Segments',
      })
    }
    return baseItems
  })()

  const getProgressPercentage = () => {
    const { nbQuestions = 1, nbPublishedQuestions = 1 } = certification || {}
    return (nbPublishedQuestions * 100) / nbQuestions
  }

  const canPublish = certification?.nbPublishedQuestions >= certification?.nbQuestionsTest

  return (
    <PageLayout
      css={css`
        margin: 0;
        max-width: none;
      `}
    >
      <Row
        style={{
          padding: '18px 16px',
          gap: 16,
          borderBottom: `1px solid ${colors.gray200}`,
        }}
      >
        <Button size='sm' onClick={() => navigate(routes.certifications.navigate())}>
          <Icon icon='XClose' size={20} />
        </Button>
        <Row justify={'space-between'} style={{ flex: 1 }}>
          <Space align={'center'} size={5}>
            <Tag color={colors.gray600} style={{ fontWeight: 500, marginInlineEnd: 0 }}>
              #{certification?.idCertification}
            </Tag>
            <Title preset='singlePageTitle'>{certification?.name}</Title>
            <PresetTag preset={'certification-state'} state={certification?.state} withDot>
              {translate(`common.states.certificationState.${certification?.state}`)}
            </PresetTag>
          </Space>
          {isInEditState && (
            <Row style={{ width: '50%' }} align={'middle'}>
              <ProgressIndicator
                percent={getProgressPercentage()}
                format={() => ''}
                style={{ flex: 1 }}
                strokeColor={canPublish ? colors.primary700 : colors.warning300}
              />
              <Text>{`${certification?.nbPublishedQuestions || 0} / ${
                certification?.nbQuestions || 0
              }(min. ${certification?.nbQuestionsTest || 0})`}</Text>

              <Button
                {...(!isOnlineCertification ? { type: 'primary' } : {})}
                style={{ marginLeft: 10 }}
                onClick={() =>
                  updateStateCertificationAction({
                    idCertification: certification.idCertification,
                    isDraftCertification,
                    isOfflineCertification,
                    isOnlineCertification,
                  })
                }
                disabled={!canPublish}
              >
                <>
                  {isDraftCertification && `${translate('certificationPage.publishButtonLabel')} `}
                  {isOnlineCertification && `${translate('certificationPage.offlineButtonLabel')} `}
                  {isOfflineCertification && `${translate('certificationPage.onlineButtonLabel')} `}
                  {isLoadingUpdateStateCertificationAction && (
                    <LoadingOutlined style={{ marginLeft: 10 }} />
                  )}
                </>
              </Button>
            </Row>
          )}
        </Row>
      </Row>
      <LayoutContainer>
        <SideBar onClick={onSidebarClick} selectedKeys={[location.pathname]} items={items} />
        <Content>
          <Col style={{ padding: '24px 32px' }}>
            {isLoadingGetCertificationAction && <Loader />}
            {isSucceededGetCertificationAction && <Outlet context={{ certification }} />}
          </Col>
        </Content>
      </LayoutContainer>
    </PageLayout>
  )
}

export default CertificationContainer
