/** @jsxImportSource @emotion/react */

import { useTranslation } from '@hooks'
import { css } from '@emotion/react'
import { Collapse } from 'antd'
import { RiskAssessmentType, RiskType } from '@pages/types'
import { PresetTag } from '@components/display'
import { getRiskLevelValueKey } from '@utils/getRiskLevelValueKey'
import { TxKeyPath } from '@i18n'
import styled from '@emotion/styled'
import { useContext } from 'react'
import {
  Col,
  colors,
  IconButton,
  IIconButtonProps,
  Row,
  Space,
  Text,
  Title,
} from 'common-components'

import { RiskContext } from '@/context'

const { Panel } = Collapse

type RiskDetailedSettingsPropsType = {
  risk: RiskType
  page?: number
}

const getIconForRiskType = (type: RiskAssessmentType): IIconButtonProps['icon'] => {
  switch (type) {
    case 'Colorimetry':
    case 'Colorimetry in blocks':
      return 'Palette'
    case 'Parallelism':
    case 'Parallelism in blocks':
      return 'Transform'
    case 'Font':
    case 'Font in blocks':
    case 'Size':
    case 'Size in blocks':
      return 'Type01'
    case 'Spacing':
    case 'Spacing in blocks':
      return 'Divide01'
    case 'versioning':
    case 'versioning in blocks':
    case 'Layers':
      return 'DataFlow1'
    case 'MetadataKeywords':
      return 'Data'
  }
  return 'Data'
}

const DetailedSettingsRisk = ({ risk, page }: RiskDetailedSettingsPropsType) => {
  const { translate } = useTranslation()

  const { disabledColors, toggleDisabledColor } = useContext(RiskContext)

  return (
    <Collapse ghost bordered expandIconPosition={'end'}>
      {risk.risks.map((riskItem, idx) => {
        return (
          //   RISK TYPE COLLAPSE PANEL
          <Panel
            key={`${riskItem.type}-${idx}-${risk?.idRisk}`}
            css={css`
              border-top: 1px solid ${colors.gray100} !important;
              border-bottom: 1px solid ${colors.gray100} !important;
              .ant-collapse-content-box {
                padding-block: 0 !important;
                padding: 0 !important;
              }
            `}
            header={
              <Space size={10} direction={'horizontal'}>
                <IconButton icon={getIconForRiskType(riskItem.type)} />
                <Title style={{ fontSize: 16, textTransform: 'capitalize' }} color={colors.gray700}>
                  {riskItem.type}
                </Title>
                <PresetTag preset='risk-level' state={riskItem.level}>
                  {translate(
                    `risk.levelStates.${getRiskLevelValueKey(riskItem.level)}` as TxKeyPath,
                  )}
                </PresetTag>
              </Space>
            }
          >
            <Space
              direction='vertical'
              css={css`
                gap: 20px;
                width: 100%;
              `}
            >
              {riskItem.colors.length ? (
                <StyledLogHeaderRow>
                  <Col span={6}>
                    <Text size='sm'>{translate('risk.assessmentList.elementHeader')}</Text>
                  </Col>
                  <Col
                    span={10}
                    css={css`
                      text-align: center;
                    `}
                  >
                    <Text size='sm'>{translate('risk.assessmentList.valueHeader')}</Text>
                  </Col>
                  <Col span={4} />
                </StyledLogHeaderRow>
              ) : (
                <StyledLogHeaderRow>
                  <Text size='sm'>{translate('risk.assessmentList.emptyListText')}</Text>
                </StyledLogHeaderRow>
              )}
              RISK VISUALISATION GROUP
              {riskItem.colors?.reduce((acc, log) => {
                const includedOnCurrentPage = log.visualisations.some(
                  (vis) => vis.pageNumber === page + 1,
                )
                if (includedOnCurrentPage)
                  acc.push(
                    <StyledLogRow key={log.key}>
                      <Col span={6}>
                        <div
                          css={css`
                            width: 20px;
                            height: 20px;
                            border-radius: 4px;
                            background-color: ${log.color};
                          `}
                        />
                      </Col>
                      <Col
                        span={10}
                        css={css`
                          text-align: center;
                        `}
                      >
                        <Text size='sm'>{log.value}</Text>
                      </Col>
                      <Col span={4}>
                        <IconButton
                          type={'link'}
                          icon={!disabledColors.includes(log.color) ? 'Eye' : 'EyeOff'}
                          iconProps={{ color: colors.gray600 }}
                          onClick={() => toggleDisabledColor(log.color)}
                        />
                      </Col>
                    </StyledLogRow>,
                  )
                return acc
              }, [])}
            </Space>
          </Panel>
        )
      })}

      {/*  METADATA */}
      {!!risk?.metadata?.length && (
        <Panel
          key={'metadata'}
          css={css`
            border-bottom: 1px solid ${colors.gray100} !important;
            .ant-collapse-content-box {
              padding-block: 0 !important;
              padding: 0 !important;
            }
          `}
          header={
            <Space size={10} direction={'horizontal'}>
              <IconButton icon={getIconForRiskType('MetadataKeywords')} />
              <Title style={{ fontSize: 16 }} color={colors.gray700}>
                Metadata
              </Title>
            </Space>
          }
        >
          <Space direction='vertical' style={{ gap: 10 }}>
            <StyledLogHeaderRow>
              <Text size='sm'>{translate('risk.assessmentList.elementHeader')}</Text>
              <Text size='sm'>{translate('risk.assessmentList.valueHeader')}</Text>
            </StyledLogHeaderRow>
            {risk.metadata.map((log) => (
              <StyledLogRow>
                <Text size='sm'>{log.key}</Text>
                <Text size='sm'>{log.value}</Text>
              </StyledLogRow>
            ))}
          </Space>
        </Panel>
      )}
    </Collapse>
  )
}

const StyledLogHeaderRow = styled(Row)`
  width: 100%;
  padding: 20px;
  background-color: ${colors.gray50} !important;
  border-top: 1px solid ${colors.gray200} !important;
  border-bottom: 1px solid ${colors.gray200} !important;
  justify-content: space-between;
`

const StyledLogRow = styled(Row)`
  gap: 20px;
  width: 100%;
  border-bottom: 1px solid ${colors.gray200} !important;
  padding: 12px 24px;
  justify-content: space-between;
  align-items: center;
`

export default DetailedSettingsRisk
