/** @jsxImportSource @emotion/react */

import { HitType, TaskType } from '@pages/types'
import { Content, LayoutContainer, Sider } from 'common-components'

import SidebarHitTaskLineProject from './SidebarLayoutsTaskProject'
import TaskHitTopBarProject from './TaskHitTopBarProject'

type TaskHitProjectPropsType = {
  task?: TaskType
  hit?: HitType
  viewUrl: string
}
const TaskHitProject = ({ task, hit, viewUrl }: TaskHitProjectPropsType) => {
  return (
    <LayoutContainer style={{ height: '100%' }}>
      <TaskHitTopBarProject hitState={hit?.hitState} taskState={task?.taskState} />
      <LayoutContainer hasSider style={{ height: '100%' }}>
        <Content>
          <iframe src={viewUrl} height='800' width='100%' frameBorder='0'></iframe>
        </Content>
        <Sider width={400} collapsed collapsedWidth={400}>
          <SidebarHitTaskLineProject task={task} hit={hit} />
        </Sider>
      </LayoutContainer>
    </LayoutContainer>
  )
}

export default TaskHitProject
