import { useRequestParams } from '@hooks'
import { useQuery } from 'react-query'
import { FormState, mutationKeys } from '@definitions'
import { getForms, GetFormsPayloadType, getLastDraftForms } from '@api'
import { FormApiType } from '@api/types'
import { useState } from 'react'
import { getStateKeyByValue } from '@utils'
import { FormType } from '@pages/types'

import { formApiMapper } from '@/mappers'
import { ListForm } from '@/views/form'

const FormsPage = () => {
  const [formDrafts, setFormDrafts] = useState<FormType[]>([])
  const [forms, setForms] = useState<FormType[]>([])

  const { afterPageChange, reqParams, setParams, pagination } = useRequestParams<
    GetFormsPayloadType,
    FormApiType
  >({
    initParams: {
      model: { state: [getStateKeyByValue(FormState, 'published')] },
    },
  })

  useQuery({
    queryKey: mutationKeys.draftForms(),
    queryFn: () => getLastDraftForms(),
    refetchOnWindowFocus: false,
    onSuccess: (formsApi) => {
      const result = formsApi?.map(formApiMapper)
      setFormDrafts(result)
    },
  })

  useQuery({
    queryKey: mutationKeys.forms(reqParams),
    queryFn: () => getForms(reqParams),
    refetchOnWindowFocus: false,
    onSuccess: (formsApi) => {
      afterPageChange(formsApi.length)
      const result = formsApi?.map(formApiMapper)
      setForms(result)
    },
  })

  return (
    <ListForm
      pagination={pagination}
      formDrafts={formDrafts}
      forms={forms}
      setParams={setParams}
      reqParams={reqParams}
    />
  )
}

export default FormsPage
