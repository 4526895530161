import { useEffect, useRef } from 'react'
import { DropInput } from 'common-components'
import { XLSX_FILE_TYPE } from '@utils'
import { RcFile } from 'antd/lib/upload'
import { Modal } from 'antd'
import { UploadChangeParam } from 'antd/es/upload/interface'

import { store } from '@/store'

type ImportFilesModalPropsType = {
  isLoading: boolean
  useDisclosure: { isOpen: boolean; close: () => void }
  onFileUpload: (file: File) => void
  downloadSampleFile: () => Promise<void>
}
const ImportFilesModal = ({
  isLoading,
  useDisclosure,
  onFileUpload,
  downloadSampleFile,
}: ImportFilesModalPropsType) => {
  const addErrorNotificationAction = store.use.addErrorNotificationAction()

  const uploadFile = useRef<RcFile | ''>('')

  useEffect(() => {
    uploadFile.current = ''
  }, [])

  const onUpload = async (ev: UploadChangeParam) => {
    try {
      const fileObj = ev.fileList[0]?.originFileObj
      uploadFile.current = fileObj
      if (fileObj?.size) {
        if (fileObj.type.split('/')[1] !== XLSX_FILE_TYPE) {
          addErrorNotificationAction({
            description: "Que les fichiers de type 'xlsx' soient acceptés",
          })
          return
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
      addErrorNotificationAction()
    }
  }

  const onUploadConfirm = () => {
    if (uploadFile.current === '') return
    onFileUpload(uploadFile.current)
  }

  return (
    <Modal
      open={useDisclosure.isOpen}
      onOk={onUploadConfirm}
      onCancel={() => useDisclosure.close()}
      okButtonProps={{
        loading: isLoading,
      }}
    >
      <DropInput beforeUpload={() => false} maxCount={1} onUpload={onUpload} />
      <a onClick={downloadSampleFile}>Télécharger un fichier d'exemple</a>
    </Modal>
  )
}

export default ImportFilesModal
