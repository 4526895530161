import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { contributorSlice, ContributorSliceType } from './contributor'
import { managerSlice, ManagerSliceType } from './manager'
import { segmentSlice, SegmentSliceType } from './segment'
import { createSelectors } from './helper'
import { notificationSlice, NotificationSliceType } from './notification'
import { certificationSlice, CertificationSliceType } from './certification'
import { projectSlice, ProjectSliceType } from './project'
import { meSlice, MeSliceType } from './me'

export type StoreGlobalType = MeSliceType & NotificationSliceType

type StoreType = StoreGlobalType &
  ContributorSliceType &
  ManagerSliceType &
  SegmentSliceType &
  ProjectSliceType &
  CertificationSliceType

/**
  DOCS:
  - https://docs.pmnd.rs/zustand/guides/typescript#slices-pattern
  - https://github.com/pmndrs/zustand/blob/main/docs/guides/typescript.md
  - https://github.com/pmndrs/zustand/blob/main/docs/guides/auto-generating-selectors.md
 */

const storeRoot = create<StoreType>()(
  devtools(
    immer((...params) => ({
      ...notificationSlice(...params),
      ...meSlice(...params),
      ...contributorSlice(...params),
      ...managerSlice(...params),
      ...segmentSlice(...params),
      ...certificationSlice(...params),
      ...projectSlice(...params),
    })),
    { name: 'Wirk Store', serialize: { options: true } },
  ),
)

const store = createSelectors(storeRoot)

export default store
