const en = {
  notifications: {
    success: {
      description: 'User has been successfully updated.',
    },
    error: {
      description: 'An error occurred during the user update.',
    },
  },
}

export default en
export type Translations = typeof en
