import { useTranslation } from '@hooks/useTranslation'
import { useEffect, useMemo, useState } from 'react'
import { PageLayout } from '@components/layout'
import { getForm } from '@api/form'
import { FormType, ProjectType } from '@pages/types'
import { Content, Pills, Space, Text, Title } from 'common-components'

import ReviewersAudienceProject from './ReviewersAudienceProject'
import ExcludedReviewersAudienceProject from './ExcludedReviewersAudienceProject'
import ContributorsAudienceProject from './ContributorsAudienceProject'

import { formApiMapper } from '@/mappers'

type AudienceProjectPropsType = {
  project: ProjectType
}

type PillsValueType = 'contributors' | 'reviewers' | 'excluded_contributors'
type OptionType = { label: string; value: PillsValueType }

const AudienceProject = ({ project }: AudienceProjectPropsType) => {
  const { translate } = useTranslation()
  const [activeTab, setActiveTab] = useState<PillsValueType>('contributors')
  const [form, setForm] = useState<FormType>()

  useEffect(() => {
    getForm(project?.form?.idForm).then((formApi) => {
      setForm(formApiMapper(formApi))
    })
  }, [project?.form?.idForm])

  const options: OptionType[] = useMemo(() => {
    // TODO "Clean up the file 'src/definitions/states.ts' to reuse keys and values properly"
    const isReviewers =
      (form?.quality === 40 || form?.quality === 20) &&
      form?.template?.template?.quality.indeterminateMode === 'manual'
    const optionsDefault: OptionType[] = [
      {
        label: translate('project.audience.pills.contributers'),
        value: 'contributors',
      },
      {
        label: translate('project.audience.pills.excludedContributers'),
        value: 'excluded_contributors',
      },
    ]

    if (isReviewers) {
      const reviewers: OptionType[] = [
        {
          label: translate('project.audience.pills.reviewers'),
          value: 'reviewers',
        },
      ]
      return [...optionsDefault, ...reviewers]
    }
    return optionsDefault
  }, [form?.quality, form?.template?.template?.quality.indeterminateMode])

  const activeForm = useMemo(() => {
    switch (activeTab) {
      case 'contributors':
        return <ContributorsAudienceProject project={project} timePerTaskForm={form?.timePerTask} />
      case 'reviewers':
        return <ReviewersAudienceProject project={project} timePerTaskForm={form?.timePerTask} />
      case 'excluded_contributors':
        return (
          <ExcludedReviewersAudienceProject
            blacklistedUsers={project?.audience?.blacklistedUsers}
          />
        )
      default:
        return <p>Error</p>
    }
  }, [activeTab, project, form])

  return (
    <Content>
      <PageLayout>
        <Space direction='vertical' style={{ gap: 4, marginBottom: 16 }}>
          <Title preset='singlePageTitle'>{translate('project.audience.title')}</Title>
          <Text>{translate('project.audience.subtitle')}</Text>
        </Space>
        <Pills
          value={activeTab}
          onChange={(e) => setActiveTab(e.target.value)}
          options={options}
          buttonProps={'test' as any}
          style={{ marginBottom: 20 }}
        />
        {activeForm}
      </PageLayout>
    </Content>
  )
}

export default AudienceProject
