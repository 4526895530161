/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useTranslation } from '@hooks'
import { getSuppliers } from '@api'
import { UserProfile } from '@components/display'
import { SelectAsync, ValueSelectType } from '@components/inputs'
import { useEffect, useState } from 'react'
import { ContributorShortType } from '@pages/types'
import { LoadingOutlined } from '@ant-design/icons'
import { Row, Icon, Button, Space } from 'common-components'

const contributorsMapper = (contributors: ContributorShortType[]): UserOption[] =>
  contributors.map((item) => ({
    value: item.idUser,
    label: `${item.firstName} ${item.lastName}`,
    ...item,
  }))

type UserOption = ValueSelectType & ContributorShortType

export type AssignContributorPropsType = {
  contributors?: ContributorShortType[]
  isLoading?: boolean
  /**
   * This action returns the current list of managers.
   */
  onUpdateContributors?: (contributorsShort: ContributorShortType[]) => void
  /**
   *  This function returns the added manager.
   */
  onAddContributor?: (contributorShort: ContributorShortType) => void
  /**
   *  This action returns the deleted manager.
   */
  onDeleteContributor?: (contributorShort: ContributorShortType) => void
}

const AssignContributor = ({
  contributors = [],
  isLoading,
  onAddContributor,
  onDeleteContributor,
  onUpdateContributors,
}: AssignContributorPropsType) => {
  const { translate } = useTranslation()

  const [selectedContributor, setSelectedContributor] = useState<UserOption | ''>('')
  const [selectedContributors, setSelectedContributors] = useState<UserOption[]>(
    contributorsMapper(contributors),
  )

  useEffect(() => {
    setSelectedContributors(contributorsMapper(contributors))
  }, [JSON.stringify(contributors)])

  const loadOptions = (inputValue: string, callback: (dataToPass: UserOption[]) => void) => {
    getSuppliers({
      orderByAsc: true,
      search: inputValue,
      model: {
        segments: [],
      },
    })
      .then((data) => {
        const dataToPass: UserOption[] = data.map((option) => ({
          idUser: option.idUser,
          email: option.email,
          urlAvatar: option.urlAvatar,
          firstName: option.firstName,
          lastName: option.lastName,
          type: option.type,
          value: option.idUser,
          label: `${option.firstName} ${option.lastName}`,
        }))
        callback(dataToPass)
      })
      .catch(() => {
        callback([])
      })
  }

  return (
    <>
      <Row
        css={css`
          width: 100%;
          margin-top: 20px;
        `}
      >
        <Space
          css={css`
            display: grid;
            grid-template-columns: 25fr 1fr;
            gap: 6px;
            margin-bottom: 20px;
            width: 100%;
          `}
        >
          <SelectAsync<UserOption>
            loadOptions={loadOptions}
            valuesSelected={selectedContributors.map(({ label }) => label)}
            onChange={setSelectedContributor}
            value={selectedContributor}
          />
          <Button
            onClick={() => {
              if (selectedContributor !== '') {
                const items = [...selectedContributors, selectedContributor]
                setSelectedContributors(items)
                setSelectedContributor('')
                onUpdateContributors && onUpdateContributors(items)
                onAddContributor && onAddContributor(selectedContributor)
              }
            }}
            disabled={!selectedContributor || isLoading}
          >
            {isLoading ? <LoadingOutlined /> : translate('display.add')}
          </Button>
        </Space>

        {selectedContributors.map((user) => {
          return (
            <Row
              justify={'space-between'}
              align='middle'
              css={css`
                height: 44px;
                width: 100%;
              `}
            >
              <UserProfile
                name={`${user.firstName || ''} ${user.lastName || ''}`}
                avatar={user.urlAvatar}
              />
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  gap: 20px;
                `}
              >
                <Button
                  type='text'
                  onClick={() => {
                    const items = selectedContributors.filter(({ value }) => value !== user.idUser)
                    setSelectedContributors(items)
                    onUpdateContributors && onUpdateContributors(items)
                    onDeleteContributor && onDeleteContributor(user)
                  }}
                >
                  <Icon width={18} height={18} icon='XClose' />
                </Button>
              </div>
            </Row>
          )
        })}
      </Row>
    </>
  )
}

export default AssignContributor
