/** @jsxImportSource @emotion/react */
import { useState } from 'react'
import { css } from '@emotion/react'
import { getSegments } from '@api/segment'
import { getStateKeyByValue } from '@utils'
import {
  SegmentDefType,
  IdManagerRightType,
  UserAccessLevel,
  ID_MANAGER_RIGHT,
  LABEL_MANAGER_RIGHT,
  LabelManagerRightType,
  SegmentTypeType,
} from '@definitions'
import { SelectAsync, ValueSelectType } from '@components/inputs'
import { useTranslation } from '@hooks'
import { SegmentShortType } from '@pages/types'
import { MenuProps } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { Avatar, Row, Space, Text, colors, Icon, Button, Dropdown } from 'common-components'

export type AssignSegmentRightPropsType = {
  segments?: SegmentShortType[]
  isLoading?: boolean
  onAddSegment: (segmentShort: SegmentShortType, managerRight: IdManagerRightType) => void
  onDeleteSegment: (id: string) => void
}

type OptionType = ValueSelectType & {
  managerRight: IdManagerRightType
  segmentData: SegmentShortType
}

const MENU_ITEMS: [IdManagerRightType, LabelManagerRightType][] = [
  [ID_MANAGER_RIGHT[2], LABEL_MANAGER_RIGHT[2]],
  [ID_MANAGER_RIGHT[3], LABEL_MANAGER_RIGHT[3]],
]

const segmentShortMapper = (option: OptionType): SegmentShortType => {
  return {
    idSegment: option.segmentData?.idSegment,
    managerRight: option.segmentData?.managerRight,
    segmentType: option.segmentData?.segmentType,
    name: option.segmentData?.name,
    icon: option.segmentData?.icon,
    qualityMin: option.segmentData?.qualityMin,
    nbMinEvaluation: option.segmentData?.nbMinEvaluation,
  }
}

const segmentsMapper = (segments: SegmentShortType[]): OptionType[] =>
  segments.map((segment) => ({
    value: segment.idSegment,
    label: segment.name,
    managerRight: segment.managerRight,
    segmentData: segment,
  }))

type ManagerRightRenderPropsType = {
  key: number
  label: string
  onSelect: () => void
}
const menuItemRender = ({ key, label, onSelect }: ManagerRightRenderPropsType) => {
  return {
    label: <div onClick={onSelect}>{label}</div>,
    key,
  }
}

const AssignSegmentRight = ({
  segments = [],
  isLoading,
  onDeleteSegment,
  onAddSegment,
}: AssignSegmentRightPropsType) => {
  const [selectedSegment, setSelectedSegment] = useState<OptionType | ''>('')
  const [selectedSegments, setSelectedSegments] = useState<OptionType[]>(segmentsMapper(segments))

  const { translate } = useTranslation()

  const onSelectMenuItem = (option: OptionType, idAccessLevel: IdManagerRightType) => {
    const newItems = selectedSegments.map((item) => {
      if (item.value === option.value) {
        return {
          ...item,
          managerRight: idAccessLevel,
        }
      }
      return item
    })
    setSelectedSegments(newItems)
    onAddSegment(segmentShortMapper(option), idAccessLevel)
  }

  const loadOptions = (inputValue: string, callback: (data: OptionType[]) => void) => {
    getSegments({
      orderByAsc: true,
      search: inputValue,
      model: {
        segmentType: [
          getStateKeyByValue<SegmentTypeType>(SegmentDefType, 'manual'),
          getStateKeyByValue<SegmentTypeType>(SegmentDefType, 'auto'),
          getStateKeyByValue<SegmentTypeType>(SegmentDefType, 'sociodemographic'),
        ],
      },
    })
      .then((data) => {
        const loadOptions: OptionType[] = data.map((segment) => ({
          value: segment.idSegment,
          label: segment.name,
          managerRight: ID_MANAGER_RIGHT[2],
          segmentData: segment,
        }))
        callback(loadOptions)
      })
      .catch(() => {
        callback([])
      })
  }

  return (
    <Row
      css={css`
        width: 100%;
        margin-top: 20px;
      `}
    >
      <Space
        css={css`
          display: grid;
          grid-template-columns: 10fr 1fr;
          gap: 6px;
          margin-bottom: 20px;
          width: 100%;
        `}
      >
        <SelectAsync<OptionType>
          loadOptions={loadOptions}
          valuesSelected={selectedSegments.map(({ label }) => label)}
          value={selectedSegment}
          onChange={setSelectedSegment}
        />
        <Button
          onClick={() => {
            if (selectedSegment !== '') {
              setSelectedSegments([...selectedSegments, selectedSegment])
              setSelectedSegment('')
              onAddSegment(segmentShortMapper(selectedSegment), selectedSegment.managerRight)
            }
          }}
          disabled={!selectedSegment || isLoading}
          type='primary'
        >
          {isLoading ? <LoadingOutlined /> : translate('display.add')}
        </Button>
      </Space>
      {selectedSegments.map((item) => {
        const managerRights: MenuProps['items'] = MENU_ITEMS.map(
          ([idAccessLevel, labelAccessLevel]) => {
            return menuItemRender({
              key: idAccessLevel,
              label: labelAccessLevel,
              onSelect: () => onSelectMenuItem(item, idAccessLevel),
            })
          },
        )

        return (
          <Row
            key={item.value}
            justify={'space-between'}
            align='middle'
            css={css`
              height: 44px;
              width: 100%;
            `}
          >
            <Space
              css={css`
                display: flex;
                gap: 12px;
                align-items: center;
              `}
            >
              <Avatar />
              <Text color={colors.gray600}>{item.label}</Text>
            </Space>
            <div
              css={css`
                display: flex;
                align-items: center;
                gap: 20px;
              `}
            >
              <Dropdown
                menu={{ items: managerRights }}
                trigger={['click']}
                disabled={item.managerRight === ID_MANAGER_RIGHT[1]}
                placement='bottomRight'
              >
                <Space>
                  {UserAccessLevel[item.managerRight]}
                  {item.managerRight !== ID_MANAGER_RIGHT[1] && <Icon icon='ChevronDown' />}
                </Space>
              </Dropdown>
              <div
                onClick={() => {
                  setSelectedSegments(selectedSegments.filter(({ value }) => value !== item.value))
                  onDeleteSegment(item.value)
                }}
              >
                <Icon width={18} height={18} icon='XClose' />
              </div>
            </div>
          </Row>
        )
      })}
    </Row>
  )
}

export default AssignSegmentRight
