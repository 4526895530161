/** @jsxImportSource @emotion/react */

import { Content } from 'common-components'
import { getWirkUrl } from '@definitions/wirk-urls'
import { useParams } from 'react-router-dom'

import { store } from '@/store'

const QuestionRespondentCertification = () => {
  const params = useParams()
  const accessToken = store.use.me().accessToken

  const certificationUserTestQuestionUrl = `${getWirkUrl(
    'Form',
  )}/contribute/certificationUserTest/${params.respondentTestId}/question/${
    params.questionId
  }?token=${accessToken}&tenant=${params.tenant}`
  return (
    <Content>
      <iframe
        src={certificationUserTestQuestionUrl}
        height='800'
        width='100%'
        frameBorder='0'
      ></iframe>
    </Content>
  )
}

export default QuestionRespondentCertification
