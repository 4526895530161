/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { css } from '@emotion/react'
import { CommonFilters, StateCheckbox } from '@components/inputs'
import { ContributorTypeType, UserActivityState } from '@definitions'
import { GetSuppliersPayloadType } from '@api'
import { SelectSegments } from '@views/shared/segment'
import {
  Drawer,
  Button,
  FormItem,
  SelectOption,
  Text,
  SelectInput,
  Title,
  Form,
} from 'common-components'

type FiltersContributorsPropsType = {
  visible: boolean
  onClose?: () => void
  onParamsChange: (params: GetSuppliersPayloadType) => void
}
const FiltersContributors = ({
  visible,
  onClose,
  onParamsChange,
}: FiltersContributorsPropsType) => {
  const { t } = useTranslation()
  const { handleSubmit, control } = useForm<GetSuppliersPayloadType>({
    defaultValues: { model: {} },
  })
  const onSubmit = (data: GetSuppliersPayloadType) => {
    onParamsChange && onParamsChange(data)
    onClose()
    return data
  }

  return (
    <Drawer
      title={
        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <Title preset='tablePageTitle'>{t('common.filter')}</Title>
          <Text>{t('common.apply')}</Text>
        </div>
      }
      placement='right'
      closable={true}
      onClose={onClose}
      closeIcon={false}
      open={visible}
    >
      <Form
        onReset={() => {
          control._reset()
          onSubmit({ model: {} })
        }}
        onFinish={handleSubmit(onSubmit)}
        css={drawerContentStyle}
      >
        <CommonFilters control={control} />

        <Controller
          name={'model.type'}
          control={control}
          render={({ field }) => (
            <FormItem label='Role'>
              <SelectInput
                mode={'tags'}
                value={field.value}
                onChange={field.onChange}
                css={fullWidthStyle}
              >
                {Object.entries(ContributorTypeType).map(([id, label]) => (
                  <SelectOption key={id} value={Number(id)}>
                    {label}
                  </SelectOption>
                ))}
              </SelectInput>
            </FormItem>
          )}
        />
        <Controller
          name={'model.activity'}
          control={control}
          render={({ field }) => (
            <FormItem label={t('common.state')}>
              <StateCheckbox
                values={UserActivityState}
                labelTxKey={'userActivity'}
                preset={'skill'}
                inputProps={{
                  onChange: field.onChange,
                  value: field.value,
                }}
              />
            </FormItem>
          )}
        />
        <Controller
          name={'model.segments'}
          control={control}
          render={({ field }) => (
            <FormItem label={t('common.segments')}>
              <SelectSegments onChange={field.onChange} value={field.value} />
            </FormItem>
          )}
        />

        {/*<FormItem label="Performance">*/}
        {/*	<ProgressBar*/}
        {/*		{...register("model.performance")}*/}
        {/*		min={0}*/}
        {/*		range={true}*/}
        {/*		max={100}*/}
        {/*		tooltipVisible={true}*/}
        {/*		defaultValue={[25, 75]}*/}
        {/*		// @ts-ignore*/}
        {/*		css={css`*/}
        {/*			margin-top: 60px;*/}
        {/*		`}*/}
        {/*	/>*/}
        {/*</FormItem>*/}

        <div css={filterButtonsStyle}>
          <Button type='primary' htmlType='submit'>
            Apply
          </Button>
          <Button htmlType='reset'>Cancel</Button>
        </div>
      </Form>
    </Drawer>
  )
}

const drawerContentStyle = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const fullWidthStyle = css`
  width: 100%;
`

const filterButtonsStyle = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`

export default FiltersContributors
