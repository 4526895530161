/** @jsxImportSource @emotion/react */

import { ActionModal, Text, Row, colors, BG_COLORS } from 'common-components'
import { css } from '@emotion/react'
import { memo, useState } from 'react'
import { useTranslation } from '@hooks'
import { ContributorShortType, ManagerShortType } from '@pages/types'
import { getStateKeyByValue } from '@utils'
import { SegmentDefType } from '@definitions'
import { CreateSegmentPayloadType } from '@api'
import { IconType } from '@types'
import { LoadingOutlined } from '@ant-design/icons'

import { GeographicSegment } from './geographic'
import { CompetenceSegment } from './competence'
import { ManualSegment } from './manual'
import TypeSegmentSelect from './TypeSegmentSelect'

import { store } from '@/store'

const TOTAL_STEPS = 3 as const
const ICON_DEFAULT: IconType = {
  iconName: 'OfficePinPinnerDocument',
  iconColor: BG_COLORS[0],
}

type CreateSegmentModalComponentPropsType = {
  close: () => void
  isOpen: boolean
  onUpdateManagersSegmentMutation: (idSegment: string, managersShort: ManagerShortType[]) => void
  onUpdateContributorsSegmentMutation: (
    idSegment: string,
    contributorsShort: ContributorShortType[],
  ) => void
}

export type SegmentFormType = Partial<CreateSegmentPayloadType>

const CreateSegmentModalComponent = ({
  close,
  isOpen,
  onUpdateManagersSegmentMutation,
  onUpdateContributorsSegmentMutation,
}: CreateSegmentModalComponentPropsType) => {
  const [idSegment, setIdSegment] = useState<string>()

  const updateSocioDemographicSegmentAction = store.use.updateSocioDemographicSegmentAction().call
  const segment = store.use.segments().find((item) => item.idSegment === idSegment)
  const createSegmentAction = store.use.createSegmentAction().call

  const isLoadingCreateSegmentAction = store.use.createSegmentAction().status === 'LOADING'

  const { translate } = useTranslation()

  const [step, setStep] = useState(1)

  const [segmentForm, setSegmentForm] = useState<SegmentFormType>({
    icon: ICON_DEFAULT,
  })

  const isGeographicStep =
    segmentForm?.segmentType === getStateKeyByValue(SegmentDefType, 'sociodemographic') &&
    segment &&
    step > 1
  const isManualStep =
    segmentForm?.segmentType === getStateKeyByValue(SegmentDefType, 'manual') && segment && step > 1
  const isCompetenceStep =
    segmentForm?.segmentType === getStateKeyByValue(SegmentDefType, 'certification') &&
    segment &&
    step > 1

  const isValidatedStep1 = () =>
    !!segmentForm?.segmentType && !!segmentForm?.icon && !!segmentForm?.name

  const onNextStep = async () => {
    if (step === 1) {
      createSegmentAction(
        {
          icon: segmentForm?.icon,
          segmentType: segmentForm?.segmentType,
          name: segmentForm?.name,
        },
        (idSegment) => setIdSegment(idSegment),
      )
    }
    if (step === 3) {
      close()
    }
    setStep((prev) => prev + 1)
  }

  const onPreviousStep = () => {
    if (step === 2) {
      setSegmentForm({
        icon: ICON_DEFAULT,
      })
    }
    if (step === 1) {
      close()
      return
    }
    setStep((prev) => prev - 1)
  }

  return (
    <ActionModal
      key='add-segment-modal'
      maskClosable={false}
      open={isOpen}
      onOk={close}
      onConfirm={close}
      css={css`
        min-width: 700px;
      `}
      title={
        step === 1 && (
          <Row
            css={css`
              flex-direction: column;
              gap: 5px;
            `}
          >
            <Text color={colors.gray400} size='md'>
              {step} {translate('createSegment.outOf')} {TOTAL_STEPS}
            </Text>
            <Text
              css={css`
                font-weight: 500;
              `}
              color={colors.gray900}
              size='xl'
            >
              {translate('createSegment.addSegment')}
            </Text>
          </Row>
        )
      }
      onCancel={close}
      actionButtons={[
        {
          text: translate('createSegment.back'),
          onClick: onPreviousStep,
        },
        {
          type: 'primary',
          disabled: !isValidatedStep1(),
          text: !isLoadingCreateSegmentAction ? (
            translate('createSegment.next')
          ) : (
            <LoadingOutlined />
          ),
          onClick: onNextStep,
        },
      ]}
    >
      {step === 1 && (
        <TypeSegmentSelect segmentForm={segmentForm} setSegmentForm={setSegmentForm} />
      )}
      {isGeographicStep && (
        <GeographicSegment
          step={step}
          segment={segment}
          onUpdateSegmentSocioDemographic={updateSocioDemographicSegmentAction}
          onUpdateManagers={(managersUser) =>
            onUpdateManagersSegmentMutation(segment.idSegment, managersUser)
          }
        />
      )}
      {isCompetenceStep && (
        <CompetenceSegment
          step={step}
          onUpdateManagers={(managersUser) =>
            onUpdateManagersSegmentMutation(segment.idSegment, managersUser)
          }
          segment={segment}
        />
      )}
      {isManualStep && (
        <ManualSegment
          step={step}
          onUpdateContributors={(contributorsUser) =>
            onUpdateContributorsSegmentMutation(segment.idSegment, contributorsUser)
          }
          onUpdateManagers={(managersUser) =>
            onUpdateManagersSegmentMutation(segment.idSegment, managersUser)
          }
          segment={segment}
        />
      )}
    </ActionModal>
  )
}

const CreateSegmentModal = memo(
  CreateSegmentModalComponent,
  (
    oldProps: CreateSegmentModalComponentPropsType,
    newProps: CreateSegmentModalComponentPropsType,
  ) => {
    return JSON.stringify(oldProps) === JSON.stringify(newProps)
  },
)

export default CreateSegmentModal
