import { PresetTag } from '@components/display'
import { TSegmentDefType } from '@definitions/states'
import { useTranslation } from '@hooks/useTranslation'
import { FC } from 'react'
import { IAvailableIcons } from 'common-components'

type StateTagSegmentPropsType = {
  state: TSegmentDefType
}

const StateTagSegment: FC<StateTagSegmentPropsType> = ({ state }) => {
  const { translate } = useTranslation()

  const getTagIcon = (state: TSegmentDefType): IAvailableIcons => {
    switch (state) {
      case 10:
        return 'Hand'
      case 20:
        return 'Bell02'
      case 30:
        return 'CheckVerified01'
      case 40:
        return 'UserCircle'
      default:
        return 'Plus'
    }
  }

  return (
    <PresetTag preset='segment-type' state={state} icon={getTagIcon(state)}>
      {translate(`common.states.segmentState.${state}`)}
    </PresetTag>
  )
}

export default StateTagSegment
