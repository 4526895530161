/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useNavigate } from 'react-router-dom'
import { Col, Content, Row, Title, Avatar, Button, Icon } from 'common-components'
import { Space } from 'antd'
import { UserType } from '@definitions/states'
import { UserDataType } from '@pages/types'

type HeaderContributorPropsType = {
  userContributor: UserDataType
}

const HeaderContributor = function ({ userContributor }: HeaderContributorPropsType) {
  const navigate = useNavigate()
  const texts = [
    {
      type: 'tablePageTitle',
      content: `${userContributor.firstName} ${userContributor.lastName}`,
    },
    {
      type: 'tablePageSubtitle',
      content: `${UserType[userContributor.type]} - ${userContributor.email}`,
    },
  ]

  return (
    <Content>
      <Row
        justify={'space-between'}
        align={'top'}
        css={css`
          margin: 24px 0;
        `}
      >
        <Col>
          <Row justify={'center'} gutter={30} align={'middle'}>
            <Col>
              <Button
                size='sm'
                onClick={() => {
                  navigate(-1)
                }}
              >
                <Icon icon='ArrowNarrowLeft' size={20} />
              </Button>
            </Col>
            <Col>
              <Avatar src={userContributor.urlAvatar} size='xl' />
            </Col>
            <Col>
              {texts.map((text, index) => {
                if (index === 0) {
                  return (
                    <Space align={'center'}>
                      <Title key={`title-${text.content}-${index}`} style={{ fontSize: 18 }}>
                        {text.content}
                      </Title>
                      {/* HIDE
                       * <ContributorTag state="positive">TOP 20%</ContributorTag>
                       * */}
                    </Space>
                  )
                }
                return (
                  <Title key={`title-${text.content}-${index}`} preset={text.type as any}>
                    {text.content}
                  </Title>
                )
              })}
            </Col>
          </Row>
        </Col>
      </Row>
    </Content>
  )
}

export default HeaderContributor
