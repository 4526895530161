/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useNavigate } from 'react-router-dom'
import {
  FormWrapper,
  IFormWrapperProps,
  SelectInput,
  TextInput,
  Paragraph,
  colors,
  FormItem,
  Form,
  FormElements,
  Divider,
  DatePicker,
  Button,
  useDisclosure,
} from 'common-components'
import { TSociodemographicCriteriaValues } from '@definitions'
import { useSociodemographicResources, useTranslation } from '@hooks'
import { UserDataType } from '@pages/types'
import { DefaultOptionType } from 'rc-select/lib/Select'
import { DeleteModal } from '@components/modal'
import { useEffect } from 'react'

import { store } from '@/store'

type InformationContributorPropsType = {
  userContributor: UserDataType
}

const InformationContributor = ({ userContributor }: InformationContributorPropsType) => {
  const updateContributorAction = store.use.updateContributorAction().call
  const deleteContributorsAction = store.use.deleteContributorsAction().call

  const isLoadingUpdateContributorAction = store.use.updateContributorAction().status === 'LOADING'
  const isLoadingDeleteContributorsAction =
    store.use.deleteContributorsAction().status === 'LOADING'

  const [form] = FormElements.useForm<UserDataType>()
  const { translate: t } = useTranslation()
  const navigate = useNavigate()
  const { open, isOpen, close } = useDisclosure()

  const { getResourceOptions } = useSociodemographicResources()

  useEffect(() => {
    Object.keys(userContributor).map((key: keyof UserDataType) => {
      form.setFieldValue(key, userContributor[key])
    })
  }, [JSON.stringify(userContributor)])

  const onUpdateConfirm = async () => {
    const values = form.getFieldsValue()

    updateContributorAction({
      idUser: values.idUser,
      phone: values.phone,
      lastName: values.lastName,
      firstName: values.firstName,
      birthDate: values.birthDate,
      nationality: values.nationality,
      earning: values.earning,
      study: values.study,
      family: values.family,
      children: values.children,
      job: values.job,
      activitySector: values.activitySector,
      address: values.address,
    })
  }

  const deleteUserHandler = async () => {
    deleteContributorsAction(
      {
        idContributors: [form.getFieldValue('idUser')],
      },
      () => {
        navigate(-1)
      },
    )
  }
  const getSelectInputPropsByName = (
    name: keyof UserDataType | string[],
    resourceType: TSociodemographicCriteriaValues,
  ) => {
    return {
      // @ts-ignore
      onChange: (_, option: DefaultOptionType | DefaultOptionType[]) =>
        form.setFieldValue(name, option),
      value: form.getFieldValue(name)?.value?.value || null,
      options: getResourceOptions(resourceType),
    }
  }

  const formData3: IFormWrapperProps = {
    title: 'Projets blacklistés',
    description: 'This will be displayed on your profile.',
    button: {
      size: 'md',
      children: 'Enregistrer les modifications',
      loading: isLoadingUpdateContributorAction,
      disabled: isLoadingUpdateContributorAction,
      type: 'default',
      onClick: onUpdateConfirm,
    },
    table: {
      data: form.getFieldValue('blacklistedProject')?.map(() => {
        return {
          key: 'NO INFO FROM BE',
          name: 'NO INFO FROM BE',
          projectImg: 'NO INFO FROM BE',
          tasks: 'NO INFO FROM BE',
          averageQuality: 'NO INFO FROM BE',
          origin: 'NO INFO FROM BE',
          exclusionDate: 'NO INFO FROM BE',
          actions: 'NO INFO FROM BE',
        }
      }),
      columns: [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Tasks',
          dataIndex: 'tasks',
          key: 'tasks',
        },
        {
          title: 'Average Quality',
          dataIndex: 'averageQuality',
          key: 'averageQuality',
        },
        {
          title: 'Origin',
          dataIndex: 'origin',
          key: 'origin',
        },
        {
          title: 'Exclusion Date',
          dataIndex: 'exclusionDate',
          key: 'exclusionDate',
        },
        {
          title: 'Actions',
          dataIndex: 'actions',
          key: 'actions',
        },
      ],
    },
    lineBottom: true,
  }

  return (
    <div
      css={css`
        margin-top: 40px;
      `}
    >
      <div
        css={css`
          display: grid;
          grid-template-columns: 1fr 2fr;
          gap: 1rem;
        `}
      >
        <div
          css={css`
            padding: 5px;
          `}
        >
          <Paragraph
            preset={'formTitle'}
            css={css`
              margin-bottom: 5px !important;
            `}
          >
            Général
          </Paragraph>
          <Paragraph
            css={css`
              margin-bottom: 0 !important;
            `}
          >
            This will be displayed on your profile.
          </Paragraph>
        </div>
        <Form form={form}>
          <FormItem name={'idUser'} label={'ID'}>
            <TextInput disabled />
          </FormItem>
          <div
            css={css`
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              gap: 1rem;
            `}
          >
            <FormItem name={'email'} label={'Email'}>
              <TextInput disabled />
            </FormItem>
            <FormItem name={'phone'} label={'Téléphone'}>
              <TextInput />
            </FormItem>
            <FormItem name={'firstName'} label={'Nom'}>
              <TextInput />
            </FormItem>
            <FormItem name={'lastName'} label={'Prénom'}>
              <TextInput />
            </FormItem>
            <FormItem label={'Date de naissance'} name={'birthDate'}>
              <DatePicker format={['DD/MM/YY']} />
            </FormItem>
            <FormItem name={'nationality'} label={'Nationalité'}>
              <SelectInput {...getSelectInputPropsByName('nationality', 'country')} />
            </FormItem>
          </div>
        </Form>
      </div>
      <Divider
        css={css`
          color: ${colors.gray200};
          width: 100%;
        `}
      />
      <div
        css={css`
          display: grid;
          grid-template-columns: 1fr 2fr;
          gap: 1rem;
        `}
      >
        <div
          css={css`
            padding: 5px;
          `}
        >
          <Paragraph
            preset={'formTitle'}
            css={css`
              margin-bottom: 5px !important;
            `}
          >
            Socio démographique
          </Paragraph>
          <Paragraph
            css={css`
              margin-bottom: 0 !important;
            `}
          >
            This will be displayed on your profile.
          </Paragraph>
        </div>
        <Form form={form}>
          <div
            css={css`
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              gap: 1rem;
            `}
          >
            <FormItem name={'earning'} label={'Niveau de revenu'}>
              <SelectInput {...getSelectInputPropsByName('earning', 'earnings')} />
            </FormItem>
            <FormItem name={'study'} label={'Etudes'}>
              <SelectInput {...getSelectInputPropsByName('study', 'education')} />
            </FormItem>
            <FormItem name={'family'} label={'Situation familiale'}>
              <SelectInput {...getSelectInputPropsByName('family', 'maritalStatus')} />
            </FormItem>
            <FormItem name={'children'} label={'Nombre d’enfant'}>
              <SelectInput {...getSelectInputPropsByName('children', 'children')} />
            </FormItem>
            <FormItem name={'job'} label={'Profession'}>
              <SelectInput {...getSelectInputPropsByName('job', 'profession')} />
            </FormItem>
            <FormItem name={'activitySector'} label={'Secteur d’activité'}>
              <SelectInput {...getSelectInputPropsByName('activitySector', 'activity')} />
            </FormItem>
          </div>
        </Form>
      </div>
      <Divider
        css={css`
          color: ${colors.gray200};
          width: 100%;
        `}
      />
      <div
        css={css`
          display: grid;
          grid-template-columns: 1fr 2fr;
          gap: 1rem;
        `}
      >
        <div
          css={css`
            padding: 5px;
          `}
        >
          <Paragraph
            preset={'formTitle'}
            css={css`
              margin-bottom: 5px !important;
            `}
          >
            Socio démographique
          </Paragraph>
          <Paragraph
            css={css`
              margin-bottom: 0 !important;
            `}
          >
            This will be displayed on your profile.
          </Paragraph>
        </div>
        <Form form={form}>
          <FormItem name={['address', 'path']} label={'Addresse'}>
            <TextInput />
          </FormItem>
          <div
            css={css`
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              gap: 1rem;
            `}
          >
            <FormItem name={['address', 'zipCode']} label={'Code Postal'}>
              <TextInput />
            </FormItem>
            <FormItem name={['address', 'city']} label={'Ville'}>
              <TextInput />
            </FormItem>
            <FormItem name={['address', 'region']} label={'Region'}>
              <SelectInput {...getSelectInputPropsByName(['address', 'region'], 'region')} />
            </FormItem>
            <FormItem name={['address', 'country']} label={'Pays'}>
              <SelectInput {...getSelectInputPropsByName(['address', 'country'], 'country')} />
            </FormItem>
          </div>
        </Form>
      </div>
      <FormWrapper {...formData3} lineBottom={false} />
      <div
        css={css`
          border: 1px solid ${colors.gray200};
          padding: 24px;
          margin: 20px 0;
          border-radius: 12px;
        `}
      >
        <Paragraph preset='panelCard'>Supprimer le contributeur</Paragraph>
        <Button onClick={() => open()} type='dangerFilled' iconLeft='Trash01'>
          {t(`contributor.delete.button`)}
        </Button>
        <DeleteModal
          close={close}
          isOpen={isOpen}
          isLoading={isLoadingDeleteContributorsAction}
          title={t(`contributor.delete.modal.title`)}
          label={t(`contributor.delete.modal.label`)}
          onDeleteMutation={deleteUserHandler}
        />
      </div>
    </div>
  )
}

export default InformationContributor
