/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { Col, Row, Title, Avatar } from 'common-components'
import { Space } from 'antd'
import { TSegmentDefType } from '@definitions'
import { StateTagSegment } from '@views/segment/shared'

type HeaderPropsType = {
  type: TSegmentDefType
  name: string
}
export const HeaderSegment = function ({ type, name }: HeaderPropsType) {
  const texts = [
    {
      type: type,
      content: name,
    },
    {
      type: type,
    },
  ]

  return (
    <div>
      <Row
        justify={'space-between'}
        align={'top'}
        css={css`
          margin: 24px 0;
        `}
      >
        <Col>
          <Row justify={'center'} gutter={16} align={'middle'}>
            <Col>
              <Avatar size='xl' />
            </Col>
            <Col>
              {texts.map((text, index) => {
                if (index === 0) {
                  return (
                    <Space align={'center'}>
                      <Title key={`title-${text.content}-${index}`} style={{ fontSize: 18 }}>
                        {text.content}
                      </Title>
                    </Space>
                  )
                }
                return (
                  <div
                    css={css`
                      margin-top: 2px;
                    `}
                  >
                    <StateTagSegment state={text.type} />
                  </div>
                )
              })}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}
