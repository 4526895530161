import { axiosClient } from '@definitions/axios-client'
import { RiskApiType, RiskPageURLItemApiType, RiskSettingsApiType } from '@api/types'
import { AxiosResponse } from 'axios'
import { FilterPayloadType } from '@types'
import { catchApiError, downloadBlob } from '@utils'

export type GetRisksPayloadType = FilterPayloadType & {
  model: {
    state?: number[]
    level?: number
    score?: number
  }
}

export const getRisks = async (filters: GetRisksPayloadType): Promise<RiskApiType[]> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<RiskApiType[]> = await axios.post(
      '/RiskService/Risk/GetRisks',
      filters,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export const getRisk = async (id: string): Promise<RiskApiType> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<RiskApiType> = await axios.get(`/RiskService/Risk/GetRisk/${id}`)
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export const getRiskPageURLs = async (id: string): Promise<RiskPageURLItemApiType[]> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<RiskPageURLItemApiType[]> = await axios.get(
      `/RiskService/Risk/GetRisk/${id}/Pages`,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export const deleteRisk = async (id: string): Promise<void> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<void> = await axios.delete(`/RiskService/Risk/DeleteRisk/${id}`)
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export const uploadRiskFile = async (file: File) => {
  try {
    const axios = axiosClient({
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    const formData = new FormData()
    formData.append('files', file)

    const resp: AxiosResponse<void> = await axios.post(`/RiskService/Risk/CreateRisk`, formData)
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export const getRiskSettings = async (): Promise<RiskSettingsApiType> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<RiskSettingsApiType> = await axios.get('/RiskService/Risk/Settings')
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export const updateRiskSettings = async (
  data: RiskSettingsApiType,
): Promise<RiskSettingsApiType> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<RiskSettingsApiType> = await axios.patch(
      '/RiskService/Risk/Settings',
      data,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export const downloadRisk = async ({ id, fileName }: { id: string; fileName: string }) => {
  try {
    const axios = axiosClient()
    const response: AxiosResponse<Blob> = await axios.post(
      `/RiskService/Risk/GetFile/${id}/Download`,
      {},
      {
        responseType: 'blob',
      },
    )

    downloadBlob([response.data], fileName)
  } catch (e) {
    catchApiError(e)
  }
}

// TODO remove fileName ?
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const downloadRiskReport = async ({ id, fileName }: { id: string; fileName: string }) => {
  try {
    const axios = axiosClient()
    const response: AxiosResponse<Blob> = await axios.get(
      `/RiskService/Risk/GetRisk/${id}/Download`,
      {
        responseType: 'blob',
      },
    )
    const now: Date = new Date()
    const fileName = `Wirk_Risk_Export_${now.getFullYear()}/${
      now.getMonth() + 1
    }/${now.getDate()}_${now.getHours()}_${now.getMinutes()}_${now.getSeconds()}.json`

    downloadBlob([response.data], fileName)
  } catch (e) {
    catchApiError(e)
  }
}
