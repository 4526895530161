/** @jsxImportSource @emotion/react */
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { css } from '@emotion/react'
import { useTranslation } from '@hooks'
import {
  LABEL_STATE_CERTIFICATION_TYPE,
  STATE_CERTIFICATION_TYPE,
  UserAccessLevel,
} from '@definitions'
import { CommonFilters, StateCheckbox } from '@components/inputs'
import { GetCertificationsPayloadType } from '@api'
import { Button, Drawer, Form, FormItem, Title, Text } from 'common-components'
import { SelectManagers } from '@views/shared/manager'

type FiltersCertificationPropsType = {
  visible: boolean
  onFiltersChange?: (filters: GetCertificationsPayloadType) => void
  onClose?: () => void
}

const STATE_CERTIFICATION_FILTER = {
  [STATE_CERTIFICATION_TYPE[0]]: LABEL_STATE_CERTIFICATION_TYPE[0],
  [STATE_CERTIFICATION_TYPE[4]]: LABEL_STATE_CERTIFICATION_TYPE[4],
  [STATE_CERTIFICATION_TYPE[5]]: LABEL_STATE_CERTIFICATION_TYPE[5],
  [STATE_CERTIFICATION_TYPE[6]]: LABEL_STATE_CERTIFICATION_TYPE[6],
}

const FiltersCertification = ({
  visible,
  onClose,
  onFiltersChange,
}: FiltersCertificationPropsType) => {
  const { translate } = useTranslation()
  const { control, handleSubmit } = useForm<GetCertificationsPayloadType>({
    defaultValues: { model: {} },
  })

  const onSubmit = (data: GetCertificationsPayloadType) => {
    onFiltersChange && onFiltersChange(data)
    onClose && onClose()
  }

  const toggleAllDraftValues = (values: any[]) => {
    // hack to group 4 draft states of certification into 1 checkbox input
    const valuesToSet = [...values]
    if (valuesToSet.includes(1)) {
      valuesToSet.push(2, 3, 4)
    } else {
      valuesToSet.indexOf(2) >= 0 && valuesToSet.splice(valuesToSet.indexOf(2), 1)
      valuesToSet.indexOf(3) >= 0 && valuesToSet.splice(valuesToSet.indexOf(3), 1)
      valuesToSet.indexOf(4) >= 0 && valuesToSet.splice(valuesToSet.indexOf(4), 1)
    }
    return valuesToSet
  }

  return (
    <Drawer
      title={
        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <Title preset='tablePageTitle'>{translate('common.filter')}</Title>
          <Text>{translate('common.apply')}</Text>
        </div>
      }
      placement='right'
      closable={true}
      onClose={onClose}
      closeIcon={false}
      open={visible}
    >
      <Form
        onReset={() => {
          control._reset()
          onSubmit({ model: {} })
        }}
        onFinish={handleSubmit(onSubmit)}
        css={$form}
      >
        <CommonFilters control={control} />

        <Controller
          control={control}
          render={({ field }) => (
            <FormItem label={translate('common.role')}>
              <StateCheckbox
                inputProps={{
                  onChange: field.onChange,
                  value: field.value,
                }}
                values={UserAccessLevel}
                labelTxKey={'userAccessLevel'}
                preset={'user-access'}
              />
            </FormItem>
          )}
          name={'model.role'}
        />

        <Controller
          control={control}
          render={({ field }) => (
            <FormItem label={translate('common.state')}>
              <StateCheckbox
                inputProps={{
                  onChange: (values) => field.onChange(toggleAllDraftValues(values)),
                  value: field.value,
                }}
                values={STATE_CERTIFICATION_FILTER}
                labelTxKey={'certificationState'}
                preset={'certification-state'}
              />
            </FormItem>
          )}
          name={'model.state'}
        />
        <Controller
          control={control}
          render={({ field }) => (
            <FormItem label={translate('common.managers')}>
              <SelectManagers onChange={field.onChange} value={field.value} />
            </FormItem>
          )}
          name={'model.managers'}
        />

        <div css={filterButtonsStyle}>
          <Button type='primary' htmlType='submit'>
            Apply
          </Button>
          <Button htmlType='reset'>Cancel</Button>
        </div>
      </Form>
    </Drawer>
  )
}

const $form = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const filterButtonsStyle = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`

export default FiltersCertification
