/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useState, useRef, useEffect } from 'react'
import { Form, FormItem, Row, Text, colors, Col, Icon, TextInput } from 'common-components'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from '@hooks'
import { AudienceStat } from '@components/display'
import { SelectRegular, RegularSelectPropsType } from '@components/inputs'
import { SkillType, TSkillType } from '@definitions/states'
import { UpdateSegmentSkillPayloadType } from '@api/segment'
import { SegmentType } from '@pages/types'
import { AssignManager } from '@views/shared/manager'

import { HeaderSegment } from '../HeaderSegment'

import { store } from '@/store'

const type = 30

type CompetencePropsTyp = {
  step: number
  segment: SegmentType
  onUpdateManagers: (managersSegment: SegmentType['managersShort']) => void
}

const CompetenceSegment = ({ step, segment, onUpdateManagers }: CompetencePropsTyp) => {
  const updateSkillSegmentAction = store.use.updateSkillSegmentAction().call

  const { translate } = useTranslation()

  const [selectValue, setSelectValue] = useState<string>('')
  const [valuesSelected, setValuesSelected] = useState<RegularSelectPropsType['options']>([])

  const formDataRef = useRef<UpdateSegmentSkillPayloadType>(null)
  const { watch, control } =
    useForm<Pick<UpdateSegmentSkillPayloadType, 'percentageForValidation' | 'qualityMin'>>()

  const submitData = watch()

  useEffect(() => {
    formDataRef.current = {
      ...submitData,
      idSegment: segment.idSegment,
      skills: valuesSelected.map((elem) => Number(elem.value) as TSkillType),
    }
  }, [submitData])

  useEffect(() => {
    if (step === 3) {
      if (!formDataRef.current.idSegment || formDataRef.current.skills.length === 0) {
        return
      }
      updateSkillSegmentAction(formDataRef.current)
    }
  }, [step])

  if (step === 3) {
    return (
      <>
        <HeaderSegment type={type} name={segment.name} />
        <Row justify={'space-between'} align={'middle'}>
          <Col
            css={css`
              display: flex;
              flex-direction: column;
            `}
          >
            <Text color={colors.gray400} size='md'>
              3 {translate('createSegment.outOf')} 3
            </Text>
            <Text
              css={css`
                font-weight: 500;
              `}
              color={colors.gray900}
              size='xl'
            >
              {translate('createSegment.chooseManagers')}
            </Text>
          </Col>
        </Row>
        <AssignManager managersUser={segment.managersShort} onUpdateManagers={onUpdateManagers} />
      </>
    )
  }

  return (
    <div>
      <HeaderSegment type={type} name={segment.name} />
      <Row
        css={css`
          flex-direction: column;
        `}
      >
        <Text color={colors.gray400} size='md'>
          {step} {translate('createSegment.outOf')} 3
        </Text>
        <Text
          css={css`
            font-weight: 500;
          `}
          color={colors.gray900}
          size='xl'
        >
          {translate('createSegment.skillCriterion')}
        </Text>
      </Row>
      <Form
        css={css`
          margin-top: 10px;
        `}
      >
        <FormItem>
          <SelectRegular
            options={Object.entries(SkillType)
              .map(([value, label]) => ({
                value,
                label,
              }))
              .filter((elem) => !valuesSelected.some((el) => el.value === elem.value))}
            setValue={setSelectValue}
            value={selectValue}
            valuesSelected={valuesSelected}
            setValuesSelected={setValuesSelected}
          />
          {valuesSelected.map((elem) => {
            return (
              <Row
                justify={'space-between'}
                align='middle'
                css={css`
                  height: 44px;
                  width: 100%;
                `}
                key={elem.value}
              >
                <Text color={colors.gray600}>{elem.label}</Text>
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    gap: 20px;
                  `}
                >
                  <div
                    onClick={() => {
                      const newArr = valuesSelected.filter((el) => el.value !== elem.value)
                      setValuesSelected(newArr)
                    }}
                  >
                    <Icon width={18} height={18} icon='XClose' />
                  </div>
                </div>
              </Row>
            )
          })}
        </FormItem>
        <div
          css={css`
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 15px;
          `}
        >
          <Controller
            name={'percentageForValidation'}
            control={control}
            render={({ field }) => {
              return (
                <FormItem {...field} label='Nombre minimum de traitements évalués'>
                  <TextInput placeholder='35' />
                </FormItem>
              )
            }}
          />

          <Controller
            name={'qualityMin'}
            control={control}
            render={({ field }) => {
              return (
                <FormItem {...field} label='Qualité minimum'>
                  <TextInput placeholder='80%' />
                </FormItem>
              )
            }}
          />
        </div>
      </Form>
      <AudienceStat />
    </div>
  )
}

export default CompetenceSegment
