/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { IText } from '@types'
import { PropsWithChildren } from 'react'
import { Row, Col, Space, Content, colors, Text, Tag } from 'common-components'

type SubHeaderContributorPropsType = {
  count: number
  texts: IText[]
}

const SubHeaderContributor = ({
  count,
  texts,
  children,
}: PropsWithChildren<SubHeaderContributorPropsType>) => {
  return (
    <Content>
      <Row
        justify={'space-between'}
        align={'top'}
        css={css`
          border: 1px solid ${colors.gray200};
          border-radius: 12px;
          padding: 24px 26px;
          margin-top: 20px;
          align-items: center;
        `}
      >
        <Col>
          {texts.map((text) => (
            <div
              css={css`
                display: flex;
                gap: 10px;
                align-items: center;
              `}
            >
              <Text
                css={css`
                  font-size: 18px;
                  line-height: 28px;
                  font-weight: 700;
                `}
              >
                {text.content}
              </Text>
              <Tag
                css={css`
                  padding: 5px 10px;
                  height: 22px;
                `}
                color={colors.gray700}
              >
                {count}
              </Tag>
            </div>
          ))}
        </Col>
        <Row>
          <Space>{children}</Space>
        </Row>
      </Row>
    </Content>
  )
}

export default SubHeaderContributor
