/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useTranslation } from '@hooks/useTranslation'
import { RiskType } from '@pages/types'
import { useMemo, useState } from 'react'
import { ColumnsType } from 'antd/lib/table'
import { ItemType } from 'antd/es/menu/hooks/useItems'
import { PresetTag } from '@components/display'
import { TxKeyPath } from '@i18n/i18n'
import { Upload } from 'antd'
import { UploadProps } from 'antd/lib'
import {
  deleteRisk,
  downloadRisk,
  downloadRiskReport,
  GetRisksPayloadType,
  uploadRiskFile,
} from '@api/risk'
import { useMutation, useQueryClient } from 'react-query'
import { getRiskLevelValueKey } from '@utils/getRiskLevelValueKey'
import { RiskState, TRiskState } from '@definitions/states'
import { useNavigate } from 'react-router'
import { routes } from '@navigation/routes'
import { getStateKeyByValue } from '@utils'
import {
  Button,
  Col,
  Content,
  Dropdown,
  ITableProps,
  Icon,
  Row,
  Space,
  Table,
  Text,
  Title,
  useNotification,
} from 'common-components'

import { RiskSensitivityConfig } from '../../shared/risk'

import FiltersRisk from './FiltersRisk'

type ListRiskPropsType = {
  risks: RiskType[]
  tableProps?: Omit<ITableProps<RiskType>, 'data' | 'columns'>
  onFiltersChange?: (params: GetRisksPayloadType) => void
}

const ListRisk = ({ risks, tableProps = {}, onFiltersChange }: ListRiskPropsType) => {
  const { translate } = useTranslation()
  const client = useQueryClient()
  const navigate = useNavigate()

  const [isFiltersVisible, setFiltersVisible] = useState(false)
  const { showNotification, contextHolder } = useNotification()

  const { mutate: deleteRiskReq } = useMutation({
    mutationFn: deleteRisk,
    onSuccess: () => {
      client.invalidateQueries()
    },
  })

  const { mutate: downloadRiskReq } = useMutation({
    mutationFn: downloadRisk,
  })

  const { mutate: downloadRiskReportMutation } = useMutation({
    mutationFn: downloadRiskReport,
  })

  const toHideFields = (state: TRiskState) =>
    RiskState[state] === 'new' || RiskState[state] === 'inProgress' || RiskState[state] === 'error'

  const uploadProps: UploadProps = {
    customRequest: async (options) => {
      try {
        await uploadRiskFile(options.file as File)
        showNotification({
          notificationType: 'success',
          message: translate('notifications.success'),
        })
        client.invalidateQueries()
      } catch {
        showNotification({
          notificationType: 'error',
          message: translate('notifications.error'),
        })
      }
    },
  }

  const getDropdownItems = (risk: RiskType): ItemType[] => {
    return [
      {
        key: 1,
        label: <Text>{translate('risk.dropdown.details')}</Text>,
        onClick: () => navigate(routes.riskOverview.navigate(risk.idRisk)),
        disabled: risk?.state !== getStateKeyByValue(RiskState, 'done'),
      },
      {
        key: 2,
        label: <Text>{translate('risk.dropdown.download')}</Text>,
        onClick: () => downloadRiskReq({ id: risk.idRisk, fileName: risk.fileName }),
      },
      {
        key: 3,
        label: <Text>{translate('risk.dropdown.downloadReport')}</Text>,
        onClick: () => downloadRiskReportMutation({ id: risk.idRisk, fileName: risk.fileName }),
      },
      {
        key: 4,
        label: <Text>{translate('risk.dropdown.delete')}</Text>,
        onClick: () => deleteRiskReq(risk.idRisk),
      },
    ]
  }

  const tableColumns: ColumnsType<RiskType> = useMemo(() => {
    try {
      return [
        {
          title: translate('risk.table.name'),
          key: 'fileName',
          dataIndex: 'fileName',
          width: '60%',
          sorter: (a, b) => {
            if (a.fileName < b.fileName) return -1
            if (a.fileName > b.fileName) return 1
            return 0
          },
          render: (name) => (
            <Row align={'middle'}>
              <Space size={'middle'}>
                <Text size='xs' strong>
                  {name}
                </Text>
              </Space>
            </Row>
          ),
        },
        {
          title: translate('risk.table.risk'),
          key: 'level',
          width: '10%',
          sorter: (a, b) => {
            if (a.level < b.level) return -1
            if (a.level > b.level) return 1
            return 0
          },
          render: ({ level, state }) => (
            <Row align={'middle'}>
              <Space size={'middle'}>
                {toHideFields(state) ? (
                  <Text size='xs' strong>
                    -
                  </Text>
                ) : (
                  <PresetTag preset='risk-level' state={level}>
                    {translate(`risk.levelStates.${getRiskLevelValueKey(level)}` as TxKeyPath)}
                  </PresetTag>
                )}
              </Space>
            </Row>
          ),
        },
        {
          title: translate('risk.table.score'),
          key: 'score',
          width: '10%',
          sorter: (a, b) => {
            if (a.score < b.score) return -1
            if (a.score > b.score) return 1
            return 0
          },
          render: ({ score, state }) => (
            <Row align={'middle'}>
              <Space size={'middle'}>
                <Text size='xs' strong>
                  {toHideFields(state) ? '-' : `${score}%`}
                </Text>
              </Space>
            </Row>
          ),
        },
        {
          title: translate('risk.table.status'),
          key: 'state',
          dataIndex: 'state',
          width: '10%',
          sorter: (a, b) => {
            if (a.state < b.state) return -1
            if (a.state > b.state) return 1
            return 0
          },
          render: (state) => (
            <Row align={'middle'}>
              <Space size={'middle'}>
                <PresetTag withDot preset='risk-state' state={state}>
                  {translate(`common.states.riskState.${state}` as TxKeyPath)}
                </PresetTag>
              </Space>
            </Row>
          ),
        },
        {
          title: '',
          key: 'actions',
          dataIndex: 'idRisk',
          width: '10%',
          render: (_, cell) => (
            <Row align={'middle'} justify={'center'}>
              <Dropdown
                menu={{ items: getDropdownItems(cell) }}
                placement={'bottomRight'}
                overlayStyle={{ width: 200 }}
              >
                <Icon icon={'DotsVertical'} size={20} />
              </Dropdown>
            </Row>
          ),
        },
      ]
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
      return []
    }
  }, [])

  return (
    <Content>
      {contextHolder}
      <Row
        justify={'space-between'}
        align={'top'}
        css={css`
          margin: 24px 0;
        `}
      >
        <Col>
          <Title preset={'tablePageTitle'}>{translate('risk.title')}</Title>
          <Title preset={'tablePageSubtitle'}>{translate('risk.subtitle')}</Title>
        </Col>
        <Row>
          <Space>
            <Button onClick={() => setFiltersVisible(true)} iconLeft='FilterLines'>
              {translate('common.filter')}
            </Button>

            <FiltersRisk
              visible={isFiltersVisible}
              onClose={() => setFiltersVisible(false)}
              onFiltersChange={onFiltersChange}
            />

            <RiskSensitivityConfig editable />

            <Upload
              {...uploadProps}
              accept='image/png, image/jpeg, image/jpg, .pdf'
              showUploadList={false}
              multiple
            >
              <Button type='primary' iconLeft='Plus'>
                {translate('risk.downloadDocuments')}
              </Button>
            </Upload>
          </Space>
        </Row>
      </Row>
      <Table<RiskType> data={risks} columns={tableColumns} {...tableProps} />
    </Content>
  )
}

export default ListRisk
