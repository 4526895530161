const en = {
  numberOfProjects: 'Number of projects',
  treatedTasks: 'Treated tasks',
  averageQuality: 'Average segment quality',
  contributors: 'Contributors',
  activeProjects: 'Active projects',
  managers: 'Managers',
  created: 'Created on',
  by: 'by',
  usersTableColumns: {
    name: 'Name',
    role: 'Role',
    time: 'Effective time',
    status: 'Status',
  },
  projectsTableColumns: {
    name: 'Name',
    tasks: 'Tasks performed',
  },
  managersTableColumns: {
    name: 'Name',
    rights: 'Rights',
  },
  updateModal: {
    title: 'Modify segment',
    subtitle: 'Create a contributor segment',
  },
  contributorsTab: {
    label: 'Contributors',
    button: 'Add Contributor',
  },
  managersTab: {
    label: 'Manageurs',
    button: 'Add Manager',
  },
}

export default en
export type Translations = typeof en
