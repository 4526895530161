import { GetSuppliersPayloadType } from '@api/user'
import { useEffect } from 'react'
import { useDelayedEffect, useRequestParams } from '@hooks'
import { ContributorType } from '@pages/types'

import { ListContributor } from '@/views/contributor'
import { store } from '@/store'

const ContributorsPage = () => {
  const contributors = store.use.contributors()
  const isLoading = store.use.getContributorsAction().status === 'LOADING'
  const getContributorsAction = store.use.getContributorsAction().call

  const { afterPageChange, setParams, reqParams, pagination, onTableStateChange } =
    useRequestParams<GetSuppliersPayloadType, ContributorType>()

  useDelayedEffect(() => {
    getContributorsAction(reqParams)
  }, [JSON.stringify(reqParams)])

  useEffect(() => {
    afterPageChange(contributors.length)
  }, [contributors.length])

  return (
    <ListContributor
      contributorsIsLoading={isLoading}
      onParamsChange={setParams}
      tableProps={{ pagination, onChange: onTableStateChange }}
      contributors={contributors}
    />
  )
}

export default ContributorsPage
