/** @jsxImportSource @emotion/react */

import { useNavigate } from 'react-router-dom'
import { css } from '@emotion/react'
import DateUtils from '@helpers/date'
import { useTranslation } from '@hooks'
import { routes } from '@navigation/routes'
import { useState } from 'react'
import { SegmentType } from '@pages/types'
import { getStateKeyByValue } from '@utils'
import { SegmentDefType } from '@definitions'
import { DeleteModal } from '@components/modal'
import { StateTagSegment } from '@views/segment/shared'
import {
  Button,
  Col,
  Content,
  Icon,
  Row,
  Space,
  Title,
  Text,
  ActionModal,
  useDisclosure,
  FormItem,
  IconSearch,
  TextInput,
  BG_COLORS,
  Form,
  AvatarIcon,
  AvatarIcons,
  IconData,
} from 'common-components'

import { store } from '@/store'

type HeaderPropsType = {
  segment: SegmentType
}

export const HeaderSegment = ({ segment }: HeaderPropsType) => {
  const updateSegmentAction = store.use.updateSegmentAction().call
  const deleteSegmentsAction = store.use.deleteSegmentsAction().call

  const isLoadingDeleteSegmentsAction = store.use.deleteSegmentsAction().status === 'LOADING'

  const { translate } = useTranslation()
  const navigate = useNavigate()
  const useDisclosureDeleteSegmentsModal = useDisclosure()

  const [segmentName, setSegmentName] = useState<string>(segment.name)
  const [iconData, setIconData] = useState<IconData | null>(segment.icon as IconData)

  const {
    open: openUpdateModal,
    isOpen: isOpenUpdateModal,
    close: closeUpdateModal,
  } = useDisclosure()

  const handleCancel = () => closeUpdateModal()

  const handleConfirm = () => handleCancel()

  const texts = [
    {
      type: 'tablePageTitle',
      content: segment.name,
    },
    {
      type: 'tablePageSubtitle',
      content: `${translate('segment.created')} ${DateUtils.formatDate(
        segment.creationDate,
      )} ${translate('segment.by')} ${segment.idUser}`,
    },
  ]

  function getActionButtonsForAddSegment() {
    return [
      {
        text: translate('createSegment.back'),
        onClick: closeUpdateModal,
      },
      {
        type: 'primary',
        disabled: !iconData || !segmentName,
        text: translate('createSegment.next'),
        onClick: () =>
          updateSegmentAction(
            {
              idSegment: segment.idSegment,
              icon: iconData,
              name: segmentName,
            },
            () => closeUpdateModal(),
          ),
      },
    ]
  }

  const onDeleteSegmentActionMutation = async () => {
    deleteSegmentsAction(
      {
        idSegments: [segment.idSegment],
      },
      () => {
        setTimeout(() => useDisclosureDeleteSegmentsModal.close(), 200)
        navigate(routes.segments.navigate())
      },
    )
  }

  return (
    <Content>
      <Row
        justify={'space-between'}
        align={'top'}
        css={css`
          margin: 24px 0;
        `}
      >
        <Col>
          <Row justify={'center'} gutter={30} align={'middle'}>
            <Col>
              <Button
                size='sm'
                onClick={() => {
                  navigate(-1)
                }}
              >
                <Icon icon='FlipBackward' />
              </Button>
            </Col>
            <AvatarIcon
              icon={segment?.icon?.iconName as AvatarIcons}
              style={{ backgroundColor: segment?.icon?.iconColor }}
              size={'xl'}
            />
            <Col>
              {texts.map((text, index) => {
                return (
                  <Title key={`title-${text.content}-${index}`} preset={text.type as any}>
                    {text.content}
                  </Title>
                )
              })}
            </Col>
            <Col>
              <StateTagSegment state={segment.segmentType} />
            </Col>
          </Row>
        </Col>
        <Row>
          <Space>
            {segment?.segmentType !== getStateKeyByValue(SegmentDefType, 'certification') && (
              <Button
                onClick={useDisclosureDeleteSegmentsModal.open}
                type='danger'
                iconLeft='Trash02'
              >
                {translate('segments.deleteSegment.button')}
              </Button>
            )}

            <Button danger={false} onClick={openUpdateModal} iconLeft='Edit02'>
              {translate('segments.headerButtons.modify')}
            </Button>
          </Space>
          <ActionModal
            open={isOpenUpdateModal}
            onOk={handleConfirm}
            onConfirm={handleConfirm}
            css={css`
              min-width: 700px;
            `}
            title={translate('segment.updateModal.title')}
            onCancel={handleCancel}
            actionButtons={getActionButtonsForAddSegment()}
          >
            <Text size='sm'>{translate('segment.updateModal.subtitle')}</Text>
            <Form
              css={css`
                margin-top: 20px;
              `}
            >
              <Row
                css={css`
                  gap: 15px;
                `}
              >
                <FormItem label={translate('createSegment.icon')}>
                  <IconSearch value={iconData} bgColors={BG_COLORS} onSubmit={setIconData} />
                </FormItem>
                <FormItem
                  css={css`
                    flex-grow: 1;
                  `}
                  label={translate('createSegment.name')}
                >
                  <TextInput
                    value={segmentName}
                    onChange={(e) => setSegmentName(e.currentTarget.value)}
                    placeholder={translate('createSegment.exampleEnglishLevel1')}
                  />
                </FormItem>
              </Row>
            </Form>
          </ActionModal>
          {useDisclosureDeleteSegmentsModal.isOpen && (
            <DeleteModal
              close={useDisclosureDeleteSegmentsModal.close}
              isOpen={useDisclosureDeleteSegmentsModal.isOpen}
              title={translate('segments.deleteSegment.modal.title')}
              label={translate('segments.deleteSegment.modal.description')}
              isLoading={isLoadingDeleteSegmentsAction}
              onDeleteMutation={onDeleteSegmentActionMutation}
            />
          )}
        </Row>
      </Row>
    </Content>
  )
}
