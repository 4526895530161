/** @jsxImportSource @emotion/react */

import React, { memo } from 'react'
import { useTranslation } from '@hooks'
import styled from '@emotion/styled'
import { TaskStateType } from '@definitions'
import { PresetTag } from '@components/display'
import { Collapse, colors, Panel, Row, Space, Text } from 'common-components'

import { store } from '@/store'

const DataRow = styled(Row)`
  justify-content: space-between;
  align-items: flex-end;
`

const DataLabel = styled(Text)`
  font-size: 14px;
  font-weight: 500;
  color: ${colors.gray700};
`

const DataValue = styled(DataLabel)`
  font-weight: 400;
  color: ${colors.gray400};
`

const PanelContainer = styled.div`
  padding: 5px;
`

type PanelSidebarTaskPropsType = NonNullable<unknown>

const PanelSidebarTaskComponent = () => {
  const { translate } = useTranslation()

  const documentLogs = store.use.documentLogs()
  const document = store.use.document()

  return (
    <Collapse
      bordered={false}
      expandIconPosition={'end'}
      defaultActiveKey={['']}
      collapsible={'header'}
    >
      <Panel
        header={translate('project.tasklineOverview.sidebar.taskMetrics.title')}
        key='metrics'
        style={{ paddingBottom: 10 }}
      >
        <PanelContainer>
          {/*idDocument*/}
          <DataRow>
            <div>{translate('project.tasklineOverview.sidebar.taskMetrics.id')}</div>
            <div>{document?.idDocument ?? '-'}</div>
          </DataRow>
          {/*state*/}
          <DataRow>
            <div>{translate('project.tasklineOverview.sidebar.taskMetrics.status')}</div>
            <PresetTag preset={'task-state'} state={document?.state}>
              {TaskStateType[document?.state]}
            </PresetTag>
          </DataRow>
          {/*updateDate*/}
          <DataRow>
            <div>{translate('project.tasklineOverview.sidebar.taskMetrics.submissionDate')}</div>
            <div>{document?.updateDate ?? '-'}</div>
          </DataRow>
        </PanelContainer>
      </Panel>
      <Panel
        header={translate('project.tasklineOverview.sidebar.history.title')}
        key='history'
        style={{ paddingTop: 10 }}
      >
        <PanelContainer>
          {documentLogs.map((log) => (
            <DataRow>
              <Space size={5}>
                <DataValue>{log.creationDate}</DataValue>
                <DataLabel>{translate(`common.states.eventType.${log.event}`)}</DataLabel>
              </Space>
            </DataRow>
          ))}
        </PanelContainer>
      </Panel>
    </Collapse>
  )
}

const PanelSidebarTask = memo(
  PanelSidebarTaskComponent,
  (oldProps: PanelSidebarTaskPropsType, newProps: PanelSidebarTaskPropsType) => {
    return JSON.stringify(oldProps) === JSON.stringify(newProps)
  },
)

export default PanelSidebarTask
