/** @jsxImportSource @emotion/react */

import { getModels } from '@api'
import { ISelectInputProps, ISelectOptionProps, SelectInput, SelectOption } from 'common-components'
import { IModel } from '@api/types'
import { useEffect, useState } from 'react'

type IModelsFilterInputProps = ISelectInputProps & {
  models?: IModel[]
  optionProps?: Partial<ISelectOptionProps>
}
const SelectModels = ({
  models: modelsOverride,
  optionProps,
  ...rest
}: IModelsFilterInputProps) => {
  const [models, setModels] = useState<IModel[]>([])

  useEffect(() => {
    getModels({ model: {} }).then((modelApi) => {
      setModels(modelApi)
    })
  }, [])

  return (
    <SelectInput mode={'tags'} {...rest}>
      {(modelsOverride || models).map((model, i) => (
        <SelectOption
          key={`${i}-${model.idModel}`}
          value={model.idModel}
          title={`${model.name}`}
          {...optionProps}
        >
          {model.name}
        </SelectOption>
      ))}
    </SelectInput>
  )
}

export default SelectModels
