/** @jsxImportSource @emotion/react */

import {
  Button,
  Col,
  Content,
  IconButton,
  ITableProps,
  Paragraph,
  Row,
  Space,
  Table,
  Title,
  Toggle,
  useDisclosure,
} from 'common-components'
import { ColumnsType } from 'antd/lib/table'
import { useNavigate } from 'react-router'
import { routes } from '@navigation/routes'
import { PresetTag, DataLengthTag } from '@components/display'
import { useTranslation } from '@hooks'
import { useMemo, useRef, useState } from 'react'
import {
  GetCertificationQuestionsPayloadType,
  UpdateCertificationQuestionTrainingStatusPayloadType,
} from '@api'
import { QuestionType } from '@pages/types'
import { DeleteModal } from '@components/modal'
import { useParams } from 'react-router-dom'

import FiltersQuestionsCertification from './FiltersQuestionsCertification'

import { store } from '@/store'

type CertificationQuestionsPropsType = {
  questionsFiltered: QuestionType[]
  tableProps?: Omit<ITableProps<QuestionType>, 'data' | 'columns'>
  onQuestionTrainingStatusUpdate: (
    payload: UpdateCertificationQuestionTrainingStatusPayloadType,
  ) => void
  onFiltersChange?: (filters: GetCertificationQuestionsPayloadType) => void
}

const QuestionsCertification = ({
  questionsFiltered = [],
  onQuestionTrainingStatusUpdate,
  onFiltersChange,
  tableProps = {},
}: CertificationQuestionsPropsType) => {
  const { id: idCertification } = useParams()

  const deleteQuestionsCertificationAction = store.use.deleteQuestionsCertificationAction().call

  const isLoadingDeleteQuestionsCertificationAction =
    store.use.deleteQuestionsCertificationAction().status === 'LOADING'

  const navigate = useNavigate()
  const { translate } = useTranslation()
  const useDisclosureDeleteQuestionsModal = useDisclosure()
  const [isFiltersVisible, setFiltersVisible] = useState(false)

  const idQuestionSelected = useRef<string>()
  const [idQuestionsSelected, setIdQuestionsSelected] = useState<string[]>([])

  const tableColumns: ColumnsType<QuestionType> = useMemo(() => {
    try {
      return [
        {
          title: translate('certificationPage.questionsTable.questionId'),
          key: 'idCertificationQuestion',
          sorter: (a, b) => {
            if (a.idCertificationQuestion < b.idCertificationQuestion) return -1
            if (a.idCertificationQuestion > b.idCertificationQuestion) return 1
            return 0
          },
          render: (record: QuestionType) => (
            <Row align={'middle'}>
              <Space size={'middle'}>
                <Paragraph size='sm'>#{record.idCertificationQuestion}</Paragraph>
              </Space>
            </Row>
          ),
        },
        {
          title: translate('certificationPage.questionsTable.training'),
          key: 'training',
          sorter: (a, b) => {
            if (a.training < b.training) return -1
            if (a.training > b.training) return 1
            return 0
          },
          render: (record: QuestionType) => (
            <Row align={'middle'}>
              <Toggle
                onChange={(val) =>
                  onQuestionTrainingStatusUpdate({
                    idCertification: record.idCertification,
                    idCertificationQuestion: record.idCertificationQuestion,
                    training: val,
                  })
                }
                defaultChecked={record.training}
              />
            </Row>
          ),
        },
        {
          title: translate('certificationPage.questionsTable.successRate'),
          key: 'successRate',
          sorter: (a, b) => a.successRate - b.successRate,
          render: (record: QuestionType) => (
            <Row align={'middle'}>
              <Paragraph size='xs'>
                {record.successRate} {!!record.successRate && '%'}
              </Paragraph>
            </Row>
          ),
        },
        {
          title: translate('certificationPage.questionsTable.state'),
          key: 'state',
          sorter: (a, b) => a.state - b.state,
          render: (record: QuestionType) => {
            return (
              <Row align={'middle'}>
                <PresetTag preset={'question-state'} state={record.state}>
                  {translate(`common.states.questionState.${record.state}`)}
                </PresetTag>
              </Row>
            )
          },
        },
        /* HIDE
		{
			title: translate("certificationPage.questionsTable.timeAverage"),
			key: "averageDuration",
			dataIndex: "averageDuration",
			sorter: (a, b) => (a.averageDuration > b.averageDuration ? 1 : -1),
			render: (averageDuration) => (
				<Row align={"middle"}>
					<Paragraph size="xs">{averageDuration}</Paragraph>
				</Row>
			),
		},
		{
			title: translate("certificationPage.questionsTable.participantsNumber"),
			key: "nbRespondents",
			dataIndex: "nbRespondents",
			sorter: (a, b) => a.nbRespondents - b.nbRespondents,
			render: (nbRespondents) => (
				<Row align={"middle"}>
					<Paragraph size="xs">{nbRespondents}</Paragraph>
				</Row>
			),
		},*/
        {
          title: translate('certificationPage.questionsTable.actions'),
          key: 'idCertification',
          sorter: false,
          render: (record: QuestionType) => (
            <Space align={'center'} size={5}>
              <IconButton
                type={'link'}
                icon={'Eye'}
                onClick={() =>
                  navigate(
                    routes.certificationQuestion.navigate(
                      record.idCertification,
                      record.idCertificationQuestion,
                    ),
                  )
                }
              />
              <IconButton
                type='link'
                icon='Trash02'
                onClick={() => {
                  idQuestionSelected.current = record.idCertificationQuestion
                  useDisclosureDeleteQuestionsModal.open()
                }}
              />
            </Space>
          ),
        },
      ]
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
      return []
    }
  }, [])

  const onDeleteMutation = () => {
    if (idQuestionSelected.current) {
      deleteQuestionsCertificationAction(
        { idCertification, idQuestions: [idQuestionSelected.current] },
        () => {
          idQuestionSelected.current = undefined
          setTimeout(() => useDisclosureDeleteQuestionsModal.close(), 200)
        },
      )
    } else if (idQuestionsSelected.length) {
      deleteQuestionsCertificationAction(
        { idCertification, idQuestions: idQuestionsSelected },
        () => {
          setIdQuestionsSelected([])
          setTimeout(() => useDisclosureDeleteQuestionsModal.close(), 200)
        },
      )
    }
  }

  return (
    <Content>
      <Row justify={'space-between'} align={'top'} style={{ marginBottom: 22 }}>
        <Row gutter={8} style={{ alignItems: 'center' }}>
          <Col>
            <Title preset='singlePageTitle'>{translate('certificationPage.title.questions')}</Title>
          </Col>
          <Col>
            <DataLengthTag>{questionsFiltered?.length}</DataLengthTag>
          </Col>
        </Row>

        <Row>
          <Space>
            <Button iconLeft='Download01'>
              {translate('certificationPage.exportButtonLabel')}
            </Button>

            <Button onClick={() => setFiltersVisible(true)} iconLeft='FilterLines'>
              {translate('common.filter')}
            </Button>

            <FiltersQuestionsCertification
              visible={isFiltersVisible}
              onClose={() => setFiltersVisible(false)}
              idCertification={idCertification}
              onFiltersChange={onFiltersChange}
            />
            {!!idQuestionsSelected.length && (
              <Button
                onClick={useDisclosureDeleteQuestionsModal.open}
                type='danger'
                iconLeft='Trash02'
              >
                {translate('certificationPage.deleteButtonLabel')}
              </Button>
            )}
            {!idQuestionsSelected.length && (
              <Button
                type={'primary'}
                onClick={() =>
                  navigate(routes.certificationDraftQuestions.navigate(idCertification))
                }
              >
                {translate('certificationPage.createButtonLabel')}
              </Button>
            )}
          </Space>
        </Row>
      </Row>
      <Table<QuestionType>
        data={questionsFiltered}
        columns={tableColumns}
        rowKey={'idCertificationQuestion'}
        rowSelection={{
          type: 'checkbox',
          onChange: (_, questionsSelected: QuestionType[]) => {
            setIdQuestionsSelected(questionsSelected.map((item) => item?.idCertificationQuestion))
          },
          getCheckboxProps: (record: QuestionType) => ({
            name: record.idCertificationQuestion,
          }),
        }}
        onRow={() => ({
          onMouseOver: () => null,
        })}
        {...tableProps}
      />

      {useDisclosureDeleteQuestionsModal.isOpen && (
        <DeleteModal
          close={useDisclosureDeleteQuestionsModal.close}
          isOpen={useDisclosureDeleteQuestionsModal.isOpen}
          title={translate('certificationPage.deleteQuestionModal.title')}
          label={translate('certificationPage.deleteQuestionModal.description')}
          isLoading={isLoadingDeleteQuestionsCertificationAction}
          onDeleteMutation={onDeleteMutation}
        />
      )}
    </Content>
  )
}

export default QuestionsCertification
