/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next'
import { memo, useState } from 'react'
import { css } from '@emotion/react'
import { LoadingOutlined } from '@ant-design/icons'
import { AssignSegment, AssignSegmentPropsType } from '@views/shared/segment'
import { ActionModal, Row, colors, Text } from 'common-components'

import { CreateUserDataType } from '../index'

import FormContributor from './FormContributor'

type CreateContributorModalComponentPropsType = Pick<
  AssignSegmentPropsType,
  'onAddSegment' | 'onDeleteSegment'
> & {
  onCreateContributor(contributorForm: CreateUserDataType, onStepChange: () => void): Promise<void>
  close: () => void
  isOpen: boolean
  isLoading: boolean
}

const totalSteps = 2

const CreateContributorModalComponent = ({
  onCreateContributor,
  onAddSegment,
  onDeleteSegment,
  close,
  isOpen,
  isLoading,
}: CreateContributorModalComponentPropsType) => {
  const [contributorForm, setContributorForm] = useState<CreateUserDataType>(null)

  const { t } = useTranslation()
  const [step, setStep] = useState(1)
  const [isFormValid, setIsFormValid] = useState<boolean>(false)

  return (
    <ActionModal
      key={
        [t('contributors.addContributor'), 'Choisir des segments', 'Choisir des certifications'][
          step - 1
        ]
      }
      maskClosable={false}
      open={isOpen}
      onOk={close}
      customHeader={
        <Row
          css={css`
            flex-direction: column;
            gap: 5px;
          `}
        >
          <Text color={colors.gray400} size='md'>
            {step} {t('createSegment.outOf')} {totalSteps}
          </Text>
          <Text
            css={css`
              font-weight: 500;
            `}
            color={colors.gray900}
            size='xl'
          >
            {step === 1 && t('contributors.addContributor')}
            {step === 2 && 'Choisir des segments'}
          </Text>
        </Row>
      }
      onConfirm={close}
      title={
        [t('contributors.addContributor'), 'Choisir des segments', 'Choisir des certifications'][
          step - 1
        ]
      }
      onCancel={() => {
        setStep && setStep(1)
        close()
      }}
      actionButtons={[
        {
          text: t('managers.cancel'),
          onClick: () => {
            setStep && setStep(1)
            close()
          },
        },
        {
          text: isLoading ? <LoadingOutlined /> : t('createSegment.next'),
          type: 'primary',
          disabled: !isFormValid,
          onClick: async () => {
            if (setStep) {
              //////// STEP 1 ////////
              if (step === 1) {
                if (isFormValid && contributorForm?.createUser) {
                  await onCreateContributor(contributorForm, () => {
                    setStep(step + 1)
                    setContributorForm(null)
                  })
                }
              }
              //////// STEP 2 ////////
              if (step === 2) {
                close()
                setStep(step + 1)
                return
              }
            } else {
              close()
            }
          },
        },
      ]}
    >
      <>
        {step === 1 && (
          <FormContributor
            isFormValid={(validation) => setIsFormValid(validation)}
            onChangeForm={({ createUser, file }) => {
              setContributorForm({ createUser, file })
            }}
          />
        )}
        {step === 2 && (
          <AssignSegment onAddSegment={onAddSegment} onDeleteSegment={onDeleteSegment} />
        )}
      </>
    </ActionModal>
  )
}

const CreateContributorModal = memo(
  CreateContributorModalComponent,
  (
    oldProps: CreateContributorModalComponentPropsType,
    newProps: CreateContributorModalComponentPropsType,
  ) => {
    return JSON.stringify(oldProps) === JSON.stringify(newProps)
  },
)

export default CreateContributorModal
