const en = {
  draftLabel: 'Brouillons',
  formDraftsListTitle: 'Mes brouillons',
  formListTitle: 'Tous les formulaires',
  formTitle: 'Formulaires',
  formSubtitle: 'Choisir un formulaire',
  chooseFormButton: 'Choisir depuis la bibliothèque',
  addButton: 'Ajouter un formulaire',
}

export default en
export type Translations = typeof en
