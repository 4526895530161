import {
  GetCertificationsPayloadType,
  GetFormsPayloadType,
  GetProjectsPayloadType,
  GetSegmentsPayloadType,
  GetTasksPayloadType,
  GetModelsPayloadType,
} from '@api'
import { IGetWorkflowsReq } from '@types'
import { paramsToValuesArray } from '@utils'

export const mutationKeys = {
  //certifications
  certification: (params: { id: number | string }) => [
    'certification',
    ...paramsToValuesArray(params),
  ],
  certifications: (params: GetCertificationsPayloadType) => [
    'certifications',
    ...paramsToValuesArray(params),
  ],

  //forms
  form: (params: { id: number | string }) => ['form', ...paramsToValuesArray(params)],
  forms: (params?: GetFormsPayloadType) => ['forms', ...paramsToValuesArray(params || {})],
  draftForms: () => ['draft-forms'],

  //models
  model: (params: { id: number | string }) => ['model', ...paramsToValuesArray(params)],
  models: (params: GetModelsPayloadType) => ['models', ...paramsToValuesArray(params)],
  modelDrafts: () => ['model-drafts'],

  //segments
  segments: (params: GetSegmentsPayloadType) => ['segments', ...paramsToValuesArray(params)],
  segment: (params: { id: string }) => ['segment', ...paramsToValuesArray(params)],
  deleteSegment: (params: { userId: string; segmentId: string }) => [
    'deleteSegment',
    ...paramsToValuesArray(params),
  ],

  //projects
  project: (params: { id: number | string }) => ['project', ...paramsToValuesArray(params)],
  projects: (params: GetProjectsPayloadType) => ['projects', ...paramsToValuesArray(params)],

  // contributors
  contributors: (params: any = {}) => ['contributors', ...paramsToValuesArray(params)],
  contributor: (params: any = {}) => ['contributor', ...paramsToValuesArray(params)],

  //workflows
  workflow: (params: { id: number | string }) => ['workflow', ...paramsToValuesArray(params)],
  workflows: (params: IGetWorkflowsReq) => ['workflows', ...paramsToValuesArray(params)],

  // managers
  managers: (params: any = {}) => ['managers', ...paramsToValuesArray(params)],

  //tasks
  taskline: (params: { taskId: string }) => ['taskline', ...paramsToValuesArray(params)],
  tasklines: (id: string, params: Pick<GetTasksPayloadType['filters'], 'model'>) => [
    'tasklines',
    ...paramsToValuesArray({ id, ...params }),
  ],
  hit: (id: string) => ['hit', ...paramsToValuesArray({ id })],
  hits: (id: string) => ['hits', ...paramsToValuesArray({ id })],

  //Users
  user: (id: string) => ['user', id],

  // Risks
  risks: (params: Record<string, unknown> = {}) => ['risks', ...paramsToValuesArray(params)],
  riskSettings: (params: Record<string, string> = {}) => [
    'risk-settings',
    ...paramsToValuesArray(params),
  ],

  //resources
  resource: (type: number) => ['resource', type],
  sociodemographicResource: () => ['sociodemographicResource'],
}
