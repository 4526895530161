import { MeType } from '@pages/types'

import { ImmerStateCreator, StatusAction } from '../store.type'

import { getMeAction } from './me.action'

export type MeSliceType = {
  /** selectors **/
  me: MeType
  /** actions **/
  getMeAction: {
    call: () => void
    status: StatusAction
  }
}

const meSlice: ImmerStateCreator<MeSliceType> = (set) => ({
  me: undefined,
  getMeAction: {
    call: () => getMeAction(set)(),
    status: 'IDLE',
  },
})

export default meSlice
