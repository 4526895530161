/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { colors, FormItem, IFormItemProps } from 'common-components'
import { FC, PropsWithChildren } from 'react'

const VerticalFormItem: FC<PropsWithChildren<IFormItemProps>> = ({ children, ...props }) => {
  const classOverride = css`
    .ant-form-item-row {
      flex-direction: column;
      gap: 8px;
    }
    .ant-form-item-label {
      display: block;
      text-align: start;
      padding: 0 !important;
    }
    .ant-form-item-control {
      flex: none !important;
    }
    label {
      height: auto !important;
      text-align: left;
      color: ${colors.gray700} !important;
      font-weight: 500;
    }
    label::after {
      display: none;
    }
  `
  return (
    <FormItem {...props} style={{ marginBottom: 0 }} css={classOverride}>
      {children}
    </FormItem>
  )
}

export default VerticalFormItem
