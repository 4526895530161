const fr = {
  addSegment: 'Ajouter un segment',
  createContributorSegment: 'Créez un segment de contributeurs',
  icon: 'Icone',
  name: 'Nom',
  exampleEnglishLevel1: 'Exemple : Anglais Niveau 1',
  socio: 'Socio',
  manual: 'Manuelle',
  competence: 'Compétence',
  defineSelectionCriteria: 'Vous définissez des critères de sélection socio-démographiques',
  manualContributorSelection:
    'Vous sélectionnez manuellement les contributeurs qui ont une certaines compétences',
  specificCompetenceSelection:
    'Vous sélectionnez les contributeur selon une compétence spécifique.',
  back: 'Retour',
  next: 'Suivant',
  outOf: 'sur',
  users: 'Utilisateurs',
  import: 'Importer',
  addUsers: 'Ajouter des utilisateurs',
  search: 'Search',
  add: 'Ajouter',
  creator: 'Créateur',
  audience: 'Audience',
  listSizeVaries:
    'La taille de la liste peut varier dans le temps en fonction de vos paramètres et des données disponibles',
  validate: 'Valider',
  addAll: 'Ajouter tous',
  chooseManagers: 'Choisir des managers',
  ownerAndAdministrators: 'Proprietaire & Administrateurs',
  usagePermissions: 'Permissions d’utilisation',
  openToAll: 'Ouvert à tous',
  finish: 'Terminer',

  socioDemographic: 'Socio-démographique',
  parameter: 'Paramètre',
  country: 'Pays',
  criterion: 'Critère',
  usersMustRespect: 'Les utilisateurs doivent respecter au moins un des critères choisis.',
  gender: 'Genre',
  man: 'Homme',
  age: 'Âge',
  seniority: 'Ancienneté',
  skillCriterion: 'Critère de compétence',
  searchSkill: 'Rechercher une compétence',
  usersAreEvaluated: 'Les utilisateurs sont évalués sur l’ensemble des compétences suivantes',
  entry: 'Saisie',
  cutout: 'Détourage',
  minimumNumberOfTreatmentsEvaluated: 'Nombre minimum de traitements évalués',
  minimumQuality: 'Qualité minimum',
}

export default fr
