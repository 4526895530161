import { memo } from 'react'
import { useTranslation } from '@hooks'
import { colors, IconAvatarGroup, ActionModal } from 'common-components'

import AssignManager, { AssignManagerPropsType } from './AssignManager'

export type AssignManagerModalPropsType = Pick<
  AssignManagerPropsType,
  'onAddManager' | 'onDeleteManager' | 'managersUser' | 'isLoading'
> & {
  managersCount?: number
  close: () => void
  isOpen: boolean
}

const AssignManagerModalComponent = ({
  managersUser,
  onDeleteManager,
  onAddManager,
  managersCount = 1,
  isLoading,
  close,
  isOpen,
}: AssignManagerModalPropsType) => {
  const { translate } = useTranslation()
  return (
    <ActionModal
      maskClosable={false}
      open={isOpen}
      onOk={close}
      customHeader={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IconAvatarGroup
            avatars={Array.from({ length: managersCount }, () => ({
              size: 'md',
              icon: 'User01',
              iconColor: colors.success700,
              borderColor: colors.white,
              iconBackground: colors.gray100,
            }))}
          />
          <div>{translate('managers.assignSegments')}</div>
        </div>
      }
      onConfirm={close}
      onCancel={close}
    >
      <AssignManager
        isLoading={isLoading}
        managersUser={managersUser}
        onAddManager={onAddManager}
        onDeleteManager={onDeleteManager}
      />
    </ActionModal>
  )
}

const AssignManagerModal = memo(
  AssignManagerModalComponent,
  (oldProps: AssignManagerModalPropsType, newProps: AssignManagerModalPropsType) => {
    return JSON.stringify(oldProps) === JSON.stringify(newProps)
  },
)

export default AssignManagerModal
