export const getRiskLevelValueKey = (level: number) => {
  switch (level) {
    case 10:
      return 'low'
    case 20:
      return 'medium'
    case 30:
      return 'high'
    default:
      return 'unknown'
  }
}
