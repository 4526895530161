import { IModel } from '@api/types'
import { useTranslation } from '@hooks/useTranslation'
import { getModels, GetModelsPayloadType } from '@api/model'
import { FiltersBarModel } from '@views/shared/model'
import { useRequestParams } from '@hooks'
import openLink from '@utils/openLink'
import { getWirkUrl } from '@definitions/wirk-urls'
import { Col, Modal, Row, Space } from 'common-components'
import { getStateKeyByValue } from '@utils'
import { ModelState } from '@definitions/states'
import { useEffect, useState } from 'react'

import CardModel from './CardModel'

import { store } from '@/store'

type ModelsModalPropsType = {
  open: boolean
  addModel: (model: IModel) => void
  closeModal: () => void
}

const ModalArtificialModel = ({ open, closeModal, addModel }: ModelsModalPropsType) => {
  const { translate } = useTranslation()
  const accessToken = store.use.me().accessToken

  const [models, setModels] = useState<IModel[]>([])

  const { reqParams, setParams } = useRequestParams<GetModelsPayloadType, IModel>({
    initParams: {
      model: { state: [getStateKeyByValue(ModelState, 'online')] },
    },
  })

  useEffect(() => {
    getModels(reqParams).then((modelApi) => {
      setModels(modelApi)
    })
  }, [JSON.stringify(reqParams)])

  const openModel = (model: IModel) => {
    openLink(`${getWirkUrl('Model')}/model/${model.idModel}/?token=${accessToken}`)
  }

  return (
    <Modal
      title={translate('projectCreatePage.modal.models.title')}
      open={open}
      onCancel={closeModal}
      width={1000}
      footer={null}
    >
      <Space direction='vertical' style={{ width: '100%', gap: 16 }}>
        <FiltersBarModel initValues={reqParams} onFiltersChange={setParams} />
        <Row gutter={[20, 12]} style={{ maxHeight: 500, overflow: 'auto' }}>
          {models?.map((model) => (
            <Col span={8}>
              <CardModel
                key={model.idModel}
                model={model}
                style={{ width: '100%' }}
                onModelOpen={openModel}
                onModelSelect={addModel}
              />
            </Col>
          ))}
        </Row>
      </Space>
    </Modal>
  )
}

export default ModalArtificialModel
