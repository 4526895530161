/** @jsxImportSource @emotion/react */

import { useTranslation } from '@hooks'
import { FormSelector } from '@views/shared/form'
import { useEffect, useState } from 'react'
import { Row } from 'antd'
import { CertificationType } from '@pages/types/certification'
import { FormType } from '@pages/types'
import { Button, Content, Space, Text, useDisclosure } from 'common-components'
import { CollapseWithPill } from '@components/display'
import { LoadingOutlined } from '@ant-design/icons'
import { getStateKeyByValue } from '@utils'
import { PrefillCertificationMergeType, PrefillCertificationType } from '@definitions'

import { store } from '@/store'

type FormSelectorCertificationPropsType = {
  certification: CertificationType
  onNextStep: () => void
}

const FormSelectorCertification = ({
  certification,
  onNextStep,
}: FormSelectorCertificationPropsType) => {
  const updatePrefillTypeCertificationAction = store.use.updatePrefillTypeCertificationAction().call
  const updateFormCertificationAction = store.use.updateFormCertificationAction().call

  const isLoadingUpdateFormCertificationAction =
    store.use.updateFormCertificationAction().status === 'LOADING'
  const isLoadingUpdateNextStepCertificationAction =
    store.use.updateNextStepCertificationAction().status === 'LOADING'
  const isLoadingUpdatePrefillTypeCertificationAction =
    store.use.updatePrefillTypeCertificationAction().status === 'LOADING'

  const { translate } = useTranslation()
  const formUseDisclosure = useDisclosure()

  const [isOpenHydrationCollapse, setIsOpenHydrationCollapse] = useState<boolean>(false)
  const [isOpenFormCollapse, setIsOpenFormCollapse] = useState<boolean>(false)

  const onFormSelect = async (formCurrent: FormType) => {
    formUseDisclosure.close()
    updateFormCertificationAction({
      idCertification: certification.idCertification,
      form: formCurrent,
    })
  }

  useEffect(() => {
    setIsOpenFormCollapse(!!certification?.formShort?.idForm)
  }, [certification?.formShort?.idForm])

  const formPanel = {
    title: translate('projectCreatePage.collapse.manualEntry.title'),
    content: (
      <Space direction='vertical' style={{ width: '100%', padding: '0 24px 24px', gap: 16 }}>
        <Text size='sm'>{translate('projectCreatePage.collapse.manualEntry.subtitle')}</Text>
        <FormSelector
          idFormSelectedInit={certification?.formShort?.idForm}
          onFormSelect={onFormSelect}
          useDisclosure={formUseDisclosure}
        />
      </Space>
    ),
    isOpen: isOpenFormCollapse,
    setIsOpen: setIsOpenFormCollapse,
  }

  const hydrationPanel = {
    title: 'Pré-remplissage manuel (hydratation)',
    content: <> </>,
    isOpen: isOpenHydrationCollapse,
    setIsOpen: setIsOpenHydrationCollapse,
  }

  const onNextStepHandler = () => {
    if (isOpenHydrationCollapse) {
      updatePrefillTypeCertificationAction(
        {
          idCertification: certification.idCertification,
          prefillType: getStateKeyByValue<PrefillCertificationType>(
            PrefillCertificationMergeType,
            'manuel',
          ),
        },
        onNextStep,
      )
    } else {
      onNextStep()
    }
  }

  return (
    <Content>
      <CollapseWithPill panels={[formPanel]} />
      <CollapseWithPill panels={[hydrationPanel]} />

      <Row justify={'end'}>
        <Space size={5} style={{ margin: 10, gap: 16 }}>
          <Button
            disabled={
              !certification?.formShort?.idForm ||
              !isOpenFormCollapse ||
              isLoadingUpdateFormCertificationAction ||
              isLoadingUpdateNextStepCertificationAction ||
              isLoadingUpdatePrefillTypeCertificationAction
            }
            style={{
              width: '100px',
            }}
            type='primary'
            onClick={onNextStepHandler}
          >
            {isLoadingUpdateNextStepCertificationAction ||
            isLoadingUpdatePrefillTypeCertificationAction ? (
              <LoadingOutlined />
            ) : (
              translate('certificationCreation.nextStepButton')
            )}
          </Button>
        </Space>
      </Row>
    </Content>
  )
}

export default FormSelectorCertification
