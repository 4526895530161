/** @jsxImportSource @emotion/react */

import {
  Col,
  colors,
  IAvailableIcons,
  Icon,
  ISpaceProps,
  ITitleProps,
  Space,
  Title,
} from 'common-components'
import { css } from '@emotion/react'
import { GlobalOutlined } from '@ant-design/icons'
import { PropsWithChildren } from 'react'

type IItemWithAvatarPropsType = {
  title?: string
  titleProps?: ITitleProps
  iconName?: IAvailableIcons
  iconColor?: string
  containerProps?: ISpaceProps
}
const ItemWithAvatar = ({
  title,
  titleProps = {},
  containerProps = {},
  iconName,
  iconColor,
  children,
}: PropsWithChildren<IItemWithAvatarPropsType>) => {
  return (
    <Space size={20} align={'start'} style={{ width: '100%' }} {...containerProps}>
      <Col
        css={css`
          background: ${iconColor || colors.cyan100};
          border-radius: 200px;
          width: 56px;
          height: 56px;
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        {iconName ? <Icon icon={iconName} /> : <GlobalOutlined width={32} height={32} />}
      </Col>
      <Space size={10} direction={'vertical'}>
        {!!title && (
          <Title style={{ fontSize: 18 }} {...titleProps}>
            {title}
          </Title>
        )}
        {children}
      </Space>
    </Space>
  )
}

export default ItemWithAvatar
