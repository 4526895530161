import { useEffect } from 'react'
import { ListSegment } from '@views/segment'
import { GetSegmentsPayloadType } from '@api'
import { useDelayedEffect, useRequestParams } from '@hooks'
import { SegmentType } from '@pages/types'

import { store } from '@/store'

const SegmentsPage = () => {
  const segments = store.use.segments()
  const isLoadingGetSegmentsAction = store.use.getSegmentsAction().status === 'LOADING'
  const getSegmentsAction = store.use.getSegmentsAction().call

  const { afterPageChange, setParams, reqParams, pagination, onTableStateChange } =
    useRequestParams<GetSegmentsPayloadType, SegmentType>({
      initParams: { model: { managers: [] } },
    })

  useDelayedEffect(() => {
    getSegmentsAction(reqParams)
  }, [JSON.stringify(reqParams)])

  useEffect(() => {
    afterPageChange(segments.length)
  }, [segments.length])

  return (
    <ListSegment
      segmentsIsLoading={isLoadingGetSegmentsAction}
      segments={segments}
      onFiltersChange={setParams}
      tableProps={{ pagination, onChange: onTableStateChange }}
    />
  )
}

export default SegmentsPage
