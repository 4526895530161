import { CertificationUserTestType, ContributorType, SegmentType } from '@pages/types'

import { ImmerStateCreator, StatusAction } from '../store.type'
import { StoreGlobalType } from '../store'

import {
  getContributorsAction,
  getInformationContributorAction,
  CreateContributorActionPayloadType,
  createContributorAction,
  downloadContributorsAction,
  downloadContributorsSampleFileAction,
  deleteContributorsAction,
  DeleteContributorsActionPayloadType,
  GetContributorsActionPayloadType,
  GetContributorActionPayloadType,
  DownloadContributorsActionPayloadType,
  UploadContributorsFileActionPayloadType,
  uploadContributorsFileAction,
  AddSegmentToContributorsActionPayloadType,
  addSegmentToContributorsAction,
  DeleteSegmentFromContributorsActionPayloadType,
  deleteSegmentFromContributorsAction,
  UpdateContributorActionPayloadType,
  updateContributorAction,
  GetSegmentsContributorActionPayloadType,
  getSegmentsContributorAction,
  GetCertificationsTestContributorActionPayloadType,
  getCertificationsTestContributorAction,
  AddSegmentToContributorActionPayloadType,
  addSegmentToContributorAction,
  DeleteSegmentFromContributorActionPayloadType,
  deleteSegmentFromContributorAction,
  addManagerToSegmentContributorAction,
  AddManagerToSegmentContributorActionPayloadType,
  deleteManagerFromSegmentContributorAction,
  DeleteManagerFromSegmentContributorActionPayloadType,
} from './contributor.action'

export type ContributorSliceType = {
  /** selectors **/
  contributors: ContributorType[]
  segmentsContributor: SegmentType[]
  certificationsTestContributor: CertificationUserTestType[]
  /** actions **/
  getContributorsAction: {
    call: (payload: GetContributorsActionPayloadType) => void
    status: StatusAction
  }
  getInformationContributorAction: {
    call: (payload: GetContributorActionPayloadType) => void
    status: StatusAction
  }
  getSegmentsContributorAction: {
    call: (payload: GetSegmentsContributorActionPayloadType) => void
    status: StatusAction
  }
  getCertificationsTestContributorAction: {
    call: (payload: GetCertificationsTestContributorActionPayloadType) => void
    status: StatusAction
  }
  createContributorAction: {
    call: (
      payload: CreateContributorActionPayloadType,
      callback: (idContributorCreated: string) => void,
    ) => void
    status: StatusAction
  }
  deleteContributorsAction: {
    call: (payload: DeleteContributorsActionPayloadType, callback: () => void) => void
    status: StatusAction
  }
  downloadContributorsAction: {
    call: (payload: DownloadContributorsActionPayloadType) => void
    status: StatusAction
  }
  downloadContributorsSampleFileAction: {
    call: () => void
    status: StatusAction
  }
  uploadContributorsFileAction: {
    call: (payload: UploadContributorsFileActionPayloadType, callback?: () => void) => void
    status: StatusAction
  }
  updateContributorAction: {
    call: (payload: UpdateContributorActionPayloadType) => void
    status: StatusAction
  }
  addSegmentToContributorsAction: {
    call: (payload: AddSegmentToContributorsActionPayloadType, callback?: () => void) => void
    status: StatusAction
  }
  addSegmentToContributorAction: {
    call: (payload: AddSegmentToContributorActionPayloadType) => void
    status: StatusAction
  }
  addManagerToSegmentContributorAction: {
    call: (payload: AddManagerToSegmentContributorActionPayloadType) => void
    status: StatusAction
  }
  deleteManagerFromSegmentContributorAction: {
    call: (payload: DeleteManagerFromSegmentContributorActionPayloadType) => void
    status: StatusAction
  }
  deleteSegmentFromContributorsAction: {
    call: (payload: DeleteSegmentFromContributorsActionPayloadType, callback?: () => void) => void
    status: StatusAction
  }
  deleteSegmentFromContributorAction: {
    call: (payload: DeleteSegmentFromContributorActionPayloadType) => void
    status: StatusAction
  }
}

const contributorSlice: ImmerStateCreator<ContributorSliceType> = (
  set,
  get: () => StoreGlobalType & ContributorSliceType,
) => ({
  contributors: [],
  segmentsContributor: [],
  certificationsTestContributor: [],
  getContributorsAction: {
    call: (payload) => getContributorsAction(set)(payload),
    status: 'IDLE',
  },
  getInformationContributorAction: {
    call: (payload) => getInformationContributorAction(set)(payload),
    status: 'IDLE',
  },
  getSegmentsContributorAction: {
    call: (payload) => getSegmentsContributorAction(set, get)(payload),
    status: 'IDLE',
  },
  getCertificationsTestContributorAction: {
    call: (payload) => getCertificationsTestContributorAction(set, get)(payload),
    status: 'IDLE',
  },
  createContributorAction: {
    call: (payload, callback) => createContributorAction(set)(payload, callback),
    status: 'IDLE',
  },
  deleteContributorsAction: {
    call: (payload, callback) => deleteContributorsAction(set)(payload, callback),
    status: 'IDLE',
  },
  downloadContributorsAction: {
    call: (payload) => downloadContributorsAction(set)(payload),
    status: 'IDLE',
  },
  downloadContributorsSampleFileAction: {
    call: () => downloadContributorsSampleFileAction(set)(),
    status: 'IDLE',
  },
  uploadContributorsFileAction: {
    call: (payload, callback) => uploadContributorsFileAction(set)(payload, callback),
    status: 'IDLE',
  },
  updateContributorAction: {
    call: (payload) => updateContributorAction(set)(payload),
    status: 'IDLE',
  },
  addSegmentToContributorsAction: {
    call: (payload, callback) => addSegmentToContributorsAction(set, get)(payload, callback),
    status: 'IDLE',
  },
  addSegmentToContributorAction: {
    call: (payload) => addSegmentToContributorAction(set, get)(payload),
    status: 'IDLE',
  },
  addManagerToSegmentContributorAction: {
    call: (payload) => addManagerToSegmentContributorAction(set)(payload),
    status: 'IDLE',
  },
  deleteManagerFromSegmentContributorAction: {
    call: (payload) => deleteManagerFromSegmentContributorAction(set)(payload),
    status: 'IDLE',
  },
  deleteSegmentFromContributorsAction: {
    call: (payload, callback) => deleteSegmentFromContributorsAction(set, get)(payload, callback),
    status: 'IDLE',
  },
  deleteSegmentFromContributorAction: {
    call: (payload) => deleteSegmentFromContributorAction(set, get)(payload),
    status: 'IDLE',
  },
})

export default contributorSlice
