import {
  axiosClient,
  IdContributorTypeType,
  IdManagerTypeType,
  TUserActivityState,
  TUserType,
} from '@definitions'
import { AxiosResponse } from 'axios'
import { UserApiType } from '@api/types'
import { FilterPayloadType, SociodemographicFieldValue } from '@types'
import { catchApiError } from '@utils'

export const deleteSegment = async (userId: string, segmentId: string): Promise<any> => {
  try {
    const axios = axiosClient()
    const resp = await axios.delete(`/UserService/User/${userId}/RemoveSegment/${segmentId}`)
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export const downloadSampleImportUsersFile = async (): Promise<Blob> => {
  try {
    const axios = axiosClient()
    const response: AxiosResponse<Blob> = await axios.post(
      '/UserService/User/DownloadSampleImportUsersFile',
      {
        responseType: 'blob',
      },
    )
    return response.data
  } catch (e) {
    catchApiError(e)
  }
}

export type DownloadSuppliersPayloadType = {
  filters: FilterPayloadType & {
    model: {
      type?: TUserType[]
      segments?: string[]
      activity?: TUserActivityState[]
    }
  }
}

export const downloadSuppliers = async ({
  filters = { model: {} },
}: DownloadSuppliersPayloadType): Promise<Blob> => {
  try {
    const axios = axiosClient()
    const response: AxiosResponse<Blob> = await axios.post(
      '/UserService/User/GetSuppliers/Download',
      filters,
      {
        responseType: 'blob',
      },
    )
    return response.data
  } catch (e) {
    catchApiError(e)
  }
}

export type DownloadManagersPayloadType = {
  filters: FilterPayloadType & {
    model: {
      type?: TUserType[]
      segments?: string[]
      certifications?: string[]
      activity?: TUserActivityState[]
    }
  }
}
export const downloadManagers = async ({
  filters = { model: {} },
}: DownloadManagersPayloadType): Promise<Blob> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<Blob> = await axios.post(
      '/UserService/User/GetManagers/Download',
      filters,
      {
        responseType: 'blob',
      },
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export type GetSuppliersPayloadType = Pick<
  FilterPayloadType,
  'endDate' | 'entriesPerPage' | 'search' | 'startDate' | 'orderByAsc' | 'index'
> & {
  model: {
    type?: IdContributorTypeType[] // worker & condidate
    segments?: string[]
    activity?: TUserActivityState[]
  }
}
export const getSuppliers = async (
  payload: GetSuppliersPayloadType = {
    entriesPerPage: 20,
    index: 0,
    model: {},
  },
) => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<UserApiType[]> = await axios.post(
      '/UserService/User/GetSuppliers',
      payload,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export type GetManagersPayloadType = Pick<
  FilterPayloadType,
  'endDate' | 'entriesPerPage' | 'index' | 'search' | 'startDate' | 'orderByAsc'
> & {
  model: Partial<{
    type: IdManagerTypeType[]
    segments: string[]
    certifications: string[]
    activity: TUserActivityState[]
  }>
}
export const getManagers = async (payload: GetManagersPayloadType = { model: {} }) => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<UserApiType[]> = await axios.post(
      '/UserService/User/GetManagers',
      payload,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export type GetUserPayloadType = {
  id: string
}
export const getUser = async ({ id }: GetUserPayloadType): Promise<UserApiType> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<UserApiType> = await axios.get(`/UserService/User/${id}/GetUser`)
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export const getMe = async (): Promise<UserApiType> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<UserApiType> = await axios.get(`/UserService/User/GetUser`)
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export type CreateUserPayloadType = {
  lastName: string
  firstName: string
  phone: string
  email: string
  type: TUserType
}
export const createUser = async (payload: CreateUserPayloadType): Promise<UserApiType> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<UserApiType> = await axios.post(
      `/UserService/User/CreateUser`,
      payload,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export type UploadUserPhotoPayloadType = {
  idUser: string
  file: File
}
export const uploadUserPhoto = async (payload: UploadUserPhotoPayloadType): Promise<string> => {
  try {
    const axios = axiosClient({
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    const formData = new FormData()
    formData.append('IdUser', payload.idUser)
    formData.append('file', payload.file)
    const resp: AxiosResponse<string> = await axios.post(
      `/UserService/User/UploadAvatarFile`,
      formData,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export type UpdateUserPayloadType = {
  idUser: string
  phone: string
  lastName: string
  firstName: string
  type?: TUserType
  birthDate?: string
  nationality?: SociodemographicFieldValue
}
export const updateUser = async (user: UpdateUserPayloadType): Promise<UserApiType> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<UserApiType> = await axios.put(`/UserService/User/UpdateUser`, user)
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export type UpdateUserSociodemographicPayloadType = {
  idUser: string
  earning: SociodemographicFieldValue
  study: SociodemographicFieldValue
  family: SociodemographicFieldValue
  children: SociodemographicFieldValue
  job: SociodemographicFieldValue
  activitySector: SociodemographicFieldValue
}
export const updateUserSociodemographic = async (
  user: UpdateUserSociodemographicPayloadType,
): Promise<UserApiType> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<UserApiType> = await axios.put(
      `/UserService/User/UpdateSocioDemographic`,
      user,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export type UpdateUserAddressPayloadType = {
  idUser: string
  address: {
    path?: string
    zipCode?: string
    city?: string
    department?: SociodemographicFieldValue
    region?: SociodemographicFieldValue
    country?: SociodemographicFieldValue
  }
}
export const updateUserAddress = async (
  user: UpdateUserAddressPayloadType,
): Promise<UserApiType> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<UserApiType> = await axios.put(
      `/UserService/User/UpdateAddress`,
      user,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export type DeleteUserPayloadType = {
  idUser: string
}
export const deleteUser = async ({ idUser }: DeleteUserPayloadType): Promise<void> => {
  try {
    const axios = axiosClient()
    // TODO API response idUser removed
    await axios.delete(`/UserService/User/${idUser}/DeleteUser`)
  } catch (e) {
    catchApiError(e)
  }
}

export type ImportUsersPayloadType = { file: Blob }
export const importUsers = async ({ file }: ImportUsersPayloadType) => {
  try {
    const axios = axiosClient({
      headers: { 'Content-Type': 'multipart/form-data' },
    })

    const formData = new FormData()
    formData.append('file', file)

    await axios.post(`/UserService/User/ImportUsers`, formData)
  } catch (e) {
    catchApiError(e)
  }
}
