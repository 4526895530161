import {
  createProjectTask,
  CreateProjectTaskPayloadType,
  deleteDraftTask,
  DeleteDraftTaskPayloadType,
  getProjectDraftTasks,
  getTask,
  getTaskLogs,
  getTasks,
  GetTasksPayloadType,
  taskDecreasePriority,
  taskDelete,
  TaskDeletePayloadType,
  taskIncreasePriority,
  updateProjectInputJson,
  updateProjectTaskFile,
  updateProjectTaskValue,
  uploadTaskFileInputColumn,
  UploadTaskFileInputColumnPayloadType,
  uploadTasksFile,
  UploadTasksFilePayloadType,
} from '@api'
import { TaskType } from '@pages/types'
import { validationHydrationJson } from '@views/shared/helper'
import { AnswerApiType, TaskApiType } from '@api/types'
import { IColumn } from '@types'

import { StoreGlobalType } from '../store'

import { ProjectSliceType } from './project.slice'

import { taskApiMapper, taskLogApiMapper } from '@/mappers'
import { SetState } from '@/store/store.type'
import { getApiError, getSuccess } from '@/store/helper'

/****************************************************************
 * GET TASKS
 *****************************************************************/

type GetTasksActionStatusType = 'getTasks/loading' | 'getTasks/succeeded' | 'getTasks/failed'

export type GetTasksActionPayloadType = GetTasksPayloadType

export const getTasksAction =
  (
    set: SetState<
      Partial<ProjectSliceType & StoreGlobalType>,
      GetTasksActionStatusType,
      GetTasksActionPayloadType
    >,
  ) =>
  async (payload: GetTasksActionPayloadType) => {
    try {
      set(
        (state) => {
          state.getTasksAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'getTasks/loading', payload },
      )

      const tasksApi = await getTasks(payload)
      const tasks = tasksApi.map(taskApiMapper)

      set(
        (state) => {
          state.getTasksAction.status = 'SUCCEEDED'
          state.tasksFiltered = tasks
        },
        false,
        { type: 'getTasks/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.getTasksAction.status = 'FAILED'
          state.tasksFiltered = []
          state.error = getApiError(e)
        },
        false,
        { type: 'getTasks/failed' },
      )
    }
  }

/****************************************************************
 * GET TASK
 *****************************************************************/

type GetTaskActionStatusType = 'getTask/loading' | 'getTask/succeeded' | 'getTask/failed'

export type GetTaskActionPayloadType = {
  idTask: string
}

export const getTaskAction =
  (
    set: SetState<
      Partial<ProjectSliceType & StoreGlobalType>,
      GetTaskActionStatusType,
      GetTaskActionPayloadType
    >,
  ) =>
  async (payload: GetTaskActionPayloadType, callback?: () => void) => {
    try {
      set(
        (state) => {
          state.getTaskAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'getTask/loading', payload },
      )

      const taskApi = await getTask(payload.idTask)
      const task = taskApiMapper(taskApi)

      set(
        (state) => {
          state.getTaskAction.status = 'SUCCEEDED'

          const index = state.tasksFiltered?.findIndex(({ idTask }) => idTask === payload.idTask)
          if (index >= 0) {
            state.tasksFiltered[index] = task
          } else {
            state.tasksFiltered.unshift(task)
          }
        },
        false,
        { type: 'getTask/succeeded' },
      )

      callback && callback()
    } catch (e) {
      set(
        (state) => {
          state.getTaskAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'getTask/failed' },
      )
    }
  }

/****************************************************************
 * GET TASK LOGS
 *****************************************************************/

type GetTaskLogsActionStatusType =
  | 'getTaskLogs/loading'
  | 'getTaskLogs/succeeded'
  | 'getTaskLogs/failed'

export type GetTaskLogsActionPayloadType = {
  idTask: string
}

export const getTaskLogsAction =
  (
    set: SetState<
      Partial<ProjectSliceType & StoreGlobalType>,
      GetTaskLogsActionStatusType,
      GetTaskLogsActionPayloadType
    >,
  ) =>
  async (payload: GetTaskLogsActionPayloadType, callback?: () => void) => {
    try {
      set(
        (state) => {
          state.getTaskLogsAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'getTaskLogs/loading', payload },
      )

      const taskLogsApi = await getTaskLogs(payload.idTask)
      const taskLogs = taskLogsApi.map(taskLogApiMapper)

      set(
        (state) => {
          state.getTaskLogsAction.status = 'SUCCEEDED'
          state.taskLogs = taskLogs
        },
        false,
        { type: 'getTaskLogs/succeeded' },
      )

      callback && callback()
    } catch (e) {
      set(
        (state) => {
          state.getTaskLogsAction.status = 'FAILED'
          state.taskLogs = []
          state.error = getApiError(e)
        },
        false,
        { type: 'getTaskLogs/failed' },
      )
    }
  }

/****************************************************************
 * GET DRAFT TASKS
 *****************************************************************/

type GetDraftTasksActionStatusType =
  | 'getDraftTasks/loading'
  | 'getDraftTasks/succeeded'
  | 'getDraftTasks/failed'

export type GetDraftTasksActionPayloadType = {
  idProject: string
}

export const getDraftTasksAction =
  (
    set: SetState<
      Partial<ProjectSliceType & StoreGlobalType>,
      GetDraftTasksActionStatusType,
      GetDraftTasksActionPayloadType
    >,
  ) =>
  async (payload: GetDraftTasksActionPayloadType, callback?: () => void) => {
    try {
      set(
        (state) => {
          state.getDraftTasksAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'getDraftTasks/loading', payload },
      )

      const tasksDraftApi = await getProjectDraftTasks(payload.idProject)
      const tasksDraft = tasksDraftApi.map(taskApiMapper)

      set(
        (state) => {
          state.getDraftTasksAction.status = 'SUCCEEDED'
          state.tasksDraft = tasksDraft
        },
        false,
        { type: 'getDraftTasks/succeeded' },
      )

      callback && callback()
    } catch (e) {
      set(
        (state) => {
          state.getDraftTasksAction.status = 'FAILED'
          state.tasksDraft = []
          state.error = getApiError(e)
        },
        false,
        { type: 'getDraftTasks/failed' },
      )
    }
  }

/****************************************************************
 * CREATE DRAFT TASK
 *****************************************************************/

type CreateDraftTaskActionStatusType =
  | 'createDraftTask/loading'
  | 'createDraftTask/succeeded'
  | 'createDraftTask/failed'

export type CreateDraftTaskActionPayloadType = CreateProjectTaskPayloadType

export const createDraftTaskAction =
  (
    set: SetState<
      Partial<ProjectSliceType & StoreGlobalType>,
      CreateDraftTaskActionStatusType,
      CreateDraftTaskActionPayloadType
    >,
  ) =>
  async (payload: CreateDraftTaskActionPayloadType, callback: (taskDraft: TaskType) => void) => {
    try {
      set(
        (state) => {
          state.createDraftTaskAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'createDraftTask/loading', payload },
      )

      const taskApi = await createProjectTask(payload)
      const taskDraft = taskApiMapper(taskApi)

      set(
        (state) => {
          state.createDraftTaskAction.status = 'SUCCEEDED'
          state.tasksDraft.unshift(taskDraft)
        },
        false,
        { type: 'createDraftTask/succeeded' },
      )

      callback(taskDraft)
    } catch (e) {
      set(
        (state) => {
          state.createDraftTaskAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'createDraftTask/failed' },
      )
    }
  }

/****************************************************************
 * UPDATE DRAFT TASK
 *****************************************************************/

type UpdateDraftTaskActionStatusType =
  | 'updateDraftTask/loading'
  | 'updateDraftTask/succeeded'
  | 'updateDraftTask/failed'

export type UpdateDraftTaskActionPayloadType = {
  idProject: string
  idTask: string
  columnName: string
  type: IColumn['type']
  value?: string
  file?: string
}

export const updateDraftTaskAction =
  (
    set: SetState<
      Partial<ProjectSliceType & StoreGlobalType>,
      UpdateDraftTaskActionStatusType,
      UpdateDraftTaskActionPayloadType
    >,
  ) =>
  async (payload: UpdateDraftTaskActionPayloadType) => {
    try {
      set(
        (state) => {
          state.updateDraftTaskAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'updateDraftTask/loading', payload },
      )

      const payloadApi = {
        idProject: payload.idProject,
        idTask: payload.idTask,
        columnName: payload.columnName,
      }

      let taskApi: TaskApiType

      //** HYDRATATION JSON **//
      if (payload.type === 'hydration_json') {
        const inputJson = validationHydrationJson(
          JSON.parse(payload.value.replace(/\\+/g, '')),
        ) as AnswerApiType
        taskApi = await updateProjectInputJson({
          ...payloadApi,
          inputJson,
        })
        //** FILE **//
      } else if (payload.file) {
        taskApi = await updateProjectTaskFile({
          ...payloadApi,
          file: payload.file,
        })
      } else {
        //** VALUE **//
        taskApi = await updateProjectTaskValue({
          ...payloadApi,
          value: payload.value,
        })
      }

      const taskDraft = taskApiMapper(taskApi)

      set(
        (state) => {
          state.updateDraftTaskAction.status = 'SUCCEEDED'

          const index = state.tasksDraft.findIndex(({ idTask }) => idTask === payload.idTask)
          if (index >= 0) {
            state.tasksDraft[index] = taskDraft
          }
        },
        false,
        { type: 'updateDraftTask/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.updateDraftTaskAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'updateDraftTask/failed' },
      )
    }
  }

/****************************************************************
 * DELETE DRAFT TASK
 *****************************************************************/

type DeleteDraftTaskActionStatusType =
  | 'deleteDraftTask/loading'
  | 'deleteDraftTask/succeeded'
  | 'deleteDraftTask/failed'

export type DeleteDraftTaskActionPayloadType = DeleteDraftTaskPayloadType

export const deleteDraftTaskAction =
  (
    set: SetState<
      Partial<ProjectSliceType & StoreGlobalType>,
      DeleteDraftTaskActionStatusType,
      DeleteDraftTaskActionPayloadType
    >,
  ) =>
  async (payload: DeleteDraftTaskActionPayloadType) => {
    try {
      set(
        (state) => {
          state.deleteDraftTaskAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'deleteDraftTask/loading', payload },
      )

      await deleteDraftTask(payload)

      set(
        (state) => {
          state.deleteDraftTaskAction.status = 'SUCCEEDED'
          state.success = getSuccess({})

          const index = state.tasksDraft.findIndex(({ idTask }) => idTask === payload.idTask)
          if (index >= 0) {
            state.tasksDraft.splice(index, 1)
          }
        },
        false,
        { type: 'deleteDraftTask/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.deleteDraftTaskAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'deleteDraftTask/failed' },
      )
    }
  }

/****************************************************************
 * UPLOAD TASKS FILE PROJECT
 *****************************************************************/

type UploadTasksFileProjectActionStatusType =
  | 'uploadTasksFileProject/loading'
  | 'uploadTasksFileProject/succeeded'
  | 'uploadTasksFileProject/failed'

export type UploadTasksFileProjectActionPayloadType = UploadTasksFilePayloadType

export const uploadTasksFileProjectAction =
  (
    set: SetState<
      Partial<ProjectSliceType & StoreGlobalType>,
      UploadTasksFileProjectActionStatusType,
      UploadTasksFileProjectActionPayloadType
    >,
  ) =>
  async (payload: UploadTasksFileProjectActionPayloadType, callback: () => void) => {
    try {
      set(
        (state) => {
          state.uploadTasksFileProjectAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'uploadTasksFileProject/loading', payload },
      )

      await uploadTasksFile(payload)

      setTimeout(async () => {
        set(
          (state) => {
            state.uploadTasksFileProjectAction.status = 'SUCCEEDED'
            state.success = getSuccess({ messageT: 'certificationCreation.questions.refreshText' })
          },
          false,
          { type: 'uploadTasksFileProject/succeeded' },
        )
        callback()
      }, 3000)
    } catch (e) {
      set(
        (state) => {
          state.uploadTasksFileProjectAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'uploadTasksFileProject/failed' },
      )
    }
  }

/****************************************************************
 * UPLOAD COLUMN FILE QUESTIONS CERTIFICATION
 *****************************************************************/

type UploadColumnFileTasksProjectActionStatusType =
  | 'uploadColumnFileTasksProject/loading'
  | 'uploadColumnFileTasksProject/succeeded'
  | 'uploadColumnFileTasksProject/failed'

export type UploadColumnFileTasksProjectActionPayloadType = UploadTaskFileInputColumnPayloadType

export const uploadColumnFileTasksProjectAction =
  (
    set: SetState<
      Partial<ProjectSliceType & StoreGlobalType>,
      UploadColumnFileTasksProjectActionStatusType,
      UploadColumnFileTasksProjectActionPayloadType
    >,
  ) =>
  async (payload: UploadColumnFileTasksProjectActionPayloadType, callback: () => void) => {
    try {
      set(
        (state) => {
          state.uploadColumnFileTasksProjectAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'uploadColumnFileTasksProject/loading', payload },
      )

      await uploadTaskFileInputColumn(payload)

      setTimeout(async () => {
        set(
          (state) => {
            state.uploadColumnFileTasksProjectAction.status = 'SUCCEEDED'
            state.success = getSuccess({
              messageT: `certificationCreation.questions.refreshText`,
            })
          },
          false,
          { type: 'uploadColumnFileTasksProject/succeeded' },
        )
        callback()
      }, 3000)
    } catch (e) {
      set(
        (state) => {
          state.uploadColumnFileTasksProjectAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'uploadColumnFileTasksProject/failed' },
      )
    }
  }

/****************************************************************
 * DELETE TASK
 *****************************************************************/

type DeleteTaskActionStatusType =
  | 'deleteTask/loading'
  | 'deleteTask/succeeded'
  | 'deleteTask/failed'

export type DeleteTaskActionPayloadType = TaskDeletePayloadType

export const deleteTaskAction =
  (
    set: SetState<
      Partial<ProjectSliceType & StoreGlobalType>,
      DeleteTaskActionStatusType,
      DeleteTaskActionPayloadType
    >,
  ) =>
  async (payload: DeleteTaskActionPayloadType, callback?: () => void) => {
    try {
      set(
        (state) => {
          state.deleteTaskAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'deleteTask/loading', payload },
      )

      await taskDelete(payload)

      set(
        (state) => {
          state.deleteTaskAction.status = 'SUCCEEDED'

          const index = state.tasksFiltered.findIndex(({ idTask }) => idTask === payload.idTask)
          if (index >= 0) {
            state.tasksFiltered.splice(index, 1)
          }
        },
        false,
        { type: 'deleteTask/succeeded' },
      )

      callback && callback()
    } catch (e) {
      set(
        (state) => {
          state.deleteTaskAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'deleteTask/failed' },
      )
    }
  }

/****************************************************************
 * INCREASE PRIORITY TASK
 *****************************************************************/

type IncreasePriorityTaskActionStatusType =
  | 'increasePriorityTask/loading'
  | 'increasePriorityTask/succeeded'
  | 'increasePriorityTask/failed'

export type IncreasePriorityTaskActionPayloadType = {
  idTask: string
}

export const increasePriorityTaskAction =
  (
    set: SetState<
      Partial<ProjectSliceType & StoreGlobalType>,
      IncreasePriorityTaskActionStatusType,
      IncreasePriorityTaskActionPayloadType
    >,
  ) =>
  async (payload: IncreasePriorityTaskActionPayloadType) => {
    try {
      set(
        (state) => {
          state.increasePriorityTaskAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'increasePriorityTask/loading', payload },
      )

      const taskApi = await taskIncreasePriority(payload)
      const task = taskApiMapper(taskApi)

      set(
        (state) => {
          state.increasePriorityTaskAction.status = 'SUCCEEDED'
          state.success = getSuccess({})

          const index = state.tasksFiltered.findIndex(({ idTask }) => idTask === payload.idTask)
          if (index >= 0) {
            state.tasksFiltered[index] = task
          }
        },
        false,
        { type: 'increasePriorityTask/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.increasePriorityTaskAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'increasePriorityTask/failed' },
      )
    }
  }

/****************************************************************
 * DECREASE PRIORITY TASK
 *****************************************************************/

type DecreasePriorityTaskActionStatusType =
  | 'decreasePriorityTask/loading'
  | 'decreasePriorityTask/succeeded'
  | 'decreasePriorityTask/failed'

export type DecreasePriorityTaskActionPayloadType = {
  idTask: string
}

export const decreasePriorityTaskAction =
  (
    set: SetState<
      Partial<ProjectSliceType & StoreGlobalType>,
      DecreasePriorityTaskActionStatusType,
      DecreasePriorityTaskActionPayloadType
    >,
  ) =>
  async (payload: DecreasePriorityTaskActionPayloadType) => {
    try {
      set(
        (state) => {
          state.decreasePriorityTaskAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'decreasePriorityTask/loading', payload },
      )

      const taskApi = await taskDecreasePriority(payload)
      const task = taskApiMapper(taskApi)

      set(
        (state) => {
          state.decreasePriorityTaskAction.status = 'SUCCEEDED'
          state.success = getSuccess({})

          const index = state.tasksFiltered.findIndex(({ idTask }) => idTask === payload.idTask)
          if (index >= 0) {
            state.tasksFiltered[index] = task
          }
        },
        false,
        { type: 'decreasePriorityTask/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.decreasePriorityTaskAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'decreasePriorityTask/failed' },
      )
    }
  }
