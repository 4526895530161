export const sortDates = (a: string, b: string) => {
  if (new Date(a).getTime() < new Date(b).getTime()) return -1
  if (new Date(a).getTime() > new Date(b).getTime()) return 1
  return 0
}

export const sortNumbers = (a: number | string, b: number | string) => {
  if (Number(a) < Number(b)) return -1
  if (Number(a) > Number(b)) return 1
  return 0
}

export const sortStrings = (a: string, b: string) => {
  if (a < b) return -1
  if (a > b) return 1
  return 0
}
