/** @jsxImportSource @emotion/react */

import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { css } from '@emotion/react'
import { FormState, Quality, SkillType } from '@definitions'
import { StateSelect } from '@components/inputs'
import { useTranslation } from '@hooks'
import { GetFormsPayloadType } from '@api'
import { Col, Form, FormItem, Row } from 'common-components'

type FilterBarFormPropsType = {
  onFiltersChange?: (filters: GetFormsPayloadType) => void
  onClose?: () => void
  initValues?: GetFormsPayloadType
}

const FiltersBarForm = ({ onClose, onFiltersChange, initValues }: FilterBarFormPropsType) => {
  const { control, handleSubmit, watch, getValues, reset } = useForm<GetFormsPayloadType>({
    defaultValues: { model: {}, ...(initValues || {}) },
  })
  const { translate } = useTranslation()

  useEffect(() => {
    reset(initValues)
  }, [initValues])

  const onSubmit = () => {
    const data = getValues()
    onFiltersChange && onFiltersChange(data)
    onClose && onClose()
  }

  useEffect(() => {
    const subscription = watch(() => handleSubmit(onSubmit)())
    return () => subscription.unsubscribe()
  }, [watch()])

  return (
    <Form onFinish={handleSubmit(onSubmit)} css={$form}>
      <Row gutter={10}>
        <Col span={12}>
          <Controller
            name={'model.skill'}
            control={control}
            render={({ field }) => (
              <FormItem label={translate('common.skill')}>
                <StateSelect
                  values={SkillType}
                  labelTxKey={'skill'}
                  onChange={field.onChange}
                  value={field.value}
                />
              </FormItem>
            )}
          />
        </Col>
        <Col span={6}>
          <Controller
            name={'model.quality'}
            control={control}
            render={({ field }) => (
              <FormItem label={translate('common.quality')}>
                <StateSelect
                  values={Quality}
                  labelTxKey={'quality'}
                  onChange={field.onChange}
                  value={field.value}
                />
              </FormItem>
            )}
          />
        </Col>
        <Col span={6}>
          <Controller
            name={'model.state'}
            control={control}
            render={({ field }) => (
              <FormItem label={translate('common.state')}>
                <StateSelect
                  values={FormState}
                  labelTxKey={'formState'}
                  onChange={field.onChange}
                  value={field.value}
                />
              </FormItem>
            )}
          />
        </Col>
      </Row>
    </Form>
  )
}

const $form = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export default FiltersBarForm
