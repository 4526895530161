const fr = {
  invitationSent: 'Vous avez invité un utilisateur',
  invitationAccepted: 'a été invitée à collaborer sur Wirk.',
  contributorInfo: 'Contributeur',
  metrics: 'Métriques',
  information: 'Informations',
  segments: 'Segments',
  certifications: 'Certifications',
  activity: 'Activité',
  export: 'Exporter',
  filter: 'Filtrer',
  workedHours: 'Temps effectif travaillé',
  numberOfProjects: 'Nombre de projets',
  volume: 'Volume',
  projectName: 'Nom du projet',
  lastTask: 'Dernière tâche',
  tasksCompleted: 'Tâches effectuées',
  pace: 'Cadence',
  effectiveTime: 'Temps effectif',
  distribution: 'Répartition',
  quality: 'Qualité',
  delete: {
    button: 'Supprimer',
    modal: {
      title: 'Supprimer le contributeur ?',
      label: 'Êtes-vous sûr de vouloir effectuer cette action ?',
    },
  },
}

export default fr
