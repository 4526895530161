/** @jsxImportSource @emotion/react */

import { Validations } from '@definitions/validations'
import { useTranslation } from '@hooks/useTranslation'
import { css } from '@emotion/react'
import { LabelColumnType } from '@definitions'
import { ChangeEvent, PropsWithChildren, useCallback, useEffect, useState } from 'react'
import debounce from 'lodash.debounce'
import {
  Col,
  Row,
  Text,
  TextInput,
  Space,
  Form,
  SelectInput,
  IFormItemProps,
  FormItem,
  colors,
} from 'common-components'

const modelFormAllowedFieldTypes: LabelColumnType[] = [
  'text_short',
  'text',
  'phonenumber',
  'coordinates_latitude',
  'coordinates_longitude',
  'coordinates_gps',
  'datetime',
  'email',
  'number',
  'url',
  'country',
  'mcq',
  'select',
  'address',
  'multiselect',
  'multiselect_item',
  'multimcq',
  'multimcq_item',
  'file',
  'api_select',
  'api_multiselect',
  'rib',
]

type CollapseModalArtificialPropsType = {
  options: { value: string; label: string }[]
  fields?: { id: string; type: LabelColumnType }[]
  showFields: boolean
  onChangeForm: ({
    values,
    isValid,
  }: {
    values: FormFieldsType | undefined
    isValid: boolean
  }) => void
}

export type FormFieldsType = {
  acceptationRate: number
  prefillRate: number
} & { [x: string]: string }

const FormFieldsModel = ({
  options,
  fields = [],
  showFields,
  onChangeForm,
}: CollapseModalArtificialPropsType) => {
  const [form, setForm] = useState<FormFieldsType>()
  const [errors, setErrors] = useState<(keyof FormFieldsType)[]>(['prefillRate', 'acceptationRate'])
  const { translate } = useTranslation()

  const validationForm = () => {
    if (!form?.prefillRate?.toString().length) {
      if (errors.indexOf('prefillRate') === -1)
        setErrors((prevState) => prevState.concat(['prefillRate']))
    } else {
      setErrors((prevState) => prevState.filter((item) => item !== 'prefillRate'))
    }
    if (!form?.acceptationRate?.toString().length) {
      if (errors.indexOf('acceptationRate') === -1)
        setErrors((prevState) => prevState.concat(['acceptationRate']))
    } else {
      setErrors((prevState) => prevState.filter((item) => item !== 'acceptationRate'))
    }
  }

  useEffect(() => {
    onChangeDebounce(form, errors)
    validationForm()
  }, [JSON.stringify(form), errors.length])

  const onChangeInput =
    (field: 'prefillRate' | 'acceptationRate') => (e: ChangeEvent<HTMLInputElement>) => {
      setForm((prevState) => ({ ...prevState, [field]: e?.target?.value }))
    }

  const onChangeSelect = (field: string) => (value: string) => {
    setForm((prevState) => ({ ...prevState, [field]: value }))
  }

  const onChangeDebounce = useCallback(
    debounce(
      (formCurrent: FormFieldsType, errorsCurrent) =>
        onChangeForm({ values: formCurrent, isValid: errorsCurrent.length === 0 }),
      800,
    ),
    [],
  )

  return (
    <Form style={{ border: 'none' }}>
      <Space direction='vertical' style={{ width: '100%' }}>
        <Text size='xs'>
          {translate('projectCreatePage.collapse.artificialModel.content.modelInstructions')}
        </Text>
        <Row gutter={16}>
          <Col span={12} style={{ display: 'flex', flexDirection: 'column', gap: 6 }}>
            <Input label={translate('projectCreatePage.form.label.prefillRate')}>
              <TextInput
                value={form?.prefillRate}
                type='number'
                onChange={onChangeInput('prefillRate')}
              />
              <div css={errorTextColor}>
                {errors.indexOf('prefillRate') !== -1 && Validations.message.required}
              </div>
            </Input>
          </Col>
          <Col span={12} style={{ display: 'flex', flexDirection: 'column', gap: 6 }}>
            <Input label={translate('projectCreatePage.form.label.validationRate')}>
              <TextInput
                value={form?.acceptationRate}
                type='number'
                onChange={onChangeInput('acceptationRate')}
              />
              <div css={errorTextColor}>
                {errors.indexOf('acceptationRate') !== -1 && Validations.message.required}
              </div>
            </Input>
          </Col>
        </Row>
      </Space>
      <Space direction='vertical' style={{ width: '100%', gap: 8, marginTop: 8 }}>
        <Row gutter={16}>
          <Col span={12}>
            <Text
              style={{
                color: `${colors.gray700} !important`,
                fontWeight: 500,
              }}
              size='sm'
            >
              {translate('projectCreatePage.collapse.artificialModel.content.formFields')}
            </Text>
          </Col>
          <Col span={12}>
            <Text
              style={{
                color: `${colors.gray700} !important`,
                fontWeight: 500,
              }}
              size='sm'
            >
              {translate('projectCreatePage.collapse.artificialModel.content.modelFields')}
            </Text>
          </Col>
        </Row>
        {showFields && (
          <Space direction='vertical' style={{ width: '100%', display: 'flex' }}>
            <Row gutter={16}>
              {fields.map((field) => {
                if (modelFormAllowedFieldTypes.includes(field?.type)) {
                  return (
                    <>
                      <Col span={12}>
                        <Input key={field.id} label={field.id} />
                      </Col>
                      <Col span={12}>
                        <SelectInput onChange={onChangeSelect(field.id)} options={options} />
                      </Col>
                    </>
                  )
                }
              })}
            </Row>
          </Space>
        )}
      </Space>
    </Form>
  )
}

const Input = ({ children, ...props }: PropsWithChildren<IFormItemProps>) => {
  const classOverride = css`
    .ant-form-item-row {
      flex-direction: column;
      gap: 8px;
    }
    .ant-form-item-label {
      display: block;
      text-align: start;
      padding: 0 !important;
    }
    .ant-form-item-control {
      flex: none !important;
    }
    label {
      height: auto !important;
      text-align: left;
      color: ${colors.gray700} !important;
      font-weight: 500;
    }
    label::after {
      display: none;
    }
  `
  return (
    <FormItem {...props} style={{ marginBottom: 0 }} css={classOverride}>
      {children}
    </FormItem>
  )
}

const errorTextColor = css`
  height: 22px;
  padding-top: 5px;
  color: red !important;
`

export default FormFieldsModel
