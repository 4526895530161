/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled'
import {
  Avatar,
  Col,
  colors,
  HoverCard,
  Icon,
  IconButton,
  IHoverCardProps,
  Paragraph,
  Row,
  Space,
  spacing,
  Text,
} from 'common-components'

const ContentContainer = styled(Col)`
  padding: ${spacing.padding.card.default}px;
`
const FooterContainer = styled(Row)`
  width: 100%;
  height: 56px;
`
const HoverContentContainer = styled(Row)`
  width: 100%;
`

type CardFormPropsType = IHoverCardProps & {
  form: {
    idForm: string
    name: string
    updateDate: string
    nbReviewers: number
  }
  onFormPreview?: (idForm: string) => void
  onFormSelect?: (idForm: string) => void
}
const CardForm = function ({ form, onFormSelect, onFormPreview }: CardFormPropsType) {
  return (
    <HoverCard
      ContentComponent={
        <ContentContainer>
          <Paragraph>{form.name}</Paragraph>
          <Text>{form.updateDate}</Text>
        </ContentContainer>
      }
      footerContent={
        <FooterContainer align={'middle'} justify={'space-between'}>
          {/*FIXME: no avatar data*/}
          <Avatar />
          <Space size={5}>
            <Icon icon={'UsersEdit'} color={colors.gray400} size={16} />
            <Text size='xs'>{form.nbReviewers}</Text>
          </Space>
        </FooterContainer>
      }
      hoverFooterContent={
        <HoverContentContainer align={'middle'} justify={'center'}>
          {onFormPreview && (
            <IconButton
              icon={'Eye'}
              type={'text'}
              iconProps={{ color: colors.white }}
              onClick={() => onFormPreview(form.idForm)}
            />
          )}
          {onFormSelect && (
            <IconButton
              icon={'ArrowCircleBrokenRight'}
              type={'text'}
              iconProps={{ color: colors.white }}
              onClick={() => onFormSelect(form.idForm)}
            />
          )}
        </HoverContentContainer>
      }
    />
  )
}

export default CardForm
