const en = {
  title: 'Modèle de lutte anti-fraude',
  subtitle: ' ',
  downloadDocuments: 'Télécharger vos documents',
  table: {
    name: 'Nom du document',
    risk: 'Niveau de risque',
    score: 'Score',
    status: 'Status',
  },
  dropdown: {
    details: 'Détails',
    download: 'Télécharger le document',
    downloadReport: 'Télécharger le rapport',
    delete: 'Supprimer',
  },
  levelStates: {
    low: 'Faible',
    medium: 'Moyen',
    high: 'Elevé',
    unknown: 'Indéterminé',
  },
  riskOverviewTitle: 'Analyse du risque de fraude pour le document {{name}}',
  sensitivitySettings: {
    modalTitle: 'Configurer la sensibilité du modèle',
    modalOpenButton: 'Ouvrir le configurateur de sensibilité',
    submitSettingsButton: 'Modifier',
    cancelSettingsButton: 'Annuler',
    seuilChangementAlignement: 'Rupture de ligne',
    seuilDifferenceEspace: 'Espacement entre les mots',
    seuilDifferenceCouleur: 'Colorimétrie du fond',
    metadataKeywords: 'Présence de métadonnées suspectes',
    metadataKeywordsBlacklist: 'Liste des métadonnées suspectes',
    numberOfDays: 'Nombre de jours depuis la dernière édition',
    minimumLayer: 'Nombre de minimum de calques',
    size: 'Différence de taille de police',
    font: 'Font',
  },
  assessmentList: {
    emptyListText: "Pas de données à l'appui",
    elementHeader: 'Couleur',
    valueHeader: 'Valeur',
  },
  imageNotFound: 'Image introuvable',
}

export default en
export type Translations = typeof en
