/** @jsxImportSource @emotion/react */

import { Pills } from 'common-components'
import { useTranslation } from '@hooks'
import { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router'
import { routes } from '@navigation/routes'

export const settingsTabs = {
  general: 'general',
  apiKeys: 'apiKeys',
  profile: 'profile',
  changePassword: 'password',
}

export const SettingsPage = function () {
  const { translate } = useTranslation()
  const { tab } = useParams()
  const navigate = useNavigate()

  const setTab = (tab: string) => navigate(routes.settings.navigate(tab))

  const options = useMemo(
    () => [
      {
        label: translate('settings.tabs.general'),
        value: settingsTabs.general,
      },
      {
        label: translate('settings.tabs.apiKeys'),
        value: settingsTabs.apiKeys,
      },
      {
        label: translate('settings.tabs.profile'),
        value: settingsTabs.profile,
      },
      {
        label: translate('settings.tabs.changePassword'),
        value: settingsTabs.changePassword,
      },
    ],
    [],
  )

  return (
    <Pills
      value={tab}
      onChange={(e) => setTab(e.target.value)}
      options={options}
      buttonProps={'test' as any}
    />
  )
}
