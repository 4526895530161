import {
  addManagerToCertification,
  addManagerToSegment,
  createUser,
  CreateUserPayloadType,
  deleteManagerFromCertification,
  deleteManagerFromSegment,
  deleteUser,
  downloadManagers,
  DownloadManagersPayloadType,
  downloadSampleImportUsersFile,
  getManagers,
  GetManagersPayloadType,
  importUsers,
  ImportUsersPayloadType,
  updateUser,
  UpdateUserPayloadType,
  uploadUserPhoto,
  UploadUserPhotoPayloadType,
} from '@api'
import { CertificationShortType, ManagerType, SegmentShortType } from '@pages/types'
import { downloadBlob, shortTime } from '@utils'
import { INVALID_EMAIL_HTTP_STATUS } from '@api/constant'
import { IdManagerRightType, TUserType } from '@definitions'
import { TxKeyPath } from '@i18n'

import { getApiError, getSuccess } from '../helper'
import { StoreGlobalType } from '../store'

import { ManagerSliceType } from './manager.slice'

import { certificationShortApiMapper, userDataApiMapper, segmentShortApiMapper } from '@/mappers'
import { SetState } from '@/store/store.type'

/**
 * "synchronizeManagers"
 * Given that the API does not always return the state of the database (POST, PUT, DELETE),
 * we are obliged to make an additional request to the API
 */
const synchronizeManagers = async (
  payload?: GetManagersActionPayloadType,
): Promise<ManagerType[]> => {
  const managersApi = await getManagers(payload)
  return managersApi.map((userApi): ManagerType => {
    const userData = userDataApiMapper(userApi)
    const segmentsShort = userApi?.segmentsManager?.map(segmentShortApiMapper)
    const certificationsShort = userApi?.certificationsManager?.map(certificationShortApiMapper)
    return {
      userData,
      segmentsShort,
      certificationsShort,
    }
  })
}

/*****************************************************************
 * GET MANAGERS
 *****************************************************************/

type GetManagersActionStatusType =
  | 'getManagers/loading'
  | 'getManagers/succeeded'
  | 'getManagers/failed'
export type GetManagersActionPayloadType = GetManagersPayloadType

export const getManagersAction =
  (
    set: SetState<
      Partial<ManagerSliceType & StoreGlobalType>,
      GetManagersActionStatusType,
      GetManagersActionPayloadType
    >,
  ) =>
  async (payload: GetManagersActionPayloadType) => {
    try {
      set(
        (state) => {
          state.getManagersAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'getManagers/loading', payload },
      )

      const managers = await synchronizeManagers(payload)

      set(
        (state) => {
          state.getManagersAction.status = 'SUCCEEDED'
          state.managers = managers
        },
        false,
        { type: 'getManagers/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.getManagersAction.status = 'FAILED'
          state.managers = []
          state.error = getApiError(e)
        },
        false,
        { type: 'getManagers/failed' },
      )
    }
  }

/******************************************************************
 * DOWNLOAD MANAGERS SAMPLE FILE
 *****************************************************************/

type DownloadManagersSampleFileActionStatusType =
  | 'downloadManagersSampleFile/loading'
  | 'downloadManagersSampleFile/succeeded'
  | 'downloadManagersSampleFile/failed'

export const downloadManagersSampleFileAction =
  (
    set: SetState<
      Partial<ManagerSliceType & StoreGlobalType>,
      DownloadManagersSampleFileActionStatusType
    >,
  ) =>
  async () => {
    try {
      set(
        (state) => {
          state.downloadManagersSampleFileAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'downloadManagersSampleFile/loading' },
      )

      const data = await downloadSampleImportUsersFile()
      downloadBlob([data], `InputFileSample_Users.xlsx`)

      set(
        (state) => {
          state.downloadManagersSampleFileAction.status = 'SUCCEEDED'
        },
        false,
        { type: 'downloadManagersSampleFile/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.downloadManagersSampleFileAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'downloadManagersSampleFile/failed' },
      )
    }
  }

/******************************************************************
 * DELETE MANAGERS
 *****************************************************************/

type DeleteManagersActionStatusType =
  | 'deleteManagers/loading'
  | 'deleteManagers/succeeded'
  | 'deleteManagers/failed'

export type DeleteManagersActionPayloadType = {
  idManagers: string[]
}

export const deleteManagersAction =
  (
    set: SetState<
      Partial<ManagerSliceType & StoreGlobalType>,
      DeleteManagersActionStatusType,
      DeleteManagersActionPayloadType
    >,
  ) =>
  async (payload: DeleteManagersActionPayloadType, callback?: () => void) => {
    try {
      set(
        (state) => {
          state.deleteManagersAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'deleteManagers/loading', payload },
      )

      for (const id of payload.idManagers) {
        await deleteUser({ idUser: id })
      }

      set(
        (state) => {
          state.deleteManagersAction.status = 'SUCCEEDED'
          state.managers = state.managers.filter(
            ({ userData }) => !payload.idManagers.includes(userData.idUser),
          )
        },
        false,
        { type: 'deleteManagers/succeeded' },
      )

      callback && callback()
    } catch (e) {
      set(
        (state) => {
          state.deleteManagersAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'deleteManagers/failed' },
      )
    }
  }

/******************************************************************
 * UPLOAD MANAGER FILE
 *****************************************************************/

type UploadManagersFileActionStatusType =
  | 'uploadManagersFile/loading'
  | 'uploadManagersFile/succeeded'
  | 'uploadManagersFile/failed'

export type UploadManagersFileActionPayloadType = ImportUsersPayloadType
export const uploadManagersFileAction =
  (
    set: SetState<
      Partial<ManagerSliceType & StoreGlobalType>,
      UploadManagersFileActionStatusType,
      UploadManagersFileActionPayloadType
    >,
  ) =>
  async (payload: UploadManagersFileActionPayloadType, callback?: () => void) => {
    try {
      set(
        (state) => {
          state.uploadManagersFileAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'uploadManagersFile/loading', payload },
      )

      await importUsers(payload)

      setTimeout(async () => {
        const managers = await synchronizeManagers()
        set(
          (state) => {
            state.uploadManagersFileAction.status = 'SUCCEEDED'
            state.managers = managers
            state.success = getSuccess({ messageT: 'certificationCreation.questions.refreshText' })
          },
          false,
          { type: 'uploadManagersFile/succeeded' },
        )

        callback && callback()
      }, 3000)
    } catch (e) {
      set(
        (state) => {
          state.uploadManagersFileAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'uploadManagersFile/failed' },
      )
    }
  }

/******************************************************************
 * DOWNLOAD MANAGERS
 *****************************************************************/
type DownloadManagersActionStatusType =
  | 'downloadManagers/loading'
  | 'downloadManagers/succeeded'
  | 'downloadManagers/failed'
export type DownloadManagersActionPayloadType = DownloadManagersPayloadType
export const downloadManagersAction =
  (
    set: SetState<
      Partial<ManagerSliceType & StoreGlobalType>,
      DownloadManagersActionStatusType,
      DownloadManagersActionPayloadType
    >,
  ) =>
  async (payload: DownloadManagersActionPayloadType) => {
    try {
      set(
        (state) => {
          state.downloadManagersAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'downloadManagers/loading', payload },
      )

      const data = await downloadManagers(payload)
      downloadBlob([data], `Users_${shortTime()}.xlsx`)

      set(
        (state) => {
          state.downloadManagersAction.status = 'SUCCEEDED'
        },
        false,
        { type: 'downloadManagers/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.downloadManagersAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'downloadManagers/failed' },
      )
    }
  }

/******************************************************************
 * CREATE MANAGER
 *****************************************************************/

type CreateManagerActionStatusType =
  | 'createManager/loading'
  | 'createManager/succeeded'
  | 'createManager/failed'

export type CreateManagerActionPayloadType = CreateUserPayloadType &
  Pick<UploadUserPhotoPayloadType, 'file'>

export const createManagerAction =
  (
    set: SetState<
      Partial<ManagerSliceType & StoreGlobalType>,
      CreateManagerActionStatusType,
      CreateManagerActionPayloadType
    >,
  ) =>
  async (payload: CreateManagerActionPayloadType, callback: (idManagerCreated: string) => void) => {
    try {
      set(
        (state) => {
          state.createManagerAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'createManager/loading', payload },
      )

      let urlAvatar = ''
      const userApi = await createUser({
        type: payload.type,
        phone: payload.phone,
        lastName: payload.lastName,
        firstName: payload.firstName,
        email: payload.email,
      })

      if (payload?.file) {
        urlAvatar = await uploadUserPhoto({
          idUser: userApi.idUser,
          file: payload.file,
        })
      }

      set(
        (state) => {
          state.createManagerAction.status = 'SUCCEEDED'
          state.managers.unshift({
            userData: {
              ...userDataApiMapper(userApi),
              urlAvatar,
            },
            segmentsShort: [],
            certificationsShort: [],
          })
        },
        false,
        { type: 'createManager/succeeded' },
      )
      callback && callback(userApi.idUser)
    } catch (e) {
      // eslint-disable-next-line prefer-const
      let { messageTranslate, duration, ...error } = getApiError(e)

      let descriptionTranslate: TxKeyPath

      if (typeof e === 'object') {
        if ('status' in e) {
          const status = e.status as number
          if (status === INVALID_EMAIL_HTTP_STATUS) {
            messageTranslate = 'managers.createModal.title'
            descriptionTranslate = 'managers.createModal.label'
            duration = 20
          }
        }
      }

      set(
        (state) => {
          state.createManagerAction.status = 'FAILED'
          state.error = {
            ...error,
            duration,
            descriptionTranslate,
            messageTranslate,
          }
        },
        false,
        { type: 'createManager/failed' },
      )
    }
  }

/******************************************************************
 * UPDATE MANAGER
 *****************************************************************/

type UpdateManagerActionStatusType =
  | 'updateManager/loading'
  | 'updateManager/succeeded'
  | 'updateManager/failed'

export type UpdateManagerActionPayloadType = UpdateUserPayloadType &
  Pick<UploadUserPhotoPayloadType, 'file'>

export const updateManagerAction =
  (
    set: SetState<
      Partial<ManagerSliceType & StoreGlobalType>,
      UpdateManagerActionStatusType,
      UpdateManagerActionPayloadType
    >,
  ) =>
  async (payload: UpdateManagerActionPayloadType) => {
    try {
      set(
        (state) => {
          state.updateManagerAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'updateManager/loading', payload },
      )

      let urlAvatar = ''
      const userApi = await updateUser({
        idUser: payload.idUser,
        firstName: payload.firstName,
        lastName: payload.lastName,
        type: payload.type,
        phone: payload.phone,
      })

      if (payload?.file) {
        urlAvatar = await uploadUserPhoto({
          idUser: userApi.idUser,
          file: payload.file,
        })
      }

      set(
        (state) => {
          state.updateManagerAction.status = 'SUCCEEDED'
          state.managers = state.managers.map((manager) => {
            if (!payload?.file) {
              urlAvatar = manager.userData.urlAvatar
            }

            if (manager.userData.idUser === payload.idUser) {
              return {
                ...manager,
                userData: {
                  ...userDataApiMapper(userApi),
                  urlAvatar,
                },
              }
            }
            return manager
          })
        },
        false,
        { type: 'updateManager/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.updateManagerAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'updateManager/failed' },
      )
    }
  }

/******************************************************************
 * ADD SEGMENT TO MANAGERS
 *****************************************************************/

type AddSegmentToManagersActionStatusType =
  | 'addSegmentToManagers/loading'
  | 'addSegmentToManagers/succeeded'
  | 'addSegmentToManagers/failed'

export type AddSegmentToManagersActionPayloadType = {
  segmentShort: SegmentShortType
  idManagers: string[]
  managerRight: IdManagerRightType
}
export const addSegmentToManagersAction =
  (
    set: SetState<
      Partial<ManagerSliceType & StoreGlobalType>,
      AddSegmentToManagersActionStatusType,
      AddSegmentToManagersActionPayloadType
    >,
    get: () => ManagerSliceType & StoreGlobalType,
  ) =>
  async (payload: AddSegmentToManagersActionPayloadType, callback?: () => void) => {
    try {
      set(
        (state) => {
          state.addSegmentToManagersAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'addSegmentToManagers/loading', payload },
      )

      for (const idManager of payload.idManagers) {
        const manager = get().managers.find((item) => item.userData.idUser === idManager)?.userData
        await addManagerToSegment({
          id: payload.segmentShort.idSegment,
          managers: [
            {
              idUser: manager.idUser,
              email: manager.email,
              firstName: manager.firstName,
              lastName: manager.lastName,
              type: manager.type,
              urlAvatar: manager.urlAvatar,
              managerRight: payload.managerRight,
            },
          ],
        })
      }

      set(
        (state) => {
          const managers = state.managers.map((manager): ManagerType => {
            if (payload.idManagers.includes(manager.userData.idUser)) {
              let segmentsShort = manager?.segmentsShort

              const newSegmentShort: SegmentShortType = {
                ...payload.segmentShort,
                managerRight: payload.managerRight,
              }
              const indexSegmentShort = manager?.segmentsShort?.findIndex(
                ({ idSegment }) => idSegment === payload.segmentShort.idSegment,
              )

              if (indexSegmentShort === -1) {
                segmentsShort = manager?.segmentsShort?.concat(newSegmentShort)
              } else {
                segmentsShort[indexSegmentShort] = newSegmentShort
              }

              return {
                ...manager,
                segmentsShort,
              }
            }
            return manager
          })

          state.addSegmentToManagersAction.status = 'SUCCEEDED'
          state.managers = managers
        },
        false,
        { type: 'addSegmentToManagers/succeeded' },
      )

      callback && callback()
    } catch (e) {
      set(
        (state) => {
          state.addSegmentToManagersAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'addSegmentToManagers/failed' },
      )
    }
  }

/******************************************************************
 * DELETE SEGMENT FROM MANAGERS
 *****************************************************************/

type DeleteSegmentFromManagersActionStatusType =
  | 'deleteSegmentFromManagers/loading'
  | 'deleteSegmentFromManagers/succeeded'
  | 'deleteSegmentFromManagers/failed'

export type DeleteSegmentFromManagersActionPayloadType = {
  idSegment: string
  idManagers: string[]
}
export const deleteSegmentFromManagersAction =
  (
    set: SetState<
      Partial<ManagerSliceType & StoreGlobalType>,
      DeleteSegmentFromManagersActionStatusType,
      DeleteSegmentFromManagersActionPayloadType
    >,
    get: () => ManagerSliceType & StoreGlobalType,
  ) =>
  async (payload: DeleteSegmentFromManagersActionPayloadType, callback?: () => void) => {
    try {
      set(
        (state) => {
          state.deleteSegmentFromManagersAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'deleteSegmentFromManagers/loading', payload },
      )

      for (const idManager of payload.idManagers) {
        const manager = get().managers.find((item) => item.userData.idUser === idManager)
        await deleteManagerFromSegment({
          id: payload.idSegment,
          managers: [
            {
              idUser: manager.userData.idUser,
              email: manager.userData.email,
              firstName: manager.userData.firstName,
              lastName: manager.userData.lastName,
              type: manager.userData.type,
              urlAvatar: manager?.userData.urlAvatar,
              managerRight: manager?.segmentsShort.find(
                (item) => item.idSegment === payload.idSegment,
              )?.managerRight,
            },
          ],
        })
      }

      set(
        (state) => {
          const managers = state.managers.map((manager): ManagerType => {
            if (payload.idManagers.includes(manager.userData.idUser)) {
              const segmentsShort = manager.segmentsShort.filter(
                ({ idSegment }) => idSegment !== payload.idSegment,
              )
              return {
                ...manager,
                segmentsShort,
              }
            }
            return manager
          })
          state.deleteSegmentFromManagersAction.status = 'SUCCEEDED'
          state.managers = managers
        },
        false,
        { type: 'deleteSegmentFromManagers/succeeded' },
      )

      callback && callback()
    } catch (e) {
      set(
        (state) => {
          state.deleteSegmentFromManagersAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'deleteSegmentFromManagers/failed' },
      )
    }
  }

/******************************************************************
 * ADD CERTIFICATION TO MANAGERS
 *****************************************************************/

type AddCertificationToManagersActionStatusType =
  | 'addCertificationToManagers/loading'
  | 'addCertificationToManagers/succeeded'
  | 'addCertificationToManagers/failed'

export type AddCertificationToManagersActionPayloadType = {
  certificationShort: CertificationShortType
  idManagers: string[]
  managerRight: IdManagerRightType
}
export const addCertificationToManagersAction =
  (
    set: SetState<
      Partial<ManagerSliceType & StoreGlobalType>,
      AddCertificationToManagersActionStatusType,
      AddCertificationToManagersActionPayloadType
    >,
    get: () => ManagerSliceType & StoreGlobalType,
  ) =>
  async (payload: AddCertificationToManagersActionPayloadType, callback?: () => void) => {
    try {
      set(
        (state) => {
          state.addCertificationToManagersAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'addCertificationToManagers/loading', payload },
      )

      for (const idManager of payload.idManagers) {
        const manager = get().managers.find((item) => item.userData.idUser === idManager)?.userData
        await addManagerToCertification({
          id: payload.certificationShort.idCertification,
          managers: [
            {
              idUser: manager.idUser,
              email: manager.email,
              firstName: manager.firstName,
              lastName: manager.lastName,
              type: manager.type as TUserType,
              urlAvatar: manager?.urlAvatar,
              managerRight: payload.managerRight,
            },
          ],
        })
      }

      set(
        (state) => {
          const managers = state.managers.map((manager): ManagerType => {
            if (payload.idManagers.includes(manager.userData.idUser)) {
              let certificationsShort = manager?.certificationsShort

              const newCertificationShort: CertificationShortType = {
                ...payload.certificationShort,
                managerRight: payload.managerRight,
              }
              const indexCertificationShort = manager?.certificationsShort?.findIndex(
                ({ idCertification }) =>
                  idCertification === payload.certificationShort.idCertification,
              )

              if (indexCertificationShort === -1) {
                certificationsShort = manager?.certificationsShort?.concat(newCertificationShort)
              } else {
                certificationsShort[indexCertificationShort] = newCertificationShort
              }

              return {
                ...manager,
                certificationsShort,
              }
            }
            return manager
          })

          state.addCertificationToManagersAction.status = 'SUCCEEDED'
          state.managers = managers
        },
        false,
        { type: 'addCertificationToManagers/succeeded' },
      )

      callback && callback()
    } catch (e) {
      set(
        (state) => {
          state.addCertificationToManagersAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'addCertificationToManagers/failed' },
      )
    }
  }

/******************************************************************
 * DELETE CERTIFICATION FROM MANAGERS
 *****************************************************************/

type DeleteCertificationFromManagersActionStatusType =
  | 'deleteCertificationFromManagers/loading'
  | 'deleteCertificationFromManagers/succeeded'
  | 'deleteCertificationFromManagers/failed'

export type DeleteCertificationFromManagersActionPayloadType = {
  idCertification: string
  idManagers: string[]
}
export const deleteCertificationFromManagersAction =
  (
    set: SetState<
      Partial<ManagerSliceType & StoreGlobalType>,
      DeleteCertificationFromManagersActionStatusType,
      DeleteCertificationFromManagersActionPayloadType
    >,
    get: () => ManagerSliceType & StoreGlobalType,
  ) =>
  async (payload: DeleteCertificationFromManagersActionPayloadType, callback?: () => void) => {
    try {
      set(
        (state) => {
          state.deleteCertificationFromManagersAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'deleteCertificationFromManagers/loading', payload },
      )

      for (const idManager of payload.idManagers) {
        const manager = get().managers.find((item) => item.userData.idUser === idManager)
        await deleteManagerFromCertification({
          id: payload.idCertification,
          managers: [
            {
              idUser: manager.userData.idUser,
              email: manager.userData.email,
              firstName: manager.userData.firstName,
              lastName: manager.userData.lastName,
              type: manager.userData.type,
              urlAvatar: manager.userData?.urlAvatar,
              managerRight: manager?.certificationsShort.find(
                (item) => item.idCertification === payload.idCertification,
              )?.managerRight,
            },
          ],
        })
      }

      set(
        (state) => {
          const managers = state.managers.map((manager): ManagerType => {
            if (payload.idManagers.includes(manager.userData.idUser)) {
              const certificationsShort = manager.certificationsShort.filter(
                ({ idCertification }) => idCertification !== payload.idCertification,
              )
              return {
                ...manager,
                certificationsShort,
              }
            }
            return manager
          })
          state.deleteCertificationFromManagersAction.status = 'SUCCEEDED'
          state.managers = managers
        },
        false,
        { type: 'deleteCertificationFromManagers/succeeded' },
      )

      callback && callback()
    } catch (e) {
      set(
        (state) => {
          state.deleteCertificationFromManagersAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'deleteCertificationFromManagers/failed' },
      )
    }
  }
