const en = {
  apply: 'Apply filters',
  filter: 'Filter',

  certifications: 'Certifications',
  endDate: 'End date',
  category: 'Category',
  managers: 'Managers',
  models: 'Models',
  role: 'Role',
  segments: 'Segments',
  startDate: 'Start date',
  skill: 'Skill',
  state: 'State',
  quality: 'Quality',
  risk: 'Risk',
  score: 'Find specific score or higher values',

  deleting: 'You are about to delete this item',
  areYouSure: 'Are you sure?',

  buttons: {
    confirm: 'Confirm',
    delete: 'Delete',
    edit: 'Edit',
    goBack: 'Go back',
    return: 'Return',
    showLess: 'Show less',
    showMore: 'Show more',
    open: 'Open',
  },

  states: {
    certificationState: {
      1: 'Draft',
      2: 'Draft',
      3: 'Draft',
      4: 'Draft',
      10: 'Online',
      20: 'Offline',
      100: 'Archived',
    },
    eventType: {
      //TASK EVENTS
      100: 'Task created',
      101: 'Model extracted',
      102: 'Init hit created',
      103: 'Hits comparison',
      104: 'Additional hit created',
      105: 'Hit review created',
      106: 'Override hit created',
      107: 'Task done',

      // HIT EVENTS
      200: 'Hit created',
      201: 'Hit is processed',
      202: 'Hit manually released',
      203: 'Hit automatically released',
      204: 'Hit auto attribution',
      205: 'Hit manual attribution',
      206: 'Hit review added',
      207: 'Hit done',

      // DOCUMENT EVENTS
      300: 'Document created',
      301: 'Document model evaluated',
      302: 'Document extracted',
      303: 'Document extraction result',
    },
    formState: {
      10: 'Creating',
      20: 'Published',
      99: 'Archived',
    },
    modelCategory: {
      10: 'Owned',
      100: 'Wirk',
    },
    modelState: {
      10: 'Create',
      20: 'Train',
      30: 'Online',
      100: 'Error',
      999: 'Archived',
    },
    questionState: {
      1: 'Draft',
      10: 'Published',
      20: 'Deleted',
    },
    projectState: {
      1: 'Draft',
      2: 'Draft',
      3: 'Draft',
      4: 'Draft',
      10: 'Online',
      15: 'Boosted',
      20: 'Done',
      30: 'Archived',
      40: 'Stop',
      50: 'Paused',
    },
    userAccessLevel: {
      0: 'No access',
      10: 'Creator',
      20: 'User',
      30: 'Admin',
    },
    userRole: {
      0: 'Super admin',
      10: 'Admin',
      20: 'Manager',
      30: 'Worker',
      40: 'Candidate',
    },
    userCertificationState: {
      0: 'New',
      10: 'In progress',
      20: 'Done',
      30: 'Reviewed',
    },
    taskState: {
      10: 'In creation',
      20: 'On hold',
      30: 'In progress',
      80: 'Urgent',
      90: 'Behind schedule',
      100: 'Completed',
      900: 'Error',
      999: 'Cancelled',
    },
    hitType: {
      1: 'Initial',
      2: 'Iteration',
      3: 'Arbitrage',
      4: 'Review',
      5: 'Override',
    },
    hitState: {
      1: 'In creation',
      2: 'On hold',
      3: 'In progress',
      4: 'Completed',
      5: 'Validated',
      6: 'Refused',
      100: 'Cancelled',
    },
    segmentState: {
      10: 'Manual',
      20: 'Competence',
      30: 'Certification',
      40: 'Socio-demographic',
    },
    skill: {
      0: 'Simple Data Entry',
      1: 'Complex Data Entry',
      2: 'Simple Document Analysis',
      3: 'Complex Document Analysis',
      4: 'Folder Analysis',
      5: 'Content Redaction',
      6: 'Web Search',
      7: 'Bounding',
      8: 'Transcription',
      9: 'Outgoing Call',
      10: 'Incoming Call',
      11: 'Office Automation',
    },
    quality: {
      10: 'Simple',
      20: 'Multi',
      30: 'Panel',
      40: 'Relecture',
    },
    userActivity: {
      0: 'Online',
      1: 'Offline',
      2: 'Waiting Activation',
      3: 'Archived',
    },
    riskState: {
      0: 'New',
      10: 'In progress',
      20: 'Done',
      90: 'Error',
      99: 'Archived',
    },
  },
}

export default en

export type Translations = typeof en
