/** @jsxImportSource @emotion/react */

import {
  SettingsCertification,
  CreateDraftQuestionsCertification,
} from '@views/shared/certification'
import { useNavigate } from 'react-router'
import { TxKeyPath } from '@i18n'
import { routes } from '@navigation/routes'
import {
  ActionModal,
  colors,
  Text,
  useDisclosure,
  Divider,
  LayoutContainer,
  Sider,
  Steps,
  Title,
  Button,
  Icon,
} from 'common-components'
import { useTranslation } from '@hooks'
import { CertificationType } from '@pages/types/certification'
import { PageLayout } from '@components/layout'

import FormSelectorCertification from './FormSelectorCertification'

import { store } from '@/store'

type CreateCertificationPropsType = {
  certification: CertificationType
  currentStep: number
}
const CreateCertification = ({ certification, currentStep }: CreateCertificationPropsType) => {
  const updateNextStepCertificationAction = store.use.updateNextStepCertificationAction().call

  const navigate = useNavigate()
  const { translate } = useTranslation()
  const { open, isOpen, close } = useDisclosure()

  const onNextStepMutation = () => {
    updateNextStepCertificationAction(
      { idCertification: certification.idCertification },
      (nextStep) => {
        navigate(
          routes.certificationCreation.navigate(certification.idCertification, String(nextStep)),
        )
      },
    )
  }

  const goToOverview = async () => {
    updateNextStepCertificationAction({ idCertification: certification.idCertification }, () => {
      navigate(routes.certificationQuestions.navigate(certification.idCertification))
      close()
    })
  }

  const goToList = () => {
    navigate(routes.certifications.navigate())
    close()
  }

  // TODO HIDE | not remove
  // const onClickPrev = () => {
  // 	currentStep - 1 >= 0 &&
  // 		navigate(
  // 			routes.certificationCreation.navigate(
  // 				idCertification,
  // 				(currentStep - 1).toString(),
  // 			),
  // 		);
  // };

  return (
    <LayoutContainer>
      <LayoutContainer style={{ padding: 18 }}>
        <Sider collapsedWidth={82} collapsed theme={'light'}>
          <Button size='sm' onClick={() => navigate(routes.certifications.navigate())}>
            <Icon icon='XClose' />
          </Button>
        </Sider>
        <Steps
          type='navigation'
          current={currentStep - 1}
          items={[
            {
              title: translate('certificationCreation.steps.1'),
            },
            {
              title: translate('certificationCreation.steps.2'),
            },
            {
              title: translate('certificationCreation.steps.3'),
            },
          ]}
          style={{ maxWidth: 792, alignSelf: 'center' }}
          size='small'
        />
      </LayoutContainer>
      <Divider style={{ margin: '0 0 24px' }} />
      <LayoutContainer style={{ maxWidth: 1216, width: '100%', alignSelf: 'center' }}>
        <Title preset='singlePageTitle'>
          {translate(`certificationCreation.stepPageTitle.${currentStep}` as TxKeyPath)}
        </Title>
        <Title preset='tablePageSubtitle' style={{ fontSize: 14, marginBottom: 16 }}>
          {translate(`certificationCreation.stepPageSubtitle.${currentStep}` as TxKeyPath)}
        </Title>
        <PageLayout style={{ width: '100%' }}>
          {currentStep === 1 && (
            <FormSelectorCertification
              certification={certification}
              onNextStep={onNextStepMutation}
            />
          )}
          {currentStep === 2 && (
            <CreateDraftQuestionsCertification
              certification={certification}
              onNextStep={onNextStepMutation}
            />
          )}
          {currentStep === 3 && (
            <SettingsCertification
              includedFormParts={['settings', 'segments', 'managers', 'audience']}
              state={'creating'}
              certification={certification}
              onNextStep={open}
            />
          )}
          <ActionModal
            open={isOpen}
            maskClosable={false}
            title={translate('certificationCreation.creationSuccessTitle')}
            icon={'CheckCircle'}
            iconColor={colors.success600}
            iconBgColor={colors.success100}
            onConfirm={goToOverview}
            onCancel={goToList}
            actionButtons={[
              {
                text: translate('common.buttons.return'),
                onClick: goToList,
              },
              {
                type: 'primary',
                text: translate('common.buttons.open'),
                onClick: goToOverview,
              },
            ]}
          >
            <Text>{translate('certificationCreation.creationSuccessSubtitle')}</Text>
          </ActionModal>
        </PageLayout>
      </LayoutContainer>
    </LayoutContainer>
  )
}

export default CreateCertification
