import { Risk } from '@views/risk'
import { useQuery } from 'react-query'
import { getRisk } from '@api/risk'
import { useParams } from 'react-router-dom'
import { useState } from 'react'
import { RiskType } from '@pages/types'
import { mutationKeys } from '@definitions'
import { LayoutContainer } from 'common-components'

import { riskMapperApi } from '@/mappers'
import { RiskProvider } from '@/context'

const RiskPage = () => {
  const { idRisk } = useParams<{
    idRisk: string
  }>()

  const [risk, setRisk] = useState<RiskType>()

  const { isLoading, isRefetching } = useQuery({
    queryKey: mutationKeys.risks({ id: idRisk }),
    queryFn: () => getRisk(idRisk),
    enabled: !!idRisk,
    onSuccess: (riskApi) => {
      const riskData = riskMapperApi(riskApi)
      setRisk(riskData)
    },
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  })

  return (
    <LayoutContainer style={{ minHeight: '100vh' }}>
      <RiskProvider>
        {!!risk && <Risk risk={risk} isLoading={isLoading || isRefetching} />}
      </RiskProvider>
    </LayoutContainer>
  )
}

export default RiskPage
