import { useQuery } from 'react-query'
import {
  mutationKeys,
  SociodemographicCriteria,
  TSociodemographicCriteria,
  TSociodemographicCriteriaValues,
} from '@definitions'
import { getSociodemographicResource } from '@api/resource'
import { getStateKeyByValue } from '@utils'
import { Resource } from '@types'

export const useSociodemographicResources = () => {
  const { data: resources = [], refetch } = useQuery({
    queryKey: mutationKeys.sociodemographicResource(),
    queryFn: () => getSociodemographicResource(),
    refetchOnWindowFocus: false,
  })

  const getResource = (
    type: TSociodemographicCriteriaValues | TSociodemographicCriteria,
  ): Resource => {
    const criteria =
      typeof type === 'number' ? type : getStateKeyByValue(SociodemographicCriteria, type)
    return resources.find((res) => res.socioDemographicCriteria === criteria)
  }

  const getResourceOptions = (
    type: TSociodemographicCriteriaValues | TSociodemographicCriteria,
  ) => {
    const resource = getResource(type)
    const { values = {} } = resource || {}
    return Object.keys(values).map((key) => ({
      label: key,
      criteria: resource.socioDemographicCriteria,
      value: values[key],
    }))
  }

  return {
    resources,
    refetch,
    getResource,
    getResourceOptions,
  }
}
