/** @jsxImportSource @emotion/react */

import { getSegments } from '@api'
import { SegmentType } from '@pages/types'
import { useEffect, useState } from 'react'
import { ISelectInputProps, ISelectOptionProps, SelectInput, SelectOption } from 'common-components'

import { segmentApiMapper } from '@/mappers'
import { store } from '@/store'

type SelectSegmentsPropsType = ISelectInputProps & {
  optionProps?: Partial<ISelectOptionProps>
}
const SelectSegments = ({ optionProps, ...rest }: SelectSegmentsPropsType) => {
  const idMe = store.use.me().userData.idUser

  const [segments, setSegments] = useState<SegmentType[]>([])

  useEffect(() => {
    getSegments({ model: {} }).then((segmentsApi) => {
      const result = segmentsApi?.map((segmentApi) => segmentApiMapper(segmentApi, idMe))
      setSegments(result)
    })
  }, [])

  return (
    <SelectInput mode={'tags'} {...rest}>
      {segments.map((segment, i) => (
        <SelectOption
          key={`${i}-${segment.idSegment}`}
          value={segment.idSegment}
          title={`${segment.name}`}
          {...optionProps}
        >
          {segment.name}
        </SelectOption>
      ))}
    </SelectInput>
  )
}

export default SelectSegments
