import { createContext, PropsWithChildren, useState } from 'react'

type RiskContextData = {
  // detailed settings
  disabledColors: string[]
  toggleDisabledColor: (color: string) => void
  //   pages
  currentPage: number
  onPageChange: (nextPage: number) => void
  setTotalPages: (total: number) => void
  isFirstPage: boolean
  isLastPage: boolean
}

export const RiskContext = createContext<RiskContextData | null>(null)

const { Provider } = RiskContext

export function RiskProvider({ children }: PropsWithChildren<unknown>) {
  const [currentPage, setCurrentPage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [disabledColors, setDisabledColors] = useState([])

  const toggleDisabledColor = (color: string) => {
    setDisabledColors((prevColors) => {
      if (prevColors.includes(color)) {
        return prevColors.filter((c) => c !== color)
      } else {
        return [...prevColors, color]
      }
    })
  }

  const onPageChange = (nextPage: number) => {
    setCurrentPage(nextPage)
  }

  const isFirstPage = currentPage === 0
  const isLastPage = currentPage >= totalPages - 1

  return (
    <Provider
      value={{
        disabledColors,
        toggleDisabledColor,
        currentPage,
        onPageChange,
        setTotalPages,
        isFirstPage,
        isLastPage,
      }}
    >
      {children}
    </Provider>
  )
}
