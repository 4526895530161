import { useTranslation } from '@hooks/useTranslation'
import { useEffect, useState } from 'react'
import { getForm, getForms, GetFormsPayloadType } from '@api'
import { getStateKeyByValue } from '@utils'
import { FormState, getWirkUrl } from '@definitions'
import openLink from '@utils/openLink'
import { useParams } from 'react-router-dom'
import { FormType } from '@pages/types'
import { Button, Modal, Space } from 'common-components'

import FiltersBarForm from '../FiltersBarForm'
import CardFormSelected from '../CardFormSelected'

import CardForm from './CardForm'

import { formApiMapper } from '@/mappers'
import { store } from '@/store'

type FormSelectorPropsType = {
  idFormSelectedInit?: string
  onFormSelect: (form: FormType) => void
  useDisclosure: { isOpen: boolean; open: () => void; close: () => void }
}

const FormSelector = ({
  idFormSelectedInit,
  onFormSelect,
  useDisclosure,
}: FormSelectorPropsType) => {
  const accessToken = store.use.me().accessToken
  const paramsUrl = useParams()
  const { translate } = useTranslation()

  const [idFormSelected, setIdFormSelected] = useState<string>(idFormSelectedInit)
  const [formSelected, setFormSelected] = useState<FormType>()
  const [forms, setForms] = useState<FormType[]>([])

  const [params, setParams] = useState<GetFormsPayloadType>({
    model: { state: [getStateKeyByValue(FormState, 'published')] },
  })

  useEffect(() => {
    if (idFormSelected) {
      getForm(idFormSelected).then((formApi) => {
        const result = formApiMapper(formApi)
        setFormSelected(result)
        onFormSelect(result)
      })
    }
  }, [idFormSelected])

  useEffect(() => {
    getForms(params).then((formsApi) => {
      const result = formsApi?.map(formApiMapper)
      setForms(result)
    })
  }, [JSON.stringify(params)])

  const onParamsChange = (newParams: GetFormsPayloadType) => {
    const merge = { ...params, ...newParams }
    setParams({
      ...merge,
      model: {
        ...merge.model,
      },
    })
  }

  const onFormPreview = (idForm: string) =>
    openLink(
      `${getWirkUrl('Form')}/form/${idForm}/preview?token=${accessToken}&tenant=${
        paramsUrl.tenant
      }`,
    )

  return (
    <>
      <Space style={{ width: '100%' }} size={20} direction={'vertical'}>
        {!!formSelected?.template?.template && (
          <CardFormSelected templateFrom={formSelected.template.template} />
        )}
        <Button onClick={useDisclosure.open} size='sm'>
          {translate(`form.chooseFormButton`)}
        </Button>
      </Space>
      <Modal
        width={1000}
        open={useDisclosure.isOpen}
        onOk={useDisclosure.close}
        onCancel={useDisclosure.close}
        title={translate(`form.formListTitle`)}
        footer={null}
      >
        <Space direction='vertical' style={{ width: '100%' }}>
          <FiltersBarForm initValues={params} onFiltersChange={onParamsChange} />
          <Space size={10} wrap>
            {forms.map((form, i) => (
              <CardForm
                key={`${i}-${form.idForm}`}
                form={form}
                onFormSelect={setIdFormSelected}
                onFormPreview={onFormPreview}
              />
            ))}
          </Space>
        </Space>
      </Modal>
    </>
  )
}

export default FormSelector
