import { Collapse, Panel, Row, Space, Title, Text, Toggle } from 'common-components'
import { Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react'

type CollapseWithPillPropsType = {
  panels: {
    title: string
    content: ReactNode
    subtitle?: string
    isOpen?: boolean
    setIsOpen?: Dispatch<SetStateAction<boolean>>
    withPill?: boolean
    titleLevel?: 1 | 2 | 3 | 4 | 5 | 6
  }[]
}

const CollapseWithPill = ({ panels }: CollapseWithPillPropsType) => {
  const [activeKeys, setActiveKeys] = useState<string[]>([])

  const updateActiveKeys = () => {
    setActiveKeys(panels.filter((panel) => panel.isOpen).map((panel) => panel.title))
  }

  useEffect(() => {
    updateActiveKeys()
  }, [])

  return (
    <Collapse activeKey={activeKeys} style={{ width: '100%' }}>
      {panels.map(
        ({ title, content, subtitle, isOpen, setIsOpen, withPill = true, titleLevel = 5 }) => {
          useEffect(() => {
            updateActiveKeys()
          }, [isOpen])

          return (
            <Panel
              header={
                <Row
                  style={{ padding: 24 }}
                  justify='space-between'
                  onClick={(e) => e.stopPropagation()}
                >
                  <Space direction='vertical'>
                    {titleLevel === 6 ? (
                      <Text size='sm' strong>
                        {title}
                      </Text>
                    ) : (
                      <Title level={titleLevel}>{title}</Title>
                    )}
                    {subtitle && <Text size='sm'>{subtitle}</Text>}
                  </Space>
                  {withPill && (
                    <Toggle checked={isOpen} onChange={(checked) => setIsOpen(checked)} />
                  )}
                </Row>
              }
              key={title}
              showArrow={false}
              style={{ borderTop: 'none' }}
            >
              {content}
            </Panel>
          )
        },
      )}
    </Collapse>
  )
}

export default CollapseWithPill
