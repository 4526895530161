/** @jsxImportSource @emotion/react */

import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/react'
import { IdManagerRightType } from '@definitions'
import { LoadingOutlined } from '@ant-design/icons'
import { AssignCertification } from '@views/shared/certification'
import { AssignSegmentRight } from '@views/shared/segment'
import { FormManager, CreateUpdateFormManagerType } from '@views/shared/manager'
import { Text, Row, ActionModal, colors } from 'common-components'
import { CertificationShortType, SegmentShortType } from '@pages/types'

type CreateManagerModalComponentPropsType = {
  isOpen: boolean
  isLoading: boolean
  onCreateManager(managerForm: CreateUpdateFormManagerType, onSuccess: () => void): void
  onAddSegment: (segmentShort: SegmentShortType, managerRight: IdManagerRightType) => void
  onDeleteSegment: (idSegment: string) => void
  onAddCertification: (
    certificationShort: CertificationShortType,
    managerRight: IdManagerRightType,
  ) => void
  onDeleteCertification: (idCertification: string) => void
  close: () => void
}

const TOTAL_STEPS = 3

const CreateManagerModalComponent = ({
  onCreateManager,
  onDeleteSegment,
  onAddCertification,
  onDeleteCertification,
  onAddSegment,
  close,
  isOpen,
  isLoading,
}: CreateManagerModalComponentPropsType) => {
  const [managerForm, setManagerForm] = useState<CreateUpdateFormManagerType>(null)

  const { t } = useTranslation()
  const [step, setStep] = useState(1)
  const [isFormValid, setIsFormValid] = useState<boolean>(false)

  return (
    <>
      <ActionModal
        maskClosable={false}
        key={
          [t('createUser.addManager'), 'Choisir des segments', 'Choisir des certifications'][
            step - 1
          ]
        }
        open={isOpen}
        onOk={close}
        customHeader={
          <Row
            css={css`
              flex-direction: column;
              gap: 5px;
            `}
          >
            <Text color={colors.gray400} size='md'>
              {step} {t('createSegment.outOf')} {TOTAL_STEPS}
            </Text>
            <Text
              css={css`
                font-weight: 500;
              `}
              color={colors.gray900}
              size='xl'
            >
              {step === 1 && t('createUser.addManager')}
              {step === 2 && 'Choisir des segments'}
              {step === 3 && 'Choisir des certifications'}
            </Text>
          </Row>
        }
        onConfirm={close}
        // preset={preset}
        title={
          [t('createUser.addManager'), 'Choisir des segments', 'Choisir des certifications'][
            step - 1
          ]
        }
        onCancel={() => {
          setStep && setStep(1)
          close()
        }}
        actionButtons={[
          {
            text: t('managers.cancel'),
            onClick: () => {
              setStep && setStep(1)
              close()
            },
          },
          {
            text: isLoading ? <LoadingOutlined /> : t('createSegment.next'),
            type: 'primary',
            disabled: !isFormValid,
            onClick: async () => {
              if (setStep) {
                //////// STEP 1 ////////
                if (step === 1) {
                  if (isFormValid && managerForm?.createUser) {
                    onCreateManager(managerForm, () => {
                      setManagerForm(null)
                      setStep(step + 1)
                    })
                  }
                }
                //////// STEP 2 ////////
                if (step === 2) {
                  // TODO add certification
                  setStep(step + 1)
                  return
                }
                //////// STEP 3 ////////
                if (step === 3) {
                  close()
                  setStep(step + 1)
                  return
                }
              } else {
                close()
              }
            },
          },
        ]}
      >
        <>
          {step === 1 && (
            <FormManager
              isFormValid={(validation) => setIsFormValid(validation)}
              onChangeForm={setManagerForm}
            />
          )}
          {step === 2 && (
            <AssignSegmentRight onAddSegment={onAddSegment} onDeleteSegment={onDeleteSegment} />
          )}
          {step === 3 && (
            <AssignCertification
              onAddCertification={onAddCertification}
              onDeleteCertification={onDeleteCertification}
            />
          )}
        </>
      </ActionModal>
    </>
  )
}

const CreateManagerModal = memo(
  CreateManagerModalComponent,
  (
    oldProps: CreateManagerModalComponentPropsType,
    newProps: CreateManagerModalComponentPropsType,
  ) => {
    return JSON.stringify(oldProps) === JSON.stringify(newProps)
  },
)

export default CreateManagerModal
