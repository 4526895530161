const fr = {
  managers: 'Managers',
  export: 'Exporter',
  import: 'Importer',
  filter: 'Filtrer',
  addManager: 'Ajouter un manager',
  manageTeamInfo: "Gérez vos membres d'équipe et leurs permissions de compte ici.",
  name: 'Nom',
  old: 'Ancie.',
  role: 'Rôle',
  segments: 'Segments',
  certifications: 'Certifications',
  status: 'Statut',
  actions: 'Actions',
  edit: 'Modifier',
  assignSegment: 'Affecter un segment',
  assignCertification: 'Affecter une certification',
  delete: 'Supprimer',
  previous: 'Précédent',
  next: 'Suivant',
  selectedManager: 'Manager sélectionnés',
  selectedManagers: 'Managers sélectionnés',
  assignCertifications: 'Affecter à des certifications',
  assignSegments: 'Affecter à des segment',
  contributor: 'Contributeur',
  performance: 'Performance',
  effectiveTime: 'Temps effectif',
  candidate: 'Candidat',
  deleteUser1: 'Supprimer',
  deleteUser2: 'utilisateur?',
  deleteUser3: 'utilisateurs?',
  confirmAction: 'Êtes-vous sur de vouloir effectuer cette action ?',
  cancel: 'Annuler',
  confirm: 'Confirmer',
  back: 'Retour',
  validate: 'Valider',
  years: 'ans',
  year: 'an',
  superAdmin: 'Super Administrateur',
  admin: 'Administrateur',
  manager: 'Gestionnaire',
  worker: 'Travailleur',
  candidat: 'Candidat',
  active: 'Actif',
  inactive: 'Inactif',
  waiting: 'En attente...',
  deleteModal: {
    title: 'Supprimer le Manager ?',
    label: 'Êtes-vous sûr de vouloir effectuer cette action ?',
  },
  createModal: {
    title: 'Erreur dans la saisie du formulaire',
    label: "L'adresse e-mail est déjà utilisée",
  },
}

export default fr
