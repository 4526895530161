/** @jsxImportSource @emotion/react */

import { ColumnsType } from 'antd/lib/table'
import { useNavigate } from 'react-router'
import { routes } from '@navigation/routes'
import { useTranslation } from '@hooks'
import { DataLengthTag } from '@components/display'
import { CertificationUserTestType } from '@pages/types'
import { useMemo, useRef } from 'react'
import { DeleteModal } from '@components/modal'
import {
  Col,
  Content,
  IconButton,
  ITableProps,
  Row,
  Space,
  Table,
  Text,
  Title,
  Button,
  colors,
  Avatar,
  useDisclosure,
} from 'common-components'

import { store } from '@/store'

export type RespondentsCertificationPropsType = {
  respondentsFiltered: CertificationUserTestType[]
  tableProps?: Omit<ITableProps<CertificationUserTestType>, 'data' | 'columns'>
}

const RespondentsCertification = ({
  respondentsFiltered,
  tableProps = {},
}: RespondentsCertificationPropsType) => {
  const navigate = useNavigate()
  const { translate } = useTranslation()

  const downloadRespondentsCertificationAction =
    store.use.downloadRespondentsCertificationAction().call
  const deleteRespondentCertificationAction = store.use.deleteRespondentCertificationAction().call

  const isLoadingDeleteRespondentCertificationAction =
    store.use.deleteRespondentCertificationAction().status === 'LOADING'

  const idRespondentSelected = useRef<string>()

  const useDisclosureDeleteRespondentModal = useDisclosure()

  const tableColumns: ColumnsType<CertificationUserTestType> = useMemo(() => {
    try {
      return [
        {
          title: translate('certificationPage.respondentsTable.respondent'),
          key: 'idUser',
          sorter: (a, b) => {
            if (a.idUser < b.idUser) return -1
            if (a.idUser > b.idUser) return 1
            return 0
          },
          render: (record: CertificationUserTestType) => (
            <Row align={'middle'}>
              <Space size={'middle'}>
                <Avatar size='sm' src={record.user?.urlAvatar} />
                <Text size='sm' strong>
                  {record.user?.firstName} {record.user?.lastName}
                </Text>
              </Space>
            </Row>
          ),
        },
        {
          title: translate('certificationPage.respondentsTable.type'),
          key: 'type',
          sorter: (a, b) => {
            if (a.userType < b.userType) return -1
            if (a.userType > b.userType) return 1
            return 0
          },
          render: (record: CertificationUserTestType) => (
            <Row align={'middle'}>
              <Text size='xs'>{translate(`common.states.userRole.${record.user.type}`)}</Text>
            </Row>
          ),
        },
        {
          title: translate('certificationPage.respondentsTable.date'),
          key: 'creationDate',
          sorter: (a, b) => {
            if (a.creationDate < b.creationDate) return -1
            if (a.creationDate > b.creationDate) return 1
            return 0
          },
          render: (record: CertificationUserTestType) => (
            <Row align={'middle'}>
              <Text size='xs'>{new Date(record.creationDate).toLocaleDateString()}</Text>
            </Row>
          ),
        },
        {
          title: translate('certificationPage.respondentsTable.time'),
          key: 'duration',
          sorter: (a, b) => {
            if (a.duration < b.duration) return -1
            if (a.duration > b.duration) return 1
            return 0
          },
          render: (record: CertificationUserTestType) => (
            <Row align={'middle'}>
              <Text size='xs'>{record.duration}</Text>
            </Row>
          ),
        },
        {
          title: translate('certificationPage.respondentsTable.score'),
          key: 'mark',
          sorter: (a, b) => a.mark - b.mark,
          render: (record: CertificationUserTestType) => {
            return (
              <Row align={'middle'}>
                <Text size='xs'>{record.mark && `${record.mark}%`}</Text>
              </Row>
            )
          },
        },
        {
          title: translate('certificationPage.respondentsTable.state'),
          key: 'state',
          sorter: (a, b) => a.state - b.state,
          render: (record: CertificationUserTestType) => (
            <Row align={'middle'}>
              <Text size='xs'>
                {translate(`common.states.userCertificationState.${record.state}`)}
              </Text>
            </Row>
          ),
        },
        {
          title: translate('certificationPage.respondentsTable.actions'),
          key: 'idCertification',
          sorter: false,
          render: (record: CertificationUserTestType) => (
            <Space align={'center'} size={5}>
              <IconButton
                type={'link'}
                icon={'Eye'}
                iconProps={{ color: colors.gray600 }}
                onClick={() =>
                  navigate(
                    routes.certificationRespondent.navigate(
                      record.idCertification,
                      record.idCertificationUserTest,
                    ),
                  )
                }
              />
              <IconButton
                type={'link'}
                icon='RefreshCW01'
                iconProps={{ color: colors.gray600 }}
                onClick={() => {
                  idRespondentSelected.current = record.idCertificationUserTest
                  useDisclosureDeleteRespondentModal.open()
                }}
              />
            </Space>
          ),
        },
      ]
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
      return []
    }
  }, [])

  // TODO This function has been partially implemented for multi-select. Refactor it.
  // // rowSelection object indicates the need for row selection
  // const rowSelection = {
  // 	onChange: (
  // 		_: React.Key[],
  // 		selectedRows: CertificationUserTestType[],
  // 	) => {
  // 		setSelectedRows(selectedRows);
  // 	},
  // 	getCheckboxProps: (record: CertificationUserTestType) => ({
  // 		name: record.idCertificationUserTest,
  // 	}),
  // };

  return (
    <Content>
      <Row justify={'space-between'} align={'top'} style={{ marginBottom: 22 }}>
        <Row gutter={8} style={{ alignItems: 'center' }}>
          <Col>
            <Title preset='singlePageTitle'>
              {translate('certificationPage.title.respondents')}
            </Title>
          </Col>
          <Col>
            <DataLengthTag>{respondentsFiltered.length}</DataLengthTag>
          </Col>
        </Row>
        <Row>
          <Space>
            <Button
              onClick={() => downloadRespondentsCertificationAction({})}
              iconLeft='Download01'
            >
              {translate('certificationPage.exportButtonLabel')}
            </Button>
            {/*<Button iconLeft='FilterLines'>*/}
            {/*  {translate('certificationPage.filtersButtonLabel')}*/}
            {/*</Button>*/}
          </Space>
        </Row>
      </Row>
      <Table<CertificationUserTestType>
        data={respondentsFiltered}
        columns={tableColumns}
        rowKey={'idCertificationUserTest'}
        // TODO This function has been partially implemented for multi-select. Refactor it.
        // rowSelection={{
        //   type: 'checkbox',
        //   // ...rowSelection,
        // }}
        onRow={() => ({
          onMouseOver: () => null,
        })}
        {...tableProps}
      />

      {useDisclosureDeleteRespondentModal.isOpen && (
        <DeleteModal
          close={useDisclosureDeleteRespondentModal.close}
          isOpen={useDisclosureDeleteRespondentModal.isOpen}
          title={translate('certificationPage.deleteRespondentQuestionModal.title')}
          label={translate('certificationPage.deleteRespondentQuestionModal.description')}
          isLoading={isLoadingDeleteRespondentCertificationAction}
          onDeleteMutation={() =>
            deleteRespondentCertificationAction(
              {
                idCertificationUserTest: idRespondentSelected.current,
              },
              useDisclosureDeleteRespondentModal.close,
            )
          }
        />
      )}
    </Content>
  )
}

export default RespondentsCertification
