const fr = {
  contributors: 'Contributeurs',
  contributor: 'Contributeur',
  manageTeamMembers: 'Gérez les membres de votre équipe et leurs autorisations de compte ici.',
  export: 'Exporter',
  import: 'Importer',
  filter: 'Filtrer',
  addContributor: 'Ajouter un contributeur',
  name: 'Nom',
  experience: 'Ancienneté',
  role: 'Rôle',
  segments: 'Segments',
  performance: 'Performance',
  effectiveTime: 'Temps effectif',
  status: 'Statut',
  active: 'Actif',
  waiting: 'En attente...',
  selectedContributors: 'Contributeurs sélectionnés',
  selectedContributor: 'Contributeur sélectionnés',
  assignToSegments: 'Affecter à des segments',
  add: 'Ajouter',
  search: 'Rechercher',
  confirm: 'Valider',
  back: 'Retour',
  deleteUser1: 'Supprimer',
  deleteUser2: 'utilisateur?',
  deleteUser3: 'utilisateurs?',
  confirmDeleteUser: 'Êtes-vous sûr de vouloir effectuer cette action ?',
  cancel: 'Annuler',
  delete: 'Supprimer',
  states: {
    1: 'Inactif',
    10: 'Active',
  },
}

export default fr
