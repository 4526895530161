/** @jsxImportSource @emotion/react */

import { ListCertification } from '@views/certification'
import { GetCertificationsPayloadType } from '@api'
import { useDelayedEffect, useRequestParams } from '@hooks'
import { useEffect } from 'react'
import { CertificationType } from '@pages/types'

import { store } from '@/store'

const CertificationsPage = () => {
  const certifications = store.use.certifications()

  const getCertificationsAction = store.use.getCertificationsAction().call
  const isLoading = store.use.getCertificationsAction().status === 'LOADING'

  const { afterPageChange, setParams, reqParams, pagination, onTableStateChange } =
    useRequestParams<GetCertificationsPayloadType, CertificationType>({
      initParams: { model: { managers: [] } },
    })

  useDelayedEffect(() => {
    getCertificationsAction(reqParams)
  }, [JSON.stringify(reqParams)])

  useEffect(() => {
    afterPageChange(certifications.length)
  }, [certifications.length])

  return (
    <ListCertification
      certifications={certifications}
      certificationIsLoading={isLoading}
      onTableStateChange={onTableStateChange}
      pagination={pagination}
      setParams={setParams}
    />
  )
}

export default CertificationsPage
