import { useContext } from 'react'
import styled from '@emotion/styled'
import { TAudienceCombinationType } from '@definitions/states'
import { useTranslation } from '@hooks/useTranslation'
import {
  ProjectAudienceReviewerGroupSegmentType,
  ProjectAudienceReviewerGroupType,
} from '@pages/types'
import { Avatar, Button, colors, IconButton, Space, Text } from 'common-components'

import SegmentsTreeLeaf from './SegmentsTreeLeaf'
import TreeWrapper from './TreeWrapper'
import AddButtonSegment from './AddButtonSegment'

import { AudienceContext } from '@/context'

export type TAudienceCollapseType = 'contributors' | 'reviewers'

type SegmentsTreePropsType = {
  type: TAudienceCollapseType
  audience: ProjectAudienceReviewerGroupType[]
  combination: TAudienceCombinationType
}

const Wrapper = styled.div`
  width: 100%;
  padding: 8px;
  border-radius: 12px;
  border: 1px solid ${colors.gray200};
  max-width: 918px;
`

const SegmentsTree = ({ type, audience, combination }: SegmentsTreePropsType) => {
  const { translate } = useTranslation()

  const {
    addGroup,
    deleteGroup,
    addSegment,
    deleteSegment,
    changeTreeCombination,
    changeGroupCombination,
  } = useContext(AudienceContext)

  const handleAddSegment = (segment: ProjectAudienceReviewerGroupSegmentType, groupIdx: number) =>
    addSegment(type, groupIdx, segment)

  const handleAddGroup = () => addGroup(type)

  const handleDeleteGroup = (groupIdx: number) => deleteGroup(type, groupIdx)

  const handleDeleteSegment = (
    deletedSegment: ProjectAudienceReviewerGroupSegmentType,
    groupIdx: number,
  ) => deleteSegment(type, groupIdx, deletedSegment)

  const handleTreeCombinationChange = (value: TAudienceCombinationType) =>
    changeTreeCombination(type, value)

  const handleGroupCombinationChange = (value: TAudienceCombinationType, groupIdx: number) =>
    changeGroupCombination(type, groupIdx, value)

  return (
    <Wrapper>
      <TreeWrapper
        defaultCombination={combination}
        contentLength={audience?.length}
        onSelectChange={handleTreeCombinationChange}
      >
        {audience?.map((segmentsGroup, idx) => (
          <SegmentsTreeLeaf
            key={idx}
            first={idx === 0}
            last={idx + 1 === audience.length}
            single={audience.length === 1}
          >
            <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
              <IconButton
                icon='Trash01'
                type='link'
                size={'xs'}
                iconProps={{ color: colors.gray400 }}
                onClick={() => handleDeleteGroup(idx)}
              />
            </Space>
            <TreeWrapper
              defaultCombination={segmentsGroup.combination}
              contentLength={segmentsGroup.segments.length}
              onSelectChange={handleGroupCombinationChange}
              groupIdx={idx}
            >
              <Space direction='vertical' style={{ width: '100%', paddingRight: 24 }}>
                {segmentsGroup.segments.map((segment) => (
                  <SegmentsTreeLeaf
                    key={segment.idSegment}
                    single={segmentsGroup.segments.length === 1}
                  >
                    <Space
                      style={{
                        width: '100%',
                        padding: '0 8px',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Space>
                        <Avatar size='sm' />
                        <Text size='sm'>{segment.name}</Text>
                      </Space>
                      <IconButton
                        icon='Trash01'
                        type='link'
                        size='xs'
                        iconProps={{ color: colors.gray400 }}
                        onClick={() => handleDeleteSegment(segment, idx)}
                      />
                    </Space>
                  </SegmentsTreeLeaf>
                ))}
              </Space>
            </TreeWrapper>
            <AddButtonSegment groupIdx={idx} onSegmentClick={handleAddSegment} />
          </SegmentsTreeLeaf>
        ))}
      </TreeWrapper>

      <Button iconLeft='FolderPlus' style={{ marginTop: 8 }} onClick={handleAddGroup}>
        {translate('project.audience.buttons.addGroup')}
      </Button>
    </Wrapper>
  )
}

export default SegmentsTree
