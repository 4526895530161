/** @jsxImportSource @emotion/react */

import React from 'react'
import { css } from '@emotion/react'
import { Text, Space, Icon, colors, IAvailableIcons } from 'common-components'
import { useTranslation } from '@hooks'
import { TxKeyPath } from '@i18n/i18n'
import { SegmentTypeType, TSegmentDefType } from '@definitions'

interface PillProps {
  id: TSegmentDefType
  icon: IAvailableIcons
  title: string
  text: string
}

type PillComponentPropsType = {
  elem: PillProps
  segmentType: SegmentTypeType
  setSegmentType: (typeSegment: SegmentTypeType) => void
}

const PillComponent = ({ elem, segmentType, setSegmentType }: PillComponentPropsType) => {
  const { translate } = useTranslation()

  return (
    <Space
      key={elem.id}
      onClick={() => {
        setSegmentType(elem.id)
      }}
      css={css`
        width: 278px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 6px;
        text-align: center;
        cursor: pointer;
        padding: 20px;
        border: 1px solid ${segmentType === elem.id ? colors.gray600 : colors.gray200};
        border-radius: 10px;
      `}
    >
      <div
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
          width: 32px;
          height: 32px;
          background: ${colors.gray100};
          border: 4px solid ${colors.gray50};
          border-radius: 28px;
        `}
      >
        <Icon color={colors.gray600} icon={elem.icon} />
      </div>
      <Text
        color={colors.gray700}
        css={css`
          font-weight: 600;
        `}
        size={'md'}
      >
        {translate(`createSegment.${elem.title}` as TxKeyPath)}
      </Text>
      <Text color={'#8F97B3'} size={'sm'}>
        {translate(`createSegment.${elem.text}` as TxKeyPath)}
      </Text>
    </Space>
  )
}

export default PillComponent
