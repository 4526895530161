import { z } from 'zod'
import { ErrorMessageOptions, parse } from 'zod-error'

const options: ErrorMessageOptions = {
  delimiter: {
    error: ' ',
  },
  path: {
    enabled: true,
    type: 'objectNotation',
    transform: ({ value }) => `${value}`,
  },
  code: {
    enabled: true,
    transform: ({ value }) => `${value}`,
  },
  message: {
    enabled: true,
    transform: ({ value }) => `${value}`,
  },
  transform: ({ errorMessage }) => `👉 ${errorMessage}`,
}

export const validationHydrationJson = (inputs: object) => {
  const questionFieldTypeSchema = z.object({
    type: z.string(),
    id: z.string(),
    value: z.string(),
    items: z.array(z.string()).optional(),
    color: z.number().optional(),
    confidence: z.number().optional(),
    nbMatching: z.number().optional(),
    isMatched: z.boolean().optional(),
    answers: z.array(z.string()).optional(),
    itemsApi: z
      .array(
        z.object({
          label: z.string(),
          id: z.string(),
        }),
      )
      .optional(),
    itemApi: z
      .object({
        label: z.string(),
        id: z.string(),
      })
      .optional(),
    itemsAnswers: z.array(z.array(z.string())).optional(),
  })
  const answerTypeSchema = z.object({
    fields: z.array(questionFieldTypeSchema).optional(),
    blocks: z
      .array(
        z.object({
          id: z.string(),
          color: z.number().optional(),
          rows: z.array(
            z.object({
              color: z.number().optional(),
              fields: z.array(questionFieldTypeSchema),
              blocks: z.array(z.string()),
              data: z.array(z.string()),
              isHidden: z.literal(true),
              primaryKey: z.string(),
            }),
          ),
        }),
      )
      .optional(),
    data: z
      .array(
        z.object({
          id: z.string(),
          type: z.string(),
          value: z.string(),
        }),
      )
      .optional(),
  })
  return parse(answerTypeSchema, inputs, options)
}
