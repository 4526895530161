/** @jsxImportSource @emotion/react */

import { Button, Content, useDisclosure } from 'common-components'
import { ManagerType, SegmentType } from '@pages/types'
import { PageLayout } from '@components/layout'
import { AssignManagerModal, TableManagers } from '@views/shared/manager'
import { Loader } from '@components/utils'
import { useRequestParams, useTranslation } from '@hooks'
import { useEffect, useState } from 'react'
import { getStateKeyByValue } from '@utils'
import { SegmentDefType, SegmentTypeType } from '@definitions'
import { DeleteModal } from '@components/modal'
import { GetManagersPayloadType } from '@api'

import { SubHeaderSegment } from '../shared'

import { store } from '@/store'

const SEGMENT_MANUAL = getStateKeyByValue<SegmentTypeType>(SegmentDefType, 'manual')
const SEGMENT_SOCIO = getStateKeyByValue<SegmentTypeType>(SegmentDefType, 'sociodemographic')

type ManagersSegmentPropsType = {
  segment: SegmentType
}
const ManagersSegment = ({ segment }: ManagersSegmentPropsType) => {
  const idSegment = segment.idSegment
  const managers = store.use.managersSegment()
  const managersShort = segment.managersShort
  const segmentType = segment.segmentType

  const deleteManagersFromSegmentAction = store.use.deleteManagersFromSegmentAction().call
  const addManagerToSegmentAction = store.use.addManagerToSegmentAction().call
  const getManagersSegmentAction = store.use.getManagersSegmentAction().call

  const isLoadingDeleteManagersFromSegmentAction =
    store.use.deleteManagersFromSegmentAction().status === 'LOADING'
  const isLoadingAddManagerToSegmentAction =
    store.use.addManagerToSegmentAction().status === 'LOADING'
  const isLoadingGetManagersSegmentAction =
    store.use.getManagersSegmentAction().status === 'LOADING'

  const { translate } = useTranslation()
  const [idManagersSelected, setIdManagersSelected] = useState<string[]>([])
  const useDisclosureAssignManagersModal = useDisclosure()
  const useDisclosureDeleteManagersModal = useDisclosure()

  const isShowActionsManager = segmentType === SEGMENT_MANUAL || segmentType === SEGMENT_SOCIO

  const { afterPageChange, pagination, onTableStateChange } = useRequestParams<
    GetManagersPayloadType,
    ManagerType
  >({
    initParams: { model: {} },
  })

  useEffect(() => {
    getManagersSegmentAction({ idSegment })
  }, [idSegment])

  useEffect(() => {
    afterPageChange(managers?.length)
  }, [managers?.length])

  return (
    <PageLayout>
      <Content>
        <SubHeaderSegment
          texts={[
            {
              type: 'tablePageTitle',
              content: translate('segment.managersTab.label'),
            },
          ]}
          count={managers.length}
        >
          {/*** The actions on a single managers ***/}
          {isShowActionsManager && !idManagersSelected.length && (
            <Button onClick={useDisclosureAssignManagersModal.open} type='default' iconLeft='Plus'>
              {translate('segment.managersTab.button')}
            </Button>
          )}
          {/*** The actions on multiple managers ***/}
          {isShowActionsManager && !!idManagersSelected.length && (
            <Button
              type='danger'
              onClick={useDisclosureDeleteManagersModal.open}
              iconLeft='Trash01'
            >
              {translate('managers.delete')}
            </Button>
          )}
        </SubHeaderSegment>

        {isLoadingGetManagersSegmentAction && <Loader />}
        {!isLoadingGetManagersSegmentAction && (
          <TableManagers
            managers={managers}
            setSelectedCheckboxes={setIdManagersSelected}
            selectedCheckboxes={idManagersSelected}
            tableProps={{ pagination, onChange: onTableStateChange }}
          />
        )}
        {/*Assign managers to Segments */}
        {useDisclosureAssignManagersModal.isOpen && (
          <AssignManagerModal
            isLoading={isLoadingAddManagerToSegmentAction}
            managersUser={managersShort}
            onDeleteManager={async ({ idUser }) =>
              deleteManagersFromSegmentAction(
                {
                  idSegment: segment.idSegment,
                  idManagers: [idUser],
                },
                () => {
                  useDisclosureAssignManagersModal.close()
                },
              )
            }
            onAddManager={(managerShort) =>
              addManagerToSegmentAction({
                idSegment: segment.idSegment,
                managerShort,
              })
            }
            managersCount={idManagersSelected.length}
            close={useDisclosureAssignManagersModal.close}
            isOpen={useDisclosureAssignManagersModal.isOpen}
          />
        )}
        {/*delete Manager*/}
        {useDisclosureDeleteManagersModal.isOpen && (
          <DeleteModal
            isLoading={isLoadingDeleteManagersFromSegmentAction}
            close={useDisclosureDeleteManagersModal.close}
            isOpen={useDisclosureDeleteManagersModal.isOpen}
            title={`${translate('managers.deleteUser1')} ${idManagersSelected.length} ${
              idManagersSelected.length === 1
                ? translate('managers.deleteUser2')
                : translate('managers.deleteUser3')
            }
              `}
            label={translate(`contributor.delete.modal.label`)}
            onDeleteMutation={async () =>
              deleteManagersFromSegmentAction(
                {
                  idSegment: segment.idSegment,
                  idManagers: idManagersSelected,
                },
                () => {
                  useDisclosureDeleteManagersModal.close()
                  setIdManagersSelected([])
                },
              )
            }
          />
        )}
      </Content>
    </PageLayout>
  )
}

export default ManagersSegment
