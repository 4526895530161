/** @jsxImportSource @emotion/react */
import { getSegments } from '@api/segment'
import { css } from '@emotion/react'
import { useTranslation } from '@hooks/useTranslation'
import { useMemo, useEffect, useState } from 'react'
import { ProjectAudienceReviewerGroupSegmentType, SegmentType } from '@pages/types'
import debounce from 'lodash.debounce'
import { Avatar, colors, Icon, Popover, Button, Space, Text, TextInput } from 'common-components'

import { segmentApiMapper } from '@/mappers'
import { store } from '@/store'

type AddButtonSegmentPropsType = {
  groupIdx: number
  onSegmentClick: (segment: ProjectAudienceReviewerGroupSegmentType, idx: number) => void
}

const AddButtonSegment = ({ groupIdx, onSegmentClick }: AddButtonSegmentPropsType) => {
  const idMe = store.use.me().userData.idUser

  const { translate } = useTranslation()

  const [segments, setSegments] = useState<SegmentType[]>([])
  const [inputValue, setInputValue] = useState<string>('')
  const [open, setOpen] = useState<boolean>(false)

  const fetch = () => {
    getSegments({ model: {}, search: inputValue }).then((segmentsApi) => {
      const segmentResult = segmentsApi?.map((segmentApi) => segmentApiMapper(segmentApi, idMe))
      setSegments(segmentResult)
    })
  }

  useEffect(() => {
    debounce(fetch, 1500)()
  }, [inputValue])

  const formatSegment = ({
    icon,
    idSegment,
    name,
  }: SegmentType): ProjectAudienceReviewerGroupSegmentType => {
    return {
      icon,
      idSegment,
      name,
      // TOFIX: what are these values?
      percentageForValidation: 0,
      managerRight: 0,
    }
  }

  const PopoverContent = useMemo(
    () => (
      <div style={{ width: 320 }}>
        <TextInput
          prefix={<Icon icon='SearchLg' size={20} style={{ marginRight: 8 }} />}
          bordered={false}
          style={{
            borderRadius: 0,
            borderBottom: `1px solid ${colors.gray200}`,
            fontWeight: 500,
          }}
          value={inputValue}
          onChange={(e) => setInputValue(e.target?.value || '')}
        />
        <div
          style={{
            maxHeight: 200,
            overflowX: 'auto',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {!!segments.length &&
            segments.map((segment) => (
              <Space
                style={{
                  width: '100%',
                  padding: '8px 16px',
                  cursor: 'pointer',
                  boxSizing: 'border-box',
                }}
                onClick={() => onSegmentClick(formatSegment(segment), groupIdx)}
              >
                <Avatar size='sm' />
                <Text size='xs'>{segment.name}</Text>
              </Space>
            ))}
        </div>
      </div>
    ),
    [segments, inputValue],
  )

  return (
    <Popover
      open={open}
      onOpenChange={(newOpen: boolean) => {
        setOpen(newOpen)
      }}
      trigger='click'
      content={PopoverContent}
      placement='bottomLeft'
      arrow={false}
      overlayInnerStyle={{ padding: 0 }}
    >
      <Button
        style={{ marginTop: 8 }}
        iconLeft='PlusCircle'
        css={css`
          .ant-popover-inner {
            padding: 0;
          }
        `}
      >
        {translate('project.audience.buttons.addSegment')}
      </Button>
    </Popover>
  )
}

export default AddButtonSegment
