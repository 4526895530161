/** @jsxImportSource @emotion/react */

import {
  Button,
  colors,
  Content,
  ITableProps,
  Paragraph,
  Row,
  Space,
  Table,
} from 'common-components'
import { css } from '@emotion/react'
import { ColumnsType } from 'antd/lib/table'
import { useNavigate } from 'react-router'
import { routes } from '@navigation/routes'
import { useTranslation } from '@hooks'
import { UseTranslationOptions } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { CertificationUserTestQuestionType } from '@pages/types'
import { useMemo } from 'react'

type RespondentQuestionsCertificationPropsType = {
  certificationUserTestQuestion: CertificationUserTestQuestionType[]
  tableProps?: Omit<ITableProps<CertificationUserTestQuestionType>, 'data' | 'columns'>
}

const QuestionsRespondentCertification = ({
  certificationUserTestQuestion = [],
  tableProps = {},
}: RespondentQuestionsCertificationPropsType) => {
  const { id, respondentTestId } = useParams()
  const navigate = useNavigate()
  const { translate } = useTranslation()

  const tableColumns: ColumnsType<CertificationUserTestQuestionType> = useMemo(() => {
    try {
      return [
        {
          title: translate('certificationPage.respondentQuestionsTable.id'),
          key: 'idCertificationUserTestQuestion',
          sorter: (a, b) => {
            if (a.idCertificationUserTestQuestion < b.idCertificationUserTestQuestion) return -1
            if (a.idCertificationUserTestQuestion > b.idCertificationUserTestQuestion) return 1
            return 0
          },
          render: (record: CertificationUserTestQuestionType) => (
            <Row align={'middle'}>
              <Space size={'middle'}>
                <Paragraph size='sm'>#{record.idCertificationUserTestQuestion}</Paragraph>
              </Space>
            </Row>
          ),
        },
        {
          title: translate('certificationPage.respondentQuestionsTable.time'),
          key: 'duration',
          sorter: (a, b) => {
            if (a.duration < b.duration) return -1
            if (a.duration > b.duration) return 1
            return 0
          },
          render: (record: CertificationUserTestQuestionType) => (
            <Row align={'middle'}>
              <Paragraph size='xs'>{record?.duration}</Paragraph>
            </Row>
          ),
        },
        {
          title: translate('certificationPage.respondentQuestionsTable.score'),
          key: 'mark',
          sorter: (a, b) => a.mark - b.mark,
          render: (record: CertificationUserTestQuestionType) => (
            <Row align={'middle'}>
              <Paragraph size='xs'>{record?.mark ? `${record.mark}%` : ''}</Paragraph>
            </Row>
          ),
        },
      ]
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
      return []
    }
  }, [])

  return (
    <Content>
      <Row
        justify={'space-between'}
        align={'top'}
        css={css`
          margin: 24px 0;
        `}
      >
        <Space align={'center'} size={10}>
          <Space align={'center'} size={10}>
            <Button
              iconLeft={'FlipBackward'}
              onClick={() => navigate(routes.certificationRespondents.navigate(id))}
            />
            <Paragraph size={'lg'} color={colors.gray900} style={{ marginBottom: 0 }}>
              {translate(`certificationPage.respondentHeaderTitle`, {
                name: respondentTestId,
              } as UseTranslationOptions)}
            </Paragraph>
          </Space>
        </Space>
        <Row>
          <Space>
            {/*<Button iconLeft={'FilterLines'}>*/}
            {/*  {translate('certificationPage.filtersButtonLabel')}*/}
            {/*</Button>*/}
            <Button iconLeft={'Download02'}>
              {translate('certificationPage.exportButtonLabel')}
            </Button>
          </Space>
        </Row>
      </Row>
      <Table<CertificationUserTestQuestionType>
        data={certificationUserTestQuestion}
        columns={tableColumns}
        rowKey={'idCertificationQuestion'}
        // TODO This function has been partially implemented for multi-select. Refactor it.
        // rowSelection={{
        //   type: 'checkbox',
        // }}
        onRow={(record) => ({
          onClick: () =>
            navigate(
              routes.certificationRespondentQuestion.navigate(
                id,
                record.idCertificationUserTest,
                record.idCertificationUserTestQuestion,
              ),
            ),
          onMouseOver: () => null,
        })}
        {...tableProps}
      />
    </Content>
  )
}

export default QuestionsRespondentCertification
