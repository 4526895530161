const en = {
  addManager: 'Add a manager',
  lastName: {
    errorMessage: 'Last name is required',
    label: 'Last Name',
  },
  firstName: {
    errorMessage: 'First name is required',
    label: 'First Name',
  },
  phone: {
    errorMessage: 'Phone number is required',
    label: 'Phone',
  },
  email: {
    errorMessage: 'Please enter a valid email address',
    label: 'Email',
  },
  role: {
    errorMessage: 'Role is required',
    label: 'Role',
    selectOptions: {
      worker: 'Worker',
      candidate: 'Candidate',
      manager: 'Manager',
      admin: 'Admin',
    },
  },
}

export default en
export type Translations = typeof en
