import { CollapseWithPill } from '@components/display'
import { useTranslation } from '@hooks/useTranslation'
import { useEffect, useState } from 'react'
import { FormType, ProjectType } from '@pages/types'
import {
  getAiGenerativeResource,
  GetAiGenerativeResourcePayloadType,
  getModelAnnotation,
  UpdatePrefillProjectPayloadType,
} from '@api'
import { LoadingOutlined } from '@ant-design/icons'
import { AiGenerativeResourceApiType, IModel } from '@api/types'
import {
  Avatar,
  Button,
  colors,
  Divider,
  FormItem,
  IconButton,
  SelectInput,
  Space,
  Text,
  Title,
} from 'common-components'
import { PrefillMergeType, PrefillType } from '@definitions'
import { getStateKeyByValue } from '@utils'

import ModalArtificialModel from './ModalArtificialModel'
import FormFieldsModel, { FormFieldsType } from './FormFieldsModel'

const removeAttributes = <T, K extends keyof T>(object: T, attributesToRemove: K[]): Omit<T, K> => {
  const result = { ...object }
  for (const attribute of attributesToRemove) {
    delete result[attribute]
  }
  return result
}

type PrefillSettingsCollapsePropsType = {
  project: ProjectType
  form: FormType
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  onChange: (prefillSettings: ProjectType['prefillSettings']) => void
}

const PREFILLING_TYPE_OPTIONS = [
  {
    label: 'IA Génerative Locale',
    value: getStateKeyByValue<PrefillType>(PrefillMergeType, 'iagenerative'),
  },
  { label: 'GPT', value: getStateKeyByValue<PrefillType>(PrefillMergeType, 'gpt') },
  { label: 'Model paramétré', value: getStateKeyByValue<PrefillType>(PrefillMergeType, 'model') },
  { label: 'Manuel ', value: getStateKeyByValue<PrefillType>(PrefillMergeType, 'manuel') },
]

type AiGenerativeType = {
  all: AiGenerativeResourceApiType[]
  chosen?: AiGenerativeResourceApiType
  isLoading: boolean
  optionsSelect: {
    label: string
    value: string
  }[]
}

const PrefillSettingsCollapse = ({
  project,
  form,
  isOpen,
  onChange,
  setIsOpen,
}: PrefillSettingsCollapsePropsType) => {
  const { translate } = useTranslation()

  const [model, setModel] = useState<IModel | undefined>()
  const [fieldsModel, setFieldsModel] = useState<{ values?: FormFieldsType; isValid?: boolean }>()
  const [annotationOptions, setAnnotationOptions] = useState<{ value: string; label: string }[]>([])
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const [statePrefillingType, setStatePrefillingType] = useState<PrefillType>(0)
  const [aiGenerative, setAiGenerative] = useState<AiGenerativeType>({
    all: [],
    isLoading: false,
    optionsSelect: [],
  })

  useEffect(() => {
    let aiGenerativeCurrent: UpdatePrefillProjectPayloadType['prefillSettings']['aiGenerative']
    let modelCurrent: UpdatePrefillProjectPayloadType['prefillSettings']['model']

    if ((statePrefillingType === 1 || statePrefillingType === 4) && aiGenerative?.chosen) {
      aiGenerativeCurrent = aiGenerative?.chosen
    }
    if (statePrefillingType === 2 && project?.idProject && model?.idModel && fieldsModel?.isValid) {
      modelCurrent = {
        idModel: model.idModel,
        name: model.name,
        mapping: {
          idModel: model.idModel,
          idProject: project.idProject,
          prefillRate: fieldsModel.values.prefillRate,
          acceptationRate: fieldsModel.values.acceptationRate,
          columnMappingModelForm: removeAttributes(fieldsModel.values, [
            'prefillRate',
            'acceptationRate',
          ]),
        },
      }
    }
    onChange({ type: statePrefillingType, aiGenerative: aiGenerativeCurrent, model: modelCurrent })
  }, [
    model?.idModel,
    statePrefillingType,
    JSON.stringify(aiGenerative.chosen),
    JSON.stringify(fieldsModel),
  ])

  const prefillingTypeSelectionHandler = async (e: string) => {
    try {
      const state = Number(e) as PrefillType
      setStatePrefillingType(state)
      setFieldsModel(undefined)
      if (state === 1 || state === 4) {
        await aiGenerativeSelectionHandler(state)
      } else {
        setAiGenerative({
          all: [],
          chosen: undefined,
          isLoading: false,
          optionsSelect: [],
        })
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }

  const aiGenerativeSelectionHandler = async (type: GetAiGenerativeResourcePayloadType['type']) => {
    try {
      setAiGenerative({
        all: [],
        chosen: undefined,
        isLoading: true,
        optionsSelect: [],
      })
      const response = await getAiGenerativeResource({
        type,
      })
      const options = response?.map((item) => ({
        label: item.name,
        value: item.idAIGenerative,
      }))
      setAiGenerative({ all: response, isLoading: false, optionsSelect: options })
    } catch (e) {
      setAiGenerative({
        all: [],
        chosen: undefined,
        isLoading: false,
        optionsSelect: [],
      })
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }

  const prefillSettingsHandler = (e: string) => {
    try {
      const chosenPrefillSettings = aiGenerative.all?.find((item) => item.idAIGenerative === e)
      setAiGenerative((prevState) => ({ ...prevState, chosen: chosenPrefillSettings }))
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }

  const closeModal = () => setIsModalOpen(false)

  const addModel = async (model: IModel) => {
    const annotations = await getModelAnnotation({ idModel: model.idModel })
    const options = annotations?.map((item) => ({ label: item.name, value: item.idAnnotation }))
    setAnnotationOptions(options)
    setModel(model)
    closeModal()
  }

  const Loader = () => (
    <div
      style={{
        marginTop: '10px',
        justifyContent: 'center',
        display: 'flex',
      }}
    >
      <LoadingOutlined style={{ fontSize: '20px' }} />
    </div>
  )

  const PrefillTypePanel = {
    title: translate('projectCreatePage.collapse.artificialModel.title'),
    isOpen: isOpen,
    setIsOpen: setIsOpen,
    content: (
      <Space direction='vertical' style={{ width: '100%', gap: 8, paddingBottom: 16 }}>
        <Space direction='vertical' style={{ width: '100%', padding: '0 24px', gap: 16 }}>
          <FormItem label={translate('project.prefillType')}>
            <SelectInput
              options={PREFILLING_TYPE_OPTIONS}
              onChange={prefillingTypeSelectionHandler}
            />
          </FormItem>
          {(statePrefillingType === 1 || statePrefillingType === 4) && aiGenerative.isLoading && (
            <Loader />
          )}
          {(statePrefillingType === 1 || statePrefillingType === 4) &&
            !!aiGenerative.optionsSelect.length && (
              <FormItem
                label={
                  statePrefillingType === 1
                    ? translate('project.modelSelection')
                    : translate('project.versionSelection')
                }
              >
                <SelectInput
                  options={aiGenerative.optionsSelect}
                  onChange={prefillSettingsHandler}
                />
              </FormItem>
            )}
          {statePrefillingType === 2 && (
            <>
              <Space direction='vertical' style={{ width: '100%', gap: 16 }}>
                <Text size='sm'>
                  {translate('projectCreatePage.collapse.artificialModel.content.chooseForm')}
                </Text>
                <Button onClick={() => setIsModalOpen(true)} style={{ width: 'fit-content' }}>
                  {translate('projectCreatePage.collapse.artificialModel.button')}
                </Button>

                {model?.idModel && (
                  <Space
                    direction='vertical'
                    style={{ width: '100%', gap: 16 }}
                    key={model.idModel}
                  >
                    <Space
                      style={{
                        width: '100%',
                        padding: '20px 16px',
                        border: `1px solid ${colors.gray200}`,
                        borderRadius: 12,
                        position: 'relative',
                      }}
                    >
                      <Avatar size='lg' />
                      <Space direction='vertical'>
                        <Title level={5}>{model.name}</Title>
                        <Text>{model.idModel}</Text>
                      </Space>
                      <IconButton
                        icon='XClose'
                        type='link'
                        size='sm'
                        iconProps={{ color: colors.gray500 }}
                        style={{ position: 'absolute', top: 0, right: 0 }}
                        onClick={() => setModel(undefined)}
                      />
                    </Space>
                  </Space>
                )}
              </Space>
              {model?.idModel && project?.form?.idForm && (
                <>
                  <Divider style={{ background: colors.gray200, margin: '8px 0' }} />
                  <FormFieldsModel
                    showFields={form?.template?.template?.tree?.nodeType === 'root'}
                    fields={form?.template?.template?.fields?.map((item) => ({
                      id: item?.id,
                      type: item?.type,
                    }))}
                    options={annotationOptions}
                    onChangeForm={setFieldsModel}
                  />
                </>
              )}
            </>
          )}
        </Space>
      </Space>
    ),
  }

  return (
    <>
      <CollapseWithPill panels={[PrefillTypePanel]} />
      <ModalArtificialModel addModel={addModel} open={isModalOpen} closeModal={closeModal} />
    </>
  )
}

export default PrefillSettingsCollapse
