import { DocumentApiType, DocumentLogApiType } from '@api/types'
import { DocumentLogType, DocumentType } from '@pages/types'
import { dateTime } from '@utils'

import { removeNullAndUndefinedMapper } from './helper.mapper'

export const documentLogApiMapper = (documentLogApi: DocumentLogApiType): DocumentLogType => {
  try {
    const result: DocumentLogType = {
      event: documentLogApi?.event,
      // idModel: documentLogApi?.idModel,
      // idDocument: documentLogApi?.idDocument,
      creationDate: dateTime(documentLogApi?.creationDate),
    }

    return removeNullAndUndefinedMapper<DocumentLogType>(result)
  } catch (e) {
    throw e
  }
}

export const documentApiMapper = (documentLogApi: DocumentApiType): DocumentType => {
  try {
    const result: DocumentType = {
      idDocument: documentLogApi?.idDocument,
      // idModel: documentLogApi?.idModel,
      // name: documentLogApi?.name,
      // url: documentLogApi?.url,
      // ocrVersion: documentLogApi?.ocrVersion,
      state: documentLogApi?.state,
      // creationDate: documentLogApi?.creationDate,
      updateDate: dateTime(documentLogApi?.updateDate),
    }

    return removeNullAndUndefinedMapper<DocumentType>(result)
  } catch (e) {
    throw e
  }
}
