/** @jsxImportSource @emotion/react */
import { SegmentType } from '@pages/types'
import { Row, Text, colors, Col, Button } from 'common-components'
import { css } from '@emotion/react'
import { useTranslation } from '@hooks'
import { capitalizeFirstLetter } from '@utils/capitalize-first'
import { AssignManager } from '@views/shared/manager'
import { AssignContributor } from '@views/shared/contributor'

import { HeaderSegment } from '../HeaderSegment'

type ManualPropsType = {
  step: number
  segment: SegmentType
  onUpdateManagers: (managersShort: SegmentType['managersShort']) => void
  onUpdateContributors: (contributorsShort: SegmentType['contributorsShort']) => void
}

const ManualSegment = ({
  step,
  segment,
  onUpdateContributors,
  onUpdateManagers,
}: ManualPropsType) => {
  const { translate } = useTranslation()

  return (
    <div>
      <HeaderSegment type={10} name={segment.name} />
      {step === 2 && (
        <>
          <Row justify={'space-between'} align={'middle'}>
            <Col
              css={css`
                display: flex;
                flex-direction: column;
              `}
            >
              <Text color={colors.gray400} size='md'>
                2 {translate('createSegment.outOf')} 3
              </Text>
              <Text
                css={css`
                  font-weight: 500;
                `}
                color={colors.gray900}
                size='xl'
              >
                {translate('createSegment.addUsers')}
              </Text>
            </Col>
            <Col
              css={css`
                display: flex;
                gap: 8px;
              `}
            >
              <Button iconLeft='FileLock02'>
                {capitalizeFirstLetter(translate('contributors.deleteUser3').slice(0, -1))}
              </Button>
              <Button iconLeft='Upload01'>{translate('createSegment.import')}</Button>
            </Col>
          </Row>
          <AssignContributor onUpdateContributors={onUpdateContributors} />
        </>
      )}
      {step === 3 && (
        <>
          <Row justify={'space-between'} align={'middle'}>
            <Col
              css={css`
                display: flex;
                flex-direction: column;
              `}
            >
              <Text color={colors.gray400} size='md'>
                3 {translate('createSegment.outOf')} 3
              </Text>
              <Text
                css={css`
                  font-weight: 500;
                `}
                color={colors.gray900}
                size='xl'
              >
                {translate('createSegment.chooseManagers')}
              </Text>
            </Col>
          </Row>
          <AssignManager managersUser={segment.managersShort} onUpdateManagers={onUpdateManagers} />
        </>
      )}
    </div>
  )
}

export default ManualSegment
