import { CollapseWithPill } from '@components/display'
import { FormSelector } from '@views/shared/form'
import { useTranslation } from '@hooks/useTranslation'
import { FormType, ProjectType } from '@pages/types'
import { Space, Text, useDisclosure } from 'common-components'

type FormCollapsePropsType = {
  useDisclosure: ReturnType<typeof useDisclosure>
  project: ProjectType
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  updateFormProject: (formSelected: FormType) => void
}
const FormCollapse = ({
  isOpen,
  project,
  updateFormProject,
  setIsOpen,
  useDisclosure,
}: FormCollapsePropsType) => {
  const { translate } = useTranslation()

  const formPanel = {
    title: translate('projectCreatePage.collapse.manualEntry.title'),
    content: (
      <Space direction='vertical' style={{ width: '100%', padding: '0 24px 24px', gap: 16 }}>
        <Text size='sm'>{translate('projectCreatePage.collapse.manualEntry.subtitle')}</Text>
        <FormSelector
          idFormSelectedInit={project?.form?.idForm}
          onFormSelect={(formSelected) => updateFormProject(formSelected)}
          useDisclosure={useDisclosure}
        />
      </Space>
    ),
    isOpen,
    setIsOpen,
  }

  return <CollapseWithPill panels={[formPanel]} />
}

export default FormCollapse
