/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Space, Avatar, Text, colors } from 'common-components'

type UserProfilePropsType = {
  name: string
  avatar: string
}
const UserProfile = ({ avatar, name }: UserProfilePropsType) => {
  return (
    <Space
      css={css`
        display: flex;
        gap: 12px;
        align-items: center;
      `}
    >
      <Avatar src={avatar} />
      <Text color={colors.gray600}>{name}</Text>
    </Space>
  )
}

export default UserProfile
