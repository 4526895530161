import {
  attachCertificationQuestions,
  createCertificationDraftQuestion,
  CreateCertificationDraftQuestionType,
  deleteCertificationDraftQuestion,
  DeleteCertificationDraftQuestionType,
  deleteCertificationQuestion,
  getCertificationDraftQuestions,
  getCertificationQuestion,
  getCertificationQuestions,
  GetCertificationQuestionsPayloadType,
  updateCertificationQuestionFile,
  updateCertificationQuestionInputJson,
  updateCertificationQuestionTrainingStatus,
  updateCertificationQuestionValue,
  uploadCertificationQuestionsColumnFile,
  UploadCertificationQuestionsColumnFilePayloadType,
  uploadCertificationQuestionsFile,
  UploadCertificationQuestionsFilePayloadType,
} from '@api/certification'
import { QuestionType } from '@pages/types'
import { IColumn } from '@types'
import { validationHydrationJson } from '@views/shared/helper'
import { AnswerApiType, QuestionApiType } from '@api/types'

import { SetState } from '../store.type'
import { getApiError, getSuccess } from '../helper'
import { StoreGlobalType } from '../store'

import { CertificationSliceType } from './certification.slice'
import { synchronizeCertification } from './certification.action'

import { certificationApiMapper, questionApiMapper } from '@/mappers'

/****************************************************************
 * GET QUESTIONS CERTIFICATION
 *****************************************************************/

type GetQuestionsCertificationActionStatusType =
  | 'getQuestionsCertification/loading'
  | 'getQuestionsCertification/succeeded'
  | 'getQuestionsCertification/failed'

export type GetQuestionsCertificationActionPayloadType = GetCertificationQuestionsPayloadType

export const getQuestionsCertificationAction =
  (
    set: SetState<
      Partial<CertificationSliceType & StoreGlobalType>,
      GetQuestionsCertificationActionStatusType,
      GetQuestionsCertificationActionPayloadType
    >,
  ) =>
  async (payload: GetQuestionsCertificationActionPayloadType) => {
    try {
      set(
        (state) => {
          state.getQuestionsCertificationAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'getQuestionsCertification/loading', payload },
      )

      const questionsApi = await getCertificationQuestions(payload)
      const questionsFiltered = questionsApi.map(questionApiMapper)

      set(
        (state) => {
          state.getQuestionsCertificationAction.status = 'SUCCEEDED'
          state.questionsFiltered = questionsFiltered
        },
        false,
        { type: 'getQuestionsCertification/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.getQuestionsCertificationAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'getQuestionsCertification/failed' },
      )
    }
  }

/****************************************************************
 * CREATE DRAFT QUESTION CERTIFICATION
 *****************************************************************/

type CreateDraftQuestionCertificationActionStatusType =
  | 'createDraftQuestionCertification/loading'
  | 'createDraftQuestionCertification/succeeded'
  | 'createDraftQuestionCertification/failed'

export type CreateDraftQuestionCertificationActionPayloadType = CreateCertificationDraftQuestionType

export const createDraftQuestionCertificationAction =
  (
    set: SetState<
      Partial<CertificationSliceType & StoreGlobalType>,
      CreateDraftQuestionCertificationActionStatusType,
      CreateDraftQuestionCertificationActionPayloadType
    >,
  ) =>
  async (
    payload: CreateDraftQuestionCertificationActionPayloadType,
    callback: (questionDraft: QuestionType) => void,
  ) => {
    try {
      set(
        (state) => {
          state.createDraftQuestionCertificationAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'createDraftQuestionCertification/loading', payload },
      )

      const questionDraftApi = await createCertificationDraftQuestion(payload)
      const questionDraft = questionApiMapper(questionDraftApi)

      set(
        (state) => {
          state.createDraftQuestionCertificationAction.status = 'SUCCEEDED'
          state.questionsDraft.unshift(questionDraft)
        },
        false,
        { type: 'createDraftQuestionCertification/succeeded' },
      )

      callback(questionDraft)
    } catch (e) {
      set(
        (state) => {
          state.createDraftQuestionCertificationAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'createDraftQuestionCertification/failed' },
      )
    }
  }

/****************************************************************
 * Attach QUESTIONS TO CERTIFICATION
 *****************************************************************/

type AttachQuestionsToCertificationActionStatusType =
  | 'attachQuestionsToCertification/loading'
  | 'attachQuestionsToCertification/succeeded'
  | 'attachQuestionsToCertification/failed'

export type AttachQuestionsToCertificationActionPayloadType = {
  idCertification: string
}

export const attachQuestionsToCertificationAction =
  (
    set: SetState<
      Partial<CertificationSliceType & StoreGlobalType>,
      AttachQuestionsToCertificationActionStatusType,
      AttachQuestionsToCertificationActionPayloadType
    >,
    get: () => StoreGlobalType,
  ) =>
  async (payload: AttachQuestionsToCertificationActionPayloadType, callback: () => void) => {
    try {
      set(
        (state) => {
          state.attachQuestionsToCertificationAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'attachQuestionsToCertification/loading', payload },
      )

      const idMe = get().me.userData.idUser
      const certificationApi = await attachCertificationQuestions(payload)
      const certification = certificationApiMapper(certificationApi, idMe)

      set(
        (state) => {
          state.attachQuestionsToCertificationAction.status = 'SUCCEEDED'
          const index = state.certifications?.findIndex(
            ({ idCertification }) => idCertification === payload.idCertification,
          )
          if (index >= 0) {
            state.certifications[index] = certification
          }
        },
        false,
        { type: 'attachQuestionsToCertification/succeeded' },
      )

      callback()
    } catch (e) {
      set(
        (state) => {
          state.attachQuestionsToCertificationAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'attachQuestionsToCertification/failed' },
      )
    }
  }

/****************************************************************
 * UPDATE DRAFT QUESTION CERTIFICATION
 *****************************************************************/

type UpdateDraftQuestionCertificationActionStatusType =
  | 'updateDraftQuestionCertification/loading'
  | 'updateDraftQuestionCertification/succeeded'
  | 'updateDraftQuestionCertification/failed'

export type UpdateDraftQuestionCertificationActionPayloadType = {
  idCertification: string
  idCertificationQuestion: string
  columnName: string
  type: IColumn['type']
  value?: string
  file?: string
}

export const updateDraftQuestionCertificationAction =
  (
    set: SetState<
      Partial<CertificationSliceType & StoreGlobalType>,
      UpdateDraftQuestionCertificationActionStatusType,
      UpdateDraftQuestionCertificationActionPayloadType
    >,
  ) =>
  async (payload: UpdateDraftQuestionCertificationActionPayloadType) => {
    try {
      set(
        (state) => {
          state.updateDraftQuestionCertificationAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'updateDraftQuestionCertification/loading', payload },
      )

      const payloadApi = {
        idCertification: payload.idCertification,
        idCertificationQuestion: payload.idCertificationQuestion,
        columnName: payload.columnName,
      }

      let questionDraftApi: QuestionApiType

      //** HYDRATATION JSON **//
      if (payload.type === 'hydration_json') {
        const inputJson = validationHydrationJson(
          JSON.parse(payload.value.replace(/\\+/g, '')),
        ) as AnswerApiType
        questionDraftApi = await updateCertificationQuestionInputJson({
          ...payloadApi,
          inputJson,
        })
        //** FILE **//
      } else if (payload?.file) {
        questionDraftApi = await updateCertificationQuestionFile({
          ...payloadApi,
          file: payload.file,
        })
      } else {
        //** VALUE **//
        questionDraftApi = await updateCertificationQuestionValue({
          ...payloadApi,
          value: payload.value,
        })
      }

      const questionDraft = questionApiMapper(questionDraftApi)

      set(
        (state) => {
          state.updateDraftQuestionCertificationAction.status = 'SUCCEEDED'

          const index = state.questionsDraft.findIndex(
            ({ idCertificationQuestion }) =>
              idCertificationQuestion === payload.idCertificationQuestion,
          )
          if (index >= 0) {
            state.questionsDraft[index] = questionDraft
          }
        },
        false,
        { type: 'updateDraftQuestionCertification/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.updateDraftQuestionCertificationAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'updateDraftQuestionCertification/failed' },
      )
    }
  }

/****************************************************************
 * GET DRAFT QUESTIONS CERTIFICATION
 *****************************************************************/

type GetDraftQuestionsCertificationActionStatusType =
  | 'getDraftQuestionsCertification/loading'
  | 'getDraftQuestionsCertification/succeeded'
  | 'getDraftQuestionsCertification/failed'

export type GetDraftQuestionsCertificationActionPayloadType = {
  idCertification: string
}

export const getDraftQuestionsCertificationAction =
  (
    set: SetState<
      Partial<CertificationSliceType & StoreGlobalType>,
      GetDraftQuestionsCertificationActionStatusType,
      GetDraftQuestionsCertificationActionPayloadType
    >,
  ) =>
  async (payload: GetDraftQuestionsCertificationActionPayloadType, callback?: () => void) => {
    try {
      set(
        (state) => {
          state.getDraftQuestionsCertificationAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'getDraftQuestionsCertification/loading', payload },
      )

      const draftQuestionsApi = await getCertificationDraftQuestions(payload.idCertification)
      const draftQuestions = draftQuestionsApi?.questions?.map(questionApiMapper) || []

      set(
        (state) => {
          state.getDraftQuestionsCertificationAction.status = 'SUCCEEDED'
          state.questionsDraft = draftQuestions
        },
        false,
        { type: 'getDraftQuestionsCertification/succeeded' },
      )

      callback && callback()
    } catch (e) {
      set(
        (state) => {
          state.getDraftQuestionsCertificationAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'getDraftQuestionsCertification/failed' },
      )
    }
  }

/****************************************************************
 * GET QUESTION CERTIFICATION
 *****************************************************************/

type GetQuestionCertificationActionStatusType =
  | 'getQuestionCertification/loading'
  | 'getQuestionCertification/succeeded'
  | 'getQuestionCertification/failed'

export type GetQuestionCertificationActionPayloadType = {
  idCertification: string
  idQuestion: string
}

export const getQuestionCertificationAction =
  (
    set: SetState<
      Partial<CertificationSliceType & StoreGlobalType>,
      GetQuestionCertificationActionStatusType,
      GetQuestionCertificationActionPayloadType
    >,
  ) =>
  async (payload: GetQuestionCertificationActionPayloadType) => {
    try {
      set(
        (state) => {
          state.getQuestionCertificationAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'getQuestionCertification/loading', payload },
      )

      const questionApi = await getCertificationQuestion(payload)
      const question = questionApiMapper(questionApi)

      set(
        (state) => {
          state.getQuestionCertificationAction.status = 'SUCCEEDED'
          state.questionsFiltered = [question]
        },
        false,
        { type: 'getQuestionCertification/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.getQuestionCertificationAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'getQuestionCertification/failed' },
      )
    }
  }

/****************************************************************
 * DELETE DRAFT QUESTION CERTIFICATION
 *****************************************************************/

type DeleteDraftQuestionCertificationActionStatusType =
  | 'deleteDraftQuestionCertification/loading'
  | 'deleteDraftQuestionCertification/succeeded'
  | 'deleteDraftQuestionCertification/failed'

export type DeleteDraftQuestionCertificationActionPayloadType = DeleteCertificationDraftQuestionType

export const deleteDraftQuestionCertificationAction =
  (
    set: SetState<
      Partial<CertificationSliceType & StoreGlobalType>,
      DeleteDraftQuestionCertificationActionStatusType,
      DeleteDraftQuestionCertificationActionPayloadType
    >,
  ) =>
  async (payload: DeleteDraftQuestionCertificationActionPayloadType) => {
    try {
      set(
        (state) => {
          state.deleteDraftQuestionCertificationAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'deleteDraftQuestionCertification/loading', payload },
      )

      await deleteCertificationDraftQuestion(payload)

      set(
        (state) => {
          state.deleteDraftQuestionCertificationAction.status = 'SUCCEEDED'

          const index = state.questionsDraft.findIndex(
            ({ idCertificationQuestion }) =>
              idCertificationQuestion === payload.idCertificationQuestion,
          )
          if (index >= 0) {
            state.questionsDraft.splice(index, 1)
          }
        },
        false,
        { type: 'deleteDraftQuestionCertification/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.deleteDraftQuestionCertificationAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'deleteDraftQuestionCertification/failed' },
      )
    }
  }

/****************************************************************
 * UPLOAD QUESTION FILE CERTIFICATION
 *****************************************************************/

type UploadQuestionsFileCertificationActionStatusType =
  | 'uploadQuestionsFileCertification/loading'
  | 'uploadQuestionsFileCertification/succeeded'
  | 'uploadQuestionsFileCertification/failed'

export type UploadQuestionsFileCertificationActionPayloadType =
  UploadCertificationQuestionsFilePayloadType

export const uploadQuestionsFileCertificationAction =
  (
    set: SetState<
      Partial<CertificationSliceType & StoreGlobalType>,
      UploadQuestionsFileCertificationActionStatusType,
      UploadQuestionsFileCertificationActionPayloadType
    >,
  ) =>
  async (payload: UploadQuestionsFileCertificationActionPayloadType, callback: () => void) => {
    try {
      set(
        (state) => {
          state.uploadQuestionsFileCertificationAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'uploadQuestionsFileCertification/loading', payload },
      )

      await uploadCertificationQuestionsFile(payload)

      setTimeout(async () => {
        set(
          (state) => {
            state.uploadQuestionsFileCertificationAction.status = 'SUCCEEDED'
            state.success = getSuccess({ messageT: 'certificationCreation.questions.refreshText' })
          },
          false,
          { type: 'uploadQuestionsFileCertification/succeeded' },
        )
        callback()
      }, 3000)
    } catch (e) {
      set(
        (state) => {
          state.uploadQuestionsFileCertificationAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'uploadQuestionsFileCertification/failed' },
      )
    }
  }

/****************************************************************
 * UPLOAD COLUMN FILE QUESTIONS CERTIFICATION
 *****************************************************************/

type UploadColumnFileQuestionsCertificationActionStatusType =
  | 'uploadColumnFileQuestionsCertification/loading'
  | 'uploadColumnFileQuestionsCertification/succeeded'
  | 'uploadColumnFileQuestionsCertification/failed'

export type UploadColumnFileQuestionsCertificationActionPayloadType =
  UploadCertificationQuestionsColumnFilePayloadType

export const uploadColumnFileQuestionsCertificationAction =
  (
    set: SetState<
      Partial<CertificationSliceType & StoreGlobalType>,
      UploadColumnFileQuestionsCertificationActionStatusType,
      UploadColumnFileQuestionsCertificationActionPayloadType
    >,
  ) =>
  async (
    payload: UploadColumnFileQuestionsCertificationActionPayloadType,
    callback: () => void,
  ) => {
    try {
      set(
        (state) => {
          state.uploadColumnFileQuestionsCertificationAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'uploadColumnFileQuestionsCertification/loading', payload },
      )

      await uploadCertificationQuestionsColumnFile(payload)

      setTimeout(async () => {
        set(
          (state) => {
            state.uploadColumnFileQuestionsCertificationAction.status = 'SUCCEEDED'
            state.success = getSuccess({
              messageT: `certificationCreation.questions.refreshText`,
            })
          },
          false,
          { type: 'uploadColumnFileQuestionsCertification/succeeded' },
        )
        callback()
      }, 3000)
    } catch (e) {
      set(
        (state) => {
          state.uploadColumnFileQuestionsCertificationAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'uploadColumnFileQuestionsCertification/failed' },
      )
    }
  }

/****************************************************************
 * DELETE QUESTIONS CERTIFICATION
 *****************************************************************/

type DeleteQuestionsCertificationActionStatusType =
  | 'deleteQuestionsCertification/loading'
  | 'deleteQuestionsCertification/succeeded'
  | 'deleteQuestionsCertification/failed'

export type DeleteQuestionsCertificationActionPayloadType = {
  idCertification: string
  idQuestions: string[]
}

export const deleteQuestionsCertificationAction =
  (
    set: SetState<
      Partial<CertificationSliceType & StoreGlobalType>,
      DeleteQuestionsCertificationActionStatusType,
      DeleteQuestionsCertificationActionPayloadType
    >,
    get: () => CertificationSliceType & StoreGlobalType,
  ) =>
  async (payload: DeleteQuestionsCertificationActionPayloadType, callback?: () => void) => {
    try {
      set(
        (state) => {
          state.deleteQuestionsCertificationAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'deleteQuestionsCertification/loading', payload },
      )

      for (const idQuestion of payload.idQuestions) {
        await deleteCertificationQuestion({
          certificationId: payload.idCertification,
          questionId: idQuestion,
        })
      }

      const idMe = get().me.userData.idUser
      const certification = await synchronizeCertification(payload.idCertification, idMe)

      set(
        (state) => {
          state.deleteQuestionsCertificationAction.status = 'SUCCEEDED'
          state.success = getSuccess({})

          for (const idQuestion of payload.idQuestions) {
            const index = state.questionsFiltered?.findIndex(
              ({ idCertificationQuestion }) => idCertificationQuestion === idQuestion,
            )
            if (index >= 0) {
              state.questionsFiltered.splice(index, 1)
            }
          }

          const indexCertification = state.certifications.findIndex(
            ({ idCertification }) => idCertification === payload.idCertification,
          )

          if (indexCertification >= 0) {
            state.certifications[indexCertification] = certification
          }
        },
        false,
        { type: 'deleteQuestionsCertification/succeeded' },
      )

      callback && callback()
    } catch (e) {
      set(
        (state) => {
          state.deleteQuestionsCertificationAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'deleteQuestionsCertification/failed' },
      )
    }
  }

/****************************************************************
 * UPDATE TRAINING QUESTION CERTIFICATION
 *****************************************************************/

type UpdateTrainingQuestionCertificationActionStatusType =
  | 'updateTrainingQuestionCertification/loading'
  | 'updateTrainingQuestionCertification/succeeded'
  | 'updateTrainingQuestionCertification/failed'

export type UpdateTrainingQuestionCertificationActionPayloadType = {
  idCertification: string
  idQuestion: string
  training: boolean
}

export const updateTrainingQuestionCertificationAction =
  (
    set: SetState<
      Partial<CertificationSliceType & StoreGlobalType>,
      UpdateTrainingQuestionCertificationActionStatusType,
      UpdateTrainingQuestionCertificationActionPayloadType
    >,
    get: () => CertificationSliceType & StoreGlobalType,
  ) =>
  async (payload: UpdateTrainingQuestionCertificationActionPayloadType, callback?: () => void) => {
    try {
      set(
        (state) => {
          state.updateTrainingQuestionCertificationAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'updateTrainingQuestionCertification/loading', payload },
      )

      const questionApi = await updateCertificationQuestionTrainingStatus({
        idCertification: payload.idCertification,
        idCertificationQuestion: payload.idQuestion,
        training: payload.training,
      })

      const idMe = get().me.userData.idUser
      const question = questionApiMapper(questionApi)
      const certification = await synchronizeCertification(payload.idCertification, idMe)

      set(
        (state) => {
          state.updateTrainingQuestionCertificationAction.status = 'SUCCEEDED'
          state.success = getSuccess({})

          const indexQuestion = state.questionsFiltered?.findIndex(
            ({ idCertificationQuestion }) => idCertificationQuestion === payload.idQuestion,
          )
          if (indexQuestion >= 0) {
            state.questionsFiltered[indexQuestion] = question
          }

          const indexCertification = state.certifications.findIndex(
            ({ idCertification }) => idCertification === payload.idCertification,
          )

          if (indexCertification >= 0) {
            state.certifications[indexCertification] = certification
          }

          state.success = getSuccess({
            descriptionT: 'certificationPage.notifications.trainingStatusChangeSuccess',
          })
        },
        false,
        { type: 'updateTrainingQuestionCertification/succeeded' },
      )

      callback && callback()
    } catch (e) {
      set(
        (state) => {
          const error = getApiError(e)

          state.updateTrainingQuestionCertificationAction.status = 'FAILED'
          state.error = {
            ...error,
            descriptionTranslate: 'certificationPage.notifications.trainingStatusChangeError',
          }
        },
        false,
        { type: 'updateTrainingQuestionCertification/failed' },
      )
    }
  }
