/** @jsxImportSource @emotion/react */

import { IColumn } from '@types'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { Badge, Button, Col, colors, IColProps, Row, Text, TextInput } from 'common-components'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { QuestionInputType } from '@pages/types'

type CellDataType<T> = T & QuestionInputType

type TAllowedInputs = ('text' | 'file')[]

const allowedFileInputFieldTypes = ['data_embed', 'data_video', 'data_audio', 'data_image']

type CellTableInputPropsType<T> = {
  cellData?: CellDataType<T>
  CellComponent: React.FC<IColProps>
  template: IColumn
  onUploadOpen?: () => void
  onInputDone?: (val: string, shouldEditNextCell?: boolean) => void
  placeholder?: string
  isEditing?: boolean
}

const PreviewContainer = styled(Row)`
  align-items: center;
  justify-content: space-between;
`

const EditContainer = styled(Row)`
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  width: 320px;
  z-index: 1;
  background-color: ${colors.white};
  box-shadow: 0px 6px 8px ${colors.gray300};
  border-radius: 8px;
`
const CellTableInput = memo(function <TData>({
  template,
  cellData,
  CellComponent,
  onUploadOpen,
  onInputDone,
  placeholder,
  isEditing: isEditingProp,
}: CellTableInputPropsType<TData>) {
  const [isEditing, setIsEditing] = useState(false)

  const allowedInputs = useMemo(() => {
    //TODO: extend in the future or change to validation rules depending on template.type
    const allowed: TAllowedInputs = ['text']

    if (allowedFileInputFieldTypes.includes(template?.type)) allowed.push('file')

    return allowed
  }, [template, cellData])

  useEffect(() => {
    isEditingProp && setIsEditing(isEditingProp)
  }, [isEditingProp])

  const onEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      setIsEditing(false)
    }
    if (e.key === 'Enter' || e.key === 'Tab') {
      e.preventDefault()
      setIsEditing(false)
      onInputDone && onInputDone(e.currentTarget?.value, e.key === 'Tab')
    }
  }

  const onUploadClick = () => {
    onUploadOpen && onUploadOpen()
  }

  return (
    <CellComponent onClick={() => setIsEditing(true)}>
      {!isEditing && !!cellData && (
        <PreviewContainer>
          <Text css={$preview}>{cellData?.data || placeholder}</Text>
          {!!cellData.data && (
            <Badge color={cellData?.hasError ? colors.error500 : colors.success500} count={1} dot>
              <div />
            </Badge>
          )}
        </PreviewContainer>
      )}
      {!isEditing && !cellData && (
        <PreviewContainer>
          <Text css={$placeholder}>{placeholder}</Text>
        </PreviewContainer>
      )}
      {isEditing && (
        <EditContainer onBlur={() => setTimeout(() => setIsEditing(false), 150)}>
          <Col span={24}>
            <TextInput
              defaultValue={cellData?.data}
              autoFocus
              onKeyDown={onEnter}
              suffix={
                allowedInputs.includes('file') && (
                  <Button
                    type={'text'}
                    iconLeft={'UploadCloud02'}
                    iconLeftProps={{ size: 20 }}
                    onClick={onUploadClick}
                  />
                )
              }
            />
          </Col>
        </EditContainer>
      )}
    </CellComponent>
  )
})

const $preview = css`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 85%;
`

const $placeholder = css`
  font-style: italic;
  opacity: 0.6;
  font-size: 12px;
`

export default CellTableInput
