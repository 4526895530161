/** @jsxImportSource @emotion/react */
import { useState, useMemo } from 'react'
import { css } from '@emotion/react'
import {
  IRadioButtonGroupProps,
  PanelCard,
  Table,
  Row,
  RangePicker,
  Space,
  Text,
  colors,
  Button,
  Col,
  Avatar,
} from 'common-components'

import FiltersContributor from './FiltersContributor'
import ContributorTag, { TContributorsTag } from './TagContributor'

const radioButtonGroupProps = {
  size: 'md',
  value: 1,
  items: [
    { value: 1, label: 'Option 1', iconLeft: 'Speedometer02' },
    { value: 2, label: 'Option 2', iconLeft: 'Flash' },
  ],
}

type MetricType = {
  name: string
  metric?: string
  value: string
  progressType: TContributorsTag
  progress: string
}

const dummyData: MetricType[] = [
  {
    name: 'Saisie Centre Paris',
    metric: 'Vitesse',
    value: '1,2',
    progressType: 'negative',
    progress: '20%',
  },
  {
    name: 'Saisie Centre Paris',
    metric: 'Vitesse',
    value: '9,8',
    progressType: 'positive',
    progress: '30%',
  },
  {
    name: 'Saisie Centre Paris',
    metric: 'Qualité',
    value: '4,4',
    progressType: 'negative',
    progress: '10%',
  },
  {
    name: 'Saisie Centre Paris',
    metric: 'Qualité',
    value: '1,5',
    progressType: 'positive',
    progress: '40%',
  },
]

const dummyData2: MetricType[] = [
  {
    name: 'Maroc ',
    value: '$1,800',
    progressType: 'negative',
    progress: '20%',
  },
  {
    name: 'Maroc ',
    value: '$1,800',
    progressType: 'negative',
    progress: '20%',
  },
  {
    name: 'Maroc ',
    value: '$1,800',
    progressType: 'negative',
    progress: '20%',
  },
  {
    name: 'Maroc ',
    value: '$1,800',
    progressType: 'negative',
    progress: '20%',
  },
  {
    name: 'Maroc ',
    value: '$1,800',
    progressType: 'negative',
    progress: '20%',
  },
  {
    name: 'Maroc ',
    value: '$1,800',
    progressType: 'negative',
    progress: '20%',
  },
  {
    name: 'Maroc ',
    value: '$1,800',
    progressType: 'negative',
    progress: '20%',
  },
  {
    name: 'Maroc ',
    value: '$1,800',
    progressType: 'negative',
    progress: '20%',
  },
  {
    name: 'Maroc ',
    value: '$1,800',
    progressType: 'negative',
    progress: '20%',
  },
  {
    name: 'Maroc ',
    value: '$1,800',
    progressType: 'negative',
    progress: '20%',
  },
]

const MetricsContributor = () => {
  const [value1, setValue1] = useState<number | string>(1)
  const [value2, setValue2] = useState<number | string>(1)
  const [value3, setValue3] = useState<number | string>(1)
  const [isFilterDrawerVisible, setFilterDrawerVisible] = useState(false)
  const tableColumns = useMemo(() => {
    try {
      return [
        {
          title: 'Projet',
          key: 'name',
          sorter: (a: MetricType, b: MetricType) => a.name.localeCompare(b.name),
          render: (record: MetricType) => (
            <Row align={'middle'}>
              <Space size={'middle'}>
                <Text size='sm'>{record.name}</Text>
              </Space>
            </Row>
          ),
        },
        {
          title: 'Objectif',
          key: 'metric',
          sorter: (a: MetricType, b: MetricType) => a.name.localeCompare(b.name),
          render: (record: MetricType) => (
            <Row
              align={'middle'}
              css={css`
                min-width: 150px;
              `}
            >
              <Space size={'middle'}>
                <Text size='sm'>{record.metric}</Text>
              </Space>
            </Row>
          ),
        },
        {
          title: null,
          key: 'value',
          render: (record: MetricType) => (
            <Row align={'middle'}>
              <Space size={'middle'}>
                <Text size='sm'>{record.value}</Text>
                <ContributorTag state={record.progressType}>{record.progress}</ContributorTag>
              </Space>
            </Row>
          ),
        },
      ]
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
      return []
    }
  }, [])

  const tableColumns2 = useMemo(
    () => [
      {
        title: 'Projet',
        key: 'name',
        sorter: (a: MetricType, b: MetricType) => a.name.localeCompare(b.name),
        render: (record: MetricType) => (
          <Row
            align={'middle'}
            css={css`
              min-width: 900px;
            `}
          >
            <Space size={'middle'} style={{ gap: 12 }}>
              <Avatar size='sm' />
              <Text size='sm' strong>
                {record.name}
              </Text>
            </Space>
          </Row>
        ),
      },
      {
        title: 'Qualité',
        key: 'value',
        render: (record: MetricType) => (
          <Row align={'middle'}>
            <Space size={'middle'}>
              <Text size='sm'>{record.value}</Text>
              <ContributorTag state={record.progressType}>{record.progress}</ContributorTag>
            </Space>
          </Row>
        ),
      },
    ],
    [],
  )

  const content = (
    <div
      style={{
        display: 'flex',
        height: '200px',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      Graph Content
    </div>
  )

  return (
    <div
      css={css`
        margin-bottom: 20px;
      `}
    >
      <Row justify={'space-between'}>
        <RangePicker />
        <Row
          css={css`
            gap: 12px;
          `}
        >
          <Button iconLeft='Download01'>Export</Button>
          <Button
            iconLeft='FilterLines'
            onClick={() => {
              setFilterDrawerVisible(true)
            }}
          >
            Filtrer
          </Button>
        </Row>
      </Row>
      <div
        css={css`
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 24px;
          width: 100%;
          margin-top: 20px;
        `}
      >
        <PanelCard
          title='Volume'
          // TOFIX: maybe this prop should accept ReactNode so we could pass any tag?
          tagText='TOP 20%'
          width={596}
          content={content}
          value={value1}
          onChange={(e: any) => setValue1(e.target.value)}
          radioButtonGroupProps={radioButtonGroupProps as IRadioButtonGroupProps}
        />
        <PanelCard
          title='Qualité'
          value={value2}
          onChange={(e: any) => setValue2(e.target.value)}
          width={596}
          content={content}
          radioButtonGroupProps={radioButtonGroupProps as IRadioButtonGroupProps}
        />
        <PanelCard
          title='Temps moyen de réalisation'
          value={value3}
          onChange={(e: any) => setValue3(e.target.value)}
          width={596}
          content={content}
          radioButtonGroupProps={radioButtonGroupProps as IRadioButtonGroupProps}
        />
        <PanelCard
          title='Table'
          onChange={(e: any) => setValue1(e.target.value)}
          width={596}
          content={
            <div
              css={css`
                margin-top: 10px;
                width: 100%;
              `}
            >
              <Table<MetricType> data={dummyData} columns={tableColumns} />
            </div>
          }
        />
      </div>
      <Row
        justify={'space-between'}
        align={'middle'}
        css={css`
          border: 1px solid ${colors.gray200};
          border-radius: 12px;
          padding: 24px 26px;
          margin-top: 20px;
        `}
      >
        <Col
          css={css`
            display: flex;
            align-items: center;
            gap: 8px;
          `}
        >
          <Text
            css={css`
              font-size: 18px;
              line-height: 28px;
              font-weight: 700;
            `}
          >
            Compétences
          </Text>
        </Col>
      </Row>
      <Table<MetricType> data={dummyData2} columns={tableColumns2} />
      <FiltersContributor
        visible={isFilterDrawerVisible}
        onClose={() => setFilterDrawerVisible(false)}
      />
    </div>
  )
}

export default MetricsContributor
