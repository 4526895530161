import { FilterPayloadType, IconType } from '@types'
import { SegmentShortApiType, UserShortApiType, SegmentApiType } from '@api/types'
import {
  axiosClient,
  IdManagerRightType,
  SegmentTypeType,
  TSegmentDefType,
  TSkillType,
  TSociodemographicCriteria,
  TSociodemographicCriteriaValues,
  TUserType,
} from '@definitions'
import { AxiosResponse } from 'axios'
import { catchApiError } from '@utils'

export type GetSegmentsPayloadType = Pick<
  FilterPayloadType,
  'endDate' | 'search' | 'startDate' | 'orderByAsc'
> & {
  model: Partial<{
    role?: IdManagerRightType
    segmentType: TSegmentDefType[]
    managers?: string[]
  }>
}
export const getSegments = async (filters: GetSegmentsPayloadType): Promise<SegmentApiType[]> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<SegmentApiType[]> = await axios.post(
      '/SegmentService/Segment/GetSegments',
      filters,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export type GetSegmentsSupplierPayloadType = {
  idContributor: string
}
export const getSegmentsSupplier = async ({
  idContributor,
}: GetSegmentsSupplierPayloadType): Promise<SegmentApiType[]> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<SegmentApiType[]> = await axios.get(
      '/SegmentService/Segment/GetSegmentsSupplier/' + idContributor,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export type GetSegmentPayloadType = {
  idSegment: string
}

export const getSegment = async ({ idSegment }: GetSegmentPayloadType): Promise<SegmentApiType> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<SegmentApiType> = await axios.get(
      `/SegmentService/Segment/${idSegment}`,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export type DownloadSegmentsPayloadType = {
  filters: FilterPayloadType & {
    model: Partial<{
      name: string
      icon: {
        iconColor: string | null
        iconName: string | null
      }
      creationDate: string
      updateDate: string
      role: IdManagerRightType
      segmentType: TSegmentDefType[]
      state: number[]
      frequency: number[]
      managers: string[]
      segments: string[]
    }>
  }
}
export const downloadSegments = async ({
  filters = { model: {} },
}: DownloadSegmentsPayloadType) => {
  try {
    const axios = axiosClient()
    const response: AxiosResponse<Blob> = await axios.post(
      '/SegmentService/Segment/GetSegments/Download',
      filters,
      {
        responseType: 'blob',
      },
    )
    return response.data
  } catch (e) {
    catchApiError(e)
  }
}

export type AddManagerToSegmentPayloadType = {
  id: string
  managers: UserShortApiType[]
}
export const addManagerToSegment = async ({ id, managers }: AddManagerToSegmentPayloadType) => {
  try {
    const axios = axiosClient()
    await axios.post(`/SegmentService/Segment/${id}/AddManagers`, { idSegment: id, managers })
  } catch (e) {
    catchApiError(e)
  }
}

export type DeleteManagerFromSegmentPayloadType = {
  id: string
  managers: UserShortApiType[]
}
export const deleteManagerFromSegment = async ({
  id,
  managers,
}: DeleteManagerFromSegmentPayloadType) => {
  try {
    const axios = axiosClient()
    await axios.put(`/SegmentService/Segment/${id}/DeleteManagers`, { idSegment: id, managers })
  } catch (e) {
    catchApiError(e)
  }
}

export type AddUsersToSegmentPayloadType = {
  id: string
  users: {
    idUser: string
    email: string
    firstName: string
    lastName: string
    type: TUserType
    urlAvatar: string
  }[]
}
export const addUsersToSegment = async ({
  id,
  users,
}: AddUsersToSegmentPayloadType): Promise<SegmentApiType> => {
  try {
    const axios = axiosClient()
    const resp = await axios.post(`/SegmentService/Segment/${id}/AddUsers`, {
      idSegment: id,
      users,
    })
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export type DeleteUserFromSegmentPayloadType = {
  id: string
  users: {
    idUser: string
    email: string
    firstName: string
    lastName: string
    type: TUserType
    urlAvatar: string
  }[]
}
export const deleteUsersFromSegment = async ({ id, users }: DeleteUserFromSegmentPayloadType) => {
  try {
    const axios = axiosClient()
    await axios.put(`/SegmentService/Segment/${id}/DeleteUsers`, { idSegment: id, users })
  } catch (e) {
    catchApiError(e)
  }
}

export type UpdateUsersSegmentPayloadType = {
  id: string
  users: {
    idUser: string
    email: string
    firstName: string
    lastName: string
    type: TUserType
    urlAvatar: string
  }[]
}
export const updateUsersSegment = async ({ id, users }: UpdateUsersSegmentPayloadType) => {
  try {
    const axios = axiosClient()
    await axios.put(`/SegmentService/Segment/${id}/UpdateUsers`, { users })
  } catch (e) {
    catchApiError(e)
  }
}

export type UpdateManagersSegmentPayloadType = {
  idSegment: string
  managersShort: UserShortApiType[]
}
export const updateManagersSegment = async ({
  idSegment,
  managersShort,
}: UpdateManagersSegmentPayloadType) => {
  try {
    const axios = axiosClient()
    await axios.put(`/SegmentService/Segment/${idSegment}/UpdateManagers`, {
      managers: managersShort,
    })
  } catch (e) {
    catchApiError(e)
  }
}

export type UpdateSegmentSkillPayloadType = {
  idSegment: string
  percentageForValidation: number
  qualityMin: number
  skills: TSkillType[]
}
export const updateSegmentSkill = async ({
  idSegment,
  percentageForValidation,
  skills,
  qualityMin,
}: UpdateSegmentSkillPayloadType) => {
  try {
    const axios = axiosClient()
    await axios.put(`/SegmentService/Segment/${idSegment}/UpdateSkill`, {
      percentageForValidation,
      skills,
      qualityMin,
    })
  } catch (e) {
    catchApiError(e)
  }
}

export type UpdateSegmentSocioDemographicPayloadType = {
  idSegment: string
  socioDemographicCriteria: TSociodemographicCriteriaValues | TSociodemographicCriteria
  socioDemographicMinValue?: number
  socioDemographicMaxValue?: number
  socioDemographicCriteriaValues?: string[]
}
export const updateSegmentSocioDemographic = async ({
  idSegment,
  socioDemographicMaxValue,
  socioDemographicMinValue,
  socioDemographicCriteriaValues,
  socioDemographicCriteria,
}: UpdateSegmentSocioDemographicPayloadType) => {
  try {
    const axios = axiosClient()
    await axios.put(`/SegmentService/Segment/${idSegment}/UpdateSocioDemographic`, {
      socioDemographicMaxValue,
      socioDemographicMinValue,
      socioDemographicCriteriaValues,
      socioDemographicCriteria,
    })
  } catch (e) {
    catchApiError(e)
  }
}

type RequestAccessForSegmentPayloadType = {
  idSegment: string
}
export const requestAccessForSegment = async ({
  idSegment,
}: RequestAccessForSegmentPayloadType) => {
  try {
    const axios = axiosClient()
    const resp = await axios.get(`/SegmentService/Segment/${idSegment}/Join`)
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export type CreateSegmentPayloadType = {
  icon: IconType
  name: string
  segmentType: SegmentTypeType
}
export const createSegment = async (payload: CreateSegmentPayloadType): Promise<SegmentApiType> => {
  try {
    const axios = axiosClient()
    const resp = await axios.post<SegmentApiType>(`/SegmentService/Segment/CreateSegment`, payload)
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export const removeSegment = async (id: string): Promise<void> => {
  try {
    const axios = axiosClient()
    const resp = await axios.delete(`/SegmentService/Segment/${id}/DeleteSegment`)
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export type CreateCertificationSegmentPayloadType = {
  icon?: IconType
  name: string
  qualityMin?: number
  managers?: UserShortApiType[]
  certification: {
    icon?: IconType
    idCertification: string
    name: string
  }
}
export const createCertificationSegment = async (data: CreateCertificationSegmentPayloadType) => {
  try {
    const axios = axiosClient()
    const resp = await axios.post<SegmentApiType>(
      `/SegmentService/Segment/CreateCertificationSegment`,
      data,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export type UpdateCertificationSegmentPayloadType = {
  idSegment: string
  icon?: IconType
  name: string
  qualityMin?: number
  managers?: UserShortApiType[]
  certification: {
    icon?: IconType
    idCertification: string
    name: string
  }
}
export const updateCertificationSegment = async (
  payload: UpdateCertificationSegmentPayloadType,
) => {
  try {
    const axios = axiosClient()
    const resp = await axios.put<SegmentApiType>(
      `/SegmentService/Segment/UpdateCertificationSegment`,
      payload,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

type UpdateSegmentInfoPayloadType = {
  id: string
  body: {
    name: string
    icon: {
      iconColor: string | null
      iconName: string | null
    }
  }
}
export const updateSegmentInfo = async ({ id, body }: UpdateSegmentInfoPayloadType) => {
  try {
    const axios = axiosClient()
    const resp = await axios.put<SegmentShortApiType>(
      `/SegmentService/Segment/${id}/UpdateInfo`,
      body,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}
