/** @jsxImportSource @emotion/react */

import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Space, Statistic, StatisticProps } from 'antd'
import { Bar, BarChart, ResponsiveContainer } from 'recharts'
import { useNavigate } from 'react-router'
import { Col, colors, ILayoutCardProps, LayoutCard, Row, Icon, Title } from 'common-components'

export type StatisticsCardPropsType = ILayoutCardProps & {
  statistics?: StatisticProps[]
  graphData?: any
  title?: string
  chartColor?: string
  link?: string
}

const ContentContainer = styled(Col)``
const Container = styled(Row)`
  width: 100%;
  padding: 20px;
`
const StatisticsCard = ({
  title,
  statistics = [],
  chartColor,
  link,
  ...rest
}: StatisticsCardPropsType) => {
  const navigate = useNavigate()
  const data = [
    {
      name: 'Page A',
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: 'Page B',
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: 'Page C',
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: 'Page D',
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: 'Page E',
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: 'Page F',
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: 'Page G',
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ]

  const Graph = () => (
    <Row
      css={css`
        padding-top: 10px;
      `}
    >
      <ResponsiveContainer width='100%' height={100}>
        <BarChart width={150} height={100} data={[...data, ...data, ...data]}>
          <Bar dataKey='uv' fill={chartColor || colors.primary400} strokeOpacity={0} radius={5} />
        </BarChart>
      </ResponsiveContainer>
    </Row>
  )

  return (
    <LayoutCard
      css={css`
        background-color: ${colors.gray800};
      `}
      style={{ width: 390, minHeight: 240 }}
      HeaderComponent={
        <Container style={{ cursor: 'pointer' }} onClick={() => navigate(link)}>
          <Title style={{ fontWeight: 600, fontSize: 20, color: colors.white }}>{title}</Title>
          <Icon icon={'AndroidOpen1'} />
        </Container>
      }
      ContentComponent={
        <Container style={{ paddingTop: 0 }}>
          <ContentContainer span={24}>
            <Space size={30}>
              {statistics.map((item) => (
                <Statistic
                  css={css`
                    .ant-statistic-title {
                      color: ${colors.white};
                      font-weight: 400;
                      font-size: 10px;
                    }
                  `}
                  valueStyle={{ color: colors.white, fontWeight: 600, fontSize: 18 }}
                  {...item}
                />
              ))}
            </Space>
            <Graph />
          </ContentContainer>
        </Container>
      }
      {...rest}
    />
  )
}

export default StatisticsCard
