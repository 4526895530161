import { format, parse, differenceInYears } from 'date-fns'

class DateUtils {
  static formatDate(dateString: string) {
    const locale = window.navigator.language
    const parsedDate = parse(dateString)
    return format(parsedDate, 'd MMMM YYYY', { locale })
  }

  static getYears(dateString: string) {
    const parsedDate = parse(dateString)
    const currentDate = new Date()
    return differenceInYears(currentDate, parsedDate)
  }

  static convertEffectiveData(dateString: string) {
    if (!dateString) return
    const [days, time] = dateString.split('.')
    const [hours, minutes, seconds] = time.split(':')

    const totalHours =
      parseInt(days) * 24 + parseInt(hours) + parseInt(minutes) / 60 + parseInt(seconds) / 3600

    return totalHours
  }

  static convertTimeToSeconds(dateString: string) {
    const timeArray = dateString.split(':')
    const seconds = +timeArray[0] * 3600 + +timeArray[1] * 60 + +timeArray[2]
    return seconds
  }
}

export default DateUtils
