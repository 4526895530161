export const findKeyByValue = <K extends number, T extends string>(
  object: Record<K, T>,
  value: T,
): K => {
  for (const key in object) {
    if (object[key] === value) {
      return Number(key) as K
    }
  }
  return null
}
