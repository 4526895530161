/** @jsxImportSource @emotion/react */
import { useState } from 'react'
import { css } from '@emotion/react'
import { getSegments } from '@api/segment'
import { getStateKeyByValue } from '@utils'
import { SegmentDefType } from '@definitions'
import { SelectAsync, ValueSelectType } from '@components/inputs'
import { useTranslation } from '@hooks'
import { SegmentShortType } from '@pages/types'
import { LoadingOutlined } from '@ant-design/icons'
import { Avatar, Row, Space, Text, colors, Icon, Button } from 'common-components'

export type AssignSegmentPropsType = {
  segments?: SegmentShortType[]
  isLoading?: boolean
  onAddSegment: (segmentShort: SegmentShortType) => void
  onDeleteSegment: (idSegment: string) => void
}

type OptionType = ValueSelectType & {
  segmentData: SegmentShortType
}

const segmentsMapper = (segments: SegmentShortType[]): OptionType[] =>
  segments.map((segment) => ({
    value: segment.idSegment,
    label: segment.name,
    segmentData: segment,
  }))

const AssignSegment = ({
  segments = [],
  isLoading,
  onDeleteSegment,
  onAddSegment,
}: AssignSegmentPropsType) => {
  const { translate } = useTranslation()

  const [selectedSegment, setSelectedSegment] = useState<OptionType | ''>('')
  const [selectedSegments, setSelectedSegments] = useState<OptionType[]>(segmentsMapper(segments))

  const loadOptions = (inputValue: string, callback: (options: OptionType[]) => void) => {
    getSegments({
      search: inputValue,
      orderByAsc: true,
      model: {
        segmentType: [getStateKeyByValue(SegmentDefType, 'manual')],
      },
    })
      .then((segmentsData) => {
        const loadOptions: OptionType[] = segmentsData.map(
          (segment): OptionType => ({
            value: segment.idSegment,
            label: segment.name,
            segmentData: segment,
          }),
        )
        callback(loadOptions)
      })
      .catch(() => {
        callback([])
      })
  }

  return (
    <Row
      css={css`
        width: 100%;
        margin-top: 20px;
      `}
    >
      <Space
        css={css`
          display: grid;
          grid-template-columns: 10fr 1fr;
          gap: 6px;
          margin-bottom: 20px;
          width: 100%;
        `}
      >
        <SelectAsync<OptionType>
          loadOptions={loadOptions}
          value={selectedSegment}
          valuesSelected={selectedSegments.map(({ label }) => label)}
          onChange={setSelectedSegment}
        />
        <Button
          onClick={() => {
            if (selectedSegment !== '') {
              setSelectedSegments([...selectedSegments, selectedSegment])
              setSelectedSegment('')
              onAddSegment({
                idSegment: selectedSegment?.segmentData?.idSegment,
                nbMinEvaluation: selectedSegment?.segmentData?.nbMinEvaluation,
                icon: selectedSegment?.segmentData?.icon,
                qualityMin: selectedSegment?.segmentData?.qualityMin,
                name: selectedSegment?.segmentData?.name,
                segmentType: selectedSegment?.segmentData?.segmentType,
                managerRight: selectedSegment?.segmentData?.managerRight,
              })
            }
          }}
          disabled={!selectedSegment || isLoading}
          type='primary'
        >
          {isLoading ? <LoadingOutlined /> : translate('display.add')}
        </Button>
      </Space>
      {selectedSegments.map((item) => {
        return (
          <Row
            key={item.value}
            justify={'space-between'}
            align='middle'
            css={css`
              height: 44px;
              width: 100%;
            `}
          >
            <Space
              css={css`
                display: flex;
                gap: 12px;
                align-items: center;
              `}
            >
              <Avatar />
              <Text color={colors.gray600}>{item.label}</Text>
            </Space>
            <div
              css={css`
                display: flex;
                align-items: center;
                gap: 20px;
              `}
            >
              <div
                onClick={() => {
                  setSelectedSegments(selectedSegments.filter(({ value }) => value !== item.value))
                  onDeleteSegment(item.value)
                }}
              >
                <Icon width={18} height={18} icon='XClose' />
              </div>
            </div>
          </Row>
        )
      })}
    </Row>
  )
}

export default AssignSegment
