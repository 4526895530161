import { CertificationType, CertificationUserTestType, QuestionType } from '@pages/types'

import { ImmerStateCreator, StatusAction } from '../store.type'

import {
  addManagersToCertificationAction,
  AddManagersToCertificationActionPayloadType,
  createCertificationAction,
  CreateCertificationActionPayloadType,
  createSegmentCertificationAction,
  CreateSegmentCertificationActionPayloadType,
  deleteSegmentCertificationAction,
  DeleteSegmentCertificationActionPayloadType,
  getCertificationAction,
  GetCertificationActionPayloadType,
  getCertificationsAction,
  GetCertificationsActionPayloadType,
  updateFormCertificationAction,
  UpdateFormCertificationActionPayloadType,
  updateInformationCertificationAction,
  UpdateInformationCertificationActionPayloadType,
  updateNextStepCertificationAction,
  UpdateNextStepCertificationActionPayloadType,
  updatePrefillTypeCertificationAction,
  UpdatePrefillTypeCertificationActionPayloadType,
  updateSegmentCertificationAction,
  UpdateSegmentCertificationActionPayloadType,
  updateStateCertificationAction,
  UpdateStateCertificationActionPayloadType,
  uploadAccessCertificationAction,
  UploadAccessCertificationActionPayloadType,
} from './certification.action'
import {
  attachQuestionsToCertificationAction,
  AttachQuestionsToCertificationActionPayloadType,
  createDraftQuestionCertificationAction,
  CreateDraftQuestionCertificationActionPayloadType,
  deleteDraftQuestionCertificationAction,
  DeleteDraftQuestionCertificationActionPayloadType,
  deleteQuestionsCertificationAction,
  DeleteQuestionsCertificationActionPayloadType,
  getDraftQuestionsCertificationAction,
  GetDraftQuestionsCertificationActionPayloadType,
  getQuestionCertificationAction,
  GetQuestionCertificationActionPayloadType,
  updateDraftQuestionCertificationAction,
  UpdateDraftQuestionCertificationActionPayloadType,
  uploadColumnFileQuestionsCertificationAction,
  UploadColumnFileQuestionsCertificationActionPayloadType,
  uploadQuestionsFileCertificationAction,
  UploadQuestionsFileCertificationActionPayloadType,
  getQuestionsCertificationAction,
  GetQuestionsCertificationActionPayloadType,
  updateTrainingQuestionCertificationAction,
  UpdateTrainingQuestionCertificationActionPayloadType,
} from './question.action'
import {
  deleteRespondentCertificationAction,
  DeleteRespondentCertificationActionPayloadType,
  downloadRespondentsCertificationAction,
  DownloadRespondentsCertificationActionPayloadType,
  getQuestionRespondentCertificationAction,
  GetQuestionRespondentCertificationActionPayloadType,
  getQuestionsRespondentCertificationAction,
  GetQuestionsRespondentCertificationActionPayloadType,
  getRespondentsCertificationAction,
  GetRespondentsCertificationActionPayloadType,
} from './respondent.action'

import { StoreGlobalType } from '@/store/store'

/** StatusAction
 *
----------------------------- Lifecycle ----------------------------------
  IDLE => LOADING => SUCCEEDED or FAILED
 *
 */

export type CertificationSliceType = {
  /** certifications **/
  certifications: CertificationType[]
  questionsFiltered: QuestionType[]
  questionsDraft: QuestionType[]
  respondentsFiltered: CertificationUserTestType[]
  /** ACTION **/
  /** CERTIFICATION **/
  createCertificationAction: {
    call: (
      payload: CreateCertificationActionPayloadType,
      callback: (idCertification: string) => void,
    ) => void
    status: StatusAction
  }
  createSegmentCertificationAction: {
    call: (payload: CreateSegmentCertificationActionPayloadType) => void
    status: StatusAction
  }
  updateSegmentCertificationAction: {
    call: (payload: UpdateSegmentCertificationActionPayloadType) => void
    status: StatusAction
  }
  deleteSegmentCertificationAction: {
    call: (payload: DeleteSegmentCertificationActionPayloadType) => void
    status: StatusAction
  }
  updateFormCertificationAction: {
    call: (payload: UpdateFormCertificationActionPayloadType) => void
    status: StatusAction
  }
  updatePrefillTypeCertificationAction: {
    call: (payload: UpdatePrefillTypeCertificationActionPayloadType, callback: () => void) => void
    status: StatusAction
  }
  updateInformationCertificationAction: {
    call: (payload: UpdateInformationCertificationActionPayloadType, callback: () => void) => void
    status: StatusAction
  }
  addManagersToCertificationAction: {
    call: (payload: AddManagersToCertificationActionPayloadType) => void
    status: StatusAction
  }
  getCertificationsAction: {
    call: (payload: GetCertificationsActionPayloadType) => void
    status: StatusAction
  }
  getCertificationAction: {
    call: (payload: GetCertificationActionPayloadType) => void
    status: StatusAction
  }
  uploadAccessCertificationAction: {
    call: (payload: UploadAccessCertificationActionPayloadType) => void
    status: StatusAction
  }
  updateNextStepCertificationAction: {
    call: (
      payload: UpdateNextStepCertificationActionPayloadType,
      callback: (nextStep: number) => void,
    ) => void
    status: StatusAction
  }
  attachQuestionsToCertificationAction: {
    call: (payload: AttachQuestionsToCertificationActionPayloadType, callback: () => void) => void
    status: StatusAction
  }
  updateStateCertificationAction: {
    call: (payload: UpdateStateCertificationActionPayloadType) => void
    status: StatusAction
  }
  /** QUESTION **/
  updateTrainingQuestionCertificationAction: {
    call: (payload: UpdateTrainingQuestionCertificationActionPayloadType) => void
    status: StatusAction
  }
  getQuestionsCertificationAction: {
    call: (payload: GetQuestionsCertificationActionPayloadType) => void
    status: StatusAction
  }
  createDraftQuestionCertificationAction: {
    call: (
      payload: CreateDraftQuestionCertificationActionPayloadType,
      callback: (questionDraft: QuestionType) => void,
    ) => void
    status: StatusAction
  }
  updateDraftQuestionCertificationAction: {
    call: (payload: UpdateDraftQuestionCertificationActionPayloadType) => void
    status: StatusAction
  }
  getQuestionCertificationAction: {
    call: (payload: GetQuestionCertificationActionPayloadType) => void
    status: StatusAction
  }
  getDraftQuestionsCertificationAction: {
    call: (payload: GetDraftQuestionsCertificationActionPayloadType, callback?: () => void) => void
    status: StatusAction
  }
  deleteDraftQuestionCertificationAction: {
    call: (payload: DeleteDraftQuestionCertificationActionPayloadType) => void
    status: StatusAction
  }
  uploadQuestionsFileCertificationAction: {
    call: (payload: UploadQuestionsFileCertificationActionPayloadType, callback: () => void) => void
    status: StatusAction
  }
  uploadColumnFileQuestionsCertificationAction: {
    call: (
      payload: UploadColumnFileQuestionsCertificationActionPayloadType,
      callback: () => void,
    ) => void
    status: StatusAction
  }
  deleteQuestionsCertificationAction: {
    call: (payload: DeleteQuestionsCertificationActionPayloadType, callback?: () => void) => void
    status: StatusAction
  }
  /** RESPONDENT **/
  getRespondentsCertificationAction: {
    call: (payload: GetRespondentsCertificationActionPayloadType) => void
    status: StatusAction
  }
  downloadRespondentsCertificationAction: {
    call: (payload: DownloadRespondentsCertificationActionPayloadType) => void
    status: StatusAction
  }
  getQuestionsRespondentCertificationAction: {
    call: (payload: GetQuestionsRespondentCertificationActionPayloadType) => void
    status: StatusAction
  }
  getQuestionRespondentCertificationAction: {
    call: (payload: GetQuestionRespondentCertificationActionPayloadType) => void
    status: StatusAction
  }
  deleteRespondentCertificationAction: {
    call: (payload: DeleteRespondentCertificationActionPayloadType, callback: () => void) => void
    status: StatusAction
  }
}

const certificationSlice: ImmerStateCreator<CertificationSliceType> = (
  set,
  get: () => StoreGlobalType & CertificationSliceType,
) => ({
  certifications: [],
  questionsFiltered: [],
  questionsDraft: [],
  respondentsFiltered: [],
  /**  **/
  getCertificationsAction: {
    call: (payload) => getCertificationsAction(set, get)(payload),
    status: 'IDLE',
  },
  getCertificationAction: {
    call: (payload) => getCertificationAction(set, get)(payload),
    status: 'IDLE',
  },
  getQuestionsCertificationAction: {
    call: (payload) => getQuestionsCertificationAction(set)(payload),
    status: 'IDLE',
  },
  getDraftQuestionsCertificationAction: {
    call: (payload, callback) => getDraftQuestionsCertificationAction(set)(payload, callback),
    status: 'IDLE',
  },
  getRespondentsCertificationAction: {
    call: (payload) => getRespondentsCertificationAction(set, get)(payload),
    status: 'IDLE',
  },
  getQuestionCertificationAction: {
    call: (payload) => getQuestionCertificationAction(set)(payload),
    status: 'IDLE',
  },
  getQuestionsRespondentCertificationAction: {
    call: (payload) => getQuestionsRespondentCertificationAction(set)(payload),
    status: 'IDLE',
  },
  getQuestionRespondentCertificationAction: {
    call: (payload) => getQuestionRespondentCertificationAction(set)(payload),
    status: 'IDLE',
  },
  createCertificationAction: {
    call: (payload, callback) => createCertificationAction(set, get)(payload, callback),
    status: 'IDLE',
  },
  uploadAccessCertificationAction: {
    call: (payload) => uploadAccessCertificationAction(set)(payload),
    status: 'IDLE',
  },
  updateFormCertificationAction: {
    call: (payload) => updateFormCertificationAction(set, get)(payload),
    status: 'IDLE',
  },
  updatePrefillTypeCertificationAction: {
    call: (payload, callback) => updatePrefillTypeCertificationAction(set)(payload, callback),
    status: 'IDLE',
  },
  updateNextStepCertificationAction: {
    call: (payload, callback) => updateNextStepCertificationAction(set)(payload, callback),
    status: 'IDLE',
  },
  createDraftQuestionCertificationAction: {
    call: (payload, callback) => createDraftQuestionCertificationAction(set)(payload, callback),
    status: 'IDLE',
  },
  updateDraftQuestionCertificationAction: {
    call: (payload) => updateDraftQuestionCertificationAction(set)(payload),
    status: 'IDLE',
  },
  attachQuestionsToCertificationAction: {
    call: (payload, callback) => attachQuestionsToCertificationAction(set, get)(payload, callback),
    status: 'IDLE',
  },
  deleteDraftQuestionCertificationAction: {
    call: (payload) => deleteDraftQuestionCertificationAction(set)(payload),
    status: 'IDLE',
  },
  uploadQuestionsFileCertificationAction: {
    call: (payload, callback) => uploadQuestionsFileCertificationAction(set)(payload, callback),
    status: 'IDLE',
  },
  uploadColumnFileQuestionsCertificationAction: {
    call: (payload, callback) =>
      uploadColumnFileQuestionsCertificationAction(set)(payload, callback),
    status: 'IDLE',
  },
  updateInformationCertificationAction: {
    call: (payload, callback) => updateInformationCertificationAction(set)(payload, callback),
    status: 'IDLE',
  },
  addManagersToCertificationAction: {
    call: (payload) => addManagersToCertificationAction(set)(payload),
    status: 'IDLE',
  },
  createSegmentCertificationAction: {
    call: (payload) => createSegmentCertificationAction(set, get)(payload),
    status: 'IDLE',
  },
  updateSegmentCertificationAction: {
    call: (payload) => updateSegmentCertificationAction(set, get)(payload),
    status: 'IDLE',
  },
  deleteSegmentCertificationAction: {
    call: (payload) => deleteSegmentCertificationAction(set, get)(payload),
    status: 'IDLE',
  },
  updateStateCertificationAction: {
    call: (payload) => updateStateCertificationAction(set, get)(payload),
    status: 'IDLE',
  },
  deleteQuestionsCertificationAction: {
    call: (payload, callback) => deleteQuestionsCertificationAction(set, get)(payload, callback),
    status: 'IDLE',
  },
  updateTrainingQuestionCertificationAction: {
    call: (payload) => updateTrainingQuestionCertificationAction(set, get)(payload),
    status: 'IDLE',
  },
  deleteRespondentCertificationAction: {
    call: (payload, callback) => deleteRespondentCertificationAction(set)(payload, callback),
    status: 'IDLE',
  },
  downloadRespondentsCertificationAction: {
    call: (payload) => downloadRespondentsCertificationAction(set)(payload),
    status: 'IDLE',
  },
})

export default certificationSlice
