/** @jsxImportSource @emotion/react */

import { useNavigate, useOutletContext, useParams } from 'react-router'
import { QuestionRespondentCertification } from '@views/certification'
import {
  Button,
  ButtonGroup,
  colors,
  LayoutContainer,
  Paragraph,
  Row,
  Space,
  Tag,
} from 'common-components'
import { useDelayedEffect, useTranslation } from '@hooks'
import { routes } from '@navigation/routes'
import { Loader } from '@components/utils'
import { CertificationUserTestType } from '@pages/types'

import { store } from '@/store'

const QuestionRespondentCertificationPage = () => {
  const { id: idCertification, respondentTestId, questionId } = useParams()

  const { respondentsFiltered } = useOutletContext<{
    respondentsFiltered: CertificationUserTestType[]
  }>()

  const questions = respondentsFiltered
    ?.find((respondent) => respondent.idCertificationUserTest === respondentTestId)
    ?.questions?.find((question) => question.idCertificationUserTestQuestion === questionId)

  const getQuestionRespondentCertificationAction =
    store.use.getQuestionRespondentCertificationAction().call
  const isLoading = store.use.getQuestionRespondentCertificationAction().status === 'LOADING'
  const isSucceeded = store.use.getQuestionRespondentCertificationAction().status === 'SUCCEEDED'

  const { translate } = useTranslation()
  const navigate = useNavigate()

  useDelayedEffect(() => {
    getQuestionRespondentCertificationAction({
      idCertification,
      idCertificationUserTest: respondentTestId,
      userTestQuestionId: questionId,
    })
  }, [idCertification, questionId, respondentTestId])

  return (
    <>
      <LayoutContainer style={{ padding: 18, borderBottom: `1px solid ${colors.gray200}` }}>
        <Row justify={'space-between'} style={{ width: '100%' }}>
          <Space size={10}>
            <Button
              iconLeft={'FlipBackward'}
              onClick={() =>
                navigate(routes.certificationRespondent.navigate(idCertification, respondentTestId))
              }
            />

            <Paragraph size='lg' color={colors.gray900} style={{ marginBottom: 0 }}>
              {`${translate(`certificationPage.questionHeaderTitle`)} ${
                questions?.idCertificationUserTestQuestion
              }`}
            </Paragraph>
            {questions?.mark && <Tag color={colors.success800}>{questions?.mark}%</Tag>}
          </Space>
          <ButtonGroup className='ant-btn-group'>
            {/*TODO: connect to the api (index inside question entity is required)*/}
            <Button iconLeft={'ChevronLeft'} />
            <Button iconLeft={'ChevronRight'} />
          </ButtonGroup>
        </Row>
      </LayoutContainer>
      {isLoading && <Loader />}
      {isSucceeded && <QuestionRespondentCertification />}
    </>
  )
}

export default QuestionRespondentCertificationPage
