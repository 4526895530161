import {
  addManagerToSegment,
  addUsersToSegment,
  createSegment,
  CreateSegmentPayloadType,
  deleteManagerFromSegment,
  deleteUsersFromSegment,
  downloadSegments,
  DownloadSegmentsPayloadType,
  getManagers,
  getSegment,
  GetSegmentPayloadType,
  getSegments,
  GetSegmentsPayloadType,
  getSuppliers,
  removeSegment,
  requestAccessForSegment,
  updateManagersSegment,
  updateSegmentInfo,
  updateSegmentSkill,
  UpdateSegmentSkillPayloadType,
  updateSegmentSocioDemographic,
  UpdateSegmentSocioDemographicPayloadType,
  updateUsersSegment,
} from '@api'
import { downloadBlob, shortTime } from '@utils'
import {
  ContributorShortType,
  ContributorType,
  ManagerShortType,
  ManagerType,
  SegmentType,
} from '@pages/types'
import { IconData } from 'common-components'

import { SetState } from '../store.type'

import { SegmentSliceType } from './segment.slice'

import {
  segmentApiMapper,
  certificationShortApiMapper,
  userDataApiMapper,
  segmentShortApiMapper,
} from '@/mappers'
import { getApiError, getSuccess } from '@/store/helper'
import { StoreGlobalType } from '@/store/store'

const synchronizeContributorsSegment = async (idSegment: string): Promise<ContributorType[]> => {
  const contributorsApi = await getSuppliers({ model: { segments: [idSegment] } })
  return contributorsApi.map((contributorApi): ContributorType => {
    const userData = userDataApiMapper(contributorApi)
    const segmentsShort = contributorApi?.segments?.map(segmentShortApiMapper)
    return {
      userData,
      segmentsShort,
    }
  })
}

const synchronizeManagersSegment = async (idSegment: string): Promise<ManagerType[]> => {
  const managersApi = await getManagers({ model: { segments: [idSegment] } })
  return managersApi.map((userApi): ManagerType => {
    const userData = userDataApiMapper(userApi)
    const segmentsShort = userApi?.segmentsManager?.map(segmentShortApiMapper)
    const certificationsShort = userApi.certificationsManager?.map(certificationShortApiMapper)
    return {
      userData,
      segmentsShort,
      certificationsShort,
    }
  })
}

/******************************************************************
 * REQUEST ACCESS SEGMENT
 *****************************************************************/

type RequestAccessSegmentActionStatusType =
  | 'requestAccessSegment/loading'
  | 'requestAccessSegment/succeeded'
  | 'requestAccessSegment/failed'

export type RequestAccessSegmentActionPayloadType = {
  idSegment: string
}

export const requestAccessSegmentAction =
  (
    set: SetState<
      Partial<SegmentSliceType & StoreGlobalType>,
      RequestAccessSegmentActionStatusType,
      RequestAccessSegmentActionPayloadType
    >,
  ) =>
  async (payload: RequestAccessSegmentActionPayloadType) => {
    try {
      set(
        (state) => {
          state.requestAccessSegmentAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'requestAccessSegment/loading', payload },
      )

      await requestAccessForSegment(payload)

      set(
        (state) => {
          state.requestAccessSegmentAction.status = 'SUCCEEDED'
          state.success = getSuccess({
            messageT: 'certificationsPage.notifications.requestAccessSuccess',
          })
        },
        false,
        { type: 'requestAccessSegment/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.requestAccessSegmentAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'requestAccessSegment/failed' },
      )
    }
  }

/******************************************************************
 * GET SEGMENTS
 *****************************************************************/

type GetSegmentsActionStatusType =
  | 'getSegments/loading'
  | 'getSegments/succeeded'
  | 'getSegments/failed'

export type GetSegmentsActionPayloadType = GetSegmentsPayloadType

export const getSegmentsAction =
  (
    set: SetState<
      Partial<SegmentSliceType & StoreGlobalType>,
      GetSegmentsActionStatusType,
      GetSegmentsActionPayloadType
    >,
    get: () => StoreGlobalType & SegmentSliceType,
  ) =>
  async (payload: GetSegmentsActionPayloadType) => {
    try {
      set(
        (state) => {
          state.getSegmentsAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'getSegments/loading', payload },
      )

      const idMe = get().me.userData.idUser
      const segmentsApi = await getSegments(payload)
      const segments = segmentsApi.map((segmentApi) => segmentApiMapper(segmentApi, idMe))

      set(
        (state) => {
          state.getSegmentsAction.status = 'SUCCEEDED'
          state.segments = segments
        },
        false,
        { type: 'getSegments/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.getSegmentsAction.status = 'FAILED'
          state.segments = []
          state.error = getApiError(e)
        },
        false,
        { type: 'getSegments/failed' },
      )
    }
  }

/******************************************************************
 * GET SEGMENT
 *****************************************************************/

type GetSegmentActionStatusType =
  | 'getSegment/loading'
  | 'getSegment/succeeded'
  | 'getSegment/failed'

export type GetSegmentActionPayloadType = GetSegmentPayloadType

export const getSegmentAction =
  (
    set: SetState<
      Partial<SegmentSliceType & StoreGlobalType>,
      GetSegmentActionStatusType,
      GetSegmentActionPayloadType
    >,
    get: () => StoreGlobalType & SegmentSliceType,
  ) =>
  async (payload: GetSegmentActionPayloadType) => {
    try {
      set(
        (state) => {
          state.getSegmentAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'getSegment/loading', payload },
      )

      const idMe = get().me.userData.idUser
      const segmentApi = await getSegment(payload)
      const segment = segmentApiMapper(segmentApi, idMe)
      const contributorsSegment = await synchronizeContributorsSegment(payload.idSegment)

      set(
        (state) => {
          state.getSegmentAction.status = 'SUCCEEDED'
          const index = state.segments?.findIndex(
            ({ idSegment }) => idSegment === payload.idSegment,
          )
          if (index >= 0) {
            state.segments[index] = segment
          } else {
            state.segments.unshift(segment)
          }
          state.contributorsSegment = contributorsSegment
        },
        false,
        { type: 'getSegment/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.getSegmentAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'getSegment/failed' },
      )
    }
  }

/******************************************************************
 * CREATE SEGMENT
 *****************************************************************/

type CreateSegmentActionStatusType =
  | 'createSegment/loading'
  | 'createSegment/succeeded'
  | 'createSegment/failed'

export type CreateSegmentActionPayloadType = CreateSegmentPayloadType

export const createSegmentAction =
  (
    set: SetState<
      Partial<SegmentSliceType & StoreGlobalType>,
      CreateSegmentActionStatusType,
      CreateSegmentActionPayloadType
    >,
    get: () => StoreGlobalType & SegmentSliceType,
  ) =>
  async (payload: CreateSegmentActionPayloadType, callback: (idSegment: string) => void) => {
    try {
      set(
        (state) => {
          state.createSegmentAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'createSegment/loading', payload },
      )

      const idMe = get().me.userData.idUser
      const segmentApi = await createSegment(payload)
      const segment = segmentApiMapper(segmentApi, idMe)

      set(
        (state) => {
          state.createSegmentAction.status = 'SUCCEEDED'
          state.segments.unshift(segment)
        },
        false,
        { type: 'createSegment/succeeded' },
      )

      callback(segment.idSegment)
    } catch (e) {
      set(
        (state) => {
          state.createSegmentAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'createSegment/failed' },
      )
    }
  }

/******************************************************************
 * DOWNLOAD SEGMENTS
 *****************************************************************/

type DownloadSegmentsActionStatusType =
  | 'downloadSegments/loading'
  | 'downloadSegments/succeeded'
  | 'downloadSegments/failed'

export type DownloadSegmentsActionPayloadType = DownloadSegmentsPayloadType

export const downloadSegmentsAction =
  (
    set: SetState<
      Partial<SegmentSliceType & StoreGlobalType>,
      DownloadSegmentsActionStatusType,
      DownloadSegmentsActionPayloadType
    >,
  ) =>
  async (payload: DownloadSegmentsActionPayloadType) => {
    try {
      set(
        (state) => {
          state.downloadSegmentsAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'downloadSegments/loading', payload },
      )

      const data = await downloadSegments(payload)
      downloadBlob([data], `Segments_${shortTime()}.xlsx`)

      set(
        (state) => {
          state.downloadSegmentsAction.status = 'SUCCEEDED'
        },
        false,
        { type: 'downloadSegments/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.downloadSegmentsAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'downloadSegments/failed' },
      )
    }
  }

/******************************************************************
 * DELETE SEGMENTS
 *****************************************************************/

type DeleteSegmentsActionStatusType =
  | 'deleteSegments/loading'
  | 'deleteSegments/succeeded'
  | 'deleteSegments/failed'

export type DeleteSegmentsActionPayloadType = {
  idSegments: string[]
}

export const deleteSegmentsAction =
  (
    set: SetState<
      Partial<SegmentSliceType & StoreGlobalType>,
      DeleteSegmentsActionStatusType,
      DeleteSegmentsActionPayloadType
    >,
  ) =>
  async (payload: DeleteSegmentsActionPayloadType, callback?: () => void) => {
    try {
      set(
        (state) => {
          state.deleteSegmentsAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'deleteSegments/loading', payload },
      )

      for (const id of payload.idSegments) {
        await removeSegment(id)
      }

      set(
        (state) => {
          state.deleteSegmentsAction.status = 'SUCCEEDED'
          state.segments = state.segments.filter(
            ({ idSegment }) => !payload.idSegments.includes(idSegment),
          )
        },
        false,
        { type: 'deleteSegments/succeeded' },
      )

      callback && callback()
    } catch (e) {
      set(
        (state) => {
          state.deleteSegmentsAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'deleteSegments/failed' },
      )
    }
  }

/******************************************************************
 * UPDATE SEGMENT
 *****************************************************************/

type UpdateSegmentActionStatusType =
  | 'updateSegment/loading'
  | 'updateSegment/succeeded'
  | 'updateSegment/failed'

export type UpdateSegmentActionPayloadType = {
  idSegment: string
  icon: IconData
  name: string
}

export const updateSegmentAction =
  (
    set: SetState<
      Partial<SegmentSliceType & StoreGlobalType>,
      UpdateSegmentActionStatusType,
      UpdateSegmentActionPayloadType
    >,
  ) =>
  async (payload: UpdateSegmentActionPayloadType, callback?: () => void) => {
    try {
      set(
        (state) => {
          state.updateSegmentAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'updateSegment/loading', payload },
      )

      await updateSegmentInfo({
        id: payload.idSegment,
        body: { icon: payload.icon, name: payload.name },
      })

      set(
        (state) => {
          state.updateSegmentAction.status = 'SUCCEEDED'
          const index = state.segments?.findIndex((item) => item.idSegment === payload.idSegment)
          if (index >= 0) {
            state.segments[index].name = payload.name
            state.segments[index].icon = payload.icon
          }
        },
        false,
        { type: 'updateSegment/succeeded' },
      )

      callback && callback()
    } catch (e) {
      set(
        (state) => {
          state.updateSegmentAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'updateSegment/failed' },
      )
    }
  }

/******************************************************************
 * UPDATE SOCIO DEMOGRAPHIC SEGMENT
 *****************************************************************/

type UpdateSocioDemographicSegmentActionStatusType =
  | 'updateSocioDemographicSegment/loading'
  | 'updateSocioDemographicSegment/succeeded'
  | 'updateSocioDemographicSegment/failed'

export type UpdateSocioDemographicSegmentActionPayloadType =
  UpdateSegmentSocioDemographicPayloadType

export const updateSocioDemographicSegmentAction =
  (
    set: SetState<
      Partial<SegmentSliceType & StoreGlobalType>,
      UpdateSocioDemographicSegmentActionStatusType,
      UpdateSocioDemographicSegmentActionPayloadType
    >,
  ) =>
  async (payload: UpdateSocioDemographicSegmentActionPayloadType) => {
    try {
      set(
        (state) => {
          state.updateSocioDemographicSegmentAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'updateSocioDemographicSegment/loading', payload },
      )

      await updateSegmentSocioDemographic(payload)

      set(
        (state) => {
          state.updateSocioDemographicSegmentAction.status = 'SUCCEEDED'
          const index = state.segments?.findIndex((item) => item.idSegment === payload.idSegment)
          if (index >= 0) {
            state.segments[index].socioDemographicCriteria = payload.socioDemographicCriteria
            state.segments[index].socioDemographicMinValue = payload.socioDemographicMinValue
            state.segments[index].socioDemographicMaxValue = payload.socioDemographicMaxValue
            state.segments[index].socioDemographicCriteriaValues =
              payload.socioDemographicCriteriaValues
          }
        },
        false,
        { type: 'updateSocioDemographicSegment/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.updateSocioDemographicSegmentAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'updateSocioDemographicSegment/failed' },
      )
    }
  }

/******************************************************************
 * ADD MANAGER TO SEGMENTS
 *****************************************************************/

type AddManagerToSegmentsActionStatusType =
  | 'addManagerToSegments/loading'
  | 'addManagerToSegments/succeeded'
  | 'addManagerToSegments/failed'

export type AddManagerToSegmentsActionPayloadType = {
  idSegments: string[]
  managerShort: ManagerShortType
}
export const addManagerToSegmentsAction =
  (
    set: SetState<
      Partial<SegmentSliceType & StoreGlobalType>,
      AddManagerToSegmentsActionStatusType,
      AddManagerToSegmentsActionPayloadType
    >,
  ) =>
  async (payload: AddManagerToSegmentsActionPayloadType) => {
    try {
      set(
        (state) => {
          state.addManagerToSegmentsAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'addManagerToSegments/loading', payload },
      )

      const newManagerShort: ManagerShortType = {
        idUser: payload.managerShort.idUser,
        email: payload.managerShort.email,
        firstName: payload.managerShort.firstName,
        lastName: payload.managerShort.lastName,
        type: payload.managerShort.type,
        urlAvatar: payload.managerShort.urlAvatar,
        managerRight: payload.managerShort.managerRight,
      }

      for (const idSegment of payload.idSegments) {
        await addManagerToSegment({
          id: idSegment,
          managers: [newManagerShort],
        })
      }

      set(
        (state) => {
          const segments = state.segments.map((segment): SegmentType => {
            if (payload.idSegments.includes(segment.idSegment)) {
              let managersShort = segment?.managersShort

              const indexManagerShort = segment?.managersShort?.findIndex(
                ({ idUser }) => idUser === payload.managerShort.idUser,
              )

              if (indexManagerShort === -1) {
                managersShort = segment?.managersShort?.concat(newManagerShort)
              } else {
                managersShort[indexManagerShort] = newManagerShort
              }

              return {
                ...segment,
                managersShort,
              }
            }
            return segment
          })

          state.addManagerToSegmentsAction.status = 'SUCCEEDED'
          state.segments = segments
        },
        false,
        { type: 'addManagerToSegments/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.addManagerToSegmentsAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'addManagerToSegments/failed' },
      )
    }
  }

/******************************************************************
 * DELETE MANAGER FROM SEGMENTS
 *****************************************************************/

type DeleteManagerFromSegmentsActionStatusType =
  | 'deleteManagerFromSegments/loading'
  | 'deleteManagerFromSegments/succeeded'
  | 'deleteManagerFromSegments/failed'

export type DeleteManagerFromSegmentsActionPayloadType = {
  idSegments: string[]
  managerShort: ManagerShortType
}
export const deleteManagerFromSegmentsAction =
  (
    set: SetState<
      Partial<SegmentSliceType & StoreGlobalType>,
      DeleteManagerFromSegmentsActionStatusType,
      DeleteManagerFromSegmentsActionPayloadType
    >,
  ) =>
  async (payload: DeleteManagerFromSegmentsActionPayloadType) => {
    try {
      set(
        (state) => {
          state.deleteManagerFromSegmentsAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'deleteManagerFromSegments/loading', payload },
      )

      const newManagerShort: ManagerShortType = {
        idUser: payload.managerShort.idUser,
        email: payload.managerShort.email,
        firstName: payload.managerShort.firstName,
        lastName: payload.managerShort.lastName,
        type: payload.managerShort.type,
        urlAvatar: payload.managerShort.urlAvatar,
        managerRight: payload.managerShort.managerRight,
      }

      for (const idSegment of payload.idSegments) {
        await deleteManagerFromSegment({
          id: idSegment,
          managers: [newManagerShort],
        })
      }

      set(
        (state) => {
          const segments = state.segments.map((segment): SegmentType => {
            if (payload.idSegments.includes(segment.idSegment)) {
              const managersShort = segment?.managersShort?.filter(
                ({ idUser }) => idUser !== payload.managerShort.idUser,
              )
              return {
                ...segment,
                managersShort,
              }
            }
            return segment
          })

          state.deleteManagerFromSegmentsAction.status = 'SUCCEEDED'
          state.segments = segments
        },
        false,
        { type: 'deleteManagerFromSegments/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.deleteManagerFromSegmentsAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'deleteManagerFromSegments/failed' },
      )
    }
  }

/******************************************************************
 * MODIFY CONTRIBUTORS FROM SEGMENT
 *****************************************************************/

type ModifyContributorsFromSegmentActionStatusType =
  | 'modifyContributorsFromSegment/loading'
  | 'modifyContributorsFromSegment/succeeded'
  | 'modifyContributorsFromSegment/failed'

export type ModifyContributorsFromSegmentActionPayloadType = {
  idSegment: string
  contributorsShort: ContributorShortType[]
}
export const modifyContributorsFromSegmentAction =
  (
    set: SetState<
      Partial<SegmentSliceType & StoreGlobalType>,
      ModifyContributorsFromSegmentActionStatusType,
      ModifyContributorsFromSegmentActionPayloadType
    >,
  ) =>
  async (payload: ModifyContributorsFromSegmentActionPayloadType) => {
    try {
      set(
        (state) => {
          state.modifyContributorsFromSegmentAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'modifyContributorsFromSegment/loading', payload },
      )

      await updateUsersSegment({
        id: payload.idSegment,
        users: payload.contributorsShort.map((contributor) => ({
          idUser: contributor.idUser,
          email: contributor.email,
          firstName: contributor?.firstName,
          lastName: contributor?.lastName,
          type: contributor?.type,
          urlAvatar: contributor?.urlAvatar,
        })),
      })

      set(
        (state) => {
          const contributorsShort = payload.contributorsShort.map(
            (contributor): ContributorShortType => ({
              idUser: contributor.idUser,
              email: contributor.email,
              firstName: contributor?.firstName,
              lastName: contributor?.lastName,
              type: contributor?.type,
              urlAvatar: contributor?.urlAvatar,
              mark: contributor?.mark,
              phone: contributor?.phone,
            }),
          )
          const indexSegment = state.segments.findIndex(
            ({ idSegment }) => idSegment === payload.idSegment,
          )

          state.modifyContributorsFromSegmentAction.status = 'SUCCEEDED'
          state.segments[indexSegment].contributorsShort = contributorsShort
        },
        false,
        { type: 'modifyContributorsFromSegment/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.modifyContributorsFromSegmentAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'modifyContributorsFromSegment/failed' },
      )
    }
  }

/******************************************************************
 * MODIFY MANAGERS FROM SEGMENT
 *****************************************************************/

type ModifyManagersFromSegmentActionStatusType =
  | 'modifyManagersFromSegment/loading'
  | 'modifyManagersFromSegment/succeeded'
  | 'modifyManagersFromSegment/failed'

export type ModifyManagersFromSegmentActionPayloadType = {
  idSegment: string
  managersShort: ManagerShortType[]
}

export const modifyManagersFromSegmentAction =
  (
    set: SetState<
      Partial<SegmentSliceType & StoreGlobalType>,
      ModifyManagersFromSegmentActionStatusType,
      ModifyManagersFromSegmentActionPayloadType
    >,
  ) =>
  async (payload: ModifyManagersFromSegmentActionPayloadType) => {
    try {
      set(
        (state) => {
          state.modifyManagersFromSegmentAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'modifyManagersFromSegment/loading', payload },
      )

      await updateManagersSegment({
        idSegment: payload.idSegment,
        managersShort: payload.managersShort.map((manager) => ({
          idUser: manager.idUser,
          email: manager.email,
          firstName: manager.firstName,
          lastName: manager.lastName,
          type: manager.type,
          urlAvatar: manager.urlAvatar,
          managerRight: manager.managerRight,
        })),
      })

      set(
        (state) => {
          const managersShort = payload.managersShort.map(
            (manager): ManagerShortType => ({
              idUser: manager.idUser,
              email: manager.email,
              firstName: manager?.firstName,
              lastName: manager?.lastName,
              type: manager?.type,
              urlAvatar: manager?.urlAvatar,
              phone: manager?.phone,
              managerRight: manager.managerRight,
            }),
          )
          const indexSegment = state.segments.findIndex(
            ({ idSegment }) => idSegment === payload.idSegment,
          )

          state.modifyManagersFromSegmentAction.status = 'SUCCEEDED'
          state.segments[indexSegment].managersShort = managersShort
        },
        false,
        { type: 'modifyManagersFromSegment/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.modifyManagersFromSegmentAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'modifyManagersFromSegment/failed' },
      )
    }
  }

/******************************************************************
 * GET MANAGERS SEGMENT
 *****************************************************************/

type GetManagersSegmentActionStatusType =
  | 'getManagersSegment/loading'
  | 'getManagersSegment/succeeded'
  | 'getManagersSegment/failed'

export type GetManagersSegmentActionPayloadType = {
  idSegment: string
}

export const getManagersSegmentAction =
  (
    set: SetState<
      Partial<SegmentSliceType & StoreGlobalType>,
      GetManagersSegmentActionStatusType,
      GetManagersSegmentActionPayloadType
    >,
  ) =>
  async (payload: GetManagersSegmentActionPayloadType) => {
    try {
      set(
        (state) => {
          state.getManagersSegmentAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'getManagersSegment/loading', payload },
      )

      const managers = await synchronizeManagersSegment(payload.idSegment)

      set(
        (state) => {
          state.getManagersSegmentAction.status = 'SUCCEEDED'
          state.managersSegment = managers
        },
        false,
        { type: 'getManagersSegment/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.getManagersSegmentAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'getManagersSegment/failed' },
      )
    }
  }

/******************************************************************
 * ADD CONTRIBUTOR TO SEGMENT
 *****************************************************************/

type AddContributorToSegmentActionStatusType =
  | 'addContributorToSegment/loading'
  | 'addContributorToSegment/succeeded'
  | 'addContributorToSegment/failed'

export type AddContributorToSegmentActionPayloadType = {
  idSegment: string
  contributorShort: ContributorShortType
}

export const addContributorToSegmentAction =
  (
    set: SetState<
      Partial<SegmentSliceType & StoreGlobalType>,
      AddContributorToSegmentActionStatusType,
      AddContributorToSegmentActionPayloadType
    >,
  ) =>
  async (payload: AddContributorToSegmentActionPayloadType) => {
    try {
      set(
        (state) => {
          state.addContributorToSegmentAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'addContributorToSegment/loading', payload },
      )

      await addUsersToSegment({
        id: payload.idSegment,
        users: [
          {
            idUser: payload.contributorShort.idUser,
            email: payload.contributorShort.email,
            firstName: payload.contributorShort.firstName,
            lastName: payload.contributorShort.lastName,
            type: payload.contributorShort.type,
            urlAvatar: payload.contributorShort.urlAvatar,
          },
        ],
      })

      const contributors = await synchronizeContributorsSegment(payload.idSegment)
      set(
        (state) => {
          state.addContributorToSegmentAction.status = 'SUCCEEDED'
          const index = state.segments?.findIndex(
            ({ idSegment }) => idSegment === payload.idSegment,
          )
          if (index >= 0) {
            state.segments[index].contributorsShort.unshift(payload.contributorShort)
            state.contributorsSegment = contributors
          }
        },
        false,
        { type: 'addContributorToSegment/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.addContributorToSegmentAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'addContributorToSegment/failed' },
      )
    }
  }

/******************************************************************
 * DELETE CONTRIBUTORS FROM SEGMENT
 *****************************************************************/

type DeleteContributorsFromSegmentActionStatusType =
  | 'deleteContributorsFromSegment/loading'
  | 'deleteContributorsFromSegment/succeeded'
  | 'deleteContributorsFromSegment/failed'

export type DeleteContributorsFromSegmentActionPayloadType = {
  idSegment: string
  idContributors: string[]
}

export const deleteContributorsFromSegmentAction =
  (
    set: SetState<
      Partial<SegmentSliceType & StoreGlobalType>,
      DeleteContributorsFromSegmentActionStatusType,
      DeleteContributorsFromSegmentActionPayloadType
    >,
    get: () => StoreGlobalType & SegmentSliceType,
  ) =>
  async (payload: DeleteContributorsFromSegmentActionPayloadType, callback?: () => void) => {
    try {
      set(
        (state) => {
          state.deleteContributorsFromSegmentAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'deleteContributorsFromSegment/loading', payload },
      )

      const segment = get().segments.find(({ idSegment }) => idSegment === payload.idSegment)

      for (const idContributor of payload.idContributors) {
        const contributor = get().contributorsSegment.find(
          (item) => idContributor === item.userData.idUser,
        ).userData

        await deleteUsersFromSegment({
          id: payload.idSegment,
          users: [
            {
              idUser: contributor.idUser,
              email: contributor.email,
              firstName: contributor?.firstName,
              lastName: contributor?.lastName,
              type: contributor?.type,
              urlAvatar: contributor?.urlAvatar,
            },
          ],
        })
      }

      set(
        (state) => {
          state.deleteContributorsFromSegmentAction.status = 'SUCCEEDED'

          const index = state.segments?.findIndex(
            ({ idSegment }) => idSegment === payload.idSegment,
          )
          if (index >= 0) {
            state.segments[index].contributorsShort = segment.contributorsShort.filter(
              (contributor) => !payload.idContributors.includes(contributor.idUser),
            )
            state.contributorsSegment = state.contributorsSegment.filter(
              (contributor) => !payload.idContributors.includes(contributor.userData.idUser),
            )
          }
        },
        false,
        { type: 'deleteContributorsFromSegment/succeeded' },
      )

      callback && callback()
    } catch (e) {
      set(
        (state) => {
          state.deleteContributorsFromSegmentAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'deleteContributorsFromSegment/failed' },
      )
    }
  }

/******************************************************************
 * ADD MANAGER TO SEGMENT
 *****************************************************************/

type AddManagerToSegmentActionStatusType =
  | 'addManagerToSegment/loading'
  | 'addManagerToSegment/succeeded'
  | 'addManagerToSegment/failed'

export type AddManagerToSegmentActionPayloadType = {
  idSegment: string
  managerShort: ManagerShortType
}

export const addManagerToSegmentAction =
  (
    set: SetState<
      Partial<SegmentSliceType & StoreGlobalType>,
      AddManagerToSegmentActionStatusType,
      AddManagerToSegmentActionPayloadType
    >,
  ) =>
  async (payload: AddManagerToSegmentActionPayloadType) => {
    try {
      set(
        (state) => {
          state.addManagerToSegmentAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'addManagerToSegment/loading', payload },
      )

      await addManagerToSegment({
        id: payload.idSegment,
        managers: [
          {
            idUser: payload.managerShort.idUser,
            email: payload.managerShort.email,
            managerRight: payload.managerShort.managerRight,
            phone: payload.managerShort.phone,
            firstName: payload.managerShort?.firstName,
            lastName: payload.managerShort?.lastName,
            type: payload.managerShort?.type,
            urlAvatar: payload.managerShort?.urlAvatar,
          },
        ],
      })

      const managers = await synchronizeManagersSegment(payload.idSegment)
      set(
        (state) => {
          state.addManagerToSegmentAction.status = 'SUCCEEDED'

          const index = state.segments?.findIndex(
            ({ idSegment }) => idSegment === payload.idSegment,
          )
          if (index >= 0) {
            state.segments[index].managersShort.unshift(payload.managerShort)
            state.managersSegment = managers
          }
        },
        false,
        { type: 'addManagerToSegment/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.addManagerToSegmentAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'addManagerToSegment/failed' },
      )
    }
  }

/******************************************************************
 * DELETE MANAGERS FROM SEGMENT
 *****************************************************************/

type DeleteManagersFromSegmentActionStatusType =
  | 'deleteManagersFromSegment/loading'
  | 'deleteManagersFromSegment/succeeded'
  | 'deleteManagersFromSegment/failed'

export type DeleteManagersFromSegmentActionPayloadType = {
  idSegment: string
  idManagers: string[]
}

export const deleteManagersFromSegmentAction =
  (
    set: SetState<
      Partial<SegmentSliceType & StoreGlobalType>,
      DeleteManagersFromSegmentActionStatusType,
      DeleteManagersFromSegmentActionPayloadType
    >,
    get: () => StoreGlobalType & SegmentSliceType,
  ) =>
  async (payload: DeleteManagersFromSegmentActionPayloadType, callback?: () => void) => {
    try {
      set(
        (state) => {
          state.deleteManagersFromSegmentAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'deleteManagersFromSegment/loading', payload },
      )

      const segment = get().segments.find(({ idSegment }) => idSegment === payload.idSegment)

      for (const idManager of payload.idManagers) {
        const manager = get().managersSegment.find(
          (item) => idManager === item.userData.idUser,
        ).userData

        await deleteManagerFromSegment({
          id: payload.idSegment,
          managers: [
            {
              idUser: manager.idUser,
              email: manager.email,
              // TODO remove ?
              // managerRight: manager.managerRight,
              phone: manager.phone,
              firstName: manager?.firstName,
              lastName: manager?.lastName,
              type: manager?.type,
              urlAvatar: manager?.urlAvatar,
            },
          ],
        })
      }

      set(
        (state) => {
          state.deleteManagersFromSegmentAction.status = 'SUCCEEDED'

          const index = state.segments?.findIndex(
            ({ idSegment }) => idSegment === payload.idSegment,
          )
          if (index >= 0) {
            state.segments[index].managersShort = segment.managersShort.filter(
              (manager) => !payload.idManagers.includes(manager.idUser),
            )
            state.managersSegment = state.managersSegment.filter(
              (manager) => !payload.idManagers.includes(manager.userData.idUser),
            )
          }
        },
        false,
        { type: 'deleteManagersFromSegment/succeeded' },
      )

      callback && callback()
    } catch (e) {
      set(
        (state) => {
          state.deleteManagersFromSegmentAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'deleteManagersFromSegment/failed' },
      )
    }
  }

/******************************************************************
 * UPDATE SKILL SEGMENT
 *****************************************************************/

type UpdateSkillSegmentActionStatusType =
  | 'updateSkillSegment/loading'
  | 'updateSkillSegment/succeeded'
  | 'updateSkillSegment/failed'

export type UpdateSkillSegmentActionPayloadType = UpdateSegmentSkillPayloadType

export const updateSkillSegmentAction =
  (
    set: SetState<
      Partial<SegmentSliceType & StoreGlobalType>,
      UpdateSkillSegmentActionStatusType,
      UpdateSkillSegmentActionPayloadType
    >,
  ) =>
  async (payload: UpdateSkillSegmentActionPayloadType, callback?: () => void) => {
    try {
      set(
        (state) => {
          state.updateSkillSegmentAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'updateSkillSegment/loading', payload },
      )

      await updateSegmentSkill(payload)

      set(
        (state) => {
          state.updateSkillSegmentAction.status = 'SUCCEEDED'
          const index = state.segments?.findIndex((item) => item.idSegment === payload.idSegment)
          if (index >= 0) {
            state.segments[index].percentageForValidation = payload.percentageForValidation
            state.segments[index].skills = payload.skills
            state.segments[index].qualityMin = payload.qualityMin
          }
        },
        false,
        { type: 'updateSkillSegment/succeeded' },
      )

      callback && callback()
    } catch (e) {
      set(
        (state) => {
          state.updateSkillSegmentAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'updateSkillSegment/failed' },
      )
    }
  }
