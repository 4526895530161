/** @jsxImportSource @emotion/react */

import { useTranslation } from '@hooks'
import { css } from '@emotion/react'
import { colors, Icon, LayoutContainer, Space, Text } from 'common-components'
import { useParams } from 'react-router'
import { HitType, TaskType } from '@pages/types'
import { memo } from 'react'

import { TTasklineHitNavParams } from '../shared'

import { PanelSidebarTask, TaskSidebarTask, HitSidebarTask } from './sidebar'

type SidebarLayoutsTaskProjectPropsType = {
  task: TaskType
  hit: HitType
}

const SidebarLayoutsTaskProjectComponent = ({ task, hit }: SidebarLayoutsTaskProjectPropsType) => {
  const { translate } = useTranslation()
  const { view } = useParams<TTasklineHitNavParams>()

  return (
    <LayoutContainer
      css={css`
        padding: 22px 20px;
        border-left: 1px solid ${colors.gray200};
        height: 100%;
        max-width: 400px;
        width: 100%;
      `}
    >
      <Space size={10} direction={'horizontal'} style={{ marginBottom: 20 }}>
        <Icon color={colors.gray400} icon={'Menu01'} />
        <Text color={colors.gray700}>{translate('project.tasklineOverview.sidebar.title')}</Text>
      </Space>

      {view === 'hit' && hit && <HitSidebarTask hit={hit} />}
      {view === 'task' && task && <TaskSidebarTask task={task} />}
      {view === 'wirk-model' && task && <PanelSidebarTask />}
    </LayoutContainer>
  )
}

const SidebarLayoutsTaskProject = memo(
  SidebarLayoutsTaskProjectComponent,
  (oldProps: SidebarLayoutsTaskProjectPropsType, newProps: SidebarLayoutsTaskProjectPropsType) => {
    return JSON.stringify(oldProps) === JSON.stringify(newProps)
  },
)

export default SidebarLayoutsTaskProject
