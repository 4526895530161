/** @jsxImportSource @emotion/react */
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Button, Drawer, Form, FormItem, Title, Text } from 'common-components'
import { css } from '@emotion/react'
import { useTranslation } from '@hooks'
import { SegmentDefType, UserAccessLevel } from '@definitions'
import { CommonFilters, StateCheckbox } from '@components/inputs'
import { GetSegmentsPayloadType } from '@api/segment'
import { SelectManagers } from '@views/shared/manager'

type FiltersSegmentsPropsType = {
  visible: boolean
  onFiltersChange?: (filters: GetSegmentsPayloadType) => void
  onClose?: () => void
}

const FiltersSegments = ({ visible, onClose, onFiltersChange }: FiltersSegmentsPropsType) => {
  const { translate } = useTranslation()
  const { control, handleSubmit } = useForm<GetSegmentsPayloadType>({
    defaultValues: { model: {} },
  })

  const onSubmit = (data: GetSegmentsPayloadType) => {
    onFiltersChange && onFiltersChange(data)
    onClose && onClose()
  }

  return (
    <Drawer
      title={
        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <Title preset='tablePageTitle'>{translate('common.filter')}</Title>
          <Text>{translate('common.apply')}</Text>
        </div>
      }
      placement='right'
      closable={true}
      onClose={onClose}
      closeIcon={false}
      open={visible}
    >
      <Form
        onReset={() => {
          control._reset()
          onSubmit({ model: {} })
        }}
        onFinish={handleSubmit(onSubmit)}
        css={$form}
      >
        <CommonFilters control={control} />

        <Controller
          control={control}
          render={({ field }) => (
            <FormItem label={translate('common.role')}>
              <StateCheckbox
                inputProps={{
                  onChange: field.onChange,
                  value: field.value as unknown as number[],
                }}
                values={UserAccessLevel}
                labelTxKey={'userAccessLevel'}
                preset={'user-access'}
              />
            </FormItem>
          )}
          name={'model.role'}
        />

        <Controller
          control={control}
          render={({ field }) => (
            <FormItem label={translate('common.state')}>
              <StateCheckbox
                inputProps={{
                  onChange: field.onChange,
                  value: field.value,
                }}
                values={SegmentDefType}
                labelTxKey={'segmentState'}
                preset={'segment-type'}
              />
            </FormItem>
          )}
          name={'model.segmentType'}
        />
        <Controller
          control={control}
          render={({ field }) => (
            <FormItem label={translate('common.managers')}>
              <SelectManagers onChange={field.onChange} value={field.value} />
            </FormItem>
          )}
          name={'model.managers'}
        />

        <div css={filterButtonsStyle}>
          <Button type='primary' htmlType='submit'>
            Apply
          </Button>
          <Button htmlType='reset'>Cancel</Button>
        </div>
      </Form>
    </Drawer>
  )
}

export default FiltersSegments

const filterButtonsStyle = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`

const $form = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`
