import {
  RiskApiType,
  RiskSettingsApiType,
  RiskItemApiType,
  RiskItemColorItemApiType,
  RiskPageURLItemApiType,
} from '@api/types'
import {
  RiskType,
  RiskSettingsType,
  RiskItemType,
  RiskItemColorItemType,
  RiskPageURLItemType,
} from '@pages/types'

export const riskMapperApi = (riskApi: RiskApiType): RiskType => {
  try {
    return {
      idRisk: riskApi.idRisk,
      state: riskApi.state,
      level: riskApi.level,
      score: riskApi.score,
      fileName: riskApi.fileName,
      risks: riskApi.risks?.map(riskItemMapperApi) ?? [],
      metadata: riskApi.metadata,
      riskSettingsUsed: riskApi.riskSettingsUsed,
    }
  } catch (e) {
    throw e
  }
}

export const riskItemMapperApi = (riskItemApi: RiskItemApiType): RiskItemType => {
  try {
    return {
      type: riskItemApi.type,
      level: riskItemApi.level,
      score: riskItemApi.score,
      colors: riskItemApi.colors.map((colorApi) => riskItemColorItemMapperApi(colorApi)),
    }
  } catch (e) {
    throw e
  }
}

export const riskItemColorItemMapperApi = (
  riskItemColorItemApi: RiskItemColorItemApiType,
): RiskItemColorItemType => {
  try {
    return {
      key: riskItemColorItemApi.key,
      value: riskItemColorItemApi.value,
      color: riskItemColorItemApi.color,
      visualisations: riskItemColorItemApi.visualisations.map((recordApi) => ({
        riskVisualisationId: recordApi.riskVisualisationId,
        riskId: recordApi.riskId,
        x1: recordApi.x1,
        y1: recordApi.y1,
        height: recordApi.height,
        width: recordApi.width,
        pageNumber: recordApi.pageNumber,
        color: recordApi.color,
        riskKey: recordApi.riskKey,
      })),
    }
  } catch (e) {
    throw e
  }
}

export const riskSettingsMapperApi = (riskSettingsApi: RiskSettingsApiType): RiskSettingsType => {
  try {
    return {
      userId: riskSettingsApi.userId,
      seuilChangementAlignement: riskSettingsApi.seuilChangementAlignement,
      seuilDifferenceEspace: riskSettingsApi.seuilDifferenceEspace,
      seuilDifferenceCouleur: riskSettingsApi.seuilDifferenceCouleur,
      metadataKeywords: riskSettingsApi.metadataKeywords,
      metadataKeywordsBlacklist: {
        value: riskSettingsApi.metadataKeywordsBlacklist.value,
      },
      numberOfDays: riskSettingsApi.numberOfDays,
      minimumLayer: riskSettingsApi.minimumLayer,
      size: riskSettingsApi.size,
      font: riskSettingsApi.font,
    }
  } catch (e) {
    throw e
  }
}

export const updateRiskSettingsPayloadMapper = (
  riskSettingsPayload: RiskSettingsType,
): RiskSettingsApiType => {
  try {
    return {
      userId: riskSettingsPayload.userId,
      seuilChangementAlignement: riskSettingsPayload.seuilChangementAlignement,
      seuilDifferenceEspace: riskSettingsPayload.seuilDifferenceEspace,
      seuilDifferenceCouleur: riskSettingsPayload.seuilDifferenceCouleur,
      metadataKeywords: riskSettingsPayload.metadataKeywords,
      metadataKeywordsBlacklist: {
        isActive: true,
        value: riskSettingsPayload.metadataKeywordsBlacklist.value,
      },
      numberOfDays: riskSettingsPayload.numberOfDays,
      minimumLayer: riskSettingsPayload.minimumLayer,
      size: riskSettingsPayload.size,
      font: riskSettingsPayload.font,
    }
  } catch (e) {
    throw e
  }
}

export const riskPageURLItemMapperApi = (
  riskPageURLItemApi: RiskPageURLItemApiType,
): RiskPageURLItemType => {
  try {
    return {
      pageNumber: riskPageURLItemApi.pageNumber,
      imageUrl: riskPageURLItemApi.imageUrl,
    }
  } catch (e) {
    throw e
  }
}
