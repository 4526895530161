import { FilterPayloadType } from '@types'
import { axiosClient, TModelCategory, TModelState } from '@definitions'
import { AxiosResponse } from 'axios'
import { catchApiError } from '@utils'
import { DocumentApiType, DocumentLogApiType, IModel, IModelAnnotation } from '@api/types'

export type GetModelsPayloadType = FilterPayloadType & {
  model: {
    idUser?: string[]
    state?: TModelState[]
    category?: TModelCategory[]
    allUsers?: boolean
  }
}

export const getModels = async (filters: GetModelsPayloadType): Promise<IModel[]> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<IModel[]> = await axios.post('/ModelService/Model/GetModels', filters)
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export const getModelDrafts = async (): Promise<IModel[]> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<IModel[]> = await axios.get(`/ModelService/Model/GetLastDraftModel`)
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export const copyModel = async (modelId: string): Promise<string> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<string> = await axios.post('/ModelService/Model/GetModels', {
      idModel: modelId,
    })
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export const deleteModel = async (modelId: string): Promise<void> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<void> = await axios.delete(
      `/ModelService/Model/${modelId}/deleteModel`,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

// DOCUMENT

export const getDocument = async (idDocument: string): Promise<DocumentApiType> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<DocumentApiType> = await axios.get(
      `/ModelService/Document/GetDocument/${idDocument}`,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export const getDocumentLogs = async (idDocument: string): Promise<DocumentLogApiType[]> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<DocumentLogApiType[]> = await axios.get(
      `/ModelService/Document/${idDocument}/GetDocumentLogs`,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export const getModelAnnotation = async (params: {
  idModel: string
}): Promise<IModelAnnotation[]> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<IModelAnnotation[]> = await axios.get(
      `/ModelService/Annotation/GetModel/${params.idModel}`,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export const duplicateModel = async (model: IModel): Promise<IModel> => {
  try {
    const axios = axiosClient()
    const resp = await axios.post<IModel>('/ModelService/Model/DuplicateModel', model)
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}
