/** @jsxImportSource @emotion/react */
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Button, Checkbox, Drawer, Form, FormItem, Space, Text, Title } from 'common-components'
import { css } from '@emotion/react'
import { useTranslation } from '@hooks'
import { QuestionState } from '@definitions'
import { CommonFilters, StateCheckbox } from '@components/inputs'
import { GetCertificationQuestionsPayloadType } from '@api'

type FiltersQuestionsCertificationPropsType = {
  visible: boolean
  idCertification: string
  onFiltersChange: (filters: GetCertificationQuestionsPayloadType) => void
  onClose?: () => void
}

const FiltersQuestionsCertification = ({
  visible,
  onClose,
  onFiltersChange,
  idCertification,
}: FiltersQuestionsCertificationPropsType) => {
  const { translate } = useTranslation()
  const { control, handleSubmit, reset } = useForm<GetCertificationQuestionsPayloadType>({
    defaultValues: {
      model: {
        idCertification,
      },
    },
  })

  const onSubmit = (data: GetCertificationQuestionsPayloadType) => {
    onFiltersChange(data)
    onClose && onClose()
  }

  return (
    <Drawer
      title={
        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <Title preset='tablePageTitle'>{translate('common.filter')}</Title>
          <Text>{translate('common.apply')}</Text>
        </div>
      }
      placement='right'
      closable={true}
      onClose={onClose}
      closeIcon={false}
      open={visible}
    >
      <Form onFinish={handleSubmit(onSubmit)} css={$form}>
        <CommonFilters control={control} />

        <Controller
          control={control}
          render={({ field }) => (
            <FormItem label={translate('common.state')}>
              <StateCheckbox
                inputProps={{
                  onChange: field.onChange,
                  value: field.value,
                }}
                values={QuestionState}
                labelTxKey={'questionState'}
                preset={'question-state'}
              />
            </FormItem>
          )}
          name={'model.state'}
        />

        <Controller
          name={'model.training'}
          control={control}
          render={({ field }) => (
            <FormItem>
              <Space>
                <Checkbox
                  onChange={(ev) =>
                    field.onChange({
                      ...ev,
                      target: { ...ev.target, value: ev.target.checked },
                    })
                  }
                  checked={field.value}
                />
                <Text>{translate('certificationPage.questionsTable.training')}</Text>
              </Space>
            </FormItem>
          )}
        />

        <Space css={[$fullWidth, $footerButtonsContainer]} size={10}>
          <Button onClick={() => reset()}>Cancel</Button>
          <Button type='primary' htmlType='submit'>
            Apply
          </Button>
        </Space>
      </Form>
    </Drawer>
  )
}

const $form = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const $fullWidth = css`
  width: 100%;
`

const $footerButtonsContainer = css`
  justify-content: center;
`

export default FiltersQuestionsCertification
