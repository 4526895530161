import React from 'react'
import { TAvailableRoutesTitles, INavigationEntry } from '@types'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router'
import { Col, colors, IconButton, Text } from 'common-components'

export const NavEntriesList: React.FC<{
  heading: string
  entries: Array<INavigationEntry>
  selectedRoute: TAvailableRoutesTitles
  iconColor: string
}> = ({ heading, entries, selectedRoute, iconColor }) => {
  const NavEntriesWrapper = styled.div`
    display: grid;
    grid-auto-rows: minmax(70px, auto);
    gap: 1em;
  `
  const NavEntry = styled(Col)<{ title: string; selectedRoute: string }>`
    display: flex;
    align-items: center;
    gap: 1em;
    background: ${(props) => (props.selectedRoute === props.title ? '#F9FAFB' : 'transparent')};
    border-radius: 8px;
    padding: 8px;
    border: 1px solid transparent;
    @media (max-width: 600px) {
      gap: 0.4em;
    }
    &:hover {
      background: ${colors.gray50};
      cursor: ${(props) => (props.selectedRoute === props.title ? 'not-allowed' : 'pointer')};
    }
  `
  const TypographyWrap = styled(Col)`
    display: flex;
    flex-direction: column;
    gap: 0.4em;
  `
  const navigate = useNavigate()

  return (
    <Col>
      <Text
        strong
        style={{
          textTransform: 'uppercase',
          color: colors.gray400,
        }}
      >
        {heading}
      </Text>
      <NavEntriesWrapper>
        {entries.map((entry) => (
          <NavEntry
            title={entry.title}
            selectedRoute={selectedRoute}
            key={entry.title}
            onClick={() => (entry.onClick ? entry.onClick() : !!entry.to && navigate(entry.to))}
          >
            <IconButton
              size='sm'
              icon={entry.icon}
              iconProps={{ color: iconColor, width: 24, height: 24 }}
              style={{ borderColor: colors.gray100, padding: 8 }}
            />
            <TypographyWrap>
              <Text size='sm' strong>
                {entry.title}
              </Text>
              <Text>{entry.subtitle}</Text>
            </TypographyWrap>
          </NavEntry>
        ))}
      </NavEntriesWrapper>
    </Col>
  )
}
