import { Paragraph, colors, ActionModal } from 'common-components'
import { useTranslation } from '@hooks'
import { LoadingOutlined } from '@ant-design/icons'
import { memo } from 'react'

type DeleteModalPropsType = {
  isOpen: boolean
  isLoading?: boolean
  close: () => void
  title: string
  label: string
  onDeleteMutation: () => void
}

const DeleteModalComponent = ({
  isOpen,
  isLoading,
  close,
  title,
  label,
  onDeleteMutation,
}: DeleteModalPropsType) => {
  const { translate } = useTranslation()
  return (
    <>
      <ActionModal
        iconColor={colors.error600}
        iconBgColor={colors.error100}
        iconBorderColor={colors.error50}
        maskClosable={false}
        open={isOpen}
        onOk={close}
        onConfirm={close}
        title={title}
        onCancel={close}
        actionButtons={[
          {
            text: translate(`model.deleteModal.labelCancelButton`),
            onClick: () => {
              close()
            },
          },
          {
            text: isLoading ? (
              <LoadingOutlined />
            ) : (
              translate(`model.deleteModal.labelConfirmButton`)
            ),
            type: isLoading ? 'primary' : 'dangerFilled',
            disabled: isLoading,
            onClick: async () => {
              await onDeleteMutation()
            },
          },
        ]}
      >
        <Paragraph size='md'>{label}</Paragraph>
      </ActionModal>
    </>
  )
}

const DeleteModal = memo(
  DeleteModalComponent,
  (oldProps: DeleteModalPropsType, newProps: DeleteModalPropsType) => {
    return JSON.stringify(oldProps) === JSON.stringify(newProps)
  },
)

export default DeleteModal
