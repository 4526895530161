import { useEffect } from 'react'
import { GetManagersPayloadType } from '@api/user'
import { useDelayedEffect, useRequestParams } from '@hooks'
import { ManagerType } from '@pages/types'
import { ListManager } from '@views/manager'

import { store } from '@/store'

export const ManagersPage = () => {
  const managers = store.use.managers()
  const isLoading = store.use.getManagersAction().status === 'LOADING'
  const getManagersAction = store.use.getManagersAction().call

  const { afterPageChange, setParams, reqParams, pagination, onTableStateChange } =
    useRequestParams<GetManagersPayloadType, ManagerType>()

  useDelayedEffect(() => {
    getManagersAction(reqParams)
  }, [JSON.stringify(reqParams)])

  useEffect(() => {
    afterPageChange(managers.length)
  }, [managers.length])

  return (
    <ListManager
      managersIsLoading={isLoading}
      onParamsChange={setParams}
      tableProps={{ pagination, onChange: onTableStateChange }}
      managers={managers}
    />
  )
}
