import { AudienceProject } from '@views/project'
import { ProjectType } from '@pages/types'
import { useOutletContext } from 'react-router'

import { AudienceProvider } from '@/context'

const AudienceProjectPage = () => {
  const { project } = useOutletContext<{ project: ProjectType }>()

  return (
    <AudienceProvider>
      <AudienceProject project={project} />
    </AudienceProvider>
  )
}

export default AudienceProjectPage
