/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react'
import { css } from '@emotion/react'
import { useTranslation } from 'react-i18next'
import { Icon, Row, Text, colors } from 'common-components'
import { PresetTag } from '@components/display'

type StatusTagUserPropsType = {
  status: 0 | 1 | 2
}

const StatusTagUser = ({ status }: StatusTagUserPropsType) => {
  const { t } = useTranslation()
  const UserStatus: Record<number, string> = {
    1: t('managers.active'),
    3: t('managers.inactive'),
    2: t('managers.waiting'),
  }

  const styling = useMemo(() => {
    if (status === 0) {
      return (
        <div
          css={css`
            width: 6px;
            height: 6px;
            border-radius: 999px;
            background-color: ${colors.success500};
          `}
        />
      )
    }
    if (status === 1) {
      return (
        <div
          css={css`
            width: 6px;
            height: 6px;
            border-radius: 999px;
            background-color: ${colors.gray500};
          `}
        />
      )
    }
    if (status === 2) {
      return <Icon icon='Hourglass03' color={colors.primary500} size={12} />
    }
  }, [status])

  const color = useMemo(() => {
    if (status === 0) {
      return colors.success700
    } else if (status === 1) {
      return colors.gray700
    } else {
      return colors.primary700
    }
  }, [status])

  return (
    <Row align={'middle'}>
      <PresetTag
        css={css`
          display: flex;
          padding: 5px 9px;
          gap: 4px;
        `}
        state={status}
        preset={'user-status'}
      >
        {styling}
        <Text color={color}>{UserStatus[status] || 'active'}</Text>
      </PresetTag>
    </Row>
  )
}

export default StatusTagUser
