const en = {
  pageTitle: 'Segments',
  pageTitleSelected: 'selected segments',
  pageDescription:
    'A segment is a group of collaborators. Combine segments to define your project audiences.',
  headerButtons: {
    export: 'Export',
    filter: 'Filter',
    create: 'Create a segment',
    modify: 'Modify',
    add: 'Add managers',
  },
  tableColumns: {
    name: 'Name',
    type: 'Type',
    privileges: 'My privileges',
    members: 'Members',
    managers: 'Managers',
    use: 'Use',
  },
  request: 'Request',
  requestModal: {
    title: 'Request access',
    description: 'Ask the administrators of this segment to grant you access.',
    ok: 'Confirm',
    cancel: 'Cancel',
  },
  segmentType: {
    competence: 'Competence',
    manual: 'Manual',
    certification: 'Certification',
    socioDemographic: 'Socio-demographic',
  },
  addManagers: 'Add Managers',
  back: 'Back',
  finish: 'Finish',
  deleteSegment: {
    button: 'Delete',
    modal: {
      title: 'Delete a segment',
      subTitle1: 'Delete',
      subTitle2: 'segment',
      subTitle3: 'segments',
      description: 'Are you sure you want to delete this segment ?',
      button: 'Delete',
    },
  },
}

export default en
export type Translations = typeof en
