/****************************************************************
 * GET DOCUMENT LOGS
 *****************************************************************/
import { getDocument, getDocumentLogs } from '@api'

import { StoreGlobalType } from '../store'
import { SetState } from '../store.type'
import { getApiError } from '../helper'

import { ProjectSliceType } from './project.slice'

import { documentApiMapper, documentLogApiMapper } from '@/mappers/model.mapper'

type GetDocumentLogsActionStatusType =
  | 'getDocumentLogs/loading'
  | 'getDocumentLogs/succeeded'
  | 'getDocumentLogs/failed'

export type GetDocumentLogsActionPayloadType = {
  idDocument: string
}

export const getDocumentLogsAction =
  (
    set: SetState<
      Partial<ProjectSliceType & StoreGlobalType>,
      GetDocumentLogsActionStatusType,
      GetDocumentLogsActionPayloadType
    >,
  ) =>
  async (payload: GetDocumentLogsActionPayloadType) => {
    try {
      set(
        (state) => {
          state.getDocumentLogsAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'getDocumentLogs/loading', payload },
      )

      const documentApi = await getDocument(payload.idDocument)
      const document = documentApiMapper(documentApi)

      const documentLogsApi = await getDocumentLogs(payload.idDocument)
      const documentLogs = documentLogsApi?.map(documentLogApiMapper)

      set(
        (state) => {
          state.getDocumentLogsAction.status = 'SUCCEEDED'
          state.documentLogs = documentLogs
          state.document = document
        },
        false,
        { type: 'getDocumentLogs/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.getDocumentLogsAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'getDocumentLogs/failed' },
      )
    }
  }
