import { TxKeyPath } from '@i18n/index'

import { ImmerStateCreator } from '../store.type'

import {
  addErrorNotificationAction,
  AddErrorNotificationActionPayloadType,
  deleteErrorNotificationAction,
  deleteSuccessNotificationAction,
} from './notification.action'

export type NotificationSliceType = {
  error?: {
    id: string
    description?: string
    descriptionTranslate?: TxKeyPath
    messageTranslate: TxKeyPath
    duration: number
  }
  success?: {
    id: string
    descriptionTranslate?: TxKeyPath
    messageTranslate: TxKeyPath
    duration: number
  }
  addErrorNotificationAction: (payload?: AddErrorNotificationActionPayloadType) => void
  deleteErrorNotificationAction: () => void
  deleteSuccessNotificationAction: () => void
}

const notificationSlice: ImmerStateCreator<NotificationSliceType> = (set) => ({
  error: undefined,
  success: undefined,
  addErrorNotificationAction: (payload) => addErrorNotificationAction(set)(payload),
  deleteErrorNotificationAction: () => deleteErrorNotificationAction(set)(),
  deleteSuccessNotificationAction: () => deleteSuccessNotificationAction(set)(),
})

export default notificationSlice
