import { useEffect } from 'react'
import { DELAY_DURATION_CALL_ACTION } from '@utils'

const useDelayedEffect = (
  callback: () => void,
  deps: unknown[] = [],
  delay = DELAY_DURATION_CALL_ACTION,
) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      callback()
    }, delay)

    return () => clearTimeout(timer)
  }, deps)
}

export default useDelayedEffect
