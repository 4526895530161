/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

const AudienceStat = () => {
  return (
    <div
      css={css`
        display: flex;
        padding: 20px 16px;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        border-radius: 8px;
        background: var(--gray-50, #f9fafb);
      `}
    >
      {/* HIDE
			<div
				css={css`
					display: flex;
					flex-direction: column;
					gap: 4px;
					max-width: 400px;
				`}
			>
				<Text
					css={css`
						color: ${colors.gray900} !important;
						font-size: 18px;
						font-weight: 800;
						line-height: 20px;
					`}
				>
					{translate("project.audience.title")}
				</Text>
				<Text
					css={css`
						font-size: 14px;
						font-family: Caros Soft;
						line-height: 20px;
					`}
				>
					{translate("createSegment.listSizeVaries")}
				</Text>
			</div>
			<div
				css={css`
					display: flex;
					flex-direction: column;
					gap: 4px;
				`}
			>
				<ProgressIndicator
					format={() => `2156`}
					size={"small"}
					type="dashboard"
					percent={35}
				/>
				<Text size="xs">Utilisateurs</Text>
			</div>
			*/}
    </div>
  )
}

export default AudienceStat
