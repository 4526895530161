/** @jsxImportSource @emotion/react */

import React, { useContext, useState } from 'react'
import { css } from '@emotion/react'
import { getRiskLevelValueKey } from '@utils/getRiskLevelValueKey'
import { TxKeyPath } from '@i18n'
import { useQuery } from 'react-query'
import { getRiskPageURLs } from '@api/risk'
import { mutationKeys } from '@definitions'
import {
  colors,
  Content,
  LayoutContainer,
  Logo,
  Paragraph,
  Sider,
  Space,
  Title,
} from 'common-components'
import { useTranslation } from '@hooks'
import { RiskSensitivityConfig } from '@views/shared/risk'

import DetailedSettingsRisk from './DetailedSettingsRisk'
import DocumentPreviewRisk from './DocumentPreviewRisk'
import TopBarRisk from './TopBarRisk'

import { riskPageURLItemMapperApi } from '@/mappers'
import { RiskAssessmentType, RiskPageURLItemType, RiskSettingsType, RiskType } from '@/pages/types'
import { RiskContext } from '@/context'
import { PresetTag } from '@/components/display'

const riskSettingTypeMap: Record<
  keyof Omit<RiskSettingsType, 'userId' | 'metadataKeywordsBlacklist'>,
  RiskAssessmentType[]
> = {
  seuilChangementAlignement: ['Parallelism', 'Parallelism in blocks'],
  seuilDifferenceEspace: ['Spacing', 'Spacing in blocks'],
  seuilDifferenceCouleur: ['Colorimetry', 'Colorimetry in blocks'],
  metadataKeywords: ['MetadataKeywords'],
  numberOfDays: ['versioning', 'versioning in blocks'],
  minimumLayer: ['Layers'],
  size: ['Size', 'Size in blocks'],
  font: ['Font', 'Font in blocks'],
}

type RiskOverviewPropsType = {
  risk: RiskType
  isLoading?: boolean
}
const Risk = ({ risk, isLoading }: RiskOverviewPropsType) => {
  const { translate } = useTranslation()
  const { setTotalPages, currentPage } = useContext(RiskContext)

  const [pageUrls, setPageUrls] = useState<RiskPageURLItemType[]>([])

  useQuery({
    queryKey: mutationKeys.risks({ key: 'urls', id: risk.idRisk }),
    queryFn: () => getRiskPageURLs(risk.idRisk),
    onSuccess: (riskPagesApi) => {
      const risksPageUrlsData = riskPagesApi.map(riskPageURLItemMapperApi)
      setPageUrls(risksPageUrlsData)
      setTotalPages(riskPagesApi.length)
    },
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  })

  const riskWithUsedSettings = (() => {
    const activeRisks = risk?.risks?.filter((riskItem) => {
      const settingKey = Object.keys(riskSettingTypeMap).find(
        (key: keyof typeof riskSettingTypeMap) => riskSettingTypeMap[key].includes(riskItem.type),
      ) as keyof typeof riskSettingTypeMap
      return risk.riskSettingsUsed[settingKey]?.isActive
    })
    return {
      ...risk,
      risks: activeRisks,
    }
  })()

  return (
    <LayoutContainer style={{ height: '100%' }}>
      {isLoading ? (
        <div
          style={{
            minHeight: '100vh',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Logo preview={false} width={100} style={{ marginBottom: 20 }} />
          <Paragraph preset={'pageHeader'}>{translate('auth.loading')}</Paragraph>
        </div>
      ) : (
        <>
          <TopBarRisk docName={risk.fileName} riskLevel={risk.level} riskScore={risk.score} />
          <LayoutContainer hasSider style={{ height: '100%' }}>
            <Sider width={360} collapsed collapsedWidth={360}>
              <LayoutContainer
                css={css`
                  padding: 22px 0;
                  border-right: 1px solid ${colors.gray200};
                  height: 100%;
                  max-width: 420px;
                  width: 100%;
                `}
              >
                <Space
                  size={10}
                  direction={'horizontal'}
                  css={css`
                    margin-bottom: 20px;
                    padding: 0 20px;
                  `}
                >
                  <Title style={{ fontSize: 16 }} color={colors.gray700}>
                    {translate('project.tasklineOverview.sidebar.title')}
                  </Title>
                  <PresetTag preset='risk-level' state={risk.level}>
                    {translate(`risk.levelStates.${getRiskLevelValueKey(risk.level)}` as TxKeyPath)}
                  </PresetTag>
                </Space>

                <div
                  css={css`
                    margin-bottom: 20px;
                    padding: 0 20px;
                  `}
                >
                  <RiskSensitivityConfig editable={false} settingsUsed={risk.riskSettingsUsed} />
                </div>

                <DetailedSettingsRisk page={currentPage} risk={riskWithUsedSettings} />
              </LayoutContainer>
            </Sider>
            <Content>
              <LayoutContainer
                css={css`
                  background: ${colors.gray50};
                  height: 100%;
                `}
              >
                {!pageUrls[currentPage]?.imageUrl ? (
                  <LayoutContainer
                    css={css`
                      width: 100%;
                      height: 100%;
                      display: flex;
                      align-items: center;
                      padding: 30px;
                    `}
                  >
                    <Logo preview={false} width={100} style={{ marginBottom: 20 }} />
                    <Paragraph preset={'pageHeader'}>{translate('risk.imageNotFound')}</Paragraph>
                  </LayoutContainer>
                ) : (
                  <DocumentPreviewRisk
                    imgSrc={pageUrls[currentPage]?.imageUrl}
                    risks={risk.risks}
                  />
                )}
              </LayoutContainer>
            </Content>
          </LayoutContainer>
        </>
      )}
    </LayoutContainer>
  )
}

export default Risk
