/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useRef, useEffect, useState } from 'react'
import {
  Form,
  FormItem,
  Row,
  SelectInput,
  SelectOption,
  Text,
  Col,
  colors,
  ProgressBar,
} from 'common-components'
import { useForm, Controller } from 'react-hook-form'
import { useSociodemographicResources, useTranslation } from '@hooks'
import { AudienceStat } from '@components/display'
import {
  SociodemographicCriteria,
  TSociodemographicCriteria,
  TSociodemographicCriteriaValues,
} from '@definitions/states'
import { getStateKeyByValue } from '@utils'
import { AssignManager } from '@views/shared/manager'
import { SegmentType } from '@pages/types'
import { UpdateSegmentSocioDemographicPayloadType } from '@api'

import { HeaderSegment } from '../HeaderSegment'

const type = 40

const fullWidthStyle = css`
  width: 100%;
`

type GeographicPropsType = {
  step: number
  segment: SegmentType
  onUpdateSegmentSocioDemographic: (payload: UpdateSegmentSocioDemographicPayloadType) => void
  onUpdateManagers: (managersShort: SegmentType['managersShort']) => void
}

type FormType = {
  id: string
  criteria: string[]
  parameter: TSociodemographicCriteriaValues | TSociodemographicCriteria
  progressValue: number[]
}
const GeographicSegment = ({
  step,
  segment,
  onUpdateSegmentSocioDemographic,
  onUpdateManagers,
}: GeographicPropsType) => {
  const { translate } = useTranslation()
  const { watch, control } = useForm<FormType>()
  const [progressValue, setProgressValue] = useState<[number, number]>([0, 0])
  const formDataRef = useRef<FormType>(null)
  const { resources, getResourceOptions, getResource } = useSociodemographicResources()
  const submitData = watch()

  useEffect(() => {
    formDataRef.current = {
      ...submitData,
      progressValue,
      id: segment.idSegment,
    }
  }, [submitData])

  useEffect(() => {
    if (step === 3) {
      const id = formDataRef.current.id
      const isAgeSelected =
        submitData.parameter === getStateKeyByValue<number>(SociodemographicCriteria, 'age')
      const isSenioritySelected =
        submitData.parameter === getStateKeyByValue<number>(SociodemographicCriteria, 'seniority')
      let payload: UpdateSegmentSocioDemographicPayloadType

      if (isAgeSelected || isSenioritySelected) {
        payload = {
          idSegment: id || segment.idSegment,
          socioDemographicCriteria: submitData.parameter,
          socioDemographicMinValue: progressValue[0],
          socioDemographicMaxValue: progressValue[1],
        }
      } else {
        payload = {
          socioDemographicCriteria: submitData.parameter,
          socioDemographicCriteriaValues: submitData.criteria,
          idSegment: id || segment.idSegment,
        }
      }
      onUpdateSegmentSocioDemographic(payload)
    }
  }, [step])

  if (step === 3) {
    return (
      <>
        <HeaderSegment type={type} name={segment.name} />
        <Row justify={'space-between'} align={'middle'}>
          <Col
            css={css`
              display: flex;
              flex-direction: column;
            `}
          >
            <Text color={colors.gray400} size='md'>
              3 {translate('createSegment.outOf')} 3
            </Text>
            <Text
              css={css`
                font-weight: 500;
              `}
              color={colors.gray900}
              size='xl'
            >
              {translate('createSegment.chooseManagers')}
            </Text>
          </Col>
        </Row>
        <AssignManager managersUser={segment.managersShort} onUpdateManagers={onUpdateManagers} />
      </>
    )
  }

  return (
    <div>
      <HeaderSegment type={type} name={segment.name} />
      <Row
        css={css`
          flex-direction: column;
        `}
      >
        <Text color={colors.gray400} size='md'>
          {step} {translate('createSegment.outOf')} 3
        </Text>
        <Text
          css={css`
            font-weight: 500;
          `}
          color={colors.gray900}
          size='xl'
        >
          {translate('createSegment.addUsers')}
        </Text>
      </Row>
      <Form
        css={css`
          margin-top: 10px;
        `}
      >
        <FormItem label='Paramètre'>
          <Controller
            name={'parameter'}
            control={control}
            render={({ field }) => {
              return (
                <SelectInput onChange={field.onChange} css={fullWidthStyle}>
                  {resources.map((res) => (
                    <SelectOption
                      key={res.socioDemographicCriteria}
                      value={res.socioDemographicCriteria}
                    >
                      {res.name}
                    </SelectOption>
                  ))}
                </SelectInput>
              )
            }}
          />
        </FormItem>
        {submitData.parameter !== getStateKeyByValue<number>(SociodemographicCriteria, 'age') &&
        submitData.parameter !==
          getStateKeyByValue<number>(SociodemographicCriteria, 'seniority') ? (
          <FormItem label='Criteria'>
            <Controller
              name={'criteria'}
              control={control}
              render={({ field }) => {
                return (
                  <SelectInput onChange={field.onChange} mode={'multiple'} css={fullWidthStyle}>
                    {getResourceOptions(submitData.parameter).map((option) => (
                      <SelectOption value={option.value}>{option.label}</SelectOption>
                    ))}
                  </SelectInput>
                )
              }}
            />
          </FormItem>
        ) : (
          <FormItem label='Criteria'>
            <ProgressBar
              place='top'
              setValue={setProgressValue}
              value={progressValue}
              defaultValue={[
                getResource(submitData.parameter)?.minValue || 0,
                getResource(submitData.parameter)?.maxValue || 0,
              ]}
              min={getResource(submitData.parameter)?.minValue}
              max={getResource(submitData.parameter)?.maxValue}
              range={true}
              step={getResource(submitData.parameter)?.intervalRange || 1}
            />
          </FormItem>
        )}
      </Form>
      <AudienceStat />
    </div>
  )
}

export default GeographicSegment
