/** @jsxImportSource @emotion/react */
import { useEffect, useMemo } from 'react'
import { PageLayout } from '@components/layout'
import { useRequestParams, useTranslation } from '@hooks'
import { useNavigate } from 'react-router'
import { ColumnsType } from 'antd/lib/table'
import {
  Content,
  Text,
  Row,
  Space,
  colors,
  AvatarIcons,
  AvatarIcon,
  IconButton,
  Table,
} from 'common-components'
import { GetCertificationUserTestsPayloadType } from '@api'
import { routes } from '@navigation/routes'
import { CertificationUserTestType, ContributorType } from '@pages/types'
import { Loader } from '@components/utils'

import { SubHeaderContributor } from '../shared'

import { store } from '@/store'

type CertificationsContributorPropsType = {
  contributor: ContributorType
}

const CertificationsContributor = ({ contributor }: CertificationsContributorPropsType) => {
  const idContributor = contributor.userData.idUser
  const certificationsTest = store.use.certificationsTestContributor()

  const getCertificationsTestContributorAction =
    store.use.getCertificationsTestContributorAction().call
  const isLoadingGetCertificationsTestContributorAction =
    store.use.getCertificationsTestContributorAction().status === 'LOADING'

  const { afterPageChange, pagination, onTableStateChange } = useRequestParams<
    GetCertificationUserTestsPayloadType,
    CertificationUserTestType
  >({ initParams: { model: {} } })

  const navigate = useNavigate()
  const { translate } = useTranslation()

  useEffect(() => {
    getCertificationsTestContributorAction({
      idContributor,
    })
  }, [idContributor])

  useEffect(() => {
    afterPageChange(certificationsTest?.length)
  }, [certificationsTest?.length])

  const tableColumns: ColumnsType<CertificationUserTestType> = useMemo(() => {
    try {
      return [
        {
          title: translate('certificationsPage.table.name'),
          key: 'name',
          width: 580,
          sorter: (a, b) => {
            if (a.name < b.name) return -1
            if (a.name > b.name) return 1
            return 0
          },
          render: (record: CertificationUserTestType) => (
            <Row align={'middle'}>
              <Space size={'middle'} style={{ gap: 12 }}>
                <AvatarIcon
                  icon={record.certification.icon?.iconName as AvatarIcons}
                  style={{ backgroundColor: record.certification.icon?.iconColor }}
                  size={'sm'}
                />
                <Text size='sm' strong style={{ color: colors.gray900 }}>
                  {record.certification.name}
                </Text>
              </Space>
            </Row>
          ),
        },
        {
          title: translate('certificationPage.respondentsTable.type'),
          key: 'type',
          sorter: (a, b) => {
            if (a.user.type < b.user.type) return -1
            if (a.user.type > b.user.type) return 1
            return 0
          },
          render: (_, record) => (
            <Row align={'middle'}>
              <Text size='xs'>{translate(`common.states.userRole.${record.user.type}`)}</Text>
            </Row>
          ),
        },
        {
          title: translate('certificationPage.respondentsTable.date'),
          key: 'creationDate',
          sorter: (a, b) => {
            if (a.creationDate < b.creationDate) return -1
            if (a.creationDate > b.creationDate) return 1
            return 0
          },
          render: (record: CertificationUserTestType) => (
            <Row align={'middle'}>
              <Text size='xs'>{new Date(record.creationDate).toLocaleDateString()}</Text>
            </Row>
          ),
        },
        {
          title: translate('certificationPage.respondentsTable.time'),
          key: 'duration',
          sorter: (a, b) => {
            if (a.duration < b.duration) return -1
            if (a.duration > b.duration) return 1
            return 0
          },
          render: (record: CertificationUserTestType) => (
            <Row align={'middle'}>
              <Text size='xs'>{record.duration}</Text>
            </Row>
          ),
        },
        {
          title: translate('certificationPage.respondentsTable.score'),
          key: 'mark',
          sorter: (a, b) => a.mark - b.mark,
          render: (record: CertificationUserTestType) => {
            return (
              <Row align={'middle'}>
                <Text size='xs'>{record?.mark && `${record.mark}%`}</Text>
              </Row>
            )
          },
        },
        {
          title: translate('certificationPage.respondentsTable.state'),
          key: 'state',
          sorter: (a, b) => a.state - b.state,
          render: (record: CertificationUserTestType) => (
            <Row align={'middle'}>
              <Text size='xs'>
                {translate(`common.states.userCertificationState.${record.state}`)}
              </Text>
            </Row>
          ),
        },
        {
          title: translate('certificationPage.respondentsTable.actions'),
          key: 'idCertification',
          sorter: false,
          render: (record: CertificationUserTestType) => (
            <Space align={'center'} size={5}>
              <IconButton
                type={'link'}
                icon={'Eye'}
                iconProps={{ color: colors.gray600 }}
                onClick={() =>
                  navigate(
                    routes.certificationRespondent.navigate(
                      record.idCertification,
                      record.idCertificationUserTest,
                    ),
                  )
                }
              />
            </Space>
          ),
        },
      ]
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
      return []
    }
  }, [])

  return (
    <PageLayout>
      <Content>
        <SubHeaderContributor
          texts={[
            {
              type: 'tablePageTitle',
              content: translate('certificationsPage.listHeaderTitle'),
            },
          ]}
          count={certificationsTest.length}
        />

        {isLoadingGetCertificationsTestContributorAction && <Loader />}
        {!isLoadingGetCertificationsTestContributorAction && (
          <Table<CertificationUserTestType>
            data={certificationsTest}
            columns={tableColumns}
            rowKey='idCertificationUserTest'
            onRow={() => ({
              onMouseOver: () => null,
            })}
            pagination={pagination}
            onChange={onTableStateChange}
          />
        )}
      </Content>
    </PageLayout>
  )
}
export default CertificationsContributor
