const en = {
  nextStepButton: 'Next step',
  prevStepButton: 'Previous step',
  finishButton: 'Finish',
  creationSuccessTitle: 'Your certification has been created',
  creationSuccessSubtitle:
    "Your certification is in draft form. To publish it, enter the answers to the questions you've added",
  steps: {
    1: 'Input parameters',
    2: 'Questions',
    3: 'Certification segments',
  },
  stepPageTitle: {
    1: 'Form choice',
    2: 'Add questions',
    3: 'Settings',
  },
  stepPageSubtitle: {
    1: 'Define how your project tasks are completed',
    2: 'Define how your project tasks are completed',
    3: 'Define how your project tasks are completed',
  },
  questions: {
    eraseButtonLabel: 'Effacer',
    refreshButtonLabel: 'Rafraichir',
    refreshText: "Vos données sont en cours d'intégration.",
    errorsOnly: 'Erreurs uniquement',
    questionsTitle: 'Questions',
    questionsSubtitle: 'Déposez vos demandes sous format Excel ou saisissez les directement',
    uploadFile: 'Télécharger au format Excel',
  },
  settings: {
    audienceTitle: 'Audience',
    settingsTitle: 'Settings',
    settingsSubtitle: 'Choose an entry form',
    audience: {
      external: 'External',
      externalDesc: 'This certification is also available to external contributors',
      candidates: 'Candidates',
      candidatesDesc: 'This certification is a recruitment test',
      interns: 'Interns',
      internsDesc: 'This certification defines access to an internal project',
    },
    segments: {
      segmentsTitle: 'Segments',
      segmentsSubtitle: 'Define certification thresholds that form segments',
      addSegmentButton: 'New segment',
    },
  },
}

export default en
export type Translations = typeof en
