/** @jsxImportSource @emotion/react */

import { PresetTag } from '@components/display'
import { useTranslation } from '@hooks'
import { PropsWithChildren, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { routes } from '@navigation/routes'
import { CertificationType, QuestionType } from '@pages/types'
import { DeleteModal } from '@components/modal'
import { useNavigate } from 'react-router'
import { getWirkUrl } from '@definitions'
import {
  Button,
  ButtonGroup,
  colors,
  Content,
  LayoutContainer,
  Paragraph,
  Row,
  Space,
  Toggle,
  useDisclosure,
} from 'common-components'

import { store } from '@/store'

type QuestionLayoutCertificationPropsType = {
  certification?: CertificationType
  question?: QuestionType
  onQuestionTrainingStatusUpdate?: (training: boolean) => void
}

const QuestionCertification = ({
  certification,
  question,
  children,
  onQuestionTrainingStatusUpdate,
}: PropsWithChildren<QuestionLayoutCertificationPropsType>) => {
  const params = useParams()
  const accessToken = store.use.me().accessToken

  const deleteQuestionsCertificationAction = store.use.deleteQuestionsCertificationAction().call

  const isLoadingDeleteQuestionsCertificationAction =
    store.use.deleteQuestionsCertificationAction().status === 'LOADING'

  const certificationQuestionUrl = `${getWirkUrl('Form')}/contribute/certification/${
    params.id
  }/question/${params.questionId}?token=${accessToken}&tenant=${params.tenant}`

  const useDisclosureDeleteQuestionsModal = useDisclosure()

  const navigate = useNavigate()
  const { translate } = useTranslation()

  const currentQuestionIdx = useMemo(
    () =>
      certification?.questions.findIndex(
        (curr) => curr.idCertificationQuestion === question?.idCertificationQuestion,
      ),
    [question?.idCertificationQuestion, certification?.questions.length],
  )

  const navigateToQuestion = (questionId: string) => {
    navigate(routes.certificationQuestion.navigate(certification.idCertification, questionId))
  }

  const onDeleteQuestionMutation = () => {
    deleteQuestionsCertificationAction(
      {
        idCertification: params.id,
        idQuestions: [question.idCertificationQuestion],
      },
      () => {
        useDisclosureDeleteQuestionsModal.close()
        navigate(routes.certificationQuestions.navigate(certification.idCertification))
      },
    )
  }

  return (
    <LayoutContainer>
      <LayoutContainer style={{ padding: 18, borderBottom: `1px solid ${colors.gray200}` }}>
        <Row style={{ width: '100%' }} justify={'space-between'}>
          <Space size={10} align={'center'}>
            <ButtonGroup className='ant-btn-group'>
              {/*TODO: connect to the api (index inside question entity is required)*/}
              <Button
                disabled={currentQuestionIdx === 0}
                iconLeft={'ChevronLeft'}
                onClick={() =>
                  navigateToQuestion(
                    certification?.questions[currentQuestionIdx - 1]?.idCertificationQuestion,
                  )
                }
              />
              <Button
                disabled={currentQuestionIdx + 1 === certification?.questions.length}
                iconLeft={'ChevronRight'}
                onClick={() =>
                  navigateToQuestion(
                    certification?.questions[currentQuestionIdx + 1]?.idCertificationQuestion,
                  )
                }
              />
            </ButtonGroup>
            <Paragraph size={'lg'} color={colors.gray900} style={{ marginBottom: 0 }}>
              {`${translate(`certificationPage.questionHeaderTitle`)} ${
                question?.idCertificationQuestion
              }`}
            </Paragraph>
            <PresetTag preset={'question-state'} state={question?.state} withDot>
              {translate(`common.states.questionState.${question?.state}`)}
            </PresetTag>
            <Toggle
              onChange={(val) => onQuestionTrainingStatusUpdate(val)}
              defaultChecked={question?.training}
            />
          </Space>
          <Row align={'middle'}>
            <Button
              onClick={useDisclosureDeleteQuestionsModal.open}
              type='danger'
              iconLeft='Trash02'
            >
              {translate('certificationPage.deleteQuestionModal.deleteButtonLabel')}
            </Button>
          </Row>
        </Row>
      </LayoutContainer>
      <LayoutContainer>
        <Content>
          {children}

          <Content>
            <iframe
              src={certificationQuestionUrl}
              height='800'
              width='100%'
              frameBorder='0'
            ></iframe>
          </Content>

          {useDisclosureDeleteQuestionsModal.isOpen && (
            <DeleteModal
              close={useDisclosureDeleteQuestionsModal.close}
              isOpen={useDisclosureDeleteQuestionsModal.isOpen}
              title={translate('certificationPage.deleteQuestionModal.title')}
              label={translate('certificationPage.deleteQuestionModal.description')}
              isLoading={isLoadingDeleteQuestionsCertificationAction}
              onDeleteMutation={onDeleteQuestionMutation}
            />
          )}
        </Content>
      </LayoutContainer>
    </LayoutContainer>
  )
}

export default QuestionCertification
