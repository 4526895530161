/** @jsxImportSource @emotion/react */
import { VerticalFormItem } from '@components/display'
import { Validations } from '@definitions/validations'
import { css } from '@emotion/react'
import { useTranslation } from '@hooks/useTranslation'
import {
  Button,
  Col,
  Divider,
  FormElements,
  IconButton,
  Row,
  SelectInput,
  SelectOption,
  Space,
  TimePicker,
  colors,
} from 'common-components'

const nbDays = [0, 1, 2, 3, 4, 5, 6, 7, 15, 30, 45, 60, 90, 120, 180, 360]

const LeverageHours = () => {
  const { translate } = useTranslation()
  const timeFormat = 'HH:mm'

  return (
    <FormElements.List name='leverageHours'>
      {(deadlines, { add, remove }) => (
        <Space direction='vertical' style={{ width: '100%', gap: 16 }}>
          <Space
            direction='vertical'
            style={{
              width: '100%',
              border: `1px solid ${colors.gray200}`,
              borderRadius: 16,
              overflow: 'hidden',
            }}
          >
            {deadlines.map((deadline, idx) => {
              return (
                <div key={deadline.key}>
                  <Space
                    style={{ width: '100%', padding: 16 }}
                    css={css`
                      .ant-space-item:first-child {
                        flex: 1;
                      }
                    `}
                  >
                    <Row gutter={16} style={{ flex: 1 }}>
                      <Col span={8}>
                        <VerticalFormItem
                          label={translate(
                            'projectCreatePage.collapse.processingTime.form.timeOfLifting',
                          )}
                          name={[idx, 'leverageTime']}
                          rules={[{ required: true, message: Validations.message.required }]}
                        >
                          <TimePicker minuteStep={30} format={timeFormat} />
                        </VerticalFormItem>
                      </Col>
                      <Col span={8}>
                        <VerticalFormItem
                          label={translate(
                            'projectCreatePage.collapse.processingTime.form.dayOfDelivery',
                          )}
                          name={[idx, 'nbDays']}
                          rules={[{ required: true, message: Validations.message.required }]}
                        >
                          <SelectInput value={nbDays[0]}>
                            {nbDays.map((day) => (
                              <SelectOption key={day} value={day}>{`J+${day}`}</SelectOption>
                            ))}
                          </SelectInput>
                        </VerticalFormItem>
                      </Col>
                      <Col span={8}>
                        <VerticalFormItem
                          label={translate(
                            'projectCreatePage.collapse.processingTime.form.cutOffTime',
                          )}
                          name={[idx, 'deleveringTime']}
                          rules={[{ required: true, message: Validations.message.required }]}
                        >
                          <TimePicker minuteStep={30} format={timeFormat} />
                        </VerticalFormItem>
                      </Col>
                    </Row>
                    <IconButton
                      icon='XClose'
                      type='link'
                      iconProps={{ color: colors.gray500 }}
                      onClick={() => remove(deadline.name)}
                    />
                  </Space>
                  {idx + 1 < deadlines.length && <Divider style={{ margin: 0 }} />}
                </div>
              )
            })}
          </Space>
          <Button onClick={() => add()}>
            {translate('projectCreatePage.collapse.processingTime.button.addHour')}
          </Button>
        </Space>
      )}
    </FormElements.List>
  )
}

export default LeverageHours
