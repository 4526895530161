/** @jsxImportSource @emotion/react */

import { PresetTag, VerticalFormItem } from '@components/display'
import { useTranslation } from '@hooks'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { FormState } from '@definitions'
import { css } from '@emotion/react'
import { GlobalOutlined, LoadingOutlined } from '@ant-design/icons'
import { CertificationType } from '@pages/types'
import {
  BG_COLORS,
  Button,
  Card,
  Col,
  colors,
  Content,
  Form,
  IconSearch,
  Row,
  Space,
  TextInput,
  Title,
  Text,
} from 'common-components'

import AssignManager from '../manager/assign/AssignManager'

import CreateSegmentCertification from './CreateSegmentCertification'

import { store } from '@/store'

export type SettingsCertificationPropsType = {
  certification?: CertificationType
  onNextStep?: () => void
  includedFormParts?: ('settings' | 'form' | 'info' | 'audience' | 'segments' | 'managers')[]
  state?: 'creating' | 'editing'
}

const SettingsCertification = ({
  certification,
  onNextStep,
  includedFormParts = ['settings', 'audience', 'managers', 'segments'],
  state = 'creating',
}: SettingsCertificationPropsType) => {
  const { translate } = useTranslation()

  const addManagersToCertificationAction = store.use.addManagersToCertificationAction().call
  const updateInformationCertificationAction = store.use.updateInformationCertificationAction().call

  const isLoadingAddManagersToCertificationAction =
    store.use.addManagersToCertificationAction().status === 'LOADING'
  const isLoadingUpdateInformationCertificationAction =
    store.use.updateInformationCertificationAction().status === 'LOADING'
  const isLoadingUpdateNextStepCertificationAction =
    store.use.updateNextStepCertificationAction().status === 'LOADING'

  const { control, handleSubmit, getValues, reset, setValue } = useForm({
    defaultValues: certification,
  })

  useEffect(() => {
    !!certification?.idCertification && reset(certification)
  }, [certification?.idCertification])

  const onSubmit = () => {
    updateInformationCertificationAction(
      {
        idCertification: getValues().idCertification,
        nbQuestionsTest: getValues().nbQuestionsTest,
        nbAttemptsMax: getValues().nbAttemptsMax,
        icon: getValues().icon,
        name: getValues().name,
      },
      onNextStep,
    )
  }

  return (
    <Content>
      {includedFormParts.includes('form') && (
        <Card style={{ width: '100%', padding: 20, marginBottom: 20 }}>
          <Title style={{ fontSize: 18 }}>{translate(`form.formTitle`)}</Title>
          <Title preset='tablePageSubtitle' style={{ fontSize: 14, marginBottom: 16 }}>
            {translate(`form.formSubtitle`)}
          </Title>
          <Card style={{ width: '100%', padding: 20, marginBottom: 20 }}>
            <Space size={20} align={'start'} style={{ width: '100%' }}>
              <Col
                css={css`
                  background: ${colors.cyan100};
                  border-radius: 200px;
                  width: 56px;
                  height: 56px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                `}
              >
                <GlobalOutlined width={32} height={32} />
              </Col>
              <Space size={10} direction={'vertical'}>
                <Title style={{ fontSize: 18 }}>{certification.formShort?.name}</Title>
                <PresetTag preset={'form-state'} state={certification.formShort?.state}>
                  {FormState[certification.formShort?.state]}
                </PresetTag>
              </Space>
            </Space>
          </Card>
        </Card>
      )}
      <Form onFinish={handleSubmit(onSubmit)}>
        {includedFormParts.includes('settings') && (
          <Card style={{ width: '100%', padding: 20, marginBottom: 20 }}>
            <Col span={24}>
              <Title style={{ fontSize: 18 }}>
                {translate(`certificationCreation.settings.settingsTitle`)}
              </Title>
              <Title preset='tablePageSubtitle' style={{ fontSize: 14, marginBottom: 16 }}>
                {translate(`certificationCreation.settings.settingsSubtitle`)}
              </Title>
              {includedFormParts.includes('info') && (
                <Row style={{ marginBottom: 10 }}>
                  <Col span={2}>
                    <IconSearch
                      bgColors={BG_COLORS}
                      onSubmit={(data) => {
                        setValue('icon', data)
                      }}
                    />
                  </Col>
                  <Col flex={'auto'}>
                    <Controller
                      name={'name'}
                      control={control}
                      render={({ field }) => (
                        <VerticalFormItem label={'Nom'}>
                          <TextInput value={field.value} onChange={field.onChange} />
                        </VerticalFormItem>
                      )}
                    />
                  </Col>
                </Row>
              )}
              <Row justify={'space-between'} align={'middle'} style={{ width: '100%' }}>
                <Col span={11}>
                  <Controller
                    name={'nbQuestionsTest'}
                    control={control}
                    render={({ field }) => (
                      <VerticalFormItem label={'Nombre de questions por test'}>
                        <TextInput type={'number'} value={field.value} onChange={field.onChange} />
                      </VerticalFormItem>
                    )}
                  />
                </Col>
                <Col span={11}>
                  <Controller
                    name={'nbAttemptsMax'}
                    control={control}
                    render={({ field }) => (
                      <VerticalFormItem label={'Nombre de passages maximal'}>
                        <TextInput type={'number'} value={field.value} onChange={field.onChange} />
                      </VerticalFormItem>
                    )}
                  />
                </Col>
              </Row>
            </Col>
          </Card>
        )}
        {/* HIDE
				{includedFormParts.includes("audience") && (
					<Card style={{ width: "100%", padding: 20, marginBottom: 20 }}>
						<Space
							style={{
								display: "flex",
								flexDirection: "column",
								alignItems: "flex-start",
							}}
						>
							<Title style={{ fontSize: 18 }}>
								{translate(`certificationCreation.settings.audienceTitle`)}
							</Title>
							<Controller
								name={"audience"}
								control={control}
								render={({ field }) => (
									<CheckboxGroup value={field.value} onChange={field.onChange}>
										<Space
											style={{
												display: "flex",
												flexDirection: "column",
												alignItems: "flex-start",
											}}
										>
											<Space size={5} align={"start"}>
												<Checkbox
													key={getStateKeyByValue(
														CertificationAudienceType,
														"External",
													)}
													value={getStateKeyByValue(
														CertificationAudienceType,
														"External",
													)}
												/>
												<Space size={3} direction={"vertical"}>
													{translate(
														`certificationCreation.settings.audience.external`,
													)}
													<Text>
														{translate(
															`certificationCreation.settings.audience.externalDesc`,
														)}
													</Text>
												</Space>
											</Space>
											<Space size={5} align={"start"}>
												<Checkbox
													key={getStateKeyByValue(
														CertificationAudienceType,
														"Candidate",
													)}
													value={getStateKeyByValue(
														CertificationAudienceType,
														"Candidate",
													)}
												/>
												<Space size={3} direction={"vertical"}>
													{translate(
														`certificationCreation.settings.audience.candidates`,
													)}
													<Text>
														{translate(
															`certificationCreation.settings.audience.candidatesDesc`,
														)}
													</Text>
												</Space>
												<Text></Text>
											</Space>
											<Space size={5} align={"start"}>
												<Checkbox
													key={getStateKeyByValue(
														CertificationAudienceType,
														"Internal",
													)}
													value={getStateKeyByValue(
														CertificationAudienceType,
														"Internal",
													)}
												/>
												<Space size={3} direction={"vertical"}>
													{translate(
														`certificationCreation.settings.audience.interns`,
													)}
													<Text>
														{translate(
															`certificationCreation.settings.audience.internsDesc`,
														)}
													</Text>
												</Space>
												<Text></Text>
											</Space>
										</Space>
									</CheckboxGroup>
								)}
							/>
						</Space>
					</Card>
				)}
					*/}
        {includedFormParts.includes('segments') && (
          <Card style={{ width: '100%', padding: 20, marginBottom: 20 }}>
            <CreateSegmentCertification certification={certification} />
          </Card>
        )}
        {includedFormParts.includes('managers') && (
          <Card style={{ width: '100%', padding: 20, marginBottom: 20 }}>
            <Controller
              name={'managersShort'}
              control={control}
              render={({ field }) => {
                return (
                  <>
                    <Row justify={'space-between'} align={'middle'}>
                      <Text
                        css={css`
                          font-weight: 500;
                        `}
                        color={colors.gray900}
                        size='xl'
                      >
                        {translate('createSegment.chooseManagers')}
                      </Text>
                    </Row>
                    <AssignManager
                      isLoading={isLoadingAddManagersToCertificationAction}
                      managersUser={field.value || []}
                      onUpdateManagers={(managersShort) =>
                        addManagersToCertificationAction({
                          idCertification: certification.idCertification,
                          managersShort,
                        })
                      }
                    />
                  </>
                )
              }}
            />
          </Card>
        )}
        {state === 'editing' ? (
          <Row justify={'start'} style={{ width: '100%' }}>
            <Button type={'primary'} htmlType={'submit'}>
              Enregistrer les modifications
            </Button>
          </Row>
        ) : (
          <Row justify={'end'}>
            <Space size={5} style={{ margin: 10, gap: 16 }}>
              <Button
                style={{
                  width: '100px',
                }}
                disabled={
                  isLoadingUpdateInformationCertificationAction ||
                  isLoadingUpdateNextStepCertificationAction ||
                  isLoadingAddManagersToCertificationAction
                }
                type={'primary'}
                onClick={handleSubmit(onSubmit)}
              >
                {isLoadingUpdateNextStepCertificationAction ||
                isLoadingUpdateInformationCertificationAction ? (
                  <LoadingOutlined />
                ) : (
                  translate('certificationCreation.finishButton')
                )}
              </Button>
            </Space>
          </Row>
        )}
      </Form>
    </Content>
  )
}

export default SettingsCertification
