/** @jsxImportSource @emotion/react */
import { FC, useContext, useEffect } from 'react'
import { useTranslation } from '@hooks/useTranslation'
import { UpdateAudienceReviewersPayloadType } from '@api/project'
import { TAudienceType } from '@definitions/states'
import { ProjectType } from '@pages/types'
import { Button, Form, FormElements } from 'common-components'
import { LoadingOutlined } from '@ant-design/icons'

import { AudienceCollapse } from '../shared'

import { audienceCollapsesOverride } from './ContributorsAudienceProject'

import { AudienceContext } from '@/context'
import { store } from '@/store'

type AudienceReviewersPropsType = {
  project: ProjectType
  timePerTaskForm: string
}

const ReviewersAudienceProject: FC<AudienceReviewersPropsType> = ({ project, timePerTaskForm }) => {
  const { translate } = useTranslation()
  const [form] = FormElements.useForm()

  const updateAudienceReviewersProjectAction = store.use.updateAudienceReviewersProjectAction().call

  const isLoadingUpdateAudienceReviewersProjectAction =
    store.use.updateAudienceReviewersProjectAction().status === 'LOADING'

  const { setAudience, reviewersAudience } = useContext(AudienceContext)

  const handleSubmit = (values: ProjectType['audience']) => {
    if (!project) return
    const updateAudienceReviewersPayload: UpdateAudienceReviewersPayloadType = {
      idProject: project.idProject,
      audience: {
        reviewerAudienceType: (values.audienceType as TAudienceType) || 0,
        reviewersAudienceGroups: reviewersAudience.audienceGroups,
        reviewersCombination: reviewersAudience.combination,
        reviewerEarning: values?.reviewerEarning,
      },
    }
    updateAudienceReviewersProjectAction(updateAudienceReviewersPayload)
  }

  useEffect(() => {
    if (project?.audience) {
      setAudience('reviewers', {
        audienceGroups: project.audience?.reviewersAudienceGroups,
        combination: project.audience?.reviewersCombination,
      })
    }
  }, [project?.audience])

  return (
    <Form form={form} onFinish={handleSubmit}>
      <div css={audienceCollapsesOverride}>
        <AudienceCollapse
          type='reviewers'
          projectId={project?.idProject}
          audience={reviewersAudience?.audienceGroups}
          combination={reviewersAudience?.combination}
          audienceType={project?.audience?.audienceType}
          selectedEarnings={project?.audience?.reviewerEarning}
          timePerTask={timePerTaskForm}
          withCollapseTitle={false}
        />
      </div>
      <Button
        type='primary'
        htmlType='submit'
        disabled={isLoadingUpdateAudienceReviewersProjectAction}
        style={{
          width: '150px',
          marginTop: 8,
        }}
      >
        {isLoadingUpdateAudienceReviewersProjectAction ? (
          <LoadingOutlined />
        ) : (
          translate('project.audience.buttons.saveChanges')
        )}
      </Button>
    </Form>
  )
}

export default ReviewersAudienceProject
