const fr = {
  auth: {
    signingIn: "Vérification de l'état de connexion...",
    loading: 'Chargement...',
    signInButton: 'Se connecter',
    inactivityError: "Vous avez été déconnecté pour cause d'inactivité. Veuillez vous reconnecter",
  },
  notFoundPage:
    "La page n'est pas disponible pour le moment ou n'existe pas. Veuillez essayer de revenir en arrière et de choisir une autre direction.",
  notifications: {
    error: 'Oops...',
    success: 'Success!',
    requestIsProcessing: 'Votre demande est en cours de traitement',
  },
  dashboard: {
    tabs: {
      projects: 'Projets',
      workflows: 'Workflows',
    },
  },
  certificationsPage: {
    certificationsListHeaderTitle: 'Certifications',
  },

  segmentsPage: {
    table: {
      name: 'Nom',
      type: 'Type',
      rights: 'Mes droits',
      Respondents: 'Répondants',
      avgTime: 'Temps moyen',
      avgScore: 'Note moyenne',
      status: 'Status',
    },
  },

  projectsPage: {
    createButtonLabel: 'Nouveau projet',
    exportButtonLabel: 'Exporter',
    filtersButtonLabel: 'Filtrer',
    listHeaderTitle: 'Projets',
    listHeaderSubtitle: 'Manage your team members and their account permissions here.',
    boostPopover: 'Fin du boost dans',
    table: {
      name: 'Nom',
      state: 'Statut',
      segments: 'Segments',
      total: 'Total',
      inDirect: 'En direct',
      alerts: 'Alertes',
      actions: 'Actions',
    },
    dropdown: {
      open: 'Ouvrir',
      audience: 'Modifier l’audience',
      parameters: 'Modifier les paramètres',
      boost1: 'Boost 1 heure',
      boost2: 'Boost 6 heures',
      boost3: 'Boost 12 heures',
      stopBoost: 'Stopper le boost',
      duplicate: 'Dupliquer',
      stop: 'Arrêter le projet',
      archive: 'Archive',
    },
    modals: {
      create: {
        title: 'Ajouter un projet',
        subtitle: 'Créez une certification',
        label: 'Nom',
        cancelButton: 'Retour',
        submitButton: 'Suivant',
      },
      archive: {
        title: 'Archiver le projet',
        subtitle: 'Les tâches non effectuées seront annulées.',
        cancelButton: 'Annuler',
        submitButton: 'Confirmer',
      },
      stop: {
        title: 'Arrêter le projet',
        subtitle:
          'Êtes-vous sûr de vouloir supprimer ce message ? Cette action ne peut être annulée.',
        cancelButton: 'Annuler',
        submitButton: 'Arrêter',
      },
    },
  },

  projectCreatePage: {
    titles: {
      1: 'Paramètres de saisie',
      2: 'Réglages',
      3: 'Définition de l’audience',
      4: 'Ajouter des données',
    },

    descriptions: {
      1: 'Définissez comment sont complétées les tâches de votre projet',
      2: 'Définissez comment sont complétées les tâches de votre projet',
      3: 'Définissez comment sont complétées les tâches de votre projet',
      4: 'Voici les documents attendus par le formulaire que vous avez choisis',
    },

    collapse: {
      manualEntry: {
        title: 'Formulaire de saisie manuelle',
        subtitle: 'Choisissez un formulaire de saisie',
        button: 'Choisir dans la bibliothèque',
      },
      generative: {
        title: 'Allimenter mon Formulaire avec une Intelligence Artificielle Générative',
        subtitle: 'Intelligence Artificielle Générative sélectionnée',
      },
      artificialModel: {
        title: 'Modèle d’intelligence artificielle',
        content: {
          chooseForm: 'Choisissez un formulaire de saisie',
          modelInstructions:
            'En choisissant un modèle et un traitement complémentaire humain, vous pouvez paramétrer le taux de confiance à partir duquel le modèle enverra directement la réponse et le taux à partir duquel un humain vérifiera le pré-remplissage IA.',
          formFields: 'Champs du formulaire',
          modelFields: 'Champ du modèle',
          fieldLabel: 'Prénom',
        },
        button: 'Ajouter des modèles',
      },
      SLA: {
        title: 'SLA',
        subtitle: 'Lorsque ces engagements ne sont pas respecté, un breach est créé.',
      },
      processingTime: {
        title: 'Durée de traitement des demandes',
        checkbox: {
          exerciseTime: {
            title: 'Heure de levée et date butoir',
            subtitle:
              'Les tâches soumises avant l’heure de levée doivent être livrée dans un nombre de jour fixe.',
          },
          averageTime: {
            title: "Objectif de temps total moyen de réalisation complète d'un demande",
            subtitle: 'Chaque tâche doit être soumise dans un temps défini.',
          },
        },
        form: {
          timeOfLifting: 'Heure de levée',
          dayOfDelivery: 'Jour de livraison',
          cutOffTime: 'Heure limite',
          maxDeliveryTime: 'Délai maximum de livraison',
        },
        button: {
          addHour: 'Ajouter une heure de levée',
        },
      },
      objectiveQuality: {
        title: 'Objectif de qualité',
      },
      productivityTargets: {
        title: 'Objectifs interne de productivité',
        subtitle: 'Définissez vos objectifs pour ce projet',
      },
      applicationsPurpose: {
        title: 'Objectif des demandes',
        form: {
          deliveryTime: 'Délai moyen de livraison',
          processingTime: 'Temps de traitement effectif d’une demande',
        },
      },
      contributorsObjective: {
        title: 'Objectif des contributeurs',
        form: {
          timeOfContribution: 'Temps effectif d’une contribution',
          quality: 'Qualité',
        },
      },
      alerts: {
        title: 'Alertes',
        subtitle: 'Définissez vos alertes',
        form: {
          recepients: 'Destinataires',
        },
        toggles: {
          sms: 'SMS alert',
          email: 'Email alert',
        },
        selectValues: {
          none: 'Décocher',
          manager: 'Tous les managers',
          admin: 'Tous les administrateurs',
        },
      },
      parameters: {
        title: 'Paramètres',
        subtitle: 'Choisissez un formulaire de saisiez',
      },
      diversification: {
        title: 'Diversification des répondants',
        form: {
          diversification: 'Diversification des répondants',
          minQualityLevel: 'Seuil minimum de qualité',
          numContributions: 'Nombre de contribution évaluées',
        },
      },
      managers: {
        title: 'Managers',
      },
      webhook: {
        title: 'Webhook',
        subtitle: 'Choisissez un formulaire de saisie',
        copyButton: 'Copy',
      },
      audience: {
        title: {
          contributors: 'Contributeurs',
          reviewers: 'Relecteurs / Arbitres',
        },
        subtitle: {
          contributors: 'Définissez vos objectifs pour ce projet',
          reviewers: 'Définissez vos objectifs pour ce projet',
        },
        external: {
          title: {
            contributors: 'Contributeurs externes',
            reviewers: 'Relecteurs externes',
          },
          form: {
            title: {
              provider: 'Provider',
              segment: 'Segment',
              compensation: 'Rémunération',
            },
            radio: {
              type: {
                amazon: 'Amazon mTurk',
                yappers: 'Yappers',
              },
              earnings: {
                0: 'Economique',
                1: 'Median',
                2: 'Compétitif',
                priceSubtitle: 'centimes / tâche',
              },
            },
          },
        },
        internal: {
          title: {
            contributors: 'Contributeurs internes',
            reviewers: 'Relecteurs internes',
          },
        },
      },
      generalSettings: {
        title: 'Paramètres Généraux',
      },
      tasks: {
        title: 'Tâches',
        subtitle: 'Importez vos tâches au format CSV ou commencez à saisir dans le tableau',
      },
    },

    form: {
      label: {
        validationRate: 'Taux de validation',
        prefillRate: 'Taux de préremplissage',
        firstName: 'Prénom',
        lastName: 'Nom de famille',
        client: 'Client',
      },
      placeholder: {
        firstName: 'Prenom_Client',
        tableClient: 'Tableau_Client',
        clientName: 'Nom_Client',
      },
    },

    submitButton: {
      step1: 'Etape suivante',
    },

    modal: {
      models: {
        title: 'Tous les modèles',
      },
    },
  },

  settings: {
    tabs: {
      general: 'Généraux',
      apiKeys: 'Clés d’API',
      profile: 'Mon profil',
      changePassword: 'Changer de mot de passe',
    },
  },
}

export default fr
