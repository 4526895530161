import { memo } from 'react'
import { colors, IconAvatarGroup, ActionModal } from 'common-components'
import { useTranslation } from '@hooks'

import AssignSegment, { AssignSegmentPropsType } from './AssignSegment'

export type AssignSegmentModalPropsType = Pick<
  AssignSegmentPropsType,
  'onDeleteSegment' | 'onAddSegment' | 'isLoading' | 'segments'
> & {
  contributorsCount?: number
  close: () => void
  isOpen: boolean
}

const AssignSegmentModalComponent = ({
  segments,
  contributorsCount = 1,
  onDeleteSegment,
  isLoading,
  onAddSegment,
  close,
  isOpen,
}: AssignSegmentModalPropsType) => {
  const { translate } = useTranslation()
  return (
    <ActionModal
      maskClosable={false}
      open={isOpen}
      onOk={close}
      customHeader={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IconAvatarGroup
            avatars={Array.from({ length: contributorsCount }, () => ({
              size: 'md',
              icon: 'Announcement02',
              iconColor: colors.success700,
              borderColor: colors.white,
              iconBackground: colors.gray100,
            }))}
          />
          <div>{translate('managers.assignSegments')}</div>
        </div>
      }
      onConfirm={close}
      onCancel={close}
    >
      <AssignSegment
        isLoading={isLoading}
        segments={segments}
        onAddSegment={onAddSegment}
        onDeleteSegment={onDeleteSegment}
      />
    </ActionModal>
  )
}

const AssignSegmentModal = memo(
  AssignSegmentModalComponent,
  (oldProps: AssignSegmentModalPropsType, newProps: AssignSegmentModalPropsType) => {
    return JSON.stringify(oldProps) === JSON.stringify(newProps)
  },
)

export default AssignSegmentModal
