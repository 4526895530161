/** @jsxImportSource @emotion/react */
import { useTranslation } from '@hooks/useTranslation'
import { Title, Text, TextInput, FormElements, Space, colors } from 'common-components'
import { css } from '@emotion/react'
import { VerticalFormItem } from '@components/display'
import { useState, useEffect } from 'react'
import { Validations } from '@definitions/validations'

type WebhookPanelPropsType = {
  urlWebhook: string
}

const WebhookPanel = ({ urlWebhook }: WebhookPanelPropsType) => {
  const { translate } = useTranslation()
  const [text, setText] = useState<string>(urlWebhook)
  const form = FormElements.useFormInstance()

  useEffect(() => {
    if (form && urlWebhook) form.setFieldValue('urlWebhook', urlWebhook)
  }, [form, urlWebhook])

  return (
    <Space
      direction='vertical'
      style={{
        width: '100%',
        border: `1px solid ${colors.gray200}`,
        borderRadius: 16,
        padding: 24,
      }}
    >
      <Title level={5}>{translate('projectCreatePage.collapse.webhook.title')}</Title>
      <Text size='sm'>{translate('projectCreatePage.collapse.webhook.subtitle')}</Text>
      <VerticalFormItem
        name='urlWebhook'
        initialValue={urlWebhook}
        rules={[
          {
            pattern: Validations.pattern.link,
            message: Validations.message.link,
          },
        ]}
      >
        <TextInput
          value={text}
          onChange={(e) => setText(e.target.value)}
          css={css`
            .ant-input-group-addon {
              padding: 0;
            }
          `}
        />
      </VerticalFormItem>
    </Space>
  )
}

export default WebhookPanel
