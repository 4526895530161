/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router'
import { routes } from '@navigation/routes'
import { useTranslation } from '@hooks'
import { UserAccessLevel } from '@definitions/states'
import React, { useMemo, useRef, useState } from 'react'
import { ManagerShortType, SegmentType } from '@pages/types'
import { StateTagSegment } from '@views/segment/shared'
import {
  Button,
  Checkbox,
  Icon,
  WithModal,
  Row,
  Space,
  Table,
  Text,
  Title,
  Content,
  Avatar,
  AvatarGroup,
  CommonIconAvatar,
  colors,
  useDisclosure,
  AvatarIcon,
  AvatarIcons,
  ITableProps,
} from 'common-components'

import { AssignManagerModal } from '../manager/assign'

const ManagersWrapper = styled.div`
  display: flex;
`

const ModalIconWrapper = styled.div`
  background: #d1fadf;
  border: 8px solid #ecfdf3;
  border-radius: 28px;
  max-width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export type TableSegmentsPropsType = {
  segments: SegmentType[]
  setSelectedCheckboxes: (item: string[]) => void
  selectedCheckboxes: string[]
  tableProps?: Omit<ITableProps<SegmentType>, 'data' | 'columns'>
  isLoadingManagersMutation: boolean
  onAddManagerSegment: (idSegment: string, managersShort: ManagerShortType) => void
  onDeleteManagerSegment: (idSegment: string, manager: ManagerShortType) => void
  onAccessRequest?: (segmentId: string) => void
}

const getManagerUser = (
  segments: SegmentType[],
  idSegment: string,
): SegmentType['managersShort'] => {
  return segments.find((item) => item.idSegment === idSegment).managersShort
}

const SEGMENT_TYPE_CERTIFICATION = 30 as const

const TableSegments = ({
  segments,
  setSelectedCheckboxes,
  selectedCheckboxes,
  tableProps = {},
  isLoadingManagersMutation,
  onAddManagerSegment,
  onDeleteManagerSegment,
  onAccessRequest,
}: TableSegmentsPropsType) => {
  const [mainCheckbox, setMainCheckbox] = useState(false)
  const { translate } = useTranslation()
  const navigate = useNavigate()
  const segmentDisclosure = useDisclosure()
  const idSegmentClicked = useRef<string>()

  const addDisabledClass = (record: SegmentType) => {
    return !record.role ? 'disabled' : ''
  }

  const handleMainCheckboxClick = () => {
    setMainCheckbox(!mainCheckbox)
    if (mainCheckbox) {
      setSelectedCheckboxes([])
      return
    }
    const allIds: string[] = segments?.reduce((accu: string[], item) => {
      if (item.segmentType !== SEGMENT_TYPE_CERTIFICATION) {
        accu.push(item.idSegment)
      }
      return accu
    }, [])
    setSelectedCheckboxes(allIds)
  }

  const handleCheckboxClick = (id: string) => {
    if (selectedCheckboxes.includes(id)) {
      setSelectedCheckboxes(selectedCheckboxes.filter((checkboxId: string) => id !== checkboxId))
      return
    }
    setSelectedCheckboxes([...selectedCheckboxes, id])
  }

  const tableColumns = useMemo(() => {
    try {
      return [
        {
          title: (
            <Checkbox name='checkbox' checked={mainCheckbox} onClick={handleMainCheckboxClick} />
          ),
          key: 'checkbox',
          render: (record: SegmentType) =>
            record.segmentType !== SEGMENT_TYPE_CERTIFICATION && (
              <Checkbox
                name='checkbox'
                checked={selectedCheckboxes.includes(record.idSegment)}
                disabled={record.role === 0}
                onClick={() => handleCheckboxClick(record.idSegment)}
              />
            ),
        },
        {
          title: translate('segments.tableColumns.name'),
          key: 'name',
          sorter: (recordA: SegmentType, recordB: SegmentType) => {
            if (recordA.name < recordB.name) return -1
            if (recordA.name > recordB.name) return 1
            return 0
          },
          render: (record: SegmentType) => (
            <Row align={'middle'}>
              <Space style={{ columnGap: 12, rowGap: 12 }}>
                <AvatarIcon
                  icon={record?.icon?.iconName as AvatarIcons}
                  style={{ backgroundColor: record?.icon?.iconColor }}
                  size={'sm'}
                />
                <Text size='sm' strong>
                  {record.name}
                </Text>
              </Space>
            </Row>
          ),
        },
        {
          title: translate('segments.tableColumns.type'),
          key: 'type',
          sorter: (recordA: SegmentType, recordB: SegmentType) => {
            if (recordA.segmentType < recordB.segmentType) return -1
            if (recordA.segmentType > recordB.segmentType) return 1
            return 0
          },
          render: (record: SegmentType) => {
            return (
              <Row align={'middle'}>
                <Space size={'middle'}>
                  {record.segmentType && <StateTagSegment state={record.segmentType} />}
                </Space>
              </Row>
            )
          },
        },
        {
          title: translate('segments.tableColumns.privileges'),
          key: 'accessLevel',
          sorter: (recordA: SegmentType, recordB: SegmentType) => {
            if (recordA.role < recordB.role) return -1
            if (recordA.role > recordB.role) return 1
            return 0
          },
          render: (record: SegmentType) => {
            if (!record.role) {
              const WrappedButton: any = WithModal(
                Button,
                <Content>
                  <Title preset='tablePageTitle'>{translate('segments.requestModal.title')}</Title>
                  <Title preset='tablePageSubtitle'>
                    {translate('segments.requestModal.description')}
                  </Title>
                </Content>,
              )
              return (
                <Row align={'middle'}>
                  <WrappedButton
                    title={
                      <ModalIconWrapper>
                        <Icon width={24} height={24} icon='Key02' />
                      </ModalIconWrapper>
                    }
                    onConfirm={() => {
                      onAccessRequest && onAccessRequest(record.idSegment)
                    }}
                    id='modal'
                    size={'inline'}
                    okText={translate('segments.requestModal.ok')}
                    cancelText={translate('segments.requestModal.cancel')}
                  >
                    <div id='modal'>{translate('segments.request')}</div>
                  </WrappedButton>
                </Row>
              )
            }
            return (
              <Row align={'middle'}>
                <Text size='xs' style={{ textTransform: 'capitalize' }}>
                  {UserAccessLevel[record.role]}
                </Text>
              </Row>
            )
          },
        },
        {
          title: translate('segments.tableColumns.members'),
          key: 'members',
          sorter: (a: SegmentType, b: SegmentType) => {
            if (a?.contributorsShort.length < b.contributorsShort.length) return -1
            if (a?.contributorsShort.length > b.contributorsShort.length) return 1
            return 0
          },
          render: ({ contributorsShort }: SegmentType) => contributorsShort?.length,
        },
        {
          title: translate('segments.tableColumns.managers'),
          key: 'managers',
          render: (record: SegmentType) => (
            <ManagersWrapper>
              <AvatarGroup>
                {record.managersShort.map((man) => (
                  <Avatar src={man.urlAvatar} />
                ))}
              </AvatarGroup>
              {record.segmentType !== SEGMENT_TYPE_CERTIFICATION && (
                <CommonIconAvatar
                  // @ts-ignore
                  css={css`
                    margin-left: 10px;
                    cursor: pointer;
                    border: 1px dashed ${colors.gray300} !important;
                  `}
                  iconColor={colors.gray400}
                  onClick={() => {
                    idSegmentClicked.current = record.idSegment
                    segmentDisclosure.open()
                  }}
                  iconBackground={colors.white}
                  borderColor={colors.gray300}
                  icon={'Plus'}
                  name='add-manager'
                />
              )}
            </ManagersWrapper>
          ),
        },
      ]
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
      return []
    }
  }, [mainCheckbox, selectedCheckboxes, segments.length])

  const onRowClick = useMemo(
    () => (record: SegmentType) => ({
      onClick: (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
        if (
          !!record.role &&
          !['checkbox', 'add-manager'].includes((e.target as HTMLInputElement).name) &&
          !['path', 'svg'].includes((e.target as HTMLInputElement).tagName)
        ) {
          navigate(routes.segment.navigate(record.idSegment))
        }
      },
    }),
    [navigate],
  )

  return (
    <>
      <Table<SegmentType>
        data={segments}
        css={css`
          cursor: pointer;
        `}
        columns={tableColumns}
        rowClassName={addDisabledClass}
        onRow={onRowClick}
        {...tableProps}
      />
      {segmentDisclosure.isOpen && (
        <AssignManagerModal
          managersUser={getManagerUser(segments, idSegmentClicked.current)}
          onAddManager={(managersSegment) =>
            onAddManagerSegment(idSegmentClicked.current, managersSegment)
          }
          onDeleteManager={(idManager) =>
            onDeleteManagerSegment(idSegmentClicked.current, idManager)
          }
          isLoading={isLoadingManagersMutation}
          close={segmentDisclosure.close}
          isOpen={segmentDisclosure.isOpen}
        />
      )}
    </>
  )
}

export default TableSegments
