/** @jsxImportSource @emotion/react */
import { TAvailableRoutesTitles } from '@types'
import React, { useState } from 'react'
import { useNavigationEntries, useTranslation } from '@hooks'
import styled from '@emotion/styled'
import { colors, Icon, IconButton, Logo, Row, Space, Title } from 'common-components'
import { Popover } from 'antd'

import { ProfileDropdown } from './ProfileDropdown'
import { NavEntriesList } from './NavEntriesList'

const Container = styled(Row)`
  width: 100%;
  max-width: 1235px;
  margin: 0 auto;
  padding: 1em;
`
const SettingsSection = styled.div``

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.6em;
  padding: 32px 40px 32px 40px;
  background: #fff;
  border-radius: 16px;
  max-width: 1235px;
  margin: auto;
  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
    padding: 1em;
  }
  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 0.6em;
  }
`

export const NavBar: React.FC = () => {
  // TODO: Entry point for current route
  const [selectedRoute] = useState<TAvailableRoutesTitles>('dashboard')

  const { translate } = useTranslation()

  const { currentEntry, defaultEntry, firstColumn, secondColumn, thirdColumn, fourthColumn } =
    useNavigationEntries()

  const content = (
    <Wrapper>
      <NavEntriesList
        heading={translate('navigation.dropdownPanel.headings.analyser')}
        entries={firstColumn.entries}
        selectedRoute={selectedRoute}
        iconColor={firstColumn.config.iconColor}
      />
      <NavEntriesList
        heading={translate('navigation.dropdownPanel.headings.mobiliser')}
        entries={secondColumn.entries}
        selectedRoute={selectedRoute}
        iconColor={secondColumn.config.iconColor}
      />
      <NavEntriesList
        heading={translate('navigation.dropdownPanel.headings.launchpad')}
        entries={thirdColumn.entries}
        selectedRoute={selectedRoute}
        iconColor={thirdColumn.config.iconColor}
      />
      <NavEntriesList
        heading={translate('navigation.dropdownPanel.headings.settings')}
        entries={fourthColumn.entries}
        selectedRoute={selectedRoute}
        iconColor={fourthColumn.config.iconColor}
      />
    </Wrapper>
  )

  return (
    <Container justify={'space-between'}>
      <Space size={25} align={'center'}>
        <Logo preview={false} width={82} />
        <Popover content={content}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              gap: 12,
            }}
          >
            <IconButton
              size='sm'
              icon={(currentEntry || defaultEntry).icon}
              iconProps={{
                color: (currentEntry || defaultEntry).iconColor,
                width: 24,
                height: 24,
              }}
              style={{ borderColor: colors.gray100, padding: 8 }}
            />
            <Title level={5}>{(currentEntry || defaultEntry).title}</Title>
            <Icon icon={'ChevronDown'} color={colors.gray500} size={20} />
          </div>
        </Popover>
      </Space>
      <SettingsSection>
        <ProfileDropdown />
      </SettingsSection>
    </Container>
  )
}
