/** @jsxImportSource @emotion/react */

import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { css } from '@emotion/react'
import { GetModelsPayloadType } from '@api'
import { ModelCategory, ModelState } from '@definitions'
import { CommonFilters, StateCheckbox } from '@components/inputs'
import { useTranslation } from '@hooks'
import { Button, Checkbox, Drawer, Form, FormItem, Space, Text, Title } from 'common-components'
import { SelectManagers } from '@views/shared/manager'

type FiltersModelPropsType = {
  visible: boolean
  onFiltersChange?: (filters: GetModelsPayloadType) => void
  onClose?: () => void
  initValues?: GetModelsPayloadType
}

const FiltersModel = ({ visible, onClose, onFiltersChange, initValues }: FiltersModelPropsType) => {
  const { control, handleSubmit, reset } = useForm<GetModelsPayloadType>({
    defaultValues: { model: {}, ...(initValues || {}) },
  })
  const { translate } = useTranslation()

  useEffect(() => {
    reset(initValues)
  }, [initValues])

  const onSubmit = (data: GetModelsPayloadType) => {
    onFiltersChange && onFiltersChange(data)
    onClose && onClose()
  }

  return (
    <Drawer
      title={
        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <Title preset='tablePageTitle'>{translate('common.filter')}</Title>
          <Text>{translate('common.apply')}</Text>
        </div>
      }
      placement='right'
      closable={true}
      onClose={onClose}
      closeIcon={false}
      open={visible}
    >
      <Form onFinish={handleSubmit(onSubmit)} css={$form}>
        <CommonFilters<GetModelsPayloadType> control={control} />

        <Controller
          name={'model.allUsers'}
          control={control}
          render={({ field }) => (
            <FormItem>
              <Space>
                <Checkbox
                  onChange={(ev) =>
                    field.onChange({
                      ...ev,
                      target: { ...ev.target, value: ev.target.checked },
                    })
                  }
                  checked={field.value}
                />
                <Text>{translate('model.allModels')}</Text>
              </Space>
            </FormItem>
          )}
        />

        <Controller
          name={'model.state'}
          control={control}
          render={({ field }) => (
            <FormItem label={translate('common.state')}>
              <StateCheckbox
                values={ModelState}
                labelTxKey={'modelState'}
                preset={'model-state'}
                inputProps={{
                  onChange: field.onChange,
                  value: field.value,
                }}
              />
            </FormItem>
          )}
        />

        <Controller
          name={'model.category'}
          control={control}
          render={({ field }) => (
            <FormItem label={translate('common.category')}>
              <StateCheckbox
                values={ModelCategory}
                labelTxKey={'modelCategory'}
                preset={'model-category'}
                inputProps={{
                  onChange: field.onChange,
                  value: field.value,
                }}
              />
            </FormItem>
          )}
        />

        <Controller
          name={'model.idUser'}
          control={control}
          render={({ field }) => (
            <FormItem label={translate('common.managers')}>
              <SelectManagers onChange={field.onChange} value={field.value} />
            </FormItem>
          )}
        />

        <Space css={[$fullWidth, $footerButtonsContainer]} size={10}>
          <Button onClick={() => reset()}>Cancel</Button>
          <Button type='primary' htmlType='submit'>
            Apply
          </Button>
        </Space>
      </Form>
    </Drawer>
  )
}

const $form = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const $fullWidth = css`
  width: 100%;
`

const $footerButtonsContainer = css`
  justify-content: center;
`

export default FiltersModel
