import { PresetTag } from '@components/display'
import { UseTranslationOptions } from 'react-i18next'
import { useTranslation } from '@hooks'
import { getRiskLevelValueKey } from '@utils/getRiskLevelValueKey'
import { TRiskLevelState } from '@definitions'
import { useNavigate } from 'react-router'
import { useContext } from 'react'
import {
  Button,
  ButtonGroup,
  colors,
  Icon,
  LayoutContainer,
  Paragraph,
  Row,
  Space,
} from 'common-components'

import { RiskContext } from '@/context'

type RiskTopBarPropsType = {
  docName?: string
  riskScore?: number
  riskLevel?: TRiskLevelState
}
const TopBarRisk = ({ docName, riskLevel, riskScore }: RiskTopBarPropsType) => {
  const { translate } = useTranslation()
  const navigate = useNavigate()

  const { currentPage, onPageChange, isFirstPage, isLastPage } = useContext(RiskContext)

  return (
    <LayoutContainer
      style={{
        padding: 18,
        borderBottom: `1px solid ${colors.gray200}`,
        flex: 'unset',
      }}
    >
      <Row style={{ width: '100%' }} justify={'space-between'}>
        <Space size={10} align={'center'}>
          <Button size='sm' onClick={() => navigate(-1)}>
            <Icon icon='FlipBackward' size={20} />
          </Button>
          <Paragraph size={'lg'} color={colors.gray900} style={{ marginBottom: 0 }}>
            {translate(`risk.riskOverviewTitle`, {
              name: docName,
            } as UseTranslationOptions)}
          </Paragraph>
          <PresetTag preset='risk-level' state={riskLevel}>
            {translate(`risk.levelStates.${getRiskLevelValueKey(riskLevel)}`)}
          </PresetTag>
          <PresetTag preset='risk-score' state={riskScore}>
            {riskScore ? `${riskScore}%` : '-'}
          </PresetTag>
        </Space>
        <Space size={10} align={'center'}>
          <ButtonGroup className='ant-btn-group'>
            <Button
              onClick={() => onPageChange(currentPage - 1)}
              disabled={isFirstPage}
              iconLeft={'ChevronLeft'}
            />
            <Button
              onClick={() => onPageChange(currentPage + 1)}
              disabled={isLastPage}
              iconLeft={'ChevronRight'}
            />
          </ButtonGroup>
        </Space>
      </Row>
    </LayoutContainer>
  )
}

export default TopBarRisk
