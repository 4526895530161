// mimic the enum-like behaviour

/**
 * TODO "Clean up the file 'src/definitions/states.ts' to reuse keys and values properly"
 */

/** PREFILL QUESTION TYPE **/
const PREFILL_CERTIFICATION_TYPE = [1] as const
const LABEL_PREFILL_CERTIFICATION_TYPE = ['manuel'] as const
export type PrefillCertificationType = (typeof PREFILL_CERTIFICATION_TYPE)[number]
export const PrefillCertificationMergeType = {
  [PREFILL_CERTIFICATION_TYPE[0]]: LABEL_PREFILL_CERTIFICATION_TYPE[0],
}

/** PREFILL TYPE **/

const PREFILL_TYPE = [0, 1, 2, 3, 4] as const
const LABEL_PREFILL_TYPE = ['none', 'iagenerative', 'model', 'manuel', 'gpt'] as const
export type PrefillType = (typeof PREFILL_TYPE)[number]
export const PrefillMergeType = {
  [PREFILL_TYPE[0]]: LABEL_PREFILL_TYPE[0],
  [PREFILL_TYPE[1]]: LABEL_PREFILL_TYPE[1],
  [PREFILL_TYPE[2]]: LABEL_PREFILL_TYPE[2],
  [PREFILL_TYPE[3]]: LABEL_PREFILL_TYPE[3],
  [PREFILL_TYPE[4]]: LABEL_PREFILL_TYPE[4],
}

export const AlertType = {
  0: 'none',
  10: 'manager',
  20: 'admin',
}

/** COLUMN TYPE **/

const COLUMN_TYPE = [
  10, 20, 30, 40, 41, 42, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 175, 180, 185,
  190, 200, 201, 210, 211, 220, 230, 240, 250, 260,
] as const
// type ColumnType = (typeof COLUMN_TYPE)[number]
const LABEL_COLUMN_TYPE = [
  'text_short',
  'text',
  'phonenumber',
  'coordinates_latitude',
  'coordinates_longitude',
  'coordinates_gps',
  'datetime',
  'email',
  'number',
  'url',
  'country',
  'mcq',
  'select',
  'address',
  'data_video',
  'data_audio',
  'data_image',
  'data_url',
  'data_text',
  'data_numeric',
  'data_embed',
  'data_iframe',
  'block',
  'multiselect',
  'multiselect_item',
  'multimcq',
  'multimcq_item',
  'file',
  'input_json',
  'api_select',
  'api_multiselect',
  'rib',
] as const
export type LabelColumnType = (typeof LABEL_COLUMN_TYPE)[number]

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const AppColumnType = {
  [COLUMN_TYPE[0]]: LABEL_COLUMN_TYPE[0],
  [COLUMN_TYPE[1]]: LABEL_COLUMN_TYPE[1],
  [COLUMN_TYPE[2]]: LABEL_COLUMN_TYPE[2],
  [COLUMN_TYPE[3]]: LABEL_COLUMN_TYPE[3],
  [COLUMN_TYPE[4]]: LABEL_COLUMN_TYPE[4],
  [COLUMN_TYPE[5]]: LABEL_COLUMN_TYPE[5],
  [COLUMN_TYPE[6]]: LABEL_COLUMN_TYPE[6],
  [COLUMN_TYPE[7]]: LABEL_COLUMN_TYPE[7],
  [COLUMN_TYPE[8]]: LABEL_COLUMN_TYPE[8],
  [COLUMN_TYPE[9]]: LABEL_COLUMN_TYPE[9],
  [COLUMN_TYPE[10]]: LABEL_COLUMN_TYPE[10],
  [COLUMN_TYPE[11]]: LABEL_COLUMN_TYPE[11],
  [COLUMN_TYPE[12]]: LABEL_COLUMN_TYPE[12],
  [COLUMN_TYPE[13]]: LABEL_COLUMN_TYPE[13],
  [COLUMN_TYPE[14]]: LABEL_COLUMN_TYPE[14],
  [COLUMN_TYPE[15]]: LABEL_COLUMN_TYPE[15],
  [COLUMN_TYPE[16]]: LABEL_COLUMN_TYPE[16],
  [COLUMN_TYPE[17]]: LABEL_COLUMN_TYPE[17],
  [COLUMN_TYPE[18]]: LABEL_COLUMN_TYPE[18],
  [COLUMN_TYPE[19]]: LABEL_COLUMN_TYPE[19],
  [COLUMN_TYPE[20]]: LABEL_COLUMN_TYPE[20],
  [COLUMN_TYPE[21]]: LABEL_COLUMN_TYPE[21],
  [COLUMN_TYPE[22]]: LABEL_COLUMN_TYPE[22],
  [COLUMN_TYPE[23]]: LABEL_COLUMN_TYPE[23],
  [COLUMN_TYPE[24]]: LABEL_COLUMN_TYPE[24],
  [COLUMN_TYPE[25]]: LABEL_COLUMN_TYPE[25],
  [COLUMN_TYPE[26]]: LABEL_COLUMN_TYPE[26],
  [COLUMN_TYPE[27]]: LABEL_COLUMN_TYPE[27],
  [COLUMN_TYPE[28]]: LABEL_COLUMN_TYPE[28],
  [COLUMN_TYPE[29]]: LABEL_COLUMN_TYPE[29],
  [COLUMN_TYPE[30]]: LABEL_COLUMN_TYPE[30],
  [COLUMN_TYPE[31]]: LABEL_COLUMN_TYPE[31],
}
export const CertificationAudienceType = {
  10: 'Internal',
  11: 'Candidate',
  20: 'External',
}

/** CERTIFICATION STATE **/
// The draft state for a certification
// 1: "extractionParameter",
// 2: "settingsDefinition",
// 3: "addData",
// 4: "addAnswers"
export const STATE_CERTIFICATION_TYPE = [1, 2, 3, 4, 10, 20, 100] as const
export const LABEL_STATE_CERTIFICATION_TYPE = [
  'extractionParameter',
  'settingsDefinition',
  'addData',
  'addAnswers',
  'online',
  'offline',
  'archived',
] as const
export const CertificationState = {
  [STATE_CERTIFICATION_TYPE[0]]: LABEL_STATE_CERTIFICATION_TYPE[0],
  [STATE_CERTIFICATION_TYPE[1]]: LABEL_STATE_CERTIFICATION_TYPE[1],
  [STATE_CERTIFICATION_TYPE[2]]: LABEL_STATE_CERTIFICATION_TYPE[2],
  [STATE_CERTIFICATION_TYPE[3]]: LABEL_STATE_CERTIFICATION_TYPE[3],
  [STATE_CERTIFICATION_TYPE[4]]: LABEL_STATE_CERTIFICATION_TYPE[4],
  [STATE_CERTIFICATION_TYPE[5]]: LABEL_STATE_CERTIFICATION_TYPE[5],
  [STATE_CERTIFICATION_TYPE[6]]: LABEL_STATE_CERTIFICATION_TYPE[6],
}

export const DocumentState = {
  10: 'creation',
  20: 'ocr',
  30: 'done',
  100: 'error',
}
export const FormState = {
  10: 'creating',
  20: 'published',
  99: 'archived',
}
export const ProjectState = {
  1: 'extractionParameter',
  2: 'settings',
  3: 'audienceDefinition',
  4: 'addData',
  10: 'online',
  15: 'boosted',
  20: 'done',
  30: 'archived',
  40: 'stop',
  50: 'paused',
}
export const CrowdType = {
  10: 'internal',
  11: 'candidates',
  20: 'external',
}
export const Frequency = {
  10: 'few',
  20: 'often',
}
export const ModelCategory = {
  10: 'owned',
  100: 'wirk',
}

const STATE_MODEL_TYPE = [10, 20, 30, 100, 999] as const
const LABEL_STATE_MODEL_TYPE = ['create', 'train', 'online', 'error', 'archived'] as const

export const ModelState = {
  [STATE_MODEL_TYPE[0]]: LABEL_STATE_MODEL_TYPE[0],
  [STATE_MODEL_TYPE[1]]: LABEL_STATE_MODEL_TYPE[1],
  [STATE_MODEL_TYPE[2]]: LABEL_STATE_MODEL_TYPE[2],
  [STATE_MODEL_TYPE[3]]: LABEL_STATE_MODEL_TYPE[3],
  [STATE_MODEL_TYPE[4]]: LABEL_STATE_MODEL_TYPE[4],
}
export const Quality = {
  10: 'simple',
  20: 'multi',
  30: 'panel',
  40: 'relecture',
}
export const QuestionState = {
  1: 'draft',
  10: 'published',
  20: 'deleted',
}
export const ResourceType = {
  1: 'string',
  10: 'number',
}

/** SEGMENT Type **/
const SEGMENT_TYPE = [10, 20, 30, 40] as const
const LABEL_SEGMENT_TYPE = ['manual', 'auto', 'certification', 'sociodemographic'] as const
export type SegmentTypeType = (typeof SEGMENT_TYPE)[number]
// type LabelSegmentTypeType = (typeof LABEL_SEGMENT_TYPE)[number]
export const SegmentDefType = {
  [SEGMENT_TYPE[0]]: LABEL_SEGMENT_TYPE[0],
  [SEGMENT_TYPE[1]]: LABEL_SEGMENT_TYPE[1],
  [SEGMENT_TYPE[2]]: LABEL_SEGMENT_TYPE[2],
  [SEGMENT_TYPE[3]]: LABEL_SEGMENT_TYPE[3],
}
export const SkillType = {
  0: 'Simple Data Entry',
  1: 'Complex Data Entry',
  2: 'Simple Document Analysis',
  3: 'Complex Document Analysis',
  4: 'Folder Analysis',
  5: 'Content Redaction',
  6: 'Web Search',
  7: 'Bounding',
  8: 'Transcription',
  9: 'Outgoing Call',
  10: 'Incoming Call',
  11: 'Office Automation',
}

export const SociodemographicCriteria = {
  0: 'age',
  1: 'country',
  2: 'department',
  3: 'region',
  4: 'nationality',
  5: 'gender',
  6: 'seniority',
  7: 'activity',
  8: 'profession',
  9: 'children',
  10: 'maritalStatus',
  11: 'earnings',
  12: 'education',
}
export const UserActivityState = {
  1: 'active',
  2: 'waitingActivation',
  3: 'archived',
}
export const UserCertificationState = {
  0: 'new',
  10: 'inProgress',
  20: 'done',
  30: 'reviewed',
}
/** MANAGER RIGHT **/
export const ID_MANAGER_RIGHT = [0, 10, 20, 30] as const
export const LABEL_MANAGER_RIGHT = ['noAccess', 'creator', 'user', 'admin'] as const
export type IdManagerRightType = (typeof ID_MANAGER_RIGHT)[number]
export type LabelManagerRightType = (typeof LABEL_MANAGER_RIGHT)[number]
export const UserAccessLevel: Record<IdManagerRightType, LabelManagerRightType> = {
  [ID_MANAGER_RIGHT[0]]: LABEL_MANAGER_RIGHT[0],
  [ID_MANAGER_RIGHT[1]]: LABEL_MANAGER_RIGHT[1],
  [ID_MANAGER_RIGHT[2]]: LABEL_MANAGER_RIGHT[2],
  [ID_MANAGER_RIGHT[3]]: LABEL_MANAGER_RIGHT[3],
}
/** MANAGER Type (Role) **/
const ID_MANAGER_TYPE = [0, 10, 20] as const
const LABEL_MANAGER_TYPE = ['superAdmin', 'admin', 'manager'] as const
export type IdManagerTypeType = (typeof ID_MANAGER_TYPE)[number]
type LabelManagerTypeType = (typeof LABEL_MANAGER_TYPE)[number]
export const ManagerTypeType: Record<IdManagerTypeType, LabelManagerTypeType> = {
  [ID_MANAGER_TYPE[0]]: LABEL_MANAGER_TYPE[0],
  [ID_MANAGER_TYPE[1]]: LABEL_MANAGER_TYPE[1],
  [ID_MANAGER_TYPE[2]]: LABEL_MANAGER_TYPE[2],
}
/** CONTRIBUTOR Type (Role) **/
const ID_CONTRIBUTOR_TYPE = [30, 40] as const
const LABEL_CONTRIBUTOR_TYPE = ['worker', 'candidate'] as const
export type IdContributorTypeType = (typeof ID_CONTRIBUTOR_TYPE)[number]
type LabelContributorTypeType = (typeof LABEL_CONTRIBUTOR_TYPE)[number]
export const ContributorTypeType: Record<IdContributorTypeType, LabelContributorTypeType> = {
  [ID_CONTRIBUTOR_TYPE[0]]: LABEL_CONTRIBUTOR_TYPE[0],
  [ID_CONTRIBUTOR_TYPE[1]]: LABEL_CONTRIBUTOR_TYPE[1],
}
/** USER TYPE **/
export type TUserType = 0 | 10 | 20 | 30 | 40
export type RoleUserType = 'superAdmin' | 'admin' | 'manager' | 'worker' | 'candidate'
export const UserType: Record<TUserType, RoleUserType> = {
  0: 'superAdmin',
  10: 'admin',
  20: 'manager',
  30: 'worker',
  40: 'candidate',
}
export const WorkflowType = {
  10: 'active',
  20: 'manual',
}
export const UserStatus = {
  0: 'active',
  1: 'inactive',
  2: 'waiting',
}

export const TaskOriginType: Record<number, string> = {
  1: 'Web',
  2: 'Api',
  3: 'Auto',
}

export const TaskStateType = {
  10: 'inCreation',
  20: 'onHold',
  30: 'inProgress',
  80: 'urgent',
  90: 'late',
  100: 'completed',
  900: 'cancelled',
  999: 'error',
}
export const Event = {
  //TASK EVENTS
  100: 'TaskCreation',
  101: 'ModelExtraction',
  102: 'CreationInitialHit',
  103: 'HitComparison',
  104: 'CreationAdditionnalHit',
  105: 'CreationReviewHit',
  106: 'CreationOverrideHit',
  107: 'TaskDone',

  // HIT EVENTS
  200: 'HitCreation',
  201: 'WorkingHit',
  202: 'ManualReleaseHit',
  203: 'AutoReleaseHit',
  204: 'HitAttributionAuto',
  205: 'HitAttributionManual',
  206: 'HitReview',
  207: 'DoneHit',

  // DOCUMENT EVENTS
  300: 'DocumentCreation',
  301: 'DocumentModelEvalutation',
  302: 'DocumentExtraction',
  303: 'DocumentExtractionResult',
}

export const HitType = {
  1: 'initial',
  2: 'iteration',
  3: 'arbitrage',
  4: 'review',
  5: 'override',
}

export const HitStateType = {
  1: 'inCreation',
  2: 'onHold',
  3: 'inProgress',
  4: 'completed',
  5: 'validated',
  6: 'refused',
  100: 'cancelled',
}

export const AudienceCombinationType = {
  10: 'and',
  20: 'or',
}

export const AudienceType = {
  0: 'Internal',
  10: 'Yappers',
  20: 'MTurk',
}

export const EarningsType = {}

export const ModelAnnotationType = {
  10: 'Text',
  11: 'Email',
  12: 'Address',
  40: 'Digital',
  70: 'Date',
  71: 'DateTime',
  72: 'Time',
}

export const SlaType = {
  10: 'Leverage',
  20: 'Delay',
}

export const RiskState = {
  0: 'new',
  10: 'inProgress',
  20: 'done',
  90: 'error',
  99: 'archived',
}

export const RiskLevelState = {
  10: 'low',
  20: 'medium',
  30: 'high',
}

// export const FormTreeChildNodeType = {
// 	// TODO: find real keys
// 	10: "field_node",
// 	20: "block_node",
// 	30: "root",
// }

export type TAlertType = keyof typeof AlertType
export type TCertificationState = keyof typeof CertificationState
export type TDocumentState = keyof typeof DocumentState
export type TFormState = keyof typeof FormState
export type TProjectState = keyof typeof ProjectState
export type TFrequency = keyof typeof Frequency
export type TModelCategory = keyof typeof ModelCategory
export type TModelState = keyof typeof ModelState
export type TQuality = keyof typeof Quality
export type TQualityValues = (typeof Quality)[TQuality]
export type TQuestionState = keyof typeof QuestionState
export type TResourceType = keyof typeof ResourceType
export type TSegmentDefType = keyof typeof SegmentDefType
export type TSkillType = keyof typeof SkillType
export type TSociodemographicCriteria = keyof typeof SociodemographicCriteria
export type TSociodemographicCriteriaValues =
  | 'age'
  | 'country'
  | 'department'
  | 'region'
  | 'nationality'
  | 'gender'
  | 'seniority'
  | 'activity'
  | 'profession'
  | 'children'
  | 'maritalStatus'
  | 'earnings'
  | 'education'
export type TUserActivityState = keyof typeof UserActivityState
export type TUserCertificationState = keyof typeof UserCertificationState
export type TWorkflowType = keyof typeof WorkflowType
export type TUserStatusState = keyof typeof UserStatus
export type TTaskOrigin = keyof typeof TaskOriginType
export type TTaskState = keyof typeof TaskStateType
export type TEvent = keyof typeof Event
export type THitState = keyof typeof HitStateType
export type THitType = keyof typeof HitType
export type TAudienceCombinationType = keyof typeof AudienceCombinationType
export type TAudienceType = keyof typeof AudienceType
export type TModelAnnotationType = keyof typeof ModelAnnotationType
export type TSlaType = keyof typeof SlaType
export type TRiskState = keyof typeof RiskState
export type TRiskLevelState = keyof typeof RiskLevelState
