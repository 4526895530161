import {
  DocumentType,
  DocumentLogType,
  HitLogType,
  HitType,
  ProjectType,
  TaskLogType,
  TaskType,
} from '@pages/types'
import { TProjectState } from '@definitions'

import { ImmerStateCreator, StatusAction } from '../store.type'

import {
  addManagersToProjectAction,
  AddManagersToProjectActionPayloadType,
  archiveProjectAction,
  ArchiveProjectActionPayloadType,
  attachTasksToProjectAction,
  AttachTasksToProjectActionPayloadType,
  boostProjectAction,
  BoostProjectActionPayloadType,
  createProjectAction,
  CreateProjectActionPayloadType,
  downloadProjectsFileAction,
  getProjectAction,
  GetProjectActionPayloadType,
  getProjectsAction,
  GetProjectsActionPayloadType,
  stopBoostProjectAction,
  StopBoostProjectActionPayloadType,
  stopProjectAction,
  StopProjectActionPayloadType,
  updateAudienceProjectAction,
  UpdateAudienceProjectActionPayloadType,
  updateAudienceReviewersProjectAction,
  UpdateAudienceReviewersProjectActionPayloadType,
  updateFormProjectAction,
  UpdateFormProjectActionPayloadType,
  updateNextStepProjectAction,
  UpdateNextStepProjectActionPayloadType,
  updatePrefillProjectAction,
  UpdatePrefillProjectActionPayloadType,
  updateSettingsProjectAction,
  UpdateSettingsProjectActionPayloadType,
} from './project.action'
import {
  createDraftTaskAction,
  CreateDraftTaskActionPayloadType,
  decreasePriorityTaskAction,
  DecreasePriorityTaskActionPayloadType,
  deleteDraftTaskAction,
  DeleteDraftTaskActionPayloadType,
  deleteTaskAction,
  DeleteTaskActionPayloadType,
  getDraftTasksAction,
  GetDraftTasksActionPayloadType,
  getTaskAction,
  GetTaskActionPayloadType,
  getTaskLogsAction,
  GetTaskLogsActionPayloadType,
  getTasksAction,
  GetTasksActionPayloadType,
  increasePriorityTaskAction,
  IncreasePriorityTaskActionPayloadType,
  updateDraftTaskAction,
  UpdateDraftTaskActionPayloadType,
  uploadColumnFileTasksProjectAction,
  UploadColumnFileTasksProjectActionPayloadType,
  uploadTasksFileProjectAction,
  UploadTasksFileProjectActionPayloadType,
} from './task.action'
import {
  getHitAction,
  GetHitActionPayloadType,
  getHitLogsAction,
  GetHitLogsActionPayloadType,
  getHitReviewAction,
  GetHitReviewActionPayloadType,
  getHitsAction,
  GetHitsActionPayloadType,
  createAdditionalHitAction,
  CreateAdditionalHitActionPayloadType,
  createOverrideHitAction,
  CreateOverrideHitActionPayloadType,
  DeleteHitActionPayloadType,
  deleteHitAction,
  assignHitAction,
  AssignHitActionPayloadType,
  updateMarkHitAction,
  UpdateMarkHitActionPayloadType,
} from './hit.action'
import { getDocumentLogsAction, GetDocumentLogsActionPayloadType } from './model.action'

/** StatusAction
 *
----------------------------- Lifecycle ----------------------------------
  IDLE => LOADING => SUCCEEDED or FAILED
 *
 */

export type ProjectSliceType = {
  /** selectors **/
  projects: ProjectType[]
  tasksFiltered: TaskType[]
  tasksDraft: TaskType[]
  hits: HitType[]
  hitReview: HitType
  hitLogs: HitLogType[]
  taskLogs: TaskLogType[]
  documentLogs: DocumentLogType[]
  document: DocumentType
  /** actions **/
  /** PROJECT **/
  createProjectAction: {
    call: (payload: CreateProjectActionPayloadType, callback: (idProject: string) => void) => void
    status: StatusAction
  }
  boostProjectAction: {
    call: (payload: BoostProjectActionPayloadType, callback?: () => void) => void
    status: StatusAction
  }
  stopBoostProjectAction: {
    call: (payload: StopBoostProjectActionPayloadType, callback?: () => void) => void
    status: StatusAction
  }
  archiveProjectAction: {
    call: (payload: ArchiveProjectActionPayloadType, callback?: () => void) => void
    status: StatusAction
  }
  stopProjectAction: {
    call: (payload: StopProjectActionPayloadType, callback?: () => void) => void
    status: StatusAction
  }
  getProjectsAction: {
    call: (payload: GetProjectsActionPayloadType) => void
    status: StatusAction
  }
  getProjectAction: {
    call: (payload: GetProjectActionPayloadType) => void
    status: StatusAction
  }
  updateNextStepProjectAction: {
    call: (
      payload: UpdateNextStepProjectActionPayloadType,
      callback: (nextStep: TProjectState) => void,
    ) => void
    status: StatusAction
  }
  updateFormProjectAction: {
    call: (payload: UpdateFormProjectActionPayloadType, callback: () => void) => void
    status: StatusAction
  }
  updatePrefillProjectAction: {
    call: (payload: UpdatePrefillProjectActionPayloadType, callback: () => void) => void
    status: StatusAction
  }
  updateSettingsProjectAction: {
    call: (payload: UpdateSettingsProjectActionPayloadType, callback: () => void) => void
    status: StatusAction
  }
  addManagersToProjectAction: {
    call: (payload: AddManagersToProjectActionPayloadType, callback?: () => void) => void
    status: StatusAction
  }
  updateAudienceProjectAction: {
    call: (payload: UpdateAudienceProjectActionPayloadType, callback?: () => void) => void
    status: StatusAction
  }
  updateAudienceReviewersProjectAction: {
    call: (payload: UpdateAudienceReviewersProjectActionPayloadType, callback?: () => void) => void
    status: StatusAction
  }
  attachTasksToProjectAction: {
    call: (payload: AttachTasksToProjectActionPayloadType, callback: () => void) => void
    status: StatusAction
  }
  uploadTasksFileProjectAction: {
    call: (payload: UploadTasksFileProjectActionPayloadType, callback: () => void) => void
    status: StatusAction
  }
  downloadProjectsFileAction: {
    call: () => void
    status: StatusAction
  }
  /** TASK **/
  getTasksAction: {
    call: (payload: GetTasksActionPayloadType) => void
    status: StatusAction
  }
  getTaskAction: {
    call: (payload: GetTaskActionPayloadType, callback?: () => void) => void
    status: StatusAction
  }
  getTaskLogsAction: {
    call: (payload: GetTaskLogsActionPayloadType, callback?: () => void) => void
    status: StatusAction
  }
  getDraftTasksAction: {
    call: (payload: GetDraftTasksActionPayloadType, callback?: () => void) => void
    status: StatusAction
  }
  updateDraftTaskAction: {
    call: (payload: UpdateDraftTaskActionPayloadType) => void
    status: StatusAction
  }
  createDraftTaskAction: {
    call: (
      payload: CreateDraftTaskActionPayloadType,
      callback: (taskDraft: TaskType) => void,
    ) => void
    status: StatusAction
  }
  deleteDraftTaskAction: {
    call: (payload: DeleteDraftTaskActionPayloadType) => void
    status: StatusAction
  }
  uploadColumnFileTasksProjectAction: {
    call: (payload: UploadColumnFileTasksProjectActionPayloadType, callback: () => void) => void
    status: StatusAction
  }
  deleteTaskAction: {
    call: (payload: DeleteTaskActionPayloadType, callback?: () => void) => void
    status: StatusAction
  }
  increasePriorityTaskAction: {
    call: (payload: IncreasePriorityTaskActionPayloadType, callback?: () => void) => void
    status: StatusAction
  }
  decreasePriorityTaskAction: {
    call: (payload: DecreasePriorityTaskActionPayloadType) => void
    status: StatusAction
  }
  createAdditionalHitAction: {
    call: (payload: CreateAdditionalHitActionPayloadType, callback: () => void) => void
    status: StatusAction
  }
  createOverrideHitAction: {
    call: (payload: CreateOverrideHitActionPayloadType, callback: () => void) => void
    status: StatusAction
  }
  /** HIT **/
  getHitsAction: {
    call: (payload: GetHitsActionPayloadType, callback?: () => void) => void
    status: StatusAction
  }
  getHitAction: {
    call: (payload: GetHitActionPayloadType, callback?: () => void) => void
    status: StatusAction
  }
  getHitLogsAction: {
    call: (payload: GetHitLogsActionPayloadType) => void
    status: StatusAction
  }
  getHitReviewAction: {
    call: (payload: GetHitReviewActionPayloadType) => void
    status: StatusAction
  }
  deleteHitAction: {
    call: (payload: DeleteHitActionPayloadType, callback?: () => void) => void
    status: StatusAction
  }
  assignHitAction: {
    call: (payload: AssignHitActionPayloadType, callback?: () => void) => void
    status: StatusAction
  }
  updateMarkHitAction: {
    call: (payload: UpdateMarkHitActionPayloadType, callback?: () => void) => void
    status: StatusAction
  }
  /** MODEL **/
  getDocumentLogsAction: {
    call: (payload: GetDocumentLogsActionPayloadType) => void
    status: StatusAction
  }
}

const projectSlice: ImmerStateCreator<ProjectSliceType> = (set) => ({
  projects: [],
  tasksFiltered: [],
  tasksDraft: [],
  hits: [],
  hitLogs: [],
  taskLogs: [],
  hitReview: undefined,
  documentLogs: [],
  document: undefined,
  /**  **/
  getProjectsAction: {
    call: (payload) => getProjectsAction(set)(payload),
    status: 'IDLE',
  },
  getHitAction: {
    call: (payload) => getHitAction(set)(payload),
    status: 'IDLE',
  },
  getProjectAction: {
    call: (payload) => getProjectAction(set)(payload),
    status: 'IDLE',
  },
  getTasksAction: {
    call: (payload) => getTasksAction(set)(payload),
    status: 'IDLE',
  },
  getHitsAction: {
    call: (payload, callback) => getHitsAction(set)(payload, callback),
    status: 'IDLE',
  },
  getTaskAction: {
    call: (payload, callback) => getTaskAction(set)(payload, callback),
    status: 'IDLE',
  },
  getHitLogsAction: {
    call: (payload) => getHitLogsAction(set)(payload),
    status: 'IDLE',
  },
  getTaskLogsAction: {
    call: (payload, callback) => getTaskLogsAction(set)(payload, callback),
    status: 'IDLE',
  },
  getHitReviewAction: {
    call: (payload) => getHitReviewAction(set)(payload),
    status: 'IDLE',
  },
  getDocumentLogsAction: {
    call: (payload) => getDocumentLogsAction(set)(payload),
    status: 'IDLE',
  },
  updateNextStepProjectAction: {
    call: (payload, callback) => updateNextStepProjectAction(set)(payload, callback),
    status: 'IDLE',
  },
  updateFormProjectAction: {
    call: (payload, callback) => updateFormProjectAction(set)(payload, callback),
    status: 'IDLE',
  },
  updatePrefillProjectAction: {
    call: (payload, callback) => updatePrefillProjectAction(set)(payload, callback),
    status: 'IDLE',
  },
  updateSettingsProjectAction: {
    call: (payload, callback) => updateSettingsProjectAction(set)(payload, callback),
    status: 'IDLE',
  },
  addManagersToProjectAction: {
    call: (payload, callback) => addManagersToProjectAction(set)(payload, callback),
    status: 'IDLE',
  },
  updateAudienceProjectAction: {
    call: (payload, callback) => updateAudienceProjectAction(set)(payload, callback),
    status: 'IDLE',
  },
  updateAudienceReviewersProjectAction: {
    call: (payload, callback) => updateAudienceReviewersProjectAction(set)(payload, callback),
    status: 'IDLE',
  },
  getDraftTasksAction: {
    call: (payload, callback) => getDraftTasksAction(set)(payload, callback),
    status: 'IDLE',
  },
  createDraftTaskAction: {
    call: (payload, callback) => createDraftTaskAction(set)(payload, callback),
    status: 'IDLE',
  },
  updateDraftTaskAction: {
    call: (payload) => updateDraftTaskAction(set)(payload),
    status: 'IDLE',
  },
  deleteDraftTaskAction: {
    call: (payload) => deleteDraftTaskAction(set)(payload),
    status: 'IDLE',
  },
  attachTasksToProjectAction: {
    call: (payload, callback) => attachTasksToProjectAction(set)(payload, callback),
    status: 'IDLE',
  },
  uploadTasksFileProjectAction: {
    call: (payload, callback) => uploadTasksFileProjectAction(set)(payload, callback),
    status: 'IDLE',
  },
  uploadColumnFileTasksProjectAction: {
    call: (payload, callback) => uploadColumnFileTasksProjectAction(set)(payload, callback),
    status: 'IDLE',
  },
  createProjectAction: {
    call: (payload, callback) => createProjectAction(set)(payload, callback),
    status: 'IDLE',
  },
  boostProjectAction: {
    call: (payload, callback) => boostProjectAction(set)(payload, callback),
    status: 'IDLE',
  },
  stopBoostProjectAction: {
    call: (payload, callback) => stopBoostProjectAction(set)(payload, callback),
    status: 'IDLE',
  },
  stopProjectAction: {
    call: (payload, callback) => stopProjectAction(set)(payload, callback),
    status: 'IDLE',
  },
  downloadProjectsFileAction: {
    call: () => downloadProjectsFileAction(set)(),
    status: 'IDLE',
  },
  archiveProjectAction: {
    call: (payload, callback) => archiveProjectAction(set)(payload, callback),
    status: 'IDLE',
  },
  deleteTaskAction: {
    call: (payload, callback) => deleteTaskAction(set)(payload, callback),
    status: 'IDLE',
  },
  increasePriorityTaskAction: {
    call: (payload) => increasePriorityTaskAction(set)(payload),
    status: 'IDLE',
  },
  decreasePriorityTaskAction: {
    call: (payload) => decreasePriorityTaskAction(set)(payload),
    status: 'IDLE',
  },
  createAdditionalHitAction: {
    call: (payload, callback) => createAdditionalHitAction(set)(payload, callback),
    status: 'IDLE',
  },
  createOverrideHitAction: {
    call: (payload, callback) => createOverrideHitAction(set)(payload, callback),
    status: 'IDLE',
  },
  deleteHitAction: {
    call: (payload, callback) => deleteHitAction(set)(payload, callback),
    status: 'IDLE',
  },
  assignHitAction: {
    call: (payload, callback) => assignHitAction(set)(payload, callback),
    status: 'IDLE',
  },
  updateMarkHitAction: {
    call: (payload, callback) => updateMarkHitAction(set)(payload, callback),
    status: 'IDLE',
  },
})

export default projectSlice
