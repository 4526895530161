/** @jsxImportSource @emotion/react */

import { useParams } from 'react-router'
import {
  GetCertificationQuestionsPayloadType,
  UpdateCertificationQuestionTrainingStatusPayloadType,
} from '@api'
import { QuestionsCertification } from '@views/certification'
import { useRequestParams, useDelayedEffect } from '@hooks'
import { useEffect } from 'react'
import { QuestionType } from '@pages/types'

import { store } from '@/store'

const QuestionsCertificationPage = () => {
  const { id: idCertification } = useParams()

  const questionsFiltered = store.use.questionsFiltered()

  const getQuestionsCertificationAction = store.use.getQuestionsCertificationAction().call
  const updateTrainingQuestionCertificationAction =
    store.use.updateTrainingQuestionCertificationAction().call

  const { afterPageChange, setParams, reqParams, pagination, onTableStateChange } =
    useRequestParams<GetCertificationQuestionsPayloadType, QuestionType>({
      initParams: { model: { idCertification } },
    })

  useDelayedEffect(() => {
    getQuestionsCertificationAction(reqParams)
  }, [JSON.stringify(reqParams)])

  useEffect(() => {
    idCertification && setParams({ model: { idCertification } })
  }, [idCertification])

  useEffect(() => {
    afterPageChange(questionsFiltered?.length)
  }, [questionsFiltered?.length])

  const onQuestionTrainingStatusChange = async ({
    idCertification,
    idCertificationQuestion,
    training,
  }: UpdateCertificationQuestionTrainingStatusPayloadType) => {
    updateTrainingQuestionCertificationAction({
      idCertification,
      idQuestion: idCertificationQuestion,
      training,
    })
  }

  return (
    <>
      <QuestionsCertification
        onQuestionTrainingStatusUpdate={onQuestionTrainingStatusChange}
        questionsFiltered={questionsFiltered}
        tableProps={{ pagination, onChange: onTableStateChange }}
        onFiltersChange={setParams}
      />
    </>
  )
}

export default QuestionsCertificationPage
