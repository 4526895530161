// All formatting functions go here (e.g. date, time, currency, full name etc.)

import moment from 'moment'

export const XLSX_FILE_TYPE = 'vnd.openxmlformats-officedocument.spreadsheetml.sheet' as const

export const dateTime = (dateISO?: string, format = 'DD/MM/YY HH:mm:ss') => {
  if (!dateISO) return
  return moment(dateISO).format(format)
}

export const shortTime = (): string => {
  const now = new Date()
  return `${now.getHours().toString().padStart(2, '0')}:${now
    .getMinutes()
    .toString()
    .padStart(2, '0')}`
}

export const secondsToHumanReadable = (timeInSeconds: number) => {
  const formatDigits = (digits: number) => (String(digits).length === 1 ? `0${digits}` : digits)
  const hours = Math.floor(timeInSeconds / 3600)
  const minutes = Math.floor((timeInSeconds % 3600) / 60)
  const seconds = timeInSeconds % 60

  return `${hours ? hours + ':' : ''}${minutes ? formatDigits(minutes) + ':' : ''}${formatDigits(
    seconds,
  )}`
}

export const ticksToTime = (ticksStr: string) => {
  try {
    const ticks = BigInt(ticksStr)
    const secondsInTick = BigInt(10000000)
    const totalSeconds: bigint = ticks / secondsInTick
    const hours: bigint = totalSeconds / BigInt(3600)
    const minutes: bigint = (totalSeconds % BigInt(3600)) / BigInt(60)
    const seconds: bigint = totalSeconds % BigInt(60)

    return `${hours}:${minutes}:${seconds}`
  } catch (_) {
    return '0:0:0'
  }
}
