import React from 'react'
import { useParams } from 'react-router-dom'
import { Segment } from '@views/segment'
import { Loader } from '@components/utils'
import { useDelayedEffect } from '@hooks'

import { store } from '@/store'

const SegmentPage = () => {
  const { id } = useParams<{ id: string }>()

  const segment = store.use.segments()?.find(({ idSegment }) => idSegment === id)
  const getSegmentAction = store.use.getSegmentAction().call
  const isLoadingGetSegmentAction = store.use.getSegmentAction().status === 'LOADING'

  useDelayedEffect(() => {
    getSegmentAction({ idSegment: id })
  }, [id])

  return (
    <>
      {isLoadingGetSegmentAction && <Loader />}
      {!isLoadingGetSegmentAction && segment && <Segment segment={segment} />}
    </>
  )
}

export default SegmentPage
