import { CollapseWithPill } from '@components/display'
import { useTranslation } from '@hooks'
import { useState, useEffect } from 'react'
import { FormElements, Text } from 'common-components'
import { TxKeyPath } from '@i18n/i18n'
import { TAudienceCombinationType, TAudienceType } from '@definitions/states'
import { ProjectAudienceReviewerGroupType } from '@pages/types'

import { SegmentsTree, TAudienceCollapseType } from './segmentsTree'

type AudienceCollapsePropsType = {
  type: TAudienceCollapseType
  projectId: string
  audience: ProjectAudienceReviewerGroupType[]
  combination: TAudienceCombinationType
  audienceType: TAudienceType
  selectedEarnings: number
  timePerTask: string
  withCollapseTitle?: boolean
}

const AudienceCollapse = ({
  type,
  projectId,
  audience,
  combination,
  audienceType,
  withCollapseTitle = true,
}: AudienceCollapsePropsType) => {
  const { translate } = useTranslation()
  const form = FormElements.useFormInstance()

  const [isExternalOpen, setIsExternalOpen] = useState(true)
  const [isInternalOpen, setIsInternalOpen] = useState(true)

  // const openExternal = (isOpen: boolean) => {
  // 	if (isInternalOpen) setIsInternalOpen(false)
  // 	// to set already existing or default values
  // 	form.setFieldValue(type === "contributors" ? "audienceType" : "reviewersAudienceType", audienceType || 10)
  // 	form.setFieldValue(type === "contributors" ? "supplierEarning" : "reviewerEarning", selectedEarnings || undefined)
  // 	setIsExternalOpen(isOpen)
  // }
  const openInternal = (isOpen: boolean) => {
    if (isExternalOpen) setIsExternalOpen(false)
    form.setFieldValue(type === 'contributors' ? 'audienceType' : 'reviewersAudienceType', 0)
    form.setFieldValue(type === 'contributors' ? 'supplierEarning' : 'reviewerEarning', undefined)
    setIsInternalOpen(isOpen)
  }

  useEffect(() => {
    if (typeof audienceType === 'number')
      audienceType !== 0 ? setIsExternalOpen(true) : setIsInternalOpen(true)
  }, [audienceType])

  return !audience && !projectId ? (
    <Text>Loading....</Text>
  ) : (
    <CollapseWithPill
      panels={[
        ...(withCollapseTitle
          ? [
              {
                title: translate(`projectCreatePage.collapse.audience.title.${type}` as TxKeyPath),
                subtitle: translate(
                  `projectCreatePage.collapse.audience.subtitle.${type}` as TxKeyPath,
                ),
                content: '',
                withPill: false,
              },
            ]
          : []),
        /* HIDE
				 * {
					title: translate(
						`projectCreatePage.collapse.audience.external.title.${type}` as TxKeyPath,
					),
					titleLevel: withCollapseTitle ? 6 : 5,
					content: (
						<ExternalSegmentsWrapper
							type={type}
							selectedAudienceType={audienceType}
							selectedEarnings={selectedEarnings}
							timePerTask={timePerTask}
						>
							<SegmentsTree
								type={type}
								audience={audience}
								combination={combination}
							/>
						</ExternalSegmentsWrapper>
					),
					isOpen: isExternalOpen,
					setIsOpen: openExternal,
				},*/
        {
          title: translate(
            `projectCreatePage.collapse.audience.internal.title.${type}` as TxKeyPath,
          ),
          titleLevel: withCollapseTitle ? 6 : 5,
          content: (
            <div style={{ width: '100%', padding: '0 24px 24px' }}>
              <SegmentsTree type={type} audience={audience} combination={combination} />
            </div>
          ),
          isOpen: isInternalOpen,
          setIsOpen: openInternal,
        },
      ]}
    />
  )
}

export default AudienceCollapse
