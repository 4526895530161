const en = {
  auth: {
    signingIn: 'Checking sign in status...',
    loading: 'Loading...',
    signInButton: 'Sign in',
    inactivityError: 'You were logged out due to inactivity. Please log in again',
  },
  notFoundPage:
    "Page is not available right now or doesn't exist. Please, try to go back and choose a different direction",
  notifications: {
    error: 'Oops...',
    success: 'Success!',
    requestIsProcessing: 'Your request is processing',
  },
  dashboard: {
    tabs: {
      projects: 'Projects',
      workflows: 'Workflows',
    },
  },
  projectsPage: {
    createButtonLabel: 'Nouveau projet',
    exportButtonLabel: 'Export',
    filtersButtonLabel: 'Filtres',
    listHeaderTitle: 'Projets',
    listHeaderSubtitle: ' ',
    boostPopover: 'End of the boost in',
    table: {
      name: 'Nom',
      state: 'Status',
      segments: 'Segments',
      total: 'Total',
      inDirect: 'In direct', // not sure if this is the correct translation
      alerts: 'Alerts',
      actions: 'Actions',
    },
    dropdown: {
      open: 'Ouvrir',
      audience: "Modifer l'audience",
      parameters: 'Modifer les parametres',
      boost1: 'Boost 1 heure',
      boost2: 'Boost 6 heures',
      boost3: 'Boost 12 heures',
      stopBoost: 'Arreter le boost',
      duplicate: 'Dupliquer',
      stop: 'Arreter le projet',
      archive: 'Archiver',
    },
    modal: {
      create: {
        title: 'Nouveau projet',
        subtitle: 'Créer un projet',
        label: 'Nom',
        cancelButton: 'Retour',
        submitButton: 'Suivant',
      },
      archive: {
        title: 'Archive the project',
        subtitle: 'Unfinished tasks will be cancelled.',
        cancelButton: 'Cancel',
        submitButton: 'Confirm',
      },
      stop: {
        title: 'Stop the project',
        subtitle: 'Are you sure you want to delete this post? This action cannot be undone.',
        cancelButton: 'Cancel',
        submitButton: 'Stop',
      },
    },
  },

  projectCreatePage: {
    titles: {
      1: "Types d'extraction",
      2: 'Paramètres',
      3: 'Audience',
      4: 'Ajout de données',
    },

    descriptions: {
      1: 'Définir les étapes de vos traitements',
      2: 'Définir les paramètres du projet',
      3: "Choisir l'audience du projet",
      4: 'Déposer les données à traiter',
    },

    collapse: {
      manualEntry: {
        title: 'Formulaire pour saisie manuelle',
        subtitle: 'Choisir votre Formulaire',
        button: 'Choisir depuis la bibliothèque',
      },
      generative: {
        title: 'Préremplir mes traitements grâce à une Intelligence Artificielle Générative',
        subtitle: 'Intelligence Artificielle Générative séléctionnée',
      },
      artificialModel: {
        title: "Préremplir mes traitements grâce à un Modèle paramétré d'Intelligence Artificielle",
        content: {
          chooseForm: 'Choisir votre Modèle',
          modelInstructions:
            'By choosing a model and human add-on processing, you can set the confidence rate at which the model will send the answer directly and the rate at which a human will check the AI pre-fill.',
          formFields: 'Form fields',
          modelFields: 'Model fields',
          fieldLabel: 'First name',
        },
        button: 'Ajouter un Modèle',
      },
      SLA: {
        title: 'SLA',
        subtitle: 'En cas de dépassement de ces engagements, une alerte est remontée.',
      },
      processingTime: {
        title: 'Temps maximum de réalisation de la demande',
        checkbox: {
          exerciseTime: {
            title: 'Exercise time and deadline',
            subtitle:
              'Jobs submitted before the deadline must be delivered within a fixed number of days.',
          },
          averageTime: {
            title: 'Objectif de délai de réalisation',
            subtitle: ' ',
          },
        },
        form: {
          timeOfLifting: 'Time of lifting',
          dayOfDelivery: 'Day of delivery',
          cutOffTime: 'Cut off time',
          maxDeliveryTime: 'Délai maximum de réalisation',
        },
        button: {
          addHour: 'Add one hour of lifting time',
        },
      },
      objectiveQuality: {
        title: 'Quality objective',
      },
      productivityTargets: {
        title: 'Internal productivity targets',
        subtitle: 'Define your objectives for this project',
      },
      applicationsPurpose: {
        title: 'Purpose of the applications',
        form: {
          deliveryTime: 'Average delivery time',
          processingTime: 'Actual processing time of an application',
        },
      },
      contributorsObjective: {
        title: "Contributors' objective",
        form: {
          timeOfContribution: 'Effective time of a contribution',
          quality: 'Qualité',
        },
      },
      alerts: {
        title: 'Alerts',
        subtitle: 'Set your alerts',
        form: {
          recepients: 'Recepients',
        },
        toggles: {
          sms: 'Alertes SMS',
          email: 'Alertes email',
        },
        selectValues: {
          none: 'Uncheck',
          manager: 'All managers',
          admin: 'All administrators',
        },
      },
      parameters: {
        title: 'Parameters',
        subtitle: 'Choose an entry form',
      },
      diversification: {
        title: 'Diversification of respondents',
        form: {
          diversification: 'Diversification of respondents',
          minQualityLevel: 'Minimum quality level',
          numContributions: 'Number of contributions evaluated',
        },
      },
      managers: {
        title: 'Managers',
      },
      webhook: {
        title: 'Webhook',
        subtitle: 'Votre URL de notification',
        copyButton: 'Copy',
      },
      audience: {
        title: {
          contributors: 'Contributeurs',
          reviewers: 'Reviewers / Referees',
        },
        subtitle: {
          contributors: "Définir l'audience des contributeurs",
          reviewers: "Définir l'audience des relecteurs",
        },
        external: {
          title: {
            contributors: 'External contributors',
            reviewers: 'External reviewers',
          },
          form: {
            title: {
              provider: 'Provider',
              segment: 'Segment',
              compensation: 'Compensation',
            },
            radio: {
              type: {
                amazon: 'Amazon mTurk',
                yappers: 'Yappers',
              },
              earnings: {
                0: 'Economic',
                1: 'Medium',
                2: 'Competitive',
                priceSubtitle: 'cents / job',
              },
            },
          },
        },
        internal: {
          title: {
            contributors: 'Contributeurs internes',
            reviewers: 'Relecteurs internes',
          },
        },
      },
      generalSettings: {
        title: 'General settings',
      },
    },

    form: {
      label: {
        validationRate: 'Validation rate',
        prefillRate: 'Pre-fill rate',
        firstName: 'First name',
        lastName: 'Last name',
        client: 'Client',
      },
      placeholder: {
        firstName: 'Customer_Firstname',
        tableClient: 'Table_Client',
        clientName: 'Customner_Name',
      },
    },

    submitButton: {
      step1: 'Etape suivante',
    },

    modal: {
      models: {
        title: 'Liste des Modèles',
      },
    },
  },

  segmentsPage: {
    table: {
      name: 'Name',
      type: 'Type',
      rights: 'Rights',
      Respondents: 'Respondents',
      avgTime: 'Average time',
      avgScore: 'Average score',
      status: 'Status',
      managers: 'Managers',
    },
  },

  navigation: {
    dropdownPanel: {
      headings: {
        analyser: 'Analyse',
        mobiliser: 'Audience',
        launchpad: 'Traitement',
        settings: 'Paramètres',
      },
      columns: {
        first: {
          dashboard: 'Dashboard',
          performance: 'Performance',
        },
        second: {
          contributeurs: 'Contributeurs',
          certifications: 'Certifications',
          segments: 'Segments',
        },
        third: {
          projets: 'Projets',
          formulaires: 'Formulaires',
          modèles: 'Modèles',
          workflows: 'Workflows',
          risks: 'Fraude',
        },
        fourth: {
          managers: 'Managers',
          consommation: 'Consommation',
          facturation: 'Facturation',
          paramètres: 'Paramètres',
          logout: 'Se déconnecter',
        },
      },
    },
  },
  questionPage: {
    headerTitle: 'Question %{id}',
    archiveButtonLabel: 'Archive',
    submitButtonLabel: 'Submit',
  },
  settings: {
    tabs: {
      general: 'General',
      apiKeys: 'API keys',
      profile: 'My profile',
      changePassword: 'Change password',
    },
  },
}

export default en
export type Translations = typeof en
