import { FilterPayloadType, IconType } from '@types'
import {
  UserShortApiType,
  CertificationApiType,
  CertificationUserTestApiType,
  CertificationUserTestQuestionApiType,
  FormShortApiType,
  QuestionApiType,
  AnswerApiType,
} from '@api/types'
import {
  axiosClient,
  IdManagerRightType,
  PrefillCertificationType,
  TCertificationState,
  TSegmentDefType,
  TUserType,
} from '@definitions'
import { AxiosResponse } from 'axios'
import { catchApiError, downloadBlob, shortTime } from '@utils'

export type CreateCertificationPayloadType = {
  name: string
  icon?: IconType
}
export const createCertification = async (
  payload: CreateCertificationPayloadType,
): Promise<CertificationApiType> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<CertificationApiType> = await axios.post(
      '/CertificationService/Certification/CreateCertification',
      payload,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export type GetCertificationsPayloadType = Pick<
  FilterPayloadType,
  'endDate' | 'entriesPerPage' | 'index' | 'startDate' | 'search' | 'orderByAsc'
> & {
  model: {
    role?: IdManagerRightType[]
    state?: TCertificationState[]
    managers?: string[]
  }
}
export const getCertifications = async (
  payload: GetCertificationsPayloadType,
): Promise<CertificationApiType[]> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<CertificationApiType[]> = await axios.post(
      '/CertificationService/Certification/GetCertifications',
      payload,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export type GetCertificationPayloadType = {
  idCertification: string
}

export const getCertification = async ({
  idCertification,
}: GetCertificationPayloadType): Promise<CertificationApiType> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<CertificationApiType> = await axios.get(
      `/CertificationService/Certification/${idCertification}`,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export type UpdateCertificationFormPayloadType = {
  idCertification: string
  form: FormShortApiType
}
export const updateCertificationForm = async ({
  idCertification,
  form,
}: UpdateCertificationFormPayloadType): Promise<CertificationApiType> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<CertificationApiType> = await axios.put(
      `/CertificationService/Certification/UpdateForm`,
      { idCertification, form },
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export type UpdateCertificationSegmentsPayloadType = {
  idCertification: string
  segments: {
    idSegment: string
    icon?: IconType
    managerRight?: IdManagerRightType
    name: string
    nbMinEvaluation?: number
    qualityMin?: number
    segmentType?: TSegmentDefType
  }[]
}
export const updateCertificationSegments = async ({
  idCertification,
  segments,
}: UpdateCertificationSegmentsPayloadType): Promise<CertificationApiType> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<CertificationApiType> = await axios.put(
      `/CertificationService/Certification/UpdateSegments`,
      { idCertification, segments },
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

type UpdateCertificationSettingsPayloadType = {
  idCertification: string
  nbQuestionsTest: number
  nbAttemptsMax: number
}
export const updateCertificationSettings = async (
  payload: UpdateCertificationSettingsPayloadType,
): Promise<CertificationApiType> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<CertificationApiType> = await axios.put(
      `/CertificationService/Certification/UpdateSettings`,
      payload,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export type UpdateCertificationInfoPayloadType = {
  idCertification: string
  name: string
  icon: IconType
}
export const updateCertificationInfo = async (
  payload: UpdateCertificationInfoPayloadType,
): Promise<CertificationApiType> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<CertificationApiType> = await axios.put(
      `/CertificationService/Certification/${payload.idCertification}/UpdateInfo`,
      payload,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export type UpdatePrefillCertificationPayloadType = {
  idCertification: string
  prefillType: PrefillCertificationType
}
export const updatePrefillCertification = async ({
  idCertification,
  prefillType,
}: UpdatePrefillCertificationPayloadType) => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<CertificationApiType> = await axios.put(
      `CertificationService/Certification/${idCertification}/UpdatePrefillCertification`,
      { prefillType },
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

type UpdateCertificationQuestionInputJsonPayloadType = {
  idCertification: string
  idCertificationQuestion: string
  inputJson: AnswerApiType
}
export const updateCertificationQuestionInputJson = async ({
  idCertification,
  idCertificationQuestion,
  inputJson,
}: UpdateCertificationQuestionInputJsonPayloadType) => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<QuestionApiType> = await axios.put(
      `/CertificationService/Certification/UpdateInputJson`,
      {
        idCertification,
        idCertificationQuestion,
        inputJson,
      },
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export type UpdateCertificationManagersPayloadType = {
  idCertification: string
  managers: UserShortApiType[]
}
export const updateCertificationManagers = async ({
  idCertification,
  managers,
}: UpdateCertificationManagersPayloadType) => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<CertificationApiType> = await axios.put(
      `/CertificationService/Certification/UpdateManagers`,
      { idCertification, managers },
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export type AddManagerToCertificationPayloadType = {
  id: string
  managers: UserShortApiType[]
}
export const addManagerToCertification = async ({
  id,
  managers,
}: AddManagerToCertificationPayloadType) => {
  try {
    const axios = axiosClient()
    await axios.post(`/CertificationService/Certification/AddManagers`, {
      idCertification: id,
      managers,
    })
  } catch (e) {
    catchApiError(e)
  }
}

export type DeleteManagerFromCertificationPayloadType = {
  id: string
  managers: UserShortApiType[]
}
export const deleteManagerFromCertification = async ({
  id,
  managers,
}: DeleteManagerFromCertificationPayloadType) => {
  try {
    const axios = axiosClient()
    await axios.put(`/CertificationService/Certification/DeleteManagers`, {
      idCertification: id,
      managers,
    })
  } catch (e) {
    catchApiError(e)
  }
}

type RequestAccessForCertificationPayloadType = {
  idCertification: string
}
export const requestAccessForCertification = async ({
  idCertification,
}: RequestAccessForCertificationPayloadType): Promise<void> => {
  try {
    const axios = axiosClient()
    await axios.get(`/CertificationService/Certification/${idCertification}/Join`)
  } catch (e) {
    catchApiError(e)
  }
}

export type NextStepCertificationType = {
  idCertification: string
}
export const nextStepCertification = async ({
  idCertification,
}: NextStepCertificationType): Promise<TCertificationState> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<TCertificationState> = await axios.put(
      `/CertificationService/Certification/${idCertification}/NextStep`,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export type PublishCertificationPayloadType = {
  idCertification: string
}
export const publishCertification = async ({
  idCertification,
}: PublishCertificationPayloadType): Promise<CertificationApiType> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<CertificationApiType> = await axios.post(
      '/CertificationService/Certification/PublishCertification',
      { idCertification },
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

// CERTIFICATION QUESTIONS

export type CreateCertificationDraftQuestionType = {
  idCertification: string
}
export const createCertificationDraftQuestion = async ({
  idCertification,
}: CreateCertificationDraftQuestionType): Promise<QuestionApiType> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<QuestionApiType> = await axios.post(
      `/CertificationService/Certification/${idCertification}/CreateNewQuestion`,
      {},
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

type GetCertificationDraftQuestionsFunctionType = (certificationId: string) => Promise<{
  idCertifications: string
  questions: QuestionApiType[]
}>
export const getCertificationDraftQuestions: GetCertificationDraftQuestionsFunctionType = async (
  certificationId,
) => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<ReturnType<GetCertificationDraftQuestionsFunctionType>> =
      await axios.get(`/CertificationService/Certification/${certificationId}/DraftQuestions`)
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export type UploadCertificationQuestionsFilePayloadType = {
  idCertification: string
  file: File
}
export const uploadCertificationQuestionsFile = async ({
  idCertification,
  file,
}: UploadCertificationQuestionsFilePayloadType): Promise<void> => {
  try {
    const axios = axiosClient({
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    const formData = new FormData()
    formData.append('idCertification', idCertification)
    formData.append('file', file)

    const resp: AxiosResponse<void> = await axios.post(
      `/CertificationService/Certification/UploadQuestionsFile`,
      formData,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export type UploadCertificationQuestionsColumnFilePayloadType = {
  idCertification: string
  columnName: string
  file: string
}
export const uploadCertificationQuestionsColumnFile = async ({
  idCertification,
  file,
  columnName,
}: UploadCertificationQuestionsColumnFilePayloadType): Promise<void> => {
  try {
    const axios = axiosClient({
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    const formData = new FormData()
    formData.append('IdCertification', idCertification)
    formData.append('ColumnName', columnName)
    formData.append('file', file)

    const resp: AxiosResponse<void> = await axios.post(
      `/CertificationService/Certification/UploadQuestionFileInputColumn`,
      formData,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

type UpdateCertificationQuestionValuePayloadType = {
  idCertification: string
  idCertificationQuestion: string
  columnName: string
  value: string
}
export const updateCertificationQuestionValue = async ({
  idCertification,
  value,
  idCertificationQuestion,
  columnName,
}: UpdateCertificationQuestionValuePayloadType): Promise<QuestionApiType> => {
  try {
    const axios = axiosClient({
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    const formData = new FormData()
    formData.append('IdCertification', idCertification)
    formData.append('IdCertificationQuestion', idCertificationQuestion)
    formData.append('ColumnName', columnName)
    formData.append('Value', value)

    const resp: AxiosResponse<QuestionApiType> = await axios.put(
      `/CertificationService/Certification/UpdateQuestionValue`,
      formData,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

type UpdateCertificationQuestionFilePayload = {
  idCertification: string
  idCertificationQuestion: string
  columnName: string
  file: string
}

export const updateCertificationQuestionFile = async ({
  idCertification,
  file,
  idCertificationQuestion,
  columnName,
}: UpdateCertificationQuestionFilePayload): Promise<QuestionApiType> => {
  try {
    const axios = axiosClient({
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    const formData = new FormData()
    formData.append('IdCertification', idCertification)
    formData.append('IdCertificationQuestion', idCertificationQuestion)
    formData.append('ColumnName', columnName)
    formData.append('File', file)

    const resp: AxiosResponse<QuestionApiType> = await axios.post(
      `/CertificationService/Certification/UploadQuestionFile`,
      formData,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export type AttachCertificationQuestionsType = {
  idCertification: string
}
export const attachCertificationQuestions = async ({
  idCertification,
}: AttachCertificationQuestionsType): Promise<CertificationApiType> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<CertificationApiType> = await axios.put(
      `/CertificationService/Certification/AttachQuestionsToCertification`,
      { idCertification },
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export type GetCertificationQuestionsPayloadType = FilterPayloadType & {
  model: {
    idCertification?: string
    state?: TCertificationState[]
    training?: boolean
  }
}
export const getCertificationQuestions = async (
  payload: GetCertificationQuestionsPayloadType,
): Promise<QuestionApiType[]> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<QuestionApiType[]> = await axios.post(
      `/CertificationService/Certification/GetQuestions`,
      payload,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export type GetCertificationQuestionPayloadType = {
  idCertification: string
  idQuestion: string
}

export const getCertificationQuestion = async ({
  idCertification,
  idQuestion,
}: GetCertificationQuestionPayloadType): Promise<QuestionApiType> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<QuestionApiType> = await axios.get(
      `/CertificationService/Certification/${idCertification}/Question/${idQuestion}`,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export type UpdateCertificationQuestionTrainingStatusPayloadType = {
  training: boolean
  idCertification: string
  idCertificationQuestion: string
}
export const updateCertificationQuestionTrainingStatus = async (
  payload: UpdateCertificationQuestionTrainingStatusPayloadType,
): Promise<QuestionApiType> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<QuestionApiType> = await axios.put(
      `/CertificationService/Certification/Questions/UpdateTrainingQuestion`,
      payload,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export const deleteCertificationQuestion = async (data: {
  certificationId: string
  questionId: string
}): Promise<void> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<void> = await axios.delete(
      `/CertificationService/Certification/${data.certificationId}/Questions/${data.questionId}/DeleteQuestion`,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

// export const deleteCertificationDraftQuestions = async (certificationId: string): Promise<void> => {
// 	try {
// 		const axios = axiosClient();
// 		const resp: AxiosResponse<void> = await axios.delete(
// 			`/CertificationService/Certification/${certificationId}/DeleteDrafQuestions`,
// 		);
// 		return resp.data;
// 	} catch (err: any) {
// 		throw err.response;
// 	}
// };

export type DeleteCertificationDraftQuestionType = {
  idCertification: string
  idCertificationQuestion: string
}
export const deleteCertificationDraftQuestion = async ({
  idCertification,
  idCertificationQuestion,
}: DeleteCertificationDraftQuestionType): Promise<void> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<void> = await axios.delete(
      `/CertificationService/Certification/${idCertification}/DeleteDrafQuestion/${idCertificationQuestion}`,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

// CERTIFICATION USER TESTS

export type GetCertificationUserTestsPayloadType = FilterPayloadType & {
  model: {
    idCertification?: string
    idUser?: string
    state?: TCertificationState[]
    userType?: TUserType[]
  }
}
export const getCertificationUserTests = async (
  payload: GetCertificationUserTestsPayloadType,
): Promise<CertificationUserTestApiType[]> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<CertificationUserTestApiType[]> = await axios.post(
      `/CertificationService/CertificationUserTest/GetCertificationUserTests`,
      payload,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export const downloadSampleFileCertification = async ({
  idCertification,
}: {
  idCertification: string
}): Promise<void> => {
  try {
    const axios = axiosClient()
    const response: AxiosResponse<BlobPart> = await axios.post(
      `/CertificationService/Certification/${idCertification}/DownloadSampleFile`,
      {},
      {
        responseType: 'blob',
      },
    )
    downloadBlob([response.data], `InputFileSample_certification_${idCertification}.xlsx`)
  } catch (e) {
    catchApiError(e)
  }
}

export type ExportCertificationUserTestsPayloadType = FilterPayloadType & {
  model?: {
    idCertification?: string
    idUser?: string
    state?: TCertificationState[]
    userType?: TUserType[]
  }
}
export const exportCertificationUserTests = async (
  params?: ExportCertificationUserTestsPayloadType,
): Promise<void> => {
  try {
    const axios = axiosClient()
    const response: AxiosResponse<BlobPart> = await axios.post(
      `/CertificationService/CertificationUserTest/GetCertificationUserTests/Download`,
      params,
      {
        responseType: 'blob',
      },
    )

    downloadBlob(
      [response.data],
      `CertificationUserTests_${params.model.idCertification}_${shortTime()}.xlsx`,
    )
  } catch (e) {
    catchApiError(e)
  }
}

export type GetCertificationUserTestQuestionsPayloadType = FilterPayloadType & {
  model: {
    idCertificationUserTest?: string
  }
}
export const getCertificationUserTestQuestions = async (
  payload: GetCertificationUserTestQuestionsPayloadType,
): Promise<CertificationUserTestQuestionApiType[]> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<CertificationUserTestQuestionApiType[]> = await axios.post(
      `/CertificationService/CertificationUserTest/GetCertificationUserTestQuestions`,
      payload,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export type GetCertificationUserTestQuestionPayloadType = {
  idCertificationUserTest: string
  userTestQuestionId: string
}
export const getCertificationUserTestQuestion = async ({
  idCertificationUserTest,
  userTestQuestionId,
}: GetCertificationUserTestQuestionPayloadType): Promise<CertificationUserTestQuestionApiType> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<CertificationUserTestQuestionApiType> = await axios.get(
      `/CertificationService/CertificationUserTest/${idCertificationUserTest}/Questions/${userTestQuestionId}`,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

type UpdateOnlineStatePayloadType = {
  idCertification: string
}
export const updateOnlineState = async ({
  idCertification,
}: UpdateOnlineStatePayloadType): Promise<CertificationApiType> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<CertificationApiType> = await axios.put(
      `/CertificationService/Certification/${idCertification}/UpdateOnlineState`,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export type DeleteCertificationUserTestsPayloadType = {
  idCertificationUserTest: string
}
export const deleteCertificationUserTests = async ({
  idCertificationUserTest,
}: DeleteCertificationUserTestsPayloadType): Promise<void> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<void> = await axios.delete(
      `/CertificationService/CertificationUserTest/${idCertificationUserTest}/DeleteCertificationUserTest`,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}
