import { axiosClient, TSociodemographicCriteria } from '@definitions'
import { Resource } from '@types'
import { catchApiError } from '@utils'

export const getResource = async (resourceType: TSociodemographicCriteria): Promise<Resource[]> => {
  try {
    const axios = axiosClient()
    const resp = await axios.get<Resource[]>(`/ResourceService/Resource/${resourceType}`)
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export const getSociodemographicResource = async (): Promise<Resource[]> => {
  try {
    const axios = axiosClient()
    const resp = await axios.get<Resource[]>(`/ResourceService/Resource/Sociodemographic`)
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}
