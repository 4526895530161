/** @jsxImportSource @emotion/react */

import React, { memo } from 'react'
import { useTranslation } from '@hooks'
import styled from '@emotion/styled'
import { TaskStateType } from '@definitions'
import { PresetTag } from '@components/display'
import { TaskType } from '@pages/types'
import { Collapse, Panel, Row, Space, Text } from 'common-components'

import { store } from '@/store'

const DataRow = styled(Row)`
  justify-content: space-between;
  align-items: flex-end;
`

const PanelContainer = styled.div`
  padding: 5px;
`

type TaskSidebarTaskPropsType = {
  task: TaskType
}

const TaskSidebarTaskComponent = ({ task }: TaskSidebarTaskPropsType) => {
  const { translate } = useTranslation()

  const taskLogs = store.use.taskLogs()

  return (
    <Collapse
      bordered={false}
      expandIconPosition={'end'}
      defaultActiveKey={['']}
      collapsible={'header'}
    >
      <Panel
        header={translate('project.tasklineOverview.sidebar.taskMetrics.title')}
        key='metrics'
        style={{ paddingBottom: 10 }}
      >
        <PanelContainer>
          {/*idTask*/}
          <DataRow>
            <div>{translate('project.tasklineOverview.sidebar.taskMetrics.taskId')}</div>
            <div>{task.idTask}</div>
          </DataRow>
          {/*taskState*/}
          <DataRow>
            <div>{translate('project.tasklineOverview.sidebar.taskMetrics.status')}</div>
            <PresetTag preset={'task-state'} style={{ margin: 0 }} state={task?.taskState}>
              {TaskStateType[task?.taskState]}
            </PresetTag>
          </DataRow>
          {/*duration*/}
          <DataRow>
            <div>{translate('project.tasklineOverview.sidebar.taskMetrics.durationTotal')}</div>
            <div>{task?.duration ?? '-'}</div>
          </DataRow>
          {/*effectiveTime*/}
          <DataRow>
            <div>{translate('project.tasklineOverview.sidebar.taskMetrics.effectiveTime')}</div>
            <div>{task?.effectiveTime ?? '-'}</div>
          </DataRow>
          {/*dueDate*/}
          <DataRow>
            <div>{translate('project.tasklineOverview.sidebar.taskMetrics.deadlineDate')}</div>
            <div>{task?.dueDate ?? '-'}</div>
          </DataRow>
          {/*deleveringDate*/}
          <DataRow>
            <div>{translate('project.tasklineOverview.sidebar.taskMetrics.deliveryDate')}</div>
            <div>{task?.deleveringDate ?? '-'}</div>
          </DataRow>
          {/*creationDate*/}
          <DataRow>
            <div>{translate('project.tasklineOverview.sidebar.taskMetrics.creationDate')}</div>
            <div>{task?.creationDate ?? '-'}</div>
          </DataRow>
        </PanelContainer>
      </Panel>
      <Panel
        header={translate('project.tasklineOverview.sidebar.history.title')}
        key='history'
        style={{ paddingTop: 10 }}
      >
        <PanelContainer>
          {taskLogs.map((log, i: number) => (
            <DataRow key={`${log.creationDate}-${log.event}-${i}`}>
              <Space size={5}>
                <Text style={{ fontWeight: 400 }}>{log.creationDate}</Text>
                <Text style={{ fontWeight: 500 }}>
                  {translate(`common.states.eventType.${log.event}`)}
                </Text>
              </Space>
              {!!log.user && (
                <Text
                  style={{ fontWeight: 400 }}
                >{`${log.user?.firstName} ${log.user?.lastName}`}</Text>
              )}
            </DataRow>
          ))}
        </PanelContainer>
      </Panel>
    </Collapse>
  )
}

const TaskSidebarTask = memo(
  TaskSidebarTaskComponent,
  (oldProps: TaskSidebarTaskPropsType, newProps: TaskSidebarTaskPropsType) => {
    return JSON.stringify(oldProps) === JSON.stringify(newProps)
  },
)

export default TaskSidebarTask
