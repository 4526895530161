/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useTranslation } from '@hooks/useTranslation'
import { FC, useState } from 'react'
import {
  ActionModal,
  Button,
  Form,
  FormItem,
  Paragraph,
  Radio,
  RangePicker,
  Space,
  colors,
  useDisclosure,
} from 'common-components'

type ExportButtonProjectPropsType = {
  idProject: string
}

const ExportButtonProject: FC<ExportButtonProjectPropsType> = () => {
  const { translate } = useTranslation()

  const { open, isOpen, close } = useDisclosure()
  const [val1, setVal1] = useState()
  const [val2, setVal2] = useState()

  return (
    <>
      <Button onClick={open} iconLeft='Download01'>
        {translate('project.navigation.export')}
      </Button>
      <ActionModal
        open={isOpen}
        onOk={() => close()}
        iconBgColor={colors.primary100}
        iconColor={colors.primary600}
        icon={'Download01'}
        onConfirm={() => close()}
        title={translate('project.modal.title')}
        onCancel={close}
        actionButtons={[
          {
            type: 'primary',
            text: translate('project.modal.export'),
            onClick: () => {
              close()
            },
          },
        ]}
      >
        <Space
          css={css`
            display: flex;
            flex-direction: column;
            gap: 20px;
            align-items: start;
          `}
        >
          <Paragraph>{translate('project.modal.description')}</Paragraph>
          <Form>
            <FormItem label={translate('project.modal.period')}>
              <RangePicker />
            </FormItem>
            <FormItem label={translate('project.modal.radioTitle1')}>
              <Radio
                value={val1}
                onChange={(e) => {
                  setVal1(e.target.value)
                }}
                options={[
                  {
                    value: 1,
                    label: translate('project.modal.radioOption1'),
                  },
                  {
                    value: 2,
                    label: translate('project.modal.radioOption2'),
                  },
                ]}
              />
            </FormItem>
            <FormItem label={translate('project.modal.radioTitle2')}>
              <Radio
                value={val2}
                onChange={(e) => {
                  setVal2(e.target.value)
                }}
                options={[
                  {
                    value: 1,
                    label: translate('project.modal.radioOption3'),
                  },
                  {
                    value: 2,
                    label: translate('project.modal.radioOption4'),
                  },
                ]}
              />
            </FormItem>
          </Form>
        </Space>
      </ActionModal>
    </>
  )
}

export default ExportButtonProject
