import { getStateKeyByValue } from '@utils'
import { getWirkUrl, HitStateType } from '@definitions'
import { useTranslation } from '@hooks'
import openLink from '@utils/openLink'
import { useParams } from 'react-router-dom'
import { HitType } from '@pages/types'
import { Avatar, Button, Col, Icon, Paragraph, Space } from 'common-components'
import { TTasklineHitNavParams } from '@views/project'

import { store } from '@/store'

type ContributorTaskLineHitPropsType = {
  hit: HitType
}
const ContributorHitTask = function ({ hit }: ContributorTaskLineHitPropsType) {
  const { translate } = useTranslation()
  const accessToken = store.use.me().accessToken
  const params = useParams<TTasklineHitNavParams>()

  const deleteHitAction = store.use.deleteHitAction().call
  const assignHitAction = store.use.assignHitAction().call

  const isLoadingDeleteHitAction = store.use.deleteHitAction().status === 'LOADING'
  const isLoadingAssignHitAction = store.use.assignHitAction().status === 'LOADING'

  const getTaskLogsAction = store.use.getTaskLogsAction().call
  const getHitLogsAction = store.use.getHitLogsAction().call

  const onHitDelete = () => {
    deleteHitAction({ idHit: hit.idHit }, () => {
      getTaskLogsAction({ idTask: params.taskId })
      getHitLogsAction({ idHit: hit.idHit })
    })
  }
  const onHitAssign = () => {
    assignHitAction({ idHit: hit.idHit }, () => {
      getTaskLogsAction({ idTask: params.taskId })
      getHitLogsAction({ idHit: hit.idHit })
      openLink(
        `${getWirkUrl('Form')}/contribute/project/${hit.idProject}?token=${accessToken}&tenant=${
          params.tenant
        }`,
      )
    })
  }

  switch (hit?.hitState) {
    case getStateKeyByValue(HitStateType, 'inCreation'):
    case getStateKeyByValue(HitStateType, 'onHold'):
      return (
        <Space size={'middle'}>
          <Avatar size={'2xl'} src={hit?.user?.urlAvatar} icon={<Icon icon={'Hourglass01'} />} />
          <Col style={{ alignItems: 'center' }}>
            <Paragraph>
              {translate('project.tasklineOverview.sidebar.contributor.inProgress')}
            </Paragraph>
            <Paragraph>
              {translate('project.tasklineOverview.sidebar.contributor.search')}
            </Paragraph>
          </Col>
          <Button onClick={onHitAssign} loading={isLoadingAssignHitAction} size={'inline'}>
            {translate('project.tasklineOverview.sidebar.contributor.assignButton')}
          </Button>
        </Space>
      )

    case getStateKeyByValue(HitStateType, 'inProgress'):
      return (
        <Space size={'middle'}>
          <Avatar size={'2xl'} src={hit?.user?.urlAvatar} />
          <Col style={{ alignItems: 'center' }}>
            <Paragraph>
              {hit?.user?.firstName} {hit?.user?.lastName}
            </Paragraph>
            {/*<Paragraph>{hit?.user?.e}</Paragraph>*/}
            <Space size={4}>
              <Button onClick={onHitAssign} loading={isLoadingAssignHitAction} size={'inline'}>
                {translate('project.tasklineOverview.sidebar.contributor.assignButton')}
              </Button>
              <Button onClick={onHitDelete} loading={isLoadingDeleteHitAction} size={'inline'}>
                {translate('project.tasklineOverview.sidebar.contributor.removeButton')}
              </Button>
            </Space>
          </Col>
        </Space>
      )

    case getStateKeyByValue(HitStateType, 'completed'):
      return (
        <Space size={'middle'}>
          <Avatar size={'2xl'} src={hit?.user?.urlAvatar} />
          <Col style={{ alignItems: 'center' }}>
            <Paragraph>
              {hit?.user?.firstName} {hit?.user?.lastName}
            </Paragraph>
            {/*<Paragraph>{hit?.user?.e}</Paragraph>*/}
          </Col>
        </Space>
      )
    default:
      return (
        <Space size={'middle'}>
          <Avatar size={'2xl'} src={hit?.user?.urlAvatar} />
          <Col style={{ alignItems: 'center' }}>
            <Paragraph>
              {hit?.user?.firstName} {hit?.user?.lastName}
            </Paragraph>
            {/*<Paragraph>{hit?.user?.e}</Paragraph>*/}
          </Col>
        </Space>
      )
  }
}

export default ContributorHitTask
