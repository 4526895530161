/** @jsxImportSource @emotion/react */
import { useState } from 'react'
import { css } from '@emotion/react'
import { SelectAsync, ValueSelectType } from '@components/inputs'
import { useTranslation } from '@hooks'
import { CertificationShortType } from '@pages/types'
import { getCertifications } from '@api'
import {
  ID_MANAGER_RIGHT,
  IdManagerRightType,
  LABEL_MANAGER_RIGHT,
  LabelManagerRightType,
  UserAccessLevel,
} from '@definitions'
import { Dropdown, MenuProps } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { Avatar, Row, Space, Text, colors, Icon, Button } from 'common-components'

export type AssignCertificationPropsType = {
  certifications?: CertificationShortType[]
  isLoading?: boolean
  onAddCertification: (
    certificationShort: CertificationShortType,
    managerRight: IdManagerRightType,
  ) => void
  onDeleteCertification: (id: string) => void
}

type OptionType = ValueSelectType & {
  managerRight: IdManagerRightType
  certificationData: CertificationShortType
}

const optionMapper = (option: OptionType): CertificationShortType => {
  return {
    icon: option.certificationData.icon,
    managerRight: option.certificationData.managerRight,
    idCertification: option.certificationData.idCertification,
    name: option.label,
  }
}

const certificationsMapper = (certifications: CertificationShortType[]): OptionType[] =>
  certifications.map((certification) => ({
    value: certification.idCertification,
    label: certification.name,
    managerRight: certification.managerRight,
    certificationData: certification,
  }))

const MENU_ITEMS: [IdManagerRightType, LabelManagerRightType][] = [
  [ID_MANAGER_RIGHT[2], LABEL_MANAGER_RIGHT[2]],
  [ID_MANAGER_RIGHT[3], LABEL_MANAGER_RIGHT[3]],
]

type ManagerRightRenderPropsType = {
  key: number
  label: string
  onSelect: () => void
}
const menuItemRender = ({ key, label, onSelect }: ManagerRightRenderPropsType) => {
  return {
    label: <div onClick={onSelect}>{label}</div>,
    key,
  }
}

const AssignCertification = ({
  certifications = [],
  isLoading,
  onDeleteCertification,
  onAddCertification,
}: AssignCertificationPropsType) => {
  const { translate } = useTranslation()

  const [selectedCertification, setSelectedCertification] = useState<OptionType | ''>('')
  const [selectedCertifications, setSelectedCertifications] = useState<OptionType[]>(
    certificationsMapper(certifications),
  )

  const onSelectMenuItem = (option: OptionType, idManagerRight: IdManagerRightType) => {
    const newItems = selectedCertifications.map((item) => {
      if (item.value === option.value) {
        return {
          ...item,
          managerRight: idManagerRight,
        }
      }
      return item
    })
    setSelectedCertifications(newItems)
    onAddCertification(optionMapper(option), idManagerRight)
  }

  const loadOptions = (inputValue: string, callback: (data: OptionType[]) => void) => {
    getCertifications({
      orderByAsc: true,
      search: inputValue,
      model: {},
    })
      .then((data) => {
        const loadOptions = data.map((certification) => ({
          value: certification.idCertification,
          label: certification.name,
          managerRight: ID_MANAGER_RIGHT[2],
          certificationData: certification,
        }))
        callback(loadOptions)
      })
      .catch(() => {
        callback([])
      })
  }

  return (
    <Row
      css={css`
        width: 100%;
        margin-top: 20px;
      `}
    >
      <Space
        css={css`
          display: grid;
          grid-template-columns: 10fr 1fr;
          gap: 6px;
          margin-bottom: 20px;
          width: 100%;
        `}
      >
        <SelectAsync<OptionType>
          loadOptions={loadOptions}
          valuesSelected={selectedCertifications.map(({ label }) => label)}
          value={selectedCertification}
          onChange={setSelectedCertification}
        />
        <Button
          onClick={() => {
            if (selectedCertification !== '') {
              setSelectedCertifications([...selectedCertifications, selectedCertification])
              setSelectedCertification('')
              onAddCertification(
                optionMapper(selectedCertification),
                selectedCertification.managerRight,
              )
            }
          }}
          disabled={!selectedCertification || isLoading}
          type='primary'
        >
          {isLoading ? <LoadingOutlined /> : translate('display.add')}
        </Button>
      </Space>
      {selectedCertifications.map((item) => {
        const managerRights: MenuProps['items'] = MENU_ITEMS.map(
          ([idAccessLevel, labelAccessLevel]) => {
            return menuItemRender({
              key: idAccessLevel,
              label: labelAccessLevel,
              onSelect: () => onSelectMenuItem(item, idAccessLevel),
            })
          },
        )

        return (
          <Row
            key={item.value}
            justify={'space-between'}
            align='middle'
            css={css`
              height: 44px;
              width: 100%;
            `}
          >
            <Space
              css={css`
                display: flex;
                gap: 12px;
                align-items: center;
              `}
            >
              <Avatar />
              <Text color={colors.gray600}>{item.label}</Text>
            </Space>
            <div
              css={css`
                display: flex;
                align-items: center;
                gap: 20px;
              `}
            >
              <Dropdown
                menu={{ items: managerRights }}
                trigger={['click']}
                disabled={item.managerRight === ID_MANAGER_RIGHT[1]}
                placement='bottomRight'
              >
                <Space>
                  {UserAccessLevel[item.managerRight]}
                  {item.managerRight !== ID_MANAGER_RIGHT[1] && <Icon icon='ChevronDown' />}
                </Space>
              </Dropdown>
              <div
                onClick={() => {
                  setSelectedCertifications(
                    selectedCertifications.filter(({ value }) => value !== item.value),
                  )
                  onDeleteCertification(item.value)
                }}
              >
                <Icon width={18} height={18} icon='XClose' />
              </div>
            </div>
          </Row>
        )
      })}
    </Row>
  )
}

export default AssignCertification
