/** @jsxImportSource @emotion/react */
import {
  ActionModal,
  Button,
  Card,
  Col,
  colors,
  Row,
  CheckboxGroup,
  Content,
  Text,
  Title,
  useDisclosure,
  Space,
} from 'common-components'
import { useTranslation } from '@hooks'
import { FiltersBarForm } from '@views/shared/form'
import { useMutation, useQueryClient } from 'react-query'
import { getWirkUrl } from '@definitions'
import { bookmarkForm, deleteForm, duplicateForm, GetFormsPayloadType } from '@api'
import { useState } from 'react'
import { Pagination, PaginationProps } from 'antd'
import openLink from '@utils/openLink'
import { useParams } from 'react-router-dom'
import { FormType } from '@pages/types'

import ManagementCardForm from './ManagementCardForm'
import FiltersForm from './FiltersForm'

import { store } from '@/store'

type ListFormPropsType = {
  formDrafts: FormType[]
  forms: FormType[]
  reqParams: GetFormsPayloadType
  setParams: (filters: GetFormsPayloadType) => void
  pagination: PaginationProps
}
const ListForm = ({ formDrafts, forms, pagination, setParams, reqParams }: ListFormPropsType) => {
  const client = useQueryClient()
  const { translate } = useTranslation()
  const { open, isOpen, close } = useDisclosure()
  const accessToken = store.use.me().accessToken
  const params = useParams()

  const [idFormsToDelete, setIdFormsToDelete] = useState<string[]>([])
  const [isFiltersVisible, setFiltersVisible] = useState(false)

  const { mutate: bookmark } = useMutation({
    mutationFn: bookmarkForm,
    onSuccess: () => {
      client.invalidateQueries()
    },
  })

  const deleteFormMutation = useMutation({
    mutationFn: deleteForm,
    onSuccess: () => {
      // setFormDrafts([])
      client.invalidateQueries()
    },
  })

  const onFormsDelete = async () => {
    for (const idForm of idFormsToDelete) {
      await deleteFormMutation.mutateAsync(idForm)
    }
  }
  const onFormDeleteConfirm = async () => {
    await deleteFormMutation.mutateAsync(idFormsToDelete[0])
    setIdFormsToDelete([])
    close()
  }

  const onClickFormCreate = () =>
    openLink(`${getWirkUrl('Form')}?token=${accessToken}&tenant=${params.tenant}`)

  const onFormOpen = (idForm: string) =>
    openLink(
      `${getWirkUrl('Form')}/form/${idForm}/fields?token=${accessToken}&tenant=${params.tenant}`,
    )
  const onFormCopy = async (idForm: string) => {
    try {
      const formCopy = await duplicateForm(idForm)
      await client.invalidateQueries()
      openLink(
        `${getWirkUrl('Form')}/form/${formCopy.idForm}/fields?token=${accessToken}&tenant=${
          params.tenant
        }`,
      )
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }

  const onFormDelete = (idForm: string) => {
    setIdFormsToDelete([idForm])
    open()
  }

  const onFormBookmarkToggle = (idForm: string, isBookmarked: boolean) => {
    bookmark({ idForm, isBookmarked })
  }

  return (
    <>
      <Card style={{ width: '100%', padding: 20, marginBottom: 20 }}>
        <Title preset='singlePageTitle'>{translate(`form.formDraftsListTitle`)}</Title>
        <Content>
          <CheckboxGroup>
            <Space size={10} wrap>
              {formDrafts.map((form, i) => (
                <ManagementCardForm
                  key={`${i}-${form.idForm}`}
                  form={form}
                  onFormOpen={onFormOpen}
                  onFormCopy={onFormCopy}
                  onFormDelete={onFormDelete}
                />
              ))}
            </Space>
          </CheckboxGroup>
        </Content>
      </Card>
      <Space direction='vertical' style={{ gap: 16 }}>
        <Space align='center' style={{ width: '100%', justifyContent: 'space-between' }}>
          <Title preset='singlePageTitle'>{translate(`form.formListTitle`)}</Title>
          {idFormsToDelete.length ? (
            <Button iconLeft='Trash02' onClick={onFormsDelete}>
              {translate('certificationPage.archiveButtonLabel')}
            </Button>
          ) : (
            <Button onClick={onClickFormCreate} type='primary' iconLeft='Plus'>
              {translate('form.addButton')}
            </Button>
          )}
        </Space>
        <Row
          justify={'space-between'}
          align={'middle'}
          style={{
            width: '100%',
          }}
        >
          <Col span={16}>
            <FiltersBarForm initValues={reqParams} onFiltersChange={setParams} />
          </Col>

          <Button onClick={() => setFiltersVisible(true)} iconLeft='FilterLines'>
            {translate('common.filter')}
          </Button>

          <FiltersForm
            visible={isFiltersVisible}
            onClose={() => setFiltersVisible(false)}
            initValues={reqParams}
            onFiltersChange={setParams}
          />
        </Row>
      </Space>

      <Content>
        <CheckboxGroup
          onChange={(selected: string[]) => {
            setIdFormsToDelete(selected)
          }}
          value={idFormsToDelete}
        >
          <Space size={10} wrap>
            {forms.map((form, i) => (
              <ManagementCardForm
                key={`${i}-${form.idForm}`}
                form={form}
                onFormOpen={onFormOpen}
                onFormCopy={onFormCopy}
                onFormDelete={onFormDelete}
                onBookmarkToggle={onFormBookmarkToggle}
                canSelect
              />
            ))}
          </Space>
        </CheckboxGroup>
      </Content>
      <ActionModal
        open={isOpen}
        title={translate('common.deleting')}
        icon={'Trash02'}
        iconColor={colors.error600}
        iconBgColor={colors.error100}
        onConfirm={() => onFormDeleteConfirm()}
        onCancel={close}
        actionButtons={[
          {
            text: translate('common.buttons.return'),
            onClick: close,
          },
          {
            type: 'primary',
            text: translate('common.buttons.delete'),
            onClick: () => onFormDeleteConfirm(),
          },
        ]}
      >
        <Text>{translate('common.areYouSure')}</Text>
      </ActionModal>
      {/*FIXME: move pagination to common with predefined styles*/}
      <Row justify={'end'} style={{ width: '100%', padding: '10px 0' }}>
        <Pagination {...pagination} />
      </Row>
    </>
  )
}

export default ListForm
