/** @jsxImportSource @emotion/react */
import { ILayoutProps, LayoutContainer } from 'common-components'
import styled from '@emotion/styled'

const StyledLayoutContainer = styled(LayoutContainer)`
  max-width: 1216px;
  margin: auto;
`

const PageLayout = function ({ children, ...rest }: ILayoutProps) {
  return (
    <StyledLayoutContainer theme={'light'} {...rest}>
      {children}
    </StyledLayoutContainer>
  )
}

export default PageLayout
