import { useEffect, useState } from 'react'
import { useTranslation } from '@hooks'
import { UpdateProjectSettingsPayloadType } from '@api/project'
import { Dayjs } from 'dayjs'
import { TAlertType, TSlaType } from '@definitions'
import { ProjectType } from '@pages/types'
import { Button, Form, FormElements, Space } from 'common-components'
import { LoadingOutlined } from '@ant-design/icons'

import { SlaCollapse, WebhookPanel, ParametersCollapse } from '../../shared'

import { store } from '@/store'

type Step2CreateProjectPropsType = {
  project: ProjectType
  isLoadingNextStep: boolean
  nextStep: () => void
}

type FormValuesType = {
  slaType: TSlaType
  delay: Dayjs
  leverageHours?: {
    nbDays: number
    leverageTime: Dayjs
    deleveringTime: Dayjs
  }[]
  averageDeleveringTimeGoal: Dayjs
  effectiveTimeTaskGoal: Dayjs
  effectiveTimeHitGoal: Dayjs
  qualityExpected: number
  diversification: number
  nbMinEvaluation: number
  expectedQualityMin: number
  urlWebhook: string
  smsAlert: TAlertType
  emailAlert: TAlertType
}

const Step2CreateProject = ({
  project,
  isLoadingNextStep,
  nextStep,
}: Step2CreateProjectPropsType) => {
  const { translate } = useTranslation()
  const [form] = FormElements.useForm<FormValuesType>()

  const [managersProject, setManagersProject] = useState<ProjectType['managers']>([])

  const updateSettingsProjectAction = store.use.updateSettingsProjectAction().call
  const addManagersToProjectAction = store.use.addManagersToProjectAction().call

  const isLoadingUpdateSettingsProjectAction =
    store.use.updateSettingsProjectAction().status === 'LOADING'
  const isLoadingAddManagersToProjectAction =
    store.use.addManagersToProjectAction().status === 'LOADING'

  useEffect(() => {
    if (!project) return
    if (project.settings?.urlWebhook) {
      form.setFieldValue('urlWebhook', project?.settings?.urlWebhook)
    }
    if (project.managers?.length) {
      const managersProject = project.managers.map((manager) => ({
        idUser: manager.idUser,
        email: manager.email,
        firstName: manager.firstName,
        lastName: manager.lastName,
        type: manager.type,
        urlAvatar: manager.urlAvatar,
        managerRight: manager.managerRight,
      }))
      setManagersProject(managersProject)
    }
  }, [project])

  const handleSubmit = async (values: FormValuesType) => {
    const updateProjectSettingsPayload: UpdateProjectSettingsPayloadType = {
      id: project.idProject,
      name: project?.name,
      sla: {
        type: values?.slaType || project?.sla?.type,
        delay: values.delay?.format('HH:mm:ss') || project?.sla?.delay,
        leverageHours:
          values.leverageHours?.map((prop) => ({
            leverageTime: prop.leverageTime.format('HH:mm:ss'),
            nbDays: prop.nbDays,
            deleveringTime: prop.deleveringTime.format('HH:mm:ss'),
          })) || project?.sla?.leverageHours,
        averageDeleveringTimeGoal:
          values.averageDeleveringTimeGoal?.format('HH:mm:ss') ||
          project?.sla?.averageDeleveringTimeGoal,
        effectiveTimeTaskGoal:
          values.effectiveTimeTaskGoal?.format('HH:mm:ss') || project?.sla?.effectiveTimeTaskGoal,
        effectiveTimeHitGoal:
          values.effectiveTimeHitGoal?.format('HH:mm:ss') || project?.sla?.effectiveTimeHitGoal,
        qualityExpected: values.qualityExpected || project?.sla?.qualityExpected,
      },
      settings: {
        diversification: values.diversification || project?.settings?.diversification,
        nbMinEvaluation: values.nbMinEvaluation || project?.settings?.nbMinEvaluation,
        expectedQualityMin: values.expectedQualityMin || project?.settings?.expectedQualityMin,
        urlWebhook: values?.urlWebhook,
        smsAlert:
          typeof values?.smsAlert === 'number'
            ? values?.smsAlert
            : project?.settings?.smsAlert || 0,
        emailAlert:
          typeof values?.emailAlert === 'number'
            ? values?.emailAlert
            : project?.settings?.emailAlert || 0,
      },
    }

    const updateProjectManagerPayload = {
      idProject: project.idProject,
      managers: managersProject.map((item) => ({
        idUser: item.idUser,
        email: item.email,
        firstName: item.firstName,
        lastName: item.lastName,
        type: item.type,
        urlAvatar: item.urlAvatar,
        managerRight: item.managerRight,
      })),
    }

    updateSettingsProjectAction(
      { idProject: project.idProject, payload: updateProjectSettingsPayload },
      () => {
        addManagersToProjectAction(updateProjectManagerPayload, () => {
          nextStep()
        })
      },
    )
  }

  return (
    <Form form={form} onFinish={handleSubmit}>
      <Space direction='vertical' style={{ width: '100%', gap: 16 }}>
        <SlaCollapse
          type={project?.sla?.type}
          leverageHours={project?.sla?.leverageHours}
          delay={project?.sla?.delay}
        />
        {/* HIDE
				<ProductivityTargetsCollapse
					averageDeleveringTimeGoal={project?.sla?.averageDeleveringTimeGoal}
					effectiveTimeTaskGoal={project?.sla?.effectiveTimeTaskGoal}
					effectiveTimeHitGoal={project?.sla?.effectiveTimeHitGoal}
					qualityExpected={project?.sla?.qualityExpected}
				/>
				<AlertsCollapse page="create"
					smsAlert={project?.settings?.smsAlert}
					emailAlert={project?.settings?.emailAlert}
				/>*/}
        <ParametersCollapse
          page='create'
          diversification={project?.settings?.diversification}
          nbMinEvaluation={project?.settings?.nbMinEvaluation}
          expectedQualityMin={project?.settings?.expectedQualityMin}
          managersProject={managersProject}
          setManagersProject={setManagersProject}
        />
        <WebhookPanel urlWebhook={project?.settings?.urlWebhook} />
        <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
          <Button
            disabled={
              isLoadingAddManagersToProjectAction ||
              isLoadingUpdateSettingsProjectAction ||
              isLoadingNextStep
            }
            type='primary'
            htmlType='submit'
            style={{
              width: '150px',
            }}
          >
            {isLoadingAddManagersToProjectAction ||
            isLoadingUpdateSettingsProjectAction ||
            isLoadingNextStep ? (
              <LoadingOutlined />
            ) : (
              translate('projectCreatePage.submitButton.step1')
            )}
          </Button>
        </Space>
      </Space>
    </Form>
  )
}

export default Step2CreateProject
