import { Contributor } from '@views/contributor'
import { useParams } from 'react-router-dom'
import { Loader } from '@components/utils'
import { useDelayedEffect } from '@hooks'

import { store } from '@/store'

const ContributorPage = () => {
  const { id } = useParams<{ id: string }>()

  const contributor = store.use
    .contributors()
    ?.find((contributor) => contributor.userData.idUser === id)

  const getInformationContributorAction = store.use.getInformationContributorAction().call
  const isLoadingGetInformationContributorAction =
    store.use.getInformationContributorAction().status === 'LOADING'

  useDelayedEffect(() => {
    getInformationContributorAction({ id })
  }, [id])

  return (
    <>
      {isLoadingGetInformationContributorAction && <Loader />}
      {!isLoadingGetInformationContributorAction && contributor && (
        <Contributor contributor={contributor} />
      )}
    </>
  )
}

export default ContributorPage
