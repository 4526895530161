import { useMemo, useState } from 'react'
import { useTranslation } from '@hooks/useTranslation'
import { ColumnsType } from 'antd/lib/table'
import { TaskOriginType } from '@definitions/states'
import { sortDates, sortNumbers } from '@utils/sorters'
import { PresetTag, DataLengthTag } from '@components/display'
import { ItemType } from 'antd/es/menu/hooks/useItems'
import { routes } from '@navigation/routes'
import { useNavigate, useParams } from 'react-router-dom'
import { TaskType } from '@pages/types'
import {
  Content,
  Space,
  Dropdown,
  Tag,
  Text,
  Table,
  Row,
  colors,
  Button,
  Icon,
  Title,
  ITableProps,
} from 'common-components'
import { LoadingOutlined } from '@ant-design/icons'

import TableHits from './TableHits'

import { store } from '@/store'

type ProjectTasklinesPropsType = {
  tasks: TaskType[]
  tableProps?: Omit<ITableProps<TaskType>, 'data' | 'columns'>
}

const TasksProject = ({ tasks, tableProps = {} }: ProjectTasklinesPropsType) => {
  const { translate } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()

  const hits = store.use.hits()

  const getHitsAction = store.use.getHitsAction().call
  const deleteTaskAction = store.use.deleteTaskAction().call
  const increasePriorityTaskAction = store.use.increasePriorityTaskAction().call
  const decreasePriorityTaskAction = store.use.decreasePriorityTaskAction().call

  const isLoadingGetHitsAction = store.use.getHitsAction().status === 'LOADING'
  const isSucceededGetHitsAction = store.use.getHitsAction().status === 'SUCCEEDED'

  const [expandedRowKeys, setExpandedRowKeys] = useState([])

  const getDropdownItems = (taskId: string, priority: number): ItemType[] => {
    const dropDownItems = []

    dropDownItems.push({
      key: 1,
      label: <Text>{translate('projectsPage.dropdown.open')}</Text>,
      icon: <Icon icon={'ArrowCircleBrokenRight'} size={16} />,
      onClick: () => navigate(routes.projectTask.navigate(params.projectId, taskId)),
    })
    dropDownItems.push({
      key: 2,
      label: <Text>{translate('project.tasklineOverview.actionsItems.requestPrioritizing')}</Text>,
      icon: <Icon icon={'ArrowUp'} />,
      onClick: () => increasePriorityTaskAction({ idTask: taskId }),
    })
    if (priority > 1) {
      dropDownItems.push({
        key: 3,
        label: (
          <Text>{translate('project.tasklineOverview.actionsItems.requestDeprioritizing')}</Text>
        ),
        icon: <Icon icon={'ArrowDown'} />,
        onClick: () => decreasePriorityTaskAction({ idTask: taskId }),
      })
    }
    dropDownItems.push({
      key: 4,
      label: <Text>{translate('project.tasklineOverview.actionsItems.deleteTask')}</Text>,
      icon: <Icon icon={'Trash01'} />,
      onClick: () => deleteTaskAction({ idTask: taskId }),
    })
    return dropDownItems
  }

  const getPriorityTag = (priority: TaskType['priority']) => {
    switch (true) {
      case priority >= 0 && priority < 20:
        return (
          <Tag style={{ background: colors.grayblue50, color: colors.grayblue500 }}>{priority}</Tag>
        )
      case priority >= 20 && priority < 40:
        return (
          <Tag
            style={{
              background: colors.grayblue200,
              color: colors.grayblue500,
            }}
          >
            {priority}
          </Tag>
        )
      case priority >= 40 && priority < 60:
        return (
          <Tag
            style={{
              background: colors.grayblue500,
              color: colors.grayblue100,
            }}
          >
            {priority}
          </Tag>
        )
      case priority >= 60 && priority < 80:
        return (
          <Tag style={{ background: colors.grayblue800, color: colors.grayblue25 }}>{priority}</Tag>
        )
      case priority >= 80:
        return (
          <Tag style={{ background: colors.grayblue900, color: colors.grayblue25 }}>{priority}</Tag>
        )
      default:
        return (
          <Tag style={{ background: colors.grayblue900, color: colors.grayblue25 }}>{priority}</Tag>
        )
    }
  }

  const tableColumns: ColumnsType<TaskType> = useMemo(() => {
    try {
      return [
        {
          title: translate('project.taskline.table.id'),
          key: 'idTask',
          width: 115,
          sorter: (a, b) => sortNumbers(a.idTask, b.idTask),
          render: (record: TaskType) => (
            <Row align={'middle'}>
              <Space size={'middle'}>
                <Text size='sm' strong color={colors.gray900}>
                  #{record.idTask}
                </Text>
              </Space>
            </Row>
          ),
        },
        {
          title: translate('project.taskline.table.creationDate'),
          key: 'creationDate',
          width: 125,
          sorter: (a, b) => sortDates(a.creationDate, b.creationDate),
          render: (record: TaskType) => (
            <Row align={'middle'}>
              <Space size={'middle'}>
                <Text size='xs'>{record.creationDate}</Text>
              </Space>
            </Row>
          ),
        },
        {
          title: translate('project.taskline.table.origin'),
          key: 'origin',
          width: 125,
          sorter: (a, b) => sortNumbers(a.origin, b.origin),
          render: (record: TaskType) => (
            <Row align={'middle'}>
              <Space size={'middle'}>
                <Text size='xs'>{TaskOriginType[record.origin]}</Text>
              </Space>
            </Row>
          ),
        },
        {
          title: translate('project.taskline.table.priority'),
          key: 'priority',
          width: 91,
          sorter: (a, b) => sortNumbers(a.priority, b.priority),
          render: (record: TaskType) => (
            <Row align={'middle'}>
              <Space size={'middle'}>{getPriorityTag(record.priority)}</Space>
            </Row>
          ),
        },
        {
          title: translate('project.taskline.table.deadline'),
          key: 'deleveringDate',
          width: 138,
          sorter: (a, b) => sortDates(a?.deleveringDate, b?.deleveringDate),
          render: (record: TaskType) => (
            <Row align={'middle'}>
              <Space size={'middle'}>
                <Text size='xs'>{record?.deleveringDate || '-'}</Text>
              </Space>
            </Row>
          ),
        },
        {
          title: translate('project.taskline.table.state'),
          key: 'taskState',
          width: 136,
          sorter: (a, b) => sortNumbers(a.taskState, b.taskState),
          render: (record: TaskType) => (
            <Row align={'middle'}>
              <Space size={'middle'}>
                <PresetTag withDot preset='task-state' state={record.taskState}>
                  {translate(`common.states.taskState.${record.taskState}`)}
                </PresetTag>
              </Space>
            </Row>
          ),
        },
        /* HIDE
       * {
        title: translate("project.taskline.table.costs"),
        key: "totalCost",
        dataIndex: "totalCost",
        width: 86,
        sorter: (a, b) => sortNumbers(a.totalCost, b.totalCost),
        render: (totalCost) => (
          <Row align={"middle"}>
            <Space size={"middle"}>
              <Text size="xs">{totalCost} €</Text>
            </Space>
          </Row>
        ),
      },
      {
        title: translate("project.taskline.table.provider"),
        key: "provider",
        dataIndex: "provider",
        width: 141,
        render: () => (
          <Row align={"middle"}>
            <Space size={"middle"}>
            </Space>
          </Row>
        ),
      },*/
        {
          title: translate('project.taskline.table.note'),
          key: 'note',
          width: 114,
          render: () => (
            <Row align={'middle'}>
              <Space size={'middle'}>-</Space>
            </Row>
          ),
        },
        {
          title: translate('project.taskline.table.time'),
          key: 'effectiveTime',
          width: 86,
          render: (record: TaskType) => (
            <Row align={'middle'}>
              <Space size={'middle'}>{record.effectiveTime}</Space>
            </Row>
          ),
        },
        /* HIDE
      {
        title: translate("project.taskline.table.model"),
        key: "accuracyRateModel",
        dataIndex: "accuracyRateModel",
        width: 79,
        render: (accuracyRateModel) => (
          <Row align={"middle"}>
            <Space size={"middle"}>{accuracyRateModel && `${accuracyRateModel}%`}</Space>
          </Row>
        ),
      },*/
        {
          key: 'action',
          width: 63,
          render: (record: TaskType) => (
            <Row align={'middle'} justify={'center'}>
              <Dropdown
                menu={{ items: getDropdownItems(record.idTask, record.priority) }}
                placement={'bottomRight'}
                overlayStyle={{ width: 240 }}
              >
                <Icon icon={'DotsVertical'} size={20} />
              </Dropdown>
            </Row>
          ),
        },
      ]
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
      return []
    }
  }, [])

  const handleExpand = (expanded: boolean, record: TaskType) => {
    if (expanded) {
      getHitsAction({ idTask: record.idTask })
    }
    setExpandedRowKeys(expanded ? [record.idTask] : [])
  }

  return (
    <Content>
      <Space
        align='center'
        style={{
          width: '100%',
          marginBottom: 16,
          justifyContent: 'space-between',
        }}
      >
        <Space>
          <Title preset='singlePageTitle'>{translate('project.taskline.title')}</Title>
          <DataLengthTag>{tasks?.length || 0}</DataLengthTag>
        </Space>
        <Button iconLeft='FilterLines'>Filter</Button>
      </Space>
      <Table<TaskType>
        data={tasks}
        columns={tableColumns}
        rowKey={(task) => task.idTask}
        expandable={{
          defaultExpandAllRows: false,
          onExpand: handleExpand,
          expandedRowKeys: expandedRowKeys,
          expandedRowRender: (task) => (
            <>
              {isLoadingGetHitsAction && (
                <div
                  style={{
                    display: 'flex',
                    margin: '10px',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <LoadingOutlined style={{ fontSize: '20px' }} />
                </div>
              )}
              {isSucceededGetHitsAction && (
                <TableHits hits={hits} taskId={task.idTask} projectId={task.project.idProject} />
              )}
            </>
          ),
        }}
        {...tableProps}
      />
    </Content>
  )
}

export default TasksProject
