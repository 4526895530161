const fr = {
  apply: 'Appliquez des filtres',
  filter: 'Filtrer',

  certifications: 'Certifications',
  endDate: 'Date de fin',
  category: 'Catégorie',
  managers: 'Managers',
  models: 'Modèles',
  role: 'Rôle',
  segments: 'Segments',
  startDate: 'Date de début',
  skill: 'Compétence',
  state: 'Statut',
  quality: 'Qualité',
  risk: 'Risque',
  score: 'Trouver un score spécifique ou des valeurs plus élevées',

  deleting: 'Vous êtes sur le point de supprimer cet élément',
  areYouSure: 'Êtes-vous sûr ?',

  buttons: {
    confirm: 'Confirmer',
    delete: 'Supprimer',
    edit: 'Editer',
    goBack: 'Retourner',
    return: 'Retour',
    showLess: 'Afficher moins',
    showMore: 'Afficher plus',
    open: 'Ouvrir',
  },

  states: {
    certificationState: {
      1: 'Projet',
      2: 'Projet',
      3: 'Projet',
      4: 'Projet',
      10: 'En ligne',
      20: 'Hors ligne',
      100: 'Archivé',
    },
    projectState: {
      1: 'Brouillon',
      2: 'Brouillon',
      3: 'Brouillon',
      4: 'Brouillon',
      10: 'En ligne',
      15: 'Boosté',
      20: 'Terminé',
      30: 'Archivé',
      40: 'Arrêter',
      50: 'En pause',
    },
    eventType: {
      //TASK EVENTS
      100: 'Tâche créée',
      101: 'Modèle extrait',
      102: 'Résultat initial créé',
      103: 'Comparaison des résultats',
      104: 'Résultat supplémentaire créé',
      105: "Création d'une révision de résultat",
      106: "Création d'un résultat de substitution",
      107: 'Tâche accomplie',

      // ÉVÉNEMENTS DE RÉUSSITE
      200: 'Résultat positif créé',
      201: 'Le résultat fonctionne',
      202: 'Validation manuelle du résultat',
      203: 'Résultat validé automatiquement',
      204: "Attribution automatique d'un résultat",
      205: "Attribution manuelle d'un résultat",
      206: 'Révision du résultat ajoutée',
      207: 'Vérification effectuée',

      // ÉVÉNEMENTS RELATIFS AUX DOCUMENTS
      300: 'Document créé',
      301: 'Modèle de document évalué',
      302: 'Document extrait',
      303: "Résultat de l'extraction du document",
    },
    formState: {
      10: 'Création',
      20: 'Publié',
      99: 'Archivé',
    },
    modelCategory: {
      10: 'Owned',
      100: 'Wirk',
    },
    modelState: {
      10: 'Créer',
      20: 'Former',
      30: 'En ligne',
      100: 'Erreur',
      999: 'Archivé',
    },
    taskState: {
      10: 'En création',
      20: 'En attente',
      30: 'En cours',
      80: 'Urgente',
      90: 'En retard',
      100: 'Terminée',
      900: 'En erreur',
      999: 'Annulée',
    },
    hitType: {
      1: 'Initial',
      2: 'Itération',
      3: 'Arbitrage',
      4: 'Révision',
      5: 'Annulation',
    },
    hitState: {
      1: 'En création',
      2: 'En attente',
      3: 'En cours',
      4: 'Terminé',
      5: 'Validé',
      6: 'Refusé',
      100: 'Annulé',
    },
    segmentState: {
      10: 'Compétence',
      20: 'Manuel',
      30: 'Certification',
      40: 'Socio-démographique',
    },
    skill: {
      0: 'Saisie simple',
      1: 'Saisie complexe',
      2: 'Analyse de documents simple',
      3: 'Analyse de documents complexes',
      4: 'Analyse de dossiers',
      5: 'Rédaction de contenu',
      6: 'Recherche sur le web',
      7: 'Délimitation',
      8: 'Transcription',
      9: 'Appel sortant',
      10: 'Appel entrant',
      11: 'Bureautique',
    },
    quality: {
      10: 'Simple',
      20: 'Multi',
      30: 'Panel',
      40: 'Relecture',
    },
    userCertificationState: {
      0: 'Nouveau',
      10: 'En cours',
      20: 'Terminé',
      30: 'Révisé',
    },
    riskState: {
      0: 'Nouveau',
      10: 'En cours',
      20: 'Réalisé',
      90: 'Erreur',
      99: 'Archivé',
    },
  },
}

export default fr
