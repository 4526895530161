const en = {
  invitationSent: 'You have invited a user',
  invitationAccepted: 'was invited to collaborate on Wirk.',
  contributorInfo: 'Contributor',
  metrics: 'Metrics',
  information: 'Information',
  segments: 'Segments',
  certifications: 'Certifications',
  activity: 'Activity',
  export: 'Export',
  filter: 'Filter',
  workedHours: 'Effective time worked',
  numberOfProjects: 'Number of projects',
  volume: 'Volume',
  projectName: 'Project name',
  lastTask: 'Last task',
  tasksCompleted: 'Tasks done',
  pace: 'Pace',
  effectiveTime: 'Effective time',
  distribution: 'Distribution',
  quality: 'Quality',
  delete: {
    button: 'Delete',
    modal: {
      title: 'Delete contributor ?',
      label: 'Are you sure you want to perform this action ?',
    },
  },
}

export default en

export type Translations = typeof en
