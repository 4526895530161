import { Avatar, IDropdownProps, IAvatarProps } from 'common-components'

import { store } from '@/store'

type ProfileDropdownPropsType = Omit<IDropdownProps, 'menu'> & {
  avatarProps?: IAvatarProps
}

export const ProfileDropdown = ({ avatarProps = {} }: ProfileDropdownPropsType) => {
  const me = store.use.me()

  // const items: Array<ItemType> = [
  // 	{
  // 		key: "0",
  // 		label: (
  // 			<Row>
  // 				<Space size={"middle"}>
  // 					<Badge
  // 						dot
  // 						color={"blue"} // colors.success500
  // 						offset={[-64 * 0.05, 64 * 0.75]}
  // 					>
  // 						<Avatar size={"2xl"} />
  // 					</Badge>
  // 					<Col style={{ alignItems: "center" }}>
  // 						<Paragraph>User Name</Paragraph>
  // 						<Paragraph>example@email.com</Paragraph>
  // 					</Col>
  // 				</Space>
  // 			</Row>
  // 		),
  // 	},
  // 	{
  // 		type: "divider",
  // 	},
  // 	{
  // 		key: "1",
  // 		label: (
  // 			<a target="_blank" rel="noopener noreferrer" href="#">
  // 				View profile
  // 			</a>
  // 		),
  // 		icon: <Icon icon={"User01"} />,
  // 	},
  // 	{
  // 		key: "2",
  // 		label: (
  // 			<a target="_blank" rel="noopener noreferrer" href="#">
  // 				Settings
  // 			</a>
  // 		),
  // 		icon: <Icon icon={"Settings01"} />,
  // 	},
  // 	{
  // 		key: "3",
  // 		label: (
  // 			<a target="_blank" rel="noopener noreferrer" href="#">
  // 				Keyboard shortcuts
  // 			</a>
  // 		),

  // 		icon: <Icon icon={"Zap"} />,

  // 		disabled: true,
  // 	},
  // 	{
  // 		type: "divider",
  // 	},
  // 	{
  // 		key: "4",
  // 		label: (
  // 			<a target="_blank" rel="noopener noreferrer" href="#">
  // 				Company profile
  // 			</a>
  // 		),
  // 		icon: <Icon icon={"HomeLine"} />,
  // 	},
  // 	{
  // 		key: "5",
  // 		label: (
  // 			<a target="_blank" rel="noopener noreferrer" href="#">
  // 				Team
  // 			</a>
  // 		),
  // 		icon: <Icon icon={"Users01"} />,
  // 	},
  // 	{
  // 		key: "6",
  // 		label: (
  // 			<a target="_blank" rel="noopener noreferrer" href="#">
  // 				Invite colleagues
  // 			</a>
  // 		),
  // 		icon: <Icon icon={"UserPlus01"} />,
  // 	},
  // 	{
  // 		type: "divider",
  // 	},
  // 	{
  // 		key: "7",
  // 		label: (
  // 			<a target="_blank" rel="noopener noreferrer" href="#">
  // 				Changelog
  // 			</a>
  // 		),
  // 		icon: <Icon icon={"LayersTwo01"} />,
  // 	},
  // 	{
  // 		key: "8",
  // 		label: (
  // 			<a target="_blank" rel="noopener noreferrer" href="#">
  // 				Slack community
  // 			</a>
  // 		),
  // 		icon: <Icon icon={"MessageSmileCircle"} />,
  // 	},
  // 	{
  // 		key: "9",
  // 		label: (
  // 			<a target="_blank" rel="noopener noreferrer" href="#">
  // 				Support
  // 			</a>
  // 		),
  // 		icon: <Icon icon={"HelpCircle"} />,
  // 	},
  // 	{
  // 		key: "10",
  // 		label: (
  // 			<a target="_blank" rel="noopener noreferrer" href="#">
  // 				API
  // 			</a>
  // 		),
  // 		icon: <Icon icon={"Container"} />,
  // 	},
  // 	{
  // 		type: "divider",
  // 	},
  // 	{
  // 		key: "11",
  // 		label: (
  // 			<a target="_blank" rel="noopener noreferrer" href="#">
  // 				Log out
  // 			</a>
  // 		),
  // 		icon: <Icon icon={"LogOut01"} />,
  // 		onClick: async () => {
  // 			// await auth.removeUser()
  // 			await auth.signoutRedirect();
  // 		},
  // 	},
  // ];

  return (
    // <Dropdown menu={{ items, triggerSubMenuAction: "hover" }} {...rest}>
    <Avatar src={me?.userData?.urlAvatar} size={'md'} {...avatarProps} />

    // </Dropdown>
  )
}
