/** @jsxImportSource @emotion/react */

import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { css } from '@emotion/react'
import { ModelCategory, ModelState } from '@definitions'
import { StateSelect } from '@components/inputs'
import { useTranslation } from '@hooks'
import { Col, Form, FormItem, Row } from 'common-components'
import { GetModelsPayloadType } from '@api'

type FilterBarModelPropsType = {
  onFiltersChange?: (filters: GetModelsPayloadType) => void
  onClose?: () => void
  initValues?: GetModelsPayloadType
}

const FiltersBarModel = ({ onClose, onFiltersChange, initValues }: FilterBarModelPropsType) => {
  const { control, handleSubmit, watch, getValues, reset } = useForm<GetModelsPayloadType>({
    defaultValues: { model: {}, ...(initValues || {}) },
  })
  const { translate } = useTranslation()

  useEffect(() => {
    reset(initValues)
  }, [initValues])

  const onSubmit = () => {
    const data = getValues()
    onFiltersChange && onFiltersChange(data)
    onClose && onClose()
  }

  useEffect(() => {
    const subscription = watch(() => handleSubmit(onSubmit)())
    return () => subscription.unsubscribe()
  }, [watch()])

  return (
    <Form onFinish={handleSubmit(onSubmit)} css={$form}>
      <Row gutter={10}>
        <Col span={8}>
          <Controller
            name={'model.category'}
            control={control}
            render={({ field }) => (
              <FormItem label={translate('common.quality')}>
                <StateSelect
                  values={ModelCategory}
                  labelTxKey={'modelCategory'}
                  onChange={field.onChange}
                  value={field.value}
                />
              </FormItem>
            )}
          />
        </Col>
        <Col span={8}>
          <Controller
            name={'model.state'}
            control={control}
            render={({ field }) => (
              <FormItem label={translate('common.state')}>
                <StateSelect
                  values={ModelState}
                  labelTxKey={'modelState'}
                  onChange={field.onChange}
                  value={field.value}
                />
              </FormItem>
            )}
          />
        </Col>
      </Row>
    </Form>
  )
}

const $form = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export default FiltersBarModel
