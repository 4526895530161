import {
  createUser,
  CreateUserPayloadType,
  deleteUser,
  downloadSampleImportUsersFile,
  downloadSuppliers,
  DownloadSuppliersPayloadType,
  getSuppliers,
  GetSuppliersPayloadType,
  getUser,
  GetUserPayloadType,
  importUsers,
  ImportUsersPayloadType,
  updateUser,
  updateUserAddress,
  updateUserSociodemographic,
  uploadUserPhoto,
  UploadUserPhotoPayloadType,
} from '@api/user'
import { INVALID_EMAIL_HTTP_STATUS } from '@api/constant'
import { downloadBlob, shortTime } from '@utils'
import { TUserType } from '@definitions'
import {
  addManagerToSegment,
  addUsersToSegment,
  deleteManagerFromSegment,
  deleteUsersFromSegment,
  getSegmentsSupplier,
  GetSegmentsSupplierPayloadType,
} from '@api/segment'
import { ContributorType, SegmentShortType, UserDataType } from '@pages/types'
import { TxKeyPath } from '@i18n'
import { getCertificationUserTests } from '@api/certification'
import { UserShortApiType } from '@api/types'

import { getApiError, getSuccess } from '../helper'
import { SetState } from '../store.type'
import { StoreGlobalType } from '../store'

import { ContributorSliceType } from './contributor.slice'

import {
  certificationUserTestApiMapper,
  segmentApiMapper,
  userDataApiMapper,
  segmentShortApiMapper,
} from '@/mappers'

/**
 * "synchronizeContributors"
 */
const synchronizeContributors = async (
  payload?: GetContributorsActionPayloadType,
): Promise<ContributorType[]> => {
  const usersApi = await getSuppliers(payload)
  return usersApi.map((userApi): ContributorType => {
    const userData = userDataApiMapper(userApi)
    const segmentsShort = userApi?.segments?.map(segmentShortApiMapper)
    return {
      userData,
      segmentsShort,
    }
  })
}

/****************************************************************
 * GET CONTRIBUTORS
 *****************************************************************/

type GetContributorsActionStatusType =
  | 'getContributors/loading'
  | 'getContributors/succeeded'
  | 'getContributors/failed'

export type GetContributorsActionPayloadType = GetSuppliersPayloadType

export const getContributorsAction =
  (
    set: SetState<
      Partial<ContributorSliceType & StoreGlobalType>,
      GetContributorsActionStatusType,
      GetContributorsActionPayloadType
    >,
  ) =>
  async (payload: GetContributorsActionPayloadType) => {
    try {
      set(
        (state) => {
          state.getContributorsAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'getContributors/loading', payload },
      )

      const contributors = await synchronizeContributors(payload)

      set(
        (state) => {
          state.getContributorsAction.status = 'SUCCEEDED'
          state.contributors = contributors
        },
        false,
        { type: 'getContributors/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.getContributorsAction.status = 'FAILED'
          state.contributors = []
          state.error = getApiError(e)
        },
        false,
        { type: 'getContributors/failed' },
      )
    }
  }

/******************************************************************
 * GET CONTRIBUTOR
 *****************************************************************/

type GetContributorActionStatusType =
  | 'getInformationContributor/loading'
  | 'getInformationContributor/succeeded'
  | 'getInformationContributor/failed'

export type GetContributorActionPayloadType = GetUserPayloadType
export const getInformationContributorAction =
  (
    set: SetState<
      Partial<ContributorSliceType & StoreGlobalType>,
      GetContributorActionStatusType,
      GetContributorActionPayloadType
    >,
  ) =>
  async (payload: GetContributorActionPayloadType) => {
    try {
      set(
        (state) => {
          state.getInformationContributorAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'getInformationContributor/loading', payload },
      )

      const userApi = await getUser(payload)
      const contributor: ContributorType = {
        userData: userDataApiMapper(userApi),
      }

      set(
        (state) => {
          state.getInformationContributorAction.status = 'SUCCEEDED'

          const index = state.contributors?.findIndex(
            ({ userData }) => userData.idUser === payload.id,
          )
          if (index >= 0) {
            state.contributors[index] = contributor
          } else {
            state.contributors.unshift(contributor)
          }
        },
        false,
        { type: 'getInformationContributor/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.getInformationContributorAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'getInformationContributor/failed' },
      )
    }
  }

/******************************************************************
 * ADD SEGMENT TO CONTRIBUTOR
 *****************************************************************/

type AddSegmentToContributorActionStatusType =
  | 'addSegmentToContributor/loading'
  | 'addSegmentToContributor/succeeded'
  | 'addSegmentToContributor/failed'

export type AddSegmentToContributorActionPayloadType = {
  idContributor: string
  idSegment: string
}

export const addSegmentToContributorAction =
  (
    set: SetState<
      Partial<ContributorSliceType & StoreGlobalType>,
      AddSegmentToContributorActionStatusType,
      AddSegmentToContributorActionPayloadType
    >,
    get: () => StoreGlobalType & ContributorSliceType,
  ) =>
  async (payload: AddSegmentToContributorActionPayloadType) => {
    try {
      set(
        (state) => {
          state.addSegmentToContributorAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'addSegmentToContributor/loading', payload },
      )

      const contributor = get().contributors.find(
        ({ userData }) => userData.idUser === payload.idContributor,
      )

      const segmentApi = await addUsersToSegment({
        id: payload.idSegment,
        users: [
          {
            idUser: contributor.userData.idUser,
            email: contributor.userData.email,
            firstName: contributor.userData.firstName,
            lastName: contributor.userData.lastName,
            type: contributor.userData.type,
            urlAvatar: contributor.userData.urlAvatar,
          },
        ],
      })

      const idMe = get().me.userData.idUser
      const segment = segmentApiMapper(segmentApi, idMe)

      set(
        (state) => {
          state.addSegmentToContributorAction.status = 'SUCCEEDED'
          state.segmentsContributor.unshift(segment)
        },
        false,
        { type: 'addSegmentToContributor/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.addSegmentToContributorAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'addSegmentToContributor/failed' },
      )
    }
  }

/******************************************************************
 * DELETE SEGMENT FROM CONTRIBUTOR
 *****************************************************************/

type DeleteSegmentFromContributorActionStatusType =
  | 'deleteSegmentFromContributor/loading'
  | 'deleteSegmentFromContributor/succeeded'
  | 'deleteSegmentFromContributor/failed'

export type DeleteSegmentFromContributorActionPayloadType = {
  idContributor: string
  idSegment: string
}

export const deleteSegmentFromContributorAction =
  (
    set: SetState<
      Partial<ContributorSliceType & StoreGlobalType>,
      DeleteSegmentFromContributorActionStatusType,
      DeleteSegmentFromContributorActionPayloadType
    >,
    get: () => ContributorSliceType,
  ) =>
  async (payload: DeleteSegmentFromContributorActionPayloadType) => {
    try {
      set(
        (state) => {
          state.deleteSegmentFromContributorAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'deleteSegmentFromContributor/loading', payload },
      )
      const contributor = get().contributors.find(
        (item) => item.userData.idUser === payload.idContributor,
      )

      await deleteUsersFromSegment({
        id: payload.idSegment,
        users: [
          {
            idUser: contributor.userData.idUser,
            email: contributor.userData.email,
            firstName: contributor.userData.firstName,
            lastName: contributor.userData.lastName,
            type: contributor.userData.type,
            urlAvatar: contributor.userData.urlAvatar,
          },
        ],
      })

      set(
        (state) => {
          state.deleteSegmentFromContributorAction.status = 'SUCCEEDED'
          state.segmentsContributor = state.segmentsContributor.filter(
            ({ idSegment }) => idSegment !== payload.idSegment,
          )
        },
        false,
        { type: 'deleteSegmentFromContributor/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.deleteSegmentFromContributorAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'deleteSegmentFromContributor/failed' },
      )
    }
  }

/******************************************************************
 * ADD MANAGER TO SEGMENT CONTRIBUTOR
 *****************************************************************/

type AddManagerToSegmentContributorActionStatusType =
  | 'addManagerToSegmentContributor/loading'
  | 'addManagerToSegmentContributor/succeeded'
  | 'addManagerToSegmentContributor/failed'

export type AddManagerToSegmentContributorActionPayloadType = {
  idContributor: string
  idSegment: string
  manager: UserShortApiType
}

export const addManagerToSegmentContributorAction =
  (
    set: SetState<
      Partial<ContributorSliceType & StoreGlobalType>,
      AddManagerToSegmentContributorActionStatusType,
      AddManagerToSegmentContributorActionPayloadType
    >,
  ) =>
  async (payload: AddManagerToSegmentContributorActionPayloadType) => {
    try {
      set(
        (state) => {
          state.addManagerToSegmentContributorAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'addManagerToSegmentContributor/loading', payload },
      )

      await addManagerToSegment({
        managers: [payload.manager],
        id: payload.idSegment,
      })

      set(
        (state) => {
          state.addManagerToSegmentContributorAction.status = 'SUCCEEDED'

          state.segmentsContributor
            .find(({ idSegment }) => idSegment === payload.idSegment)
            .managersShort.unshift({
              idUser: payload.manager.idUser,
              email: payload.manager.email,
              firstName: payload.manager.firstName,
              lastName: payload.manager.lastName,
              type: payload.manager.type,
              urlAvatar: payload.manager.urlAvatar,
              managerRight: payload.manager.managerRight,
            })
        },
        false,
        { type: 'addManagerToSegmentContributor/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.addManagerToSegmentContributorAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'addManagerToSegmentContributor/failed' },
      )
    }
  }

/******************************************************************
 * DELETE MANAGER FROM SEGMENT CONTRIBUTOR
 *****************************************************************/

type DeleteManagerFromSegmentContributorActionStatusType =
  | 'deleteManagerFromSegmentContributor/loading'
  | 'deleteManagerFromSegmentContributor/succeeded'
  | 'deleteManagerFromSegmentContributor/failed'

export type DeleteManagerFromSegmentContributorActionPayloadType = {
  idContributor: string
  idSegment: string
  manager: UserShortApiType
}

export const deleteManagerFromSegmentContributorAction =
  (
    set: SetState<
      Partial<ContributorSliceType & StoreGlobalType>,
      DeleteManagerFromSegmentContributorActionStatusType,
      DeleteManagerFromSegmentContributorActionPayloadType
    >,
  ) =>
  async (payload: DeleteManagerFromSegmentContributorActionPayloadType) => {
    try {
      set(
        (state) => {
          state.deleteManagerFromSegmentContributorAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'deleteManagerFromSegmentContributor/loading', payload },
      )

      await deleteManagerFromSegment({
        managers: [payload.manager],
        id: payload.idSegment,
      })

      set(
        (state) => {
          state.deleteManagerFromSegmentContributorAction.status = 'SUCCEEDED'

          const indexSegment = state.segmentsContributor.findIndex(
            ({ idSegment }) => idSegment === payload.idSegment,
          )
          if (indexSegment >= 0) {
            state.segmentsContributor[indexSegment].managersShort = state.segmentsContributor[
              indexSegment
            ].managersShort.filter(({ idUser }) => idUser !== payload.manager.idUser)
          }
        },
        false,
        { type: 'deleteManagerFromSegmentContributor/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.deleteManagerFromSegmentContributorAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'deleteManagerFromSegmentContributor/failed' },
      )
    }
  }

/******************************************************************
 * GET SEGMENTS CONTRIBUTOR
 *****************************************************************/

type GetSegmentsContributorActionStatusType =
  | 'getSegmentsContributor/loading'
  | 'getSegmentsContributor/succeeded'
  | 'getSegmentsContributor/failed'

export type GetSegmentsContributorActionPayloadType = GetSegmentsSupplierPayloadType
export const getSegmentsContributorAction =
  (
    set: SetState<
      Partial<ContributorSliceType & StoreGlobalType>,
      GetSegmentsContributorActionStatusType,
      GetSegmentsContributorActionPayloadType
    >,
    get: () => StoreGlobalType,
  ) =>
  async (payload: GetSegmentsContributorActionPayloadType) => {
    try {
      set(
        (state) => {
          state.getSegmentsContributorAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'getSegmentsContributor/loading', payload },
      )

      const segmentsApi = await getSegmentsSupplier({ idContributor: payload.idContributor })
      const idMe = get().me.userData.idUser
      const segments = segmentsApi?.map((segmentApi) => segmentApiMapper(segmentApi, idMe))

      set(
        (state) => {
          state.getSegmentsContributorAction.status = 'SUCCEEDED'
          state.segmentsContributor = segments
        },
        false,
        { type: 'getSegmentsContributor/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.getSegmentsContributorAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'getSegmentsContributor/failed' },
      )
    }
  }

/******************************************************************
 * GET CERTIFICATION TEST CONTRIBUTOR
 *****************************************************************/

type GetCertificationsTestContributorActionStatusType =
  | 'getCertificationsTestContributor/loading'
  | 'getCertificationsTestContributor/succeeded'
  | 'getCertificationsTestContributor/failed'

export type GetCertificationsTestContributorActionPayloadType = {
  idContributor: string
}
export const getCertificationsTestContributorAction =
  (
    set: SetState<
      Partial<ContributorSliceType & StoreGlobalType>,
      GetCertificationsTestContributorActionStatusType,
      GetCertificationsTestContributorActionPayloadType
    >,
    get: () => ContributorSliceType & StoreGlobalType,
  ) =>
  async (payload: GetCertificationsTestContributorActionPayloadType) => {
    try {
      set(
        (state) => {
          state.getCertificationsTestContributorAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'getCertificationsTestContributor/loading', payload },
      )

      const idMe = get().me.userData.idUser
      const certificationUserTestsApi = await getCertificationUserTests({
        model: { idUser: payload.idContributor },
      })
      const certificationsTest = certificationUserTestsApi?.map((certificationUser) =>
        certificationUserTestApiMapper(certificationUser, idMe),
      )

      set(
        (state) => {
          state.getCertificationsTestContributorAction.status = 'SUCCEEDED'
          state.certificationsTestContributor = certificationsTest
        },
        false,
        { type: 'getCertificationsTestContributor/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.getCertificationsTestContributorAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'getCertificationsTestContributor/failed' },
      )
    }
  }

/******************************************************************
 * CREATE CONTRIBUTOR
 *****************************************************************/

type CreateContributorActionStatusType =
  | 'createContributor/loading'
  | 'createContributor/succeeded'
  | 'createContributor/failed'

export type CreateContributorActionPayloadType = CreateUserPayloadType &
  Pick<UploadUserPhotoPayloadType, 'file'>

export const createContributorAction =
  (
    set: SetState<
      Partial<ContributorSliceType & StoreGlobalType>,
      CreateContributorActionStatusType,
      CreateContributorActionPayloadType
    >,
  ) =>
  async (
    payload: CreateContributorActionPayloadType,
    callback: (idContributorCreated: string) => void,
  ) => {
    try {
      set(
        (state) => {
          state.createContributorAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'createContributor/loading', payload },
      )

      let urlAvatar = ''
      const userApi = await createUser({
        type: payload.type,
        phone: payload.phone,
        lastName: payload.lastName,
        firstName: payload.firstName,
        email: payload.email,
      })

      if (payload?.file) {
        urlAvatar = await uploadUserPhoto({
          idUser: userApi.idUser,
          file: payload.file,
        })
      }

      set(
        (state) => {
          state.createContributorAction.status = 'SUCCEEDED'
          state.contributors.unshift({
            userData: {
              ...userDataApiMapper(userApi),
              urlAvatar,
            },
            segmentsShort: [],
          })
        },
        false,
        { type: 'createContributor/succeeded' },
      )
      callback && callback(userApi.idUser)
    } catch (e) {
      // eslint-disable-next-line prefer-const
      let { messageTranslate, duration, ...error } = getApiError(e)

      let descriptionTranslate: TxKeyPath

      if (typeof e === 'object') {
        if ('status' in e) {
          const status = e.status as number
          if (status === INVALID_EMAIL_HTTP_STATUS) {
            messageTranslate = 'managers.createModal.title'
            descriptionTranslate = 'managers.createModal.label'
            duration = 20
          }
        }
      }

      set(
        (state) => {
          state.createContributorAction.status = 'FAILED'
          state.error = {
            ...error,
            duration,
            descriptionTranslate,
            messageTranslate,
          }
        },
        false,
        { type: 'createContributor/failed' },
      )
    }
  }

/******************************************************************
 * UPDATE CONTRIBUTOR
 *****************************************************************/

type UpdateContributorActionStatusType =
  | 'updateContributor/loading'
  | 'updateContributor/succeeded'
  | 'updateContributor/failed'

export type UpdateContributorActionPayloadType = Partial<
  Pick<
    UserDataType,
    | 'idUser'
    | 'phone'
    | 'lastName'
    | 'firstName'
    | 'birthDate'
    | 'nationality'
    | 'earning'
    | 'study'
    | 'family'
    | 'children'
    | 'job'
    | 'activitySector'
    | 'address'
  >
>

export const updateContributorAction =
  (
    set: SetState<
      Partial<ContributorSliceType & StoreGlobalType>,
      UpdateContributorActionStatusType,
      UpdateContributorActionPayloadType
    >,
  ) =>
  async (payload: UpdateContributorActionPayloadType) => {
    try {
      set(
        (state) => {
          state.updateContributorAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'updateContributor/loading', payload },
      )

      await updateUser({
        idUser: payload?.idUser,
        phone: payload?.phone,
        lastName: payload?.lastName,
        firstName: payload?.firstName,
        birthDate: payload?.birthDate ? new Date(payload?.birthDate?.toString()).toISOString() : '',
        nationality: payload.nationality,
      })
      await updateUserSociodemographic({
        idUser: payload?.idUser,
        earning: payload?.earning,
        study: payload?.study,
        family: payload?.family,
        children: payload?.children,
        job: payload?.job,
        activitySector: payload?.activitySector,
      })
      const userApi = await updateUserAddress({
        idUser: payload?.idUser,
        address: payload?.address,
      })

      set(
        (state) => {
          state.updateContributorAction.status = 'SUCCEEDED'

          const index = state.contributors?.findIndex(
            ({ userData }) => userData.idUser === payload.idUser,
          )
          if (index >= 0) {
            state.contributors[index].userData = userDataApiMapper(userApi)
          }

          state.success = getSuccess({
            descriptionT: 'updateUser.notifications.success.description',
          })
        },
        false,
        { type: 'updateContributor/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.updateContributorAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'updateContributor/failed' },
      )
    }
  }

/******************************************************************
 * DOWNLOAD CONTRIBUTOR
 *****************************************************************/
type DownloadContributorsActionStatusType =
  | 'downloadContributors/loading'
  | 'downloadContributors/succeeded'
  | 'downloadContributors/failed'
export type DownloadContributorsActionPayloadType = DownloadSuppliersPayloadType
export const downloadContributorsAction =
  (
    set: SetState<
      Partial<ContributorSliceType & StoreGlobalType>,
      DownloadContributorsActionStatusType,
      DownloadContributorsActionPayloadType
    >,
  ) =>
  async (payload: DownloadContributorsActionPayloadType) => {
    try {
      set(
        (state) => {
          state.downloadContributorsAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'downloadContributors/loading', payload },
      )

      const data = await downloadSuppliers(payload)
      downloadBlob([data], `Users_${shortTime()}.xlsx`)

      set(
        (state) => {
          state.downloadContributorsAction.status = 'SUCCEEDED'
        },
        false,
        { type: 'downloadContributors/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.downloadContributorsAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'downloadContributors/failed' },
      )
    }
  }

/******************************************************************
 * DOWNLOAD CONTRIBUTOR SAMPLE FILE
 *****************************************************************/

type DownloadContributorsSampleFileActionStatusType =
  | 'downloadContributorsSampleFile/loading'
  | 'downloadContributorsSampleFile/succeeded'
  | 'downloadContributorsSampleFile/failed'

export const downloadContributorsSampleFileAction =
  (
    set: SetState<
      Partial<ContributorSliceType & StoreGlobalType>,
      DownloadContributorsSampleFileActionStatusType
    >,
  ) =>
  async () => {
    try {
      set(
        (state) => {
          state.downloadContributorsSampleFileAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'downloadContributorsSampleFile/loading' },
      )

      const data = await downloadSampleImportUsersFile()
      downloadBlob([data], `InputFileSample_Users.xlsx`)

      set(
        (state) => {
          state.downloadContributorsSampleFileAction.status = 'SUCCEEDED'
        },
        false,
        { type: 'downloadContributorsSampleFile/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.downloadContributorsSampleFileAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'downloadContributorsSampleFile/failed' },
      )
    }
  }

/******************************************************************
 * DELETE CONTRIBUTORS
 *****************************************************************/

type DeleteContributorsActionStatusType =
  | 'deleteContributors/loading'
  | 'deleteContributors/succeeded'
  | 'deleteContributors/failed'

export type DeleteContributorsActionPayloadType = {
  idContributors: string[]
}

export const deleteContributorsAction =
  (
    set: SetState<
      Partial<ContributorSliceType & StoreGlobalType>,
      DeleteContributorsActionStatusType,
      DeleteContributorsActionPayloadType
    >,
  ) =>
  async (payload: DeleteContributorsActionPayloadType, callback: () => void) => {
    try {
      set(
        (state) => {
          state.deleteContributorsAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'deleteContributors/loading', payload },
      )

      for (const id of payload.idContributors) {
        await deleteUser({ idUser: id })
      }

      set(
        (state) => {
          state.deleteContributorsAction.status = 'SUCCEEDED'
          state.contributors = state.contributors.filter(
            ({ userData }) => !payload.idContributors.includes(userData.idUser),
          )
        },
        false,
        { type: 'deleteContributors/succeeded' },
      )

      callback && callback()
    } catch (e) {
      set(
        (state) => {
          state.deleteContributorsAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'deleteContributors/failed' },
      )
    }
  }

/******************************************************************
 * UPLOAD CONTRIBUTOR FILE
 *****************************************************************/

type UploadContributorsFileActionStatusType =
  | 'uploadContributorsFile/loading'
  | 'uploadContributorsFile/succeeded'
  | 'uploadContributorsFile/failed'

export type UploadContributorsFileActionPayloadType = ImportUsersPayloadType
export const uploadContributorsFileAction =
  (
    set: SetState<
      Partial<ContributorSliceType & StoreGlobalType>,
      UploadContributorsFileActionStatusType,
      UploadContributorsFileActionPayloadType
    >,
  ) =>
  async (payload: UploadContributorsFileActionPayloadType, callback: () => void) => {
    try {
      set(
        (state) => {
          state.uploadContributorsFileAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'uploadContributorsFile/loading', payload },
      )

      await importUsers(payload)

      setTimeout(async () => {
        const contributors = await synchronizeContributors()
        set(
          (state) => {
            state.uploadContributorsFileAction.status = 'SUCCEEDED'
            state.contributors = contributors
            state.success = getSuccess({ messageT: 'certificationCreation.questions.refreshText' })
          },
          false,
          { type: 'uploadContributorsFile/succeeded' },
        )

        callback && callback()
      }, 3000)
    } catch (e) {
      set(
        (state) => {
          state.uploadContributorsFileAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'uploadContributorsFile/failed' },
      )
    }
  }

/******************************************************************
 * ADD SEGMENT TO CONTRIBUTORS
 *****************************************************************/

type AddSegmentToContributorsActionStatusType =
  | 'addSegmentToContributors/loading'
  | 'addSegmentToContributors/succeeded'
  | 'addSegmentToContributors/failed'

export type AddSegmentToContributorsActionPayloadType = {
  segmentShort: SegmentShortType
  idContributors: string[]
}
export const addSegmentToContributorsAction =
  (
    set: SetState<
      Partial<ContributorSliceType & StoreGlobalType>,
      AddSegmentToContributorsActionStatusType,
      AddSegmentToContributorsActionPayloadType
    >,
    get: () => ContributorSliceType,
  ) =>
  async (payload: AddSegmentToContributorsActionPayloadType, callback?: () => void) => {
    try {
      set(
        (state) => {
          state.addSegmentToContributorsAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'addSegmentToContributors/loading', payload },
      )

      for (const contributorId of payload.idContributors) {
        const currentContributor = get().contributors.find(
          (item) => item.userData.idUser === contributorId,
        )?.userData

        await addUsersToSegment({
          id: payload.segmentShort.idSegment,
          users: [
            {
              idUser: currentContributor.idUser,
              email: currentContributor.email,
              firstName: currentContributor.firstName,
              lastName: currentContributor.lastName,
              type: currentContributor.type as TUserType,
              urlAvatar: currentContributor.urlAvatar,
            },
          ],
        })
      }

      set(
        (state) => {
          const contributors = state.contributors.map((contributor): ContributorType => {
            if (payload.idContributors.includes(contributor.userData.idUser)) {
              const segmentsShort = contributor?.segmentsShort?.concat(payload.segmentShort)
              return {
                ...contributor,
                segmentsShort,
              }
            }
            return contributor
          })

          state.addSegmentToContributorsAction.status = 'SUCCEEDED'
          state.contributors = contributors
        },
        false,
        { type: 'addSegmentToContributors/succeeded' },
      )

      callback && callback()
    } catch (e) {
      set(
        (state) => {
          state.addSegmentToContributorsAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'addSegmentToContributors/failed' },
      )
    }
  }

/******************************************************************
 * DELETE SEGMENT FROM CONTRIBUTORS
 *****************************************************************/

type DeleteSegmentFromContributorsActionStatusType =
  | 'deleteSegmentFromContributors/loading'
  | 'deleteSegmentFromContributors/succeeded'
  | 'deleteSegmentFromContributors/failed'

export type DeleteSegmentFromContributorsActionPayloadType = {
  idSegment: string
  idContributors: string[]
}
export const deleteSegmentFromContributorsAction =
  (
    set: SetState<
      Partial<ContributorSliceType & StoreGlobalType>,
      DeleteSegmentFromContributorsActionStatusType,
      DeleteSegmentFromContributorsActionPayloadType
    >,
    get: () => ContributorSliceType,
  ) =>
  async (payload: DeleteSegmentFromContributorsActionPayloadType, callback?: () => void) => {
    try {
      set(
        (state) => {
          state.deleteSegmentFromContributorsAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'deleteSegmentFromContributors/loading', payload },
      )

      for (const contributorId of payload.idContributors) {
        const currentContributor = get().contributors.find(
          (item) => item.userData.idUser === contributorId,
        )?.userData

        await deleteUsersFromSegment({
          id: payload.idSegment,
          users: [
            {
              idUser: currentContributor.idUser,
              email: currentContributor.email,
              firstName: currentContributor.firstName,
              lastName: currentContributor.lastName,
              type: currentContributor.type as TUserType,
              urlAvatar: currentContributor.urlAvatar,
            },
          ],
        })
      }

      set(
        (state) => {
          const contributors = state.contributors.map((contributor): ContributorType => {
            if (payload.idContributors.includes(contributor.userData.idUser)) {
              const segmentsShort = contributor.segmentsShort.filter(
                ({ idSegment }) => idSegment !== payload.idSegment,
              )
              return {
                ...contributor,
                segmentsShort,
              }
            }
            return contributor
          })
          state.deleteSegmentFromContributorsAction.status = 'SUCCEEDED'
          state.contributors = contributors
        },
        false,
        { type: 'deleteSegmentFromContributors/succeeded' },
      )

      callback && callback()
    } catch (e) {
      set(
        (state) => {
          state.deleteSegmentFromContributorsAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'deleteSegmentFromContributors/failed' },
      )
    }
  }
