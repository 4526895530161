import { Countdown } from '@components/display'
import { ProjectState, TProjectState } from '@definitions/states'
import { useTranslation } from '@hooks/useTranslation'
import { ItemType } from 'antd/es/menu/hooks/useItems'
import { useMemo, useState, useEffect } from 'react'
import { Button, Dropdown, IButtonProps, Icon, Text, colors } from 'common-components'

import { store } from '@/store'

type BoostButtonProjectPropsType = {
  idProject: string
  state: TProjectState
  endBoost: string
}

const BoostButtonProject = ({ idProject, state, endBoost }: BoostButtonProjectPropsType) => {
  const { translate } = useTranslation()

  const boostProjectAction = store.use.boostProjectAction().call
  const stopBoostProjectAction = store.use.stopBoostProjectAction().call

  const [isHover, setIsHover] = useState(false)
  const [isProjectBoosted, setIsProjectBoosted] = useState<boolean>()

  useEffect(() => {
    if (ProjectState[state] === 'boosted') setIsProjectBoosted(true)
  }, [state])

  const handleMouseEnter = () => {
    isProjectBoosted && setIsHover(true)
  }
  const handleMouseLeave = () => {
    isProjectBoosted && setIsHover(false)
  }

  const dropdownItems: ItemType[] = [
    {
      key: 0,
      label: <Text>{translate('projectsPage.dropdown.boost1')}</Text>,
      icon: <Icon icon={'Lightning02'} size={16} />,
      onClick: () => boostProjectAction({ idProject, nbHours: 1 }),
    },
    {
      key: 1,
      label: <Text>{translate('projectsPage.dropdown.boost2')}</Text>,
      icon: <Icon icon={'Lightning02'} size={16} />,
      onClick: () => boostProjectAction({ idProject, nbHours: 6 }),
    },
    {
      key: 2,
      label: <Text>{translate('projectsPage.dropdown.boost3')}</Text>,
      icon: <Icon icon={'Lightning02'} size={16} />,
      onClick: () => boostProjectAction({ idProject, nbHours: 12 }),
    },
  ]

  const buttonStyles = useMemo(
    () =>
      isHover
        ? {
            background: colors.blue100,
            border: `1px solid ${colors.blue500}`,
            color: colors.blue600,
            minWidth: 160,
          }
        : {
            background: colors.blue25,
            border: `1px solid ${colors.blue100}`,
            color: colors.blue600,
            minWidth: 160,
          },
    [isHover],
  )

  return isProjectBoosted ? (
    <Button
      style={buttonStyles}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      iconLeft={isHover ? ('' as IButtonProps['iconLeft']) : 'Lightning01'}
      onClick={() => {
        stopBoostProjectAction({ idProject }, () => setIsProjectBoosted(false))
        setIsHover(false)
      }}
    >
      {isHover ? (
        translate('project.navigation.stopBoost')
      ) : (
        <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
          {translate('project.navigation.boosted')}
          <Countdown date={endBoost} onTimeEnd={() => setIsProjectBoosted(false)} />
        </div>
      )}
    </Button>
  ) : (
    <Dropdown menu={{ items: dropdownItems }} placement='bottomRight'>
      <Button>{translate('project.navigation.boost')}</Button>
    </Dropdown>
  )
}

export default BoostButtonProject
