const fr = {
  draftLabel: 'Brouillon',
  trainLabel: 'Entraînement',
  errorLabel: 'Erreur',
  archivedLabel: 'Archivé',
  currentModels: 'Mes modèles en cours',
  allModels: 'Tous les modèles',
  addButton: 'Ajouter un modèle',
  filterButton: 'Filtrer',
  lastModified: 'Modifié le',
  deleteModal: {
    labelCancelButton: 'Annuler',
    labelConfirmButton: 'Confirmer',
  },
}

export default fr
