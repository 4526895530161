const en = {
  draftLabel: 'Draft',
  trainLabel: 'Train',
  errorLabel: 'Error',
  archivedLabel: 'Archived',
  currentModels: 'My current models',
  allModels: 'Mes Modèles',
  addButton: 'Add model',
  filterButton: 'Filter',
  lastModified: 'Last modified',
  deleteModal: {
    labelCancelButton: 'Cancel',
    labelConfirmButton: 'Confirm',
  },
}

export default en
export type Translations = typeof en
