/** @jsxImportSource @emotion/react */

import { useTranslation } from 'react-i18next'
import { Col, Row, Space } from 'antd'
import { css } from '@emotion/react'
import { IText } from '@types'
import { PropsWithChildren } from 'react'
import { Content, Title } from 'common-components'

export type HeaderManagerPropsType = {
  managersSelectedCount: number
}

const HeaderManagers = ({
  managersSelectedCount,
  children,
}: PropsWithChildren<HeaderManagerPropsType>) => {
  const { t } = useTranslation()

  const texts: IText[] = [
    {
      type: 'tablePageTitle',
      content:
        managersSelectedCount > 0
          ? `${managersSelectedCount} ${
              managersSelectedCount === 1
                ? t('managers.selectedManager')
                : t('managers.selectedManagers')
            }`
          : t('managers.managers'),
    },
    {
      type: 'tablePageSubtitle',
      content: t('managers.manageTeamInfo'),
    },
  ]

  return (
    <Content>
      <Row
        justify={'space-between'}
        align={'top'}
        css={css`
          margin: 24px 0;
        `}
      >
        <Col>
          {texts.map((text, index) => {
            return (
              <Title key={`title-${text.content}-${index}`} preset={text.type}>
                {text.content}
              </Title>
            )
          })}
        </Col>
        <Row>
          <Space>{children}</Space>
        </Row>
      </Row>
    </Content>
  )
}

export default HeaderManagers
