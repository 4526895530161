/** @jsxImportSource @emotion/react */

import { useTranslation } from 'react-i18next'
import { css } from '@emotion/react'
import { PropsWithChildren } from 'react'
import { IText } from '@types'
import { Row, Col, Title, Space, Content } from 'common-components'

export type HeaderContributorsPropsType = {
  countContributorsSelected: number
}

const HeaderContributors = ({
  countContributorsSelected,
  children,
}: PropsWithChildren<HeaderContributorsPropsType>) => {
  const { t } = useTranslation()

  const texts: IText[] = [
    {
      type: 'tablePageTitle',
      content:
        countContributorsSelected > 0
          ? `${countContributorsSelected} ${
              countContributorsSelected === 1
                ? t('contributors.selectedContributor')
                : t('contributors.selectedContributors')
            }`
          : t('contributors.contributors'),
    },
    {
      type: 'tablePageSubtitle',
      content: t('contributors.manageTeamMembers'),
    },
  ]

  return (
    <Content>
      <Row
        justify={'space-between'}
        align={'top'}
        css={css`
          margin: 24px 0;
        `}
      >
        <Col>
          {texts.map((text, index) => (
            <Title key={`title-${text.content}-${index}`} preset={text.type}>
              {text.content}
            </Title>
          ))}
        </Col>
        <Row>
          <Space>{children}</Space>
        </Row>
      </Row>
    </Content>
  )
}

export default HeaderContributors
