import { Outlet } from 'react-router'
import { PageLayout } from '@components/layout'

import { NavBar } from '../navigation'

const NavBarLayout = () => {
  return (
    <>
      <NavBar />
      <PageLayout>
        <Outlet />
      </PageLayout>
    </>
  )
}

export default NavBarLayout
