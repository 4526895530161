/** @jsxImportSource @emotion/react */

import React, { memo } from 'react'
import { useTranslation } from '@hooks'
import styled from '@emotion/styled'
import { getStateKeyByValue } from '@utils'
import { HitStateType } from '@definitions'
import { PresetTag } from '@components/display'
import { HitLogType, HitType } from '@pages/types'
import { Collapse, Panel, Row, Space } from 'common-components'

import ContributorHitTask from './ContributorHitTask'
import ReviewHitTask from './ReviewHitTask'

import { store } from '@/store'

const DataRow = styled(Row)`
  justify-content: space-between;
  align-items: flex-end;
`

const PanelContainer = styled.div`
  padding: 5px;
`

type HitSidebarTaskPropsType = {
  hit: HitType
}

const HitSidebarTaskComponent = ({ hit }: HitSidebarTaskPropsType) => {
  const { translate } = useTranslation()

  const hitLogs = store.use.hitLogs()

  return (
    <Collapse
      bordered={false}
      expandIconPosition={'end'}
      defaultActiveKey={['contributor', 'review']}
      collapsible={'header'}
    >
      <Panel
        header={translate('project.tasklineOverview.sidebar.contributor.title')}
        key='contributor'
        style={{ paddingBottom: 10 }}
      >
        <PanelContainer>
          {hit && (
            <Row>
              <ContributorHitTask hit={hit} />
            </Row>
          )}
        </PanelContainer>
      </Panel>
      <Panel
        header={translate('project.tasklineOverview.sidebar.taskMetrics.title')}
        key='metrics'
        style={{ paddingBottom: 10, paddingTop: 10 }}
      >
        <PanelContainer>
          {/*idTask*/}
          <DataRow>
            <div>{translate('project.tasklineOverview.sidebar.taskMetrics.taskId')}</div>
            <div>{hit?.task.idTask}</div>
          </DataRow>
          {/*request id*/}
          <DataRow>
            <div>{translate('project.tasklineOverview.sidebar.taskMetrics.requestId')}</div>
            <div>{hit?.idHit}</div>
          </DataRow>
          {/*status*/}
          <DataRow>
            <div>{translate('project.tasklineOverview.sidebar.taskMetrics.status')}</div>
            <PresetTag preset={'hit-state'} style={{ margin: 0 }} state={hit.hitState}>
              {HitStateType[hit.hitState]}
            </PresetTag>
          </DataRow>
          {/*mark*/}
          <DataRow>
            <div>{translate('project.tasklineOverview.sidebar.taskMetrics.quality')}</div>
            <div>{hit?.mark ?? '-'}</div>
          </DataRow>
          {/*duration*/}
          <DataRow>
            <div>{translate('project.tasklineOverview.sidebar.taskMetrics.duration')}</div>
            <div>{hit?.duration ?? '-'}</div>
          </DataRow>
          {/*effectiveTime*/}
          <DataRow>
            <div>{translate('project.tasklineOverview.sidebar.taskMetrics.effectiveTime')}</div>
            <div>{hit?.effectiveTime ?? '-'}</div>
          </DataRow>
          {/*endDate*/}
          <DataRow>
            <div>{translate('project.tasklineOverview.sidebar.taskMetrics.submissionDate')}</div>
            <div>{hit?.endDate ?? '-'}</div>
          </DataRow>
          {/*creationDate*/}
          <DataRow>
            <div>{translate('project.tasklineOverview.sidebar.taskMetrics.creationDate')}</div>
            <div>{hit?.creationDate ?? '-'}</div>
          </DataRow>
        </PanelContainer>
      </Panel>
      <Panel
        header={translate('project.tasklineOverview.sidebar.history.title')}
        key='history'
        style={{ paddingBottom: 10, paddingTop: 10 }}
      >
        <PanelContainer>
          {hitLogs?.length &&
            hitLogs.map((log: HitLogType, i) => (
              <DataRow key={`${log.creationDate}-${log.event}-${i}`}>
                <Space size={5}>
                  <div>{log.creationDate}</div>
                  <div>{translate(`common.states.eventType.${log.event}`)}</div>
                </Space>
                {!!log.user && <div>{`${log.user?.firstName} ${log.user?.lastName}`}</div>}
              </DataRow>
            ))}
        </PanelContainer>
      </Panel>
      {hit?.hitState === getStateKeyByValue(HitStateType, 'completed') && (
        <Panel
          header={translate('project.tasklineOverview.sidebar.review.title')}
          key='review'
          style={{ paddingTop: 10 }}
        >
          <PanelContainer>
            <ReviewHitTask hit={hit} />
          </PanelContainer>
        </Panel>
      )}
    </Collapse>
  )
}

const HitSidebarTask = memo(
  HitSidebarTaskComponent,
  (oldProps: HitSidebarTaskPropsType, newProps: HitSidebarTaskPropsType) => {
    return JSON.stringify(oldProps) === JSON.stringify(newProps)
  },
)

export default HitSidebarTask
