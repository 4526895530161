import { HitApiType, HitLogApiType, TaskApiType, TaskLogApiType } from '@api/types'
import { HitLogType, HitType, TaskLogType, TaskType } from '@pages/types'
import { ID_COLUMN_HYDRATION } from '@views/shared/constants'
import { dateTime, ticksToTime } from '@utils'

import { contributorShortApiMapper, managerShortApiMapper } from '@/mappers/user.mapper'
import { removeNullAndUndefinedMapper } from '@/mappers/helper.mapper'

export const taskApiMapper = (taskApi: TaskApiType): TaskType => {
  try {
    const columns = taskApi?.columns || []
    if (taskApi?.inputJson) {
      columns.push({
        data: JSON.stringify(taskApi.inputJson),
        name: ID_COLUMN_HYDRATION,
        type: 'hydration_json',
      })
    }

    const result: TaskType = {
      idTask: taskApi.idTask,
      priority: taskApi?.priority,
      project: {
        ...taskApi?.project,
        managers: taskApi.project.managers.map(managerShortApiMapper) || [],
      },
      taskState: taskApi?.taskState,
      creationDate: dateTime(taskApi?.creationDate),
      origin: taskApi?.origin,
      deleveringDate: dateTime(taskApi?.deleveringDate),
      idDocument: taskApi?.idDocument,
      dueDate: dateTime(taskApi?.dueDate),
      inputJson: taskApi?.inputJson,
      columns,
      duration: ticksToTime(taskApi?.duration),
      effectiveTime: ticksToTime(taskApi?.effectiveTime),
    }

    return removeNullAndUndefinedMapper<TaskType>(result)
  } catch (e) {
    throw e
  }
}

export const hitApiMapper = (hitApi?: HitApiType): HitType => {
  try {
    if (!hitApi?.idHit) {
      return
    }
    const result: HitType = {
      idHit: hitApi.idHit,
      hitType: hitApi?.hitType,
      hitState: hitApi?.hitState,
      idProject: hitApi?.idProject,
      user: hitApi?.user && contributorShortApiMapper(hitApi?.user),
      mark: hitApi?.mark,
      hitReviews:
        hitApi?.hitReviews?.map((hitReview) => ({
          idReviewer: hitReview.idReviewer,
          reviewDate: dateTime(hitReview?.reviewDate),
          mark: hitReview?.mark,
        })) || [],
      endDate: dateTime(hitApi?.endDate),
      creationDate: dateTime(hitApi?.creationDate),
      duration: ticksToTime(hitApi?.duration),
      effectiveTime: ticksToTime(hitApi?.effectiveTime),
      task: hitApi?.task?.idTask
        ? {
            idTask: hitApi.task.idTask,
          }
        : undefined,
    }
    return removeNullAndUndefinedMapper<HitType>(result)
  } catch (e) {
    throw e
  }
}

export const hitLogApiMapper = (hitLogs?: HitLogApiType): HitLogType => {
  try {
    const result: HitLogType = {
      idHit: hitLogs.idHit,
      creationDate: dateTime(hitLogs?.creationDate),
      user: hitLogs?.user ? contributorShortApiMapper(hitLogs?.user) : undefined,
      event: hitLogs?.event,
    }
    return removeNullAndUndefinedMapper<HitLogType>(result)
  } catch (e) {
    throw e
  }
}

export const taskLogApiMapper = (taskLogs?: TaskLogApiType): TaskLogType => {
  try {
    const result = {
      creationDate: dateTime(taskLogs?.creationDate),
      user: taskLogs?.user ? contributorShortApiMapper(taskLogs?.user) : undefined,
      event: taskLogs?.event,
    }
    return removeNullAndUndefinedMapper<TaskLogType>(result)
  } catch (e) {
    throw e
  }
}
