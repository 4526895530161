/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FC, useContext, useEffect } from 'react'
import { useTranslation } from '@hooks/useTranslation'
import { TAudienceType } from '@definitions/states'
import { ProjectType } from '@pages/types'
import { Button, Form, FormElements } from 'common-components'
import { LoadingOutlined } from '@ant-design/icons'

import { AudienceCollapse } from '../shared'

import { AudienceContext } from '@/context'
import { store } from '@/store'

export const audienceCollapsesOverride = css`
  .ant-collapse {
    border: none !important;
  }

  .ant-collapse-item:last-child {
    border-bottom: none !important;
  }
`

type AudienceContributorsPropsType = {
  project: ProjectType
  timePerTaskForm: string
}

const ContributorsAudienceProject: FC<AudienceContributorsPropsType> = ({
  project,
  timePerTaskForm,
}) => {
  const { translate } = useTranslation()
  const [form] = FormElements.useForm()

  const updateAudienceProjectAction = store.use.updateAudienceProjectAction().call

  const isLoadingUpdateAudienceProjectAction =
    store.use.updateAudienceProjectAction().status === 'LOADING'

  const { setAudience, audience } = useContext(AudienceContext)

  const handleSubmit = async (values: ProjectType['audience']) => {
    // TODO - Do not delete "values", for future use
    if (!project) return
    updateAudienceProjectAction({
      idProject: project.idProject,
      audience: {
        audienceType: (values.audienceType as TAudienceType) || 0,
        audienceGroups: audience.audienceGroups,
        combination: audience.combination,
        supplierEarning: values.supplierEarning,
      },
    })
  }

  useEffect(() => {
    if (project?.audience) {
      setAudience('contributors', {
        audienceGroups: project.audience.audienceGroups,
        combination: project.audience.combination,
      })
    }
  }, [project?.audience])

  return (
    <Form form={form} onFinish={handleSubmit}>
      <div css={audienceCollapsesOverride}>
        <AudienceCollapse
          type='contributors'
          projectId={project?.idProject}
          audience={audience?.audienceGroups}
          combination={audience?.combination}
          audienceType={project?.audience?.audienceType}
          selectedEarnings={project?.audience?.supplierEarning}
          timePerTask={timePerTaskForm}
          withCollapseTitle={false}
        />
      </div>
      <Button
        type='primary'
        disabled={isLoadingUpdateAudienceProjectAction}
        htmlType='submit'
        size='sm'
        style={{
          width: '150px',
          marginTop: 8,
        }}
      >
        {isLoadingUpdateAudienceProjectAction ? (
          <LoadingOutlined />
        ) : (
          translate('project.audience.buttons.saveChanges')
        )}
      </Button>
    </Form>
  )
}

export default ContributorsAudienceProject
