import { SegmentShortApiType } from '@api/types'
import { useState } from 'react'
import { CertificationType } from '@pages/types'
import { Button, IconButton, Row, Space, TextInput } from 'common-components'
import { ItemWithAvatar } from '@components/display'

import { store } from '@/store'

type CreateSegmentCertificationPropsType = {
  certification?: CertificationType
}
const CreateSegmentCertification = ({ certification }: CreateSegmentCertificationPropsType) => {
  const createSegmentCertificationAction = store.use.createSegmentCertificationAction().call
  const updateSegmentCertificationAction = store.use.updateSegmentCertificationAction().call
  const deleteSegmentCertificationAction = store.use.deleteSegmentCertificationAction().call

  const onRemoveSegment = async (idSegment: string) => {
    deleteSegmentCertificationAction({ idCertification: certification.idCertification, idSegment })
  }

  const onSegmentCreateSubmit = async () => {
    const defaultMinQuality = 90
    createSegmentCertificationAction({
      icon: certification.icon,
      name: `${certification.name} ${defaultMinQuality}+`,
      qualityMin: defaultMinQuality,
      managers: certification?.managersShort,
      certification: {
        icon: certification.icon,
        idCertification: certification.idCertification,
        name: `${certification.name} ${defaultMinQuality}+`,
      },
    })
  }

  const onSegmentUpdateSubmit = async (
    segment: Partial<SegmentShortApiType>,
    qualityVal: number,
  ) => {
    updateSegmentCertificationAction({
      idSegment: segment.idSegment,
      icon: certification.icon,
      name: `${certification.name} ${qualityVal}+`,
      qualityMin: qualityVal,
      managers: certification?.managersShort,
      certification: {
        icon: certification.icon,
        idCertification: certification.idCertification,
        name: `${certification.name} ${qualityVal}+`,
      },
    })
  }

  const QualityValueInput = ({ segment }: { segment: Partial<SegmentShortApiType> }) => {
    const [val, setVal] = useState(segment.qualityMin || 0)

    return (
      <TextInput
        disabled={!segment.idSegment}
        type={'number'}
        value={val}
        suffix={
          <IconButton
            type={'text'}
            style={{ margin: 0, padding: 0 }}
            onClick={() => onSegmentUpdateSubmit(segment, val)}
            icon={'CheckCircle'}
          />
        }
        onChange={(ev) => setVal(Number(ev.target.value))}
        max={100}
        min={0}
        style={{ minWidth: 200 }}
      />
    )
  }

  return (
    <>
      {certification?.segmentsShort?.map((segment) => (
        <Row
          key={segment.idSegment}
          justify={'space-between'}
          style={{ width: '100%', marginBottom: 10 }}
        >
          <ItemWithAvatar title={segment.name} containerProps={{ style: {}, align: 'center' }} />
          <Space size={10}>
            <QualityValueInput segment={segment} />
            <IconButton onClick={() => onRemoveSegment(segment.idSegment)} icon={'X'} />
          </Space>
        </Row>
      ))}
      <Button onClick={onSegmentCreateSubmit}>Nouveau segment</Button>
    </>
  )
}

export default CreateSegmentCertification
