const en = {
  global: 'Global',
  variation: 'Variation',
  distribution: 'Distribution',
  decomposition: 'Decomposition',
  tracking: 'Tracking',
}

export default en
export type Translations = typeof en
