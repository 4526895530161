const en = {
  user: 'User',
  managerSelect: 'Please choose a manager',
  creator: 'Creator',
  ownersAndDirectors: 'Owners & Directors',
  search: 'Search',
  add: 'Add',
}

export default en
export type Translations = typeof en
