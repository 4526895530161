/** @jsxImportSource @emotion/react */

import {
  ActionModal,
  Button,
  Card,
  Col,
  colors,
  Row,
  Space,
  Text,
  Title,
  useDisclosure,
} from 'common-components'
import { useTranslation } from '@hooks'
import { FiltersBarModel } from '@views/shared/model'
import { useMutation, useQueryClient } from 'react-query'
import { getWirkUrl } from '@definitions'
import { deleteModel, duplicateModel, GetModelsPayloadType } from '@api'
import { IModel } from '@api/types'
import { useState } from 'react'
import { Pagination, PaginationProps } from 'antd'
import { CheckboxValueType } from 'antd/es/checkbox/Group'
import openLink from '@utils/openLink'

import FiltersModel from './FiltersModel'
import CatalogueModels from './CatalogueModels'

import { store } from '@/store'

type ListModelPropsType = {
  models: IModel[]
  modelDrafts: IModel[]
  pagination: PaginationProps
  reqParams: GetModelsPayloadType
  setParams: (params: GetModelsPayloadType) => void
}

const ListModel = ({
  modelDrafts,
  models,
  setParams,
  reqParams,
  pagination,
}: ListModelPropsType) => {
  const client = useQueryClient()
  const { translate } = useTranslation()
  const accessToken = store.use.me().accessToken
  const { open, isOpen, close } = useDisclosure()

  const [selected, setSelected] = useState<CheckboxValueType[]>([])
  const [modelToDelete, setModelToDelete] = useState<IModel | null>(null)
  const [isFiltersVisible, setFiltersVisible] = useState(false)

  const deleteModelMutation = useMutation({
    mutationFn: deleteModel,
    onSuccess: () => {
      setSelected([])
      close()
      client.invalidateQueries()
    },
  })

  const onModelsDelete = async () => {
    for (const modelId of selected) {
      await deleteModelMutation.mutateAsync(modelId as string)
    }
  }

  const onModelDeleteConfirm = async (modelId: string) => {
    await deleteModelMutation.mutateAsync(modelId)
    await client.invalidateQueries()
  }

  const onClickModelCreate = () => openLink(`${getWirkUrl('Model')}?token=${accessToken}`)

  const onModelOpen = (model: IModel) =>
    openLink(`${getWirkUrl('Model')}/model/${model.idModel}/?token=${accessToken}`)
  const onModelCopy = async (model: IModel) => {
    try {
      const modelCopy = await duplicateModel(model)
      await client.invalidateQueries()
      openLink(`${getWirkUrl('Model')}/model/${modelCopy.idModel}?token=${accessToken}`)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }
  const onModelDelete = (model: IModel) => {
    setModelToDelete(model)
    open()
  }
  const onModelCheckboxToggle = (selected: CheckboxValueType[]) => setSelected(selected)

  return (
    <>
      <Card style={{ width: '100%', padding: 20, marginBottom: 20 }}>
        <Title preset='singlePageTitle'>{translate(`model.currentModels`)}</Title>
        <CatalogueModels
          data={modelDrafts}
          onModelOpen={onModelOpen}
          onModelCopy={onModelCopy}
          onModelDelete={onModelDelete}
        />
      </Card>
      <Space direction='vertical' style={{ gap: 16 }}>
        <Space align='center' style={{ width: '100%', justifyContent: 'space-between' }}>
          <Title preset='singlePageTitle'>{translate(`model.allModels`)}</Title>
          {selected.length ? (
            <Button iconLeft='Trash02' onClick={onModelsDelete}>
              {translate('certificationPage.archiveButtonLabel')}
            </Button>
          ) : (
            <Button onClick={onClickModelCreate} type='primary' iconLeft='Plus'>
              {translate('model.addButton')}
            </Button>
          )}
        </Space>
        <Row
          justify={'space-between'}
          align={'middle'}
          style={{
            width: '100%',
          }}
        >
          <Col span={16}>
            <FiltersBarModel initValues={reqParams} onFiltersChange={setParams} />
          </Col>

          <Button onClick={() => setFiltersVisible(true)} iconLeft='FilterLines'>
            {translate('common.filter')}
          </Button>

          <FiltersModel
            visible={isFiltersVisible}
            onClose={() => setFiltersVisible(false)}
            initValues={reqParams}
            onFiltersChange={setParams}
          />
        </Row>
        <CatalogueModels
          data={models}
          onModelOpen={onModelOpen}
          onModelCopy={onModelCopy}
          onModelDelete={onModelDelete}
          canSelect
          inputProps={{ onChange: onModelCheckboxToggle, value: selected }}
        />
        <ActionModal
          open={isOpen}
          title={translate('common.deleting')}
          icon={'Trash02'}
          iconColor={colors.error600}
          iconBgColor={colors.error100}
          onConfirm={() => onModelDeleteConfirm(modelToDelete?.idModel)}
          onCancel={close}
          actionButtons={[
            {
              text: translate('common.buttons.return'),
              onClick: close,
            },
            {
              type: 'primary',
              text: translate('common.buttons.delete'),
              onClick: () => onModelDeleteConfirm(modelToDelete?.idModel),
            },
          ]}
        >
          <Text>{translate('common.areYouSure')}</Text>
        </ActionModal>
        {/*FIXME: move pagination to common with predefined styles*/}
        <Row justify={'end'} style={{ width: '100%', padding: '10px 0' }}>
          <Pagination {...pagination} />
        </Row>
      </Space>
    </>
  )
}

export default ListModel
