import { SegmentApiType, SegmentShortApiType } from '@api/types'
import { SegmentShortType, SegmentType } from '@pages/types'
import { ID_MANAGER_RIGHT } from '@definitions'

import { assignRoleToSegmentOrCertification, removeNullAndUndefinedMapper } from './helper.mapper'

import { contributorShortApiMapper, managerShortApiMapper } from '@/mappers/user.mapper'

export const segmentApiMapper = (segmentApi: SegmentApiType, idMe: string): SegmentType => {
  try {
    if (!segmentApi.idSegment) {
      throw Error('idSegment not found')
    }

    if (!idMe) {
      throw Error('idMe not found')
    }

    const result: SegmentType = {
      idSegment: segmentApi.idSegment,
      name: segmentApi?.name,
      icon: segmentApi?.icon,
      managerRight: segmentApi?.managerRight,
      segmentType: segmentApi?.segmentType,
      managersShort: segmentApi?.managers?.map(managerShortApiMapper) || [],
      contributorsShort: segmentApi?.users?.map(contributorShortApiMapper) || [],
      idUser: segmentApi?.idUser,
      creationDate: segmentApi?.creationDate,
      role: assignRoleToSegmentOrCertification(idMe, segmentApi?.managers),
      socioDemographicCriteria: segmentApi?.socioDemographicCriteria,
      socioDemographicCriteriaValues: segmentApi?.socioDemographicCriteriaValues,
      socioDemographicMaxValue: segmentApi?.socioDemographicMaxValue,
      socioDemographicMinValue: segmentApi?.socioDemographicMinValue,
      percentageForValidation: segmentApi?.percentageForValidation,
      qualityMin: segmentApi?.qualityMin,
      skills: segmentApi?.skills,
    }

    return removeNullAndUndefinedMapper<SegmentType>(result)
  } catch (e) {
    throw e
  }
}

export const segmentShortApiMapper = (segmentShortApi: SegmentShortApiType): SegmentShortType => {
  try {
    const result: SegmentShortType = {
      idSegment: segmentShortApi.idSegment,
      icon: segmentShortApi?.icon,
      name: segmentShortApi?.name,
      managerRight: segmentShortApi?.managerRight || ID_MANAGER_RIGHT[2], // TODO for manager
      segmentType: segmentShortApi?.segmentType,
      qualityMin: segmentShortApi?.qualityMin,
      nbMinEvaluation: segmentShortApi?.nbMinEvaluation,
    }

    return removeNullAndUndefinedMapper<SegmentShortType>(result)
  } catch (e) {
    throw e
  }
}
