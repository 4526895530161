import { getRisks, GetRisksPayloadType } from '@api/risk'
import { ListRisk } from '@views/risk'
import { mutationKeys } from '@definitions/react-query-mutation-keys'
import { useRequestParams } from '@hooks/useRequestParams'
import { RiskType } from '@pages/types'
import { useQuery } from 'react-query'
import { useState } from 'react'

import { riskMapperApi } from '@/mappers'

const RisksPage = () => {
  const { afterPageChange, setParams, reqParams, pagination, onTableStateChange } =
    useRequestParams<GetRisksPayloadType, RiskType>()

  const [risks, setRisks] = useState<RiskType[]>([])

  useQuery({
    queryKey: mutationKeys.risks(reqParams),
    queryFn: () => getRisks(reqParams),
    onSuccess: (risksApi) => {
      afterPageChange(risksApi.length)
      const risksData = risksApi.filter((risk) => risk.state !== 99).map(riskMapperApi)
      setRisks(risksData)
    },
  })

  return (
    <ListRisk
      risks={risks}
      onFiltersChange={setParams}
      tableProps={{ pagination, onChange: onTableStateChange }}
    />
  )
}

export default RisksPage
