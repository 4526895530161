/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { CreateProject } from '@views/project'
import { useParams } from 'react-router'
import { useEffect } from 'react'
import { Loader } from '@components/utils'
import { PageLayout } from '@components/layout'

import { store } from '@/store'

const CreateProjectPage = () => {
  const { id, step } = useParams()

  const project = store.use.projects()?.find((project) => project.idProject === id)

  const getProjectAction = store.use.getProjectAction().call
  const isLoadingGetProjectAction = store.use.getProjectAction().status === 'LOADING'
  const isSucceededGetProjectAction = store.use.getProjectAction().status === 'SUCCEEDED'

  useEffect(() => {
    getProjectAction({ idProject: id })
  }, [id])

  return (
    <PageLayout
      css={css`
        margin: 0;
        max-width: none;
      `}
    >
      {isLoadingGetProjectAction && <Loader />}
      {isSucceededGetProjectAction && (
        <CreateProject currentStep={Number(step)} project={project} />
      )}
    </PageLayout>
  )
}

export default CreateProjectPage
