import {
  assignHit,
  AssignHitPayloadType,
  createAdditionalHit,
  deleteHit,
  getHit,
  getHitLogs,
  getHits,
  getHitToReview,
  taskCreateOverrideHit,
  updateHitMark,
  UpdateHitMarkPayloadType,
} from '@api'

import { StoreGlobalType } from '../store'

import { ProjectSliceType } from './project.slice'

import { hitApiMapper, hitLogApiMapper } from '@/mappers'
import { SetState } from '@/store/store.type'
import { getApiError, getSuccess } from '@/store/helper'

/****************************************************************
 * GET HITS
 *****************************************************************/

type GetHitsActionStatusType = 'getHits/loading' | 'getHits/succeeded' | 'getHits/failed'

export type GetHitsActionPayloadType = {
  idTask: string
}

export const getHitsAction =
  (
    set: SetState<
      Partial<ProjectSliceType & StoreGlobalType>,
      GetHitsActionStatusType,
      GetHitsActionPayloadType
    >,
  ) =>
  async (payload: GetHitsActionPayloadType, callback?: () => void) => {
    try {
      set(
        (state) => {
          state.getHitsAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'getHits/loading', payload },
      )

      const hitsApi = await getHits(payload.idTask)
      const hits = hitsApi?.map(hitApiMapper)

      set(
        (state) => {
          state.getHitsAction.status = 'SUCCEEDED'
          state.hits = hits
        },
        false,
        { type: 'getHits/succeeded' },
      )
      callback && callback()
    } catch (e) {
      set(
        (state) => {
          state.getHitsAction.status = 'FAILED'
          state.hits = []
          state.error = getApiError(e)
        },
        false,
        { type: 'getHits/failed' },
      )
    }
  }

/****************************************************************
 * GET HIT
 *****************************************************************/

type GetHitActionStatusType = 'getHit/loading' | 'getHit/succeeded' | 'getHit/failed'

export type GetHitActionPayloadType = {
  idHit: string
}

export const getHitAction =
  (
    set: SetState<
      Partial<ProjectSliceType & StoreGlobalType>,
      GetHitActionStatusType,
      GetHitActionPayloadType
    >,
  ) =>
  async (payload: GetHitActionPayloadType) => {
    try {
      set(
        (state) => {
          state.getHitAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'getHit/loading', payload },
      )

      const hitApi = await getHit(payload)
      const hit = hitApiMapper(hitApi)

      set(
        (state) => {
          state.getHitAction.status = 'SUCCEEDED'

          const index = state.hits?.findIndex(({ idHit }) => idHit === payload.idHit)
          if (index >= 0) {
            state.hits[index] = hit
          } else {
            state.hits.unshift(hit)
          }
        },
        false,
        { type: 'getHit/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.getHitAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'getHit/failed' },
      )
    }
  }

/****************************************************************
 * GET HIT LOGS
 *****************************************************************/

type GetHitLogsActionStatusType =
  | 'getHitLogs/loading'
  | 'getHitLogs/succeeded'
  | 'getHitLogs/failed'

export type GetHitLogsActionPayloadType = {
  idHit: string
}

export const getHitLogsAction =
  (
    set: SetState<
      Partial<ProjectSliceType & StoreGlobalType>,
      GetHitLogsActionStatusType,
      GetHitLogsActionPayloadType
    >,
  ) =>
  async (payload: GetHitLogsActionPayloadType) => {
    try {
      set(
        (state) => {
          state.getHitLogsAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'getHitLogs/loading', payload },
      )

      const hitLogsApi = await getHitLogs(payload.idHit)
      const hitLogs = hitLogsApi?.map(hitLogApiMapper)

      set(
        (state) => {
          state.getHitLogsAction.status = 'SUCCEEDED'
          state.hitLogs = hitLogs
        },
        false,
        { type: 'getHitLogs/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.getHitLogsAction.status = 'FAILED'
          state.hitLogs = []
          state.error = getApiError(e)
        },
        false,
        { type: 'getHitLogs/failed' },
      )
    }
  }

/****************************************************************
 * GET HIT REVIEW
 *****************************************************************/

type GetHitReviewActionStatusType =
  | 'getHitReview/loading'
  | 'getHitReview/succeeded'
  | 'getHitReview/failed'

export type GetHitReviewActionPayloadType = {
  idProject: string
}

export const getHitReviewAction =
  (
    set: SetState<
      Partial<ProjectSliceType & StoreGlobalType>,
      GetHitReviewActionStatusType,
      GetHitReviewActionPayloadType
    >,
  ) =>
  async (payload: GetHitReviewActionPayloadType) => {
    try {
      set(
        (state) => {
          state.getHitReviewAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'getHitReview/loading', payload },
      )

      const hitReviewApi = await getHitToReview(payload.idProject)
      const hitReview = hitApiMapper(hitReviewApi)

      set(
        (state) => {
          state.getHitReviewAction.status = 'SUCCEEDED'
          if (hitReview) {
            state.hitReview = hitReview
          }
        },
        false,
        { type: 'getHitReview/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.error = getApiError(e)
        },
        false,
        { type: 'getHitReview/failed' },
      )
    }
  }

/****************************************************************
 * CREATE ADDITIONAL HIT TASK
 *****************************************************************/

type CreateAdditionalHitActionStatusType =
  | 'createAdditionalHit/loading'
  | 'createAdditionalHit/succeeded'
  | 'createAdditionalHit/failed'

export type CreateAdditionalHitActionPayloadType = {
  idTask: string
}

export const createAdditionalHitAction =
  (
    set: SetState<
      Partial<ProjectSliceType & StoreGlobalType>,
      CreateAdditionalHitActionStatusType,
      CreateAdditionalHitActionPayloadType
    >,
  ) =>
  async (payload: CreateAdditionalHitActionPayloadType, callback: () => void) => {
    try {
      set(
        (state) => {
          state.createAdditionalHitAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'createAdditionalHit/loading', payload },
      )

      const hitApi = await createAdditionalHit(payload)
      const hit = hitApiMapper(hitApi)

      set(
        (state) => {
          state.createAdditionalHitAction.status = 'SUCCEEDED'
          state.success = getSuccess({})
          state.hits.unshift(hit)
        },
        false,
        { type: 'createAdditionalHit/succeeded' },
      )

      callback()
    } catch (e) {
      set(
        (state) => {
          state.createAdditionalHitAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'createAdditionalHit/failed' },
      )
    }
  }

/****************************************************************
 * CREATE OVERRIDE HIT
 *****************************************************************/

type CreateOverrideHitActionStatusType =
  | 'createOverrideHit/loading'
  | 'createOverrideHit/succeeded'
  | 'createOverrideHit/failed'

export type CreateOverrideHitActionPayloadType = {
  idTask: string
}

export const createOverrideHitAction =
  (
    set: SetState<
      Partial<ProjectSliceType & StoreGlobalType>,
      CreateOverrideHitActionStatusType,
      CreateOverrideHitActionPayloadType
    >,
  ) =>
  async (payload: CreateOverrideHitActionPayloadType, callback: () => void) => {
    try {
      set(
        (state) => {
          state.createOverrideHitAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'createOverrideHit/loading', payload },
      )

      await taskCreateOverrideHit(payload)

      set(
        (state) => {
          state.createOverrideHitAction.status = 'SUCCEEDED'
          state.success = getSuccess({})
        },
        false,
        { type: 'createOverrideHit/succeeded' },
      )

      callback()
    } catch (e) {
      set(
        (state) => {
          state.createOverrideHitAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'createOverrideHit/failed' },
      )
    }
  }

/****************************************************************
 * DELETE HIT
 *****************************************************************/

type DeleteHitActionStatusType = 'deleteHit/loading' | 'deleteHit/succeeded' | 'deleteHit/failed'

export type DeleteHitActionPayloadType = {
  idHit: string
}

export const deleteHitAction =
  (
    set: SetState<
      Partial<ProjectSliceType & StoreGlobalType>,
      DeleteHitActionStatusType,
      DeleteHitActionPayloadType
    >,
  ) =>
  async (payload: DeleteHitActionPayloadType, callback?: () => void) => {
    try {
      set(
        (state) => {
          state.deleteHitAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'deleteHit/loading', payload },
      )

      const hitApi = await deleteHit(payload)
      const hit = hitApiMapper(hitApi)

      set(
        (state) => {
          state.deleteHitAction.status = 'SUCCEEDED'
          state.success = getSuccess({})

          const index = state.hits?.findIndex(({ idHit }) => idHit === hit.idHit)
          if (index >= 0) {
            state.hits[index] = hit
          }
        },
        false,
        { type: 'deleteHit/succeeded' },
      )

      callback && callback()
    } catch (e) {
      set(
        (state) => {
          state.deleteHitAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'deleteHit/failed' },
      )
    }
  }

/****************************************************************
 * ASSIGN HIT
 *****************************************************************/

type AssignHitActionStatusType = 'assignHit/loading' | 'assignHit/succeeded' | 'assignHit/failed'

export type AssignHitActionPayloadType = AssignHitPayloadType

export const assignHitAction =
  (
    set: SetState<
      Partial<ProjectSliceType & StoreGlobalType>,
      AssignHitActionStatusType,
      AssignHitActionPayloadType
    >,
  ) =>
  async (payload: AssignHitActionPayloadType, callback?: () => void) => {
    try {
      set(
        (state) => {
          state.assignHitAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'assignHit/loading', payload },
      )

      const hitApi = await assignHit(payload)
      const hit = hitApiMapper(hitApi)

      set(
        (state) => {
          state.assignHitAction.status = 'SUCCEEDED'
          state.success = getSuccess({})

          const index = state.hits?.findIndex(({ idHit }) => idHit === hit.idHit)
          if (index >= 0) {
            state.hits[index] = hit
          }
        },
        false,
        { type: 'assignHit/succeeded' },
      )

      callback && callback()
    } catch (e) {
      set(
        (state) => {
          state.assignHitAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'assignHit/failed' },
      )
    }
  }

/****************************************************************
 * UPDATE MARK HIT
 *****************************************************************/

type UpdateMarkHitActionStatusType =
  | 'updateMarkHit/loading'
  | 'updateMarkHit/succeeded'
  | 'updateMarkHit/failed'

export type UpdateMarkHitActionPayloadType = UpdateHitMarkPayloadType

export const updateMarkHitAction =
  (
    set: SetState<
      Partial<ProjectSliceType & StoreGlobalType>,
      UpdateMarkHitActionStatusType,
      UpdateMarkHitActionPayloadType
    >,
  ) =>
  async (payload: UpdateMarkHitActionPayloadType, callback?: () => void) => {
    try {
      set(
        (state) => {
          state.updateMarkHitAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'updateMarkHit/loading', payload },
      )

      const hitApi = await updateHitMark(payload)
      const hit = hitApiMapper(hitApi)

      set(
        (state) => {
          state.updateMarkHitAction.status = 'SUCCEEDED'
          const index = state.hits?.findIndex(({ idHit }) => idHit === hit.idHit)
          if (index >= 0) {
            state.hits[index] = hit
          }
        },
        false,
        { type: 'updateMarkHit/succeeded' },
      )

      callback && callback()
    } catch (e) {
      set(
        (state) => {
          state.updateMarkHitAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'updateMarkHit/failed' },
      )
    }
  }
