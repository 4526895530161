import { StoreApi } from 'zustand/vanilla'
import { UseBoundStore } from 'zustand'
import { TxKeyPath } from '@i18n'
import { DELAY_DURATION_NOTIFICATION } from '@utils'
import { v4 as uuidv4 } from 'uuid'

import { WithSelectors } from './store.type'
import { NotificationSliceType } from './notification'

export const getSuccess = ({
  messageT,
  descriptionT,
}: {
  messageT?: TxKeyPath
  descriptionT?: TxKeyPath
}): NotificationSliceType['success'] => {
  const duration = DELAY_DURATION_NOTIFICATION
  const messageTranslate = messageT ?? 'notifications.success'
  const descriptionTranslate = descriptionT ?? undefined

  return {
    id: crypto.randomUUID(),
    messageTranslate,
    descriptionTranslate,
    duration,
  }
}

export const getApiError = (e: unknown): NotificationSliceType['error'] => {
  // eslint-disable-next-line no-console
  console.error(e)
  const duration = 5
  const messageTranslate: TxKeyPath = 'notifications.error'
  let description = 'Error'

  if (typeof e === 'object' && 'message' in e) {
    description = String(e.message)
  }

  return {
    id: uuidv4(),
    messageTranslate,
    description,
    duration,
  }
}

export const createSelectors = <S extends UseBoundStore<StoreApi<object>>>(_store: S) => {
  const store = _store as WithSelectors<typeof _store>
  store.use = {}
  for (const k of Object.keys(store.getState())) {
    ;(store.use as any)[k] = () => store((s) => s[k as keyof typeof s])
  }

  return store
}
