import { useMutation, useQuery, useQueryClient } from 'react-query'
import { getRiskSettings, updateRiskSettings } from '@api/risk'
import React, { useState } from 'react'
import { mutationKeys } from '@definitions'
import { RiskSettingsType } from '@pages/types'
import { RiskSettingsApiType } from '@api/types'
import { useTranslation } from '@hooks'
import {
  Button,
  Logo,
  Modal,
  Paragraph,
  Space,
  useDisclosure,
  useNotification,
} from 'common-components'

import FormRiskSensitivityConfig, {
  RiskSensitivityConfigFormPropsType,
} from './FormRiskSensitivityConfig'

import { riskSettingsMapperApi, updateRiskSettingsPayloadMapper } from '@/mappers'

type RiskSensitivityConfigPropsType = Omit<
  RiskSensitivityConfigFormPropsType,
  'onSubmit' | 'settings'
> & {
  settingsUsed?: RiskSettingsType
}

const RiskSensitivityConfig = ({ settingsUsed, ...props }: RiskSensitivityConfigPropsType) => {
  const queryClient = useQueryClient()
  const { translate } = useTranslation()
  const { showNotification, contextHolder } = useNotification()

  const { isOpen, open, close } = useDisclosure()

  const [settings, setSettings] = useState<RiskSettingsType>(settingsUsed)

  const { isLoading, dataUpdatedAt } = useQuery({
    queryKey: mutationKeys.riskSettings(),
    queryFn: () => getRiskSettings(),
    onSuccess: (riskSettingsApi) => {
      const riskSettingsData = riskSettingsMapperApi(riskSettingsApi)
      setSettings(riskSettingsData)
    },
    enabled: !settingsUsed,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  })

  const { mutate: updateRiskSettingsMutation } = useMutation<
    RiskSettingsType,
    unknown,
    RiskSettingsApiType
  >({
    mutationFn: (vars) => updateRiskSettings(updateRiskSettingsPayloadMapper(vars)),
    onSuccess: async () => {
      showNotification({
        notificationType: 'success',
        message: translate('notifications.success'),
      })
      await queryClient.invalidateQueries()
    },
    onError: () => {
      showNotification({
        notificationType: 'error',
        message: translate('notifications.error'),
      })
    },
  })

  return (
    <>
      {contextHolder}
      <Button onClick={open} iconLeft='Settings02'>
        {translate('risk.sensitivitySettings.modalOpenButton')}
      </Button>
      <Modal
        width={1000}
        open={isOpen}
        onOk={close}
        onCancel={close}
        title={translate(`risk.sensitivitySettings.modalTitle`)}
        footer={null}
      >
        <Space direction='vertical' style={{ width: '100%' }}>
          {isLoading ? (
            <div
              style={{
                minHeight: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Logo preview={false} width={100} style={{ marginBottom: 20 }} />
              <Paragraph preset={'pageHeader'}>{translate('auth.loading')}</Paragraph>
            </div>
          ) : (
            !!settings && (
              <FormRiskSensitivityConfig
                settings={settings}
                onSubmit={updateRiskSettingsMutation}
                settingsUpdateTimestamp={dataUpdatedAt}
                onCancel={close}
                {...props}
              />
            )
          )}
        </Space>
      </Modal>
    </>
  )
}

export default RiskSensitivityConfig
