const fr = {
  activeAlertsFilter: 'Alertes actives',
  taskOnProgressFilter: 'Avoir une tâche en cours',
  modelSelection: "Choix d'un modèle",
  versionSelection: "Choix d'une version",
  prefillType: 'Type de préremplissage',
  navigation: {
    title: 'Validation carte grise',
    online: 'En-ligne',
    boost: 'Boost',
    boosted: 'Boosté',
    stopBoost: 'Stopper le boost',
    export: 'Exporter',
    magazine: 'Revue',
    addingData: 'Ajouter des données',
  },
  modal: {
    title: 'Exporter les réponses',
    description: 'Exportez les réponses de votre projet pour commencer à exploiter les données.',
    period: 'Période',
    radioTitle1: 'Format',
    radioOption1: 'Meilleure réponse',
    radioOption2: 'Toutes les réponses',
    radioTitle2: 'Format',
    radioOption3: 'Excel',
    radioOption4: 'JSON',
    subTitle: 'Export des groupes duplicables',
    export: 'Exporter',
  },
  tasklines: {
    title: 'Tasklines',
    table: {
      id: 'Taskline ID',
      creationDate: 'Date de création',
      origin: 'Origine',
      priority: 'Priorité',
      deadline: 'Echéance',
      state: 'Statut',
      costs: 'Coûts',
      provider: 'Provider',
      note: 'Note',
      time: 'Temps',
      model: 'Model',
    },
  },
  settings: {
    title: 'Paramètres',
    pills: {
      general: 'Général',
      model: 'Modele',
    },
    buttons: {
      saveChanges: 'Enregistrer les modifications',
    },
    labels: {
      name: 'Nom',
      form: 'Formulaire',
    },
  },
  audience: {
    title: 'Audience',
    subtitle:
      'Sur la base des paramètres que vous avez choisis, nous estimons que vous aurez un volume de tâche de 10459 tâches sur la période suivante, réparties comme ceci :',
    pills: {
      contributers: 'Contributeurs',
      reviewers: 'Relecteurs',
      excludedContributers: 'Contributeurs exclus',
    },
    buttons: {
      addGroup: 'Ajouter groupe',
      addSegment: 'Ajouter segment',
      saveChanges: 'Enregistrer les modifications',
    },
    select: {
      10: 'Et',
      20: 'Ou',
    },
    blacklist: {
      title: 'Contributeurs exclus',
      button: 'Reintégrer',
    },
  },
  tasklineOverview: {
    title: 'Taskline hit {{id}}',
    hitTitle: 'Taskline {{id}}',
    modelTitle: 'Taskline model {{id}}',
    menuItems: {
      initialEntryView: 'Saisie initiale 1',
      taskView: 'Vue globale de la taskline',
      wirkModelView: 'Wirk Model',
      taskOverloadView: 'Tâche de surcharge',
    },
    actionsTitle: 'Actions',
    actionsItems: {
      requestNewIteration: 'Demander une nouvelle itération',
      requestPrioritizing: 'Prioriser la demande',
      requestDeprioritizing: 'Déprioriser la demande',
      requestOverloadTaskCreation: 'Créer une tâche de surcharge à partir de cette saisie',
      deleteTask: 'Annuler la demande',
    },
    sidebar: {
      title: 'Informations',
      contributor: {
        title: 'Contributor',
        removeButton: 'Libérer',
        assignButton: 'Faire',
        inProgress: 'En cours d’attribution',
        search: 'Recherche...',
      },
      taskMetrics: {
        title: 'Métriques de la tâche',
        taskId: 'ID de la tâche',
        requestId: 'ID de la demande',
        id: 'ID',
        status: 'Statut',
        costInEur: 'Cout en €**',
        quality: 'Qualité',
        duration: 'Durée',
        durationTotal: 'Durée totale',
        effectiveTime: 'Temps effectif',
        depositDate: 'Date de dépot',
        deadlineDate: 'Deadline',
        deliveryDate: 'Date de livraison',
        submissionDate: 'Date de soumission',
        creationDate: 'Date de création',
      },
      history: {
        title: 'Historique',
      },
      review: {
        title: 'Revue',
        rate: 'Note',
        reviewer: 'Relecteur',
        reviewDate: 'Date de revue',
        editButton: 'Editer',
      },
    },
  },
  notifications: {
    nextHitError: 'Pas de résultats positifs à examiner',
  },
}

export default fr
