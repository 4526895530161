/** @jsxImportSource @emotion/react */

import { getCertifications } from '@api'
import { CertificationType } from '@pages/types'
import { useEffect, useState } from 'react'
import { ISelectInputProps, ISelectOptionProps, SelectInput, SelectOption } from 'common-components'

import { certificationApiMapper } from '@/mappers'
import { store } from '@/store'

type SelectCertificationsPropsType = ISelectInputProps & {
  optionProps?: Partial<ISelectOptionProps>
}
const SelectCertifications = ({ optionProps, ...rest }: SelectCertificationsPropsType) => {
  const idMe = store.use.me().userData.idUser
  const [certifications, setCertifications] = useState<CertificationType[]>([])

  useEffect(() => {
    getCertifications({ model: {} }).then((certificationsApi) => {
      const result = certificationsApi?.map((certificationApi) =>
        certificationApiMapper(certificationApi, idMe),
      )
      setCertifications(result)
    })
  }, [])

  return (
    <SelectInput mode={'tags'} {...rest}>
      {certifications.map((certification, i) => (
        <SelectOption
          key={`${i}-${certification.idCertification}`}
          value={certification.idCertification}
          title={`${certification.name}`}
          {...optionProps}
        >
          {certification.name}
        </SelectOption>
      ))}
    </SelectInput>
  )
}

export default SelectCertifications
