/** @jsxImportSource @emotion/react */

import { useTranslation } from '@hooks'
import { AllTranslations } from '@i18n'
import { ISelectInputProps, ISelectOptionProps, SelectInput, SelectOption } from 'common-components'

type StateSelectPropsType = ISelectInputProps & {
  values: Record<number, string>
  labelTxKey: keyof AllTranslations['common']['states']
  optionProps?: Partial<ISelectOptionProps>
}
const StateSelect = ({ values, labelTxKey, optionProps = {}, ...rest }: StateSelectPropsType) => {
  const { translate } = useTranslation()
  type StateVal = keyof AllTranslations['common']['states'][`${typeof labelTxKey}`]

  const keysArray = Object.keys(values) || []

  return (
    <SelectInput mode={'tags'} {...rest}>
      {keysArray.map((stateStr, i) => {
        const state = Number(stateStr) as StateVal
        return (
          <SelectOption
            key={`${i}-${stateStr}`}
            value={state}
            title={translate(`common.states.${labelTxKey}.${state}`)}
            {...optionProps}
          >
            {translate(`common.states.${labelTxKey}.${state}`)}
          </SelectOption>
        )
      })}
    </SelectInput>
  )
}

export default StateSelect
