import { UserShortApiType } from '@api/types'
import { ID_MANAGER_RIGHT, IdManagerRightType } from '@definitions'

type AnyObject = { [key: string]: unknown }

const isEmptyObject = (obj: AnyObject): boolean => {
  return typeof obj === 'object' && !Array.isArray(obj) && Object.keys(obj).length === 0
}

const isEmpty = (value: unknown): boolean => {
  return value === null || value === undefined
}

/**
 * This function allows removing null or undefined values received by the Front from the API.
 * It is crucial to have it to avoid polluting the store with unnecessary values.
 *
 * {x: undefined}   => remove x
 * {x: null}        => remove x
 * {x: [undefined]} => {x: []}
 * {x: [null]}      => {x: []}
 */
export const removeNullAndUndefinedMapper = <T extends AnyObject>(obj: Readonly<T>): T => {
  try {
    if (isEmpty(obj)) {
      return obj
    }

    if (Array.isArray(obj)) {
      // @ts-ignore
      return obj
        .map(removeNullAndUndefinedMapper)
        .filter((item) => !isEmpty(item) && !isEmptyObject(item))
    }

    if (typeof obj === 'object') {
      // @ts-ignore
      const result: T = {}
      for (const key in obj) {
        if (!isEmpty(obj[key])) {
          const cleanedValue = removeNullAndUndefinedMapper(obj[key] as Readonly<T>)
          if (!isEmpty(cleanedValue) && !isEmptyObject(cleanedValue)) {
            // @ts-ignore
            result[key] = cleanedValue
          }
        }
      }
      return result
    }

    return obj
  } catch (e) {
    // eslint-disable-next-line
    console.error(e)
    return obj
  }
}

/**
 * assign Role To Segment Or Certification
 */
export const assignRoleToSegmentOrCertification = (
  idMe: string,
  managers?: UserShortApiType[],
): IdManagerRightType => {
  const managerRight = managers?.find(({ idUser }) => idUser === idMe)?.managerRight

  if (isEmpty(managerRight)) {
    return ID_MANAGER_RIGHT[0]
  }
  return managerRight
}
