import { Route, RouterProvider } from 'react-router'
import CatalogueWorkflowsPage from '@pages/workflows/CatalogueWorkflowsPage'
import { createBrowserRouter, createRoutesFromElements } from 'react-router-dom'
import RootContainer from '@pages/root.container'
import { ErrorPage } from '@pages/ErrorPage'
import { DashboardPage } from '@pages/dashboard'
import { AnalysisPage } from '@pages/analysis'
import { CertificationsPage } from '@pages/certifications'
import { FormsPage } from '@pages/forms'
import { ModelsPage } from '@pages/models'
import { SegmentsPage } from '@pages/segments'
import { SegmentPage } from '@pages/segment'
import { SettingsPage } from '@pages/settings'
import { ProjectsPage } from '@pages/projects'
import { ContributorsPage } from '@pages/contributors'
import { ContributorPage } from '@pages/contributor'
import { ManagersPage } from '@pages/managers'
import { RisksPage } from '@pages/risks'
import {
  CreateCertificationPage,
  CreateDraftQuestionsCertificationPage,
  QuestionCertificationPage,
  QuestionRespondentCertificationPage,
  QuestionsCertificationPage,
  QuestionsRespondentCertificationPage,
  RespondentsCertificationPage,
  CreateSegmentCertificationPage,
  SettingsCertificationPage,
  CertificationContainer,
  RespondentContainer,
} from '@pages/certification'
import {
  AnalyseProjectPage,
  AudienceProjectPage,
  CreateProjectPage,
  DashboardProjectPage,
  TaskHitProjectPage,
  ProjectContainer,
  SettingsProjectPage,
  TasksProjectPage,
} from '@pages/project'
import { RiskPage } from '@pages/risk'
import NavBarLayout from '@components/layout/NavBarLayout'

import { rootUrl, routes } from './routes'

const AppRouter = function () {
  const router = createBrowserRouter(
    createRoutesFromElements([
      <Route path={rootUrl.path} element={<RootContainer />} errorElement={<ErrorPage />}>
        <Route element={<NavBarLayout />}>
          <Route path={routes.dashboard.path} element={<DashboardPage />} />
          <Route path={routes.performances.path} element={<AnalysisPage />} />
          <Route path={routes.certifications.path} element={<CertificationsPage />} />
          <Route path={routes.forms.path} element={<FormsPage />} />
          <Route path={routes.models.path} element={<ModelsPage />} />
          <Route path={routes.segments.path} element={<SegmentsPage />} />
          <Route path={routes.segment.path} element={<SegmentPage />} />
          <Route path={routes.settings.path} element={<SettingsPage />} />
          <Route path={routes.projects.path} element={<ProjectsPage />} />
          <Route path={routes.contributors.path} element={<ContributorsPage />} />
          <Route path={routes.contributor.path} element={<ContributorPage />} />
          <Route path={routes.workflows.path} element={<CatalogueWorkflowsPage />} />
          <Route path={routes.managers.path} element={<ManagersPage />} />
          <Route path={routes.risks.path} element={<RisksPage />} />
        </Route>
        {/** CREATE CERTIFICATION **/}
        <Route path={routes.certificationCreation.path} element={<CreateCertificationPage />} />,
        {/** CERTIFICATION **/}
        <Route
          path='/:tenant/certifications/:id'
          element={<CertificationContainer />}
          errorElement={<ErrorPage />}
        >
          {/** RESPONDENTS **/}
          <Route
            path={routes.certificationRespondents.path}
            element={<RespondentContainer />}
            errorElement={<ErrorPage />}
          >
            <Route index element={<RespondentsCertificationPage />} />
            <Route
              path={routes.certificationRespondentQuestion.path}
              element={<QuestionRespondentCertificationPage />}
            />
            <Route
              path={routes.certificationRespondent.path}
              element={<QuestionsRespondentCertificationPage />}
            />
          </Route>

          <Route
            path={routes.certificationQuestions.path}
            element={<QuestionsCertificationPage />}
          />
          <Route
            path={routes.certificationDraftQuestions.path}
            element={<CreateDraftQuestionsCertificationPage />}
          />
          <Route path={routes.certificationQuestion.path} element={<QuestionCertificationPage />} />

          <Route
            path={routes.certificationSegments.path}
            element={<CreateSegmentCertificationPage />}
          />
          <Route
            path={routes.certificationParameters.path}
            element={<SettingsCertificationPage />}
          />
        </Route>
        ,{/** CREATE PROJECT **/}
        <Route path={routes.projectCreation.path} element={<CreateProjectPage />} />,
        {/** PROJECT **/}
        <Route
          path='/:tenant/projects/:projectId'
          element={<ProjectContainer />}
          errorElement={<ErrorPage />}
        >
          <Route path={routes.projectTasks.path} element={<TasksProjectPage />} />
          <Route path={routes.projectTaskHit.path} element={<TaskHitProjectPage />} />
          <Route path={routes.projectAudience.path} element={<AudienceProjectPage />} />
          <Route path={routes.projectSettings.path} element={<SettingsProjectPage />} />
          <Route path={routes.projectDashboard.path} element={<DashboardProjectPage />} />
          <Route path={routes.projectAnalyse.path} element={<AnalyseProjectPage />} />
        </Route>
        ,{/** RISK **/}
        <Route path={routes.riskOverview.path} element={<RiskPage />} />,
      </Route>,
    ]),
  )

  return <RouterProvider router={router} />
}

export default AppRouter
