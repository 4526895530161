/** @jsxImportSource @emotion/react */

import { CreateCertification } from '@views/certification'
import { useParams } from 'react-router'
import { PageLayout } from '@components/layout'
import { css } from '@emotion/react'
import { Loader } from '@components/utils'
import { useDelayedEffect } from '@hooks'

import { store } from '@/store'

const CreateCertificationPage = () => {
  const { id: idCertification, step } = useParams()
  const currentStep = Number(step)

  const certification = store.use
    .certifications()
    ?.find((certification) => certification.idCertification === idCertification)

  const getCertificationAction = store.use.getCertificationAction().call
  const isLoadingGetCertificationAction = store.use.getCertificationAction().status === 'LOADING'
  const isSucceededGetCertificationAction =
    store.use.getCertificationAction().status === 'SUCCEEDED'

  useDelayedEffect(() => {
    getCertificationAction({ idCertification })
  }, [idCertification])

  return (
    <PageLayout
      css={css`
        max-width: 1440px;
      `}
    >
      {isLoadingGetCertificationAction && <Loader />}
      {isSucceededGetCertificationAction && (
        <CreateCertification currentStep={currentStep} certification={certification} />
      )}
    </PageLayout>
  )
}

export default CreateCertificationPage
