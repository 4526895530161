/** @jsxImportSource @emotion/react */

import { Content, Space } from 'common-components'
import { IWorkflow } from '@types'
import { CardWorkflowPropsType, CardWorkflow } from '@views/shared/workflow'

type CatalogueWorkflowsPropsType = Omit<CardWorkflowPropsType, 'workflow'> & {
  data: (IWorkflow & { cardProps?: Omit<CardWorkflowPropsType, 'workflow'> })[]
}

const ListWorkflow = ({ data, ...rest }: CatalogueWorkflowsPropsType) => {
  return (
    <Content>
      <Space size={10} wrap>
        {data.map((workflow, i) => (
          <CardWorkflow key={`${i}-${workflow.idWorkflow}`} workflow={workflow} {...rest} />
        ))}
      </Space>
    </Content>
  )
}

export default ListWorkflow
