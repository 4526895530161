import {
  deleteCertificationUserTests,
  DeleteCertificationUserTestsPayloadType,
  exportCertificationUserTests,
  ExportCertificationUserTestsPayloadType,
  getCertificationUserTestQuestion,
  GetCertificationUserTestQuestionPayloadType,
  getCertificationUserTestQuestions,
  GetCertificationUserTestQuestionsPayloadType,
  getCertificationUserTests,
  GetCertificationUserTestsPayloadType,
} from '@api/certification'

import { SetState } from '../store.type'
import { getApiError, getSuccess } from '../helper'
import { StoreGlobalType } from '../store'

import { CertificationSliceType } from './certification.slice'

import { certificationUserTestApiMapper, certificationUserTestQuestionApiMapper } from '@/mappers'

/****************************************************************
 * GET RESPONDENTS CERTIFICATION
 *****************************************************************/

type GetRespondentsCertificationActionStatusType =
  | 'getRespondentsCertification/loading'
  | 'getRespondentsCertification/succeeded'
  | 'getRespondentsCertification/failed'

export type GetRespondentsCertificationActionPayloadType = GetCertificationUserTestsPayloadType

export const getRespondentsCertificationAction =
  (
    set: SetState<
      Partial<CertificationSliceType & StoreGlobalType>,
      GetRespondentsCertificationActionStatusType,
      GetRespondentsCertificationActionPayloadType
    >,
    get: () => CertificationSliceType & StoreGlobalType,
  ) =>
  async (payload: GetRespondentsCertificationActionPayloadType) => {
    try {
      set(
        (state) => {
          state.getRespondentsCertificationAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'getRespondentsCertification/loading', payload },
      )

      const idMe = get().me.userData.idUser
      const respondentsApi = await getCertificationUserTests(payload)
      const respondents = respondentsApi?.map((respondentApi) =>
        certificationUserTestApiMapper(respondentApi, idMe),
      )

      set(
        (state) => {
          state.getRespondentsCertificationAction.status = 'SUCCEEDED'
          state.respondentsFiltered = respondents
        },
        false,
        { type: 'getRespondentsCertification/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.getRespondentsCertificationAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'getRespondentsCertification/failed' },
      )
    }
  }

/****************************************************************
 * GET QUESTIONS RESPONDENT CERTIFICATION
 *****************************************************************/

type GetQuestionsRespondentCertificationActionStatusType =
  | 'getQuestionsRespondentCertification/loading'
  | 'getQuestionsRespondentCertification/succeeded'
  | 'getQuestionsRespondentCertification/failed'

export type GetQuestionsRespondentCertificationActionPayloadType =
  GetCertificationUserTestQuestionsPayloadType & {
    idCertification: string
  }

export const getQuestionsRespondentCertificationAction =
  (
    set: SetState<
      Partial<CertificationSliceType & StoreGlobalType>,
      GetQuestionsRespondentCertificationActionStatusType,
      GetQuestionsRespondentCertificationActionPayloadType
    >,
  ) =>
  async (payload: GetQuestionsRespondentCertificationActionPayloadType) => {
    try {
      set(
        (state) => {
          state.getQuestionsRespondentCertificationAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'getQuestionsRespondentCertification/loading', payload },
      )

      const questionsRespondentApi = await getCertificationUserTestQuestions(payload)
      const questionsRespondent = questionsRespondentApi?.map(
        certificationUserTestQuestionApiMapper,
      )

      set(
        (state) => {
          state.getQuestionsRespondentCertificationAction.status = 'SUCCEEDED'

          /** CERTIFICATION **/
          const index = state.respondentsFiltered?.findIndex(
            ({ idCertificationUserTest }) =>
              idCertificationUserTest === payload.model.idCertificationUserTest,
          )
          /** RESPONDENT **/
          if (index >= 0) {
            state.respondentsFiltered[index].questions = questionsRespondent
          }
        },
        false,
        { type: 'getQuestionsRespondentCertification/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.getQuestionsRespondentCertificationAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'getQuestionsRespondentCertification/failed' },
      )
    }
  }

/****************************************************************
 * GET QUESTION RESPONDENT CERTIFICATION
 *****************************************************************/

type GetQuestionRespondentCertificationActionStatusType =
  | 'getQuestionRespondentCertification/loading'
  | 'getQuestionRespondentCertification/succeeded'
  | 'getQuestionRespondentCertification/failed'

export type GetQuestionRespondentCertificationActionPayloadType =
  GetCertificationUserTestQuestionPayloadType & {
    idCertification: string
  }

export const getQuestionRespondentCertificationAction =
  (
    set: SetState<
      Partial<CertificationSliceType & StoreGlobalType>,
      GetQuestionRespondentCertificationActionStatusType,
      GetQuestionRespondentCertificationActionPayloadType
    >,
  ) =>
  async (payload: GetQuestionRespondentCertificationActionPayloadType) => {
    try {
      set(
        (state) => {
          state.getQuestionRespondentCertificationAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'getQuestionRespondentCertification/loading', payload },
      )

      const questionRespondentApi = await getCertificationUserTestQuestion(payload)
      const questionRespondent = certificationUserTestQuestionApiMapper(questionRespondentApi)

      set(
        (state) => {
          state.getQuestionRespondentCertificationAction.status = 'SUCCEEDED'

          /** CERTIFICATION **/
          const indexRespondent = state.respondentsFiltered?.findIndex(
            ({ idCertificationUserTest }) =>
              idCertificationUserTest === payload.idCertificationUserTest,
          )
          /** RESMONDENT **/
          if (indexRespondent >= 0) {
            const indexQuestion = state.respondentsFiltered[indexRespondent]?.questions?.findIndex(
              ({ idCertificationUserTestQuestion }) =>
                idCertificationUserTestQuestion === payload.userTestQuestionId,
            )
            /** QUESTION **/
            if (indexQuestion >= 0) {
              state.respondentsFiltered[indexRespondent].questions[indexQuestion] =
                questionRespondent
            } else {
              state.respondentsFiltered[indexRespondent].questions.unshift(questionRespondent)
            }
          }
        },
        false,
        { type: 'getQuestionRespondentCertification/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.getQuestionRespondentCertificationAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'getQuestionRespondentCertification/failed' },
      )
    }
  }

/****************************************************************
 * DELETE RESPONDENT CERTIFICATION
 *****************************************************************/

type DeleteRespondentCertificationActionStatusType =
  | 'deleteRespondentCertification/loading'
  | 'deleteRespondentCertification/succeeded'
  | 'deleteRespondentCertification/failed'

export type DeleteRespondentCertificationActionPayloadType = DeleteCertificationUserTestsPayloadType

export const deleteRespondentCertificationAction =
  (
    set: SetState<
      Partial<CertificationSliceType & StoreGlobalType>,
      DeleteRespondentCertificationActionStatusType,
      DeleteRespondentCertificationActionPayloadType
    >,
  ) =>
  async (payload: DeleteRespondentCertificationActionPayloadType, callback: () => void) => {
    try {
      set(
        (state) => {
          state.deleteRespondentCertificationAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'deleteRespondentCertification/loading', payload },
      )

      await deleteCertificationUserTests(payload)

      set(
        (state) => {
          state.deleteRespondentCertificationAction.status = 'SUCCEEDED'
          state.success = getSuccess({})

          const index = state.respondentsFiltered?.findIndex(
            ({ idCertificationUserTest }) =>
              idCertificationUserTest === payload.idCertificationUserTest,
          )
          if (index >= 0) {
            state.respondentsFiltered.splice(index, 1)
          }
        },
        false,
        { type: 'deleteRespondentCertification/succeeded' },
      )
      callback()
    } catch (e) {
      set(
        (state) => {
          state.deleteRespondentCertificationAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'deleteRespondentCertification/failed' },
      )
    }
  }

/****************************************************************
 * DOWNLOAD RESPONDENTS CERTIFICATION
 *****************************************************************/

type DownloadRespondentsCertificationActionStatusType =
  | 'downloadRespondentsCertification/loading'
  | 'downloadRespondentsCertification/succeeded'
  | 'downloadRespondentsCertification/failed'

export type DownloadRespondentsCertificationActionPayloadType =
  ExportCertificationUserTestsPayloadType

export const downloadRespondentsCertificationAction =
  (
    set: SetState<
      Partial<CertificationSliceType & StoreGlobalType>,
      DownloadRespondentsCertificationActionStatusType,
      DownloadRespondentsCertificationActionPayloadType
    >,
  ) =>
  async (payload: DownloadRespondentsCertificationActionPayloadType) => {
    try {
      set(
        (state) => {
          state.downloadRespondentsCertificationAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'downloadRespondentsCertification/loading', payload },
      )

      await exportCertificationUserTests(payload)

      set(
        (state) => {
          state.downloadRespondentsCertificationAction.status = 'SUCCEEDED'
        },
        false,
        { type: 'downloadRespondentsCertification/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.downloadRespondentsCertificationAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'downloadRespondentsCertification/failed' },
      )
    }
  }
