import { PresetTag } from '@components/display'
import { ProjectState, TProjectState } from '@definitions/states'
import { useTranslation } from '@hooks'

type StateTagProjectPropsType = {
  state: TProjectState
  italic?: boolean
}

const StateTagProject = ({ state, italic }: StateTagProjectPropsType) => {
  const { translate } = useTranslation()

  return ProjectState[state] === 'boosted' ? (
    <PresetTag
      style={{ fontStyle: italic && 'italic' }}
      preset={'project-state'}
      state={state}
      icon='Lightning01'
    >
      {translate(`common.states.projectState.${state}`)}
    </PresetTag>
  ) : (
    <PresetTag
      style={{ fontStyle: italic && 'italic' }}
      preset={'project-state'}
      state={state}
      withDot
    >
      {translate(`common.states.projectState.${state}`)}
    </PresetTag>
  )
}

export default StateTagProject
