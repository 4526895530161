import { PaginationProps } from 'antd'
import { FilterPayloadType } from '@types'
import { useState } from 'react'
import { ITableProps } from 'common-components'

export type UseRequestParams<T extends { model: Record<any, any> }> = {
  initParams: FilterPayloadType & T
  paginationProps?: PaginationProps
}

export type UseRequestParamsReturn<
  TReq extends { model: Record<any, any> },
  TData extends Record<any, any> = unknown,
> = {
  reqParams: FilterPayloadType & TReq
  afterPageChange: (fetchedItemsNumber: number) => void
  setParams: (params: TReq) => void
  pagination: PaginationProps
  onTableStateChange?: (...params: Parameters<ITableProps<TData>['onChange']>) => void
}

/**
 * TODO "useRequestParams" is a very complex and poorly typed Hook with a lot of occurrences of "any".
 * TODO This part should be handled in the store. We plan to refactor it later.
 */

export function useRequestParams<
  T extends { model: Record<any, any> },
  TData extends Record<any, any> = unknown,
>(props?: UseRequestParams<T>): UseRequestParamsReturn<T, TData> {
  const { initParams, paginationProps = {} } = props || {}
  const { pageSize: initPageSize = 20, defaultCurrent = 0 } = paginationProps

  const [params, setParams] = useState<FilterPayloadType & T>({
    model: {},
    ...initParams,
  })
  const [index, setIndex] = useState(defaultCurrent)
  const [totalItems, setTotalItems] = useState(0)
  const [pageSize, setPageSize] = useState(initPageSize)

  const afterPageChange = (fetchedItemsNumber: number) => {
    // HACK: BE doesn't send total number of items. This function decides either to add one more available page or no
    const total =
      // on current page if less items than page size don't add more pages
      fetchedItemsNumber < pageSize
        ? (index + 1) * pageSize
        : // add page if different
          (index + 1) * pageSize + 1
    setTotalItems(total)
  }

  const onChange = (page: number) => {
    setIndex(page - 1)
  }

  const onParamsChange = (params: T) => {
    setTotalItems(0)
    setParams(params)
  }

  const onShowSizeChange = (_: number, next: number) => {
    setTotalItems(0)
    setPageSize(next)
  }

  const onTableStateChange = (
    ...args: Parameters<UseRequestParamsReturn<T, TData>['onTableStateChange']>
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, __, sorter] = args
    const { columnKey, order } = Array.isArray(sorter) ? sorter[0] : sorter
    const paramsCopy = {
      ...params,
      orderBy: columnKey,
      orderByAsc: order === 'ascend',
    }
    if (typeof order === 'undefined') {
      delete paramsCopy.orderBy
      delete paramsCopy.orderByAsc
    }
    setParams(paramsCopy)
  }

  return {
    setParams: onParamsChange,
    afterPageChange,
    reqParams: {
      index,
      entriesPerPage: pageSize,
      ...params,
    },
    onTableStateChange,
    pagination: {
      onChange,
      defaultPageSize: pageSize,
      current: index + 1,
      total: totalItems,
      pageSize,
      onShowSizeChange,
    },
  }
}
