/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { css } from '@emotion/react'
import { Divider } from 'antd'
import {
  Drawer,
  Button,
  FormItem,
  SelectOption,
  Text,
  SelectInput,
  Title,
  Form,
} from 'common-components'

const statuses = ['Last day', 'Last 7 days', 'Last month', 'Last year']

const drawerContentStyle = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const fullWidthStyle = css`
  width: 100%;
`

const filterButtonsStyle = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`

type FiltersContributorPropsType = {
  visible: boolean
  onClose: () => void
}

const FiltersContributor = ({ visible, onClose }: FiltersContributorPropsType) => {
  const { t } = useTranslation()
  const { register, handleSubmit } = useForm()

  return (
    <Drawer
      title={
        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <Title preset='tablePageTitle'>{t('common.filter')}</Title>
          <Text>{t('common.apply')}</Text>
        </div>
      }
      placement='right'
      closable={true}
      onClose={onClose}
      closeIcon={false}
      open={visible}
    >
      <Form
        onFinish={handleSubmit((data) => {
          onClose()
          return data
        })}
        css={drawerContentStyle}
      >
        {/* Добавитьеще отдельный компонент */}
        <FormItem label='Date'>
          <SelectInput {...register('date')} css={fullWidthStyle}>
            {statuses.map((i) => (
              <SelectOption key={i} value={i}>
                {i}
              </SelectOption>
            ))}
          </SelectInput>
        </FormItem>

        {/* <FormItem label="Projet">
					<SearchPanel showItems={false} title={""} />
				</FormItem>

				<FormItem label="Segment">
					<SearchPanel showItems={false} title={""} />
				</FormItem>

				<FormItem label="Typologie">
					<SearchPanel showItems={false} title={""} />
				</FormItem> */}

        <Divider
          css={css`
            margin-top: 35vh;
          `}
        />

        <div css={filterButtonsStyle}>
          <Button type='primary' htmlType='submit'>
            Apply
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </div>
      </Form>
    </Drawer>
  )
}

export default FiltersContributor
