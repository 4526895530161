const fr = {
  global: 'Global',
  variation: 'Variation',
  distribution: 'Distribution',
  decomposition: 'Décomposition',
  tracking: 'Suivi',
}

export default fr
export type Translations = typeof fr
