/** @jsxImportSource @emotion/react */

import { StatisticsCard, StatisticsCardPropsType } from '@components/cards'
import { CardWorkflow, CardWorkflowPropsType } from '@views/shared/workflow'
import { IGetWorkflowsReq, IWorkflow } from '@types'
import {
  Col,
  colors,
  Content,
  Paragraph,
  Pills,
  Row,
  Space,
  spacing,
  Text,
} from 'common-components'
import { useMemo } from 'react'
import { UseRequestParamsReturn, useTranslation } from '@hooks'
import { useNavigate, useParams } from 'react-router'
import { routes } from '@navigation/routes'
import { ResponsiveContainer, BarChart, Bar } from 'recharts'
import { dateTime } from '@utils'
import { css } from '@emotion/react'
import { Pagination } from 'antd'
import openLink from '@utils/openLink'
import { getWirkUrl } from '@definitions'
import { GetProjectsPayloadType } from '@api'
import { ProjectType } from '@pages/types'

import CardProject from './CardProject'

import { store } from '@/store'

const dashboardTabs = {
  projects: 'projects',
  workflows: 'workflows',
}

type DashboardPropsType = {
  workflows: (IWorkflow & {
    cardProps?: Omit<CardWorkflowPropsType, 'workflow'>
  })[]
  projects: (ProjectType & { cardProps?: Omit<CardWorkflowPropsType, 'workflow'> })[]
  projectsPagination?: UseRequestParamsReturn<GetProjectsPayloadType>['pagination']
  workflowsPagination?: UseRequestParamsReturn<IGetWorkflowsReq>['pagination']
}
const Dashboard = ({
  workflows,
  projects,
  projectsPagination,
  workflowsPagination,
}: DashboardPropsType) => {
  const { translate } = useTranslation()
  const { tab = dashboardTabs.projects } = useParams()
  const navigate = useNavigate()
  const accessToken = store.use.me().accessToken

  const setTab = (tab: string) => navigate(routes.dashboard.navigate(tab))

  const onWorkflowSelect = (workflow: IWorkflow) =>
    openLink(`${getWirkUrl('Manager')}/${workflow.idWorkflow}?token=${accessToken}`)
  const onWorkflowListSelect = (workflow: IWorkflow) =>
    openLink(`${getWirkUrl('Manager')}/${workflow.idWorkflow}/executions?token=${accessToken}`)

  const options = useMemo(
    () => [
      {
        label: translate('dashboard.tabs.projects'),
        value: dashboardTabs.projects,
      },
      {
        label: translate('dashboard.tabs.workflows'),
        value: dashboardTabs.workflows,
      },
    ],
    [],
  )

  const stats: StatisticsCardPropsType[] = [
    {
      title: 'Workflows',
      link: routes.workflows.navigate(),
      statistics: [
        {
          title: 'Iterations',
          value: 1232,
          valueStyle: { color: colors.primary400 },
        },
        {
          title: 'Errors',
          value: 6,
          valueStyle: { color: colors.primary400 },
        },
      ],
    },
    {
      title: 'Projects',
      chartColor: colors.success200,
      link: routes.projects.navigate(),
      statistics: [
        {
          title: 'Tasks completed',
          value: 123321,
          valueStyle: { color: colors.success200 },
        },
      ],
    },
    {
      title: 'Contributors',
      chartColor: colors.warning200,
      link: routes.contributors.navigate(),
      statistics: [
        {
          title: 'On-line',
          value: 234,
          valueStyle: { color: colors.warning200 },
        },
      ],
    },
  ]

  const data = [
    {
      name: 'Page A',
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: 'Page B',
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: 'Page C',
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: 'Page D',
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: 'Page E',
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: 'Page F',
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: 'Page G',
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ]

  const Graph = () => (
    <Row
      css={css`
        padding-top: 10px;
      `}
    >
      <ResponsiveContainer width='100%' height={40}>
        <BarChart width={150} height={40} data={[...data, ...data, ...data]}>
          <Bar dataKey='uv' fill='#8884d8' strokeOpacity={0} radius={5} />
        </BarChart>
      </ResponsiveContainer>
    </Row>
  )
  return (
    <Content>
      <Space size={10} wrap style={{ paddingBottom: 20 }}>
        {stats.map((stat, i) => (
          <StatisticsCard key={`${i}-${stat.title}`} {...stat} />
        ))}
      </Space>
      <Pills
        value={tab}
        onChange={(e) => setTab(e.target.value)}
        options={options}
        buttonProps={'test' as any}
      />
      {tab === 'workflows' && (
        <>
          <Space size={10} wrap>
            {workflows.map((workflow, i) => (
              <CardWorkflow
                key={`${i}-${workflow.idWorkflow}`}
                workflow={workflow}
                onWorkflowSelect={onWorkflowSelect}
                onWorkflowListSelect={onWorkflowListSelect}
                ContentComponent={
                  <Col
                    css={css`
                      padding: ${spacing.padding.card.default}px;
                    `}
                  >
                    <Paragraph>{workflow.name}</Paragraph>
                    <Text>{dateTime(workflow.updateDate)}</Text>
                    <Graph />
                  </Col>
                }
              ></CardWorkflow>
            ))}
          </Space>
          <Row justify={'end'} style={{ width: '100%', padding: '10px 0' }}>
            <Pagination {...workflowsPagination} />
          </Row>
        </>
      )}
      {tab === 'projects' && (
        <>
          <Space size={10} wrap>
            {projects.map((project, i) => (
              <CardProject key={`${i}-${project.idProject}`} project={project} />
            ))}
          </Space>
          {/*FIXME: move pagination to common with predefined styles*/}
          <Row justify={'end'} style={{ width: '100%', padding: '10px 0' }}>
            <Pagination {...projectsPagination} />
          </Row>
        </>
      )}
    </Content>
  )
}

export default Dashboard
