import { FC, useMemo, PropsWithChildren } from 'react'
import { colors, Icon, Tag, Text } from 'common-components'

export type TContributorsTag = 'positive' | 'negative' | 'neutral'

interface Props {
  state: TContributorsTag
  withIcon?: boolean
}

const getContributorsTagColors = (
  state: TContributorsTag,
): { text: string; background: string } => {
  switch (state) {
    case 'positive':
      return {
        text: colors.success700,
        background: colors.success50,
      }
    case 'negative':
      return {
        text: colors.error700,
        background: colors.error50,
      }
    case 'neutral':
      return {
        text: colors.warning700,
        background: colors.warning50,
      }
    default:
      return {
        text: colors.gray700,
        background: colors.gray50,
      }
  }
}

// TODO: Panel card common component should use this tag inside
const ContributorTag: FC<PropsWithChildren<Props>> = ({ state, withIcon = true, children }) => {
  const tagColors = useMemo(() => getContributorsTagColors(state), [state])

  return (
    <Tag
      color={tagColors.background}
      type='fill'
      style={{
        height: 22,
        padding: '2px 10px',
      }}
    >
      {withIcon && (
        <Icon
          color={tagColors.text}
          icon={'ArrowUp'}
          size={14}
          style={{
            transform: `rotate(${state === 'negative' ? '180' : '0'}deg)`,
          }}
        />
      )}
      <Text size='xs' strong style={{ marginLeft: 6, color: tagColors.text }}>
        {children}
      </Text>
    </Tag>
  )
}

export default ContributorTag
