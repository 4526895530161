/** @jsxImportSource @emotion/react */

import {
  BG_COLORS,
  Form,
  FormItem,
  IAvailableIcons,
  IconSearch,
  Row,
  TextInput,
} from 'common-components'
import { css } from '@emotion/react'
import { useTranslation } from '@hooks'
import { getStateKeyByValue } from '@utils'
import { SegmentDefType, SegmentTypeType } from '@definitions'
import { Dispatch, SetStateAction } from 'react'

import PillComponent from './Pill'
import { SegmentFormType } from './CreateSegmentModal'

export type SegmentTypeSelectPropsType = {
  segmentForm: SegmentFormType
  setSegmentForm: Dispatch<SetStateAction<SegmentFormType>>
}

const PILL_DATA: Readonly<{
  id: SegmentTypeType
  icon: IAvailableIcons
  title: string
  text: string
}>[] = [
  {
    id: getStateKeyByValue(SegmentDefType, 'sociodemographic'),
    icon: 'UserCircle',
    title: 'socio',
    text: 'defineSelectionCriteria',
  },
  {
    id: getStateKeyByValue(SegmentDefType, 'manual'),
    icon: 'Hand',
    title: 'manual',
    text: 'manualContributorSelection',
  },
  /* HIDE
	 * {
		id: getStateKeyByValue(SegmentType, "certification") as TSegmentDefType,
		icon: "Star02" as IAvailableIcons,
		title: "competence",
		text: "specificCompetenceSelection",
	},*/
]

const TypeSegmentSelect = ({ segmentForm, setSegmentForm }: SegmentTypeSelectPropsType) => {
  const { translate } = useTranslation()

  return (
    <Form
      css={css`
        margin-top: 20px;
      `}
    >
      <Row
        css={css`
          gap: 15px;
        `}
      >
        <FormItem label={translate('createSegment.icon')}>
          <IconSearch
            value={segmentForm?.icon}
            bgColors={BG_COLORS}
            onSubmit={(icon) =>
              setSegmentForm((prev) => ({
                ...prev,
                icon,
              }))
            }
          />
        </FormItem>
        <FormItem
          css={css`
            flex-grow: 1;
          `}
          label={translate('createSegment.name')}
        >
          <TextInput
            onBlur={(e) =>
              setSegmentForm((prev) => ({
                ...prev,
                name: e.target.value,
              }))
            }
            placeholder={translate('createSegment.exampleEnglishLevel1')}
          />
        </FormItem>
      </Row>
      <Row
        css={css`
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 10px;
        `}
      >
        {PILL_DATA.map((elem) => (
          <PillComponent
            elem={elem}
            segmentType={segmentForm?.segmentType}
            setSegmentType={(value) =>
              setSegmentForm((prev) => ({
                ...prev,
                segmentType: value,
              }))
            }
          />
        ))}
      </Row>
    </Form>
  )
}

export default TypeSegmentSelect
