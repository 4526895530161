/** @jsxImportSource @emotion/react */
import {
  Tag as BaseTag,
  ITagProps,
  colors,
  Icon,
  IIconProps,
  IBadgeProps,
  Badge,
} from 'common-components'
import {
  TCertificationState,
  THitState,
  TTaskState,
  TProjectState,
  TQuestionState,
  TWorkflowType,
  TSegmentDefType,
  TUserStatusState,
  TFormState,
  TUserType,
  TQuality,
  TSkillType,
  TModelState,
  TModelCategory,
  IdManagerRightType,
  TRiskState,
  TRiskLevelState,
} from '@definitions'
import { css } from '@emotion/react'
import { RiskItemType } from '@pages/types'

type TPresetType =
  | 'certification-state'
  | 'question-state'
  | 'project-state'
  | 'common-state'
  | 'model-state'
  | 'model-category'
  | 'workflow-type'
  | 'user-status'
  | 'user-type'
  | 'task-state'
  | 'hit-state'
  | 'segment-type'
  | 'form-state'
  | 'quality'
  | 'skill'
  | 'user-access'
  | 'risk-state'
  | 'risk-level'
  | 'risk-score'

type TPresetStateType =
  | TCertificationState
  | TQuestionState
  | TProjectState
  | TWorkflowType
  | TSegmentDefType
  | TModelState
  | TModelCategory
  | TUserStatusState
  | TTaskState
  | THitState
  | TUserType
  | TFormState
  | TQuality
  | TSkillType
  | IdManagerRightType
  | TRiskState
  | TRiskLevelState
  | RiskItemType['score']
  | string

export type PresetTagPropsType = Omit<ITagProps, 'icon'> & {
  preset: TPresetType
  state?: TPresetStateType
  withDot?: boolean
  dotProps?: IBadgeProps
  icon?: IIconProps['icon']
}
const PresetTag = ({
  preset,
  state,
  withDot,
  icon,
  children,
  style,
  ...rest
}: PresetTagPropsType) => {
  const color = getPreset(preset, state)
  const getPrefix = () => {
    if (withDot && !icon) {
      return (
        <Badge
          dot
          alignSelf='center'
          color={color}
          css={css`
            margin-right: 5px;
            font-size: 12px;
          `}
        />
      )
    }
    if (icon && !withDot) {
      return <Icon icon={icon} color={color} size={12} style={{ marginRight: 6 }} />
    }
  }

  return (
    <BaseTag color={color} style={{ fontWeight: 500, ...style }} {...rest}>
      {getPrefix()}
      {children}
    </BaseTag>
  )
}

const getPreset = (
  preset: PresetTagPropsType['preset'],
  state?: PresetTagPropsType['state'] | string,
) => {
  switch (preset) {
    case 'certification-state':
      return getCertificationStateColor(state as TCertificationState)
    case 'question-state':
      return getQuestionStateColor(state as TQuestionState)
    case 'project-state':
      return getProjectStateColor(state as TProjectState)
    case 'workflow-type':
      return getWorkflowTypeColor(state as TWorkflowType)
    case 'task-state':
      return getTaskStateColor(state as TTaskState)
    case 'hit-state':
      return getHitStateColor(state as THitState)
    case 'segment-type':
      return getSegmentTypeColor(state as TSegmentDefType)
    case 'form-state':
      return getFormStateColor(state as TFormState)
    case 'user-status':
      return getUserStatusColor(state as TUserStatusState)
    case 'user-type':
      return getUserTypeColor(state as TUserType)
    case 'user-access':
      return getUserAccessLevelColor(state as IdManagerRightType)
    case 'quality':
      return getQualityColor(state as TQuality)
    case 'skill':
      return getSkillColor(state as TSkillType)
    case 'risk-state':
      return getRiskStateColor(state as TRiskState)
    case 'risk-level':
      return getRiskLevelColors(state as TRiskLevelState)
    case 'risk-score':
      return getRiskScoreColors(state as RiskItemType['score'])
  }
}

// const getCommonStateColor = (state: string) => {
// 	switch (state) {
// 	case "darkGray":
// 		return colors.gray700;
// 	case "darkGreen":
// 		return colors.success700;
// 	default:
// 		return "transparent";
// 	}
// };

const getCertificationStateColor = (state?: TCertificationState) => {
  switch (state?.toString()) {
    case '1':
      return colors.gray900
    case '10':
      return colors.success600
    case '20':
      return colors.warning600
    case '100':
      return colors.gray600
    default:
      return colors.gray600
  }
}

const getQuestionStateColor = (state?: TQuestionState) => {
  switch (state?.toString()) {
    case '1':
      return colors.warning600
    case '10':
      return colors.success600
    case '20':
      return colors.gray400
    default:
      return colors.gray100
  }
}

const getUserStatusColor = (state?: TUserStatusState) => {
  switch (state?.toString()) {
    case '0':
      return colors.success400
    case '1':
      return colors.gray400
    case '2':
      return colors.primary400
    default:
      return colors.gray400
  }
}

const getUserTypeColor = (state?: TUserType) => {
  switch (state?.toString()) {
    case '0':
      return colors.warning500
    case '10':
      return colors.yellow400
    case '20':
      return colors.blue400
    case '30':
      return colors.success500
    case '40':
      return colors.gray500
    default:
      return colors.gray400
  }
}

const getProjectStateColor = (state?: TProjectState) => {
  switch (state?.toString()) {
    case '1':
    case '2':
    case '3':
    case '4':
      return colors.gray700
    case '10':
      return colors.success700
    case '15':
      return colors.blue700
    case '20':
      return colors.primary700
    case '30':
      return colors.gray700
    case '40':
      return colors.gray700
    case '50':
      return colors.gray700
    default:
      return colors.gray100
  }
}

const getTaskStateColor = (state?: TTaskState) => {
  switch (state?.toString()) {
    case '10':
      return colors.gray700
    // TODO: ask and add proper yellow color
    case '20':
      return colors.yellow500
    case '30':
      return colors.lightBlue500
    case '80':
      return colors.warning500
    case '90':
      return colors.error700
    case '100':
      return colors.success700
    case '999':
      return colors.gray700
    case '990':
      return colors.warning700
    default:
      return colors.gray700
  }
}

const getFormStateColor = (state?: TFormState) => {
  switch (state?.toString()) {
    case '10':
      return colors.gray700
    case '20':
      return colors.success600
    case '99':
      return colors.gray700
  }
}

// TODO: real values

const getHitStateColor = (state?: THitState) => {
  switch (state?.toString()) {
    case '1':
      return colors.gray700
    // TODO: ask and add proper yellow color
    case '2':
      return colors.yellow500
    case '3':
      return colors.lightBlue500
    case '4':
      return colors.success700
    case '5':
      return colors.success700
    case '6':
      return colors.error700
    case '100':
      return colors.gray700
    default:
      return colors.gray700
  }
}

const getWorkflowTypeColor = (type?: TWorkflowType) => {
  switch (type?.toString()) {
    case '10':
      return colors.success700
    case '20':
      return colors.primary700
  }
}

const getSegmentTypeColor = (type?: TSegmentDefType) => {
  switch (type?.toString()) {
    case '10':
      return colors.indigo700
    case '20':
      return colors.blue700
    case '30':
      return colors.purple700
    case '40':
      return colors.lightBlue700
    default:
      return colors.gray700
  }
}

const getQualityColor = (type?: TQuality) => {
  switch (type?.toString()) {
    default:
      return colors.gray700
  }
}

const getSkillColor = (type?: TSkillType) => {
  switch (type?.toString()) {
    default:
      return colors.gray700
  }
}

// const getModelStateColor = (type?: TModelState) => {
// 	switch (type?.toString()) {
// 	default:
// 		return colors.gray700;
// 	}
// };

// const getModelCategoryColor = (type?: TModelCategory) => {
// 	switch (type?.toString()) {
// 	default:
// 		return colors.gray700;
// 	}
// };

const getUserAccessLevelColor = (type?: IdManagerRightType) => {
  switch (type?.toString()) {
    case '0':
      return colors.gray700
    case '10':
      return colors.gray700
    case '20':
      return colors.gray700
    case '30':
      return colors.gray700
  }
}

const getRiskStateColor = (type?: TRiskState) => {
  switch (type?.toString()) {
    case '0':
      return colors.blue700
    case '10':
      return colors.primary700
    case '20':
      return colors.success700
    case '90':
      return colors.error700
    case '99':
      return colors.gray700
    default:
      return colors.gray700
  }
}

const getRiskLevelColors = (type: TRiskLevelState) => {
  switch (type?.toString()) {
    case '10':
      return colors.success700
    case '20':
      return colors.warning500
    case '30':
      return colors.error700
  }
}

const getRiskScoreColors = (score: RiskItemType['score']) => {
  if (score <= 0) return colors.success700
  if (score <= 50) return colors.warning500
  return colors.error700
}

export default PresetTag
