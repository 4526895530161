export const rootUrl = Object.freeze({
  path: '/:tenant',
  navigate: () => window.location.pathname.split('/')[1] || '',
} as const)

export const routes = Object.freeze({
  certifications: {
    path: `${rootUrl.path}/certifications`,
    navigate: () => `../../${rootUrl.navigate()}/certifications`,
  },
  certificationCreation: {
    path: `${rootUrl.path}/certifications/:id/create/:step`,
    navigate: (id: string, step: string) =>
      `../../${rootUrl.navigate()}/certifications/${id}/create/${step}`,
  },
  certification: {
    path: `${rootUrl.path}/certifications/:id`,
    navigate: (id: string | number) => `../../${rootUrl.navigate()}/certifications/${id}`,
  },
  certificationDraftQuestions: {
    path: `${rootUrl.path}/certifications/:id/draft-questions`,
    navigate: (id: string | number) =>
      `../../../${rootUrl.navigate()}/certifications/${id}/draft-questions`,
  },
  certificationQuestion: {
    path: `${rootUrl.path}/certifications/:id/questions/:questionId`,
    navigate: (id: string, questionId: string) =>
      `../../../${rootUrl.navigate()}/certifications/${id}/questions/${questionId}`,
  },
  certificationQuestions: {
    path: `${rootUrl.path}/certifications/:id/questions`,
    navigate: (id: string | number) =>
      `../../../${rootUrl.navigate()}/certifications/${id}/questions`,
  },
  certificationParameters: {
    path: `${rootUrl.path}/certifications/:id/parameters`,
    navigate: (id: string | number) =>
      `../../${rootUrl.navigate()}/certifications/${id}/parameters`,
  },
  certificationRespondent: {
    path: `${rootUrl.path}/certifications/:id/respondents/:respondentTestId/questions`,
    navigate: (id: string, respondentTestId: string) =>
      `../../../../${rootUrl.navigate()}/certifications/${id}/respondents/${respondentTestId}/questions`,
  },
  certificationRespondentQuestion: {
    path: `${rootUrl.path}/certifications/:id/respondents/:respondentTestId/questions/:questionId`,
    navigate: (id: string, respondentTestId: string, questionId: string) =>
      `../../../../${rootUrl.navigate()}/certifications/${id}/respondents/${respondentTestId}/questions/${questionId}`,
  },
  certificationRespondents: {
    path: `${rootUrl.path}/certifications/:id/respondents`,
    navigate: (id: string | number) =>
      `../../../../${rootUrl.navigate()}/certifications/${id}/respondents`,
  },
  certificationSegments: {
    path: `${rootUrl.path}/certifications/:id/segments`,
    navigate: (id: string | number) => `../../${rootUrl.navigate()}/certifications/${id}/segments`,
  },

  contributors: {
    path: `${rootUrl.path}/contributors`,
    navigate: () => `../${rootUrl.navigate()}/contributors`,
  },
  contributor: {
    path: `${rootUrl.path}/contributor/:id`,
    navigate: (id: string) => `../contributor/${id}`,
  },

  dashboard: {
    path: `${rootUrl.path}/dashboard/:tab`,
    navigate: (tab = `projects`) => `../dashboard/${tab}`,
  },

  forms: {
    path: `${rootUrl.path}/forms`,
    navigate: () => `../${rootUrl.navigate()}/forms`,
  },

  managers: {
    path: `${rootUrl.path}/managers`,
    navigate: () => `../${rootUrl.navigate()}/managers`,
  },

  models: {
    path: `${rootUrl.path}/models`,
    navigate: () => `../${rootUrl.navigate()}/models`,
  },

  performances: {
    path: `${rootUrl.path}/`,
    navigate: () => `../${rootUrl.navigate()}/`,
  },

  projects: {
    path: `${rootUrl.path}/projects`,
    navigate: () => `../../${rootUrl.navigate()}/projects`,
  },
  project: {
    path: `${rootUrl.path}/projects/:id`,
    navigate: (id: string | number) => `../projects/${id}`,
  },
  projectDashboard: {
    path: `${rootUrl.path}/projects/:projectId/dashboard`,
    navigate: (id: string | number) => `../../projects/${id}/dashboard`,
  },
  projectAnalyse: {
    path: `${rootUrl.path}/projects/:projectId/analyse`,
    navigate: (id: string | number) => `../projects/${id}/analyse`,
  },
  projectTaskHit: {
    path: `${rootUrl.path}/projects/:projectId/tasklines/:taskId/:view/:hitId?`,
    navigate: (projectId: string, taskId: string, subPath: string) =>
      `../../../${rootUrl.navigate()}/projects/${projectId}/tasklines/${taskId}/${subPath}`,
  },
  projectTask: {
    path: `${rootUrl.path}/projects/:projectId/tasklines/:taskId/:view?`,
    navigate: (projectId: string, taskId: string) =>
      `../../../${rootUrl.navigate()}/projects/${projectId}/tasklines/${taskId}/task`,
  },
  projectTasks: {
    path: `${rootUrl.path}/projects/:projectId/tasklines`,
    navigate: (id: string | number) => `../../../${rootUrl.navigate()}/projects/${id}/tasklines`,
  },
  projectAudience: {
    path: `${rootUrl.path}/projects/:projectId/audience`,
    navigate: (id: string | number) => `../../${rootUrl.navigate()}/projects/${id}/audience`,
  },
  projectSettings: {
    path: `${rootUrl.path}/projects/:projectId/settings`,
    navigate: (id: string | number) => `../../${rootUrl.navigate()}/projects/${id}/settings`,
  },
  projectCreation: {
    path: `${rootUrl.path}/projects/:id/create/:step`,
    navigate: (id: string, step: number) =>
      `../../${rootUrl.navigate()}/projects/${id}/create/${step}`,
  },

  segments: {
    path: `${rootUrl.path}/segments`,
    navigate: () => `../../${rootUrl.navigate()}/segments`,
  },
  segment: {
    path: `${rootUrl.path}/segments/:id`,
    navigate: (id: string | number) => `../segments/${id}`,
  },

  settings: {
    path: `${rootUrl.path}/settings/:tab`,
    navigate: (tab: string) => `../settings/${tab}`,
  },

  workflows: {
    path: `${rootUrl.path}/workflows`,
    navigate: () => `../${rootUrl.navigate()}/workflows`,
  },

  risks: {
    path: `${rootUrl.path}/risks`,
    navigate: () => `../${rootUrl.navigate()}/risks`,
  },
  riskOverview: {
    path: `${rootUrl.path}/risks/:idRisk`,
    navigate: (id: string) => `../../${rootUrl.navigate()}/risks/${id}`,
  },
} as const)
