import { UseTranslationOptions } from 'react-i18next'
import { PresetTag } from '@components/display'
import { useMemo, useState } from 'react'
import { useTranslation } from '@hooks'
import { useNavigate, useParams } from 'react-router'
import { routes } from '@navigation/routes'
import { getWirkUrl, THitState, TTaskState } from '@definitions'
import openLink from '@utils/openLink'
import { HitType } from '@pages/types'
import {
  Button,
  ButtonGroup,
  colors,
  Icon,
  LayoutContainer,
  Paragraph,
  Row,
  Space,
  Text,
} from 'common-components'
import { Dropdown } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import { tasklineHitViews, TTasklineHitNavParams } from '../shared'

import { store } from '@/store'

type TaskHitTopBarProjectPropsType = {
  hitState?: THitState
  taskState?: TTaskState
}

type ActionItemType = {
  key:
    | 'CREATE_ADDITIONAL_HIT'
    | 'INCREASE_PRIORITY_TASK'
    | 'DECREASE_PRIORITY_TASK'
    | 'CREATE_OVERRIDE_HIT'
    | 'DELETE_TASK'
  label: JSX.Element
  icon: JSX.Element
}

const TaskHitTopBarProject = ({ taskState, hitState }: TaskHitTopBarProjectPropsType) => {
  const { translate } = useTranslation()
  const params = useParams<TTasklineHitNavParams>()
  const accessToken = store.use.me().accessToken

  const navigate = useNavigate()
  const { projectId, taskId, hitId, view } = useParams<TTasklineHitNavParams>()

  // TODO HIDE NOT REMOVE
  // const { project } = useOutletContext<{ project: ProjectType }>()
  // const idModel = project?.prefillSettings?.model?.idModel

  const [selectedView, setSelectedView] = useState<HitType>(null)
  const [isOpenDropdownActions, setIsOpenDropdownActions] = useState<boolean>()

  const hits = store.use.hits()
  const hitReview = store.use.hitReview()

  const deleteTaskAction = store.use.deleteTaskAction().call
  const increasePriorityTaskAction = store.use.increasePriorityTaskAction().call
  const decreasePriorityTaskAction = store.use.decreasePriorityTaskAction().call
  const createAdditionalHitAction = store.use.createAdditionalHitAction().call
  const createOverrideHitAction = store.use.createOverrideHitAction().call
  const getTaskLogsAction = store.use.getTaskLogsAction().call
  const getHitsAction = store.use.getHitsAction().call

  const isLoadingCreateAdditionalHitAction =
    store.use.createAdditionalHitAction().status === 'LOADING'
  const isLoadingGetTaskLogsAction = store.use.getTaskLogsAction().status === 'LOADING'
  const isLoadingIncreasePriorityTaskAction =
    store.use.increasePriorityTaskAction().status === 'LOADING'
  const isLoadingDecreasePriorityTaskAction =
    store.use.decreasePriorityTaskAction().status === 'LOADING'
  const isLoadingDeleteTaskAction = store.use.deleteTaskAction().status === 'LOADING'
  const isLoadingCreateOverrideHitAction = store.use.createOverrideHitAction().status === 'LOADING'
  const isLoadingGetHitsAction = store.use.getHitsAction().status === 'LOADING'

  const isLoadingAction1 = isLoadingCreateAdditionalHitAction || isLoadingGetTaskLogsAction
  const isLoadingAction2 = isLoadingIncreasePriorityTaskAction
  const isLoadingAction3 = isLoadingDecreasePriorityTaskAction
  const isLoadingAction4 =
    isLoadingCreateOverrideHitAction || isLoadingGetHitsAction || isLoadingGetTaskLogsAction
  const isLoadingAction5 = isLoadingDeleteTaskAction

  const onViewChange = (subPath: string) => {
    setSelectedView(hits?.find((hit: HitType) => subPath.includes(hit.idHit)))
    navigate(routes.projectTaskHit.navigate(projectId, taskId, subPath))
  }

  const onActionSelect = (key: ActionItemType['key']) => {
    switch (key) {
      case 'CREATE_ADDITIONAL_HIT':
        createAdditionalHitAction({ idTask: taskId }, () => {
          getTaskLogsAction({ idTask: taskId })
        })
        break

      case 'INCREASE_PRIORITY_TASK':
        increasePriorityTaskAction({ idTask: taskId })
        break

      case 'DECREASE_PRIORITY_TASK':
        decreasePriorityTaskAction({ idTask: taskId })
        break

      case 'CREATE_OVERRIDE_HIT':
        createOverrideHitAction({ idTask: taskId }, () => {
          getHitsAction({ idTask: taskId }, () => {
            getTaskLogsAction({ idTask: taskId }, () => {
              openLink(
                `${getWirkUrl('Form')}/contribute/project/${
                  params.projectId
                }?token=${accessToken}&tenant=${params.tenant}`,
              )
            })
          })
        })
        break

      case 'DELETE_TASK':
        deleteTaskAction({ idTask: taskId }, () =>
          navigate(routes.projectTasks.navigate(projectId)),
        )
        break
    }
  }

  const viewItems = useMemo(() => {
    const items = [
      ...hits.map((hit: HitType) => ({
        key: `${tasklineHitViews[0]}/${hit.idHit}`,
        label: <Text>{translate(`common.states.hitType.${hit.hitType}`) + ' - ' + hit.idHit}</Text>,
      })),
      {
        key: tasklineHitViews[1],
        label: <Text>{translate('project.tasklineOverview.menuItems.taskView')}</Text>,
      },
    ]
    // TODO HIDE NOT REMOVE
    // modelId &&
    //   items.push({
    //     key: tasklineHitViews[2],
    //     label: <Text>{translate('project.tasklineOverview.menuItems.wirkModelView')}</Text>,
    //   })
    return items
  }, [hits?.length])

  const actionsItems: ActionItemType[] = useMemo(
    () => [
      {
        key: 'CREATE_ADDITIONAL_HIT',
        label: (
          <Text>{translate('project.tasklineOverview.actionsItems.requestNewIteration')}</Text>
        ),
        icon: isLoadingAction1 ? <LoadingOutlined /> : <Icon icon={'FilePlus03'} />,
      },
      {
        key: 'INCREASE_PRIORITY_TASK',
        label: (
          <Text>{translate('project.tasklineOverview.actionsItems.requestPrioritizing')}</Text>
        ),
        icon: isLoadingAction2 ? <LoadingOutlined /> : <Icon icon={'ArrowUp'} />,
      },
      {
        key: 'DECREASE_PRIORITY_TASK',
        label: (
          <Text>{translate('project.tasklineOverview.actionsItems.requestDeprioritizing')}</Text>
        ),
        icon: isLoadingAction3 ? <LoadingOutlined /> : <Icon icon={'ArrowDown'} />,
      },
      {
        key: 'CREATE_OVERRIDE_HIT',
        label: (
          <Text>
            {translate('project.tasklineOverview.actionsItems.requestOverloadTaskCreation')}
          </Text>
        ),
        icon: isLoadingAction4 ? <LoadingOutlined /> : <Icon icon={'Asterisk01'} />,
      },
      {
        key: 'DELETE_TASK',
        label: <Text>{translate('project.tasklineOverview.actionsItems.deleteTask')}</Text>,
        icon: isLoadingAction5 ? <LoadingOutlined /> : <Icon icon={'Trash01'} />,
      },
    ],
    [isLoadingAction1, isLoadingAction2, isLoadingAction3, isLoadingAction4, isLoadingAction5],
  )

  const resolveHeaderTitle = () => {
    switch (view) {
      case 'hit':
        return `${translate(`project.tasklineOverview.hitTitle`, {
          id: hitId,
        } as UseTranslationOptions)}`
      case 'task':
        return `${translate(`project.tasklineOverview.title`, {
          id: taskId,
        } as UseTranslationOptions)}`
      // TODO HIDE NOT REMOVE
      // case 'wirk-model':
      //   return `${translate(`project.tasklineOverview.modelTitle`, {
      //     id: modelId,
      //   } as UseTranslationOptions)}`
    }
  }

  const HeaderTag = () => {
    switch (view) {
      case 'hit':
        return hitState ? (
          <PresetTag preset={'hit-state'} state={hitState} withDot>
            {translate(`common.states.hitState.${hitState}`)}
          </PresetTag>
        ) : (
          <></>
        )
      case 'task':
      case 'wirk-model':
        return taskState ? (
          <PresetTag preset={'task-state'} state={taskState} withDot>
            {translate(`common.states.taskState.${taskState}`)}
          </PresetTag>
        ) : (
          <></>
        )
    }
  }

  return (
    <LayoutContainer style={{ padding: 18, borderBottom: `1px solid ${colors.gray200}` }}>
      <Row style={{ width: '100%' }} justify={'space-between'}>
        <Space size={10} align={'center'}>
          <Paragraph size={'lg'} color={colors.gray900} style={{ marginBottom: 0 }}>
            {resolveHeaderTitle()}
          </Paragraph>
          <HeaderTag />
        </Space>
        <Space size={10} align={'center'}>
          <Dropdown
            menu={{
              items: viewItems,
              onClick: ({ key }) => onViewChange(key),
            }}
          >
            <Button iconRight={'ChevronDown'}>
              {view === 'hit' &&
                translate(`common.states.hitType.${selectedView?.hitType || 1}`) + ' - ' + hitId}
              {view === 'task' && translate('project.tasklineOverview.menuItems.taskView')}
              {view === 'wirk-model' &&
                translate('project.tasklineOverview.menuItems.wirkModelView')}
            </Button>
          </Dropdown>
          <Dropdown
            open={isOpenDropdownActions}
            menu={{
              items: actionsItems,
              onClick: ({ key }) => {
                onActionSelect(key as ActionItemType['key'])
              },
              disabled:
                isLoadingAction1 ||
                isLoadingAction2 ||
                isLoadingAction3 ||
                isLoadingAction4 ||
                isLoadingAction5,
              onMouseLeave: () => setIsOpenDropdownActions(false),
            }}
          >
            <Button
              iconRight={'ChevronDown'}
              onMouseEnter={(): void => setIsOpenDropdownActions(true)}
            >
              {translate('project.tasklineOverview.actionsTitle')}
            </Button>
          </Dropdown>
          <ButtonGroup className='ant-btn-group'>
            <Button onClick={() => history.back()} iconLeft={'ChevronLeft'} />
            <Button
              onClick={() => {
                navigate(
                  routes.projectTaskHit.navigate(
                    hitReview?.idProject,
                    hitReview?.task?.idTask,
                    `${tasklineHitViews[0]}/${hitReview.idHit}`,
                  ),
                )
              }}
              disabled={!hitReview?.idHit}
              iconLeft={'ChevronRight'}
            />
          </ButtonGroup>
        </Space>
      </Row>
    </LayoutContainer>
  )
}

export default TaskHitTopBarProject
