/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useTranslation } from '@hooks'
import {
  Button,
  Col,
  RangePicker,
  Row,
  StatisticCard,
  colors,
  Table,
  Paragraph,
} from 'common-components'

const data = [
  {
    key: '1',
    projectName: 'Saisie de chèque Décembre',
    lastTask: '8/12/12 14:32',
    completedTasks: 134,
    rate: '4 tâches / mn',
    effectiveTime: '1 heure 12 mn',
    distribution: '35 %',
    quality: '35 %',
  },
  {
    key: '2',
    projectName: 'Saisie de chèque exotiques',
    lastTask: '8/12/12 14:32',
    completedTasks: 1324,
    rate: '4 tâches / mn',
    effectiveTime: '1 heure 12 mn',
    distribution: '35 %',
    quality: '35 %',
  },
  {
    key: '3',
    projectName: 'Saisie de chèque exotiques',
    lastTask: '8/12/12 14:32',
    completedTasks: 1324,
    rate: '4 tâches / mn',
    effectiveTime: '1 heure 12 mn',
    distribution: '35 %',
    quality: '35 %',
  },

  {
    key: '4',
    projectName: 'Saisie de chèque exotiques',
    lastTask: '8/12/12 14:32',
    completedTasks: 1324,
    rate: '4 tâches / mn',
    effectiveTime: '1 heure 12 mn',
    distribution: '35 %',
    quality: '35 %',
  },
  {
    key: '5',
    projectName: 'Saisie de chèque exotiques',
    lastTask: '8/12/12 14:32',
    completedTasks: 1324,
    rate: '4 tâches / mn',
    effectiveTime: '1 heure 12 mn',
    distribution: '35 %',
    quality: '35 %',
  },
]

const ActivityContributor = () => {
  const { translate } = useTranslation()
  const cardData = [
    {
      title: translate('contributor.workedHours'),
      value: 'NO DATA',
    },
    {
      title: translate('contributor.numberOfProjects'),
      value: 'NO DATA',
    },
    {
      title: translate('contributor.volume'),
      value: 'NO DATA',
    },
  ]

  const columns = [
    {
      title: 'Nom du projet',
      dataIndex: 'projectName',
      key: 'projectName',
    },
    {
      title: 'Dernière tâche',
      dataIndex: 'lastTask',
      key: 'lastTask',
    },
    {
      title: 'Tâches effectuées',
      dataIndex: 'completedTasks',
      key: 'completedTasks',
    },
    {
      title: 'Cadence',
      dataIndex: 'rate',
      key: 'rate',
    },
    {
      title: 'Temps effectif',
      dataIndex: 'effectiveTime',
      key: 'effectiveTime',
    },
    {
      title: 'Repartition',
      dataIndex: 'distribution',
      key: 'distribution',
    },
    {
      title: 'Qualité',
      dataIndex: 'quality',
      key: 'quality',
    },
  ]

  return (
    <div
      css={css`
        margin-top: 40px;
      `}
    >
      <Row justify={'space-between'}>
        <RangePicker />
        <Row
          css={css`
            gap: 12px;
          `}
        >
          <Button iconLeft='Download01'>Exporter</Button>
          <Button iconLeft='FilterLines'>Filtrer</Button>
        </Row>
      </Row>
      <Row
        css={css`
          gap: 20px;
          margin-top: 20px;
        `}
      >
        {cardData.map((card) => (
          <Col key={card.title}>
            <StatisticCard width={392} title={card.title} value={card.value} />
          </Col>
        ))}
      </Row>
      <Row
        css={css`
          border: 1px solid ${colors.gray200};
          border-radius: 12px;
          height: 372px;
          margin: 20px 0;
        `}
        justify={'center'}
        align={'middle'}
      >
        <Paragraph preset='statisticValue'>MEGA COOL GRAPH</Paragraph>
      </Row>
      <Table columns={columns} data={data} />
    </div>
  )
}

export default ActivityContributor
