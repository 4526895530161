import React from 'react'
import AsyncSelect from 'react-select/async'
import { components as defaultComponents, ControlProps, CSSObjectWithLabel } from 'react-select'
import debounce from 'lodash.debounce'
import { Icon, colors } from 'common-components'

const customStyles = {
  control: (provided: CSSObjectWithLabel) => ({
    ...provided,
    display: 'flex',
    height: '40px',
    alignItems: 'center',
    gap: '8px',
    alignSelf: 'stretch',
    borderRadius: '8px',
    border: '1px solid #D0D5DD',
    background: '#FFF',
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    ':hover': {
      borderColor: '#D0D5DD',
    },
  }),
  dropdownIndicator: () => ({}),
  indicatorSeparator: () => ({}),
}

const Control: React.FC<ControlProps<any, boolean>> = ({ children, ...props }) => {
  return (
    <defaultComponents.Control {...props}>
      <div style={{ paddingLeft: 5, marginTop: 5 }}>
        <Icon width={15} height={15} color={colors.gray500} icon='SearchSm' />
      </div>
      {children}
    </defaultComponents.Control>
  )
}

export type ValueSelectType = {
  value: string
  label: string
}
type SelectPropsType<T extends ValueSelectType> = {
  loadOptions: (inputValue: string, callback: (options: T[]) => void) => void
  value: T | ''
  valuesSelected?: string[]
  onChange: (value: T | '') => void
}

const SelectAsync = <T extends ValueSelectType>({
  loadOptions,
  value,
  valuesSelected = [],
  onChange,
}: SelectPropsType<T>) => {
  return (
    <AsyncSelect
      cacheOptions
      defaultOptions
      loadOptions={debounce(loadOptions, 500)}
      value={value}
      isClearable
      isSearchable
      onChange={onChange}
      components={{
        Control,
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        ClearIndicator: (props) => (
          <defaultComponents.ClearIndicator {...props}>
            <Icon width={18} height={18} icon='XClose' />
          </defaultComponents.ClearIndicator>
        ),
        Option: ({ innerProps, innerRef, children }) => {
          const include = valuesSelected.includes(children?.toString())
          if (include) return null
          return (
            <div
              style={{
                padding: '10px',
              }}
              ref={innerRef}
              {...innerProps}
            >
              {children}
            </div>
          )
        },
      }}
      styles={customStyles}
    />
  )
}

export default SelectAsync
