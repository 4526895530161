import { useTranslation } from '@hooks/useTranslation'
import { FC, useMemo, useState } from 'react'
import { ProjectType } from '@pages/types'
import { Content, Pills, Space, Title } from 'common-components'

import GeneralFormSettingsProject from './GeneralFormSettingsProject'

type ProjectSettingsPropsType = {
  project: ProjectType
}

const pillsValues = {
  general: 'general',
  model: 'model',
}

const SettingsProject: FC<ProjectSettingsPropsType> = ({ project }) => {
  const { translate } = useTranslation()
  const [activeTab, setActiveTab] = useState<string>(pillsValues.general)

  const options = useMemo(
    () => [
      {
        label: translate('project.settings.pills.general'),
        value: pillsValues.general,
      },
    ],
    [],
  )

  const activeForm = useMemo(() => {
    switch (activeTab) {
      case pillsValues.general:
        return <GeneralFormSettingsProject project={project} />
      // TODO HIDE
      // case pillsValues.model:
      //   return <ModelFormSettingsProject project={project} />
      default:
        return <p>Error</p>
    }
  }, [activeTab, project?.prefillSettings?.model])

  return (
    <Content>
      <Space direction='vertical' style={{ width: '100%', gap: 16 }}>
        <Space>
          <Title preset='singlePageTitle'>{translate('project.settings.title')}</Title>
        </Space>
        <Pills
          value={activeTab}
          onChange={(e) => setActiveTab(e.target.value)}
          options={options}
          buttonProps={'test' as any}
        />
        {activeForm}
      </Space>
    </Content>
  )
}

export default SettingsProject
