/** @jsxImportSource @emotion/react */

import { Controller, useForm } from 'react-hook-form'
import { css } from '@emotion/react'
import { ProjectState } from '@definitions'
import { CommonFilters, StateCheckbox } from '@components/inputs'
import { useTranslation } from '@hooks'
import { GetProjectsPayloadType } from '@api'
import { SelectSegments } from '@views/shared/segment'
import { SelectManagers } from '@views/shared/manager'
import { Button, Checkbox, Drawer, Form, FormItem, Space, Text, Title } from 'common-components'

import SelectModels from './SelectModels'

type FiltersProjectPropsType = {
  visible: boolean
  onFiltersChange?: (filters: GetProjectsPayloadType) => void
  onClose?: () => void
}

const FiltersProject = ({ visible, onClose, onFiltersChange }: FiltersProjectPropsType) => {
  const { control, handleSubmit } = useForm<GetProjectsPayloadType>({
    defaultValues: { model: {} },
  })
  const { translate } = useTranslation()

  const onSubmit = (data: GetProjectsPayloadType) => {
    onFiltersChange && onFiltersChange(data)
    onClose && onClose()
  }

  const toggleAllDraftValues = (values: any[]) => {
    // hack to group 4 draft states of certification into 1 checkbox input
    const valuesToSet = [...values]
    if (valuesToSet.includes(1)) {
      valuesToSet.push(2, 3, 4)
    } else {
      valuesToSet.indexOf(2) >= 0 && valuesToSet.splice(valuesToSet.indexOf(2), 1)
      valuesToSet.indexOf(3) >= 0 && valuesToSet.splice(valuesToSet.indexOf(3), 1)
      valuesToSet.indexOf(4) >= 0 && valuesToSet.splice(valuesToSet.indexOf(4), 1)
    }
    return valuesToSet
  }

  const getStateForFilter = () => {
    const newState = ProjectState
    delete newState['2']
    delete newState['3']
    delete newState['4']
    return newState
  }

  return (
    <Drawer
      title={
        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <Title preset='tablePageTitle'>{translate('common.filter')}</Title>
          <Text>{translate('common.apply')}</Text>
        </div>
      }
      placement='right'
      closable={true}
      onClose={onClose}
      closeIcon={false}
      open={visible}
    >
      <Form
        onReset={() => {
          control._reset()
          onSubmit({ model: {} })
        }}
        onFinish={handleSubmit(onSubmit)}
        css={$form}
      >
        <CommonFilters<GetProjectsPayloadType> control={control} />

        <Controller
          name={'model.activeAlerts'}
          control={control}
          render={({ field }) => (
            <FormItem>
              <Space>
                <Checkbox
                  onChange={(ev) =>
                    field.onChange({
                      ...ev,
                      target: { ...ev.target, value: ev.target.checked },
                    })
                  }
                  checked={field.value}
                />
                <Text>{translate('project.activeAlertsFilter')}</Text>
              </Space>
            </FormItem>
          )}
        />

        <Controller
          name={'model.taskOnProgress'}
          control={control}
          render={({ field }) => (
            <FormItem>
              <Space>
                <Checkbox
                  onChange={(ev) =>
                    field.onChange({
                      ...ev,
                      target: { ...ev.target, value: ev.target.checked },
                    })
                  }
                  checked={field.value}
                />
                <Text>{translate('project.taskOnProgressFilter')}</Text>
              </Space>
            </FormItem>
          )}
        />

        <Controller
          name={'model.state'}
          control={control}
          render={({ field }) => (
            <FormItem label={translate('common.state')}>
              <StateCheckbox
                values={getStateForFilter()}
                labelTxKey={'projectState'}
                preset={'project-state'}
                inputProps={{
                  onChange: (values) => field.onChange(toggleAllDraftValues(values)),
                  value: field.value,
                }}
              />
            </FormItem>
          )}
        />
        <Controller
          name={'model.segments'}
          control={control}
          render={({ field }) => (
            <FormItem label={translate('common.segments')}>
              <SelectSegments onChange={field.onChange} value={field.value} />
            </FormItem>
          )}
        />
        <Controller
          name={'model.models'}
          control={control}
          render={({ field }) => (
            <FormItem label={translate('common.models')}>
              <SelectModels onChange={field.onChange} value={field.value} />
            </FormItem>
          )}
        />
        <Controller
          name={'model.managers'}
          control={control}
          render={({ field }) => (
            <FormItem label={translate('common.managers')}>
              <SelectManagers onChange={field.onChange} value={field.value} />
            </FormItem>
          )}
        />

        <div css={filterButtonsStyle}>
          <Button type='primary' htmlType='submit'>
            Apply
          </Button>
          <Button htmlType='reset'>Cancel</Button>
        </div>
      </Form>
    </Drawer>
  )
}

const filterButtonsStyle = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`

const $form = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export default FiltersProject
