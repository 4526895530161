const fr = {
  nextStepButton: 'Etape suivante',
  prevStepButton: 'Etape précédente',
  finishButton: 'Finish',
  creationSuccessTitle: 'Votre cerfification a été créée',
  creationSuccessSubtitle:
    'Votre certification est à l’état de brouillon. Pour la publier, entrez les réponses aux questions que vous avez rajouté',
  steps: {
    1: 'Paramètres de saisie',
    2: 'Questions',
    3: 'Segments de certification',
  },
  stepPageTitle: {
    1: 'Choix du formulaire',
    2: 'Ajouter des questions',
    3: 'Paramètres',
  },
  stepPageSubtitle: {
    1: 'Définissez comment sont complétées les tâches de votre projet',
    2: 'Définissez comment sont complétées les tâches de votre projet',
    3: 'Définissez comment sont complétées les tâches de votre projet',
  },
  questions: {
    eraseButtonLabel: 'Vider',
    refreshButtonLabel: 'Rafraîchir',
    refreshText:
      'Vos données sont traitées. Vous pouvez les rafraîchir toutes les 5 secondes pour voir les changements.',
    errorsOnly: 'Erreurs uniquement',
    questionsTitle: 'Questions',
    questionsSubtitle: 'Importez vos tâches au format CSV ou commencez à saisir dans le tableau',
    uploadFile: 'Ajout par excel',
  },
  settings: {
    audienceTitle: 'Audience',
    settingsTitle: 'Paramètres Généraux',
    settingsSubtitle: 'Choisissez un formulaire de saisie',
    audience: {
      external: 'Externes',
      externalDesc: 'Cette certification est également disponible aux contributeurs externes',
      candidates: 'Candidats',
      candidatesDesc: 'Cette certification est un test de recrutement',
      interns: 'Internes',
      internsDesc: 'Cette certification définit l’accès à un projet interne',
    },
    segments: {
      segmentsTitle: 'Segments',
      segmentsSubtitle: 'Définissez des seuils de certification formant des segments',
      addSegmentButton: 'Nouveau segment',
    },
  },
}

export default fr
