import { RadioGroup } from '@components/inputs'
import { useEffect, useMemo } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from '@hooks'
import { HitType } from '@pages/types'
import { Button, colors, Row, Text, useDisclosure } from 'common-components'

import { store } from '@/store'

const DataRow = styled(Row)`
  justify-content: space-between;
  align-items: flex-end;
`

const DataLabel = styled(Text)`
  font-size: 14px;
  font-weight: 500;
  color: ${colors.gray700};
`

const DataValue = styled(DataLabel)`
  font-weight: 400;
  color: ${colors.gray400};
`

export type ReviewTaskLineHitPropsType = {
  hit?: HitType
}
const ReviewHitTask = function ({ hit }: ReviewTaskLineHitPropsType) {
  const { translate } = useTranslation()

  const { open, close, isOpen } = useDisclosure()

  const updateMarkHitAction = store.use.updateMarkHitAction().call

  useEffect(() => {
    hit?.mark ? close() : open()
  }, [hit?.idHit])

  const onReviewUpdate = (mark: number) => {
    updateMarkHitAction({
      mark,
      idHit: hit.idHit,
    })
  }

  const lastReview = useMemo(() => {
    return hit?.hitReviews?.[hit.hitReviews?.length - 1]
  }, [hit?.idHit, hit?.hitReviews])

  return (
    <>
      {isOpen && <RadioGroup onChange={(val) => typeof val === 'number' && onReviewUpdate(val)} />}
      <DataRow>
        <DataLabel>{translate('project.tasklineOverview.sidebar.review.rate')}</DataLabel>
        <DataValue>{lastReview?.mark}</DataValue>
      </DataRow>
      <DataRow>
        <DataLabel>{translate('project.tasklineOverview.sidebar.review.reviewer')}</DataLabel>
        <DataValue>{lastReview?.idReviewer}</DataValue>
      </DataRow>
      <DataRow>
        <DataLabel>{translate('project.tasklineOverview.sidebar.review.reviewDate')}</DataLabel>
        <DataValue>{lastReview?.reviewDate}</DataValue>
      </DataRow>

      <Button onClick={open} size={'inline'}>
        {translate('common.buttons.edit')}
      </Button>
    </>
  )
}
export default ReviewHitTask
