/** @jsxImportSource @emotion/react */
import { useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Controller, useForm } from 'react-hook-form'
import { TFunction, useTranslation } from 'react-i18next'
import { findKeyByValue } from '@utils/find-key'
import { RoleUserType, TUserType, UserType } from '@definitions/states'
import { UploadChangeParam } from 'antd/es/upload/interface'
import debounce from 'lodash.debounce'
import { UserDataType } from '@pages/types'
import {
  FormItem,
  TextInput,
  SelectOption,
  SelectInput,
  colors,
  Avatar,
  DropInput,
  Form,
} from 'common-components'

export type CreateUpdateFormManagerType = {
  createUser: {
    idUser?: string
    email: string
    phone: string
    lastName: string
    firstName: string
    type: TUserType
  }
  file: File
}

const errorTextColor = css`
  padding-top: 5px;
  color: red !important;
`

type FieldsType = {
  name: 'lastName' | 'firstName' | 'phone' | 'email' | 'role' | 'type'
  defaultValue?: any
  errorMessage: string
  label: string
  type: 'input' | 'select'
  rules: {
    required?: boolean
    pattern?: RegExp
  }
  selectOptions?: {
    label: string
    value: string
  }[]
}
const fields1 = (t: TFunction<'translation'>): FieldsType[] => [
  {
    name: 'lastName',
    errorMessage: t(`createUser.lastName.errorMessage`),
    label: t(`createUser.lastName.label`),
    type: 'input',
    rules: { required: true },
  },
  {
    name: 'firstName',
    errorMessage: t(`createUser.firstName.errorMessage`),
    label: t(`createUser.firstName.label`),
    type: 'input',
    rules: { required: true },
  },
]
const fields2 = (t: TFunction<'translation'>): FieldsType[] => [
  {
    name: 'phone',
    errorMessage: t(`createUser.phone.errorMessage`),
    label: t(`createUser.phone.label`),
    type: 'input',
    rules: { required: true },
  },
  {
    name: 'email',
    errorMessage: t(`createUser.email.errorMessage`),
    label: t(`createUser.email.label`),
    type: 'input',
    rules: { required: true, pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/ },
  },
  {
    name: 'role',
    errorMessage: t(`createUser.role.errorMessage`),
    label: t(`createUser.role.label`),
    type: 'select',
    rules: { required: true },
    selectOptions: [
      {
        label: t(`createUser.role.selectOptions.admin`),
        value: 'admin',
      },
      {
        label: t(`createUser.role.selectOptions.manager`),
        value: 'manager',
      },
    ],
  },
]

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const ImageContainer = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
`

const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  margin-left: 20px;
`

export type FormManagerPropsType = {
  manager?: Pick<
    UserDataType,
    'lastName' | 'firstName' | 'phone' | 'email' | 'type' | 'urlAvatar' | 'idUser'
  >
  mode?: 'edit' | 'create'
  onChangeForm: ({ createUser, file }: CreateUpdateFormManagerType) => void
  isFormValid: (validation: boolean) => void
}

type FormType = Pick<CreateUpdateFormManagerType, 'file'> & {
  createUser: CreateUpdateFormManagerType['createUser'] & {
    role: RoleUserType
  }
}

const FormManager = ({
  manager,
  onChangeForm,
  isFormValid,
  mode = 'create',
}: FormManagerPropsType) => {
  const [avatarSrc, setAvatarSrc] = useState<string>(manager?.urlAvatar)
  const file = useRef<File>()
  const isSubmitted = useRef<boolean>(false)

  const { t } = useTranslation()
  const {
    formState: { errors, isDirty, touchedFields },
    trigger,
    control,
    watch,
    getValues,
  } = useForm<FormType['createUser']>({
    values: {
      email: manager?.email,
      type: manager?.type,
      firstName: manager?.firstName,
      lastName: manager?.lastName,
      role: UserType[manager?.type],
      phone: manager?.phone,
    },
  })

  const onUpload = async (ev: UploadChangeParam) => {
    const createdFile = ev.fileList[0]?.originFileObj
    file.current = createdFile
    isSubmitted.current = false
    if (createdFile) {
      const src = URL.createObjectURL(createdFile)
      setAvatarSrc(src)
    }
  }

  const onChangeValues = () => {
    if (!Object.keys(errors).length && isDirty && !isSubmitted.current) {
      const values = getValues()
      const user: CreateUpdateFormManagerType = {
        createUser: {
          idUser: manager?.idUser,
          firstName: values.firstName,
          email: values.email,
          phone: values.phone,
          lastName: values.lastName,
          type: values.role && findKeyByValue(UserType, values.role),
        },
        file: file.current,
      }
      onChangeForm(user)
      isSubmitted.current = true
    }
  }

  useEffect(() => {
    debounce(onChangeValues, 800)()
  }, [Object.keys(errors).length, Object.values(getValues()), file.current])

  useEffect(() => {
    // trigger form validation on init component
    trigger()
  }, [])

  useEffect(() => {
    isFormValid(!Object.keys(errors).length && isDirty)
  }, [Object.keys(errors).length, isDirty])

  useEffect(() => {
    const subscription = watch(() => {
      // trigger form validation
      trigger()
      isSubmitted.current = false
    })
    return () => subscription.unsubscribe()
  }, [watch])

  return (
    <Form>
      <StyledContainer>
        <ImageContainer>
          <div
            css={css`
              display: flex;
              flex-direction: column;
            `}
          >
            {fields1(t).map((item) => {
              return (
                <FormItem label={item.label}>
                  <Controller
                    name={item.name}
                    control={control}
                    rules={item.rules}
                    defaultValue={item.defaultValue}
                    render={({ field }) => <TextInput {...field} />}
                  />
                  <div css={errorTextColor}>
                    {errors[item.name] && touchedFields[item.name] && item.errorMessage}
                  </div>
                </FormItem>
              )
            })}
          </div>
          <AvatarContainer>
            <div
              css={css`
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1.5px solid ${colors.gray200};
                border-radius: 200px;
                backgroundcolor: ${colors.gray500};
              `}
            >
              <Avatar
                css={css`
                  width: 96px !important;
                  height: 96px !important;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                `}
                src={avatarSrc}
                size='lg'
              />
            </div>
            <DropInput maxCount={1} onUpload={onUpload} onlyIcon />
          </AvatarContainer>
        </ImageContainer>
        {fields2(t).map((item) => {
          if (item.type === 'input') {
            return (
              <FormItem label={item.label}>
                <Controller
                  name={item.name}
                  control={control}
                  rules={item.rules}
                  defaultValue={item.defaultValue}
                  render={({ field }) => (
                    <TextInput disabled={item.name === 'email' && mode === 'edit'} {...field} />
                  )}
                />
                <div css={errorTextColor}>
                  {errors[item.name] && touchedFields[item.name] && item.errorMessage}
                </div>
              </FormItem>
            )
          }
          return (
            <FormItem label={item.label}>
              <Controller
                name={item.name}
                control={control}
                rules={item.rules}
                defaultValue={item.defaultValue}
                render={({ field }) => (
                  <SelectInput {...field}>
                    {item.selectOptions.map((option) => (
                      <SelectOption value={option.value}>{option.label}</SelectOption>
                    ))}
                  </SelectInput>
                )}
              />
              <div css={errorTextColor}>
                {errors[item.name] && touchedFields[item.name] && item.errorMessage}
              </div>
            </FormItem>
          )
        })}
      </StyledContainer>
    </Form>
  )
}

export default FormManager
