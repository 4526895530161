import { useTranslation as translation, UseTranslationOptions } from 'react-i18next'
import { TxKeyPath } from '@i18n'

export const useTranslation = function () {
  const { t } = translation<TxKeyPath>()

  const translate = (key: TxKeyPath, options?: UseTranslationOptions<any>) => {
    return t(key, options)
  }

  return { translate }
}
