import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import en, { Translations } from './en'
import fr from './fr'
import * as certification from './certification'
import * as certificationCreation from './certification-creation'
import * as certifications from './certifications'
import * as segments from './segments'
import * as display from './display'
import * as form from './form'
import * as model from './model'
import * as segment from './segment'
import * as contributors from './contributors'
import * as createSegment from './create-segment'
import * as contributor from './contributor'
import * as workflows from './workflows'
import * as managers from './managers'
import * as createUser from './create-user'
import * as updateUser from './update-user'
import * as project from './project'
import * as common from './common'
import * as risk from './risk'
import * as analysis from './analysis'

const resources = {
  en: {
    translation: {
      ...en,
      analysis: analysis.en,
      certificationPage: certification.en,
      certificationCreation: certificationCreation.en,
      certificationsPage: certifications.en,
      segments: segments.en,
      display: display.en,
      form: form.en,
      model: model.en,
      segment: segment.en,
      project: project.en,
      contributors: contributors.en,
      createSegment: createSegment.en,
      contributor: contributor.en,
      worklfows: workflows.en,
      managers: managers.en,
      createUser: createUser.en,
      updateUser: updateUser.en,
      common: common.en,
      risk: risk.en,
    },
  },
  fr: {
    translation: {
      ...fr,
      analysis: analysis.fr,
      certificationPage: certification.fr,
      certificationCreation: certificationCreation.fr,
      certificationsPage: certifications.fr,
      segments: segments.fr,
      display: display.fr,
      form: form.fr,
      model: model.fr,
      segment: segment.fr,
      project: project.fr,
      contributors: contributors.fr,
      createSegment: createSegment.fr,
      contributor: contributor.fr,
      worklfows: workflows.fr,
      managers: managers.fr,
      createUser: createUser.fr,
      updateUser: updateUser.fr,
      common: common.fr,
      risk: risk.fr,
    },
  },
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en',

    // keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export type AllTranslations = Translations & {
  certificationPage: certification.CertificationTranslations
  certificationCreation: certificationCreation.CertificationCreationTranslations
  certificationsPage: certifications.CertificationsTranslations
  segments: segments.SegmentsTranslations
  display: display.DisplayTranslations
  form: form.FormTranslations
  model: model.ModelTranslations
  segment: segment.SegmentTranslations
  project: project.ProjectDetailsTranslations
  contributors: contributors.Translations
  createSegment: createSegment.CreateSegmentTranslations
  contributor: contributor.Translations
  worklfows: workflows.WorkflowsTranslations
  managers: managers.Translations
  createUser: createUser.Translations
  updateUser: updateUser.Translations
  common: common.Translations
  risk: risk.Translations
  analysis: analysis.AnalysisTranslations
}

export type TxKeyPath = RecursiveKeyOf<AllTranslations>

// via: https://stackoverflow.com/a/65333050
type RecursiveKeyOf<TObj extends object> = {
  [TKey in keyof TObj & (string | number)]: RecursiveKeyOfHandleValue<TObj[TKey], `${TKey}`>
}[keyof TObj & (string | number)]

type RecursiveKeyOfInner<TObj extends object> = {
  [TKey in keyof TObj & (string | number)]: RecursiveKeyOfHandleValue<
    TObj[TKey],
    `['${TKey}']` | `.${TKey}`
  >
}[keyof TObj & (string | number)]

type RecursiveKeyOfHandleValue<TValue, Text extends string> = TValue extends any[]
  ? Text
  : TValue extends object
  ? Text | `${Text}${RecursiveKeyOfInner<TValue>}`
  : Text

export default i18n
