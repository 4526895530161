import React from 'react'
import { Analysis } from '@views/analysis'
import { getWirkConfig } from '@definitions'

const getUrlKibana = (
  dashboard: 'Global' | 'Variation' | 'Distribution' | 'Decomposition' | 'Tracking',
) => {
  try {
    return `${getWirkConfig('Kibana', 'Url')}/app/dashboards#/view/${getWirkConfig(
      'Kibana',
      dashboard,
    )}?embed=true&_g=(refreshInterval%3A(pause%3A!t%2Cvalue%3A60000)%2Ctime%3A(from%3Anow-15m%2Cto%3Anow))&show-time-filter=true`
  } catch (_) {
    return ''
  }
}

const AnalysisPage: React.FC = () => (
  <Analysis
    urlVariation={getUrlKibana('Variation')}
    urlGlobal={getUrlKibana('Global')}
    urlDecomposition={getUrlKibana('Decomposition')}
    urlDistribution={getUrlKibana('Distribution')}
    urlTracking={getUrlKibana('Tracking')}
  />
)

export default AnalysisPage
