const fr = {
  notifications: {
    success: {
      description: "L'utilisateur a été mis à jour avec succès.",
    },
    error: {
      description: "Une erreur est survenue lors de la mise à jour de l'utilisateur.",
    },
  },
}

export default fr
