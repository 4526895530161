/** @jsxImportSource @emotion/react */

import { useOutletContext, useParams } from 'react-router'
import { QuestionCertification } from '@views/certification'
import { Loader } from '@components/utils'
import { CertificationType } from '@pages/types'
import { useDelayedEffect } from '@hooks'

import { store } from '@/store'

const QuestionCertificationPage = () => {
  const { questionId: idQuestion } = useParams()
  const { certification } = useOutletContext<{ certification: CertificationType }>()

  const getQuestionCertificationAction = store.use.getQuestionCertificationAction().call
  const updateTrainingQuestionCertificationAction =
    store.use.updateTrainingQuestionCertificationAction().call

  const isLoading = store.use.getQuestionCertificationAction().status === 'LOADING'
  const isSucceeded = store.use.getQuestionCertificationAction().status === 'SUCCEEDED'

  const question = store.use.questionsFiltered()[0]

  useDelayedEffect(() => {
    getQuestionCertificationAction({ idQuestion, idCertification: certification.idCertification })
  }, [certification.idCertification, idQuestion])

  const onQuestionTrainingStatusChange = async (training: boolean) => {
    updateTrainingQuestionCertificationAction({
      idCertification: question.idCertification,
      idQuestion: question.idCertificationQuestion,
      training,
    })
  }

  return (
    <>
      {isLoading && <Loader />}
      {isSucceeded && (
        <QuestionCertification
          certification={certification}
          question={question}
          onQuestionTrainingStatusUpdate={onQuestionTrainingStatusChange}
        />
      )}
    </>
  )
}

export default QuestionCertificationPage
