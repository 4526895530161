import { VerticalFormItem } from '@components/display'
import { useTranslation } from '@hooks/useTranslation'
import { useEffect, useState } from 'react'
import { Validations } from '@definitions/validations'
import { CardFormSelected } from '@views/shared/form'
import { getForm } from '@api/form'
import { FormType } from '@pages/types'
import { Title, TextInput, Text, colors, FormElements, Space } from 'common-components'

import { formApiMapper } from '@/mappers'

type GeneralSettingsPanelPropsType = {
  name: string
  idForm: string
}

export const GeneralSettingsPanel = ({ name, idForm }: GeneralSettingsPanelPropsType) => {
  const [templateFrom, setTemplateFrom] = useState<FormType['template']['template']>()
  const { translate } = useTranslation()
  const form = FormElements.useFormInstance()

  useEffect(() => {
    getForm(idForm).then((formApi) => {
      const result = formApiMapper(formApi)?.template?.template
      setTemplateFrom(result)
    })
  }, [idForm])

  useEffect(() => {
    if (form && name) form.setFieldValue('name', name)
  }, [form, name])

  return (
    <Space
      direction='vertical'
      style={{
        width: '100%',
        border: `1px solid ${colors.gray200}`,
        borderRadius: 16,
        padding: 24,
      }}
    >
      <Title level={5}>{translate('projectCreatePage.collapse.generalSettings.title')}</Title>
      <VerticalFormItem
        label={translate('project.settings.labels.name')}
        name='name'
        rules={[{ required: true, message: Validations.message.required }]}
      >
        <TextInput />
      </VerticalFormItem>
      {!!templateFrom && (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 6, marginTop: 20 }}>
          <Text size='sm' strong color={colors.gray700}>
            {translate('project.settings.labels.form')}
          </Text>
          <CardFormSelected templateFrom={templateFrom} />
        </div>
      )}
    </Space>
  )
}

export default GeneralSettingsPanel
