const fr = {
  draftLabel: 'Brouillon',
  formDraftsListTitle: 'Mes brouillons',
  formListTitle: 'Tous les formulaires',
  formTitle: 'Formulaire',
  formSubtitle: 'Choisissez un formulaire',
  chooseFormButton: 'Choisir dans la bibliothèque',
  addButton: 'Ajouter un formulaire',
}

export default fr
