import { FormApiType, FormShortApiType } from '@api/types'
import { FormShortType, FormType } from '@pages/types'
import { dateTime } from '@utils'

import { removeNullAndUndefinedMapper } from './helper.mapper'

export const formShortApiMapper = (formShortApi: FormShortApiType): FormShortType => {
  try {
    const result: FormShortType = {
      idForm: formShortApi?.idForm,
      state: 20, // published
      columns: formShortApi?.columns,
      name: formShortApi?.name,
      // TODO not remove !
      // quality: formShortApi?.quality,
      // averageDuration: formShortApi?.averageDuration,
      // minimumDuration: formShortApi?.minimumDuration,
      // keySkill: formShortApi?.keySkill,
      // hasSubdivide: formShortApi?.hasSubdivide,
      // hasReview: formShortApi?.hasReview,
      // subdivide: formShortApi?.subdivide,
      // allowError: formShortApi?.allowError,
      // prefillInputs: formShortApi?.prefillInputs,
      // fields: formShortApi?.fields,
      // nodes: formShortApi?.nodes,
      // hideIfRules: formShortApi?.hideIfRules,
      // fieldAssociation: formShortApi?.fieldAssociation,
      // ignoredFields: formShortApi?.ignoredFields,
      // validationRules: formShortApi?.validationRules,
      // iterationOverload: formShortApi?.iterationOverload,
      // comparisonRules: formShortApi?.comparisonRules,
    }

    return removeNullAndUndefinedMapper<FormShortType>(result)
  } catch (e) {
    throw e
  }
}

export const formApiMapper = (formApi: FormApiType): FormType => {
  try {
    const result: FormType = {
      columns: formApi.columns,
      idForm: formApi.idForm,
      idUser: formApi.idUser,
      name: formApi.name,
      skill: formApi.skill,
      state: formApi.state,
      quality: formApi?.quality,
      isBookmarked: formApi.isBookmarked,
      hasReview: formApi.hasReview,
      timePerTask: formApi.timePerTask,
      nbReviewers: formApi.nbReviewers,
      nbMinSUppliers: formApi.nbMinSUppliers,
      sampleSize: formApi.sampleSize,
      samplelized: formApi.samplelized,
      fields: formApi.fields,
      nodes: formApi.nodes,
      hideIfRules: formApi.hideIfRules,
      fieldAssociation: formApi.fieldAssociation,
      ignoredFields: formApi.ignoredFields,
      validationRules: formApi.validationRules,
      iterationOverload: formApi.iterationOverload,
      comparisonRules: formApi.comparisonRules,
      creationDate: dateTime(formApi.creationDate),
      updateDate: dateTime(formApi.updateDate),
      template: formApi.template,
    }

    return removeNullAndUndefinedMapper<FormType>(result)
  } catch (e) {
    throw e
  }
}
