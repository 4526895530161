const fr = {
  listHeaderTitle: 'Certifications',
  listHeaderSubtitle: 'Create certifications to test contributors',
  createButtonLabel: 'Create certification',
  filtersButtonLabel: 'Filters',
  notifications: {
    requestAccessSuccess: 'Request for accessing the certification has been sent!',
    requestAccessError: 'Request for accessing the certification failed',
  },
  table: {
    accessLevel: 'Access level',
    name: 'Name',
    participantsNumber: 'Number of participants',
    timeAverage: 'Average time',
    scoreAverage: 'Average score',
    state: 'Status',
    successRate: 'Success rate',
  },
}

export default fr
