/** @jsxImportSource @emotion/react */

import { useState } from 'react'
import { PresetTagPropsType, PresetTag } from '@components/display'
import { useTranslation } from '@hooks'
import { AllTranslations } from '@i18n'
import { Button, Checkbox, CheckboxGroup, ICheckboxGroupProps, Space } from 'common-components'

type StateCheckboxPropsType = PresetTagPropsType & {
  values: Record<number, string>
  labelTxKey: keyof AllTranslations['common']['states']
  inputProps?: ICheckboxGroupProps
  itemsToShow?: number
  initRenderAll?: boolean
}
const StateCheckbox = ({
  values,
  labelTxKey,
  inputProps = {},
  itemsToShow = 4,
  initRenderAll,
  ...rest
}: StateCheckboxPropsType) => {
  const { translate } = useTranslation()
  type StateVal = keyof AllTranslations['common']['states'][`${typeof labelTxKey}`]

  const [isShownAll, setShownAll] = useState(initRenderAll)

  const keysArray = Object.keys(values) || []

  return (
    <CheckboxGroup {...inputProps}>
      <Space size={10} direction={'vertical'}>
        {keysArray.slice(0, isShownAll ? keysArray.length : itemsToShow).map((stateStr) => {
          const state = Number(stateStr) as StateVal
          return (
            <Space key={stateStr}>
              <Checkbox key={state} value={state} />
              <PresetTag state={state} {...rest}>
                {translate(`common.states.${labelTxKey}.${state}`)}
              </PresetTag>
            </Space>
          )
        })}
        {keysArray?.length > itemsToShow && (
          <Button type={'link'} onClick={() => setShownAll(!isShownAll)}>
            {translate(isShownAll ? 'common.buttons.showLess' : 'common.buttons.showMore')}
          </Button>
        )}
      </Space>
    </CheckboxGroup>
  )
}

export default StateCheckbox
