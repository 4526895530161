/** @jsxImportSource @emotion/react */

import { CollapseWithPill } from '@components/display'
import { useTranslation } from '@hooks/useTranslation'
import { useState, useEffect } from 'react'
import { ProjectType } from '@pages/types'
import { AssignManager } from '@views/shared/manager'
import { FormElements, Space } from 'common-components'

type ParametersCollapsePropsType = {
  page: 'create' | 'settings'
  diversification: number
  nbMinEvaluation: number
  expectedQualityMin: number
  managersProject?: ProjectType['managers']
  setManagersProject?: (managers: ProjectType['managers']) => void
}

const ParametersCollapse = ({
  page,
  diversification,
  nbMinEvaluation,
  expectedQualityMin,
  managersProject,
  setManagersProject,
}: ParametersCollapsePropsType) => {
  const { translate } = useTranslation()
  const form = FormElements.useFormInstance()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setIsDiversificationOpen] = useState<boolean>(false)

  useEffect(() => {
    if (form) {
      if (diversification) form.setFieldValue('diversification', diversification)
      if (nbMinEvaluation) form.setFieldValue('nbMinEvaluation', nbMinEvaluation)
      if (expectedQualityMin) form.setFieldValue('expectedQualityMin', expectedQualityMin)
    }
    if (diversification || nbMinEvaluation || expectedQualityMin) setIsDiversificationOpen(true)
  }, [form, diversification, nbMinEvaluation, expectedQualityMin])

  // const DiversificationCollapseContent = useMemo(
  // 	() => (
  // 		<Row gutter={16} style={{ padding: "0 24px 24px" }}>
  // 			<Col
  // 				span={page === "settings" ? 8 : 24}
  // 				style={{
  // 					marginBottom: page === "create" && 16,
  // 				}}
  // 			>
  // 				<VerticalFormItem
  // 					name="diversification"
  // 					rules={[{ required: true, message: Validations.message.required }]}
  // 					label={translate(
  // 						"projectCreatePage.collapse.diversification.form.diversification",
  // 					)}
  // 				>
  // 					<TextInput type="number" min={0}  />
  // 				</VerticalFormItem>
  // 			</Col>
  // 			<Col span={page === "settings" ? 8 : 12}>
  // 				<VerticalFormItem
  // 					name="expectedQualityMin"
  // 					rules={[{ required: true, message: Validations.message.required }]}
  // 					label={translate(
  // 						"projectCreatePage.collapse.diversification.form.minQualityLevel",
  // 					)}
  // 				>
  // 					<TextInput type="number" min={0}  />
  // 				</VerticalFormItem>
  // 			</Col>
  // 			<Col span={page === "settings" ? 8 : 12}>
  // 				<VerticalFormItem
  // 					name="nbMinEvaluation"
  // 					rules={[{ required: true, message: Validations.message.required }]}
  // 					label={translate(
  // 						"projectCreatePage.collapse.diversification.form.numContributions",
  // 					)}
  // 				>
  // 					<TextInput type="number" min={0} />
  // 				</VerticalFormItem>
  // 			</Col>
  // 		</Row>
  // 	),
  // 	[],
  // );
  return (
    <CollapseWithPill
      panels={[
        /* HIDE
				 * {
					title: translate("projectCreatePage.collapse.parameters.title"),
					subtitle: translate("projectCreatePage.collapse.parameters.subtitle"),
					isOpen: page === "create" ? isDiversificationOpen : true,
					setIsOpen: setIsDiversificationOpen,
					content: DiversificationCollapseContent,
					withPill: page === "create",
				},*/
        ...(page === 'create'
          ? [
              {
                title: translate('projectCreatePage.collapse.managers.title'),
                titleLevel: 6 as 1 | 2 | 3 | 4 | 5 | 6,
                withPill: false,
                isOpen: true,
                content: (
                  <Space style={{ padding: '0 24px 24px' }}>
                    <AssignManager
                      managersUser={managersProject}
                      onUpdateManagers={(managersProject) => setManagersProject(managersProject)}
                    />
                  </Space>
                ),
              },
            ]
          : []),
      ]}
    />
  )
}

export default ParametersCollapse
