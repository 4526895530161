import { LoadingOutlined } from '@ant-design/icons'
import { useTranslation } from '@hooks/useTranslation'
import { ActionModal, colors, Text } from 'common-components'

type ConfirmProjectModalPropsType = {
  isOpen: boolean
  isLoading: boolean
  onConfirm: () => void
  closeModal: () => void
  state: 'archive' | 'stop'
}

const ConfirmProjectModal = ({
  isOpen,
  onConfirm,
  closeModal,
  isLoading,
  state,
}: ConfirmProjectModalPropsType) => {
  const { translate } = useTranslation()

  return (
    <ActionModal
      open={isOpen}
      // width={400}
      title={translate(`projectsPage.modal.${state}.title`)}
      icon={state === 'archive' ? 'Inbox01' : 'StopCircle'}
      iconColor={state === 'archive' ? colors.warning600 : colors.error600}
      // TODO: should be 2 circles with 2 colors
      iconBgColor={state === 'archive' ? colors.warning100 : colors.error100}
      onConfirm={() => closeModal()}
      onCancel={() => closeModal()}
      actionButtons={[
        {
          text: translate(`projectsPage.modal.${state}.cancelButton`),
          onClick: () => closeModal(),
        },
        {
          type: 'primary',
          disabled: isLoading,
          text: isLoading ? (
            <LoadingOutlined />
          ) : (
            translate(`projectsPage.modal.${state}.submitButton`)
          ),
          onClick: () => onConfirm(),
        },
      ]}
    >
      <Text>{translate(`projectsPage.modal.${state}.subtitle`)}</Text>
    </ActionModal>
  )
}

export default ConfirmProjectModal
