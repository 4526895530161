/** @jsxImportSource @emotion/react */

import { useRef, useState } from 'react'
import { useTranslation } from '@hooks'
import { CreateUserPayloadType, GetSuppliersPayloadType } from '@api/user'
import { ContributorType } from '@pages/types'
import { Loader } from '@components/utils'
import { DeleteModal, ImportFilesModal } from '@components/modal'
import { AssignSegmentModal } from '@views/shared/segment'
import { TableContributors, TableContributorsPropsType } from '@views/shared/contributor'
import { Content, useDisclosure, Button } from 'common-components'

import { CreateContributorModal } from './create'
import HeaderContributors from './HeaderContributors'
import FiltersContributors from './FiltersContributors'

import { store } from '@/store'

export type CreateUserDataType = {
  createUser: CreateUserPayloadType
  file: File
}

type ListContributorPropsType = Pick<TableContributorsPropsType, 'tableProps'> & {
  contributors: ContributorType[]
  contributorsIsLoading: boolean
  onParamsChange: (params: GetSuppliersPayloadType) => void
}

const ListContributor = ({
  contributors,
  onParamsChange,
  contributorsIsLoading,
  tableProps,
}: ListContributorPropsType) => {
  const deleteSegmentFromContributorsAction = store.use.deleteSegmentFromContributorsAction().call
  const addSegmentToContributorsAction = store.use.addSegmentToContributorsAction().call
  const uploadContributorsFileAction = store.use.uploadContributorsFileAction().call
  const deleteContributorsAction = store.use.deleteContributorsAction().call
  const createContributorAction = store.use.createContributorAction().call
  const downloadContributorsAction = store.use.downloadContributorsAction().call
  const downloadContributorsSampleFileAction = store.use.downloadContributorsSampleFileAction().call

  const isLoadingDeleteContributorsAction =
    store.use.deleteContributorsAction().status === 'LOADING'
  const isLoadingDownloadContributors = store.use.downloadContributorsAction().status === 'LOADING'
  const isLoadingCreateContributor = store.use.createContributorAction().status === 'LOADING'
  const isLoadingUploadContributorsFileAction =
    store.use.uploadContributorsFileAction().status === 'LOADING'
  const isLoadingAddSegmentToContributorsAction =
    store.use.addSegmentToContributorsAction().status === 'LOADING'
  const isLoadingDeleteSegmentFromContributorsAction =
    store.use.deleteSegmentFromContributorsAction().status === 'LOADING'

  const useDisclosureDeleteContributorsModal = useDisclosure()
  const useDisclosureAddContributorStepsModal = useDisclosure()
  const useDisclosureAssignSegmentsModal = useDisclosure()
  const useDisclosureImportContributorsModal = useDisclosure()

  const [idContributorsSelected, setIdContributorsSelected] = useState<string[]>([])
  const [isFiltersVisible, setFiltersVisible] = useState(false)
  const { translate } = useTranslation()

  const idNewContributor = useRef<string>()

  const onDeleteUsersMutation = async () => {
    deleteContributorsAction({ idContributors: idContributorsSelected }, () => {
      setIdContributorsSelected([])
      setTimeout(() => useDisclosureDeleteContributorsModal.close(), 200)
    })
  }

  const onCreateContributor = async (
    contributorForm: CreateUserDataType,
    onStepChange: () => void,
  ) => {
    createContributorAction(
      {
        ...contributorForm.createUser,
        file: contributorForm?.file,
      },
      (idContributorCreated) => {
        idNewContributor.current = idContributorCreated
        onStepChange()
      },
    )
  }

  return (
    <Content>
      <HeaderContributors countContributorsSelected={idContributorsSelected.length}>
        {/*** The actions on a single contributor ***/}
        {!idContributorsSelected.length && (
          <>
            <Button
              disabled={isLoadingDownloadContributors}
              onClick={async () =>
                downloadContributorsAction({
                  filters: {
                    model: {
                      segments: [],
                    },
                  },
                })
              }
              iconLeft='Download01'
            >
              {translate('managers.export')}
            </Button>
            <Button
              key='button-import-contiributors'
              onClick={useDisclosureImportContributorsModal.open}
              iconLeft='Upload01'
            >
              {translate('managers.import')}
            </Button>
            <Button onClick={() => setFiltersVisible(true)} iconLeft='FilterLines'>
              {translate('managers.filter')}
            </Button>
            <Button
              key='button-add-contiributor'
              type='primary'
              onClick={useDisclosureAddContributorStepsModal.open}
              iconLeft='UsersPlus'
            >
              {translate('contributors.addContributor')}
            </Button>
          </>
        )}
        {/*** The actions on multiple contributors ***/}
        {!!idContributorsSelected.length && (
          <>
            <Button
              type='danger'
              onClick={useDisclosureDeleteContributorsModal.open}
              iconLeft='Trash01'
            >
              {translate('managers.delete')}
            </Button>
            <Button onClick={useDisclosureAssignSegmentsModal.open} iconLeft='Target02'>
              {translate('managers.assignSegments')}
            </Button>
          </>
        )}
      </HeaderContributors>

      {contributorsIsLoading && <Loader />}
      {!contributorsIsLoading && (
        <TableContributors
          additionalColumnsTable={['segments', 'yearsOfExperience']}
          contributors={contributors}
          isLoadingSegmentMutation={
            isLoadingAddSegmentToContributorsAction || isLoadingDeleteSegmentFromContributorsAction
          }
          onAddSegment={(segmentShort, idContributor) => {
            addSegmentToContributorsAction({
              segmentShort,
              idContributors: [idContributor],
            })
          }}
          onDeleteSegment={(idSegment, idContributor) => {
            deleteSegmentFromContributorsAction({
              idSegment,
              idContributors: [idContributor],
            })
          }}
          selectedCheckboxes={idContributorsSelected}
          setSelectedCheckboxes={setIdContributorsSelected}
          tableProps={tableProps}
        />
      )}

      {/*Add Contributor Steps*/}
      {useDisclosureAddContributorStepsModal.isOpen && (
        <CreateContributorModal
          isOpen={useDisclosureAddContributorStepsModal.isOpen}
          close={useDisclosureAddContributorStepsModal.close}
          onCreateContributor={onCreateContributor}
          isLoading={
            isLoadingCreateContributor ||
            isLoadingAddSegmentToContributorsAction ||
            isLoadingDeleteSegmentFromContributorsAction
          }
          onAddSegment={(segmentShort) => {
            addSegmentToContributorsAction({
              segmentShort,
              idContributors: [idNewContributor.current],
            })
          }}
          onDeleteSegment={async (idSegment) => {
            deleteSegmentFromContributorsAction({
              idSegment,
              idContributors: [idNewContributor.current],
            })
          }}
        />
      )}

      {/*Assign Segments to contributors*/}
      {useDisclosureAssignSegmentsModal.isOpen && (
        <AssignSegmentModal
          contributorsCount={idContributorsSelected.length}
          close={useDisclosureAssignSegmentsModal.close}
          isOpen={useDisclosureAssignSegmentsModal.isOpen}
          isLoading={
            isLoadingAddSegmentToContributorsAction || isLoadingDeleteSegmentFromContributorsAction
          }
          onDeleteSegment={async (idSegment) => {
            deleteSegmentFromContributorsAction({
              idSegment,
              idContributors: idContributorsSelected,
            })
          }}
          onAddSegment={(segmentShort) => {
            addSegmentToContributorsAction({
              segmentShort,
              idContributors: idContributorsSelected,
            })
          }}
        />
      )}

      {/*delete contributor*/}
      {useDisclosureDeleteContributorsModal.isOpen && (
        <DeleteModal
          isLoading={isLoadingDeleteContributorsAction}
          close={useDisclosureDeleteContributorsModal.close}
          isOpen={useDisclosureDeleteContributorsModal.isOpen}
          title={`${translate('managers.deleteUser1')} ${idContributorsSelected.length} ${
            idContributorsSelected.length === 1
              ? translate('managers.deleteUser2')
              : translate('managers.deleteUser3')
          }
              `}
          label={translate(`contributor.delete.modal.label`)}
          onDeleteMutation={onDeleteUsersMutation}
        />
      )}

      {/*Filter*/}
      <FiltersContributors
        visible={isFiltersVisible}
        onParamsChange={onParamsChange}
        onClose={() => setFiltersVisible(false)}
      />

      {/*Import File*/}
      {useDisclosureImportContributorsModal.isOpen && (
        <ImportFilesModal
          useDisclosure={useDisclosureImportContributorsModal}
          isLoading={isLoadingUploadContributorsFileAction}
          onFileUpload={(file) =>
            uploadContributorsFileAction({ file }, () =>
              useDisclosureImportContributorsModal.close(),
            )
          }
          downloadSampleFile={async () => downloadContributorsSampleFileAction()}
        />
      )}
    </Content>
  )
}

export default ListContributor
