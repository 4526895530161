import { useContext, useEffect, useRef } from 'react'
import { RiskItemType } from '@pages/types'

import { RiskContext } from '@/context'

type RiskDocumentPreviewPropsType = {
  imgSrc: string
  risks: RiskItemType[]
}

const DocumentPreviewRisk = ({ imgSrc, risks }: RiskDocumentPreviewPropsType) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)

  const { disabledColors, currentPage } = useContext(RiskContext)

  const drawRectangle = () => {
    const canvas = canvasRef.current
    const ctx = canvas?.getContext('2d')

    if (!ctx) return
    ctx.lineWidth = 1
    // Max width and height based on figma design
    const maxWidth = 1308
    const maxHeight = 1728

    // Load image
    const image = new Image()
    image.src = imgSrc

    image.onload = () => {
      // Keep the same aspect ratio
      const scalingFactor = Math.min(maxWidth / image.width, maxHeight / image.height)
      canvas.width = image.width * scalingFactor
      canvas.height = image.height * scalingFactor

      ctx.drawImage(image, 0, 0, canvas.width, canvas.height)

      const borderWidth = 6

      risks.forEach((risk) => {
        risk.colors?.some((record) => {
          record.visualisations?.some((visualization) => {
            if (visualization.pageNumber !== currentPage + 1) return false
            if (disabledColors.includes(record.color)) return false

            const color = record.color + '50' //opacity for hex
            const x = visualization.x1 * scalingFactor - borderWidth / 2
            const y = visualization.y1 * scalingFactor - borderWidth / 2
            const width = visualization.width * scalingFactor + borderWidth
            const height = visualization.height * scalingFactor + borderWidth

            ctx.fillStyle = color
            ctx.fillRect(x, y, width, height)
            return true
          })
        })
      })
    }
  }

  useEffect(() => {
    drawRectangle()
  }, [canvasRef, imgSrc, disabledColors, currentPage])

  return (
    <div
      style={{
        margin: '48px auto',
        position: 'relative',
      }}
    >
      <canvas
        style={{
          borderRadius: 20,
          boxShadow: '0 8px 12px -8px #101828',
        }}
        ref={canvasRef}
      />
    </div>
  )
}

export default DocumentPreviewRisk
