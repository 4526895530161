/** @jsxImportSource @emotion/react */

import {
  Button,
  Content,
  ITableProps,
  Text,
  Row,
  Space,
  Table,
  colors,
  AvatarIcons,
  AvatarIcon,
  Title,
  WithModal,
  Icon,
} from 'common-components'
import styled from '@emotion/styled'
import { ColumnsType } from 'antd/lib/table'
import { routes } from '@navigation/routes'
import { useNavigate } from 'react-router'
import { UserAccessLevel } from '@definitions'
import { PresetTag } from '@components/display'
import { useTranslation } from '@hooks'
import { getStateKeyByValue } from '@utils'
import { CertificationType } from '@pages/types'
import { useMemo } from 'react'

const ModalIconWrapper = styled.div`
  background: #d1fadf;
  border: 8px solid #ecfdf3;
  border-radius: 28px;
  max-width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
`

type TableCertificationsPropsType = {
  certifications: CertificationType[]
  tableProps?: Omit<ITableProps<CertificationType>, 'data' | 'columns'>
  onAccessRequest?: (certificationId: string) => void
}

const TableCertifications = ({
  certifications,
  onAccessRequest,
  tableProps = {},
}: TableCertificationsPropsType) => {
  const navigate = useNavigate()
  const { translate } = useTranslation()

  const tableColumns: ColumnsType<CertificationType> = useMemo(() => {
    try {
      return [
        {
          title: translate('certificationsPage.table.name'),
          key: 'name',
          width: 580,
          sorter: (a, b) => {
            if (a.name < b.name) return -1
            if (a.name > b.name) return 1
            return 0
          },
          render: (record: CertificationType) => (
            <Row align={'middle'}>
              <Space size={'middle'} style={{ gap: 12 }}>
                <AvatarIcon
                  icon={record.icon?.iconName as AvatarIcons}
                  style={{ backgroundColor: record.icon?.iconColor }}
                  size={'sm'}
                />
                <Text size='sm' strong style={{ color: colors.gray900 }}>
                  {record.name}
                </Text>
              </Space>
            </Row>
          ),
        },
        {
          title: translate('certificationsPage.table.accessLevel'),
          key: 'role',
          width: 127,
          sorter: (a, b) => {
            if (a.role < b.role) return -1
            if (a.role > b.role) return 1
            return 0
          },
          render: (record: CertificationType) => {
            if (!record?.role || UserAccessLevel[record.role] === 'noAccess') {
              const WrappedButton: any = WithModal(
                Button,
                <Content>
                  <Title preset='tablePageTitle'>{translate('segments.requestModal.title')}</Title>
                  <Title preset='tablePageSubtitle'>
                    {translate('segments.requestModal.description')}
                  </Title>
                </Content>,
              )
              return (
                <Row align={'middle'}>
                  <WrappedButton
                    title={
                      <ModalIconWrapper>
                        <Icon width={24} height={24} icon='Key02' />
                      </ModalIconWrapper>
                    }
                    onConfirm={() => {
                      onAccessRequest && onAccessRequest(record.idCertification)
                    }}
                    id='modal'
                    size={'inline'}
                    okText={translate('segments.requestModal.ok')}
                    cancelText={translate('segments.requestModal.cancel')}
                  >
                    <div id='modal'>{translate('segments.request')}</div>
                  </WrappedButton>
                </Row>
              )
            }
            return (
              <Text size='xs' style={{ textTransform: 'capitalize' }}>
                {translate(`common.states.userAccessLevel.${record.role}`)}
              </Text>
            )
          },
        },
        /* HIDE
		{
			title: translate("certificationsPage.table.participantsNumber"),
			key: "nbUsers",
			dataIndex: "nbUsers",
			width: 119,
			sorter: (a, b) => a.nbUsers - b.nbUsers,
			render: (nbUsers) => (
				<Row align={"middle"}>
					<Text size="xs">{nbUsers}</Text>
				</Row>
			),
		},
		{
			title: translate("certificationsPage.table.timeAverage"),
			key: "averageDuration",
			dataIndex: "averageDuration",
			width: 130,
			sorter: (a, b) => {
				if (a.averageDuration < b.averageDuration) return -1;
				if (a.averageDuration > b.averageDuration) return 1;
				return 0;
			},
			render: (averageDuration) => (
				<Row align={"middle"}>
					<Text size="xs">{averageDuration}</Text>
				</Row>
			),
		},
		{
			title: translate("certificationsPage.table.scoreAverage"),
			key: "averageMark",
			dataIndex: "averageMark",
			width: 134,
			sorter: (a, b) => a.averageMark - b.averageMark,
			render: (averageMark) => (
				<Row align={"middle"}>
					<Text size="xs">{averageMark}%</Text>
				</Row>
			),
		},*/
        {
          title: translate('certificationsPage.table.state'),
          key: 'state',
          width: 126,
          sorter: (a, b) => {
            if (a.state < b.state) return -1
            if (a.state > b.state) return 1
            return 0
          },
          render: (record: CertificationType) => (
            <Row align={'middle'}>
              <PresetTag preset={'certification-state'} state={record.state} withDot>
                {translate(`common.states.certificationState.${record.state}`)}
              </PresetTag>
            </Row>
          ),
        },
      ]
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
      return []
    }
  }, [])
  const onRowClick = (certification: CertificationType) => {
    const { idCertification, role, state } = certification || {}

    if (!role || UserAccessLevel[role] === 'noAccess') return

    const resolveNextPage = () => {
      switch (state) {
        case 1:
        case 2:
        case 3:
          return routes.certificationCreation.navigate(idCertification, String(state))
        default:
          return routes.certificationQuestions.navigate(idCertification)
      }
    }
    navigate(resolveNextPage())
  }

  const addDisabledClass = (record: CertificationType) => {
    return record.role === getStateKeyByValue(UserAccessLevel, 'noAccess') ? 'disabled' : ''
  }

  return (
    <Content>
      <Table<CertificationType>
        data={certifications}
        columns={tableColumns}
        rowClassName={addDisabledClass}
        onRow={(record) => ({
          onClick: () => onRowClick(record),
        })}
        {...tableProps}
      />
    </Content>
  )
}

export default TableCertifications
