/** @jsxImportSource @emotion/react */

import { IColumn } from '@types'
import React, { memo, useMemo, useState } from 'react'
import { Badge, Button, colors, IColProps, Row, Tag } from 'common-components'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

type TAllowedInputs = ('text' | 'file')[]

const allowedFileInputFieldTypes = ['data_embed', 'data_video', 'data_audio', 'data_image']

type HeaderTableInputPropsType = {
  CellComponent: React.FC<IColProps>
  template: IColumn
  onUploadOpen?: () => void
}

const PreviewContainer = styled(Row)`
  align-items: center;
  justify-content: space-between;
`

const HeaderTableInput = memo(function ({
  template,
  CellComponent,
  onUploadOpen,
}: HeaderTableInputPropsType) {
  const [hovered, setHovered] = useState(false)
  const allowedInputs = useMemo(() => {
    //TODO: extend in the future or change to validation rules depending on template.type
    const allowed: TAllowedInputs = ['text']

    if (allowedFileInputFieldTypes.includes(template?.type)) allowed.push('file')

    return allowed
  }, [template])

  const onUploadClick = () => {
    onUploadOpen && onUploadOpen()
  }

  return (
    <CellComponent onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
      <PreviewContainer>
        <Tag css={$preview} color={colors.pink700}>
          {template?.name}
        </Tag>
        {hovered && allowedInputs.includes('file') && (
          <Button
            type={'text'}
            iconLeft={'UploadCloud02'}
            iconLeftProps={{ size: 20 }}
            onClick={onUploadClick}
          />
        )}
        {!hovered && (
          <Badge color={template?.hasError ? colors.error500 : colors.success500} count={1} dot>
            <div />
          </Badge>
        )}
      </PreviewContainer>
    </CellComponent>
  )
})

const $preview = css`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 85%;
`

export default HeaderTableInput
