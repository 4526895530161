/** @jsxImportSource @emotion/react */
import { CollapseWithPill, VerticalFormItem } from '@components/display'
import { css } from '@emotion/react'
import { useTranslation } from '@hooks/useTranslation'
import { useEffect, useMemo, useState } from 'react'
import { TSlaType } from '@definitions/states'
import dayjs from 'dayjs'
import { Validations } from '@definitions/validations'
import { ProjectSlaLeverageHourType } from '@pages/types'
import {
  Col,
  FormElements,
  FormItem,
  RadioElements,
  Row,
  SingleRadio,
  Space,
  Text,
  TimePicker,
} from 'common-components'

import LeverageHours from './LeverageHours'

type SlaCollapsePropsType = {
  type: TSlaType | null
  leverageHours: ProjectSlaLeverageHourType[] | null
  delay: string | null
}

const SlaCollapse = ({ type, leverageHours, delay }: SlaCollapsePropsType) => {
  const { translate } = useTranslation()
  const form = FormElements.useFormInstance()

  const [isProcessingTimeOpen, setIsProcessingTimeOpen] = useState<boolean>(false)
  const [slaType, setSlaType] = useState<number>()

  useEffect(() => {
    if (type) setIsProcessingTimeOpen(true)
  }, [type])

  const onRadioChange = (e: any) => {
    setSlaType(e.target.value as TSlaType)
    form.setFieldValue('slaType', e.target.value)
  }

  useEffect(() => {
    if (form) {
      if (!slaType) {
        form.setFieldValue('slaType', type)
        setSlaType(form.getFieldValue('slaType'))
      }
      if (slaType === 10 && leverageHours) {
        form.setFieldValue(
          'leverageHours',
          Object.values(leverageHours).map((additionalProp) => {
            return {
              leverageTime: dayjs(
                new Date().setHours(
                  +additionalProp.leverageTime.split(':')[0],
                  +additionalProp.leverageTime.split(':')[1],
                ),
              ),
              nbDays: additionalProp.nbDays,
              deleveringTime: dayjs(
                new Date().setHours(
                  +additionalProp.deleveringTime.split(':')[0],
                  +additionalProp.deleveringTime.split(':')[1],
                ),
              ),
            }
          }),
        )
      }
      if (slaType === 20 && delay) {
        form.setFieldValue(
          'delay',
          dayjs(new Date().setHours(+delay.split(':')[0], +delay.split(':')[1])),
        )
      }
    }
  }, [form, slaType, leverageHours, delay])

  const radioClassOverride = css`
    width: 100%;
    display: flex;
    .ant-radio-wrapper {
      flex: 1;
      display: flex;
    }
    .ant-radio {
      align-self: start;
    }
  `

  const ProcessingTimeCollapseContent = useMemo(
    () => (
      <Space direction='vertical' style={{ width: '100%', padding: '0 24px 24px', gap: 16 }}>
        <FormItem
          name='slaType'
          rules={[{ required: true, message: Validations.message.required }]}
          css={css`
            margin-bottom: 0;
          `}
        >
          <RadioElements.Group
            value={slaType}
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 16,
            }}
          >
            {/* HIDE <SingleRadio value={10} onChange={onRadioChange} css={radioClassOverride}>
							<Space direction="vertical">
								<Text size="sm" strong>
									{translate(
										"projectCreatePage.collapse.processingTime.checkbox.exerciseTime.title",
									)}
								</Text>
								<Text size="sm">
									{translate(
										"projectCreatePage.collapse.processingTime.checkbox.exerciseTime.subtitle",
									)}
								</Text>
							</Space>
						</SingleRadio>
						*/}
            {slaType === 10 && <LeverageHours />}
            <SingleRadio value={20} onChange={onRadioChange} css={radioClassOverride}>
              <Space direction='vertical'>
                <Text size='sm' strong>
                  {translate(
                    'projectCreatePage.collapse.processingTime.checkbox.averageTime.title',
                  )}
                </Text>
                <Text size='sm'>
                  {translate(
                    'projectCreatePage.collapse.processingTime.checkbox.averageTime.subtitle',
                  )}
                </Text>
              </Space>
            </SingleRadio>
            {slaType === 20 && (
              <Row>
                <Col span={7}>
                  <VerticalFormItem
                    name='delay'
                    rules={[{ required: true, message: Validations.message.required }]}
                    label={translate(
                      'projectCreatePage.collapse.processingTime.form.maxDeliveryTime',
                    )}
                  >
                    <TimePicker minuteStep={30} format='HH:mm' />
                  </VerticalFormItem>
                </Col>
              </Row>
            )}
          </RadioElements.Group>
        </FormItem>
      </Space>
    ),
    [slaType],
  )

  return (
    <CollapseWithPill
      panels={[
        {
          title: translate('projectCreatePage.collapse.SLA.title'),
          subtitle: translate('projectCreatePage.collapse.SLA.subtitle'),
          content: '',
          withPill: false,
        },
        {
          title: translate('projectCreatePage.collapse.processingTime.title'),
          titleLevel: 6,
          content: ProcessingTimeCollapseContent,
          isOpen: isProcessingTimeOpen,
          setIsOpen: setIsProcessingTimeOpen,
        },
      ]}
    />
  )
}

export default SlaCollapse
