/** @jsxImportSource @emotion/react */

import { useRef, useState } from 'react'
import { useTranslation } from '@hooks'
import { GetManagersPayloadType } from '@api/user'
import { ManagerType } from '@pages/types'
import { Loader } from '@components/utils'
import { DeleteModal, ImportFilesModal } from '@components/modal'
import { Content, useDisclosure, Button } from 'common-components'

import {
  CreateUpdateFormManagerType,
  TableManagers,
  TableManagersPropsType,
} from '../shared/manager'
import { AssignSegmentRightModal } from '../shared/segment'
import { AssignCertificationModal } from '../shared/certification'

import { CreateManagerModal } from './create'
import HeaderManagers from './HeaderManagers'
import { FiltersManagers } from './filters'

import { store } from '@/store'

type ListManagerPropsType = Pick<TableManagersPropsType, 'tableProps'> & {
  managers: ManagerType[]
  managersIsLoading: boolean
  onParamsChange: (params: GetManagersPayloadType) => void
}

const ListManager = ({
  managers,
  managersIsLoading,
  tableProps = {},
  onParamsChange,
}: ListManagerPropsType) => {
  const updateManagerAction = store.use.updateManagerAction().call
  const deleteCertificationFromManagersAction =
    store.use.deleteCertificationFromManagersAction().call
  const addCertificationToManagersAction = store.use.addCertificationToManagersAction().call
  const deleteSegmentFromManagersAction = store.use.deleteSegmentFromManagersAction().call
  const addSegmentToManagersAction = store.use.addSegmentToManagersAction().call
  const createManagerAction = store.use.createManagerAction().call
  const downloadManagersAction = store.use.downloadManagersAction().call
  const uploadManagersFileAction = store.use.uploadManagersFileAction().call
  const deleteManagersAction = store.use.deleteManagersAction().call
  const downloadManagersSampleFileAction = store.use.downloadManagersSampleFileAction().call

  const isLoadingUpdateManagerAction = store.use.updateManagerAction().status === 'LOADING'
  const isLoadingDeleteCertificationFromManagersAction =
    store.use.deleteCertificationFromManagersAction().status === 'LOADING'
  const isLoadingAddCertificationToManagersAction =
    store.use.addCertificationToManagersAction().status === 'LOADING'
  const isLoadingDeleteSegmentFromManagersAction =
    store.use.deleteSegmentFromManagersAction().status === 'LOADING'
  const isLoadingAddSegmentToManagersAction =
    store.use.addSegmentToManagersAction().status === 'LOADING'
  const isLoadingCreateManagerAction = store.use.createManagerAction().status === 'LOADING'
  const isLoadingDeleteManagersAction = store.use.deleteManagersAction().status === 'LOADING'
  const isLoadingUploadManagersFileAction =
    store.use.uploadManagersFileAction().status === 'LOADING'
  const isLoadingDownloadManagersAction = store.use.downloadManagersAction().status === 'LOADING'

  const idNewManager = useRef<string>()

  const useDisclosureAddManagerStepsModal = useDisclosure()
  const useDisclosureDeleteManagersModal = useDisclosure()
  const useDisclosureAssignSegmentsRightModal = useDisclosure()
  const useDisclosureAssignCertificationModal = useDisclosure()
  const useDisclosureImportManagersModal = useDisclosure()

  const { translate } = useTranslation()
  const [idManagersSelected, setIdManagersSelected] = useState<string[]>([])
  const [isFiltersVisible, setFiltersVisible] = useState(false)

  const onUpdateManager = async (managerForm: CreateUpdateFormManagerType) => {
    updateManagerAction({
      idUser: managerForm.createUser.idUser,
      firstName: managerForm.createUser.firstName,
      lastName: managerForm.createUser.lastName,
      type: managerForm.createUser.type,
      phone: managerForm.createUser.phone,
      file: managerForm?.file,
    })
  }

  const onCreateManager = async (
    managerForm: CreateUpdateFormManagerType,
    onSuccess: () => void,
  ) => {
    createManagerAction(
      {
        ...managerForm.createUser,
        file: managerForm?.file,
      },
      (idManagerCreated) => {
        idNewManager.current = idManagerCreated
        onSuccess()
      },
    )
  }

  return (
    <Content>
      <HeaderManagers managersSelectedCount={idManagersSelected.length}>
        {/*** The actions on a single contributor ***/}
        {!idManagersSelected.length && (
          <>
            <Button
              disabled={isLoadingDownloadManagersAction}
              onClick={async () =>
                downloadManagersAction({
                  filters: {
                    model: {
                      segments: [],
                    },
                  },
                })
              }
              iconLeft='Download01'
            >
              {translate('managers.export')}
            </Button>
            <Button
              key='button-import-managers'
              onClick={useDisclosureImportManagersModal.open}
              iconLeft='Upload01'
            >
              {translate('managers.import')}
            </Button>
            <Button onClick={() => setFiltersVisible(true)} iconLeft='FilterLines'>
              {translate('managers.filter')}
            </Button>
            <Button
              key='button-add-manager'
              type='primary'
              onClick={useDisclosureAddManagerStepsModal.open}
              iconLeft='ShieldPlus'
            >
              {translate('managers.addManager')}
            </Button>
          </>
        )}
        {/*** The actions on multiple contributors ***/}
        {!!idManagersSelected.length && (
          <>
            <Button onClick={useDisclosureAssignSegmentsRightModal.open} iconLeft='Target02'>
              {translate('managers.assignSegments')}
            </Button>
            <Button onClick={useDisclosureAssignCertificationModal.open} iconLeft='CheckVerified03'>
              {translate('managers.assignCertification')}
            </Button>
            <Button
              type='danger'
              onClick={useDisclosureDeleteManagersModal.open}
              iconLeft='Trash01'
            >
              {translate('managers.delete')}
            </Button>
          </>
        )}
      </HeaderManagers>
      {managersIsLoading && <Loader />}
      {!managersIsLoading && (
        <TableManagers
          additionalColumnsTable={[
            'yearsOfExperience',
            'segments',
            'certifications',
            'status',
            'action',
          ]}
          managers={managers}
          isLoadingSegmentsMutation={
            isLoadingAddSegmentToManagersAction || isLoadingDeleteSegmentFromManagersAction
          }
          isLoadingCertificationsMutation={
            isLoadingAddCertificationToManagersAction ||
            isLoadingDeleteCertificationFromManagersAction
          }
          onAddSegment={(segmentShort, idManager, managerRight) =>
            addSegmentToManagersAction({
              segmentShort,
              managerRight,
              idManagers: [idManager],
            })
          }
          onDeleteSegment={async (idSegment, idManager) => {
            deleteSegmentFromManagersAction({
              idManagers: [idManager],
              idSegment,
            })
          }}
          onAddCertification={(certificationShort, idManager, managerRight) =>
            addCertificationToManagersAction({
              managerRight,
              certificationShort,
              idManagers: [idManager],
            })
          }
          onDeleteCertification={async (idCertification, idManager) => {
            deleteCertificationFromManagersAction({
              idCertification,
              idManagers: [idManager],
            })
          }}
          isLoadingDeleteUserMutation={isLoadingDeleteManagersAction}
          onDeleteUserMutation={(idUser: string) => async () => {
            deleteManagersAction(
              {
                idManagers: [idUser],
              },
              () => {
                setTimeout(() => useDisclosureDeleteManagersModal.close(), 200)
              },
            )
          }}
          onUpdateManager={onUpdateManager}
          isLoadingUpdateManager={isLoadingUpdateManagerAction}
          setSelectedCheckboxes={setIdManagersSelected}
          selectedCheckboxes={idManagersSelected}
          tableProps={tableProps}
        />
      )}

      {useDisclosureAddManagerStepsModal.isOpen && (
        <CreateManagerModal
          isOpen={useDisclosureAddManagerStepsModal.isOpen}
          close={useDisclosureAddManagerStepsModal.close}
          onCreateManager={onCreateManager}
          isLoading={
            isLoadingCreateManagerAction ||
            isLoadingAddSegmentToManagersAction ||
            isLoadingDeleteSegmentFromManagersAction ||
            isLoadingAddCertificationToManagersAction ||
            isLoadingDeleteCertificationFromManagersAction
          }
          // SEGMENTS
          onAddSegment={(segmentShort, managerRight) => {
            addSegmentToManagersAction({
              segmentShort,
              managerRight,
              idManagers: [idNewManager.current],
            })
          }}
          onDeleteSegment={async (idSegment: string) => {
            deleteSegmentFromManagersAction({
              idManagers: [idNewManager.current],
              idSegment,
            })
          }}
          // CERTIFICATIONS
          onAddCertification={(certificationShort, managerRight) => {
            addCertificationToManagersAction({
              managerRight,
              certificationShort,
              idManagers: [idNewManager.current],
            })
          }}
          onDeleteCertification={(idCertification) => {
            deleteCertificationFromManagersAction({
              idCertification,
              idManagers: [idNewManager.current],
            })
          }}
        />
      )}

      {/*Delete Managers*/}
      {useDisclosureDeleteManagersModal.isOpen && (
        <DeleteModal
          close={useDisclosureDeleteManagersModal.close}
          isOpen={useDisclosureDeleteManagersModal.isOpen}
          title={`${translate('managers.deleteUser1')} ${idManagersSelected.length} ${
            idManagersSelected.length === 1
              ? translate('managers.deleteUser2')
              : translate('managers.deleteUser3')
          }
              `}
          label={translate(`contributor.delete.modal.label`)}
          isLoading={isLoadingDeleteManagersAction}
          onDeleteMutation={async () => {
            deleteManagersAction(
              {
                idManagers: idManagersSelected,
              },
              () => {
                setIdManagersSelected([])
                setTimeout(() => useDisclosureDeleteManagersModal.close(), 200)
              },
            )
          }}
        />
      )}

      {/*Assign Segments to managers*/}
      {useDisclosureAssignSegmentsRightModal.isOpen && (
        <AssignSegmentRightModal
          isLoading={
            isLoadingAddSegmentToManagersAction || isLoadingDeleteSegmentFromManagersAction
          }
          close={useDisclosureAssignSegmentsRightModal.close}
          isOpen={useDisclosureAssignSegmentsRightModal.isOpen}
          onAddSegment={(segmentShort, managerRight) => {
            addSegmentToManagersAction({
              segmentShort,
              managerRight,
              idManagers: idManagersSelected,
            })
          }}
          onDeleteSegment={(idSegment) => {
            deleteSegmentFromManagersAction({
              idManagers: idManagersSelected,
              idSegment,
            })
          }}
        />
      )}

      {/*Assign Certification to managers*/}
      {useDisclosureAssignCertificationModal.isOpen && (
        <AssignCertificationModal
          isLoading={
            isLoadingAddCertificationToManagersAction ||
            isLoadingDeleteCertificationFromManagersAction
          }
          managersCount={idManagersSelected.length}
          close={useDisclosureAssignCertificationModal.close}
          isOpen={useDisclosureAssignCertificationModal.isOpen}
          onAddCertification={(certificationShort, managerRight) =>
            addCertificationToManagersAction({
              managerRight,
              certificationShort,
              idManagers: idManagersSelected,
            })
          }
          onDeleteCertification={async (idCertification) =>
            deleteCertificationFromManagersAction({
              idCertification,
              idManagers: idManagersSelected,
            })
          }
        />
      )}

      {/*Filter*/}
      <FiltersManagers
        data={managers}
        visible={isFiltersVisible}
        onParamsChange={onParamsChange}
        onClose={() => setFiltersVisible(false)}
      />

      {/*Import File*/}
      {useDisclosureImportManagersModal.isOpen && (
        <ImportFilesModal
          useDisclosure={useDisclosureImportManagersModal}
          isLoading={isLoadingUploadManagersFileAction}
          onFileUpload={(file) =>
            uploadManagersFileAction({ file }, () => {
              useDisclosureImportManagersModal.close()
            })
          }
          downloadSampleFile={async () => downloadManagersSampleFileAction()}
        />
      )}
    </Content>
  )
}

export default ListManager
