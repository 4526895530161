const en = {
  title: {
    questions: 'Questions',
    respondents: 'Respondents',
  },
  createButtonLabel: 'Add question',
  deleteButtonLabel: 'Delete questions',
  exportButtonLabel: 'Export',
  filtersButtonLabel: 'Filter',
  publishButtonLabel: 'Publish',
  onlineButtonLabel: 'Put online',
  offlineButtonLabel: 'Take offline',
  archiveButtonLabel: 'Archive',
  respondentsHeaderTitle: 'Respondents',
  respondentHeaderTitle: 'Responses of {{name}}',
  questionHeaderTitle: 'Question',
  notifications: {
    trainingStatusChangeSuccess: 'Training status changed',
    trainingStatusChangeError: 'Training status failed to change',
  },
  questionsTable: {
    actions: 'Actions',
    questionId: 'ID Question',
    participantsNumber: 'Number of participants',
    timeAverage: 'Average time',
    training: 'For training?',
    state: 'Status',
    successRate: 'Success rate',
  },
  deleteQuestionModal: {
    title: 'Delete a certification question',
    description:
      'When you change a certification, it distorts the results of those who have already passed or failed it.',
    checkboxLabel: 'Allow those who have completed the certification to retake it.',
    deleteButtonLabel: 'Delete',
    cancelButtonLabel: 'Cancel',
  },
  deleteRespondentQuestionModal: {
    title: 'Delete Certification Passage',
    description:
      'You are about to delete this certification test. The wontributor will have the opportunity to repass it again later.',
  },
  respondentsTable: {
    actions: 'Actions',
    date: 'Date',
    respondent: 'Respondent',
    score: 'Score',
    state: 'Status',
    time: 'Time',
    type: 'Type',
  },
  respondentQuestionsTable: {
    id: 'ID Question',
    time: 'Time',
    score: 'Score',
  },
}

export default en
export type Translations = typeof en
