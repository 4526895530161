/** @jsxImportSource @emotion/react */

import { useOutletContext } from 'react-router'
import { SettingsCertification } from '@views/shared/certification'
import { CertificationType } from '@pages/types'

const SettingsCertificationPage = () => {
  const { certification } = useOutletContext<{ certification: CertificationType }>()

  return (
    <SettingsCertification
      includedFormParts={['form', 'settings', 'info', 'managers', 'audience']}
      state={'editing'}
      certification={certification}
    />
  )
}

export default SettingsCertificationPage
