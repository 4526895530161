import { memo } from 'react'
import { colors, IconAvatarGroup, ActionModal } from 'common-components'
import { useTranslation } from '@hooks'

import AssignContributor, { AssignContributorPropsType } from './AssignContributor'

export type AssignContributorModalPropsType = Pick<
  AssignContributorPropsType,
  'onAddContributor' | 'onDeleteContributor' | 'isLoading' | 'contributors'
> & {
  count?: number
  close: () => void
  isOpen: boolean
}

const AssignContributorModalComponent = ({
  contributors,
  onAddContributor,
  onDeleteContributor,
  isLoading,
  count = 1,
  close,
  isOpen,
}: AssignContributorModalPropsType) => {
  const { translate } = useTranslation()
  return (
    <ActionModal
      maskClosable={false}
      open={isOpen}
      onOk={close}
      customHeader={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IconAvatarGroup
            avatars={Array.from({ length: count }, () => ({
              size: 'md',
              icon: 'User01',
              iconColor: colors.success700,
              borderColor: colors.white,
              iconBackground: colors.gray100,
            }))}
          />
          <div>{translate('managers.assignSegments')}</div>
        </div>
      }
      onConfirm={close}
      onCancel={close}
    >
      <AssignContributor
        contributors={contributors}
        onAddContributor={onAddContributor}
        onDeleteContributor={onDeleteContributor}
        isLoading={isLoading}
      />
    </ActionModal>
  )
}

const AssignContributorModal = memo(
  AssignContributorModalComponent,
  (oldProps: AssignContributorModalPropsType, newProps: AssignContributorModalPropsType) => {
    return JSON.stringify(oldProps) === JSON.stringify(newProps)
  },
)

export default AssignContributorModal
