import { axiosClient } from '@definitions/axios-client'
import {
  HitApiType,
  TaskApiType,
  ProjectSlaLeverageHourApiType,
  HitLogApiType,
  FormShortApiType,
  PrefillSettingsApiType,
  AnswerApiType,
  TaskLogApiType,
} from '@api/types'
import { AxiosResponse } from 'axios'
import { TProjectState, TSlaType, TTaskOrigin, TTaskState } from '@definitions'
import { FilterPayloadType } from '@types'
import { catchApiError } from '@utils'

export type GetTasksPayloadType = {
  idProject: string
  filters: FilterPayloadType & {
    model: {
      origin?: TTaskOrigin
      state?: TTaskState
      idUser?: string[]
      dueDateMin?: string
      dueDateMax?: string
    }
  }
}
export const getTasks = async ({
  idProject,
  filters,
}: GetTasksPayloadType): Promise<TaskApiType[]> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<TaskApiType[]> = await axios.post(
      `/TaskService/Task/Project/${idProject}`,
      filters,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export const getTask = async (taskId: string): Promise<TaskApiType> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<TaskApiType> = await axios.get(`/TaskService/Task/${taskId}/GetTask`)
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export const getTaskLogs = async (taskId: string): Promise<TaskLogApiType[]> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<TaskLogApiType[]> = await axios.get(
      `/TaskService/Task/${taskId}/GetTaskLogs`,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export const getHits = async (taskId: string): Promise<HitApiType[]> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<HitApiType[]> = await axios.get(
      `/TaskService/Hit/GetHitsByTask/${taskId}`,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

type GetHitPayloadType = {
  idHit: string
}
export const getHit = async ({ idHit }: GetHitPayloadType): Promise<HitApiType> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<HitApiType> = await axios.get(`/TaskService/Hit/${idHit}`)
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export type AssignHitPayloadType = {
  idHit: string
}
export const assignHit = async ({ idHit }: AssignHitPayloadType): Promise<HitApiType> => {
  try {
    const axios = axiosClient()
    const resp = await axios.patch<HitApiType>(`/TaskService/Hit/${idHit}/AssignHit`)
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export type UpdateHitMarkPayloadType = {
  idHit: string
  mark: number
}
export const updateHitMark = async ({
  idHit,
  mark,
}: UpdateHitMarkPayloadType): Promise<HitApiType> => {
  try {
    const axios = axiosClient()
    const resp = await axios.put<HitApiType>(`/TaskService/Hit/${idHit}/UpdateMark/${mark}`)
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

type DeleteHitPayloadType = {
  idHit: string
}
export const deleteHit = async ({ idHit }: DeleteHitPayloadType): Promise<HitApiType> => {
  try {
    const axios = axiosClient()
    const resp = await axios.delete<HitApiType>(`/TaskService/Hit/${idHit}`)
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}
export const getHitLogs = async (hitId: string): Promise<HitLogApiType[]> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<HitLogApiType[]> = await axios.get(
      `/TaskService/Hit/${hitId}/GetHitLogs`,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}
export const getHitToReview = async (projectId: string): Promise<HitApiType> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<HitApiType> = await axios.get(
      `/TaskService/Hit/${projectId}/GetHitToReview`,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

// TASKLINE ACTIONS

type CreateAdditionalHitPayloadType = {
  idTask: string
}
export const createAdditionalHit = async ({
  idTask,
}: CreateAdditionalHitPayloadType): Promise<HitApiType> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<HitApiType> = await axios.post(
      `/TaskService/Task/${idTask}/CreateAddtionnalHit`,
      {},
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

type TaskIncreasePriorityPayloadType = {
  idTask: string
}
export const taskIncreasePriority = async ({
  idTask,
}: TaskIncreasePriorityPayloadType): Promise<TaskApiType> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<TaskApiType> = await axios.put(
      `/TaskService/Task/${idTask}/IncreasePriority`,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

type TaskDecreasePriorityPayloadType = {
  idTask: string
}
export const taskDecreasePriority = async ({
  idTask,
}: TaskDecreasePriorityPayloadType): Promise<TaskApiType> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<TaskApiType> = await axios.put(
      `/TaskService/Task/${idTask}/DecreasePriority`,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

type TaskCreateOverrideHitPayloadType = {
  idTask: string
}
export const taskCreateOverrideHit = async ({
  idTask,
}: TaskCreateOverrideHitPayloadType): Promise<HitApiType> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<HitApiType> = await axios.post(
      `/TaskService/Task/${idTask}/CreateOverrideHit`,
      {},
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export type TaskDeletePayloadType = {
  idTask: string
}
export const taskDelete = async ({ idTask }: TaskDeletePayloadType): Promise<void> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<void> = await axios.delete(`/TaskService/Task/${idTask}/DeleteTask`)
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

// PROJECT CREATION

export const getProjectDraftTasks = async (projectId: string): Promise<TaskApiType[]> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<TaskApiType[]> = await axios.get(
      `/TaskService/Task/${projectId}/DraftTasks`,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export type CreateProjectTaskPayloadType = {
  idProject: string
  name: string
  state: TProjectState
  sla: {
    type: TSlaType
    delay?: string
    leverageHours?: ProjectSlaLeverageHourApiType[]
    averageDeleveringTimeGoal: string
    effectiveTimeTaskGoal: string
    effectiveTimeHitGoal: string
    qualityExpected: number
  }
  form: FormShortApiType
  prefillSettings: PrefillSettingsApiType
}
export const createProjectTask = async ({
  idProject,
  sla,
  name,
  form,
  state,
  prefillSettings,
}: CreateProjectTaskPayloadType): Promise<TaskApiType> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<TaskApiType> = await axios.post(
      `/TaskService/Task/${idProject}/CreateNewTask`,
      { idProject, name, state, sla, form, prefillSettings },
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}
// TODO Do not delete for future implementation
// export const uploadTaskInputColumnFile = async (
//   data: {
//   idProject: string
//   columnName: string
//   file: string
// },
// ): Promise<void> => {
//   try {
//     const axios = axiosClient({
//       headers: { 'Content-Type': 'multipart/form-data' },
//     })
//     const formData = new FormData()
//     formData.append('IdProject', data.idProject)
//     formData.append('ColumnName', data.columnName)
//     formData.append('file', data.file)
//
//     const resp: AxiosResponse<void> = await axios.post(
//       `/ProjectService/Project/UploadTaskFileInputColumn`,
//       formData,
//     )
//     return resp.data
//   } catch (err: any) {
//     throw err.response
//   }
// }

export type UploadTasksFilePayloadType = {
  idProject: string
  file: File
}

export const uploadTasksFile = async ({
  idProject,
  file,
}: UploadTasksFilePayloadType): Promise<void> => {
  try {
    const axios = axiosClient({
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    const formData = new FormData()
    formData.append('idProject', idProject)
    formData.append('file', file)

    const resp: AxiosResponse<void> = await axios.post(
      `/ProjectService/Project/UploadTasksFile`,
      formData,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

type UpdateProjectInputJsonPayloadType = {
  idTask: string
  inputJson: AnswerApiType
}
export const updateProjectInputJson = async ({
  idTask,
  inputJson,
}: UpdateProjectInputJsonPayloadType) => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<TaskApiType> = await axios.put(`/TaskService/Task/UpdateInputJson`, {
      idTask,
      inputJson,
    })
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

type UpdateProjectTaskValuePayloadType = {
  idProject: string
  idTask: string
  columnName: string
  value: string
}
export const updateProjectTaskValue = async ({
  idProject,
  idTask,
  columnName,
  value,
}: UpdateProjectTaskValuePayloadType) => {
  try {
    const axios = axiosClient({
      headers: { 'Content-Type': 'multipart/form-data' },
    })

    const formData = new FormData()
    formData.append('IdProject', idProject)
    formData.append('id', idTask)
    formData.append('ColumnName', columnName)
    formData.append('Value', value)
    const resp: AxiosResponse<TaskApiType> = await axios.put(
      `/TaskService/Task/UpdateTaskValue`,
      formData,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

type UpdateProjectTaskFilePayloadType = {
  idProject: string
  idTask: string
  columnName: string
  file: string
}
export const updateProjectTaskFile = async ({
  idProject,
  idTask,
  columnName,
  file,
}: UpdateProjectTaskFilePayloadType) => {
  try {
    const axios = axiosClient({
      headers: { 'Content-Type': 'multipart/form-data' },
    })

    const formData = new FormData()
    formData.append('IdProject', idProject)
    formData.append('idTask', idTask)
    formData.append('ColumnName', columnName)
    formData.append('File', file)
    const resp: AxiosResponse<TaskApiType> = await axios.post(
      `/TaskService/Task/UploadTaskFile`,
      formData,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export type DeleteDraftTaskPayloadType = {
  idProject: string
  idTask: string
}
export const deleteDraftTask = async ({
  idProject,
  idTask,
}: DeleteDraftTaskPayloadType): Promise<void> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<void> = await axios.delete(
      `/TaskService/Task/${idProject}/DeleteDraftTask/${idTask}`,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}
