/** @jsxImportSource @emotion/react */

import {
  BoostButtonProject,
  ExportButtonProject,
  ReviewButtonProject,
  TTasklineHitNavParams,
} from '@views/project'
import React, { useMemo } from 'react'
import { Loader } from '@components/utils'
import { useDelayedEffect, useTranslation } from '@hooks'
import { css } from '@emotion/react'
import { useLocation, useNavigate, useParams, Outlet } from 'react-router-dom'
import { ISideBarProps, SideBar, TMenuBarItem } from '@components/navigation'
import { routes } from '@navigation/routes'
import { ProjectState } from '@definitions/states'
import {
  Tag,
  Button,
  Col,
  colors,
  Content,
  Icon,
  LayoutContainer,
  Row,
  Text,
} from 'common-components'
import { StateTagProject } from '@views/shared/project'
import { PageLayout } from '@components/layout'

import { store } from '@/store'

const ProjectContainer = () => {
  const params = useParams<TTasklineHitNavParams>()
  const { translate } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const getProjectAction = store.use.getProjectAction().call
  const getHitReviewAction = store.use.getHitReviewAction().call

  const hitReview = store.use.hitReview()
  const isLoadingGetProjectAction = store.use.getProjectAction().status === 'LOADING'
  const isLoadingGetHitReviewAction = store.use.getProjectAction().status === 'LOADING'
  const isSucceededGetProjectAction = store.use.getProjectAction().status === 'SUCCEEDED'
  const isSucceededGetHitReviewAction = store.use.getProjectAction().status === 'SUCCEEDED'

  const onSidebarClick: ISideBarProps['onClick'] = (e) => {
    navigate(e.key)
  }

  const items: TMenuBarItem[] = useMemo(() => {
    const baseItems = [
      /* HIDE
			 * {
				key: routes.projectDashboard.navigate(id),
				icon: <Icon icon={"LineChartup03"} />,
				label: "Dashboard",
			},
			{
				key: routes.projectAnalyse.navigate(id),
				icon: <Icon icon={"BarChartSquare02"} />,
				label: "Analyse",
			},*/
      {
        key: routes.projectTasks.navigate(params.projectId),
        icon: <Icon icon={'Briefcase01'} />,
        label: 'Tasklines',
      },
      {
        key: routes.projectAudience.navigate(params.projectId),
        icon: <Icon icon={'Users01'} />,
        label: 'Audience',
      },
      {
        key: routes.projectSettings.navigate(params.projectId),
        icon: <Icon icon={'Settings01'} />,
        label: 'Settings',
      },
    ]
    return baseItems
  }, [params, location.pathname])

  const project = store.use.projects()?.find((project) => project?.idProject === params.projectId)

  useDelayedEffect(() => {
    getProjectAction({ idProject: params.projectId })
    getHitReviewAction({ idProject: params.projectId })
  }, [params.projectId])

  return (
    <PageLayout
      css={css`
        margin: 0;
        max-width: none;
      `}
    >
      <LayoutContainer style={{ flex: 'none' }}>
        <Row
          align='middle'
          justify='space-between'
          css={css`
            max-height: 72px;
            padding: 18px 16px;
            background: ${colors.white};
            border-bottom: 1px solid ${colors.gray200};
          `}
        >
          <Col>
            <Row
              align='middle'
              css={css`
                gap: 16px;
              `}
            >
              <Button
                size='sm'
                disabled={!project?.idProject}
                onClick={() => {
                  if (params.view === 'hit' || params.view === 'task') {
                    navigate(routes.projectTasks.navigate(project?.idProject))
                  } else {
                    navigate(routes.projects.navigate())
                  }
                }}
              >
                <Icon icon='FlipBackward' size={20} />
              </Button>
              {project?.idProject && (
                <Tag
                  css={css`
                    max-height: 22px;
                    font-style: italic;
                    color: ${colors.gray700} !important;
                  `}
                  color={colors.gray100}
                >
                  #{project?.idProject}
                </Tag>
              )}
              <Text italic strong size='lg' color={colors.gray900}>
                {project?.name}
              </Text>
              {project?.state && <StateTagProject italic state={project?.state} />}
            </Row>
          </Col>
          <Col>
            <Row
              align='middle'
              css={css`
                gap: 16px;
              `}
            >
              {project && (
                <BoostButtonProject
                  idProject={params.projectId}
                  state={project?.state}
                  endBoost={project?.endBoost}
                />
              )}
              <ExportButtonProject idProject={params.projectId} />
              {ProjectState[project?.state] === 'online' && (
                <ReviewButtonProject idProject={params.projectId} hitReview={hitReview} />
              )}
              <Button
                type={'primary'}
                onClick={() => navigate(routes.projectCreation.navigate(params.projectId, 4))}
              >
                {translate('project.navigation.addingData')}
              </Button>
            </Row>
          </Col>
        </Row>
      </LayoutContainer>

      <LayoutContainer>
        <SideBar onClick={onSidebarClick} selectedKeys={[location.pathname]} items={items} />
        <Content>
          <Col style={{ padding: '24px 32px', height: '100%' }}>
            <>
              {(isLoadingGetProjectAction || isLoadingGetHitReviewAction) && <Loader />}
              {isSucceededGetProjectAction && isSucceededGetHitReviewAction && (
                <Outlet context={{ project }} />
              )}
            </>
          </Col>
        </Content>
      </LayoutContainer>
    </PageLayout>
  )
}

export default ProjectContainer
