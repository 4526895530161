/** @jsxImportSource @emotion/react */

import { VerticalFormItem } from '@components/display'
import { css } from '@emotion/react'
import {
  Row,
  Col,
  Title,
  Space,
  Content,
  useDisclosure,
  ActionModal,
  IconSearch,
  BG_COLORS,
  TextInput,
  Text,
  Button,
} from 'common-components'
import { useState } from 'react'
import { useTranslation } from '@hooks'
import { CreateCertificationPayloadType, GetCertificationsPayloadType } from '@api'
import { IconType } from '@types'
import { LoadingOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { routes } from '@navigation/routes'

import FiltersCertification from './FiltersCertification'

import { store } from '@/store'

type HeaderCertificationsPropsType = {
  onFiltersChange: (filters: GetCertificationsPayloadType) => void
}

const ICON_DEFAULT: IconType = {
  iconName: 'AddonPuzzlePluginProgram',
  iconColor: BG_COLORS[0],
}

const HeaderCertifications = ({ onFiltersChange }: HeaderCertificationsPropsType) => {
  const { isOpen, close, open } = useDisclosure()

  const createCertificationAction = store.use.createCertificationAction().call
  const isLoading = store.use.createCertificationAction().status === 'LOADING'

  const navigate = useNavigate()
  const { translate } = useTranslation()
  const [isFiltersVisible, setFiltersVisible] = useState(false)

  const [payload, setPayload] = useState<CreateCertificationPayloadType>({
    name: '',
    icon: ICON_DEFAULT,
  })

  return (
    <Content>
      <Row
        justify={'space-between'}
        align={'top'}
        css={css`
          margin: 24px 0;
        `}
      >
        <Col>
          <Title preset={'tablePageTitle'}>{translate('certificationsPage.listHeaderTitle')}</Title>
          <Title preset={'tablePageSubtitle'}>
            {translate('certificationsPage.listHeaderSubtitle')}
          </Title>
        </Col>
        <Row>
          <Space>
            <Button onClick={() => setFiltersVisible(true)} iconLeft='FilterLines'>
              {translate('common.filter')}
            </Button>

            <FiltersCertification
              visible={isFiltersVisible}
              onClose={() => setFiltersVisible(false)}
              onFiltersChange={onFiltersChange}
            />

            <>
              <Button type={'primary'} onClick={() => open()} iconLeft={'Plus'}>
                {translate('certificationsPage.createButtonLabel')}
              </Button>
              <ActionModal
                open={isOpen}
                maskClosable={false}
                onOk={() => ({})}
                onConfirm={() => ({})}
                title='Ajouter une certification'
                onCancel={() => {
                  setPayload({
                    name: '',
                    icon: ICON_DEFAULT,
                  })
                  close()
                }}
                actionButtons={[
                  {
                    text: 'Retour',
                    onClick: () => {
                      setPayload({
                        name: '',
                        icon: ICON_DEFAULT,
                      })
                      close()
                    },
                  },
                  {
                    type: 'primary',
                    text: isLoading ? <LoadingOutlined /> : 'Suivant',
                    onClick: () => {
                      if (payload) {
                        createCertificationAction(payload, (idCertification) => {
                          navigate(routes.certificationCreation.navigate(idCertification, '1'))
                          close()
                        })
                      }
                    },
                    disabled: !payload?.name?.length,
                  },
                ]}
              >
                <Row style={{ marginBottom: 10 }}>
                  <Text>Créez une certification</Text>
                </Row>

                <Row justify={'center'} align={'middle'}>
                  <Col span={6}>
                    <IconSearch
                      value={payload?.icon}
                      bgColors={BG_COLORS}
                      onSubmit={({ iconName, iconColor }) => {
                        setPayload((prevState) => ({
                          ...prevState,
                          icon: {
                            iconColor,
                            iconName,
                          },
                        }))
                      }}
                    />
                  </Col>
                  <Col flex={'auto'}>
                    <VerticalFormItem label={'Nom'}>
                      <TextInput
                        value={payload?.name}
                        onChange={({ target }) =>
                          setPayload((prevState) => ({
                            ...prevState,
                            name: target?.value,
                          }))
                        }
                      />
                    </VerticalFormItem>
                  </Col>
                </Row>
              </ActionModal>
            </>
          </Space>
        </Row>
      </Row>
    </Content>
  )
}

export default HeaderCertifications
