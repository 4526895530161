/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { useMemo, useRef, SetStateAction, Dispatch, useState } from 'react'
import { StatusTagUser } from '@views/shared/user'
import { capitalizeFirstLetter } from '@utils/capitalize-first'
import { ContributorType, SegmentShortType } from '@pages/types'
import { UserType } from '@definitions/states'
import DateUtils from '@helpers/date'
import { ColumnsTableType } from '@types'
import { routes } from '@navigation/routes'
import { AssignSegmentModal } from '@views/shared/segment'
import {
  Avatar,
  Checkbox,
  Row,
  Space,
  Table,
  colors,
  Text,
  CommonIconAvatar,
  IconAvatarGroup,
  useDisclosure,
  ITableProps,
} from 'common-components'

type ColumnTableType = 'checkbox' | 'name' | 'yearsOfExperience' | 'role' | 'segments' | 'status'

export type TableContributorsPropsType = {
  contributors: ContributorType[]
  isLoadingSegmentMutation?: boolean
  setSelectedCheckboxes: Dispatch<SetStateAction<string[]>>
  selectedCheckboxes: string[]
  onAddSegment?: (segmentShort: SegmentShortType, idContributor: string) => void
  onDeleteSegment?: (idSegment: string, idContributor: string) => void
  tableProps?: Omit<ITableProps<ContributorType>, 'data' | 'columns'>
  additionalColumnsTable?: ColumnTableType[]
}

const getSegments = (
  contributors: ContributorType[],
  idContributor: string,
): SegmentShortType[] | [] => {
  const segmentsCurrent = contributors.find(
    (item) => item.userData.idUser === idContributor,
  )?.segmentsShort
  if (!Array.isArray(segmentsCurrent)) {
    return []
  }
  return segmentsCurrent
}

const maxCount = 2

const TableContributors = ({
  contributors,
  isLoadingSegmentMutation,
  setSelectedCheckboxes,
  selectedCheckboxes,
  tableProps = {},
  onAddSegment,
  onDeleteSegment,
  additionalColumnsTable = [],
}: TableContributorsPropsType) => {
  const [mainCheckbox, setMainCheckbox] = useState<boolean>(false)

  const { t } = useTranslation()
  const navigate = useNavigate()
  const segmentDisclosure = useDisclosure()
  const idContributorClicked = useRef<string>()

  const handleCheckboxClick = (id: string) => {
    if (selectedCheckboxes.includes(id)) {
      setSelectedCheckboxes(selectedCheckboxes.filter((checkboxId: string) => id !== checkboxId))
      return
    }
    setSelectedCheckboxes([...selectedCheckboxes, id])
  }

  const handleMainCheckboxClick = () => {
    setMainCheckbox((prevState) => !prevState)
    if (mainCheckbox) {
      setSelectedCheckboxes([])
      return
    }
    const allIds = contributors.map((record: ContributorType) => record.userData.idUser)
    setSelectedCheckboxes(allIds)
  }

  const tableColumns = useMemo(() => {
    try {
      const columnsTable: ColumnsTableType<ContributorType, ColumnTableType>[] = [
        {
          title: (
            <Checkbox name='checkbox' checked={mainCheckbox} onClick={handleMainCheckboxClick} />
          ),
          key: 'checkbox',
          render: (record: ContributorType) => (
            <Checkbox
              name='checkbox'
              checked={selectedCheckboxes.includes(record.userData.idUser)}
              onClick={() => handleCheckboxClick(record.userData.idUser)}
            />
          ),
        },
        {
          title: t('contributors.name'),
          key: 'name',
          sorter: (a: ContributorType, b: ContributorType) => {
            const name1 = `${a.userData.firstName} ${a.userData.lastName}`
            const name2 = `${b.userData.firstName} ${b.userData.lastName}`
            return name1.localeCompare(name2)
          },
          render: (record: ContributorType) => (
            <Row align={'middle'}>
              <Space size={'middle'} style={{ gap: 12 }}>
                <Avatar src={record.userData.urlAvatar} size='sm' />
                <Text strong>{`${record.userData.firstName} ${record.userData.lastName}`}</Text>
              </Space>
            </Row>
          ),
        },
      ]

      if (additionalColumnsTable?.includes('yearsOfExperience')) {
        columnsTable.push({
          title: t('contributors.experience'),
          key: 'yearsOfExperience',
          sorter: (a: ContributorType, b: ContributorType) =>
            DateUtils.getYears(a.userData.creationDate) -
            DateUtils.getYears(b.userData.creationDate),
          render: (record: ContributorType) => {
            return (
              <Row align={'middle'}>
                <Text size='xs'>{DateUtils.getYears(record.userData.creationDate)} ans</Text>
              </Row>
            )
          },
        })
      }

      columnsTable.push({
        title: t('contributors.role'),
        key: 'role',
        // sorter: (a: ContributorType['userData'], b: ContributorType['userData']) => a.type.localeCompare(b.type),
        render: (record: ContributorType) => (
          <Row align={'middle'}>{capitalizeFirstLetter(UserType[record.userData.type])}</Row>
        ),
      })

      if (additionalColumnsTable?.includes('segments')) {
        columnsTable.push({
          title: t('contributors.segments'),
          key: 'segments',
          sorter: (a: ContributorType, b: ContributorType) => {
            if (a.segmentsShort < b.segmentsShort) return -1
            if (a.segmentsShort > b.segmentsShort) return 1
            return 0
          },
          render: (record: ContributorType) => {
            return (
              <Row align='middle'>
                {!!record.segmentsShort?.length && (
                  <IconAvatarGroup
                    avatars={record.segmentsShort.map((segment) => {
                      return {
                        size: 'md',
                        icon: 'Scale02',
                        iconColor: colors.success500,
                        borderColor: colors.white,
                        iconBackground: segment?.icon?.iconColor,
                      }
                    })}
                    maxCount={maxCount}
                  />
                )}
                <CommonIconAvatar
                  // @ts-ignore
                  css={css`
                    margin-left: ${record.segmentsShort?.length ? 10 : maxCount * 33}px;
                    cursor: pointer;
                    border: 1px dashed ${colors.gray300} !important;
                  `}
                  onClick={() => {
                    idContributorClicked.current = record.userData.idUser
                    segmentDisclosure.open()
                  }}
                  iconBackground={colors.white}
                  borderColor={colors.gray300}
                  icon={'Plus'}
                  id='plus-icon'
                />
              </Row>
            )
          },
        })
      }

      return columnsTable.concat([
        /* HIDE
			{
				title: t("contributors.performance"),
				key: "quality",
				dataIndex: "quality",
				sorter: (a: ContributorType, b: ContributorType) =>
					a.quality.localeCompare(b.quality),
				render: (quality: string) => (
					<Row align={"middle"}>
						<Text size="xs">{quality}</Text>
					</Row>
				),
			},
			{
				title: t("contributors.effectiveTime"),
				key: "effectiveTime",
				dataIndex: "effectiveTime",
				sorter: (a: ContributorType, b: ContributorType) =>
					a.effectiveTime.localeCompare(b.effectiveTime),
				render: (effectiveTime: string) => (
					<Row align={"middle"}>
						<Text size="xs">{effectiveTime}</Text>
					</Row>
				),
			},
			{
			  title: t("segment.usersTableColumns.time"),
				 key: "effectiveTime",
				dataIndex: "effectiveTime",
				sorter: (a: { temps: string }, b: { temps: string }) => {
				   if (a.temps < b.temps) return -1;
					  if (a.temps > b.temps) return 1;
						return 0;
				},
					render: (temps: string) => <Row align={"middle"}>{temps}</Row>,
			},
			*/ {
          title: t('contributors.status'),
          key: 'status',
          render: (record: ContributorType) => (
            <Row align={'middle'}>
              {/*TODO remove any*/}
              <StatusTagUser status={record.userData.activity as any} />
            </Row>
          ),
        },
      ])
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
      return []
    }
  }, [mainCheckbox, selectedCheckboxes, contributors?.length])

  return (
    <>
      <Table<ContributorType>
        css={css`
          margin-top: 10px;
          cursor: pointer;
        `}
        data={contributors}
        columns={tableColumns}
        onRow={(record: ContributorType) => ({
          onClick: (e: any) => {
            if (
              !['checkbox'].includes(e.target.name) &&
              !['modal', 'plus-icon'].includes(e.target.id) &&
              !['svg', 'path'].includes(e.target.tagName)
            ) {
              navigate(routes.contributor.navigate(record.userData.idUser))
            }
          },
        })}
        {...tableProps}
      />
      {segmentDisclosure.isOpen && (
        <AssignSegmentModal
          close={segmentDisclosure.close}
          isOpen={segmentDisclosure.isOpen}
          isLoading={isLoadingSegmentMutation}
          segments={getSegments(contributors, idContributorClicked.current)}
          onAddSegment={(segmentShort) =>
            onAddSegment && onAddSegment(segmentShort, idContributorClicked.current)
          }
          onDeleteSegment={(idSegment) =>
            onDeleteSegment && onDeleteSegment(idSegment, idContributorClicked.current)
          }
        />
      )}
    </>
  )
}

export default TableContributors
