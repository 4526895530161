/** @jsxImportSource @emotion/react */

import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { css } from '@emotion/react'
import { FormState, Quality, SkillType } from '@definitions'
import { CommonFilters, StateCheckbox } from '@components/inputs'
import { useTranslation } from '@hooks'
import { GetFormsPayloadType } from '@api'
import { Button, Drawer, Form, FormItem, Space, Title, Text } from 'common-components'
import { SelectManagers } from '@views/shared/manager'

type FiltersFormPropsType = {
  visible: boolean
  onFiltersChange?: (filters: GetFormsPayloadType) => void
  onClose?: () => void
  initValues?: GetFormsPayloadType
}

const FiltersForm = ({ visible, onClose, onFiltersChange, initValues }: FiltersFormPropsType) => {
  const { control, handleSubmit, reset } = useForm<GetFormsPayloadType>({
    defaultValues: { model: {}, ...(initValues || {}) },
  })
  const { translate } = useTranslation()

  useEffect(() => {
    reset(initValues)
  }, [initValues])

  const onSubmit = (data: GetFormsPayloadType) => {
    onFiltersChange && onFiltersChange(data)
    onClose && onClose()
  }

  return (
    <Drawer
      title={
        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <Title preset='tablePageTitle'>{translate('common.filter')}</Title>
          <Text>{translate('common.apply')}</Text>
        </div>
      }
      placement='right'
      closable={true}
      onClose={onClose}
      closeIcon={false}
      open={visible}
    >
      <Form onFinish={handleSubmit(onSubmit)} css={$form}>
        <CommonFilters<GetFormsPayloadType> control={control} />

        <Controller
          name={'model.state'}
          control={control}
          render={({ field }) => (
            <FormItem label={translate('common.state')}>
              <StateCheckbox
                values={FormState}
                labelTxKey={'formState'}
                preset={'form-state'}
                inputProps={{
                  onChange: field.onChange,
                  value: field.value,
                }}
              />
            </FormItem>
          )}
        />
        <Controller
          name={'model.idUser'}
          control={control}
          render={({ field }) => (
            <FormItem label={translate('common.managers')}>
              <SelectManagers onChange={field.onChange} value={field.value} />
            </FormItem>
          )}
        />

        <Controller
          name={'model.quality'}
          control={control}
          render={({ field }) => (
            <FormItem label={translate('common.quality')}>
              <StateCheckbox
                values={Quality}
                labelTxKey={'quality'}
                preset={'quality'}
                inputProps={{
                  onChange: field.onChange,
                  value: field.value,
                }}
              />
            </FormItem>
          )}
        />

        <Controller
          name={'model.skill'}
          control={control}
          render={({ field }) => (
            <FormItem label={translate('common.skill')}>
              <StateCheckbox
                values={SkillType}
                labelTxKey={'skill'}
                preset={'skill'}
                inputProps={{
                  onChange: field.onChange,
                  value: field.value,
                }}
              />
            </FormItem>
          )}
        />

        <Space css={[$fullWidth, $footerButtonsContainer]} size={10}>
          <Button onClick={() => reset()}>Cancel</Button>
          <Button type='primary' htmlType='submit'>
            Apply
          </Button>
        </Space>
      </Form>
    </Drawer>
  )
}

const $form = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const $fullWidth = css`
  width: 100%;
`

const $footerButtonsContainer = css`
  justify-content: center;
`

export default FiltersForm
