const en = {
  managers: 'Managers',
  export: 'Export',
  import: 'Import',
  filter: 'Filter',
  addManager: 'Add a manager',
  manageTeamInfo: 'Manage your team members and their account permissions here.',
  name: 'Name',
  old: 'Seniority',
  role: 'Role',
  segments: 'Segments',
  certifications: 'Certifications',
  status: 'Status',
  actions: 'Actions',
  edit: 'Edit',
  assignSegment: 'Assign a segment',
  assignCertification: 'Assign a certification',
  delete: 'Delete',
  previous: 'Previous',
  next: 'Next',
  selectedManager: 'Manager selected',
  selectedManagers: 'Managers selected',
  assignCertifications: 'Assign certifications',
  assignSegments: 'Assign to segments',
  contributor: 'Contributor',
  performance: 'Performance',
  effectiveTime: 'Effective time',
  candidate: 'Candidate',
  deleteUser1: 'Delete',
  deleteUser2: 'user?',
  deleteUser3: 'users?',
  confirmAction: 'Are you sure you want to perform this action?',
  cancel: 'Cancel',
  confirm: 'Confirm',
  back: 'Back',
  validate: 'Validate',
  years: 'years',
  year: 'year',
  superAdmin: 'Super Administrator',
  admin: 'Administrator',
  manager: 'Manager',
  worker: 'Worker',
  candidat: 'Candidate',
  active: 'Active',
  inactive: 'Inactive',
  waiting: 'Waiting...',
  deleteModal: {
    title: 'Delete Manager?',
    label: 'Are you sure you want to perform this action?',
  },
  createModal: {
    title: 'Error in Form Input',
    label: 'The email address is already in use',
  },
}

export default en

export type Translations = typeof en
