import { IGetWorkflowsReq, TGetWorkflowResp, TGetWorkflowsResp } from '@types'
import { axiosClient } from '@definitions'
import { AxiosResponse } from 'axios'
import { catchApiError } from '@utils'

export const getWorkflows = async (filters: IGetWorkflowsReq): Promise<TGetWorkflowsResp> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<TGetWorkflowsResp> = await axios.post(
      '/WorkflowService/Workflow/GetWorkflows',
      filters,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export const getWorkflow = async (workflowId: string): Promise<TGetWorkflowResp> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<TGetWorkflowResp> = await axios.get(
      `/WorkflowService/Workflow/${workflowId}/GetWorkflow`,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}
