import { INavigationEntry, TAvailableRoutesTitles } from '@types'
import { useTranslation } from '@hooks/useTranslation'
import { routes } from '@navigation/routes'
import { useLocation, useNavigate } from 'react-router'
import { useEffect, useState } from 'react'
import { IAvailableIcons } from 'common-components'
import { useAuth } from 'react-oidc-context'

interface IColumnsOfEntries {
  entries: Array<INavigationEntry>
  config: { iconColor?: string }
}

const cleanPath = (path: string) => path.replace(/(\.\.\/)+/g, '')

const useNavigationEntries = () => {
  const { translate } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const auth = useAuth()

  const [currentEntry, setCurrentEntry] = useState<INavigationEntry | null>(null)

  useEffect(() => {
    redirectToPerformances()
    updateCurrentEntry(getCurrentEntryFromPath(location.pathname))
  }, [location])

  const redirectToPerformances = () => {
    if (location.pathname === '/') {
      navigate(routes.projects.navigate())
    }
  }

  const updateCurrentEntry = (entry: INavigationEntry) => {
    setCurrentEntry(entry)
  }

  const firstColumn: IColumnsOfEntries = {
    entries: [
      /* HIDE
			 * {
				icon: "Speedometer02",
				title: translate(
					"navigation.dropdownPanel.columns.first.dashboard",
				) as TAvailableRoutesTitles,
				subtitle: "Vue d’ensemble sur votre compte",
				to: routes.dashboard.navigate(),
				isActive: (pathname) => pathname === routes.dashboard.navigate(),
				iconColor: "#15B79E",
			},*/
      {
        icon: 'BarLineChart',
        title: translate(
          'navigation.dropdownPanel.columns.first.performance',
        ) as TAvailableRoutesTitles,
        subtitle: 'Suivez les métriques',
        to: routes.performances.navigate(),
        isActive: (pathname) => pathname === routes.performances.navigate(),
        iconColor: '#15B79E',
      },
    ],
    config: { iconColor: '#15B79E' },
  }
  const secondColumn: IColumnsOfEntries = {
    entries: [
      {
        title: translate(
          'navigation.dropdownPanel.columns.second.contributeurs',
        ) as TAvailableRoutesTitles,
        icon: 'Users01',
        subtitle: 'Gérez vos utilisateurs',
        to: routes.contributors.navigate(),
        isActive: (pathname) => pathname.includes(cleanPath(routes.contributors.navigate())),
        iconColor: '#2E90FA',
      },
      {
        title: translate(
          'navigation.dropdownPanel.columns.second.certifications',
        ) as TAvailableRoutesTitles,
        icon: 'CheckVerified03',
        subtitle: 'Créez des tests de compétences',
        to: routes.certifications.navigate(),
        isActive: (pathname) => pathname.includes(cleanPath(routes.certifications.navigate())),
        iconColor: '#2E90FA',
      },
      {
        title: translate(
          'navigation.dropdownPanel.columns.second.segments',
        ) as TAvailableRoutesTitles,
        icon: 'Target03',
        subtitle: 'Créez vos groupes d’utilisateurs',
        to: routes.segments.navigate(),
        isActive: (pathname) => pathname.includes(cleanPath(routes.segments.navigate())),
        iconColor: '#2E90FA',
      },
    ],
    config: { iconColor: '#2E90FA' },
  }
  const thirdColumn: IColumnsOfEntries = {
    entries: [
      {
        title: translate(
          'navigation.dropdownPanel.columns.third.projets',
        ) as TAvailableRoutesTitles,
        subtitle: 'Gérez vos projets',
        icon: 'Briefcase01',
        to: routes.projects.navigate(),
        isActive: (pathname) => pathname.includes(cleanPath(routes.projects.navigate())),
        iconColor: '#7A5AF8',
      },
      {
        title: translate(
          'navigation.dropdownPanel.columns.third.formulaires',
        ) as TAvailableRoutesTitles,
        subtitle: 'Gérez vos formulaires de saisie',
        icon: 'TextInput',
        to: routes.forms.navigate(),
        isActive: (pathname) => pathname.includes(cleanPath(routes.forms.navigate())),
        iconColor: '#7A5AF8',
      },
      {
        title: translate(
          'navigation.dropdownPanel.columns.third.modèles',
        ) as TAvailableRoutesTitles,
        subtitle: 'Gérez vos modèles d’IA',
        icon: 'FaceIdSquare',
        to: routes.models.navigate(),
        isActive: (pathname) => pathname.includes(cleanPath(routes.models.navigate())),
        iconColor: '#7A5AF8',
      },
      /* HIDE
			{
				title: translate(
					"navigation.dropdownPanel.columns.third.workflows",
				) as TAvailableRoutesTitles,
				subtitle: "Gérez vos workflows",
				icon: "Dataflow01",
				to: routes.workflows.navigate(),
				isActive: (pathname) => pathname === routes.workflows.navigate(),
				iconColor: "#7A5AF8",
			},*/
      {
        title: translate('navigation.dropdownPanel.columns.third.risks') as TAvailableRoutesTitles,
        icon: 'CheckVerified03',
        subtitle: 'Paramétrez vos modèles de lutte anti-fraude',
        to: routes.risks.navigate(),
        isActive: (pathname) => pathname.includes(cleanPath(routes.risks.navigate())),
        iconColor: '#7A5AF8',
      },
    ],
    config: { iconColor: '#7A5AF8' },
  }
  const fourthColumn: IColumnsOfEntries = {
    entries: [
      {
        title: translate(
          'navigation.dropdownPanel.columns.fourth.managers',
        ) as TAvailableRoutesTitles,
        subtitle: 'Gérez vos managers',
        icon: 'ShieldTick',
        to: routes.managers.navigate(),
        isActive: (pathname) => pathname.includes(cleanPath(routes.managers.navigate())),
        iconColor: '#717BBC',
      },
      /* HIDE
			{
				title: translate(
					"navigation.dropdownPanel.columns.fourth.consommation",
				) as TAvailableRoutesTitles,
				subtitle: "Suivez vos dépenses",
				icon: "Icon10",
				iconColor: "#717BBC",
				disabled: true,
			},*/
      /* HIDE
			{
				title: translate(
					"navigation.dropdownPanel.columns.fourth.facturation",
				) as TAvailableRoutesTitles,
				subtitle: "Factures et moyens de paiement",
				icon: "CreditCard02",
				iconColor: "#717BBC",
				disabled: true,
			},*/
      /* HIDE
			{
				title: translate(
					"navigation.dropdownPanel.columns.fourth.paramètres",
				) as TAvailableRoutesTitles,
				subtitle: "Réglages, clés d’API",
				icon: "Settings01",
				iconColor: "#717BBC",
				isActive: (pathname) => pathname.includes("settings"),
				to: routes.settings.navigate(settingsTabs.general),
			},*/
      {
        title: translate(
          'navigation.dropdownPanel.columns.fourth.logout',
        ) as TAvailableRoutesTitles,
        subtitle: 'Fermez votre session',
        icon: 'LogOut01',
        iconColor: '#717BBC',
        onClick: async () => await auth.signoutRedirect(),
      },
    ],
    config: { iconColor: '#717BBC' },
  }

  const getCurrentEntryFromPath = (pathname: string) => {
    return [
      ...firstColumn.entries,
      ...secondColumn.entries,
      ...thirdColumn.entries,
      ...fourthColumn.entries,
    ].find((entry) => entry.isActive && entry.isActive(pathname))
  }

  return {
    firstColumn,
    secondColumn,
    thirdColumn,
    fourthColumn,
    currentEntry,
    defaultEntry: {
      icon: 'BarLineChart' as IAvailableIcons,
      title: translate(
        'navigation.dropdownPanel.columns.first.performance',
      ) as TAvailableRoutesTitles,
      subtitle: 'Suivez les métriques',
      to: routes.performances.navigate(),
      isActive: (pathname: string) => pathname === routes.performances.navigate(),
      iconColor: '#15B79E',
    },
  }
}

export { useNavigationEntries }
