import { useParams } from 'react-router'
import { useEffect, useMemo } from 'react'
import { TaskHitProject, TTasklineHitNavParams } from '@views/project'
import { getWirkUrl } from '@definitions'
import { DELAY_DURATION_CALL_ACTION } from '@utils'

import { store } from '@/store'

const TaskHitProjectPage = () => {
  const { tenant, taskId, hitId, view } = useParams<TTasklineHitNavParams>()

  const accessToken = store.use.me().accessToken

  const task = store.use.tasksFiltered().find(({ idTask }) => idTask === taskId)
  const hit = store.use.hits().find(({ idHit }) => idHit === hitId)

  const getTaskAction = store.use.getTaskAction().call
  const getHitsAction = store.use.getHitsAction().call
  const getHitLogsAction = store.use.getHitLogsAction().call
  const getTaskLogsAction = store.use.getTaskLogsAction().call
  const getDocumentLogsAction = store.use.getDocumentLogsAction().call

  useEffect(() => {
    const timer = setTimeout(() => {
      getTaskAction({ idTask: taskId })
      getHitsAction({ idTask: taskId })
      if (view === 'task') {
        getTaskLogsAction({ idTask: taskId })
      }
    }, DELAY_DURATION_CALL_ACTION)

    return () => {
      clearTimeout(timer)
    }
  }, [taskId, view])

  useEffect(() => {
    let timer1: NodeJS.Timeout
    let timer2: NodeJS.Timeout

    if (hitId && view === 'hit') {
      timer1 = setTimeout(() => {
        getHitLogsAction({ idHit: hitId })
      }, DELAY_DURATION_CALL_ACTION)
    }

    if (task?.idDocument && view === 'wirk-model') {
      timer2 = setTimeout(() => {
        getDocumentLogsAction({ idDocument: task.idDocument })
      }, DELAY_DURATION_CALL_ACTION)
    }

    return () => {
      clearTimeout(timer1)
      clearTimeout(timer2)
    }
  }, [hitId, task?.idDocument, view])

  const viewUrl = useMemo(() => {
    if (hit) {
      return `${getWirkUrl('Form')}/contribute/view/${hitId}?token=${accessToken}&tenant=${tenant}`
    }
    return `${getWirkUrl('Form')}/contribute/answer/${taskId}?token=${accessToken}&tenant=${tenant}`
  }, [JSON.stringify(hit), hitId, taskId, accessToken, tenant])

  return (
    <>
      <TaskHitProject viewUrl={viewUrl} hit={hit} task={task} />
    </>
  )
}

export default TaskHitProjectPage
