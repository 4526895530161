import { ContributorType, ManagerType, SegmentType } from '@pages/types'

import { ImmerStateCreator, StatusAction } from '../store.type'
import { StoreGlobalType } from '../store'

import {
  addContributorToSegmentAction,
  AddContributorToSegmentActionPayloadType,
  addManagerToSegmentAction,
  AddManagerToSegmentActionPayloadType,
  addManagerToSegmentsAction,
  AddManagerToSegmentsActionPayloadType,
  createSegmentAction,
  CreateSegmentActionPayloadType,
  deleteContributorsFromSegmentAction,
  DeleteContributorsFromSegmentActionPayloadType,
  deleteManagerFromSegmentsAction,
  DeleteManagerFromSegmentsActionPayloadType,
  deleteManagersFromSegmentAction,
  DeleteManagersFromSegmentActionPayloadType,
  deleteSegmentsAction,
  DeleteSegmentsActionPayloadType,
  downloadSegmentsAction,
  DownloadSegmentsActionPayloadType,
  getManagersSegmentAction,
  GetManagersSegmentActionPayloadType,
  getSegmentAction,
  GetSegmentActionPayloadType,
  getSegmentsAction,
  GetSegmentsActionPayloadType,
  modifyContributorsFromSegmentAction,
  ModifyContributorsFromSegmentActionPayloadType,
  modifyManagersFromSegmentAction,
  ModifyManagersFromSegmentActionPayloadType,
  requestAccessSegmentAction,
  RequestAccessSegmentActionPayloadType,
  updateSegmentAction,
  UpdateSegmentActionPayloadType,
  updateSkillSegmentAction,
  UpdateSkillSegmentActionPayloadType,
  updateSocioDemographicSegmentAction,
  UpdateSocioDemographicSegmentActionPayloadType,
} from './segment.action'

export type SegmentSliceType = {
  /** selectors **/
  segments: SegmentType[]
  contributorsSegment: ContributorType[]
  managersSegment: ManagerType[]
  /** actions **/
  getSegmentsAction: {
    call: (payload: GetSegmentsActionPayloadType) => void
    status: StatusAction
  }
  requestAccessSegmentAction: {
    call: (payload: RequestAccessSegmentActionPayloadType) => void
    status: StatusAction
  }
  getSegmentAction: {
    call: (payload: GetSegmentActionPayloadType) => void
    status: StatusAction
  }
  createSegmentAction: {
    call: (payload: CreateSegmentActionPayloadType, callback: (idSegment: string) => void) => void
    status: StatusAction
  }
  updateSegmentAction: {
    call: (payload: UpdateSegmentActionPayloadType, callback: () => void) => void
    status: StatusAction
  }
  updateSocioDemographicSegmentAction: {
    call: (payload: UpdateSocioDemographicSegmentActionPayloadType) => void
    status: StatusAction
  }
  updateSkillSegmentAction: {
    call: (payload: UpdateSkillSegmentActionPayloadType) => void
    status: StatusAction
  }
  deleteSegmentsAction: {
    call: (payload: DeleteSegmentsActionPayloadType, callback: () => void) => void
    status: StatusAction
  }
  downloadSegmentsAction: {
    call: (payload: DownloadSegmentsActionPayloadType) => void
    status: StatusAction
  }
  /** contributor **/
  modifyContributorsFromSegmentAction: {
    call: (payload: ModifyContributorsFromSegmentActionPayloadType) => void
    status: StatusAction
  }
  addContributorToSegmentAction: {
    call: (payload: AddContributorToSegmentActionPayloadType) => void
    status: StatusAction
  }
  deleteContributorsFromSegmentAction: {
    call: (payload: DeleteContributorsFromSegmentActionPayloadType, callback: () => void) => void
    status: StatusAction
  }
  /** manager **/
  getManagersSegmentAction: {
    call: (payload: GetManagersSegmentActionPayloadType) => void
    status: StatusAction
  }
  addManagerToSegmentsAction: {
    call: (payload: AddManagerToSegmentsActionPayloadType) => void
    status: StatusAction
  }
  modifyManagersFromSegmentAction: {
    call: (payload: ModifyManagersFromSegmentActionPayloadType) => void
    status: StatusAction
  }
  deleteManagerFromSegmentsAction: {
    call: (payload: DeleteManagerFromSegmentsActionPayloadType) => void
    status: StatusAction
  }
  addManagerToSegmentAction: {
    call: (payload: AddManagerToSegmentActionPayloadType) => void
    status: StatusAction
  }
  deleteManagersFromSegmentAction: {
    call: (payload: DeleteManagersFromSegmentActionPayloadType, callback: () => void) => void
    status: StatusAction
  }
}

const segmentSlice: ImmerStateCreator<SegmentSliceType> = (
  set,
  get: () => StoreGlobalType & SegmentSliceType,
) => ({
  segments: [],
  contributorsSegment: [],
  managersSegment: [],
  getSegmentsAction: {
    call: (payload) => getSegmentsAction(set, get)(payload),
    status: 'IDLE',
  },
  getSegmentAction: {
    call: (payload) => getSegmentAction(set, get)(payload),
    status: 'IDLE',
  },
  createSegmentAction: {
    call: (payload, callback) => createSegmentAction(set, get)(payload, callback),
    status: 'IDLE',
  },
  deleteSegmentsAction: {
    call: (payload, callback) => deleteSegmentsAction(set)(payload, callback),
    status: 'IDLE',
  },
  downloadSegmentsAction: {
    call: (payload) => downloadSegmentsAction(set)(payload),
    status: 'IDLE',
  },
  addManagerToSegmentsAction: {
    call: (payload) => addManagerToSegmentsAction(set)(payload),
    status: 'IDLE',
  },
  deleteManagerFromSegmentsAction: {
    call: (payload) => deleteManagerFromSegmentsAction(set)(payload),
    status: 'IDLE',
  },
  modifyContributorsFromSegmentAction: {
    call: (payload) => modifyContributorsFromSegmentAction(set)(payload),
    status: 'IDLE',
  },
  modifyManagersFromSegmentAction: {
    call: (payload) => modifyManagersFromSegmentAction(set)(payload),
    status: 'IDLE',
  },
  getManagersSegmentAction: {
    call: (payload) => getManagersSegmentAction(set)(payload),
    status: 'IDLE',
  },
  addContributorToSegmentAction: {
    call: (payload) => addContributorToSegmentAction(set)(payload),
    status: 'IDLE',
  },
  deleteContributorsFromSegmentAction: {
    call: (payload, callback) => deleteContributorsFromSegmentAction(set, get)(payload, callback),
    status: 'IDLE',
  },
  addManagerToSegmentAction: {
    call: (payload) => addManagerToSegmentAction(set)(payload),
    status: 'IDLE',
  },
  deleteManagersFromSegmentAction: {
    call: (payload, callback) => deleteManagersFromSegmentAction(set, get)(payload, callback),
    status: 'IDLE',
  },
  updateSegmentAction: {
    call: (payload) => updateSegmentAction(set)(payload),
    status: 'IDLE',
  },
  updateSocioDemographicSegmentAction: {
    call: (payload) => updateSocioDemographicSegmentAction(set)(payload),
    status: 'IDLE',
  },
  updateSkillSegmentAction: {
    call: (payload) => updateSkillSegmentAction(set)(payload),
    status: 'IDLE',
  },
  requestAccessSegmentAction: {
    call: (payload) => requestAccessSegmentAction(set)(payload),
    status: 'IDLE',
  },
})

export default segmentSlice
