import React, { PropsWithChildren, useMemo } from 'react'
import { hasAuthParams, useAuth } from 'react-oidc-context'
import { Button, LayoutContainer, Logo, Paragraph } from 'common-components'
import { PageLayout } from '@components/layout'
import { useTranslation } from '@hooks'
import { getWirkUrl } from '@definitions'

const ConsumerAuth = function (props: PropsWithChildren<unknown>) {
  const auth = useAuth()
  const { translate } = useTranslation()
  const logout = async () => {
    await auth.signoutSilent()
  }

  // automatically sign-in
  React.useEffect(() => {
    const { pathname } = window.location
    const tenant = pathname.split('/')[1]

    if (!tenant) {
      open(getWirkUrl('IdentityServer'), '_self')
      return
    }
    if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
      auth.signinRedirect()
    }
  }, [auth.isAuthenticated, auth.activeNavigator, auth.isLoading, auth.signinRedirect])

  const message = useMemo(() => {
    if (auth.activeNavigator === 'signoutSilent' || auth.activeNavigator === 'signinRedirect') {
      return translate('auth.signingIn')
    }

    if (auth.isLoading) {
      return translate('auth.loading')
    }

    if (auth.error) {
      return translate('auth.inactivityError')
    }
  }, [auth.activeNavigator, auth.isLoading, auth.isAuthenticated, auth.error])

  if (message) {
    return (
      <PageLayout>
        <LayoutContainer
          style={{
            minHeight: '100vh',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Logo preview={false} width={100} style={{ marginBottom: 20 }} />
          <Paragraph preset={'pageHeader'}>{message}</Paragraph>
          {!!auth.error && (
            <Button
              loading={auth.isLoading}
              type={'primary'}
              style={{ width: 'fit-content' }}
              //below onClick should be login. Otherwise, translate should do auth.signOutButton
              onClick={logout}
            >
              {translate('auth.signInButton')}
            </Button>
          )}
        </LayoutContainer>
      </PageLayout>
    )
  }

  return <div>{props.children}</div>
}

export default ConsumerAuth
