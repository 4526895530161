/** @jsxImportSource @emotion/react */

import { IText } from '@types'
import { css } from '@emotion/react'
import { Row, Col, Title, Space, Content } from 'common-components'
import { PropsWithChildren } from 'react'
import { useTranslation } from '@hooks'

type HeaderSegmentsPropsType = {
  idSegmentsSelectedCount: number
}

const HeaderSegments = ({
  idSegmentsSelectedCount,
  children,
}: PropsWithChildren<HeaderSegmentsPropsType>) => {
  const { translate } = useTranslation()

  const texts: IText[] = [
    {
      type: 'tablePageTitle',
      content:
        idSegmentsSelectedCount > 0
          ? `${idSegmentsSelectedCount} ${translate('segments.pageTitleSelected')}`
          : translate('segments.pageTitle'),
    },
    {
      type: 'tablePageSubtitle',
      content: translate('segments.pageDescription'),
    },
  ]
  return (
    <Content>
      <Row
        justify={'space-between'}
        align={'top'}
        css={css`
          margin: 24px 0;
        `}
      >
        <Col>
          {texts.map((text, index) => {
            return (
              <Title key={`title-${text.content}-${index}`} preset={text.type}>
                {text.content}
              </Title>
            )
          })}
        </Col>
        <Row>
          <Space>{children}</Space>
        </Row>
      </Row>
    </Content>
  )
}

export default HeaderSegments
