import { Content, Pills } from 'common-components'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from '@hooks'

import FrameWrapper from './FrameWrapper'

type AnalysisPropsType = {
  urlGlobal: string
  urlVariation: string
  urlDistribution: string
  urlDecomposition: string
  urlTracking: string
}

type PillType = 'global' | 'variation' | 'distribution' | 'decomposition' | 'tracking'

const Analysis = ({
  urlDistribution,
  urlTracking,
  urlDecomposition,
  urlVariation,
  urlGlobal,
}: AnalysisPropsType) => {
  const { translate } = useTranslation()
  const [width, setWidth] = useState(0)
  const ref = useRef<HTMLDivElement>()

  const [pillValue, setPillValue] = useState<PillType>('global')

  const OPTIONS_PILL: Readonly<{ value: PillType; label: string }>[] = [
    {
      value: 'global',
      label: translate('analysis.global'),
    },
    {
      value: 'variation',
      label: translate('analysis.variation'),
    },
    {
      value: 'distribution',
      label: translate('analysis.distribution'),
    },
    {
      value: 'decomposition',
      label: translate('analysis.decomposition'),
    },
    {
      value: 'tracking',
      label: translate('analysis.tracking'),
    },
  ]

  useEffect(() => {
    if (ref) setWidth(ref.current?.clientWidth)
  })

  return (
    <Content>
      <div
        ref={ref}
        style={{
          display: 'flex',
          flexDirection: 'row',
          margin: '24px 0',
          maxHeight: '55px',
        }}
      >
        <Pills
          options={OPTIONS_PILL}
          onChange={(e) => setPillValue(e.target.value)}
          value={pillValue}
          buttonProps={'' as any}
        />
      </div>
      <FrameWrapper url={urlGlobal} width={width} display={pillValue === 'global'} />
      <FrameWrapper url={urlVariation} width={width} display={pillValue === 'variation'} />
      <FrameWrapper url={urlDistribution} width={width} display={pillValue === 'distribution'} />
      <FrameWrapper url={urlDecomposition} width={width} display={pillValue === 'decomposition'} />
      <FrameWrapper url={urlTracking} width={width} display={pillValue === 'tracking'} />
    </Content>
  )
}

export default Analysis
