import { useEffect, useState } from 'react'
import { useDelayedEffect, useTranslation } from '@hooks'
import { PrefillMergeType, PrefillType } from '@definitions'
import { DataLengthTag, ItemWithAvatar } from '@components/display'
import { TableInput } from '@components/inputs'
import { IColumn } from '@types'
import { downloadSampleFileProject } from '@api/project'
import { ProjectType, TaskType } from '@pages/types'
import { ImportFilesModal } from '@components/modal'
import { getStateKeyByValue } from '@utils'
import {
  Button,
  Card,
  Col,
  colors,
  Row,
  Space,
  Tag,
  Text,
  Title,
  Toggle,
  useDisclosure,
} from 'common-components'
import { ID_COLUMN_HYDRATION } from '@views/shared/constants'
import { LoadingOutlined } from '@ant-design/icons'

import { store } from '@/store'

type Step4CreateProjectPropsType = {
  nextStep: () => void
  isLoadingNextStep: boolean
  project: ProjectType
}

const Step4CreateProject = ({
  nextStep,
  isLoadingNextStep,
  project,
}: Step4CreateProjectPropsType) => {
  const { translate } = useTranslation()

  const useDisclosureImportQuestionsModal = useDisclosure()

  const tasksDraft = store.use.tasksDraft()

  const getDraftTasksAction = store.use.getDraftTasksAction().call
  const createDraftTaskAction = store.use.createDraftTaskAction().call
  const updateDraftTaskAction = store.use.updateDraftTaskAction().call
  const deleteDraftTaskAction = store.use.deleteDraftTaskAction().call
  const attachTasksToProjectAction = store.use.attachTasksToProjectAction().call
  const uploadTasksFileProjectAction = store.use.uploadTasksFileProjectAction().call
  const uploadColumnFileTasksProjectAction = store.use.uploadColumnFileTasksProjectAction().call

  const isLoadingCreateDraftTaskAction = store.use.createDraftTaskAction().status === 'LOADING'
  const isLoadingUpdateDraftTaskAction = store.use.updateDraftTaskAction().status === 'LOADING'
  const isLoadingDeleteDraftTaskAction = store.use.deleteDraftTaskAction().status === 'LOADING'
  const isLoadingAttachTasksToProjectAction =
    store.use.attachTasksToProjectAction().status === 'LOADING'
  const isLoadingUploadTasksFileProjectAction =
    store.use.uploadTasksFileProjectAction().status === 'LOADING'
  const isLoadingUploadColumnFileTasksProjectAction =
    store.use.uploadColumnFileTasksProjectAction().status === 'LOADING'

  const [columns, setColumns] = useState<IColumn[]>([])

  const [refreshAvailable, setRefreshAvailable] = useState(false)
  const [refreshEnabled, setRefreshEnabled] = useState(true)

  const [errorsOnly, setErrorsOnly] = useState(false)

  useDelayedEffect(() => {
    getDraftTasksAction({ idProject: project.idProject })
  }, [project.idProject])

  const addColumnHydrationJson = () => {
    if (
      project?.prefillSettings?.type === getStateKeyByValue<PrefillType>(PrefillMergeType, 'manuel')
    ) {
      setColumns(
        project?.form?.columns.concat({
          name: ID_COLUMN_HYDRATION,
          type: 'hydration_json',
        }),
      )
    } else {
      setColumns(project?.form?.columns)
    }
  }

  useEffect(() => addColumnHydrationJson(), [JSON.stringify(project?.form?.columns)])

  const onTaskCreateMutation = async (data: {
    value?: string
    file?: string
    template: IColumn
  }) => {
    createDraftTaskAction(
      {
        idProject: project?.idProject,
        sla: project?.sla,
        name: project?.name,
        state: project?.state,
        form: {
          name: project?.form?.name,
          idForm: project?.form?.idForm,
          columns: project?.form?.columns.map(({ name, type, order }) => ({
            name,
            type,
            order,
          })),
          quality: {
            bestAnswerOnly: project?.form?.quality?.bestAnswerOnly,
            matchingCount: project?.form?.quality?.matchingCount,
            iterationCount: project?.form?.quality?.iterationCount,
            respondentPerTask: project?.form?.quality?.respondentPerTask,
            sampleMode: project?.form?.quality?.sampleMode,
            samplePercent: project?.form?.quality?.samplePercent,
            strategy: project?.form?.quality?.strategy,
            proofReadingCount: project?.form?.quality?.proofReadingCount,
            indeterminateMode: project?.form?.quality?.indeterminateMode,
          },
          allowError: project?.form?.allowError,
          comparisonRules: project?.form?.comparisonRules,
          fieldAssociation: project?.form?.fieldAssociation,
          averageDuration: project?.form?.averageDuration,
          fields: project?.form?.fields,
          hideIfRules: project?.form?.hideIfRules,
          ignoredFields: project?.form?.ignoredFields,
          iterationOverload: project?.form?.iterationOverload,
          nodes: project?.form?.nodes,
          validationRules: project?.form?.validationRules,
          hasReview: project?.form?.hasReview,
          hasSubdivide: project?.form?.hasSubdivide,
          keySkill: project?.form?.keySkill,
          minimumDuration: project?.form?.minimumDuration,
          prefillInputs: project?.form?.prefillInputs,
          subdivide: project?.form?.subdivide,
        },
        prefillSettings: project?.prefillSettings,
      },
      async (taskApi) => {
        await onTaskUpdateMutation({
          ...data,
          field: taskApi,
        })
      },
    )
  }

  const onTaskUpdateMutation = async (data: {
    value?: string
    file?: string
    field: TaskType
    template: IColumn
  }) => {
    updateDraftTaskAction({
      idProject: project?.idProject,
      idTask: data.field.idTask,
      columnName: data.template.name,
      file: data.file,
      type: data.template.type,
      value: data.value,
    })
  }

  const onPageSubmit = () => {
    attachTasksToProjectAction({ idProject: project?.idProject }, nextStep)
  }

  const onUploadTasksFileMutation = (file: File) => {
    uploadTasksFileProjectAction(
      {
        idProject: project?.idProject,
        file,
      },
      () => {
        setRefreshAvailable(true)
        getDraftTasksAction({ idProject: project.idProject })
        useDisclosureImportQuestionsModal.close()
      },
    )
  }

  const onRefresh = async () => {
    setRefreshEnabled(false)
    getDraftTasksAction({ idProject: project.idProject }, () => {
      setTimeout(() => setRefreshEnabled(true), 5000)
    })
  }

  const onColumnUpload = async (data: { file?: string; template: IColumn }) => {
    uploadColumnFileTasksProjectAction(
      {
        idProject: project.idProject,
        columnName: data.template.name,
        file: data.file,
      },
      () => {
        setRefreshAvailable(true)
        getDraftTasksAction({ idProject: project.idProject })
      },
    )
  }

  return (
    <Space direction='vertical' style={{ gap: 16 }}>
      <Card style={{ width: '100%', padding: 20, marginBottom: 20 }}>
        <ItemWithAvatar title={project?.form?.name}>
          <Space size={5}>
            {project?.form?.columns?.map((templateColumn) => (
              <Tag color={colors.pink700}>{templateColumn.name}</Tag>
            ))}
          </Space>
        </ItemWithAvatar>
      </Card>
      <Row align={'top'} justify={'space-between'} style={{ width: '100%' }}>
        <Col>
          <Space>
            <Title style={{ fontSize: 18 }}>
              {translate(`certificationCreation.questions.questionsTitle`)}
            </Title>
            <DataLengthTag>{tasksDraft?.length || 0}</DataLengthTag>
          </Space>
          <Title preset='tablePageSubtitle' style={{ fontSize: 14, marginBottom: 16 }}>
            {translate(`certificationCreation.questions.questionsSubtitle`)}
          </Title>
        </Col>

        <Space size={5} direction={'vertical'} align={'end'}>
          {refreshAvailable && (
            <Button
              disabled={!refreshEnabled}
              onClick={onRefresh}
              iconLeft={'RefreshCW01'}
              style={{ height: 48 }}
            >
              {translate(`certificationCreation.questions.refreshButtonLabel`)}
            </Button>
          )}
          <Button
            onClick={useDisclosureImportQuestionsModal.open}
            iconLeft={'UploadCloud01'}
            style={{ height: 48 }}
          >
            {translate(`certificationCreation.questions.uploadFile`)}
          </Button>
        </Space>
      </Row>
      <Row align={'middle'} justify={'space-between'} style={{ width: '100%' }}>
        <Space size={5}>
          <Toggle checked={errorsOnly} onChange={setErrorsOnly} />
          <Text>{translate(`certificationCreation.questions.errorsOnly`)}</Text>
        </Space>
        {/* HIDE
				<Button iconLeft={"Eraser"} type={"text"}>
					{translate(`certificationCreation.questions.eraseButtonLabel`)}
				</Button>*/}
      </Row>
      <TableInput<TaskType>
        keys={{
          rowKey: 'idTask',
          columnKey: 'columns',
        }}
        templateColumns={columns}
        onCreate={onTaskCreateMutation}
        onUpdate={onTaskUpdateMutation}
        onColumnUpload={onColumnUpload}
        onRowDelete={(idTask) =>
          deleteDraftTaskAction({
            idProject: project.idProject,
            idTask,
          })
        }
        data={
          errorsOnly
            ? tasksDraft?.filter((task) => task.columns.some((column) => column.hasError))
            : tasksDraft
        }
      />
      <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
        <Button
          type='primary'
          onClick={onPageSubmit}
          disabled={
            isLoadingNextStep ||
            isLoadingAttachTasksToProjectAction ||
            isLoadingDeleteDraftTaskAction ||
            isLoadingCreateDraftTaskAction ||
            isLoadingUploadColumnFileTasksProjectAction ||
            isLoadingUpdateDraftTaskAction
          }
          style={{
            width: '150px',
          }}
        >
          {isLoadingNextStep || isLoadingAttachTasksToProjectAction ? (
            <LoadingOutlined />
          ) : (
            translate('projectCreatePage.submitButton.step1')
          )}
        </Button>
      </Space>

      {/*Import Questions*/}
      {useDisclosureImportQuestionsModal.isOpen && (
        <ImportFilesModal
          useDisclosure={useDisclosureImportQuestionsModal}
          isLoading={isLoadingUploadTasksFileProjectAction}
          onFileUpload={onUploadTasksFileMutation}
          downloadSampleFile={async () => await downloadSampleFileProject(project.idProject)}
        />
      )}
    </Space>
  )
}

export default Step4CreateProject
