/** @jsxImportSource @emotion/react */
import { TAudienceCombinationType } from '@definitions/states'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useTranslation } from '@hooks/useTranslation'
import { PropsWithChildren } from 'react'
import { colors, SelectInput, SelectOption } from 'common-components'

type TreeWrapperPropsType = {
  defaultCombination: TAudienceCombinationType
  contentLength: number
  onSelectChange: (value: TAudienceCombinationType, groupIdx?: number) => void
  groupIdx?: number
}

const VerticalBranch = styled.div`
  position: relative;
  height: auto;
  width: 1.5px;
  border-right: 1px solid ${colors.gray200};
  margin: 24px 0 24px 22px;
`

const LeavesWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const optionValues: TAudienceCombinationType[] = [10, 20]

const TreeWrapper = ({
  defaultCombination,
  contentLength,
  onSelectChange,
  groupIdx,
  children,
}: PropsWithChildren<TreeWrapperPropsType>) => {
  const { translate } = useTranslation()

  const selectClassOverride = css`
    position: absolute;
    top: calc(50% - 11px);
    left: -21px;
    width: 42px !important;
    height: 22px;

    .ant-select-selector {
      padding: 0 6px !important;
      height: 22px !important;
      background: ${colors.gray50} !important;
    }

    input {
      height: 22px !important;
    }

    .ant-select-selection-item {
      line-height: 18px !important;
      font-size: 12px;
      font-weight: 500;
      padding-inline-end: 0px !important;
    }

    .ant-select-arrow {
      inset-inline-end: 3px !important;
      font-size: 10px !important;
    }
  `

  const optionClassOverride = css`
    background: red !important;

    .ant-select-item-option {
      background: red !important;
    }
  `

  return (
    <div style={{ display: 'flex' }}>
      {contentLength > 1 && (
        <VerticalBranch>
          <SelectInput
            css={selectClassOverride}
            defaultValue={defaultCombination}
            onChange={(value) => onSelectChange(value, groupIdx)}
          >
            {optionValues.map((option) => (
              <SelectOption
                key={option}
                value={option}
                css={optionClassOverride}
                style={{
                  padding: 5,
                  fontSize: 12,
                  fontWeight: 500,
                  minHeight: 22,
                }}
              >
                {translate(`project.audience.select.${option}`)}
              </SelectOption>
            ))}
          </SelectInput>
        </VerticalBranch>
      )}
      <LeavesWrapper>{children}</LeavesWrapper>
    </div>
  )
}

export default TreeWrapper
