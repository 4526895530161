import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { User } from 'oidc-client-ts'

import { getWirkUrl } from './wirk-urls'
import { oidcAuthority, oidcClientId } from './oidc-config'

export const getMeOidc = (): User => {
  const key = `oidc.user:${oidcAuthority}:${oidcClientId}`
  const oidcStorage = sessionStorage.getItem(key)
  if (!oidcStorage) {
    return
  }

  return User.fromStorageString(oidcStorage)
}

export const axiosClient = (
  config?: AxiosRequestConfig & { addBearer?: boolean },
): AxiosInstance => {
  const {
    baseURL = getWirkUrl('ApiGateway'),
    headers: customHeaders = {},
    timeout = 60 * 1000 * 5,
    addBearer = true,
  } = config || {}

  const headers: Headers = {
    'Content-Type': 'text/json; application/json; application/*+json',
    Accept: 'text/plain; text/json; application/json',
    ...customHeaders,
  }

  if (addBearer) {
    const user = getMeOidc()
    const authToken = user?.access_token
    // @ts-ignore
    headers['Authorization'] = `Bearer ${authToken}`
  }

  const client = axios.create({
    baseURL,
    headers,
    timeout,
    withCredentials: false,
  })

  client.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response
    },
    function (error) {
      return Promise.reject(error)
    },
  )

  return client
}
