import { memo } from 'react'
import { useTranslation } from '@hooks'
import { colors, IconAvatarGroup, ActionModal } from 'common-components'

import AssignCertification, { AssignCertificationPropsType } from './AssignCertification'

type AssignCertificationModalComponentPropsType = Pick<
  AssignCertificationPropsType,
  'onAddCertification' | 'onDeleteCertification' | 'isLoading' | 'certifications'
> & {
  managersCount?: number
  close: () => void
  isOpen: boolean
}

const AssignCertificationModalComponent = ({
  isLoading,
  close,
  isOpen,
  certifications,
  managersCount = 1,
  onDeleteCertification,
  onAddCertification,
}: AssignCertificationModalComponentPropsType) => {
  const { translate } = useTranslation()
  return (
    <ActionModal
      maskClosable={false}
      open={isOpen}
      onOk={close}
      customHeader={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IconAvatarGroup
            avatars={Array.from({ length: managersCount }, () => ({
              size: 'md',
              icon: 'Announcement02',
              iconColor: colors.success700,
              borderColor: colors.white,
              iconBackground: colors.gray100,
            }))}
          />
          <div>{translate('managers.assignCertification')}</div>
        </div>
      }
      onConfirm={close}
      onCancel={close}
    >
      <AssignCertification
        isLoading={isLoading}
        certifications={certifications}
        onAddCertification={onAddCertification}
        onDeleteCertification={onDeleteCertification}
      />
    </ActionModal>
  )
}

const AssignCertificationModal = memo(
  AssignCertificationModalComponent,
  (
    oldProps: AssignCertificationModalComponentPropsType,
    newProps: AssignCertificationModalComponentPropsType,
  ) => {
    return JSON.stringify(oldProps) === JSON.stringify(newProps)
  },
)

export default AssignCertificationModal
