/** @jsxImportSource @emotion/react */
import { useState } from 'react'
import { Content, useDisclosure, Button } from 'common-components'
import { useTranslation } from '@hooks'
import { GetSegmentsPayloadType } from '@api/segment'
import { SegmentType } from '@pages/types'
import { AssignManagerModal } from '@views/shared/manager'
import { Loader } from '@components/utils'
import { DeleteModal } from '@components/modal'
import { TableSegments, TableSegmentsPropsType } from '@views/shared/segment'

import FiltersSegments from './FiltersSegments'
import HeaderSegments from './HeaderSegments'
import { CreateSegmentModal } from './create'

import { store } from '@/store'

type ListSegmentPropsType = Pick<TableSegmentsPropsType, 'tableProps'> & {
  segments: SegmentType[]
  segmentsIsLoading: boolean
  onFiltersChange: (filters: GetSegmentsPayloadType) => void
}

const ListSegment = ({
  segments,
  segmentsIsLoading,
  onFiltersChange,
  tableProps = {},
}: ListSegmentPropsType) => {
  const requestAccessSegmentAction = store.use.requestAccessSegmentAction().call
  const modifyManagersFromSegmentAction = store.use.modifyManagersFromSegmentAction().call
  const modifyContributorsFromSegmentAction = store.use.modifyContributorsFromSegmentAction().call
  const deleteManagerFromSegmentsAction = store.use.deleteManagerFromSegmentsAction().call
  const downloadSegmentsAction = store.use.downloadSegmentsAction().call
  const deleteSegmentsAction = store.use.deleteSegmentsAction().call
  const addManagerToSegmentsAction = store.use.addManagerToSegmentsAction().call

  const isLoadingDeleteManagerFromSegmentsAction =
    store.use.deleteManagerFromSegmentsAction().status === 'LOADING'
  const isLoadingDownloadSegmentsAction = store.use.downloadSegmentsAction().status === 'LOADING'
  const isLoadingDeleteSegmentsAction = store.use.deleteSegmentsAction().status === 'LOADING'
  const isLoadingAddManagerToSegmentsAction =
    store.use.addManagerToSegmentsAction().status === 'LOADING'

  const { translate } = useTranslation()

  const [isFiltersVisible, setFiltersVisible] = useState(false)

  const useDisclosureAddManagersModal = useDisclosure()
  const useDisclosureCreateSegmentModal = useDisclosure()
  const useDisclosureDeleteSegmentsModal = useDisclosure()

  const [idSegmentsSelected, setIdSegmentsSelected] = useState<string[]>([])

  const onAccessRequest = (idSegment: string) => requestAccessSegmentAction({ idSegment })

  return (
    <Content>
      <HeaderSegments idSegmentsSelectedCount={idSegmentsSelected.length}>
        {/*** The actions on a single segment ***/}
        {!idSegmentsSelected.length && (
          <>
            <Button
              key='button-import-segments'
              disabled={isLoadingDownloadSegmentsAction}
              onClick={() =>
                downloadSegmentsAction({
                  filters: { model: {} },
                })
              }
              iconLeft='Download01'
            >
              {translate('managers.export')}
            </Button>

            <Button onClick={() => setFiltersVisible(true)} iconLeft='FilterLines'>
              {translate('common.filter')}
            </Button>

            <FiltersSegments
              visible={isFiltersVisible}
              onClose={() => setFiltersVisible(false)}
              onFiltersChange={onFiltersChange}
            />
            <Button
              key='button-add-segment'
              type='primary'
              onClick={useDisclosureCreateSegmentModal.open}
              iconLeft='Plus'
            >
              {translate('segments.headerButtons.create')}
            </Button>
          </>
        )}
        {/*** The actions on multiple segments ***/}
        {!!idSegmentsSelected.length && (
          <>
            <Button
              type='danger'
              onClick={useDisclosureDeleteSegmentsModal.open}
              iconLeft='Trash01'
            >
              {translate('segments.deleteSegment.button')}
            </Button>
            <Button
              key='button-add-manager'
              type='primary'
              onClick={useDisclosureAddManagersModal.open}
              iconLeft='ShieldPlus'
            >
              {translate('segments.headerButtons.add')}
            </Button>
          </>
        )}
      </HeaderSegments>
      {segmentsIsLoading && <Loader />}
      {!segmentsIsLoading && (
        <TableSegments
          isLoadingManagersMutation={
            isLoadingAddManagerToSegmentsAction || isLoadingDeleteManagerFromSegmentsAction
          }
          onAddManagerSegment={(idSegment, managerShort) =>
            addManagerToSegmentsAction({
              idSegments: [idSegment],
              managerShort,
            })
          }
          onDeleteManagerSegment={(idSegment, managerShort) =>
            deleteManagerFromSegmentsAction({
              managerShort,
              idSegments: [idSegment],
            })
          }
          segments={segments}
          setSelectedCheckboxes={setIdSegmentsSelected}
          selectedCheckboxes={idSegmentsSelected}
          onAccessRequest={onAccessRequest}
          tableProps={tableProps}
        />
      )}
      {useDisclosureAddManagersModal.isOpen && (
        <AssignManagerModal
          onAddManager={(managerShort) =>
            addManagerToSegmentsAction({
              idSegments: idSegmentsSelected,
              managerShort,
            })
          }
          onDeleteManager={(managerShort) =>
            deleteManagerFromSegmentsAction({
              managerShort,
              idSegments: idSegmentsSelected,
            })
          }
          isLoading={
            isLoadingAddManagerToSegmentsAction || isLoadingDeleteManagerFromSegmentsAction
          }
          close={useDisclosureAddManagersModal.close}
          isOpen={useDisclosureAddManagersModal.isOpen}
        />
      )}
      {useDisclosureCreateSegmentModal.isOpen && (
        <CreateSegmentModal
          isOpen={useDisclosureCreateSegmentModal.isOpen}
          close={() => {
            useDisclosureCreateSegmentModal.close()
            setIdSegmentsSelected([])
            // setMainCheckbox(false)
          }}
          onUpdateContributorsSegmentMutation={(idSegment, contributorsShort) =>
            modifyContributorsFromSegmentAction({
              contributorsShort,
              idSegment,
            })
          }
          onUpdateManagersSegmentMutation={(idSegment, managersShort) =>
            modifyManagersFromSegmentAction({
              idSegment,
              managersShort,
            })
          }
        />
      )}

      {useDisclosureDeleteSegmentsModal.isOpen && (
        <DeleteModal
          close={useDisclosureDeleteSegmentsModal.close}
          isOpen={useDisclosureDeleteSegmentsModal.isOpen}
          isLoading={isLoadingDeleteSegmentsAction}
          title={`${translate('segments.deleteSegment.modal.subTitle1')} ${
            idSegmentsSelected.length
          } ${
            idSegmentsSelected.length === 1
              ? translate('segments.deleteSegment.modal.subTitle2')
              : translate('segments.deleteSegment.modal.subTitle3')
          }
              `}
          label={translate(`segments.deleteSegment.modal.description`)}
          onDeleteMutation={async () =>
            deleteSegmentsAction({ idSegments: idSegmentsSelected }, () => {
              setIdSegmentsSelected([])
              setTimeout(() => useDisclosureDeleteSegmentsModal.close(), 200)
            })
          }
        />
      )}
    </Content>
  )
}

export default ListSegment
