/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useTranslation } from '@hooks'
import { colors, Icon, ITextInputProps, Space, TextInput } from 'common-components'

const SearchInput = ({ ...rest }: ITextInputProps) => {
  const { translate } = useTranslation()
  return (
    <TextInput
      prefix={<Icon width={15} height={15} color={colors.gray500} icon='SearchSm' />}
      placeholder={translate('display.search')}
      css={css`
        height: 45px !important;
        padding: 0px 10px;
      `}
      size='large'
      allowClear={{
        clearIcon: (
          <Space>
            <Icon width={18} height={18} icon='XClose' />
          </Space>
        ),
      }}
      {...rest}
    />
  )
}

export default SearchInput
