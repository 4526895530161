import {
  CertificationShortType,
  UserDataType,
  ContributorShortType,
  ManagerShortType,
} from '@pages/types'
import dayjs from 'dayjs'
import moment from 'moment'
import { ID_MANAGER_RIGHT } from '@definitions'
import { UserApiType, CertificationShortApiType, UserShortApiType } from '@api/types'

import { removeNullAndUndefinedMapper } from './helper.mapper'

export const toDateJsDatePicker = (dateApi: string) => {
  const date = moment(dateApi).format('DD/MM/YY')
  return dayjs(date, 'DD/MM/YY')
}

export const userDataApiMapper = (userApi: UserApiType): UserDataType => {
  try {
    const result: UserDataType = {
      idUser: userApi.idUser,
      isDeleted: userApi?.isDeleted,
      lastName: userApi?.lastName,
      firstName: userApi?.firstName,
      phone: userApi?.phone,
      urlAvatar: userApi?.urlAvatar || '',
      type: userApi?.type,
      blacklistedProject: userApi?.blacklistedProject || [],
      address: userApi?.address,
      creationDate: userApi?.creationDate,
      updateDate: userApi?.updateDate,
      email: userApi?.email,
      averageQuality: userApi?.averageQuality,
      effectiveTime: userApi?.effectiveTime,
      nationality: userApi?.nationality,
      activity: userApi?.activity,
      activitySector: userApi?.activitySector,
      earning: userApi?.earning,
      study: userApi?.study,
      family: userApi?.family,
      children: userApi?.children,
      job: userApi?.job,
    }
    return {
      ...removeNullAndUndefinedMapper<UserDataType>(result),
      birthDate: userApi?.birthDate && toDateJsDatePicker(userApi?.birthDate),
    }
  } catch (e) {
    throw e
  }
}

export const certificationShortApiMapper = (
  userCertificationApi: CertificationShortApiType,
): CertificationShortType => {
  try {
    const result: CertificationShortType = {
      idCertification: userCertificationApi.idCertification,
      icon: userCertificationApi.icon,
      name: userCertificationApi.name,
      managerRight: userCertificationApi.managerRight || ID_MANAGER_RIGHT[2],
    }

    return removeNullAndUndefinedMapper<CertificationShortType>(result)
  } catch (e) {
    throw e
  }
}

export const contributorShortApiMapper = (
  userShortApiType: UserShortApiType,
): ContributorShortType => {
  try {
    const result = {
      idUser: userShortApiType.idUser,
      email: userShortApiType.email,
      type: userShortApiType.type,
      mark: userShortApiType?.mark,
      lastName: userShortApiType?.lastName,
      firstName: userShortApiType?.firstName,
      phone: userShortApiType?.phone,
      urlAvatar: userShortApiType?.urlAvatar,
    }

    return removeNullAndUndefinedMapper<ContributorShortType>(result)
  } catch (e) {
    throw e
  }
}

export const managerShortApiMapper = (userShortApiType: UserShortApiType): ManagerShortType => {
  try {
    const result = {
      idUser: userShortApiType.idUser,
      email: userShortApiType.email,
      type: userShortApiType.type,
      lastName: userShortApiType?.lastName,
      firstName: userShortApiType?.firstName,
      phone: userShortApiType?.phone,
      urlAvatar: userShortApiType?.urlAvatar,
      managerRight: userShortApiType?.managerRight,
    }

    return removeNullAndUndefinedMapper<ManagerShortType>(result)
  } catch (e) {
    throw e
  }
}
