/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import {
  Avatar,
  Checkbox,
  Icon,
  Text,
  Row,
  Space,
  Table,
  Dropdown,
  colors,
  IconAvatarGroup,
  CommonIconAvatar,
  useDisclosure,
  ITableProps,
} from 'common-components'
import { useTranslation } from 'react-i18next'
import { useMemo, useCallback, useRef, useState } from 'react'
import { capitalizeFirstLetter } from '@utils/capitalize-first'
import { StatusTagUser } from '@views/shared/user'
import {
  ManagerType,
  CertificationShortType,
  SegmentShortType,
  ContributorType,
} from '@pages/types'
import { IdManagerRightType, UserType } from '@definitions'
import { ItemType } from 'antd/es/menu/hooks/useItems'
import DateUtils from '@helpers/date'
import { DeleteModal } from '@components/modal'
import { ColumnsTableType } from '@types'
import { CreateUpdateFormManagerType } from '@views/shared/manager'
import { AssignSegmentRightModal } from '@views/shared/segment'
import { AssignCertificationModal } from '@views/shared/certification'

import UpdateManagerModal from './UpdateManagerModal'

type ColumnTableType =
  | 'checkbox'
  | 'name'
  | 'yearsOfExperience'
  | 'role'
  | 'segments'
  | 'certifications'
  | 'status'
  | 'action'

export type TableManagersPropsType = {
  managers: ManagerType[]
  setSelectedCheckboxes: (checkboxes: string[]) => void
  selectedCheckboxes: string[]
  tableProps?: Omit<ITableProps<ManagerType>, 'data' | 'columns'>
  // segment
  isLoadingSegmentsMutation?: boolean
  onAddSegment?: (
    segmentShort: SegmentShortType,
    idManager: string,
    managerRight: IdManagerRightType,
  ) => void
  onDeleteSegment?: (idSegment: string, idManager: string) => void
  // certification
  isLoadingCertificationsMutation?: boolean
  onAddCertification?: (
    certificationShort: CertificationShortType,
    idManager: string,
    managerRight: IdManagerRightType,
  ) => void
  onDeleteCertification?: (idCertification: string, idManager: string) => void
  // delete
  isLoadingDeleteUserMutation?: boolean
  onDeleteUserMutation?: (idUser: string) => () => Promise<void>
  // manager
  onUpdateManager?: (managerForm: CreateUpdateFormManagerType) => Promise<void>
  isLoadingUpdateManager?: boolean
  //
  additionalColumnsTable?: ColumnTableType[]
}

const getSegments = (managers: ManagerType[], idManager: string): SegmentShortType[] | [] => {
  const segmentsCurrent = managers.find((item) => item.userData.idUser === idManager)?.segmentsShort
  if (!Array.isArray(segmentsCurrent)) {
    return []
  }
  return segmentsCurrent
}

const getCertifications = (
  managers: ManagerType[],
  idManager: string,
): CertificationShortType[] | [] => {
  const certificationsCurrent = managers.find(
    (item) => item.userData.idUser === idManager,
  )?.certificationsShort
  if (!Array.isArray(certificationsCurrent)) {
    return []
  }
  return certificationsCurrent
}

const TableManagers = ({
  managers,
  setSelectedCheckboxes,
  selectedCheckboxes,
  tableProps = {},
  isLoadingSegmentsMutation,
  onAddSegment,
  onDeleteSegment,
  isLoadingCertificationsMutation,
  onAddCertification,
  onDeleteCertification,
  isLoadingDeleteUserMutation,
  onDeleteUserMutation,
  onUpdateManager,
  isLoadingUpdateManager,
  additionalColumnsTable = [],
}: TableManagersPropsType) => {
  const [mainCheckbox, setMainCheckbox] = useState<boolean>()
  const { t } = useTranslation()
  const assignSegmentsRightModalUseDisclosure = useDisclosure()
  const assignCertificationModalUseDisclosure = useDisclosure()
  const updateManagerUseDisclosure = useDisclosure()
  const deleteDisclosure = useDisclosure()
  const idManagerClicked = useRef<string>()

  const getIconData = useCallback((record: ManagerType): ItemType[] => {
    return [
      {
        key: 1,
        label: <Text>{t('managers.edit')}</Text>,
        icon: <Icon icon={'Edit01'} size={16} />,
        onClick: () => {
          idManagerClicked.current = record.userData.idUser
          updateManagerUseDisclosure.open()
        },
      },
      {
        key: 2,
        label: <Text>{t('managers.assignSegment')}</Text>,
        icon: <Icon icon={'Target02'} size={16} />,
        onClick: () => {
          idManagerClicked.current = record.userData.idUser
          assignSegmentsRightModalUseDisclosure.open()
        },
      },
      {
        key: 3,
        label: <Text>{t('managers.assignCertification')}</Text>,
        icon: <Icon icon={'CheckVerified03'} size={16} />,
        onClick: () => {
          idManagerClicked.current = record.userData.idUser
          assignCertificationModalUseDisclosure.open()
        },
      },
      {
        type: 'divider',
      },
      {
        key: 4,
        label: <Text style={{ color: colors.error500 }}>{t('managers.delete')}</Text>,
        icon: <Icon icon={'Trash01'} color={colors.error500} size={16} />,
        onClick: () => {
          idManagerClicked.current = record.userData.idUser
          deleteDisclosure.open()
        },
      },
    ]
  }, [])

  const handleCheckboxClick = (id: string) => {
    if (selectedCheckboxes.includes(id)) {
      setSelectedCheckboxes(selectedCheckboxes.filter((checkboxId: string) => id !== checkboxId))
      return
    }
    setSelectedCheckboxes([...selectedCheckboxes, id])
  }

  const handleMainCheckboxClick = () => {
    setMainCheckbox(!mainCheckbox)
    if (mainCheckbox) {
      setSelectedCheckboxes([])
      return
    }
    const allIds = managers.map((record: ManagerType) => record.userData.idUser)
    setSelectedCheckboxes(allIds)
  }

  const tableColumns = useMemo(() => {
    try {
      const columnsTable: ColumnsTableType<ContributorType, ColumnTableType>[] = [
        {
          title: (
            <Checkbox name='checkbox' checked={mainCheckbox} onClick={handleMainCheckboxClick} />
          ),
          key: 'checkbox',
          render: (record: ManagerType) => (
            <Checkbox
              name='checkbox'
              checked={selectedCheckboxes.includes(record.userData.idUser)}
              onClick={() => handleCheckboxClick(record.userData.idUser)}
            />
          ),
        },
        {
          title: t('managers.name'),
          key: 'name',
          sorter: (a: ManagerType, b: ManagerType) => {
            const name1 = `${a.userData.firstName} ${a.userData.lastName}`
            const name2 = `${b.userData.firstName} ${b.userData.lastName}`
            return name1.localeCompare(name2)
          },
          render: (record: ManagerType) => (
            <Row align={'middle'}>
              <Space size={'middle'}>
                <Avatar src={record.userData.urlAvatar} />
                <Text strong>{`${record.userData.firstName} ${record.userData.lastName}`}</Text>
              </Space>
            </Row>
          ),
        },
        /* HIDE
        {
					title: t("segment.usersTableColumns.time"),
					key: "effectiveTime",
					dataIndex: "effectiveTime",
					sorter: (a: { temps: string }, b: { temps: string }) => {
						if (a.temps < b.temps) return -1;
						if (a.temps > b.temps) return 1;
						return 0;
					},
					render: (temps: string) => <Row align={"middle"}>{temps}</Row>,
				},
         */
      ]

      if (additionalColumnsTable?.includes('yearsOfExperience')) {
        columnsTable.push({
          title: t('managers.old'),
          key: 'yearsOfExperience',
          sorter: (a: ManagerType, b: ManagerType) =>
            DateUtils.getYears(a.userData.creationDate) -
            DateUtils.getYears(b.userData.creationDate),
          render: (record: ManagerType) => {
            return (
              <Row align={'middle'}>
                <Text size='xs'>{DateUtils.getYears(record.userData.creationDate)} ans</Text>
              </Row>
            )
          },
        })
      }

      columnsTable.push({
        title: t('managers.role'),
        key: 'role',
        render: (record: ManagerType) => (
          <Row align={'middle'}>
            <Space size={'middle'}>
              <Text size='sm'>
                {capitalizeFirstLetter(t(`managers.${UserType[record.userData.type]}`))}
              </Text>
            </Space>
          </Row>
        ),
      })

      if (additionalColumnsTable?.includes('segments')) {
        columnsTable.push({
          title: t('managers.segments'),
          key: 'segments',
          sorter: (a: ManagerType, b: ManagerType) => {
            if (a.segmentsShort < b.segmentsShort) return -1
            if (a.segmentsShort > b.segmentsShort) return 1
            return 0
          },
          render: (record: ManagerType) => (
            <Row align='middle'>
              {!!record.segmentsShort && (
                <IconAvatarGroup
                  avatars={record?.segmentsShort.map((segment) => {
                    return {
                      size: 'md',
                      icon: 'Scale02',
                      iconColor: colors.success500,
                      borderColor: colors.white,
                      iconBackground: segment?.icon?.iconColor,
                    }
                  })}
                  maxCount={2}
                />
              )}
              <CommonIconAvatar
                // @ts-ignore
                css={css`
                  margin-left: 10px;
                  cursor: pointer;
                  border: 1px dashed ${colors.gray300} !important;
                `}
                onClick={() => {
                  idManagerClicked.current = record.userData.idUser
                  assignSegmentsRightModalUseDisclosure.open()
                }}
                iconBackground={colors.white}
                borderColor={colors.gray300}
                icon={'Plus'}
              />
            </Row>
          ),
        })
      }

      if (additionalColumnsTable?.includes('certifications')) {
        columnsTable.push({
          title: t('managers.certifications'),
          key: 'certifications',
          sorter: (a: ManagerType, b: ManagerType) => {
            if (a.certificationsShort < b.certificationsShort) return -1
            if (a.certificationsShort > b.certificationsShort) return 1
            return 0
          },
          render: (record: ManagerType) => (
            <Row align={'middle'}>
              {!!record.certificationsShort?.length && (
                <IconAvatarGroup
                  avatars={record?.certificationsShort.map((certification) => {
                    return {
                      size: 'md',
                      icon: 'Announcement02',
                      iconColor: colors.success500,
                      borderColor: colors.white,
                      iconBackground: certification?.icon?.iconColor,
                    }
                  })}
                />
              )}
              <CommonIconAvatar
                // @ts-ignore
                css={css`
                  margin-left: 10px;
                  cursor: pointer;
                  border: 1px dashed ${colors.gray300} !important;
                `}
                onClick={() => {
                  idManagerClicked.current = record.userData.idUser
                  assignCertificationModalUseDisclosure.open()
                }}
                iconBackground={colors.white}
                borderColor={colors.gray300}
                icon={'Plus'}
              />
            </Row>
          ),
        })
      }

      if (additionalColumnsTable?.includes('status')) {
        columnsTable.push({
          title: t('managers.status'),
          key: 'status',
          render: (record: ManagerType) => (
            <Row align={'middle'}>
              {/*TODO remove any*/}
              <StatusTagUser status={record.userData.activity as any} />
            </Row>
          ),
        })
      }

      if (additionalColumnsTable?.includes('action')) {
        columnsTable.push({
          title: t('managers.actions'),
          key: 'action',
          render: (record: ManagerType) => (
            <Dropdown
              menu={{
                items: getIconData(record),
              }}
              placement={'bottomRight'}
              overlayStyle={{ width: 240 }}
            >
              <Row align={'middle'}>
                <Space size={'middle'}>
                  <Icon
                    css={css`
                      cursor: pointer;
                    `}
                    size={20}
                    color={colors.gray400}
                    icon={'DotsVertical'}
                  />
                </Space>
              </Row>
            </Dropdown>
          ),
        })
      }

      return columnsTable
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
      return []
    }
  }, [mainCheckbox, selectedCheckboxes, managers.length])

  return (
    <>
      <Table<ManagerType>
        css={css`
          margin-top: 10px;
        `}
        data={managers}
        columns={tableColumns}
        {...tableProps}
      />
      {/*** SEGMENTS ***/}
      {assignSegmentsRightModalUseDisclosure.isOpen && (
        <AssignSegmentRightModal
          key='assign-segments-right-modal'
          isLoading={isLoadingSegmentsMutation}
          close={assignSegmentsRightModalUseDisclosure.close}
          isOpen={assignSegmentsRightModalUseDisclosure.isOpen}
          segments={getSegments(managers, idManagerClicked.current)}
          onAddSegment={(segmentShort, managerRight) =>
            onAddSegment && onAddSegment(segmentShort, idManagerClicked.current, managerRight)
          }
          onDeleteSegment={(idSegment) =>
            onDeleteSegment && onDeleteSegment(idSegment, idManagerClicked.current)
          }
        />
      )}
      {/*** CERTIFICATIONS ***/}
      {assignCertificationModalUseDisclosure.isOpen && (
        <AssignCertificationModal
          isLoading={isLoadingCertificationsMutation}
          certifications={getCertifications(managers, idManagerClicked.current)}
          isOpen={assignCertificationModalUseDisclosure.isOpen}
          close={assignCertificationModalUseDisclosure.close}
          onAddCertification={(certificationShort, managerRight) =>
            onAddCertification &&
            onAddCertification(certificationShort, idManagerClicked.current, managerRight)
          }
          onDeleteCertification={(idCertification) =>
            onDeleteCertification &&
            onDeleteCertification(idCertification, idManagerClicked.current)
          }
        />
      )}
      {/*** USER ***/}
      {updateManagerUseDisclosure.isOpen && (
        <UpdateManagerModal
          onUpdateManager={onUpdateManager}
          isOpen={updateManagerUseDisclosure.isOpen}
          close={updateManagerUseDisclosure.close}
          managerUser={
            managers?.find((manager) => manager?.userData?.idUser === idManagerClicked.current)
              ?.userData
          }
          isLoading={isLoadingUpdateManager}
        />
      )}
      {deleteDisclosure.isOpen && (
        <DeleteModal
          isLoading={isLoadingDeleteUserMutation}
          close={deleteDisclosure.close}
          isOpen={deleteDisclosure.isOpen}
          title={t(`managers.deleteModal.title`)}
          label={t(`managers.deleteModal.label`)}
          onDeleteMutation={onDeleteUserMutation && onDeleteUserMutation(idManagerClicked.current)}
        />
      )}
    </>
  )
}

export default TableManagers
