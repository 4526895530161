import './i18n/i18n'
import '@definitions/styles/global.css'
import { QueryClient, QueryClientProvider } from 'react-query'
import { StyleConfigProvider } from 'common-components'
import { ConsumerAuth, ProviderAuth } from '@components/auth'
import AppRouter from '@navigation/AppRouter'

function App(): JSX.Element {
  const queryClient = new QueryClient()
  return (
    <StyleConfigProvider>
      <QueryClientProvider client={queryClient}>
        <div className='App'>
          <ProviderAuth>
            <ConsumerAuth>
              <AppRouter />
            </ConsumerAuth>
          </ProviderAuth>
        </div>
      </QueryClientProvider>
    </StyleConfigProvider>
  )
}

export default App
