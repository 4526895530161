/** @jsxImportSource @emotion/react */

import styled from '@emotion/styled'
import { ResponsiveContainer, BarChart, Bar } from 'recharts'
import { css } from '@emotion/react'
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router'
import { routes } from '@navigation/routes'
import { ProjectType } from '@pages/types'
import {
  Avatar,
  Col,
  colors,
  HoverCard,
  Icon,
  IHoverCardProps,
  Paragraph,
  Row,
  Space,
  spacing,
  Text,
} from 'common-components'

type CardProjectPropsType = IHoverCardProps & {
  project: ProjectType
}

const ContentContainer = styled(Col)`
  padding: ${spacing.padding.card.default}px;
`
const FooterContainer = styled(Row)`
  width: 100%;
  height: 56px;
`
const HoverContentContainer = styled(Row)`
  width: 100%;
`
const CardProject = function ({ project, ...rest }: CardProjectPropsType) {
  const navigate = useNavigate()

  const data = [
    {
      name: 'Page A',
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: 'Page B',
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: 'Page C',
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: 'Page D',
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: 'Page E',
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: 'Page F',
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: 'Page G',
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ]

  const Graph = () => (
    <Row
      css={css`
        padding-top: 10px;
      `}
    >
      <ResponsiveContainer width='100%' height={40}>
        <BarChart width={150} height={40} data={[...data, ...data, ...data]}>
          <Bar dataKey='uv' fill='#8884d8' strokeOpacity={0} radius={5} />
        </BarChart>
      </ResponsiveContainer>
    </Row>
  )

  const StatDot = useCallback(
    (props: React.CSSProperties & { size: number }) => {
      return (
        <Row>
          <div
            style={{
              width: props.size * 2 + 9,
              height: props.size * 2 + 9,
              borderRadius: '100%',
              borderStyle: 'solid',
              borderWidth: props.size,
              ...props,
            }}
          />
        </Row>
      )
    },
    [project],
  )
  return (
    <HoverCard
      style={{ width: 290 }}
      ContentComponent={
        <ContentContainer>
          <Paragraph>{project.name}</Paragraph>
          <Text>{project.updateDate}</Text>
          <Graph />
        </ContentContainer>
      }
      footerContent={
        <FooterContainer align={'middle'} justify={'space-between'}>
          {/*FIXME: no avatar data*/}
          <Avatar />
          <Row>
            <Space size={5}>
              <Icon icon={'FileQuestion02'} color={colors.gray400} size={16} />
              <Text size='xs'>{project.nbTasks}</Text>
            </Space>
            <Space size={5}>
              <Icon icon={'UsersEdit'} color={colors.gray400} size={16} />
              <Text size='xs'>{project.nbActiveSupplier}</Text>
            </Space>
            <Space size={5}>
              <Icon icon={'AlertHexagon'} color={colors.gray400} size={16} />
              <Text size='xs'>{project.nbAlerts}</Text>
            </Space>
          </Row>
        </FooterContainer>
      }
      hoverContent={
        <HoverContentContainer
          align={'bottom'}
          justify={'space-evenly'}
          style={{ paddingBottom: 10 }}
        >
          <Space direction={'vertical'} size={5} align={'center'}>
            <StatDot
              backgroundColor={colors.lightBlue500}
              size={20}
              borderColor={colors.lightBlue100}
            />
            <Text>En atente</Text>
            <Text>1921</Text>
          </Space>
          <Space direction={'vertical'} size={5} align={'center'}>
            <StatDot backgroundColor={colors.success500} size={9} borderColor={colors.success100} />
            <Text>En course</Text>
            <Text>1921</Text>
          </Space>
          <Space direction={'vertical'} size={5} align={'center'}>
            <StatDot backgroundColor={colors.warning500} size={5} borderColor={colors.warning100} />
            <Text>Urgente</Text>
            <Text>1921</Text>
          </Space>
          <Space direction={'vertical'} size={5} align={'center'}>
            <StatDot backgroundColor={colors.error500} size={0} borderColor={colors.error100} />
            <Text>En retard</Text>
            <Text>1921</Text>
          </Space>
        </HoverContentContainer>
      }
      hoverFooterContent={
        <HoverContentContainer align={'middle'} justify={'center'}>
          <Row>
            <Space
              size={5}
              style={{ cursor: 'pointer' }}
              onClick={() => navigate(routes.projectDashboard.navigate(project.idProject))}
            >
              <Icon icon={'ClockStopwatch'} color={colors.white} size={16} />
              <Text color={colors.white} size='xs'>
                {project.spendTime}
              </Text>
            </Space>
            <Space
              size={5}
              style={{ cursor: 'pointer' }}
              onClick={() => navigate(routes.projectTasks.navigate(project.idProject))}
            >
              <Icon icon={'FileQuestion02'} color={colors.white} size={16} />
              <Text color={colors.white} size='xs'>
                {project.nbTasks}
              </Text>
            </Space>
            <Space
              size={5}
              style={{ cursor: 'pointer' }}
              onClick={() => navigate(routes.projectAudience.navigate(project.idProject))}
            >
              <Icon icon={'UsersEdit'} color={colors.white} size={16} />
              <Text color={colors.white} size='xs'>
                {project.nbActiveSupplier}
              </Text>
            </Space>
          </Row>
        </HoverContentContainer>
      }
      {...rest}
    />
  )
}

export default CardProject
