import { ItemWithAvatar } from '@components/display'
import { Space, Tag, colors } from 'common-components'
import { FC } from 'react'
import { FormType } from '@pages/types'

type SelectedFormCardPropsType = {
  templateFrom: FormType['template']['template']
}

const CardFormSelected: FC<SelectedFormCardPropsType> = ({ templateFrom }) => {
  return (
    <Space
      style={{
        border: `1px solid ${colors.gray200}`,
        padding: 16,
        borderRadius: 12,
        width: '100%',
      }}
    >
      <ItemWithAvatar title={templateFrom?.name}>
        <Space size={5}>
          {templateFrom?.data?.map((item) => (
            <Tag color={colors.pink700}>{item?.field}</Tag>
          ))}
        </Space>
      </ItemWithAvatar>
    </Space>
  )
}

export default CardFormSelected
