import { LoadingOutlined } from '@ant-design/icons'

const Loader = () => (
  <div
    style={{
      display: 'flex',
      margin: '100px',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <LoadingOutlined style={{ fontSize: '30px' }} />
  </div>
)

export default Loader
