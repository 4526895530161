/** @jsxImportSource @emotion/react */

import { PageLayout } from '@components/layout'
import { useRequestParams, useTranslation } from '@hooks'
import { Content, useDisclosure, Button } from 'common-components'
import { useEffect, useState } from 'react'
import { AssignSegmentModal, TableSegments } from '@views/shared/segment'
import { Loader } from '@components/utils'
import { ContributorType, ManagerShortType, SegmentType } from '@pages/types'
import { GetSegmentsPayloadType } from '@api'

import { SubHeaderContributor } from '../shared'

import { store } from '@/store'

type SegmentsContributorPropsType = {
  contributor: ContributorType
}

const SegmentsContributor = ({ contributor }: SegmentsContributorPropsType) => {
  const idContributor = contributor.userData.idUser
  const segments = store.use.segmentsContributor()

  const getSegmentsContributorAction = store.use.getSegmentsContributorAction().call
  const deleteManagerFromSegmentContributorAction =
    store.use.deleteManagerFromSegmentContributorAction().call
  const addManagerToSegmentContributorAction = store.use.addManagerToSegmentContributorAction().call
  const addSegmentToContributorAction = store.use.addSegmentToContributorAction().call
  const deleteSegmentFromContributorAction = store.use.deleteSegmentFromContributorAction().call

  const isLoadingGetSegmentsContributorAction =
    store.use.getSegmentsContributorAction().status === 'LOADING'
  const isLoadingDeleteManagerFromSegmentContributorAction =
    store.use.deleteManagerFromSegmentContributorAction().status === 'LOADING'
  const isLoadingAddManagerToSegmentContributorAction =
    store.use.addManagerToSegmentContributorAction().status === 'LOADING'
  const isLoadingAddSegmentToContributorAction =
    store.use.addSegmentToContributorAction().status === 'LOADING'
  const isLoadingDeleteSegmentFromContributorAction =
    store.use.deleteSegmentFromContributorAction().status === 'LOADING'

  const { afterPageChange, pagination, onTableStateChange } = useRequestParams<
    GetSegmentsPayloadType,
    SegmentType
  >({
    initParams: { model: {} },
  })

  const [idSegmentsSelected, setIdSegmentsSelected] = useState<string[]>([])

  useEffect(() => {
    getSegmentsContributorAction({ idContributor })
  }, [idContributor])

  useEffect(() => {
    afterPageChange(segments?.length)
  }, [segments?.length])

  const useDisclosureAddSegmentsModal = useDisclosure()
  const { translate } = useTranslation()

  const onAddManagerSegmentMutation = async (idSegment: string, manager: ManagerShortType) => {
    addManagerToSegmentContributorAction({
      idContributor,
      idSegment,
      manager: {
        idUser: manager.idUser,
        email: manager.email,
        firstName: manager.firstName,
        lastName: manager.lastName,
        type: manager.type,
        urlAvatar: manager.urlAvatar,
        managerRight: manager.managerRight,
      },
    })
  }

  const onDeleteManagerSegmentMutation = async (idSegment: string, manager: ManagerShortType) => {
    deleteManagerFromSegmentContributorAction({
      idContributor,
      idSegment,
      manager: {
        idUser: manager.idUser,
        email: manager.email,
        firstName: manager.firstName,
        lastName: manager.lastName,
        type: manager.type,
        urlAvatar: manager.urlAvatar,
        managerRight: manager.managerRight,
      },
    })
  }

  return (
    <PageLayout>
      <Content>
        <SubHeaderContributor
          texts={[
            {
              type: 'tablePageTitle',
              content: translate('segments.pageTitle'),
            },
          ]}
          count={segments.length}
        >
          <Button onClick={useDisclosureAddSegmentsModal.open} type='default' iconLeft='Plus'>
            Add Segment
          </Button>
        </SubHeaderContributor>
        {isLoadingGetSegmentsContributorAction && <Loader />}
        {!isLoadingGetSegmentsContributorAction && (
          <TableSegments
            isLoadingManagersMutation={
              isLoadingAddManagerToSegmentContributorAction ||
              isLoadingDeleteManagerFromSegmentContributorAction
            }
            onAddManagerSegment={(idSegment, managers) =>
              onAddManagerSegmentMutation(idSegment, managers)
            }
            onDeleteManagerSegment={(idSegment, manager) =>
              onDeleteManagerSegmentMutation(idSegment, manager)
            }
            segments={segments}
            setSelectedCheckboxes={setIdSegmentsSelected}
            selectedCheckboxes={idSegmentsSelected}
            tableProps={{ pagination, onChange: onTableStateChange }}
          />
        )}
        {useDisclosureAddSegmentsModal.isOpen && (
          <AssignSegmentModal
            key='add-segments-modal'
            isLoading={
              isLoadingAddSegmentToContributorAction || isLoadingDeleteSegmentFromContributorAction
            }
            isOpen={useDisclosureAddSegmentsModal.isOpen}
            close={useDisclosureAddSegmentsModal.close}
            segments={segments}
            onAddSegment={({ idSegment }) =>
              addSegmentToContributorAction({
                idContributor,
                idSegment,
              })
            }
            onDeleteSegment={(idSegment) =>
              deleteSegmentFromContributorAction({
                idContributor,
                idSegment,
              })
            }
          />
        )}
      </Content>
    </PageLayout>
  )
}
export default SegmentsContributor
