import React from 'react'
import Select, {
  CSSObjectWithLabel,
  components as defaultComponents,
  ControlProps,
} from 'react-select'
import { Icon, colors } from 'common-components'

const customStyles = {
  control: (provided: CSSObjectWithLabel) => ({
    ...provided,
    display: 'flex',
    height: '40px',
    alignItems: 'center',
    gap: '8px',
    alignSelf: 'stretch',
    borderRadius: '8px',
    border: '1px solid #D0D5DD',
    background: '#FFF',
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    ':hover': {
      borderColor: '#D0D5DD',
    },
  }),
  dropdownIndicator: () => ({}),
  indicatorSeparator: () => ({}),
}

const Control: React.FC<ControlProps<any, boolean>> = ({ children, ...props }) => {
  return (
    <defaultComponents.Control {...props}>
      <div style={{ paddingLeft: 5, marginTop: 5 }}>
        <Icon width={15} height={15} color={colors.gray500} icon='SearchSm' />
      </div>
      {children}
    </defaultComponents.Control>
  )
}

type OptionType = {
  label: string
  value: string
}

export type RegularSelectPropsType = {
  options: OptionType[]
  value: string
  setValue: (value: string) => void
  valuesSelected: OptionType[]
  setValuesSelected: (values: OptionType[]) => void
}
const SelectRegular = ({
  options,
  value,
  setValue,
  valuesSelected,
  setValuesSelected,
}: RegularSelectPropsType) => {
  return (
    <Select
      options={options}
      value={value}
      isClearable
      isSearchable
      onChange={(option: OptionType) => {
        setValuesSelected([...valuesSelected, option])
        setValue('')
      }}
      components={{
        Control,
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        ClearIndicator: (props) => (
          <defaultComponents.ClearIndicator {...props}>
            <Icon width={18} height={18} icon='XClose' />
          </defaultComponents.ClearIndicator>
        ),
      }}
      styles={customStyles}
    />
  )
}

export default SelectRegular
