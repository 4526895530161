/** @jsxImportSource @emotion/react */
import { CollapseWithPill, VerticalFormItem } from '@components/display'
import { useTranslation } from '@hooks/useTranslation'
import { useEffect, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import { Validations } from '@definitions/validations'
import { Col, FormElements, Row, Space, TextInput, TimePicker } from 'common-components'

type ProductivityTargetsCollapsePropsType = {
  averageDeleveringTimeGoal: string | null
  effectiveTimeTaskGoal: string | null
  effectiveTimeHitGoal: string | null
  qualityExpected: number | null
}

const ProductivityTargetsCollapse = ({
  averageDeleveringTimeGoal,
  effectiveTimeTaskGoal,
  effectiveTimeHitGoal,
  qualityExpected,
}: ProductivityTargetsCollapsePropsType) => {
  const { translate } = useTranslation()
  const form = FormElements.useFormInstance()

  const [isApplicationPurposeOpen, setIsApplicationPurposeOpen] = useState<boolean>(false)
  const [isContributorsObjectiveOpen, setIsContributorsObjectiveOpen] = useState<boolean>(false)

  useEffect(() => {
    if (form) {
      if (averageDeleveringTimeGoal || effectiveTimeTaskGoal) setIsApplicationPurposeOpen(true)
      if (effectiveTimeHitGoal || qualityExpected) setIsContributorsObjectiveOpen(true)
    }
  }, [
    form,
    averageDeleveringTimeGoal,
    effectiveTimeTaskGoal,
    effectiveTimeHitGoal,
    qualityExpected,
  ])

  useEffect(() => {
    if (form) {
      const timeSpans = [
        {
          title: 'averageDeleveringTimeGoal',
          value: averageDeleveringTimeGoal,
        },
        {
          title: 'effectiveTimeTaskGoal',
          value: effectiveTimeTaskGoal,
        },
        {
          title: 'effectiveTimeHitGoal',
          value: effectiveTimeHitGoal,
        },
      ]
      timeSpans.map((timeSpan) => {
        if (timeSpan.value) {
          form.setFieldValue(
            timeSpan.title,
            dayjs(
              new Date().setHours(
                +timeSpan.value.split(':')[0],
                +timeSpan.value.split(':')[1],
                +timeSpan.value.split(':')[2],
              ),
            ),
          )
        }
      })
      if (qualityExpected) form.setFieldValue('qualityExpected', qualityExpected)
    }
  }, [
    form,
    averageDeleveringTimeGoal,
    effectiveTimeTaskGoal,
    effectiveTimeHitGoal,
    qualityExpected,
  ])

  const ApplicationsPurposeCollapseContent = useMemo(
    () => (
      <Space direction='vertical' style={{ width: '100%', padding: '0 24px 24px', gap: 16 }}>
        <Row gutter={16}>
          <Col span={12}>
            <VerticalFormItem
              name='averageDeleveringTimeGoal'
              rules={[{ required: true, message: Validations.message.required }]}
              label={translate('projectCreatePage.collapse.applicationsPurpose.form.deliveryTime')}
            >
              <TimePicker />
            </VerticalFormItem>
          </Col>
          <Col span={12}>
            <VerticalFormItem
              name='effectiveTimeTaskGoal'
              rules={[{ required: true, message: Validations.message.required }]}
              label={translate(
                'projectCreatePage.collapse.applicationsPurpose.form.processingTime',
              )}
            >
              <TimePicker />
            </VerticalFormItem>
          </Col>
        </Row>
      </Space>
    ),
    [],
  )

  const ContributorsObjectiveCollapseContent = useMemo(
    () => (
      <Space direction='vertical' style={{ width: '100%', padding: '0 24px 24px', gap: 16 }}>
        <Row gutter={16}>
          <Col span={12}>
            <VerticalFormItem
              name='effectiveTimeHitGoal'
              rules={[{ required: true, message: Validations.message.required }]}
              label={translate(
                'projectCreatePage.collapse.contributorsObjective.form.timeOfContribution',
              )}
            >
              <TimePicker />
            </VerticalFormItem>
          </Col>
          <Col span={12}>
            <VerticalFormItem
              name='qualityExpected'
              rules={[{ required: true, message: Validations.message.required }]}
              label={translate('projectCreatePage.collapse.contributorsObjective.form.quality')}
            >
              <TextInput type='number' min={0} max={100} />
            </VerticalFormItem>
          </Col>
        </Row>
      </Space>
    ),
    [],
  )

  return (
    <CollapseWithPill
      panels={[
        {
          title: translate('projectCreatePage.collapse.productivityTargets.title'),
          subtitle: translate('projectCreatePage.collapse.productivityTargets.subtitle'),
          content: '',
          withPill: false,
        },
        {
          title: translate('projectCreatePage.collapse.applicationsPurpose.title'),
          titleLevel: 6,
          content: ApplicationsPurposeCollapseContent,
          isOpen: isApplicationPurposeOpen,
          setIsOpen: setIsApplicationPurposeOpen,
        },
        {
          title: translate('projectCreatePage.collapse.contributorsObjective.title'),
          titleLevel: 6,
          content: ContributorsObjectiveCollapseContent,
          isOpen: isContributorsObjectiveOpen,
          setIsOpen: setIsContributorsObjectiveOpen,
        },
      ]}
    />
  )
}

export default ProductivityTargetsCollapse
