/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { VerticalFormItem } from '@components/display'
import { AlertType, TAlertType } from '@definitions/states'
import { Validations } from '@definitions/validations'
import { useTranslation } from '@hooks/useTranslation'
import { TxKeyPath } from '@i18n/i18n'
import { useEffect, useState } from 'react'
import {
  Col,
  FormElements,
  SelectInput,
  SelectOption,
  Space,
  Text,
  Toggle,
} from 'common-components'

type ColumnWithTogglePropsType = {
  type: 'sms' | 'email'
  name: string
  value: TAlertType | null
}

const values: TAlertType[] = [0, 10, 20]

const ColumnWithToggle = ({ type, name, value }: ColumnWithTogglePropsType) => {
  const { translate } = useTranslation()
  const form = FormElements.useFormInstance()

  const [isToggled, setIsToggled] = useState<boolean>(!!value)
  const [selectValue, setSelectValue] = useState<TAlertType>(value || 0)

  const handleToggleChange = (openValue: boolean) => {
    if (!openValue) {
      setSelectValue(0)
    }
    if (openValue && selectValue === 0) {
      setSelectValue(10)
    }
    setIsToggled(openValue)
  }

  const handleSelectChange = (value: TAlertType) => {
    if (value === 0) setIsToggled(false)
    if (value !== 0 && !isToggled) setIsToggled(true)
    setSelectValue(value)
  }

  useEffect(() => {
    if (value) setIsToggled(true)
  }, [value])

  useEffect(() => {
    form.setFieldValue(name, selectValue)
  }, [selectValue])

  return (
    <Col span={12} style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
      <Space>
        <Toggle checked={isToggled} onChange={handleToggleChange} />
        <Text size='sm' strong>
          {translate(`projectCreatePage.collapse.alerts.toggles.${type}`)}
        </Text>
      </Space>
      <Space direction='vertical'>
        <VerticalFormItem
          name={name}
          label={translate('projectCreatePage.collapse.alerts.form.recepients')}
          rules={[{ required: true, message: Validations.message.required }]}
          initialValue={selectValue}
          css={css`
            max-height: ${selectValue === 0 ? '20px' : 'auto'};
          `}
        >
          {!!selectValue && (
            <SelectInput value={selectValue} onChange={handleSelectChange}>
              {values.map((value) => (
                <SelectOption key={value} value={value} onChange={setSelectValue}>
                  {translate(
                    `projectCreatePage.collapse.alerts.selectValues.${AlertType[value]}` as TxKeyPath,
                  )}
                </SelectOption>
              ))}
            </SelectInput>
          )}
          {/* <div style={{ display: "none" }}>{selectValue}</div> */}
        </VerticalFormItem>
      </Space>
    </Col>
  )
}

export default ColumnWithToggle
