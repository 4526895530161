const en = {
  contributors: 'Contributors',
  manageTeamMembers: 'Manage your team members and their account permissions here.',
  export: 'Export',
  import: 'Import',
  filter: 'Filter',
  addContributor: 'Add a contributor',
  name: 'Name',
  experience: 'Years of Experience',
  role: 'Role',
  segments: 'Segments',
  performance: 'Performance',
  effectiveTime: 'Effective Time',
  status: 'Status',
  active: 'Active',
  waiting: 'Waiting...',
  selectedContributors: 'Selected contributors',
  selectedContributor: 'Selected contributor',
  assignToSegments: 'Assign to segments',
  add: 'Add',
  search: 'Search',
  confirm: 'Confirm',
  back: 'Back',
  deleteUser1: 'Delete',
  deleteUser2: 'user?',
  deleteUser3: 'users?',
  confirmDeleteUser: 'Are you sure you want to perform this action?',
  cancel: 'Cancel',
  delete: 'Delete',
  states: {
    1: 'Inactive',
    10: 'Active',
  },
}

export default en

export type Translations = typeof en
