const fr = {
  title: 'Détection de la fraude',
  subtitle: 'Projet lorem ipsum',
  downloadDocuments: 'Télécharger des documents',
  table: {
    name: 'Nom',
    risk: 'Risque',
    score: 'Score',
    status: 'Statut',
  },
  dropdown: {
    details: 'Détails',
    download: 'Téléchargement',
    downloadReport: 'Télécharger le rapport',
    delete: 'Supprimer',
  },
  levelStates: {
    low: 'Faible',
    medium: 'Moyen',
    high: 'Haut',
    unknown: 'Inconnu',
  },
  riskOverviewTitle: 'Analyse de risque du document {{name}}',
  sensitivitySettings: {
    modalTitle: 'Configuration de la sensibilité',
    modalOpenButton: 'Ouvrir la configuration de la sensibilité',
    submitSettingsButton: 'Modifier',
    cancelSettingsButton: 'Cancel',
    seuilChangementAlignement: 'Inclinaison par rapport au texte original',
    seuilDifferenceEspace: "Taille de l'espace entre les mots",
    seuilDifferenceCouleur: 'Différence de couleur de fond',
    metadataKeywords: `Nombre d'occurrences de mots-clés figurant sur la liste noire dans les métadonnées`,
    metadataKeywordsBlacklist: 'Mots clés dans les métadonnées',
    numberOfDays: 'Nombre de jours écoulés depuis la dernière modification du document',
    minimumLayer: 'Nombre minimum de couches dans le document',
    size: 'Différence de taille du texte',
    font: 'Font',
  },
  assessmentList: {
    emptyListText: "Pas de données à l'appui",
    elementHeader: 'Element',
    valueHeader: 'Valeur',
  },
  imageNotFound: 'Image non trouvée',
}

export default fr
