type FrameWrapperPropsType = {
  url: string
  width: number
  display: boolean
}
const FrameWrapper = ({ url, width, display }: FrameWrapperPropsType) => (
  <iframe
    src={url}
    style={{
      visibility: display ? 'visible' : 'collapse',
      position: 'absolute',
      width: `${width}px`,
      height: '120%',
      border: 'none',
      overflow: 'hidden',
    }}
  />
)

export default FrameWrapper
