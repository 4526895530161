import { FilterPayloadType } from '@types'
import { FormApiType } from '@api/types'
import { axiosClient, TFormState, TQuality, TSkillType } from '@definitions'
import { AxiosResponse } from 'axios'
import { catchApiError } from '@utils'

export type GetFormsPayloadType = FilterPayloadType & {
  model: {
    idUser?: string[]
    skill?: TSkillType[]
    quality?: TQuality[]
    state?: TFormState[]
  }
}
export const getForms = async (payload: GetFormsPayloadType): Promise<FormApiType[]> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<FormApiType[]> = await axios.post(
      '/FormService/Form/GetForms',
      payload,
    )
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export const getForm = async (idForm: string): Promise<FormApiType> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<FormApiType> = await axios.get(`/FormService/Form/${idForm}/GetForm`)
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

type BookmarkFormPayloadType = {
  idForm: string
  isBookmarked: boolean
}
export const bookmarkForm = async ({
  idForm,
  isBookmarked,
}: BookmarkFormPayloadType): Promise<void> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<void> = await axios.put(`/FormService/Form/Bookmarked`, {
      idForm,
      isBookmarked,
    })
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export const deleteForm = async (idForm: string): Promise<void> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<void> = await axios.delete(`/FormService/Form/${idForm}/DeleteForm`)
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export const getLastDraftForms = async (): Promise<FormApiType[]> => {
  try {
    const axios = axiosClient()
    const resp: AxiosResponse<FormApiType[]> = await axios.get('/FormService/Form/GetLastDraftForm')
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}

export const duplicateForm = async (idForm: string): Promise<FormApiType> => {
  try {
    const axios = axiosClient()
    const resp = await axios.post<FormApiType>('/FormService/Form/DuplicateForm', { idForm })
    return resp.data
  } catch (e) {
    catchApiError(e)
  }
}
