/** @jsxImportSource @emotion/react */

import { Slider } from 'antd'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Controller, useForm } from 'react-hook-form'
import { RiskSettingsType } from '@pages/types'
import { useTranslation } from '@hooks'
import { TxKeyPath } from '@i18n'
import { useEffect, useState } from 'react'
import {
  Button,
  Col,
  colors,
  Form,
  IconButton,
  Paragraph,
  Row,
  TextInput,
  Toggle,
} from 'common-components'

export type RiskSensitivityConfigFormPropsType = {
  settings: RiskSettingsType
  settingsUpdateTimestamp?: number
  onSubmit: (data: RiskSettingsType) => void
  onCancel?: () => void
  editable?: boolean
}

const FormRiskSensitivityConfig = ({
  settings,
  settingsUpdateTimestamp,
  onSubmit,
  onCancel,
  editable = true,
}: RiskSensitivityConfigFormPropsType) => {
  const { translate } = useTranslation()

  const [keywordInputVal, setKeywordInputVal] = useState('')

  const { control, handleSubmit, reset, formState } = useForm<RiskSettingsType>({
    defaultValues: settings,
  })

  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    userId: _userId,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    metadataKeywordsBlacklist,
    ...standardSettings
  } = settings

  useEffect(() => {
    reset(settings)
  }, [settingsUpdateTimestamp])

  const submit = (data: RiskSettingsType) => {
    onSubmit(data)
  }

  const cancel = () => {
    reset(settings)
    onCancel?.()
  }

  return (
    <Form disabled={!editable} onFinish={handleSubmit(submit)}>
      {Object.entries(standardSettings).map(([key, settingParam]) => (
        <Row
          justify={'space-between'}
          css={css`
            margin: 20px 0;
          `}
          align={'middle'}
        >
          <Col span={8}>
            <Paragraph>{translate(`risk.sensitivitySettings.${key}` as TxKeyPath)}</Paragraph>
          </Col>
          <Col span={8}>
            {settingParam.step !== null && (
              <Controller
                name={`${key as keyof typeof standardSettings}.value`}
                control={control}
                render={({ field }) => (
                  <StyledSlider
                    disabled={!editable || settingParam.step === null}
                    min={settingParam.min}
                    max={settingParam.max}
                    step={settingParam.min}
                    onChange={field.onChange}
                    value={typeof field.value === 'number' ? field.value : 0}
                  />
                )}
              />
            )}
          </Col>
          <Col span={2}>
            <Row
              justify={'center'}
              css={css`
                margin: 0 10px;
              `}
              align={'middle'}
            >
              <Controller
                name={`${key as keyof typeof standardSettings}.isActive`}
                control={control}
                render={({ field }) => <Toggle checked={field.value} onChange={field.onChange} />}
              />
            </Row>
          </Col>
          <Col span={4}>
            {settingParam.step !== null && (
              <Controller
                name={`${key as keyof typeof standardSettings}.value`}
                control={control}
                render={({ field }) => (
                  <TextInput
                    type={'number'}
                    min={settingParam.min}
                    max={settingParam.max}
                    step={settingParam.min}
                    disabled={!editable || settingParam.step === null}
                    value={field.value}
                    onChange={(e) => field.onChange(Number(e.target.value))}
                    css={css`
                      width: 80px;
                    `}
                  />
                )}
              />
            )}
          </Col>
        </Row>
      ))}

      <Controller
        name={`metadataKeywordsBlacklist.value`}
        control={control}
        render={({ field }) => (
          <>
            <Row
              justify={'space-between'}
              css={css`
                margin: 20px 0;
              `}
              align={'middle'}
            >
              <Col span={8}>
                <Paragraph>
                  {translate(`risk.sensitivitySettings.metadataKeywordsBlacklist`)}
                </Paragraph>
              </Col>
              <Col span={8}>
                <TextInput
                  value={keywordInputVal}
                  onChange={(e) => setKeywordInputVal(e.target.value)}
                />
              </Col>
              <Col span={2}>
                <Row
                  justify={'center'}
                  css={css`
                    margin: 0 10px;
                  `}
                >
                  <IconButton
                    icon={'Plus'}
                    onClick={() => {
                      field.onChange([keywordInputVal, ...(field.value ?? [])])

                      setKeywordInputVal('')
                    }}
                  />
                </Row>
              </Col>
              <Col span={4}></Col>
            </Row>

            {field.value.map((word) => (
              <Row
                justify={'space-between'}
                css={css`
                  margin: 20px 0;
                `}
                align={'middle'}
              >
                <Col span={8}></Col>
                <Col span={8}>
                  <Paragraph>{word}</Paragraph>
                </Col>
                <Col span={2}>
                  <Row
                    justify={'center'}
                    css={css`
                      margin: 0 10px;
                    `}
                  >
                    <IconButton
                      icon={'Trash04'}
                      css={css`
                        border: none;
                        box-shadow: none;
                      `}
                      onClick={() => {
                        field.onChange(field.value.filter((current) => current !== word))
                      }}
                    />
                  </Row>
                </Col>
                <Col span={4}></Col>
              </Row>
            ))}
          </>
        )}
      />

      {editable && (
        <Row
          justify={'end'}
          css={css`
            width: 100%;
            gap: 10px;
          `}
        >
          <Button onClick={cancel} size={'xl'} type={'ghost'}>
            {translate('risk.sensitivitySettings.cancelSettingsButton')}
          </Button>
          <Button disabled={!formState.isDirty} size={'xl'} type={'primary'} htmlType={'submit'}>
            {translate('risk.sensitivitySettings.submitSettingsButton')}
          </Button>
        </Row>
      )}
    </Form>
  )
}

const StyledSlider = styled(Slider)`
  .ant-slider-rail {
    background: ${colors.gray200};
    border-radius: 4px;
    height: 8px;
  }

  .ant-slider-track {
    height: 8px;
    background: ${colors.primary600};
    border-radius: 4px;
  }

  .ant-slider-handle {
    width: 24px;
    height: 24px;
    margin-top: -5px;
    background: ${colors.white};
    border: 1.5px solid ${colors.primary600};
    box-shadow: 0 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    border-radius: 12px;

    &::before {
      display: none;
    }

    &::after {
      display: none;
    }
  }

  .ant-tooltip-arrow {
    display: none;
  }

  .ant-tooltip-inner {
    background-color: transparent;
    color: black;
    box-shadow: unset;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
`

export default FormRiskSensitivityConfig
