/** @jsxImportSource @emotion/react */

import { useRequestParams } from '@hooks'
import { useQuery } from 'react-query'
import { ModelState, mutationKeys } from '@definitions'
import { getModels, getModelDrafts, GetModelsPayloadType } from '@api'
import { getStateKeyByValue } from '@utils'
import { ListModel } from '@views/model'

const ModelsPage = () => {
  const { afterPageChange, reqParams, setParams, pagination } =
    useRequestParams<GetModelsPayloadType>({
      initParams: {
        model: { state: [getStateKeyByValue(ModelState, 'online')] },
      },
    })

  const { data: modelDrafts = [] } = useQuery({
    queryKey: mutationKeys.modelDrafts(),
    queryFn: () => getModelDrafts(),
    refetchOnWindowFocus: false,
  })

  const { data: models = [] } = useQuery({
    queryKey: mutationKeys.models(reqParams),
    queryFn: () => getModels(reqParams),
    onSuccess: (items) => afterPageChange(items.length),
  })

  return (
    <ListModel
      modelDrafts={modelDrafts}
      models={models}
      pagination={pagination}
      reqParams={reqParams}
      setParams={setParams}
    />
  )
}

export default ModelsPage
