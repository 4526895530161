/** @jsxImportSource @emotion/react */

import { memo, useState } from 'react'
import { css } from '@emotion/react'
import { useTranslation } from '@hooks'
import { UserDataType } from '@pages/types'
import { Row } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { ActionModal, colors, Text } from 'common-components'

import FormManager, { CreateUpdateFormManagerType } from '../FormManager'

export type UpdateManagerModalPropsType = {
  managerUser: UserDataType
  isLoading: boolean
  onUpdateManager?: (formManager: CreateUpdateFormManagerType) => Promise<void>
  close: () => void
  isOpen: boolean
}

const UpdateManagerModalComponent = ({
  managerUser,
  isLoading,
  close,
  isOpen,
  onUpdateManager,
}: UpdateManagerModalPropsType) => {
  const [managerForm, setManagerForm] = useState<CreateUpdateFormManagerType>(null)

  const [isFormValid, setIsFormValid] = useState<boolean>(false)

  const { translate } = useTranslation()

  return (
    <ActionModal
      maskClosable={false}
      open={isOpen}
      onOk={close}
      customHeader={
        <Row
          css={css`
            flex-direction: column;
            gap: 5px;
          `}
        >
          <Text
            css={css`
              font-weight: 500;
            `}
            color={colors.gray900}
            size='xl'
          >
            Update Manager
          </Text>
        </Row>
      }
      onConfirm={close}
      onCancel={close}
      actionButtons={[
        {
          text: translate('managers.cancel'),
          onClick: () => {
            close()
          },
        },
        {
          text: isLoading ? <LoadingOutlined /> : translate('managers.confirm'),
          type: 'primary',
          disabled: !isFormValid,
          onClick: () => onUpdateManager(managerForm),
        },
      ]}
    >
      <FormManager
        manager={{
          idUser: managerUser?.idUser,
          type: managerUser?.type,
          email: managerUser?.email,
          firstName: managerUser?.firstName,
          lastName: managerUser?.lastName,
          phone: managerUser?.phone,
          urlAvatar: managerUser?.urlAvatar,
        }}
        mode='edit'
        isFormValid={setIsFormValid}
        onChangeForm={setManagerForm}
      />
    </ActionModal>
  )
}

const UpdateManagerModal = memo(
  UpdateManagerModalComponent,
  (oldProps: UpdateManagerModalPropsType, newProps: UpdateManagerModalPropsType) => {
    return JSON.stringify(oldProps) === JSON.stringify(newProps)
  },
)

export default UpdateManagerModal
