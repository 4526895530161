/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { ColumnsType } from 'antd/lib/table'
import { ItemType } from 'antd/es/menu/hooks/useItems'
import { ProjectState, TProjectState } from '@definitions/states'
import { GetProjectsPayloadType } from '@api'
import { routes } from '@navigation/routes'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from '@hooks'
import { VerticalFormItem } from '@components/display'
import { useForm } from 'antd/lib/form/Form'
import { ProjectType } from '@pages/types'
import { useMemo, useRef, useState } from 'react'
import {
  AvatarGroup,
  Button,
  Col,
  colors,
  Content,
  Dropdown,
  ITableProps,
  Row,
  Space,
  Table,
  Text,
  Title,
  Icon,
  useDisclosure,
  ActionModal,
  TextInput,
  Form,
  AvatarIcons,
  AvatarIcon,
} from 'common-components'
import { Loader } from '@components/utils'
import { LoadingOutlined } from '@ant-design/icons'

import { StateTagProject } from '../../shared/project'

import ConfirmProjectModal from './ConfirmProjectModal'
import { FiltersProject } from './filters'

import { store } from '@/store'

type ListProjectPropsType = {
  projects: ProjectType[]
  isLoading: boolean
  tableProps?: Omit<ITableProps<ProjectType>, 'data' | 'columns'>
  onFiltersChange?: (params: GetProjectsPayloadType) => void
}

const ListProject = ({
  projects,
  isLoading,
  tableProps = {},
  onFiltersChange,
}: ListProjectPropsType) => {
  const navigate = useNavigate()
  const { translate } = useTranslation()
  const [form] = useForm<{ name: string }>()

  const createProjectAction = store.use.createProjectAction().call
  const boostProjectAction = store.use.boostProjectAction().call
  const stopBoostProjectAction = store.use.stopBoostProjectAction().call
  const stopProjectAction = store.use.stopProjectAction().call
  const archiveProjectAction = store.use.archiveProjectAction().call
  const downloadProjectsFileAction = store.use.downloadProjectsFileAction().call

  const isLoadingCreateProjectAction = store.use.createProjectAction().status === 'LOADING'
  const isLoadingArchiveProjectAction = store.use.archiveProjectAction().status === 'LOADING'
  const isLoadingStopProjectAction = store.use.stopProjectAction().status === 'LOADING'
  const isLoadingDownloadProjectsFileAction =
    store.use.downloadProjectsFileAction().status === 'LOADING'

  const [isFiltersVisible, setFiltersVisible] = useState(false)
  const idProjectSelectedRef = useRef<string>()

  const {
    open: openCreateModal,
    isOpen: isCreateModalOpen,
    close: closeCreateModal,
  } = useDisclosure()

  const {
    open: openArchiveModal,
    isOpen: isArchiveModalOpen,
    close: closeArchiveModal,
  } = useDisclosure()

  const { open: openStopModal, isOpen: isStopModalOpen, close: closeStopModal } = useDisclosure()

  // HIDE not remove
  // const { mutate: duplicate } = useMutation({
  // 	mutationFn: duplicateProject,
  // 	onSuccess: () => {
  // 		client.invalidateQueries();
  // 	},
  // });

  const onCreateProject = () => {
    const name = form.getFieldValue('name')
    if (!name) return
    createProjectAction({ name }, (idProject) => {
      navigate(routes.projectCreation.navigate(idProject, 1))
    })
  }

  const getOpenProjectRoute = (state: TProjectState) => {
    switch (state) {
      case 1:
      case 2:
      case 3:
      case 4:
        return routes.projectCreation
      default:
        return routes.projectTasks
      /* HIDE
		default:
			return routes.projectDashboard;*/
    }
  }

  const getDropdownItems = (state: TProjectState, idProject: string): ItemType[] => {
    const isDrafted =
      ProjectState[state] === 'archive' ||
      ProjectState[state] === 'extractionParameter' ||
      ProjectState[state] === 'settings' ||
      ProjectState[state] === 'audienceDefinition' ||
      ProjectState[state] === 'addData'
    const hasArchiveButton = ProjectState[state] === 'done' || ProjectState[state] === 'stop'

    const boostOptions: ItemType[] = [
      {
        key: 4,
        label: <Text>{translate('projectsPage.dropdown.boost1')}</Text>,
        icon: <Icon icon={'Lightning02'} size={16} />,
        onClick: () => boostProjectAction({ idProject, nbHours: 1 }),
      },
      {
        key: 5,
        label: <Text>{translate('projectsPage.dropdown.boost2')}</Text>,
        icon: <Icon icon={'Lightning02'} size={16} />,
        onClick: () => boostProjectAction({ idProject, nbHours: 6 }),
      },
      {
        key: 6,
        label: <Text>{translate('projectsPage.dropdown.boost3')}</Text>,
        icon: <Icon icon={'Lightning02'} size={16} />,
        onClick: () => boostProjectAction({ idProject, nbHours: 12 }),
      },
      {
        type: 'divider',
      },
    ]

    const endBoostOption: ItemType[] = [
      {
        key: 5,
        label: <Text>{translate('projectsPage.dropdown.stopBoost')}</Text>,
        icon: <Icon icon={'StopCircle'} size={16} />,
        onClick: () => stopBoostProjectAction({ idProject }),
      },
      {
        type: 'divider',
      },
    ]

    return [
      {
        key: 1,
        label: <Text>{translate('projectsPage.dropdown.open')}</Text>,
        icon: <Icon icon={'ArrowCircleBrokenRight'} size={16} />,
        onClick: () => navigate(getOpenProjectRoute(state).navigate(idProject, state)),
      },
      ...(isDrafted
        ? []
        : [
            {
              key: 2,
              label: <Text>{translate('projectsPage.dropdown.audience')}</Text>,
              icon: <Icon icon={'UsersPlus'} size={16} />,
              onClick: () => navigate(routes.projectAudience.navigate(idProject)),
            },
            {
              key: 3,
              label: <Text>{translate('projectsPage.dropdown.parameters')}</Text>,
              icon: <Icon icon={'Settings01'} size={16} />,
              onClick: () => navigate(routes.projectSettings.navigate(idProject)),
            },
          ]),

      {
        type: 'divider',
      },

      ...(ProjectState[state] === 'online' ? boostOptions : []),

      ...(ProjectState[state] === 'boosted' ? endBoostOption : []),

      /* HIDE
			 * {
				key: 7,
				label: <Text>{translate("projectsPage.dropdown.duplicate")}</Text>,
				icon: <Icon icon={"Copy01"} size={16} />,
				onClick: () => duplicate(id),
			},*/

      ...(isDrafted
        ? []
        : hasArchiveButton
        ? [
            {
              key: 8,
              label: (
                <>
                  <Text style={{ color: colors.error500 }}>
                    {translate('projectsPage.dropdown.archive')}
                  </Text>
                </>
              ),
              icon: <Icon icon={'Archive'} color={colors.error500} size={16} />,
              onClick: () => {
                idProjectSelectedRef.current = idProject
                openArchiveModal()
              },
            },
          ]
        : [
            {
              key: 9,
              label: (
                <>
                  <Text style={{ color: colors.error500 }}>
                    {translate('projectsPage.dropdown.stop')}
                  </Text>
                </>
              ),
              icon: <Icon icon={'StopCircle'} color={colors.error500} size={16} />,
              onClick: () => {
                idProjectSelectedRef.current = idProject
                openStopModal()
              },
            },
          ]),
    ]
  }

  const tableColumns: ColumnsType<ProjectType> = useMemo(() => {
    try {
      return [
        {
          title: translate('projectsPage.table.name'),
          key: 'name',
          width: 228.5,
          sorter: (a, b) => {
            if (a.name < b.name) return -1
            if (a.name > b.name) return 1
            return 0
          },
          render: (record: ProjectType) => (
            <Row align={'middle'}>
              <Space size={'middle'}>
                <Text size='xs' strong>
                  {record.name}
                </Text>
              </Space>
            </Row>
          ),
        },
        {
          title: translate('projectsPage.table.state'),
          key: 'state',
          width: 126,
          sorter: (a, b) => {
            if (a.state < b.state) return -1
            if (a.state > b.state) return 1
            return 0
          },
          render: (record: ProjectType) => (
            <Row align={'middle'}>
              <StateTagProject state={record.state} />
            </Row>
          ),
        },
        {
          title: translate('projectsPage.table.segments'),
          key: 'audience',
          width: 228.5,
          // TODO: check if this is right decision when it will be real data
          // sorter: (a, b) => {
          // 	if (a.audience.audienceGroups.length < b.audience.audienceGroups.length) return -1;
          // 	if (a.audience.audienceGroups.length > b.audience.audienceGroups.length) return 1;
          // 	return 0;
          // },
          render: (record: ProjectType) => (
            <Row align={'middle'}>
              <AvatarGroup size='sm'>
                {record?.audience?.audienceGroups?.map((group) =>
                  group.segments.map((segment) => (
                    <AvatarIcon
                      icon={segment?.icon?.iconName as AvatarIcons}
                      style={{ backgroundColor: segment?.icon?.iconColor }}
                      key={`${segment.idSegment}`}
                      size='sm'
                    />
                  )),
                )}
                {record.audience.reviewersAudienceGroups.map((group) =>
                  group.segments.map((segment) => (
                    <AvatarIcon
                      icon={segment?.icon?.iconName}
                      style={{ backgroundColor: segment?.icon?.iconColor }}
                      key={`${segment.idSegment}`}
                      size='sm'
                    />
                  )),
                )}
              </AvatarGroup>
            </Row>
          ),
        },
        /* HIDE
		 * {
			title: translate("projectsPage.table.total"),
			key: "total",
			dataIndex: "total",
			width: 170,
			// TODO: make sorting depending on real data when it will be available
			// sorter: (a, b) => a.participantsNumber - b.participantsNumber,
			render: () => (
				<Row
					align={"middle"}
					css={css`
						display: flex;
						gap: 17px;
					`}
				>
					<Space
						css={css`
							gap: 5px;
						`}
					>
						<Icon icon={"ClockStopwatch"} size={16} />
						<Text size="xs">325</Text>
					</Space>
					<Space
						css={css`
							gap: 5px;
						`}
					>
						<Icon icon={"CoinsStacked03"} size={16} />
						<Text size="xs">5.2 k€</Text>
					</Space>
				</Row>
			),
		},
		{
			title: translate("projectsPage.table.inDirect"),
			key: "inDirect",
			dataIndex: "inDirect",
			width: 305,
			// sorter: (a, b) => a.averageTime.value - b.averageTime.value,
			render: () => (
				<Row
					align={"middle"}
					css={css`
						display: flex;
						gap: 17px;
					`}
				>
					<Space
						css={css`
							gap: 5px;
						`}
					>
						<Icon icon={"Users01"} size={16} />
						<Text size="xs">325</Text>
					</Space>
					<Space
						css={css`
							gap: 5px;
						`}
					>
						<Icon icon={"Briefcase01"} size={16} />
						<Text size="xs">5.2 k€</Text>
					</Space>
					<Space
						css={css`
							gap: 5px;
						`}
					>
						<Icon icon={"Sunset"} size={16} />
						<Text size="xs">6h10</Text>
					</Space>
					<Space
						css={css`
							gap: 5px;
						`}
					>
						<Icon icon={"Hourglass01"} size={16} />
						<Text size="xs">03:38:11</Text>
					</Space>
				</Row>
			),
		},
		{
			title: translate("projectsPage.table.alerts"),
			key: "alerts",
			dataIndex: "alerts",
			width: 90,
			sorter: (a, b) => a.nbAlerts - b.nbAlerts,
			render: (nbAlerts) => (
				<Row align={"middle"}>
					<Tag style={{ background: colors.warning100, width: 24, height: 24 }}>
						<Icon icon={"BellRinging04"} color={colors.warning600} size={12} />
					</Tag>
					<Text size="sm" strong>
						{nbAlerts || 0}
					</Text>
				</Row>
			),
		},*/
        {
          title: translate('projectsPage.table.actions'),
          key: 'actions',
          width: 68,
          render: ({ state, idProject }: ProjectType) => (
            <Row align={'middle'} justify={'center'}>
              <Dropdown
                menu={{ items: getDropdownItems(state, idProject) }}
                placement={'bottomRight'}
                overlayStyle={{ width: 240 }}
              >
                <Icon icon={'DotsVertical'} size={20} />
              </Dropdown>
            </Row>
          ),
        },
      ]
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
      return []
    }
  }, [])

  return (
    <Content>
      <Row
        justify={'space-between'}
        align={'top'}
        css={css`
          margin: 24px 0;
        `}
      >
        <Col>
          <Title preset={'tablePageTitle'}>{translate('projectsPage.listHeaderTitle')}</Title>
          <Title preset={'tablePageSubtitle'}>{translate('projectsPage.listHeaderSubtitle')}</Title>
        </Col>
        <Row>
          <Space>
            <Button
              iconLeft='Download01'
              onClick={downloadProjectsFileAction}
              disabled={isLoadingDownloadProjectsFileAction}
            >
              {translate('projectsPage.exportButtonLabel')}
            </Button>

            <Button onClick={() => setFiltersVisible(true)} iconLeft='FilterLines'>
              {translate('common.filter')}
            </Button>

            <FiltersProject
              visible={isFiltersVisible}
              onClose={() => setFiltersVisible(false)}
              onFiltersChange={onFiltersChange}
            />

            <Button type='primary' iconLeft='Plus' onClick={() => openCreateModal()}>
              {translate('projectsPage.createButtonLabel')}
            </Button>
          </Space>
        </Row>
      </Row>

      {isLoading && <Loader />}
      {!isLoading && <Table<ProjectType> data={projects} columns={tableColumns} {...tableProps} />}

      <ActionModal
        open={isCreateModalOpen}
        maskClosable={false}
        title={translate('projectsPage.modal.create.title')}
        onConfirm={() => closeCreateModal()}
        onCancel={() => closeCreateModal()}
        actionButtons={[
          {
            text: translate('projectsPage.modal.create.cancelButton'),
            onClick: () => closeCreateModal(),
          },
          {
            type: 'primary',
            disabled: isLoadingCreateProjectAction,
            text: isLoadingCreateProjectAction ? (
              <LoadingOutlined />
            ) : (
              translate('projectsPage.modal.create.submitButton')
            ),
            onClick: () => onCreateProject(),
          },
        ]}
      >
        <Form
          form={form}
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
          }}
        >
          <Text>{translate('projectsPage.modal.create.subtitle')}</Text>
          <VerticalFormItem label={translate('projectsPage.modal.create.label')} name='name'>
            <TextInput />
          </VerticalFormItem>
        </Form>
      </ActionModal>

      <ConfirmProjectModal
        isOpen={isArchiveModalOpen}
        closeModal={closeArchiveModal}
        isLoading={isLoadingArchiveProjectAction}
        onConfirm={() =>
          archiveProjectAction({ idProject: idProjectSelectedRef.current }, closeArchiveModal)
        }
        state='archive'
      />

      <ConfirmProjectModal
        isOpen={isStopModalOpen}
        closeModal={() => closeStopModal()}
        isLoading={isLoadingStopProjectAction}
        onConfirm={() =>
          stopProjectAction({ idProject: idProjectSelectedRef.current }, closeStopModal)
        }
        state='stop'
      />
    </Content>
  )
}

export default ListProject
