/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Content, Pills, Row } from 'common-components'
import { useState } from 'react'
import { useTranslation } from '@hooks'
import { SegmentType } from '@pages/types'

import { ManagersSegment } from './managers'
import { ContributorsSegment } from './contributors'
import { HeaderSegment } from './HeaderSegment'

type PillOptionType = {
  value: 'managers' | 'contributors'
  label: string
}

export type Segment = {
  segment: SegmentType
}

const Segment = ({ segment }: Segment) => {
  const { translate } = useTranslation()
  const [pillValue, setPillValue] = useState<PillOptionType['value']>('contributors')

  // HIDE - not remove
  // const cardData = [
  // /* HIDE
  // 	{
  // 		title: translate("segment.numberOfProjects"),
  // 		value: data.projectsCount,
  // 	},
  // 	{
  // 		title: translate("segment.treatedTasks"),
  // 		value: formatNumber(data.nbTasks),
  // 	},
  // 	{
  // 		title: translate("segment.averageQuality"),
  // 		value: `${data.averageQuality || 0}%`,
  // 	},*/
  // ];

  const pillsOption: PillOptionType[] = [
    {
      value: 'contributors',
      label: translate('segment.contributors'),
    },
    {
      value: 'managers',
      label: translate('segment.managers'),
    },
  ]

  return (
    <Content>
      <HeaderSegment segment={segment} />
      {/*  HIDE - not remove*/}
      {/*<Row*/}
      {/*  css={css`*/}
      {/*    gap: 20px;*/}
      {/*  `}*/}
      {/*>*/}
      {/*  {cardData.map((card) => (*/}
      {/*  	<Col key={card.title}>*/}
      {/*  		<StatisticCard width={392} title={card.title} value={card.value} />*/}
      {/*  	</Col>*/}
      {/*  ))}*/}
      {/*</Row>*/}
      <Row
        css={css`
          margin: 24px 0;
          max-height: 44px;
        `}
      >
        <Pills
          options={pillsOption}
          onChange={(e) => {
            setPillValue(e.target.value)
          }}
          value={pillValue}
          buttonProps={'test' as any}
        />
      </Row>
      {pillValue === 'managers' && <ManagersSegment segment={segment} />}
      {pillValue === 'contributors' && <ContributorsSegment segment={segment} />}
      {/* HIDE
			<Row
				justify={"space-between"}
				align={"middle"}
				css={css`
					border: 1px solid ${colors.gray200};
					border-radius: 12px;
					padding: 24px 26px;
				`}
			>
				<Col
					css={css`
						display: flex;
						align-items: center;
						gap: 8px;
					`}
				>
					<Text
						css={css`
							font-size: 18px;
							line-height: 28px;
							font-weight: 700;
						`}
					>
						{tableName}
					</Text>
					<Tag
						css={css`
							padding: 5px 10px;
							height: 22px;
							margin-top: 5px;
						`}
						color={colors.gray100}
					>
						{tableValue}
					</Tag>
				</Col>
				<Col
					css={css`
						display: flex;
						gap: 12px;
					`}
				>
					<Button iconLeft="Download01">
						{translate("segments.headerButtons.export")}
					</Button>
					<Button iconLeft="FilterLines">
						{translate("segments.headerButtons.filter")}
					</Button>
				</Col>
			</Row>
			*/}
    </Content>
  )
}

export default Segment
