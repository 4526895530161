import { ISiderProps, Menu, Sider, TMenuItemType, TMenuProps } from 'common-components'
import { useState } from 'react'

export type TMenuBarItem = TMenuItemType

export interface IMenuBarProps extends Omit<TMenuProps, 'items'> {
  items: TMenuBarItem[]
  initKey?: TMenuBarItem['key']
}

export const MenuBar = function ({
  items,
  onClick,
  initKey,
  selectedKeys,
  ...rest
}: IMenuBarProps) {
  const [current, setCurrent] = useState(initKey || items[0].key)

  const onMenuItemClick: IMenuBarProps['onClick'] = (e) => {
    setCurrent(e.key)
    onClick && onClick(e)
  }

  return (
    <Menu
      onClick={onMenuItemClick}
      items={items}
      inlineCollapsed={true}
      selectedKeys={selectedKeys || [current?.toString()]}
      style={{ border: 'none' }}
      {...rest}
    />
  )
}

export interface ISideBarProps extends IMenuBarProps {
  siderProps?: ISiderProps
}

export const SideBar = function ({ siderProps = {}, ...rest }: ISideBarProps) {
  return (
    <Sider collapsedWidth={82} collapsed theme={'light'} {...siderProps}>
      <MenuBar {...rest} />
    </Sider>
  )
}
