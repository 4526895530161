import { ManagerType } from '@pages/types'

import { ImmerStateCreator, StatusAction } from '../store.type'
import { StoreGlobalType } from '../store'

import {
  addCertificationToManagersAction,
  AddCertificationToManagersActionPayloadType,
  addSegmentToManagersAction,
  AddSegmentToManagersActionPayloadType,
  createManagerAction,
  CreateManagerActionPayloadType,
  deleteCertificationFromManagersAction,
  DeleteCertificationFromManagersActionPayloadType,
  deleteManagersAction,
  DeleteManagersActionPayloadType,
  deleteSegmentFromManagersAction,
  DeleteSegmentFromManagersActionPayloadType,
  downloadManagersAction,
  DownloadManagersActionPayloadType,
  downloadManagersSampleFileAction,
  getManagersAction,
  GetManagersActionPayloadType,
  updateManagerAction,
  UpdateManagerActionPayloadType,
  uploadManagersFileAction,
  UploadManagersFileActionPayloadType,
} from './manager.action'

export type ManagerSliceType = {
  /** selectors **/
  managers: ManagerType[]
  /** actions **/
  getManagersAction: {
    call: (payload: GetManagersActionPayloadType) => void
    status: StatusAction
  }
  deleteManagersAction: {
    call: (payload: DeleteManagersActionPayloadType, callback: () => void) => void
    status: StatusAction
  }
  downloadManagersSampleFileAction: {
    call: () => void
    status: StatusAction
  }
  uploadManagersFileAction: {
    call: (payload: UploadManagersFileActionPayloadType, callback?: () => void) => void
    status: StatusAction
  }
  downloadManagersAction: {
    call: (payload: DownloadManagersActionPayloadType) => void
    status: StatusAction
  }
  createManagerAction: {
    call: (
      payload: CreateManagerActionPayloadType,
      callback: (idManagerCreated: string) => void,
    ) => void
    status: StatusAction
  }
  updateManagerAction: {
    call: (payload: UpdateManagerActionPayloadType) => void
    status: StatusAction
  }
  /** SEGMENT **/
  addSegmentToManagersAction: {
    call: (payload: AddSegmentToManagersActionPayloadType, callback?: () => void) => void
    status: StatusAction
  }
  deleteSegmentFromManagersAction: {
    call: (payload: DeleteSegmentFromManagersActionPayloadType, callback?: () => void) => void
    status: StatusAction
  }
  /** CERTIFICATION **/
  addCertificationToManagersAction: {
    call: (payload: AddCertificationToManagersActionPayloadType, callback?: () => void) => void
    status: StatusAction
  }
  deleteCertificationFromManagersAction: {
    call: (payload: DeleteCertificationFromManagersActionPayloadType, callback?: () => void) => void
    status: StatusAction
  }
}

const managerSlice: ImmerStateCreator<ManagerSliceType> = (
  set,
  get: () => ManagerSliceType & StoreGlobalType,
) => ({
  managers: [],
  getManagersAction: {
    call: (payload) => getManagersAction(set)(payload),
    status: 'IDLE',
  },
  deleteManagersAction: {
    call: (payload, callback) => deleteManagersAction(set)(payload, callback),
    status: 'IDLE',
  },
  downloadManagersSampleFileAction: {
    call: () => downloadManagersSampleFileAction(set)(),
    status: 'IDLE',
  },
  uploadManagersFileAction: {
    call: (payload, callback) => uploadManagersFileAction(set)(payload, callback),
    status: 'IDLE',
  },
  downloadManagersAction: {
    call: (payload) => downloadManagersAction(set)(payload),
    status: 'IDLE',
  },
  createManagerAction: {
    call: (payload, callback) => createManagerAction(set)(payload, callback),
    status: 'IDLE',
  },
  updateManagerAction: {
    call: (payload) => updateManagerAction(set)(payload),
    status: 'IDLE',
  },
  /** SEGMENT **/
  addSegmentToManagersAction: {
    call: (payload, callback) => addSegmentToManagersAction(set, get)(payload, callback),
    status: 'IDLE',
  },
  deleteSegmentFromManagersAction: {
    call: (payload, callback) => deleteSegmentFromManagersAction(set, get)(payload, callback),
    status: 'IDLE',
  },
  /** CERTIFICATION **/
  addCertificationToManagersAction: {
    call: (payload, callback) => addCertificationToManagersAction(set, get)(payload, callback),
    status: 'IDLE',
  },
  deleteCertificationFromManagersAction: {
    call: (payload, callback) => deleteCertificationFromManagersAction(set, get)(payload, callback),
    status: 'IDLE',
  },
})

export default managerSlice
