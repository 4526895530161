/** @jsxImportSource @emotion/react */

import { CheckboxGroup, Content, ICheckboxGroupProps, Space } from 'common-components'
import { IModel } from '@api/types'

import ManagementCardModel, { ManagementCardModelPropsType } from './ManagementCardModel'

type CatalogueModelsPropsType = Omit<ManagementCardModelPropsType, 'model'> & {
  data: (IModel & { cardProps?: Omit<ManagementCardModelPropsType, 'model'> })[]
  inputProps?: ICheckboxGroupProps
}

const CatalogueModels = ({ data, inputProps, ...rest }: CatalogueModelsPropsType) => {
  return (
    <Content>
      <CheckboxGroup {...inputProps}>
        <Space size={10} wrap>
          {data.map((model, i) => (
            <ManagementCardModel key={`${i}-${model.idModel}`} model={model} {...rest} />
          ))}
        </Space>
      </CheckboxGroup>
    </Content>
  )
}

export default CatalogueModels
