import styled from '@emotion/styled'
import { PropsWithChildren } from 'react'
import { colors } from 'common-components'

type SegmentsTreeLeafPropsType = {
  first?: boolean
  last?: boolean
  single?: boolean
}

const HorizontalBranch = styled.div`
  width: 24px;
  height: 1px;
  margin: 24px 0;
  background: ${colors.gray200};
`

const LeafContainer = styled.div`
  flex: 1;
  padding: 8px;
  border-radius: 12px;
  border: 1px solid ${colors.gray200};
`

const SegmentsTreeLeaf = ({
  first = false,
  last = false,
  single = false,
  children,
}: PropsWithChildren<SegmentsTreeLeafPropsType>) => {
  const getItemsAlign = () => {
    switch (true) {
      case first && !single:
        return 'start'
      // return "end"
      case last && !single:
        return 'end'
      // return "start"
      case single:
        return 'center'
      default:
        return 'center'
    }
  }

  return (
    <div style={{ display: 'flex', alignItems: getItemsAlign() }}>
      {!single && <HorizontalBranch />}
      <LeafContainer>{children}</LeafContainer>
    </div>
  )
}

export default SegmentsTreeLeaf
