/** @jsxImportSource @emotion/react */

import styled from '@emotion/styled'
import { getStateKeyByValue } from '@utils'
import { FormState, TFormState } from '@definitions'
import { useTranslation } from '@hooks'
import {
  Avatar,
  Checkbox,
  Col,
  colors,
  HoverCard,
  IAvailableIcons,
  Icon,
  IconButton,
  ITagProps,
  Paragraph,
  Row,
  Space,
  spacing,
  Tag,
  Text,
} from 'common-components'

const HeaderContainer = styled(Row)`
  padding-top: ${spacing.padding.card.default}px;
  padding-left: ${spacing.padding.card.default}px;
  padding-right: ${spacing.padding.card.default}px;
  min-height: 39px;
`
const ContentContainer = styled(Col)`
  padding: ${spacing.padding.card.default}px;
  min-height: 99px;
`
const FooterContainer = styled(Row)`
  width: 100%;
  height: 56px;
`
const HoverContentContainer = styled(Row)`
  width: 100%;
`
const DraftBadgeContainer = styled(Row)`
  position: absolute;
  height: 50px;
  width: 110px;
  padding: 5px;
  top: -43px;
  right: -35px;
  transform: rotate(45deg);
  background-color: ${colors.gray600};
  align-items: flex-end;
  justify-content: center;
`

type FormManagementCardPropsType = {
  form: {
    idForm: string
    name: string
    updateDate: string
    state: TFormState
    isBookmarked: boolean
    nbReviewers: number
  }
  tags?: (ITagProps & { label: string; icon?: IAvailableIcons })[]
  canSelect?: boolean
  onBookmarkToggle?: (idForm: string, isBookmarked: boolean) => void
  onFormOpen?: (idForm: string) => void
  onFormCopy?: (idForm: string) => void
  onFormDelete?: (idForm: string) => void
}

const ManagementCardForm = ({
  form,
  tags = [],
  onBookmarkToggle,
  onFormOpen,
  onFormCopy,
  onFormDelete,
  canSelect,
}: FormManagementCardPropsType) => {
  const { translate } = useTranslation()
  const isDraft = form?.state === getStateKeyByValue(FormState, 'creating')

  return (
    <HoverCard
      style={{ height: 200 }}
      HeaderComponent={
        <HeaderContainer align={'middle'} justify={'space-between'}>
          {canSelect && <Checkbox key={form.idForm} value={form.idForm} />}
          {!isDraft && onBookmarkToggle && (
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => onBookmarkToggle(form.idForm, !form.isBookmarked)}
            >
              <Icon
                icon={'Heart'}
                color={form?.isBookmarked ? colors.error500 : colors.gray300}
                svgProps={{
                  fill: form?.isBookmarked ? colors.error500 : colors.white,
                }}
                size={20}
              />
            </div>
          )}
        </HeaderContainer>
      }
      ContentComponent={
        <ContentContainer>
          {isDraft && (
            <DraftBadgeContainer>
              <Text color={colors.white}>{translate('form.draftLabel')}</Text>
            </DraftBadgeContainer>
          )}
          <Paragraph>{form.name}</Paragraph>
          {tags.map((tag) => (
            <Tag color={colors.gray700} {...tag}>
              {tag.icon && <Icon icon={tag.icon} />}
              {tag.label}
            </Tag>
          ))}
          <Text>{form.updateDate}</Text>
        </ContentContainer>
      }
      footerContent={
        <FooterContainer align={'middle'} justify={'space-between'}>
          {/*FIXME: no avatar data*/}
          <Avatar />
          <Space size={5}>
            <Icon icon={'UsersEdit'} color={colors.gray400} size={16} />
            {/*TODO: verify*/}
            <Text size='xs'>{form.nbReviewers}</Text>
          </Space>
        </FooterContainer>
      }
      hoverFooterContent={
        <HoverContentContainer align='middle' justify='center'>
          {onFormOpen && (
            <IconButton
              icon={'LinkExternal02'}
              type={'text'}
              iconProps={{ color: colors.white }}
              onClick={() => onFormOpen(form.idForm)}
            />
          )}
          {onFormCopy && (
            <IconButton
              icon={'Copy01'}
              type={'text'}
              iconProps={{ color: colors.white }}
              onClick={() => onFormCopy(form.idForm)}
            />
          )}
          {onFormDelete && (
            <IconButton
              icon={'Trash01'}
              type={'text'}
              iconProps={{ color: colors.white }}
              onClick={() => onFormDelete(form.idForm)}
            />
          )}
        </HoverContentContainer>
      }
    />
  )
}

export default ManagementCardForm
