/** @jsxImportSource @emotion/react */

import { PrefillCertificationMergeType, PrefillCertificationType } from '@definitions'
import { downloadSampleFileCertification } from '@api'
import { DataLengthTag, ItemWithAvatar } from '@components/display'
import { TableInput } from '@components/inputs'
import { useDelayedEffect, useTranslation } from '@hooks'
import { useEffect, useState } from 'react'
import { IColumn } from '@types'
import { getStateKeyByValue } from '@utils'
import { CertificationType, QuestionType } from '@pages/types/certification'
import {
  Button,
  Card,
  Col,
  colors,
  Content,
  Row,
  Space,
  Tag,
  Text,
  Title,
  Toggle,
  useDisclosure,
} from 'common-components'
import ImportFilesModal from '@components/modal/ImportFilesModal'
import { LoadingOutlined } from '@ant-design/icons'

import { ID_COLUMN_HYDRATION } from '../constants'

import { store } from '@/store'

type CreateDraftQuestionsCertificationPropsType = {
  certification: CertificationType
  onNextStep?: () => void
}

const CreateDraftQuestionsCertification = ({
  certification,
  onNextStep,
}: CreateDraftQuestionsCertificationPropsType) => {
  const { translate } = useTranslation()

  const questionsDraft = store.use.questionsDraft()

  const uploadColumnFileQuestionsCertificationAction =
    store.use.uploadColumnFileQuestionsCertificationAction().call
  const uploadQuestionsFileCertificationAction =
    store.use.uploadQuestionsFileCertificationAction().call
  const deleteDraftQuestionCertificationAction =
    store.use.deleteDraftQuestionCertificationAction().call
  const attachQuestionsToCertificationAction = store.use.attachQuestionsToCertificationAction().call
  const updateDraftQuestionCertificationAction =
    store.use.updateDraftQuestionCertificationAction().call
  const createDraftQuestionCertificationAction =
    store.use.createDraftQuestionCertificationAction().call
  const getDraftQuestionsCertificationAction = store.use.getDraftQuestionsCertificationAction().call

  const isLoadingUploadColumnFileQuestionsCertificationAction =
    store.use.uploadColumnFileQuestionsCertificationAction().status === 'LOADING'
  const isLoadingUploadQuestionsFileCertificationAction =
    store.use.uploadQuestionsFileCertificationAction().status === 'LOADING'
  const isLoadingDeleteDraftQuestionCertificationAction =
    store.use.deleteDraftQuestionCertificationAction().status === 'LOADING'
  const isLoadingAttachQuestionsToCertificationAction =
    store.use.attachQuestionsToCertificationAction().status === 'LOADING'
  const isLoadingGetDraftQuestionsCertificationAction =
    store.use.getDraftQuestionsCertificationAction().status === 'LOADING'
  const isLoadingCreateDraftQuestionCertificationAction =
    store.use.createDraftQuestionCertificationAction().status === 'LOADING'
  const isLoadingUpdateDraftQuestionCertificationAction =
    store.use.updateDraftQuestionCertificationAction().status === 'LOADING'
  const isLoadingUpdateNextStepCertificationAction =
    store.use.updateNextStepCertificationAction().status === 'LOADING'

  const [columns, setColumns] = useState<IColumn[]>([])
  const [errorsOnly, setErrorsOnly] = useState(false)
  const useDisclosureImportQuestionsModal = useDisclosure()

  const [refreshAvailable, setRefreshAvailable] = useState(false)
  const [refreshEnabled, setRefreshEnabled] = useState(true)

  useEffect(() => addColumnHydrationJson(), [JSON.stringify(certification?.formShort?.columns)])

  useDelayedEffect(() => {
    getDraftQuestionsCertificationAction({ idCertification: certification.idCertification })
  }, [certification.idCertification])

  const addColumnHydrationJson = () => {
    if (
      certification?.prefillType ===
      getStateKeyByValue<PrefillCertificationType>(PrefillCertificationMergeType, 'manuel')
    ) {
      setColumns(
        certification?.formShort?.columns.concat({
          name: ID_COLUMN_HYDRATION,
          type: 'hydration_json',
        }),
      )
    } else {
      setColumns(certification?.formShort?.columns)
    }
  }

  const onQuestionCreateMutation = async (data: { value: string; template: IColumn }) => {
    createDraftQuestionCertificationAction(
      {
        idCertification: certification?.idCertification,
      },
      (questionDraft) => {
        onQuestionUpdateMutation({
          ...data,
          field: questionDraft,
        })
      },
    )
  }

  const onQuestionUpdateMutation = async (data: {
    value?: string
    file?: string
    field: QuestionType
    template: IColumn
  }) => {
    updateDraftQuestionCertificationAction({
      idCertification: certification.idCertification,
      idCertificationQuestion: data.field.idCertificationQuestion,
      columnName: data.template.name,
      file: data.file,
      type: data.template.type,
      value: data.value,
    })
  }

  const onColumnUploadMutation = async (data: { file?: string; template: IColumn }) => {
    uploadColumnFileQuestionsCertificationAction(
      {
        idCertification: certification?.idCertification,
        columnName: data.template.name,
        file: data.file,
      },
      () => {
        setRefreshAvailable(true)
        getDraftQuestionsCertificationAction({ idCertification: certification.idCertification })
      },
    )
  }

  const onUploadFileMutation = async (file: File) => {
    uploadQuestionsFileCertificationAction(
      {
        idCertification: certification?.idCertification,
        file,
      },
      () => {
        setRefreshAvailable(true)
        getDraftQuestionsCertificationAction({ idCertification: certification.idCertification })
        useDisclosureImportQuestionsModal.close()
      },
    )
  }

  const onRefresh = async () => {
    setRefreshEnabled(false)
    getDraftQuestionsCertificationAction({ idCertification: certification.idCertification }, () => {
      setTimeout(() => setRefreshEnabled(true), 5000)
    })
  }

  const onNext = () => {
    attachQuestionsToCertificationAction(
      {
        idCertification: certification.idCertification,
      },
      onNextStep,
    )
  }

  return (
    <Content>
      <Card style={{ width: '100%', padding: 20, marginBottom: 20 }}>
        <ItemWithAvatar title={certification?.formShort?.name}>
          <Space size={5}>
            {certification?.formShort?.columns?.map((templateColumn) => (
              <Tag color={colors.pink700}>{templateColumn.name}</Tag>
            ))}
          </Space>
        </ItemWithAvatar>
      </Card>
      <Row align={'top'} justify={'space-between'} style={{ width: '100%' }}>
        <Col>
          <Space>
            <Title style={{ fontSize: 18 }}>
              {translate(`certificationCreation.questions.questionsTitle`)}
            </Title>
            <DataLengthTag>{questionsDraft?.length || 0}</DataLengthTag>
          </Space>
          <Title preset='tablePageSubtitle' style={{ fontSize: 14, marginBottom: 16 }}>
            {translate(`certificationCreation.questions.questionsSubtitle`)}
          </Title>
        </Col>
        <Space size={5} direction={'vertical'} align={'end'}>
          {refreshAvailable && (
            <Button
              disabled={!refreshEnabled}
              onClick={onRefresh}
              iconLeft={'RefreshCW01'}
              style={{ height: 48 }}
            >
              {translate(`certificationCreation.questions.refreshButtonLabel`)}
            </Button>
          )}

          <Button
            onClick={useDisclosureImportQuestionsModal.open}
            iconLeft={'UploadCloud01'}
            style={{ height: 48 }}
          >
            {translate(`certificationCreation.questions.uploadFile`)}
          </Button>
        </Space>
      </Row>
      <Row align={'middle'} justify={'space-between'} style={{ width: '100%' }}>
        <Space size={5}>
          <Toggle checked={errorsOnly} onChange={setErrorsOnly} />
          <Text>{translate(`certificationCreation.questions.errorsOnly`)}</Text>
        </Space>
      </Row>
      <TableInput<QuestionType>
        templateColumns={columns}
        keys={{
          rowKey: 'idCertificationQuestion',
          columnKey: 'inputs',
        }}
        onCreate={onQuestionCreateMutation}
        onUpdate={onQuestionUpdateMutation}
        onColumnUpload={onColumnUploadMutation}
        onRowDelete={(idCertificationQuestion) =>
          deleteDraftQuestionCertificationAction({
            idCertification: certification.idCertification,
            idCertificationQuestion,
          })
        }
        data={
          errorsOnly
            ? questionsDraft?.filter((question) => question.inputs.some((input) => input.hasError))
            : questionsDraft
        }
      />
      <Row justify={'end'}>
        <Space size={5} style={{ margin: 10, gap: 16 }}>
          {onNextStep && (
            <Button
              disabled={
                isLoadingGetDraftQuestionsCertificationAction ||
                isLoadingUpdateDraftQuestionCertificationAction ||
                isLoadingCreateDraftQuestionCertificationAction ||
                isLoadingAttachQuestionsToCertificationAction ||
                isLoadingUpdateNextStepCertificationAction ||
                isLoadingDeleteDraftQuestionCertificationAction ||
                isLoadingUploadColumnFileQuestionsCertificationAction
              }
              style={{
                width: '100px',
              }}
              type='primary'
              onClick={onNext}
            >
              {isLoadingUpdateNextStepCertificationAction ||
              isLoadingAttachQuestionsToCertificationAction ? (
                <LoadingOutlined />
              ) : (
                translate('certificationCreation.nextStepButton')
              )}
            </Button>
          )}
        </Space>
      </Row>

      {/*Import Questions*/}
      {useDisclosureImportQuestionsModal.isOpen && (
        <ImportFilesModal
          useDisclosure={useDisclosureImportQuestionsModal}
          isLoading={isLoadingUploadQuestionsFileCertificationAction}
          onFileUpload={onUploadFileMutation}
          downloadSampleFile={async () =>
            await downloadSampleFileCertification({
              idCertification: certification.idCertification,
            })
          }
        />
      )}
    </Content>
  )
}

export default CreateDraftQuestionsCertification
