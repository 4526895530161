import { useTranslation } from '@hooks/useTranslation'
import { routes } from '@navigation/routes'
import { useNavigate } from 'react-router-dom'
import { Button } from 'common-components'
import { HitType } from '@pages/types'

import { tasklineHitViews } from '../type'

import { store } from '@/store'

type ReviewButtonProjectPropsType = {
  idProject: string
  hitReview: HitType
}

const ReviewButtonProject = ({ idProject, hitReview }: ReviewButtonProjectPropsType) => {
  const addErrorNotificationAction = store.use.addErrorNotificationAction()

  const { translate } = useTranslation()
  const navigate = useNavigate()

  const showError = () => {
    addErrorNotificationAction({
      description: translate('project.notifications.nextHitError'),
    })
  }

  const navigateToHit = () =>
    navigate(
      routes.projectTaskHit.navigate(
        idProject,
        hitReview?.task.idTask,
        `${tasklineHitViews[0]}/${hitReview?.idHit}`,
      ),
    )

  return (
    <>
      <Button
        disabled={!idProject}
        onClick={hitReview?.task && hitReview?.idHit ? navigateToHit : showError}
      >
        {translate('project.navigation.review')}
      </Button>
    </>
  )
}

export default ReviewButtonProject
