const fr = {
  user: 'Utilisateur',
  managerSelect: 'Veuillez choisir un gestionnaire',
  creator: 'Créateur',
  ownersAndDirectors: 'Proprietaire & Administrateurs',
  search: 'Rechercher',
  add: 'Ajouter',
}

export default fr
