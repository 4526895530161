import {
  archiveProject,
  attachTasksToProject,
  boostProject,
  BoostProjectPayloadType,
  createProject,
  downloadProjects,
  getProject,
  getProjects,
  GetProjectsPayloadType,
  nextStepProject,
  stopBoost,
  stopProject,
  updateAudience,
  UpdateAudiencePayloadType,
  updateAudienceReviewers,
  UpdateAudienceReviewersPayloadType,
  updateFormProject,
  UpdateFormProjectPayloadType,
  updatePrefillProject,
  UpdatePrefillProjectPayloadType,
  updateProjectManagers,
  UpdateProjectManagersPayloadType,
  updateProjectSettings,
  UpdateProjectSettingsPayloadType,
} from '@api'
import { TProjectState } from '@definitions'
import { FormType } from '@pages/types'
import { downloadBlob, shortTime } from '@utils'

import { StoreGlobalType } from '../store'

import { ProjectSliceType } from './project.slice'

import { projectApiMapper } from '@/mappers'
import { SetState } from '@/store/store.type'
import { getApiError, getSuccess } from '@/store/helper'

const updateFormProjectMapper = (
  idProject: string,
  formCurrent: FormType,
): UpdateFormProjectPayloadType => {
  try {
    const form: UpdateFormProjectPayloadType['form'] = {
      idForm: formCurrent.idForm,
      name: formCurrent.name,
      columns: formCurrent.template.template.data.map((column) => ({
        name: column.field,
        type: column.type,
      })),
      averageDuration: formCurrent.template.template.averageDuration,
      quality: formCurrent.template.template.quality,
      allowError: formCurrent.template.template.allowError,
      hasSubdivide: formCurrent.template.template.hasSubdivide,
      hasReview: formCurrent.hasReview,
      keySkill: formCurrent.template.template.keySkill,
      minimumDuration: formCurrent.template.template.minimumDuration,
      prefillInputs: formCurrent.template.template.prefillInputs,
      subdivide: formCurrent.template.template.subdivide,
      fields: formCurrent.fields,
      nodes: formCurrent.nodes,
      hideIfRules: formCurrent.hideIfRules,
      fieldAssociation: formCurrent.fieldAssociation,
      ignoredFields: formCurrent.ignoredFields,
      validationRules: formCurrent.validationRules,
      iterationOverload: formCurrent.iterationOverload,
      comparisonRules: formCurrent.comparisonRules,
    }
    return {
      idProject,
      form,
    }
  } catch (e) {
    throw e
  }
}

/****************************************************************
 * GET PROJECTS
 *****************************************************************/

type GetProjectsActionStatusType =
  | 'getProjects/loading'
  | 'getProjects/succeeded'
  | 'getProjects/failed'

export type GetProjectsActionPayloadType = GetProjectsPayloadType

export const getProjectsAction =
  (
    set: SetState<
      Partial<ProjectSliceType & StoreGlobalType>,
      GetProjectsActionStatusType,
      GetProjectsActionPayloadType
    >,
  ) =>
  async (payload: GetProjectsActionPayloadType) => {
    try {
      set(
        (state) => {
          state.getProjectsAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'getProjects/loading', payload },
      )

      const projectsApi = await getProjects(payload)
      const projects = projectsApi?.map(projectApiMapper)

      set(
        (state) => {
          state.getProjectsAction.status = 'SUCCEEDED'
          state.projects = projects
        },
        false,
        { type: 'getProjects/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.getProjectsAction.status = 'FAILED'
          state.projects = []
          state.error = getApiError(e)
        },
        false,
        { type: 'getProjects/failed' },
      )
    }
  }

/****************************************************************
 * GET PROJECT
 *****************************************************************/

type GetProjectActionStatusType =
  | 'getProject/loading'
  | 'getProject/succeeded'
  | 'getProject/failed'

export type GetProjectActionPayloadType = {
  idProject: string
}

export const getProjectAction =
  (
    set: SetState<
      Partial<ProjectSliceType & StoreGlobalType>,
      GetProjectActionStatusType,
      GetProjectActionPayloadType
    >,
  ) =>
  async (payload: GetProjectActionPayloadType) => {
    try {
      set(
        (state) => {
          state.getProjectAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'getProject/loading', payload },
      )

      const projectApi = await getProject(payload.idProject)
      const project = projectApiMapper(projectApi)

      set(
        (state) => {
          state.getProjectAction.status = 'SUCCEEDED'

          const index = state.projects?.findIndex(
            ({ idProject }) => idProject === payload.idProject,
          )
          if (index >= 0) {
            state.projects[index] = project
          } else {
            state.projects.unshift(project)
          }
        },
        false,
        { type: 'getProject/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.getProjectAction.status = 'FAILED'
          state.projects = []
          state.error = getApiError(e)
        },
        false,
        { type: 'getProject/failed' },
      )
    }
  }

/****************************************************************
 * UPDATE NEXT STEP PROJECT
 *****************************************************************/

type UpdateNextStepProjectActionStatusType =
  | 'updateNextStepProject/loading'
  | 'updateNextStepProject/succeeded'
  | 'updateNextStepProject/failed'

export type UpdateNextStepProjectActionPayloadType = {
  idProject: string
}

export const updateNextStepProjectAction =
  (
    set: SetState<
      Partial<ProjectSliceType & StoreGlobalType>,
      UpdateNextStepProjectActionStatusType,
      UpdateNextStepProjectActionPayloadType
    >,
  ) =>
  async (
    payload: UpdateNextStepProjectActionPayloadType,
    callback: (nextStep: TProjectState) => void,
  ) => {
    try {
      set(
        (state) => {
          state.updateNextStepProjectAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'updateNextStepProject/loading', payload },
      )

      const nextStep = await nextStepProject(payload)

      set(
        (state) => {
          state.updateNextStepProjectAction.status = 'SUCCEEDED'
          state.success = getSuccess({})

          const index = state.projects.findIndex(({ idProject }) => idProject === payload.idProject)
          if (index >= 0) {
            state.projects[index].state = nextStep
          }
        },
        false,
        { type: 'updateNextStepProject/succeeded' },
      )

      callback(nextStep)
    } catch (e) {
      set(
        (state) => {
          state.updateNextStepProjectAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'updateNextStepProject/failed' },
      )
    }
  }

/****************************************************************
 * UPDATE FORM PROJECT
 *****************************************************************/

type UpdateFormProjectActionStatusType =
  | 'updateFormProject/loading'
  | 'updateFormProject/succeeded'
  | 'updateFormProject/failed'

export type UpdateFormProjectActionPayloadType = {
  idProject: string
  form: FormType
}

export const updateFormProjectAction =
  (
    set: SetState<
      Partial<ProjectSliceType & StoreGlobalType>,
      UpdateFormProjectActionStatusType,
      UpdateFormProjectActionPayloadType
    >,
  ) =>
  async (payload: UpdateFormProjectActionPayloadType, callback: () => void) => {
    try {
      set(
        (state) => {
          state.updateFormProjectAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'updateFormProject/loading', payload },
      )

      const payloadApi = updateFormProjectMapper(payload.idProject, payload.form)
      const projectApi = await updateFormProject(payloadApi)
      const project = projectApiMapper(projectApi)

      set(
        (state) => {
          state.updateFormProjectAction.status = 'SUCCEEDED'

          const index = state.projects.findIndex(({ idProject }) => idProject === payload.idProject)
          if (index >= 0) {
            state.projects[index] = project
          }
        },
        false,
        { type: 'updateFormProject/succeeded' },
      )

      callback && callback()
    } catch (e) {
      set(
        (state) => {
          state.updateFormProjectAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'updateFormProject/failed' },
      )
    }
  }

/****************************************************************
 * UPDATE PREFILL PROJECT
 *****************************************************************/

type UpdatePrefillProjectActionStatusType =
  | 'updatePrefillProject/loading'
  | 'updatePrefillProject/succeeded'
  | 'updatePrefillProject/failed'

export type UpdatePrefillProjectActionPayloadType = UpdatePrefillProjectPayloadType

export const updatePrefillProjectAction =
  (
    set: SetState<
      Partial<ProjectSliceType & StoreGlobalType>,
      UpdatePrefillProjectActionStatusType,
      UpdatePrefillProjectActionPayloadType
    >,
  ) =>
  async (payload: UpdatePrefillProjectActionPayloadType, callback: () => void) => {
    try {
      set(
        (state) => {
          state.updatePrefillProjectAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'updatePrefillProject/loading', payload },
      )

      const projectApi = await updatePrefillProject(payload)
      const project = projectApiMapper(projectApi)

      set(
        (state) => {
          state.updatePrefillProjectAction.status = 'SUCCEEDED'

          const index = state.projects.findIndex(({ idProject }) => idProject === payload.idProject)
          if (index >= 0) {
            state.projects[index] = project
          }
        },
        false,
        { type: 'updatePrefillProject/succeeded' },
      )

      callback && callback()
    } catch (e) {
      set(
        (state) => {
          state.updatePrefillProjectAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'updatePrefillProject/failed' },
      )
    }
  }

/****************************************************************
 * UPDATE SETTINGS PROJECT
 *****************************************************************/

type UpdateSettingsProjectActionStatusType =
  | 'updateSettingsProject/loading'
  | 'updateSettingsProject/succeeded'
  | 'updateSettingsProject/failed'

export type UpdateSettingsProjectActionPayloadType = {
  idProject: string
  payload: UpdateProjectSettingsPayloadType
}

export const updateSettingsProjectAction =
  (
    set: SetState<
      Partial<ProjectSliceType & StoreGlobalType>,
      UpdateSettingsProjectActionStatusType,
      UpdateSettingsProjectActionPayloadType
    >,
  ) =>
  async (payload: UpdateSettingsProjectActionPayloadType, callback: () => void) => {
    try {
      set(
        (state) => {
          state.updateSettingsProjectAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'updateSettingsProject/loading', payload },
      )

      const projectApi = await updateProjectSettings(payload.payload)
      const project = projectApiMapper(projectApi)

      set(
        (state) => {
          state.updateSettingsProjectAction.status = 'SUCCEEDED'
          state.success = getSuccess({})

          const index = state.projects.findIndex(({ idProject }) => idProject === payload.idProject)
          if (index >= 0) {
            state.projects[index] = project
          }
        },
        false,
        { type: 'updateSettingsProject/succeeded' },
      )

      callback && callback()
    } catch (e) {
      set(
        (state) => {
          state.updateSettingsProjectAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'updateSettingsProject/failed' },
      )
    }
  }

/****************************************************************
 * ADD MANAGERS TO PROJECT
 *****************************************************************/

type AddManagersToProjectActionStatusType =
  | 'addManagersToProject/loading'
  | 'addManagersToProject/succeeded'
  | 'addManagersToProject/failed'

export type AddManagersToProjectActionPayloadType = UpdateProjectManagersPayloadType

export const addManagersToProjectAction =
  (
    set: SetState<
      Partial<ProjectSliceType & StoreGlobalType>,
      AddManagersToProjectActionStatusType,
      AddManagersToProjectActionPayloadType
    >,
  ) =>
  async (payload: AddManagersToProjectActionPayloadType, callback?: () => void) => {
    try {
      set(
        (state) => {
          state.addManagersToProjectAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'addManagersToProject/loading', payload },
      )

      const projectApi = await updateProjectManagers(payload)
      const project = projectApiMapper(projectApi)

      set(
        (state) => {
          state.addManagersToProjectAction.status = 'SUCCEEDED'

          const index = state.projects.findIndex(({ idProject }) => idProject === payload.idProject)
          if (index >= 0) {
            state.projects[index] = project
          }
        },
        false,
        { type: 'addManagersToProject/succeeded' },
      )

      callback && callback()
    } catch (e) {
      set(
        (state) => {
          state.addManagersToProjectAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'addManagersToProject/failed' },
      )
    }
  }

/****************************************************************
 * UPDATE AUDIENCE PROJECT
 *****************************************************************/

type UpdateAudienceProjectActionStatusType =
  | 'updateAudienceProject/loading'
  | 'updateAudienceProject/succeeded'
  | 'updateAudienceProject/failed'

export type UpdateAudienceProjectActionPayloadType = UpdateAudiencePayloadType

export const updateAudienceProjectAction =
  (
    set: SetState<
      Partial<ProjectSliceType & StoreGlobalType>,
      UpdateAudienceProjectActionStatusType,
      UpdateAudienceProjectActionPayloadType
    >,
  ) =>
  async (payload: UpdateAudienceProjectActionPayloadType, callback?: () => void) => {
    try {
      set(
        (state) => {
          state.updateAudienceProjectAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'updateAudienceProject/loading', payload },
      )

      const projectApi = await updateAudience(payload)
      const project = projectApiMapper(projectApi)

      set(
        (state) => {
          state.updateAudienceProjectAction.status = 'SUCCEEDED'
          state.success = getSuccess({})

          const index = state.projects.findIndex(({ idProject }) => idProject === payload.idProject)
          if (index >= 0) {
            state.projects[index] = project
          }
        },
        false,
        { type: 'updateAudienceProject/succeeded' },
      )

      callback && callback()
    } catch (e) {
      set(
        (state) => {
          state.updateAudienceProjectAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'updateAudienceProject/failed' },
      )
    }
  }

/****************************************************************
 * UPDATE AUDIENCE REVIEWERS PROJECT
 *****************************************************************/

type UpdateAudienceReviewersProjectActionStatusType =
  | 'updateAudienceReviewersProject/loading'
  | 'updateAudienceReviewersProject/succeeded'
  | 'updateAudienceReviewersProject/failed'

export type UpdateAudienceReviewersProjectActionPayloadType = UpdateAudienceReviewersPayloadType

export const updateAudienceReviewersProjectAction =
  (
    set: SetState<
      Partial<ProjectSliceType & StoreGlobalType>,
      UpdateAudienceReviewersProjectActionStatusType,
      UpdateAudienceReviewersProjectActionPayloadType
    >,
  ) =>
  async (payload: UpdateAudienceReviewersProjectActionPayloadType, callback?: () => void) => {
    try {
      set(
        (state) => {
          state.updateAudienceReviewersProjectAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'updateAudienceReviewersProject/loading', payload },
      )

      const projectApi = await updateAudienceReviewers(payload)
      const project = projectApiMapper(projectApi)

      set(
        (state) => {
          state.updateAudienceReviewersProjectAction.status = 'SUCCEEDED'

          const index = state.projects.findIndex(({ idProject }) => idProject === payload.idProject)
          if (index >= 0) {
            state.projects[index] = project
          }
        },
        false,
        { type: 'updateAudienceReviewersProject/succeeded' },
      )

      callback && callback()
    } catch (e) {
      set(
        (state) => {
          state.updateAudienceReviewersProjectAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'updateAudienceReviewersProject/failed' },
      )
    }
  }

/****************************************************************
 * ATTACH TASKS TO PROJECT
 *****************************************************************/

type AttachTasksToProjectActionStatusType =
  | 'attachTasksToProject/loading'
  | 'attachTasksToProject/succeeded'
  | 'attachTasksToProject/failed'

export type AttachTasksToProjectActionPayloadType = {
  idProject: string
}

export const attachTasksToProjectAction =
  (
    set: SetState<
      Partial<ProjectSliceType & StoreGlobalType>,
      AttachTasksToProjectActionStatusType,
      AttachTasksToProjectActionPayloadType
    >,
  ) =>
  async (payload: AttachTasksToProjectActionPayloadType, callback: () => void) => {
    try {
      set(
        (state) => {
          state.attachTasksToProjectAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'attachTasksToProject/loading', payload },
      )

      const projectApi = await attachTasksToProject(payload)
      const project = projectApiMapper(projectApi)

      set(
        (state) => {
          state.attachTasksToProjectAction.status = 'SUCCEEDED'
          const index = state.projects?.findIndex(
            ({ idProject }) => idProject === payload.idProject,
          )
          if (index >= 0) {
            state.projects[index] = project
          }
        },
        false,
        { type: 'attachTasksToProject/succeeded' },
      )

      callback && callback()
    } catch (e) {
      set(
        (state) => {
          state.attachTasksToProjectAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'attachTasksToProject/failed' },
      )
    }
  }

/****************************************************************
 * CREATE PROJECT
 *****************************************************************/

type CreateProjectActionStatusType =
  | 'createProject/loading'
  | 'createProject/succeeded'
  | 'createProject/failed'

export type CreateProjectActionPayloadType = {
  name: string
}

export const createProjectAction =
  (
    set: SetState<
      Partial<ProjectSliceType & StoreGlobalType>,
      CreateProjectActionStatusType,
      CreateProjectActionPayloadType
    >,
  ) =>
  async (payload: CreateProjectActionPayloadType, callback: (idProject: string) => void) => {
    try {
      set(
        (state) => {
          state.createProjectAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'createProject/loading', payload },
      )

      const projectApi = await createProject(payload)
      const project = projectApiMapper(projectApi)

      set(
        (state) => {
          state.createProjectAction.status = 'SUCCEEDED'
          state.projects.unshift(project)
        },
        false,
        { type: 'createProject/succeeded' },
      )

      callback && callback(project.idProject)
    } catch (e) {
      set(
        (state) => {
          state.createProjectAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'createProject/failed' },
      )
    }
  }

/****************************************************************
 * BOOST PROJECT
 *****************************************************************/

type BoostProjectActionStatusType =
  | 'boostProject/loading'
  | 'boostProject/succeeded'
  | 'boostProject/failed'

export type BoostProjectActionPayloadType = BoostProjectPayloadType

export const boostProjectAction =
  (
    set: SetState<
      Partial<ProjectSliceType & StoreGlobalType>,
      BoostProjectActionStatusType,
      BoostProjectActionPayloadType
    >,
  ) =>
  async (payload: BoostProjectActionPayloadType, callback?: () => void) => {
    try {
      set(
        (state) => {
          state.boostProjectAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'boostProject/loading', payload },
      )

      const projectApi = await boostProject(payload)
      const project = projectApiMapper(projectApi)

      set(
        (state) => {
          state.boostProjectAction.status = 'SUCCEEDED'
          state.success = getSuccess({})

          const index = state.projects?.findIndex(
            ({ idProject }) => idProject === payload.idProject,
          )
          if (index >= 0) {
            state.projects[index] = project
          }
        },
        false,
        { type: 'boostProject/succeeded' },
      )

      callback && callback()
    } catch (e) {
      set(
        (state) => {
          state.boostProjectAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'boostProject/failed' },
      )
    }
  }

/****************************************************************
 * STOP BOOST PROJECT
 *****************************************************************/

type StopBoostProjectActionStatusType =
  | 'stopBoostProject/loading'
  | 'stopBoostProject/succeeded'
  | 'stopBoostProject/failed'

export type StopBoostProjectActionPayloadType = {
  idProject: string
}

export const stopBoostProjectAction =
  (
    set: SetState<
      Partial<ProjectSliceType & StoreGlobalType>,
      StopBoostProjectActionStatusType,
      StopBoostProjectActionPayloadType
    >,
  ) =>
  async (payload: StopBoostProjectActionPayloadType, callback?: () => void) => {
    try {
      set(
        (state) => {
          state.stopBoostProjectAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'stopBoostProject/loading', payload },
      )

      const projectApi = await stopBoost(payload.idProject)
      const project = projectApiMapper(projectApi)

      set(
        (state) => {
          state.stopBoostProjectAction.status = 'SUCCEEDED'
          state.success = getSuccess({})

          const index = state.projects?.findIndex(
            ({ idProject }) => idProject === payload.idProject,
          )
          if (index >= 0) {
            state.projects[index] = project
          }
        },
        false,
        { type: 'stopBoostProject/succeeded' },
      )

      callback && callback()
    } catch (e) {
      set(
        (state) => {
          state.stopBoostProjectAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'stopBoostProject/failed' },
      )
    }
  }

/****************************************************************
 * STOP PROJECT
 *****************************************************************/

type StopProjectActionStatusType =
  | 'stopProject/loading'
  | 'stopProject/succeeded'
  | 'stopProject/failed'

export type StopProjectActionPayloadType = {
  idProject: string
}

export const stopProjectAction =
  (
    set: SetState<
      Partial<ProjectSliceType & StoreGlobalType>,
      StopProjectActionStatusType,
      StopProjectActionPayloadType
    >,
  ) =>
  async (payload: StopProjectActionPayloadType, callback?: () => void) => {
    try {
      set(
        (state) => {
          state.stopProjectAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'stopProject/loading', payload },
      )

      const projectApi = await stopProject(payload.idProject)
      const project = projectApiMapper(projectApi)

      set(
        (state) => {
          state.stopProjectAction.status = 'SUCCEEDED'
          state.success = getSuccess({})

          const index = state.projects?.findIndex(
            ({ idProject }) => idProject === payload.idProject,
          )
          if (index >= 0) {
            state.projects[index] = project
          }
        },
        false,
        { type: 'stopProject/succeeded' },
      )

      callback && callback()
    } catch (e) {
      set(
        (state) => {
          state.stopProjectAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'stopProject/failed' },
      )
    }
  }

/****************************************************************
 * ARCHIVE PROJECT
 *****************************************************************/

type ArchiveProjectActionStatusType =
  | 'archiveProject/loading'
  | 'archiveProject/succeeded'
  | 'archiveProject/failed'

export type ArchiveProjectActionPayloadType = {
  idProject: string
}

export const archiveProjectAction =
  (
    set: SetState<
      Partial<ProjectSliceType & StoreGlobalType>,
      ArchiveProjectActionStatusType,
      ArchiveProjectActionPayloadType
    >,
  ) =>
  async (payload: ArchiveProjectActionPayloadType, callback?: () => void) => {
    try {
      set(
        (state) => {
          state.archiveProjectAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'archiveProject/loading', payload },
      )

      const projectApi = await archiveProject(payload.idProject)
      const project = projectApiMapper(projectApi)

      set(
        (state) => {
          state.archiveProjectAction.status = 'SUCCEEDED'
          state.success = getSuccess({})

          const index = state.projects?.findIndex(
            ({ idProject }) => idProject === payload.idProject,
          )
          if (index >= 0) {
            state.projects[index] = project
          }
        },
        false,
        { type: 'archiveProject/succeeded' },
      )

      callback && callback()
    } catch (e) {
      set(
        (state) => {
          state.archiveProjectAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'archiveProject/failed' },
      )
    }
  }

/******************************************************************
 * DOWNLOAD PROJECTS FILE
 *****************************************************************/

type DownloadProjectsFileActionStatusType =
  | 'downloadProjectsFile/loading'
  | 'downloadProjectsFile/succeeded'
  | 'downloadProjectsFile/failed'

export const downloadProjectsFileAction =
  (
    set: SetState<
      Partial<ProjectSliceType & StoreGlobalType>,
      DownloadProjectsFileActionStatusType
    >,
  ) =>
  async () => {
    try {
      set(
        (state) => {
          state.downloadProjectsFileAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'downloadProjectsFile/loading' },
      )

      const data = await downloadProjects()
      downloadBlob([data], `Projects${shortTime()}.xlsx`)

      set(
        (state) => {
          state.downloadProjectsFileAction.status = 'SUCCEEDED'
        },
        false,
        { type: 'downloadProjectsFile/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.downloadProjectsFileAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'downloadProjectsFile/failed' },
      )
    }
  }
