import React, { useEffect } from 'react'
import { Dashboard } from '@views/dashboard'
import { useQuery } from 'react-query'
import { mutationKeys } from '@definitions'
import { GetProjectsPayloadType, getWorkflows } from '@api'
import { useDelayedEffect, useRequestParams } from '@hooks'
import { IGetWorkflowsReq, IWorkflow } from '@types'

import { store } from '@/store'

const DashboardPage = () => {
  const projects = store.use.projects()

  const getProjectsAction = store.use.getProjectsAction().call

  const {
    afterPageChange: afterPageChangeProjects,
    reqParams: reqParamsProjects,
    pagination: paginationProjects,
  } = useRequestParams<GetProjectsPayloadType>()

  const {
    afterPageChange: afterPageChangeWorkflows,
    reqParams: reqParamsWorkflows,
    pagination: paginationWorkflows,
  } = useRequestParams<IGetWorkflowsReq, IWorkflow>()

  const { data: workflows = [] } = useQuery({
    queryKey: mutationKeys.workflows(reqParamsWorkflows),
    queryFn: () => getWorkflows(reqParamsWorkflows),
    onSuccess: (items) => afterPageChangeWorkflows(items.length),
  })

  useDelayedEffect(() => {
    getProjectsAction(reqParamsProjects)
  }, [JSON.stringify(reqParamsProjects)])

  useEffect(() => {
    afterPageChangeProjects(projects.length)
  }, [projects.length])

  return (
    <Dashboard
      workflows={workflows}
      projects={projects}
      projectsPagination={paginationProjects}
      workflowsPagination={paginationWorkflows}
    />
  )
}

export default DashboardPage
