/** @jsxImportSource @emotion/react */

import { Controller, Control, ControllerProps } from 'react-hook-form'
import { useTranslation } from '@hooks'
import { FormItem, DatePicker } from 'common-components'

import SearchInput from './SearchInput'

type CommonFiltersPropsType<T> = {
  control: Control<any>
  controllerProps?: Omit<Partial<ControllerProps<T>>, 'render' | 'control'>
}
const CommonFilters = function CommonFilters<T>({
  control,
  controllerProps = {},
}: CommonFiltersPropsType<T>) {
  const { translate } = useTranslation()

  return (
    <>
      <Controller
        name={'search'}
        control={control}
        render={({ field }) => (
          <FormItem>
            <SearchInput onChange={field.onChange} value={field.value} />
          </FormItem>
        )}
        {...controllerProps}
      />

      <Controller
        name={'startDate'}
        control={control}
        render={({ field }) => (
          <FormItem label={translate('common.startDate')}>
            <DatePicker onChange={field.onChange} value={field.value} />
          </FormItem>
        )}
        {...controllerProps}
      />

      <Controller
        name={'endDate'}
        control={control}
        render={({ field }) => (
          <FormItem label={translate('common.endDate')}>
            <DatePicker onChange={field.onChange} value={field.value} />
          </FormItem>
        )}
        {...controllerProps}
      />
    </>
  )
}

export default CommonFilters
