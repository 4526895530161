import { PropsWithChildren } from 'react'
import { AuthProviderProps, AuthProvider as OIDCAuthProvider } from 'react-oidc-context'
import { oidcAuthority, oidcClientId, oidcRedirectUri, acrValues } from '@definitions'

const ProviderAuth = function ({ children }: PropsWithChildren<any>) {
  const oidcConfig: AuthProviderProps = {
    authority: oidcAuthority,
    client_id: oidcClientId,
    redirect_uri: oidcRedirectUri,
    scope: 'openid profile email',
    response_type: 'code',
    onSigninCallback: () => history.replaceState({}, '', oidcRedirectUri),
    post_logout_redirect_uri: window.location.origin,
    redirectMethod: 'replace',
    popup_post_logout_redirect_uri: window.location.origin,
    silent_redirect_uri: oidcRedirectUri,
  }

  if (acrValues.trim() !== 'tenant:') {
    oidcConfig.acr_values = acrValues
  }

  return <OIDCAuthProvider {...oidcConfig}>{children}</OIDCAuthProvider>
}

export default ProviderAuth
