/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useTranslation } from '@hooks'
import {
  TUserType,
  UserAccessLevel,
  IdManagerRightType,
  LABEL_MANAGER_RIGHT,
  ID_MANAGER_RIGHT,
  LabelManagerRightType,
} from '@definitions'
import { getManagers } from '@api'
import { UserProfile } from '@components/display'
import { getStateKeyByValue } from '@utils'
import { SelectAsync, ValueSelectType } from '@components/inputs'
import { useEffect, useState } from 'react'
import { MenuProps } from 'antd'
import { ManagerShortType } from '@pages/types'
import { LoadingOutlined } from '@ant-design/icons'
import { Row, Icon, Button, Space, Dropdown } from 'common-components'

const MENU_ITEMS: [IdManagerRightType, LabelManagerRightType][] = [
  [ID_MANAGER_RIGHT[2], LABEL_MANAGER_RIGHT[2]],
  [ID_MANAGER_RIGHT[3], LABEL_MANAGER_RIGHT[3]],
]

const managersUserMapper = (managersUser: ManagerShortType[]): UserOption[] =>
  managersUser.map((item) => ({
    value: item.idUser,
    label: `${item.firstName} ${item.lastName}`,
    ...item,
  }))

type UserOption = ValueSelectType & {
  idUser: string
  email: string
  lastName: string
  firstName: string
  urlAvatar: string
  managerRight: IdManagerRightType
  type: TUserType
}

export type AssignManagerPropsType = {
  managersUser?: ManagerShortType[]
  isLoading?: boolean
  /**
   * This action returns the current list of managers.
   */
  onUpdateManagers?: (managersShort: ManagerShortType[]) => void
  /**
   *  This function returns the added manager.
   */
  onAddManager?: (managerShort: ManagerShortType) => void
  /**
   *  This action returns the deleted manager.
   */
  onDeleteManager?: (managerShort: ManagerShortType) => void
}

type ManagerRightRenderPropsType = {
  key: number
  label: string
  onSelect: () => void
}
const menuItemRender = ({ key, label, onSelect }: ManagerRightRenderPropsType) => {
  return {
    label: <div onClick={onSelect}>{label}</div>,
    key,
  }
}

const AssignManager = ({
  managersUser = [],
  isLoading,
  onUpdateManagers,
  onDeleteManager,
  onAddManager,
}: AssignManagerPropsType) => {
  const { translate } = useTranslation()

  const [selectedManager, setSelectedManager] = useState<UserOption | ''>('')
  const [selectedManagers, setSelectedManagers] = useState<UserOption[]>(
    managersUserMapper(managersUser),
  )

  useEffect(() => {
    setSelectedManagers(managersUserMapper(managersUser))
  }, [JSON.stringify(managersUser)])

  const loadOptions = (inputValue: string, callback: (dataToPass: UserOption[]) => void) => {
    getManagers({
      orderByAsc: true,
      search: inputValue,
      model: {
        segments: [],
      },
    })
      .then((data) => {
        const dataToPass: UserOption[] = data.map((option) => ({
          idUser: option.idUser,
          email: option.email,
          urlAvatar: option.urlAvatar,
          firstName: option.firstName,
          lastName: option.lastName,
          type: option.type as TUserType,
          managerRight: ID_MANAGER_RIGHT[2],
          value: option.idUser,
          label: `${option.firstName} ${option.lastName}`,
        }))
        callback(dataToPass)
      })
      .catch(() => {
        callback([])
      })
  }

  const onSelectMenuItem = (idUser: string, idAccessLevel: IdManagerRightType) => {
    const newItems = selectedManagers.map((item) => {
      if (item.value === idUser) {
        const newItem = {
          ...item,
          managerRight: idAccessLevel,
        }
        onAddManager && onAddManager(newItem)
        return newItem
      }
      return item
    })
    setSelectedManagers(newItems)
    onUpdateManagers && onUpdateManagers(newItems)
  }

  return (
    <>
      <Row
        css={css`
          width: 100%;
          margin-top: 20px;
        `}
      >
        <Space
          css={css`
            display: grid;
            grid-template-columns: 25fr 1fr;
            gap: 6px;
            margin-bottom: 20px;
            width: 100%;
          `}
        >
          <SelectAsync<UserOption>
            loadOptions={loadOptions}
            valuesSelected={selectedManagers.map(({ label }) => label)}
            onChange={setSelectedManager}
            value={selectedManager}
          />
          <Button
            onClick={() => {
              if (selectedManager !== '') {
                const items = [...selectedManagers, selectedManager]
                setSelectedManagers(items)
                setSelectedManager('')
                onUpdateManagers && onUpdateManagers(items)
                onAddManager && onAddManager(selectedManager)
              }
            }}
            disabled={!selectedManager || isLoading}
          >
            {isLoading ? <LoadingOutlined /> : translate('display.add')}
          </Button>
        </Space>

        {selectedManagers.map((manager) => {
          const managerRights: MenuProps['items'] = MENU_ITEMS.map(
            ([idAccessLevel, labelAccessLevel]) => {
              return menuItemRender({
                key: idAccessLevel,
                label: labelAccessLevel,
                onSelect: () => onSelectMenuItem(manager.idUser, idAccessLevel),
              })
            },
          )

          return (
            <Row
              justify={'space-between'}
              align='middle'
              css={css`
                height: 44px;
                width: 100%;
              `}
            >
              <UserProfile
                name={`${manager.firstName || ''} ${manager.lastName || ''}`}
                avatar={manager.urlAvatar}
              />
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  gap: 20px;
                `}
              >
                <Dropdown
                  menu={{ items: managerRights }}
                  trigger={['click']}
                  disabled={manager.managerRight === ID_MANAGER_RIGHT[1]}
                  placement='bottomRight'
                >
                  <Space>
                    {UserAccessLevel[manager.managerRight]}
                    {manager.managerRight !== ID_MANAGER_RIGHT[1] && <Icon icon='ChevronDown' />}
                  </Space>
                </Dropdown>
                <Button
                  type='text'
                  disabled={manager.managerRight === getStateKeyByValue(UserAccessLevel, 'creator')}
                  onClick={() => {
                    const items = selectedManagers.filter(({ value }) => value !== manager.idUser)
                    setSelectedManagers(items)
                    onUpdateManagers && onUpdateManagers(items)
                    onDeleteManager && onDeleteManager(manager)
                  }}
                >
                  <Icon width={18} height={18} icon='XClose' />
                </Button>
              </div>
            </Row>
          )
        })}
      </Row>
    </>
  )
}

export default AssignManager
