const fr = {
  pageTitle: 'Segments',
  pageTitleSelected: 'segments sélectionnés',
  pageDescription:
    'Un segment est un groupe de collaborateurs. Combinez des segments pour définir les audiences de vos projets.',
  headerButtons: {
    export: 'Exporter',
    filter: 'Filtrer',
    modify: 'Modifier',
    create: 'Créer un segment',
    add: 'Ajouter des managers',
  },
  tableColumns: {
    name: 'Nom',
    type: 'Type',
    privileges: 'Mes droits',
    members: 'Membres',
    managers: 'Managers',
    use: 'Utilisation',
  },
  request: 'Demander',
  requestModal: {
    title: 'Demander un accès',
    description: 'Demandez aux administrateur de ce segment de vous octroyer l’accès.',
    ok: 'Confirmer',
    cancel: 'Annuler',
  },
  segmentType: {
    competence: 'Compétence',
    manual: 'Manuelle',
    certification: 'Certification',
    socioDemographic: 'Socio-démographique',
  },
  addManagers: 'Ajouter des managers',
  back: 'Retour',
  finish: 'Terminer',
  deleteSegment: {
    button: 'Supprimer',
    modal: {
      title: 'Supprimer un segment',
      subTitle1: 'Supprimer',
      subTitle2: 'segment',
      subTitle3: 'segments',
      description: 'Êtes-vous sûr de vouloir supprimer ce segment ?',
      button: 'Supprimer',
    },
  },
}

export default fr
