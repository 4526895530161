import {
  CertificationApiType,
  CertificationUserTestApiType,
  CertificationUserTestQuestionApiType,
  QuestionApiType,
} from '@api/types'
import {
  CertificationType,
  CertificationUserTestQuestionType,
  CertificationUserTestType,
  QuestionType,
} from '@pages/types/certification'
import { ticksToTime } from '@utils'
import { ID_COLUMN_HYDRATION } from '@views/shared/constants'

import { assignRoleToSegmentOrCertification, removeNullAndUndefinedMapper } from './helper.mapper'

import { contributorShortApiMapper, managerShortApiMapper } from '@/mappers/user.mapper'
import { segmentShortApiMapper } from '@/mappers/segment.mapper'
import { formShortApiMapper } from '@/mappers/form.mapper'

export const certificationApiMapper = (
  certificationApi: CertificationApiType,
  idMe: string,
): CertificationType => {
  try {
    if (!certificationApi.idCertification) {
      throw Error('idCertification not found')
    }

    if (!idMe) {
      throw Error('idMe not found')
    }

    const result: CertificationType = {
      nbUsers: certificationApi?.nbUsers,
      segmentsShort: certificationApi?.segments?.map(segmentShortApiMapper) || [],
      name: certificationApi?.name,
      managerRight: certificationApi?.managerRight,
      icon: certificationApi?.icon,
      idCertification: certificationApi.idCertification,
      state: certificationApi?.state,
      nbPublishedQuestions: certificationApi?.nbPublishedQuestions,
      questions: certificationApi?.questions?.map(questionApiMapper) || [],
      managersShort: certificationApi?.managers?.map(managerShortApiMapper) || [],
      formShort: formShortApiMapper(certificationApi?.form),
      role: assignRoleToSegmentOrCertification(idMe, certificationApi?.managers),
      audience: certificationApi?.audience || [],
      nbQuestionsTest: isNaN(certificationApi?.nbQuestionsTest)
        ? 0
        : Number(certificationApi?.nbQuestionsTest),
      nbAttemptsMax: isNaN(certificationApi?.nbAttemptsMax)
        ? 0
        : Number(certificationApi?.nbAttemptsMax),
      nbQuestions: isNaN(certificationApi?.nbQuestions) ? 0 : Number(certificationApi?.nbQuestions),
      prefillType: certificationApi?.prefillType,
    }
    return removeNullAndUndefinedMapper<CertificationType>(result)
  } catch (e) {
    throw e
  }
}

export const questionApiMapper = (questionApi: QuestionApiType): QuestionType => {
  try {
    const inputs = questionApi?.inputs || []
    if (questionApi?.inputJson) {
      inputs.push({
        data: JSON.stringify(questionApi.inputJson),
        name: ID_COLUMN_HYDRATION,
        type: 'hydration_json',
      })
    }

    const result = {
      idCertification: questionApi.idCertification,
      idCertificationQuestion: questionApi?.idCertificationQuestion,
      state: questionApi?.state,
      training: questionApi?.training,
      successRate: questionApi?.successRate,
      inputs,
      inputJson: questionApi?.inputJson,
    }

    return removeNullAndUndefinedMapper<QuestionType>(result)
  } catch (e) {
    throw e
  }
}

export const certificationUserTestQuestionApiMapper = (
  certificationUserTestQuestionApi: CertificationUserTestQuestionApiType,
): CertificationUserTestQuestionType => {
  try {
    const result: CertificationUserTestQuestionType = {
      idCertificationUserTestQuestion:
        certificationUserTestQuestionApi?.idCertificationUserTestQuestion,
      idCertificationQuestion: certificationUserTestQuestionApi?.idCertificationQuestion,
      idCertificationUserTest: certificationUserTestQuestionApi?.idCertificationUserTest,
      mark: certificationUserTestQuestionApi?.mark,
      duration: ticksToTime(certificationUserTestQuestionApi?.duration),
    }

    return removeNullAndUndefinedMapper<CertificationUserTestQuestionType>(result)
  } catch (e) {
    throw e
  }
}

export const certificationUserTestApiMapper = (
  certificationUserTestApi: CertificationUserTestApiType,
  idMe: string,
): CertificationUserTestType => {
  try {
    const result: CertificationUserTestType = {
      idCertificationUserTest: certificationUserTestApi?.idCertificationUserTest,
      idCertification: certificationUserTestApi?.idCertification,
      name: certificationUserTestApi?.name,
      user: contributorShortApiMapper(certificationUserTestApi?.user),
      idUser: certificationUserTestApi?.idUser,
      creationDate: certificationUserTestApi?.creationDate,
      mark: certificationUserTestApi?.mark,
      state: certificationUserTestApi?.state,
      certification: certificationApiMapper(certificationUserTestApi.certification, idMe),
      questions:
        certificationUserTestApi?.questions?.map(certificationUserTestQuestionApiMapper) || [],
      // TODO remove ????
      //   {
      //   ...certificationUserTestApi?.certification,
      //   managersShort:
      //     certificationUserTestApi?.certification?.managers?.map(managerShortApiMapper) || [],
      //   segmentsShort:
      //     certificationUserTestApi?.certification?.segments?.map(segmentShortApiMapper) || [],
      // },
      userType: certificationUserTestApi?.userType,
      duration: ticksToTime(certificationUserTestApi?.duration),
    }

    return removeNullAndUndefinedMapper<CertificationUserTestType>(result)
  } catch (e) {
    throw e
  }
}
