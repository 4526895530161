import { Outlet, useOutletContext } from 'react-router'
import { Content } from 'common-components'
import { useDelayedEffect, useRequestParams } from '@hooks'
import { GetCertificationUserTestsPayloadType } from '@api'
import { CertificationType, CertificationUserTestType } from '@pages/types'
import { useEffect } from 'react'
import { Loader } from '@components/utils'

import { store } from '@/store'

const CertificationContainer = () => {
  const { certification } = useOutletContext<{ certification: CertificationType }>()

  const respondentsFiltered = store.use.respondentsFiltered()

  const getRespondentsCertificationAction = store.use.getRespondentsCertificationAction().call

  const isLoading = store.use.getRespondentsCertificationAction().status === 'LOADING'
  const isSucceeded = store.use.getRespondentsCertificationAction().status === 'SUCCEEDED'

  const {
    afterPageChange,
    pagination,
    onTableStateChange: onChange,
  } = useRequestParams<GetCertificationUserTestsPayloadType, CertificationUserTestType>()

  useDelayedEffect(() => {
    getRespondentsCertificationAction({
      model: { idCertification: certification.idCertification },
    })
  }, [certification.idCertification])

  useEffect(() => {
    afterPageChange(respondentsFiltered?.length)
  }, [respondentsFiltered?.length])

  return (
    <Content>
      {isLoading && <Loader />}
      {isSucceeded && (
        <Outlet
          context={{
            pagination,
            onChange,
            respondentsFiltered: respondentsFiltered,
          }}
        />
      )}
    </Content>
  )
}

export default CertificationContainer
