/** @jsxImportSource @emotion/react */

import { useTranslation } from '@hooks/useTranslation'
import { FC, useState, useEffect } from 'react'
import { UpdateProjectManagersPayloadType, UpdateProjectSettingsPayloadType } from '@api/project'
import { TAlertType, TSlaType } from '@definitions'
import { Dayjs } from 'dayjs'
import { ProjectType } from '@pages/types'
import { AssignManager } from '@views/shared/manager'
import { css } from '@emotion/react'
import { Button, colors, Form, FormElements, Row, Space, Text } from 'common-components'
import { LoadingOutlined } from '@ant-design/icons'

import {
  ParametersCollapse,
  ProductivityTargetsCollapse,
  AlertsCollapse,
  SlaCollapse,
  WebhookPanel,
} from '../shared'

import GeneralSettingsPanel from './GeneralSettingsPanel'

import { store } from '@/store'

type GeneralFormSettingsProjectPropsType = {
  project: ProjectType
}

type FormValuesType = {
  name: string
  slaType: TSlaType
  delay: Dayjs
  leverageHours?: {
    nbDays: number
    leverageTime: Dayjs
    deleveringTime: Dayjs
  }[]
  averageDeleveringTimeGoal: Dayjs
  effectiveTimeTaskGoal: Dayjs
  effectiveTimeHitGoal: Dayjs
  qualityExpected: number
  diversification: number
  nbMinEvaluation: number
  expectedQualityMin: number
  urlWebhook: string
  smsAlert: TAlertType
  emailAlert: TAlertType
}

const GeneralFormSettingsProject: FC<GeneralFormSettingsProjectPropsType> = ({ project }) => {
  const { translate } = useTranslation()
  const [form] = FormElements.useForm<FormValuesType>()

  const [managersProject, setManagersProject] = useState<ProjectType['managers']>([])

  const updateSettingsProjectAction = store.use.updateSettingsProjectAction().call
  const addManagersToProjectAction = store.use.addManagersToProjectAction().call

  const isLoadingUpdateSettingsProjectAction =
    store.use.updateSettingsProjectAction().status === 'LOADING'
  const isLoadingAddManagersToProjectAction =
    store.use.addManagersToProjectAction().status === 'LOADING'

  useEffect(() => {
    if (!project) return
    if (project.managers?.length) {
      const managersProject = project.managers.map((manager) => ({
        idUser: manager.idUser,
        email: manager.email,
        firstName: manager.firstName,
        lastName: manager.lastName,
        type: manager.type,
        urlAvatar: manager.urlAvatar,
        managerRight: manager.managerRight,
      }))
      setManagersProject(managersProject)
    }
  }, [project])

  const handleSubmit = (values: FormValuesType) => {
    const updateProjectSettingsPayload: UpdateProjectSettingsPayloadType = {
      id: project.idProject,
      name: values.name || project?.name,
      sla: {
        type: values?.slaType || project?.sla?.type,
        delay: values?.delay?.format('HH:mm:ss') || project?.sla?.delay,
        leverageHours:
          values.leverageHours?.map((prop) => ({
            leverageTime: prop.leverageTime.format('HH:mm:ss'),
            nbDays: prop.nbDays,
            deleveringTime: prop.deleveringTime.format('HH:mm:ss'),
          })) || project?.sla?.leverageHours,
        averageDeleveringTimeGoal:
          values?.averageDeleveringTimeGoal?.format('HH:mm:ss') ||
          project?.sla?.averageDeleveringTimeGoal,
        effectiveTimeTaskGoal:
          values?.effectiveTimeTaskGoal?.format('HH:mm:ss') || project?.sla?.effectiveTimeTaskGoal,
        effectiveTimeHitGoal:
          values?.effectiveTimeHitGoal?.format('HH:mm:ss') || project?.sla?.effectiveTimeHitGoal,
        qualityExpected: values?.qualityExpected || project?.sla?.qualityExpected,
      },
      settings: {
        diversification: values?.diversification || project?.settings?.diversification,
        nbMinEvaluation: values?.nbMinEvaluation || project?.settings?.nbMinEvaluation,
        expectedQualityMin: values?.expectedQualityMin || project?.settings?.expectedQualityMin,
        urlWebhook: values?.urlWebhook,
        smsAlert:
          typeof values?.smsAlert === 'number' ? values?.smsAlert : project?.settings?.smsAlert,
        emailAlert:
          typeof values?.emailAlert === 'number'
            ? values?.emailAlert
            : project?.settings?.emailAlert,
      },
    }
    const updateProjectManagerPayload: UpdateProjectManagersPayloadType = {
      idProject: project.idProject,
      managers: managersProject.map((item) => ({
        idUser: item.idUser,
        email: item.email,
        firstName: item.firstName,
        lastName: item.lastName,
        type: item.type,
        urlAvatar: item.urlAvatar,
        managerRight: item.managerRight,
      })),
    }

    updateSettingsProjectAction(
      { idProject: project.idProject, payload: updateProjectSettingsPayload },
      () => {
        addManagersToProjectAction(updateProjectManagerPayload)
      },
    )
  }

  return (
    <Form form={form} onFinish={handleSubmit}>
      <Space direction='vertical' style={{ width: '100%', gap: 16 }}>
        <GeneralSettingsPanel name={project?.name} idForm={project?.form?.idForm} />
        <SlaCollapse
          type={project?.sla?.type}
          leverageHours={project?.sla?.leverageHours}
          delay={project?.sla?.delay}
        />
        <ProductivityTargetsCollapse
          averageDeleveringTimeGoal={project?.sla?.averageDeleveringTimeGoal}
          effectiveTimeTaskGoal={project?.sla?.effectiveTimeTaskGoal}
          effectiveTimeHitGoal={project?.sla?.effectiveTimeHitGoal}
          qualityExpected={project?.sla?.qualityExpected}
        />
        <AlertsCollapse
          page='create'
          smsAlert={project?.settings?.smsAlert}
          emailAlert={project?.settings?.emailAlert}
        />
        <ParametersCollapse
          page='settings'
          diversification={project?.settings?.diversification}
          nbMinEvaluation={project?.settings?.nbMinEvaluation}
          expectedQualityMin={project?.settings?.expectedQualityMin}
        />

        <Space
          direction='vertical'
          style={{
            width: '100%',
            border: `1px solid ${colors.gray200}`,
            borderRadius: 16,
            padding: 24,
          }}
        >
          <Row justify={'space-between'} align={'middle'}>
            <Text
              css={css`
                font-weight: 500;
              `}
              color={colors.gray900}
              size='xl'
            >
              {translate('createSegment.chooseManagers')}
            </Text>
          </Row>
          <AssignManager
            managersUser={managersProject}
            onUpdateManagers={(managersProject) => setManagersProject(managersProject)}
          />
        </Space>

        <WebhookPanel urlWebhook={project?.settings?.urlWebhook} />
        <Button
          type='primary'
          htmlType='submit'
          style={{
            width: '150px',
          }}
          disabled={isLoadingUpdateSettingsProjectAction || isLoadingAddManagersToProjectAction}
        >
          {isLoadingAddManagersToProjectAction || isLoadingUpdateSettingsProjectAction ? (
            <LoadingOutlined />
          ) : (
            translate('project.settings.buttons.saveChanges')
          )}
        </Button>
      </Space>
    </Form>
  )
}

export default GeneralFormSettingsProject
