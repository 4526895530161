/** @jsxImportSource @emotion/react */

import { Controller, useForm } from 'react-hook-form'
import { css } from '@emotion/react'
import { RiskState } from '@definitions'
import { CommonFilters, SearchInput, StateCheckbox } from '@components/inputs'
import { useTranslation } from '@hooks'
import { useMemo } from 'react'
import { GetRisksPayloadType } from '@api/risk'
import {
  Button,
  Drawer,
  Form,
  FormItem,
  Space,
  Title,
  Text,
  SelectOption,
  SelectInput,
} from 'common-components'
import { getRiskLevelValueKey } from '@utils/getRiskLevelValueKey'
import { TxKeyPath } from '@i18n'

type FiltersRiskPropsType = {
  visible: boolean
  onFiltersChange?: (filters: GetRisksPayloadType) => void
  onClose?: () => void
}

const FiltersRisk = ({ visible, onClose, onFiltersChange }: FiltersRiskPropsType) => {
  const { control, handleSubmit, reset } = useForm<GetRisksPayloadType>({
    defaultValues: { model: {} },
  })
  const { translate } = useTranslation()

  const onSubmit = (data: GetRisksPayloadType) => {
    if (data.model.score) {
      data.model.score = Number(data.model.score)
    } else {
      delete data.model.score
    }
    onFiltersChange && onFiltersChange(data)
    onClose && onClose()
  }

  const riskState = useMemo(() => {
    const state = RiskState
    delete state[99]
    return state
  }, [])

  return (
    <Drawer
      title={
        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <Title preset='tablePageTitle'>{translate('common.filter')}</Title>
          <Text>{translate('common.apply')}</Text>
        </div>
      }
      placement='right'
      closable={true}
      onClose={onClose}
      closeIcon={false}
      open={visible}
    >
      <Form onFinish={handleSubmit(onSubmit)} css={$form}>
        <CommonFilters<GetRisksPayloadType> control={control} />

        <Controller
          name={'model.states' as any}
          control={control}
          render={({ field }) => (
            <FormItem label={translate('common.state')}>
              <StateCheckbox
                values={riskState}
                labelTxKey={'riskState'}
                preset={'risk-state'}
                inputProps={{
                  onChange: (values) => field.onChange(values),
                  value: field.value,
                }}
              />
            </FormItem>
          )}
        />

        <Controller
          name={'model.level'}
          control={control}
          render={({ field }) => (
            <FormItem label={translate('common.risk')}>
              <SelectInput value={field.value} onChange={field.onChange}>
                {[10, 20, 30].map((level, i) => (
                  <SelectOption key={`${i}-${level}`} value={level} title={`${level}`}>
                    {translate(`risk.levelStates.${getRiskLevelValueKey(level)}` as TxKeyPath)}
                  </SelectOption>
                ))}
              </SelectInput>
            </FormItem>
          )}
        />

        <Controller
          name={'model.score'}
          control={control}
          render={({ field }) => (
            <FormItem label={translate('common.score')}>
              <SearchInput
                type='number'
                onChange={field.onChange}
                value={field.value}
                min={0}
                max={100}
                placeholder='%'
              />
            </FormItem>
          )}
        />

        <Space css={[$fullWidth, $footerButtonsContainer]} size={10}>
          <Button onClick={() => reset()}>Cancel</Button>
          <Button type='primary' htmlType='submit'>
            Apply
          </Button>
        </Space>
      </Form>
    </Drawer>
  )
}

const $form = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const $fullWidth = css`
  width: 100%;
`

const $footerButtonsContainer = css`
  justify-content: center;
`

export default FiltersRisk
