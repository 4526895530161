const en = {
  activeAlertsFilter: 'Active alerts only',
  taskOnProgressFilter: 'Have on progress task',
  modelSelection: 'Model Selection',
  versionSelection: 'Version selection',
  prefillType: 'Prefill type',
  navigation: {
    title: 'Validation of grey card',
    online: 'On-line',
    boost: 'Boost',
    boosted: 'Boosted',
    stopBoost: 'Stop boost',
    export: 'Export',
    review: 'Review',
    addingData: 'Adding data',
  },
  modal: {
    title: 'Exporting responses',
    description: 'Export your project responses to begin mining the data.',
    period: 'Period',
    radioTitle1: 'Format',
    radioOption1: 'Best response',
    radioOption2: 'All answers',
    radioTitle2: 'Format',
    radioOption3: 'Excel',
    radioOption4: 'JSON',
    subTitle: 'Export of duplicable groups',
    export: 'Export',
  },
  taskline: {
    title: 'Tasklines',
    table: {
      id: 'Taskline ID',
      creationDate: 'Date of creation',
      origin: 'Origin',
      priority: 'Priority',
      deadline: 'Deadline',
      state: 'Status',
      costs: 'Costs',
      provider: 'Provider',
      note: 'Note',
      time: 'Time',
      model: 'Model',
    },
  },
  settings: {
    title: 'Parameters',
    pills: {
      general: 'General',
      model: 'Model',
    },
    buttons: {
      saveChanges: 'Save changes',
    },
    labels: {
      name: 'Name',
      form: 'Form',
    },
  },
  audience: {
    title: 'Audience',
    subtitle:
      'Based on the parameters you have chosen, we estimate that you will have a task volume of 10459 tasks over the following period, broken down as follows:',
    pills: {
      contributers: 'Contributers',
      reviewers: 'Reviewers',
      excludedContributers: 'Excluded contributors',
    },
    buttons: {
      addGroup: 'Add group',
      addSegment: 'Add segment',
      saveChanges: 'Save changes',
    },
    select: {
      10: 'And',
      20: 'Or',
    },
    blacklist: {
      title: 'Excluded contributors',
      button: 'Reintegrate',
    },
  },
  tasklineOverview: {
    title: 'Taskline {{id}}',
    hitTitle: 'Taskline hit {{id}}',
    modelTitle: 'Taskline model {{id}}',
    menuItems: {
      initialEntryView: 'Initial entry',
      taskView: 'Vue globale de la taskline',
      wirkModelView: 'Wirk Model',
      taskOverloadView: 'Task overload',
    },
    actionsTitle: 'Actions',
    actionsItems: {
      requestNewIteration: 'Request for new iteration',
      requestPrioritizing: 'Request prioritizing',
      requestDeprioritizing: 'Request deprioritizing',
      requestOverloadTaskCreation: 'Request overload task creation from this task',
      deleteTask: 'Delete task',
    },
    sidebar: {
      title: 'Information',
      contributor: {
        title: 'Contributor',
        removeButton: 'Remove',
        assignButton: 'Assign',
        inProgress: 'In progress',
        search: 'Search for...',
      },
      taskMetrics: {
        title: 'Task metrics',
        taskId: 'Task ID',
        id: 'ID',
        requestId: 'Request ID',
        status: 'Status',
        costInEur: 'Cost in €**',
        quality: 'Quality',
        duration: 'Duration',
        durationTotal: 'Total duration',
        effectiveTime: 'Effective time',
        depositDate: 'Deposit date',
        deadlineDate: 'Deadline date',
        deliveryDate: 'Delivery date',
        submissionDate: 'Submission date',
        creationDate: 'Creation date',
      },
      history: {
        title: 'Logs',
      },
      review: {
        title: 'Review',
        rate: 'Rate',
        reviewer: 'Reviewer',
        reviewDate: 'Date of review',
        editButton: 'Edit',
      },
    },
  },
  notifications: {
    nextHitError: 'No hits to review',
  },
}

export default en
export type Translations = typeof en
