import { CollapseWithPill } from '@components/display'
import { useTranslation } from '@hooks/useTranslation'
import { useMemo, useState, useEffect } from 'react'
import { TAlertType } from '@definitions/states'
import { Row, Space } from 'common-components'

import ColumnWithToggle from './ColumnWithToggle'

type AlertsCollapsePropsType = {
  page: 'create' | 'settings'
  smsAlert: TAlertType | null
  emailAlert: TAlertType | null
}

const AlertsCollapse = ({ page, smsAlert, emailAlert }: AlertsCollapsePropsType) => {
  const { translate } = useTranslation()
  const [isAlertsOpen, setIsAlertsOpen] = useState<boolean>(false)

  useEffect(() => {
    if (smsAlert || emailAlert) setIsAlertsOpen(true)
  }, [smsAlert, emailAlert])

  const AlertsCollapseContent = useMemo(
    () => (
      <Space direction='vertical' style={{ width: '100%', padding: '0 24px 24px', gap: 16 }}>
        <Row gutter={16}>
          <ColumnWithToggle type='sms' name='smsAlert' value={smsAlert} />
          <ColumnWithToggle type='email' name='emailAlert' value={emailAlert} />
        </Row>
      </Space>
    ),
    [smsAlert, emailAlert],
  )

  return (
    <CollapseWithPill
      panels={[
        {
          title: translate('projectCreatePage.collapse.alerts.title'),
          subtitle: translate('projectCreatePage.collapse.alerts.subtitle'),
          isOpen: page === 'create' ? isAlertsOpen : true,
          setIsOpen: setIsAlertsOpen,
          content: AlertsCollapseContent,
          withPill: page === 'create',
        },
      ]}
    />
  )
}

export default AlertsCollapse
