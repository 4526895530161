import { useNotification } from 'common-components'
import { Outlet } from 'react-router'
import { useEffect } from 'react'
import { useTranslation } from '@hooks'
import { Loader } from '@components/utils'

import { store } from '@/store'

const RootContainer = () => {
  const error = store.use.error()
  const success = store.use.success()

  const deleteErrorNotificationAction = store.use.deleteErrorNotificationAction()
  const deleteSuccessNotificationAction = store.use.deleteSuccessNotificationAction()
  const getMeAction = store.use.getMeAction().call

  const isLoadingGetMeAction = store.use.getMeAction().status === 'LOADING'
  const isSucceededGetMeAction = store.use.getMeAction().status === 'SUCCEEDED'

  const { contextHolder, notification } = useNotification()
  const { translate: t } = useTranslation()

  useEffect(() => {
    getMeAction()
  }, [])

  useEffect(() => {
    if (error?.id) {
      const message = error?.messageTranslate ? t(error.messageTranslate) : undefined
      const description = error?.descriptionTranslate
        ? t(error.descriptionTranslate)
        : error?.description

      notification.error({
        message,
        description,
        duration: error?.duration,
        onClose: () => deleteErrorNotificationAction(),
      })
    }
  }, [error?.id])

  useEffect(() => {
    if (success?.id) {
      const message = success?.messageTranslate ? t(success.messageTranslate) : undefined
      const description = success?.descriptionTranslate
        ? t(success.descriptionTranslate)
        : undefined

      notification.success({
        message,
        description,
        duration: success?.duration,
        onClose: () => deleteSuccessNotificationAction(),
      })
    }
  }, [success?.id])

  return (
    <>
      {contextHolder}
      {isLoadingGetMeAction && <Loader />}
      {!isLoadingGetMeAction && isSucceededGetMeAction && <Outlet />}
    </>
  )
}

export default RootContainer
