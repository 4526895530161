/** @jsxImportSource @emotion/react */

import { Button, Content, useDisclosure } from 'common-components'
import { ContributorType, SegmentType } from '@pages/types'
import { PageLayout } from '@components/layout'
import { DeleteModal } from '@components/modal'
import { useRequestParams, useTranslation } from '@hooks'
import { AssignContributorModal, TableContributors } from '@views/shared/contributor'
import { useEffect, useState } from 'react'
import { SegmentDefType, SegmentTypeType } from '@definitions'
import { getStateKeyByValue } from '@utils'
import { GetSuppliersPayloadType } from '@api'

import { SubHeaderSegment } from '../shared'

import { store } from '@/store'

const SEGMENT_MANUAL = getStateKeyByValue<SegmentTypeType>(SegmentDefType, 'manual')

type ContributorsSegmentPropsType = {
  segment: SegmentType
}

const ContributorsSegment = ({ segment }: ContributorsSegmentPropsType) => {
  const contributors = store.use.contributorsSegment()
  const contributorsShort = segment.contributorsShort
  const segmentType = segment.segmentType

  const deleteContributorsFromSegmentAction = store.use.deleteContributorsFromSegmentAction().call
  const addContributorToSegmentAction = store.use.addContributorToSegmentAction().call

  const isLoadingDeleteContributorsFromSegmentAction =
    store.use.deleteContributorsFromSegmentAction().status === 'LOADING'
  const isLoadingAddContributorToSegmentAction =
    store.use.addContributorToSegmentAction().status === 'LOADING'

  const { afterPageChange, pagination, onTableStateChange } = useRequestParams<
    GetSuppliersPayloadType,
    ContributorType
  >({
    initParams: { model: {} },
  })

  const { translate } = useTranslation()
  const [idContributorsSelected, setIdContributorsSelected] = useState<string[]>([])
  const useDisclosureAssignContributorsModal = useDisclosure()
  const useDisclosureDeleteContributorsModal = useDisclosure()

  useEffect(() => {
    afterPageChange(contributors?.length)
  }, [contributors?.length])

  return (
    <PageLayout>
      <Content>
        <SubHeaderSegment
          texts={[
            {
              type: 'tablePageTitle',
              content: translate('segment.contributorsTab.label'),
            },
          ]}
          count={contributors.length}
        >
          {/*** The actions on a single contributor ***/}
          {segmentType === SEGMENT_MANUAL && !idContributorsSelected.length && (
            <Button
              onClick={useDisclosureAssignContributorsModal.open}
              type='default'
              iconLeft='Plus'
            >
              {translate('segment.contributorsTab.button')}
            </Button>
          )}
          {/*** The actions on multiple contributors ***/}
          {segmentType === SEGMENT_MANUAL && !!idContributorsSelected.length && (
            <Button
              type='danger'
              onClick={useDisclosureDeleteContributorsModal.open}
              iconLeft='Trash01'
            >
              {translate('managers.delete')}
            </Button>
          )}
        </SubHeaderSegment>

        <TableContributors
          contributors={contributors}
          setSelectedCheckboxes={setIdContributorsSelected}
          selectedCheckboxes={idContributorsSelected}
          tableProps={{ pagination, onChange: onTableStateChange }}
        />

        {/*Assign contributors to Segments */}
        {useDisclosureAssignContributorsModal.isOpen && (
          <AssignContributorModal
            isLoading={isLoadingAddContributorToSegmentAction}
            contributors={contributorsShort}
            onDeleteContributor={({ idUser }) =>
              deleteContributorsFromSegmentAction(
                {
                  idSegment: segment.idSegment,
                  idContributors: [idUser],
                },
                () => useDisclosureAssignContributorsModal.close(),
              )
            }
            onAddContributor={(contributorShort) =>
              addContributorToSegmentAction({
                idSegment: segment.idSegment,
                contributorShort,
              })
            }
            count={idContributorsSelected.length}
            close={useDisclosureAssignContributorsModal.close}
            isOpen={useDisclosureAssignContributorsModal.isOpen}
          />
        )}
        {/*delete contributor*/}
        {useDisclosureDeleteContributorsModal.isOpen && (
          <DeleteModal
            isLoading={isLoadingDeleteContributorsFromSegmentAction}
            close={useDisclosureDeleteContributorsModal.close}
            isOpen={useDisclosureDeleteContributorsModal.isOpen}
            title={`${translate('managers.deleteUser1')} ${idContributorsSelected.length} ${
              idContributorsSelected.length === 1
                ? translate('managers.deleteUser2')
                : translate('managers.deleteUser3')
            }
              `}
            label={translate(`contributor.delete.modal.label`)}
            onDeleteMutation={async () =>
              deleteContributorsFromSegmentAction(
                {
                  idSegment: segment.idSegment,
                  idContributors: idContributorsSelected,
                },
                () => {
                  useDisclosureDeleteContributorsModal.close()
                  setIdContributorsSelected([])
                },
              )
            }
          />
        )}
      </Content>
    </PageLayout>
  )
}

export default ContributorsSegment
