const fr = {
  addManager: 'Ajouter un manager',
  lastName: {
    errorMessage: 'Le nom de famille est obligatoire',
    label: 'Nom',
  },
  firstName: {
    errorMessage: 'Le prénom est obligatoire',
    label: 'Prénom',
  },
  phone: {
    errorMessage: 'Le numéro de téléphone est obligatoire',
    label: 'Téléphone',
  },
  email: {
    errorMessage: 'Veuillez introduire une adresse e-mail valide',
    label: 'Email',
  },
  role: {
    errorMessage: 'Le rôle est obligatoire',
    label: 'Rôle',
    selectOptions: {
      worker: 'Worker',
      candidate: 'Candidate',
      manager: 'Manager',
      admin: 'Admin',
    },
  },
}

export default fr
