const fr = {
  numberOfProjects: 'Nombre de projets',
  treatedTasks: 'Tâches traitées',
  averageQuality: 'Qualité moyenne du segment',
  contributors: 'Contributeurs',
  activeProjects: 'Projets actifs',
  managers: 'Managers',
  created: 'Créée le',
  by: 'par',
  usersTableColumns: {
    name: 'Nom',
    role: 'Rôle',
    time: 'Temps effectif',
    status: 'Statut',
  },
  projectsTableColumns: {
    name: 'Nom',
    tasks: 'Tâches effectuées',
  },
  managersTableColumns: {
    name: 'Nom',
    rights: 'Droits',
  },
  updateModal: {
    title: 'Modifier le segment',
    subtitle: 'Créez un segment de contributeurs',
  },
  contributorsTab: {
    label: 'Contributeurs',
    button: 'Ajouter un contributeur',
  },
  managersTab: {
    label: 'Manageurs',
    button: 'Ajouter un manageur',
  },
}

export default fr
