/** @jsxImportSource @emotion/react */
import { useTranslation } from '@hooks/useTranslation'
import { FC, useMemo } from 'react'
import { DataLengthTag } from '@components/display'
import { ColumnsType } from 'antd/lib/table'
import { css } from '@emotion/react'
import { ProjectAudienceBlacklistedUserType } from '@pages/types'
import { Button, colors, Space, Title, Table, Checkbox, Row, Text } from 'common-components'

type AudienceExcludedReviewersPropsType = {
  blacklistedUsers: ProjectAudienceBlacklistedUserType[]
}

const ExcludedReviewersAudienceProject: FC<AudienceExcludedReviewersPropsType> = ({
  blacklistedUsers,
}) => {
  const { translate } = useTranslation()

  const tableClassOverride = css`
    border-radius: 0 !important;
    border: none !important;
    border-top: 1px solid ${colors.gray200} !important;
  `

  const tableColumns: ColumnsType<ProjectAudienceBlacklistedUserType> = useMemo(() => {
    try {
      return [
        {
          key: 'checkbox',
          render: () => <Checkbox name='checkbox' />,
        },
        {
          title: 'id',
          key: 'id',
          render: (record: ProjectAudienceBlacklistedUserType) => (
            <Row>
              <Text size='sm' strong>
                {record.idUser}
              </Text>
            </Row>
          ),
        },
      ]
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
      return []
    }
  }, [])

  return (
    <div style={{ border: `1px solid ${colors.gray200}`, borderRadius: 12 }}>
      <Space
        style={{
          width: '100%',
          justifyContent: 'space-between',
          padding: '20px 24px',
        }}
        align='center'
      >
        <Space align='center'>
          <Title preset='singlePageTitle'>{translate('project.audience.blacklist.title')}</Title>
          <DataLengthTag>{blacklistedUsers?.length || 0}</DataLengthTag>
        </Space>
        <Button size='md'>{translate('project.audience.blacklist.button')}</Button>
      </Space>
      <Table<ProjectAudienceBlacklistedUserType>
        data={blacklistedUsers}
        columns={tableColumns}
        css={tableClassOverride}
      />
    </div>
  )
}

export default ExcludedReviewersAudienceProject
