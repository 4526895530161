/** @jsxImportSource @emotion/react */
import { useTranslation } from '@hooks/useTranslation'
import { HitStateType } from '@definitions/states'
import { PresetTag } from '@components/display'
import { routes } from '@navigation/routes'
import { useNavigate } from 'react-router'
import { getStateKeyByValue } from '@utils'
import { HitType } from '@pages/types'
import { Button, Space } from 'common-components'

import { tasklineHitViews } from '../../shared'

type HitsTablePropsType = {
  hits: HitType[]
  taskId: string
  projectId: string
}

const TableHits = ({ hits, taskId, projectId }: HitsTablePropsType) => {
  const { translate } = useTranslation()
  const navigate = useNavigate()

  const goToHit = (hit: HitType) => {
    navigate(
      routes.projectTaskHit.navigate(projectId, taskId, `${tasklineHitViews[0]}/${hit.idHit}`),
    )
  }

  const onRowClick = (hit: HitType) => goToHit(hit)

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '2px',
      }}
    >
      {hits.map((hit) => (
        <div
          onClick={() => onRowClick(hit)}
          style={{
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'row',
            padding: '10px',
            justifyContent: 'space-between',
            borderBottom: 'solid',
            borderBottomWidth: '1px',
            borderBottomColor: '#e9e9e9',
            paddingLeft: '60px',
          }}
        >
          {/* hit */}
          <div
            style={{
              width: '260px',
              fontWeight: 'bold',
            }}
          >
            #{hit.idHit}
          </div>
          {/* creationDate */}
          <div>-</div>
          {/* hitType */}
          <div
            style={{
              width: '125px',
            }}
          >
            {translate(`common.states.hitType.${hit.hitType}`)}
          </div>
          {/* priority */}
          <div>-</div>
          {/* deleveringDate */}
          <div>-</div>
          <div
            style={{
              width: '136px',
            }}
          >
            <PresetTag withDot preset='hit-state' state={hit.hitState}>
              {translate(`common.states.hitState.${hit.hitState}`)}
            </PresetTag>
          </div>
          {/* totalCost */}
          <div>-</div>
          {/* provider */}
          <div>-</div>
          <div
            style={{
              width: '114px',
            }}
          >
            {hit.hitState === getStateKeyByValue(HitStateType, 'completed') ? (
              <Button
                size={'inline'}
                onClick={(e) => {
                  e.stopPropagation()
                  goToHit(hit)
                }}
              >
                Note
              </Button>
            ) : (
              <Space size={'middle'}>-</Space>
            )}
          </div>
          <div
            style={{
              width: '86px',
            }}
          >
            {hit.duration}
          </div>
          {/* accuracyRateModel */}
          <div>-</div>
        </div>
      ))}
    </div>
  )
}

export default TableHits
