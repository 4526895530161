/** @jsxImportSource @emotion/react */

import { IWorkflow } from '@types'
import styled from '@emotion/styled'
import { dateTime } from '@utils'
import { WorkflowType } from '@definitions'
import { capitalizeFirstLetter } from '@utils/capitalize-first'
import { css } from '@emotion/react'
import { useTranslation } from '@hooks/useTranslation'
import {
  Col,
  colors,
  HoverCard,
  Icon,
  IconButton,
  IHoverCardProps,
  Row,
  Space,
  spacing,
  Text,
} from 'common-components'
import { PresetTag } from '@components/display'

export type CardWorkflowPropsType = IHoverCardProps & {
  workflow: IWorkflow
  onWorkflowListSelect?: (workflow: IWorkflow) => void
  onWorkflowSelect?: (workflow: IWorkflow) => void
}

const HeaderContainer = styled(Row)`
  padding-top: ${spacing.padding.card.default}px;
  padding-left: ${spacing.padding.card.default}px;
  padding-right: ${spacing.padding.card.default}px;
`
const ContentContainer = styled(Col)`
  padding: ${spacing.padding.card.default}px;
`
const FooterContainer = styled(Space)`
  width: 100%;
  height: 56px;
  justify-content: center;
`
const HoverContentContainer = styled(Row)`
  width: 100%;
`
const CardWorkflow = function ({
  workflow,
  onWorkflowListSelect,
  onWorkflowSelect,
  ContentComponent,
  ...rest
}: CardWorkflowPropsType) {
  const { translate } = useTranslation()

  return (
    <HoverCard
      HeaderComponent={
        <HeaderContainer align={'middle'} justify={'end'}>
          <PresetTag
            withDot
            preset={'workflow-type'}
            state={workflow.type}
            css={css`
              margin-inline-end: 0px;
            `}
          >
            {capitalizeFirstLetter(WorkflowType[workflow.type])}
          </PresetTag>
        </HeaderContainer>
      }
      ContentComponent={
        ContentComponent || (
          <ContentContainer>
            <Space direction='vertical'>
              <Text strong size='md'>
                {workflow.name}
              </Text>
              <Text size='xs' color={colors.gray400}>{`${translate(
                'worklfows.lastUpdated',
              )} ${dateTime(workflow.updateDate)}`}</Text>
            </Space>
          </ContentContainer>
        )
      }
      footerContent={
        <FooterContainer align={'center'} size={10}>
          <Space size={5} align={'start'}>
            <Icon icon={'Lightning02'} color={colors.gray400} size={16} />
            <Text size='xs'>{workflow.nbExecution}</Text>
          </Space>
          <Space size={5} align={'start'}>
            <Icon icon={'AlertHexagon'} color={colors.gray400} size={16} />
            <Text size='xs'>{workflow.errorRate}</Text>
          </Space>
        </FooterContainer>
      }
      hoverFooterContent={
        <HoverContentContainer align={'middle'} justify={'center'}>
          {onWorkflowListSelect && (
            <IconButton
              icon={'Dataflow01'}
              type={'text'}
              iconProps={{ color: colors.white }}
              onClick={() => onWorkflowListSelect(workflow)}
            />
          )}
          {onWorkflowSelect && (
            <IconButton
              icon={'List'}
              type={'text'}
              iconProps={{ color: colors.white }}
              onClick={() => onWorkflowSelect(workflow)}
            />
          )}
        </HoverContentContainer>
      }
      {...rest}
    />
  )
}

export default CardWorkflow
