export const getWirkUrl = (key: string) => {
  try {
    // @ts-ignore
    const url = process.env.WIRK['WebApps'][key].URL
    return url
  } catch (e) {
    throw new Error("URL don't exist")
  }
}

export const getWirkConfig = (key1: string, key2: string) => {
  try {
    // @ts-ignore
    const url = process.env.WIRK['WebApps'][key1][key2]
    return url
  } catch (e) {
    throw new Error("Config don't exist")
  }
}
