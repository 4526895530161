import { useEffect, useState } from 'react'
import { useTranslation } from '@hooks/useTranslation'
import { FormType, ProjectType } from '@pages/types'
import { Button, Form, Space, useDisclosure } from 'common-components'
import { LoadingOutlined } from '@ant-design/icons'

import { PrefillSettingsCollapse } from './prefillSettings'
import FormCollapse from './FormCollapse'

import { store } from '@/store'

type Step1CreateProjectPropsType = {
  project: ProjectType
  isLoadingNextStep: boolean
  nextStep: () => void
}

const Step1CreateProject = ({
  project,
  isLoadingNextStep,
  nextStep,
}: Step1CreateProjectPropsType) => {
  const { translate } = useTranslation()

  const updateFormProjectAction = store.use.updateFormProjectAction().call
  const updatePrefillProjectAction = store.use.updatePrefillProjectAction().call

  const isLoadingUpdateFormProjectAction = store.use.updateFormProjectAction().status === 'LOADING'
  const isLoadingUpdatePrefillProjectAction =
    store.use.updatePrefillProjectAction().status === 'LOADING'

  const formPanelUseDisclosure = useDisclosure()

  const [isOpenFormCollapse, setIsOpenFormCollapse] = useState<boolean>(false)
  const [isOpenAICollapse, setIsOpenAICollapse] = useState<boolean>(false)

  const [form, setForm] = useState<FormType>()
  const [isValidStep, setIsValidStep] = useState<boolean>()
  const [prefillSettings, setPrefillSettings] = useState<ProjectType['prefillSettings']>()

  const onUpdateFormProjectMutation = async (formSelected: FormType) => {
    updateFormProjectAction({ idProject: project.idProject, form: formSelected }, () => {
      setForm(formSelected)
      formPanelUseDisclosure.close()
    })
  }

  const onSubmit = async () => {
    updatePrefillProjectAction({ idProject: project.idProject, prefillSettings }, nextStep)
  }

  const validationSteps = () => {
    let isValid = false

    if (isOpenFormCollapse && project?.form?.idForm?.length) {
      if (!isOpenAICollapse && prefillSettings?.type === 0) {
        isValid = true
      } else {
        if (prefillSettings?.type === 3) {
          isValid = true
        }
        if (prefillSettings?.type === 1 || prefillSettings?.type === 4) {
          if (prefillSettings?.aiGenerative?.idAIGenerative) {
            isValid = true
          }
        }
        if (prefillSettings?.type === 2 && prefillSettings?.model) {
          isValid = true
        }
      }
    }

    setIsValidStep(isValid)
  }

  useEffect(() => {
    setIsOpenFormCollapse(!!project?.form?.idForm?.length)
  }, [project?.form?.idForm])

  useEffect(() => {
    if (!isOpenAICollapse) {
      setPrefillSettings({
        type: 0,
        model: undefined,
        aiGenerative: undefined,
      })
    }
  }, [isOpenAICollapse])

  useEffect(() => {
    validationSteps()
  }, [isOpenAICollapse, isOpenFormCollapse, project?.form?.idForm, JSON.stringify(prefillSettings)])

  return (
    <Form style={{ display: 'block' }}>
      <Space style={{ width: '100%', flexDirection: 'column', alignItems: 'unset', gap: 16 }}>
        <FormCollapse
          isOpen={isOpenFormCollapse}
          project={project}
          useDisclosure={formPanelUseDisclosure}
          setIsOpen={setIsOpenFormCollapse}
          updateFormProject={onUpdateFormProjectMutation}
        />
        <PrefillSettingsCollapse
          isOpen={isOpenAICollapse}
          project={project}
          form={form}
          setIsOpen={setIsOpenAICollapse}
          onChange={setPrefillSettings}
        />
      </Space>
      <Space style={{ width: '100%', marginTop: 8, justifyContent: 'flex-end' }}>
        <Button
          disabled={
            !isValidStep ||
            isLoadingNextStep ||
            isLoadingUpdatePrefillProjectAction ||
            isLoadingUpdateFormProjectAction
          }
          type='primary'
          onClick={onSubmit}
          style={{
            width: '150px',
          }}
        >
          {isLoadingNextStep ? (
            <LoadingOutlined />
          ) : (
            translate('projectCreatePage.submitButton.step1')
          )}
        </Button>
      </Space>
    </Form>
  )
}

export default Step1CreateProject
