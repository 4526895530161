import { getMe } from '@api'
import { getMeOidc } from '@definitions'

import { SetState } from '../store.type'
import { NotificationSliceType } from '../notification'
import { getApiError } from '../helper'

import { MeSliceType } from './me.slice'

import { certificationShortApiMapper, segmentShortApiMapper, userDataApiMapper } from '@/mappers'

/******************************************************************
 * GET ME
 *****************************************************************/

type GetMeActionStatusType = 'getMe/loading' | 'getMe/succeeded' | 'getMe/failed'

export const getMeAction =
  (set: SetState<Partial<MeSliceType & NotificationSliceType>, GetMeActionStatusType>) =>
  async () => {
    try {
      set(
        (state) => {
          state.getMeAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'getMe/loading' },
      )

      const meApi = await getMe()

      const userData = userDataApiMapper(meApi)
      const segmentsShort = meApi?.segmentsManager?.map(segmentShortApiMapper)
      const certificationsShort = meApi.certificationsManager?.map(certificationShortApiMapper)
      const accessToken = getMeOidc().access_token

      set(
        (state) => {
          state.getMeAction.status = 'SUCCEEDED'
          state.me = {
            accessToken,
            userData,
            segmentsShort,
            certificationsShort,
          }
        },
        false,
        { type: 'getMe/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.getMeAction.status = 'FAILED'
          state.me = undefined
          state.error = getApiError(e)
        },
        false,
        { type: 'getMe/failed' },
      )
    }
  }
