import { useParams } from 'react-router'
import { TasksProject } from '@views/project'
import { GetTasksPayloadType } from '@api'
import { TaskType } from '@pages/types'
import { useEffect } from 'react'
import { useDelayedEffect, useRequestParams } from '@hooks'

import { store } from '@/store'

const TasksProjectPage = () => {
  const { projectId } = useParams()

  const tasksFiltered = store.use.tasksFiltered()

  const getTasksAction = store.use.getTasksAction().call

  const { afterPageChange, pagination, onTableStateChange, reqParams } = useRequestParams<
    GetTasksPayloadType['filters'],
    TaskType
  >({
    initParams: { model: {} },
  })

  useDelayedEffect(() => {
    getTasksAction({ idProject: projectId, filters: reqParams })
  }, [JSON.stringify(reqParams)])

  useEffect(() => {
    afterPageChange(tasksFiltered.length)
  }, [tasksFiltered.length])

  return (
    <TasksProject tableProps={{ pagination, onChange: onTableStateChange }} tasks={tasksFiltered} />
  )
}

export default TasksProjectPage
