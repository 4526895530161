import {
  createCertification,
  CreateCertificationPayloadType,
  createCertificationSegment,
  CreateCertificationSegmentPayloadType,
  getCertification,
  GetCertificationPayloadType,
  getCertifications,
  GetCertificationsPayloadType,
  nextStepCertification,
  NextStepCertificationType,
  publishCertification,
  removeSegment,
  requestAccessForCertification,
  updateCertificationForm,
  UpdateCertificationFormPayloadType,
  updateCertificationInfo,
  updateCertificationManagers,
  updateCertificationSegment,
  UpdateCertificationSegmentPayloadType,
  updateCertificationSegments,
  updateCertificationSettings,
  updateOnlineState,
  updatePrefillCertification,
  UpdatePrefillCertificationPayloadType,
} from '@api'
import { FormType, ManagerShortType, SegmentShortType } from '@pages/types'
import { IconType } from '@types'
import { CertificationApiType, SegmentApiType } from '@api/types'

import { SetState } from '../store.type'
import { getApiError, getSuccess } from '../helper'
import { StoreGlobalType } from '../store'

import { CertificationSliceType } from './certification.slice'

import { certificationApiMapper } from '@/mappers'

const updateCertificationFormPayloadMapper = (
  idCertification: string,
  formCurrent: FormType,
): UpdateCertificationFormPayloadType => {
  try {
    const columns =
      formCurrent.template?.template?.data?.map((col) => ({
        name: col.field,
        type: col.type,
      })) || []

    const form: UpdateCertificationFormPayloadType['form'] = {
      idForm: formCurrent.idForm,
      name: formCurrent.name,
      columns,
      quality: formCurrent.template.template.quality,
      averageDuration: formCurrent.template.template.averageDuration,
      minimumDuration: formCurrent.template.template.minimumDuration,
      keySkill: formCurrent.template.template.keySkill,
      hasSubdivide: formCurrent.template.template.hasSubdivide,
      subdivide: formCurrent.template.template.subdivide,
      allowError: formCurrent.template.template.allowError,
      prefillInputs: formCurrent.template.template.prefillInputs,
      comparisonRules: formCurrent.comparisonRules,
      fieldAssociation: formCurrent.fieldAssociation,
      fields: formCurrent.fields,
      hideIfRules: formCurrent.hideIfRules,
      ignoredFields: formCurrent.ignoredFields,
      iterationOverload: formCurrent.iterationOverload,
      nodes: formCurrent.nodes,
      validationRules: formCurrent.validationRules,
    }

    return {
      idCertification,
      form,
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
  }
}

const certificationSegmentApiMapper = (segmentApi: SegmentApiType): SegmentShortType => {
  try {
    return {
      idSegment: segmentApi.idSegment,
      icon: segmentApi?.icon,
      name: segmentApi?.name,
      managerRight: segmentApi?.managerRight,
      segmentType: segmentApi?.segmentType,
      qualityMin: segmentApi?.qualityMin,
      nbMinEvaluation: segmentApi?.nbMinEvaluation,
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
    return
  }
}

/**
 * Synchronize certitication
 */

export const synchronizeCertification = async (idCertification: string, idMe: string) => {
  const certificationApi = await getCertification({ idCertification })
  return certificationApiMapper(certificationApi, idMe)
}

/****************************************************************
 * CREATE CERTIFICATION
 *****************************************************************/

type CreateCertificationActionStatusType =
  | 'createCertification/loading'
  | 'createCertification/succeeded'
  | 'createCertification/failed'

export type CreateCertificationActionPayloadType = CreateCertificationPayloadType

export const createCertificationAction =
  (
    set: SetState<
      Partial<CertificationSliceType & StoreGlobalType>,
      CreateCertificationActionStatusType,
      CreateCertificationActionPayloadType
    >,
    get: () => StoreGlobalType & CertificationSliceType,
  ) =>
  async (
    payload: CreateCertificationActionPayloadType,
    callback: (idCertification: string) => void,
  ) => {
    try {
      set(
        (state) => {
          state.createCertificationAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'createCertification/loading', payload },
      )

      const idMe = get().me.userData.idUser
      const certificationApi = await createCertification(payload)
      const certification = certificationApiMapper(certificationApi, idMe)

      set(
        (state) => {
          state.createCertificationAction.status = 'SUCCEEDED'
          state.success = getSuccess({})

          state.certifications.unshift(certification)
        },
        false,
        { type: 'createCertification/succeeded' },
      )

      callback(certification.idCertification)
    } catch (e) {
      set(
        (state) => {
          state.createCertificationAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'createCertification/failed' },
      )
    }
  }

/****************************************************************
 * UPDATE FORM CERTIFICATION
 *****************************************************************/

type UpdateFormCertificationActionStatusType =
  | 'updateFormCertification/loading'
  | 'updateFormCertification/succeeded'
  | 'updateFormCertification/failed'

export type UpdateFormCertificationActionPayloadType = {
  idCertification: string
  form: FormType
}

export const updateFormCertificationAction =
  (
    set: SetState<
      Partial<CertificationSliceType & StoreGlobalType>,
      UpdateFormCertificationActionStatusType,
      UpdateFormCertificationActionPayloadType
    >,
    get: () => StoreGlobalType & CertificationSliceType,
  ) =>
  async (payload: UpdateFormCertificationActionPayloadType) => {
    try {
      set(
        (state) => {
          state.updateFormCertificationAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'updateFormCertification/loading', payload },
      )

      const idMe = get().me.userData.idUser
      const payloadApi = updateCertificationFormPayloadMapper(payload.idCertification, payload.form)
      const certificationApi = await updateCertificationForm(payloadApi)
      const certification = certificationApiMapper(certificationApi, idMe)

      set(
        (state) => {
          state.updateFormCertificationAction.status = 'SUCCEEDED'
          const index = state.certifications.findIndex(
            ({ idCertification }) => idCertification === payload.idCertification,
          )
          if (index >= 0) {
            state.certifications[index] = certification
          }
        },
        false,
        { type: 'updateFormCertification/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.updateFormCertificationAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'updateFormCertification/failed' },
      )
    }
  }

/****************************************************************
 * UPDATE PREFILL TYPE CERTIFICATION
 *****************************************************************/

type UpdatePrefillTypeCertificationActionStatusType =
  | 'updatePrefillTypeCertification/loading'
  | 'updatePrefillTypeCertification/succeeded'
  | 'updatePrefillTypeCertification/failed'

export type UpdatePrefillTypeCertificationActionPayloadType = UpdatePrefillCertificationPayloadType

export const updatePrefillTypeCertificationAction =
  (
    set: SetState<
      Partial<CertificationSliceType & StoreGlobalType>,
      UpdatePrefillTypeCertificationActionStatusType,
      UpdatePrefillTypeCertificationActionPayloadType
    >,
  ) =>
  async (payload: UpdatePrefillTypeCertificationActionPayloadType, callback: () => void) => {
    try {
      set(
        (state) => {
          state.updatePrefillTypeCertificationAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'updatePrefillTypeCertification/loading', payload },
      )

      await updatePrefillCertification(payload)

      set(
        (state) => {
          state.updatePrefillTypeCertificationAction.status = 'SUCCEEDED'
          const index = state.certifications.findIndex(
            ({ idCertification }) => idCertification === payload.idCertification,
          )
          if (index >= 0) {
            state.certifications[index].prefillType = payload.prefillType
          }
        },
        false,
        { type: 'updatePrefillTypeCertification/succeeded' },
      )

      callback()
    } catch (e) {
      set(
        (state) => {
          state.updatePrefillTypeCertificationAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'updatePrefillTypeCertification/failed' },
      )
    }
  }

/****************************************************************
 * UPDATE NEXT STEP CERTIFICATION
 *****************************************************************/

type UpdateNextStepCertificationActionStatusType =
  | 'updateNextStepCertification/loading'
  | 'updateNextStepCertification/succeeded'
  | 'updateNextStepCertification/failed'

export type UpdateNextStepCertificationActionPayloadType = NextStepCertificationType

export const updateNextStepCertificationAction =
  (
    set: SetState<
      Partial<CertificationSliceType & StoreGlobalType>,
      UpdateNextStepCertificationActionStatusType,
      UpdateNextStepCertificationActionPayloadType
    >,
  ) =>
  async (
    payload: UpdateNextStepCertificationActionPayloadType,
    callback: (nextStep: number) => void,
  ) => {
    try {
      set(
        (state) => {
          state.updateNextStepCertificationAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'updateNextStepCertification/loading', payload },
      )

      const nextStep = await nextStepCertification(payload)

      set(
        (state) => {
          state.updateNextStepCertificationAction.status = 'SUCCEEDED'
          state.success = getSuccess({})

          const index = state.certifications.findIndex(
            ({ idCertification }) => idCertification === payload.idCertification,
          )
          if (index >= 0) {
            state.certifications[index].state = nextStep
          }
        },
        false,
        { type: 'updateNextStepCertification/succeeded' },
      )

      callback(nextStep)
    } catch (e) {
      set(
        (state) => {
          state.updateNextStepCertificationAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'updateNextStepCertification/failed' },
      )
    }
  }

/****************************************************************
 * GET CERTIFICATIONS
 *****************************************************************/

type GetCertificationsActionStatusType =
  | 'getCertifications/loading'
  | 'getCertifications/succeeded'
  | 'getCertifications/failed'

export type GetCertificationsActionPayloadType = GetCertificationsPayloadType

export const getCertificationsAction =
  (
    set: SetState<
      Partial<CertificationSliceType & StoreGlobalType>,
      GetCertificationsActionStatusType,
      GetCertificationsActionPayloadType
    >,
    get: () => StoreGlobalType & CertificationSliceType,
  ) =>
  async (payload: GetCertificationsActionPayloadType) => {
    try {
      set(
        (state) => {
          state.getCertificationsAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'getCertifications/loading', payload },
      )

      const idMe = get().me.userData.idUser
      const certificationsApi = await getCertifications(payload)
      const certifications = certificationsApi?.map((certificationApi) =>
        certificationApiMapper(certificationApi, idMe),
      )

      set(
        (state) => {
          state.getCertificationsAction.status = 'SUCCEEDED'
          state.certifications = certifications
        },
        false,
        { type: 'getCertifications/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.getCertificationsAction.status = 'FAILED'
          state.certifications = []
          state.error = getApiError(e)
        },
        false,
        { type: 'getCertifications/failed' },
      )
    }
  }

/****************************************************************
 * GET CERTIFICATION
 *****************************************************************/

type GetCertificationActionStatusType =
  | 'getCertification/loading'
  | 'getCertification/succeeded'
  | 'getCertification/failed'

export type GetCertificationActionPayloadType = GetCertificationPayloadType

export const getCertificationAction =
  (
    set: SetState<
      Partial<CertificationSliceType & StoreGlobalType>,
      GetCertificationActionStatusType,
      GetCertificationActionPayloadType
    >,
    get: () => StoreGlobalType & CertificationSliceType,
  ) =>
  async (payload: GetCertificationActionPayloadType) => {
    try {
      set(
        (state) => {
          state.getCertificationAction.status = 'LOADING'
          state.questionsFiltered = []
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'getCertification/loading', payload },
      )

      const idMe = get().me.userData.idUser
      const certification = await synchronizeCertification(payload.idCertification, idMe)

      set(
        (state) => {
          state.getCertificationAction.status = 'SUCCEEDED'

          const index = state.certifications?.findIndex(
            ({ idCertification }) => idCertification === payload.idCertification,
          )
          if (index >= 0) {
            state.certifications[index] = certification
          } else {
            state.certifications.unshift(certification)
          }
        },
        false,
        { type: 'getCertification/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.getCertificationAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'getCertification/failed' },
      )
    }
  }

/****************************************************************
 * UPLOAD ACCESS CERTIFICATION
 *****************************************************************/

type UploadAccessCertificationActionStatusType =
  | 'uploadAccessCertification/loading'
  | 'uploadAccessCertification/succeeded'
  | 'uploadAccessCertification/failed'

export type UploadAccessCertificationActionPayloadType = {
  idCertification: string
}

export const uploadAccessCertificationAction =
  (
    set: SetState<
      Partial<CertificationSliceType & StoreGlobalType>,
      UploadAccessCertificationActionStatusType,
      UploadAccessCertificationActionPayloadType
    >,
  ) =>
  async (payload: UploadAccessCertificationActionPayloadType) => {
    try {
      set(
        (state) => {
          state.uploadAccessCertificationAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'uploadAccessCertification/loading', payload },
      )

      await requestAccessForCertification(payload)

      set(
        (state) => {
          state.uploadAccessCertificationAction.status = 'SUCCEEDED'
          state.success = getSuccess({
            descriptionT: 'certificationsPage.notifications.requestAccessSuccess',
          })
        },
        false,
        { type: 'uploadAccessCertification/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.uploadAccessCertificationAction.status = 'FAILED'
          state.error = {
            ...getApiError(e),
            descriptionTranslate: 'certificationsPage.notifications.requestAccessError',
          }
        },
        false,
        { type: 'uploadAccessCertification/failed' },
      )
    }
  }

/****************************************************************
 * UPDATE PREFILL TYPE CERTIFICATION
 *****************************************************************/

type UpdateInformationCertificationActionStatusType =
  | 'updateInformationCertification/loading'
  | 'updateInformationCertification/succeeded'
  | 'updateInformationCertification/failed'

export type UpdateInformationCertificationActionPayloadType = {
  idCertification: string
  nbQuestionsTest?: number
  nbAttemptsMax?: number
  icon?: IconType
  name?: string
}

export const updateInformationCertificationAction =
  (
    set: SetState<
      Partial<CertificationSliceType & StoreGlobalType>,
      UpdateInformationCertificationActionStatusType,
      UpdateInformationCertificationActionPayloadType
    >,
  ) =>
  async (payload: UpdateInformationCertificationActionPayloadType, callback: () => void) => {
    try {
      set(
        (state) => {
          state.updateInformationCertificationAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'updateInformationCertification/loading', payload },
      )

      // TODO Remove this check after implementing the verification in the form for creating certification and updating settings.
      const nbQuestionsTest = isNaN(payload?.nbQuestionsTest) ? 0 : Number(payload?.nbQuestionsTest)
      const nbAttemptsMax = isNaN(payload?.nbAttemptsMax) ? 0 : Number(payload?.nbAttemptsMax)

      await updateCertificationSettings({
        idCertification: payload.idCertification,
        nbQuestionsTest,
        nbAttemptsMax,
      })
      await updateCertificationInfo({
        idCertification: payload.idCertification,
        icon: payload?.icon,
        name: payload?.name,
      })

      set(
        (state) => {
          state.updateInformationCertificationAction.status = 'SUCCEEDED'
          const index = state.certifications.findIndex(
            ({ idCertification }) => idCertification === payload.idCertification,
          )
          if (index >= 0) {
            state.certifications[index].nbQuestionsTest = nbQuestionsTest
            state.certifications[index].nbAttemptsMax = nbAttemptsMax
            state.certifications[index].icon = payload.icon
            state.certifications[index].name = payload.name
          }
        },
        false,
        { type: 'updateInformationCertification/succeeded' },
      )

      callback && callback()
    } catch (e) {
      set(
        (state) => {
          state.updateInformationCertificationAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'updateInformationCertification/failed' },
      )
    }
  }

/****************************************************************
 * ADD MANAGERS TO CERTIFICATION
 *****************************************************************/

type AddManagersToCertificationActionStatusType =
  | 'addManagersToCertification/loading'
  | 'addManagersToCertification/succeeded'
  | 'addManagersToCertification/failed'

export type AddManagersToCertificationActionPayloadType = {
  idCertification: string
  managersShort: ManagerShortType[]
}

export const addManagersToCertificationAction =
  (
    set: SetState<
      Partial<CertificationSliceType & StoreGlobalType>,
      AddManagersToCertificationActionStatusType,
      AddManagersToCertificationActionPayloadType
    >,
  ) =>
  async (payload: AddManagersToCertificationActionPayloadType) => {
    try {
      set(
        (state) => {
          state.addManagersToCertificationAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'addManagersToCertification/loading', payload },
      )

      await updateCertificationManagers({
        idCertification: payload.idCertification,
        managers: payload.managersShort,
      })

      set(
        (state) => {
          state.addManagersToCertificationAction.status = 'SUCCEEDED'
          const index = state.certifications.findIndex(
            ({ idCertification }) => idCertification === payload.idCertification,
          )
          if (index >= 0) {
            state.certifications[index].managersShort = payload.managersShort
          }
        },
        false,
        { type: 'addManagersToCertification/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.addManagersToCertificationAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'addManagersToCertification/failed' },
      )
    }
  }

/****************************************************************
 * CREATE SEGMENT CERTIFICATION
 *****************************************************************/

type CreateSegmentCertificationActionStatusType =
  | 'createSegmentCertification/loading'
  | 'createSegmentCertification/succeeded'
  | 'createSegmentCertification/failed'

export type CreateSegmentCertificationActionPayloadType = CreateCertificationSegmentPayloadType

export const createSegmentCertificationAction =
  (
    set: SetState<
      Partial<CertificationSliceType & StoreGlobalType>,
      CreateSegmentCertificationActionStatusType,
      CreateSegmentCertificationActionPayloadType
    >,
    get: () => CertificationSliceType,
  ) =>
  async (payload: CreateSegmentCertificationActionPayloadType) => {
    try {
      set(
        (state) => {
          state.createSegmentCertificationAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'createSegmentCertification/loading', payload },
      )

      const segmentApi = await createCertificationSegment(payload)
      const segmentShort = certificationSegmentApiMapper(segmentApi)

      const indexCertification = get().certifications.findIndex(
        ({ idCertification }) => idCertification === payload.certification.idCertification,
      )
      let newSegmentsShort: SegmentShortType[] = []

      if (indexCertification >= 0) {
        newSegmentsShort =
          get().certifications[indexCertification].segmentsShort.concat(segmentShort)
      }

      await updateCertificationSegments({
        idCertification: payload.certification.idCertification,
        segments: newSegmentsShort,
      })

      set(
        (state) => {
          state.createSegmentCertificationAction.status = 'SUCCEEDED'
          state.certifications[indexCertification].segmentsShort = newSegmentsShort
        },
        false,
        { type: 'createSegmentCertification/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.createSegmentCertificationAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'createSegmentCertification/failed' },
      )
    }
  }

/****************************************************************
 * UPDATE SEGMENT CERTIFICATION
 *****************************************************************/

type UpdateSegmentCertificationActionStatusType =
  | 'updateSegmentCertification/loading'
  | 'updateSegmentCertification/succeeded'
  | 'updateSegmentCertification/failed'

export type UpdateSegmentCertificationActionPayloadType = UpdateCertificationSegmentPayloadType

export const updateSegmentCertificationAction =
  (
    set: SetState<
      Partial<CertificationSliceType & StoreGlobalType>,
      UpdateSegmentCertificationActionStatusType,
      UpdateSegmentCertificationActionPayloadType
    >,
    get: () => CertificationSliceType,
  ) =>
  async (payload: UpdateSegmentCertificationActionPayloadType) => {
    try {
      set(
        (state) => {
          state.updateSegmentCertificationAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'updateSegmentCertification/loading', payload },
      )

      const segmentApi = await updateCertificationSegment(payload)
      const segmentShort = certificationSegmentApiMapper(segmentApi)

      const indexCertification = get().certifications.findIndex(
        ({ idCertification }) => idCertification === payload.certification.idCertification,
      )
      let newSegmentsShort: SegmentShortType[] = []

      if (indexCertification >= 0) {
        newSegmentsShort = get().certifications[indexCertification]?.segmentsShort?.map((item) => {
          if (item.idSegment === payload.idSegment) {
            return segmentShort
          }
          return item
        })
      }

      await updateCertificationSegments({
        idCertification: payload.certification.idCertification,
        segments: newSegmentsShort,
      })

      set(
        (state) => {
          state.updateSegmentCertificationAction.status = 'SUCCEEDED'
          state.certifications[indexCertification].segmentsShort = newSegmentsShort
        },
        false,
        { type: 'updateSegmentCertification/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.updateSegmentCertificationAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'updateSegmentCertification/failed' },
      )
    }
  }

/****************************************************************
 * DELETE SEGMENT CERTIFICATION
 *****************************************************************/

type DeleteSegmentCertificationActionStatusType =
  | 'deleteSegmentCertification/loading'
  | 'deleteSegmentCertification/succeeded'
  | 'deleteSegmentCertification/failed'

export type DeleteSegmentCertificationActionPayloadType = {
  idCertification: string
  idSegment: string
}

export const deleteSegmentCertificationAction =
  (
    set: SetState<
      Partial<CertificationSliceType & StoreGlobalType>,
      DeleteSegmentCertificationActionStatusType,
      DeleteSegmentCertificationActionPayloadType
    >,
    get: () => CertificationSliceType,
  ) =>
  async (payload: DeleteSegmentCertificationActionPayloadType) => {
    try {
      set(
        (state) => {
          state.deleteSegmentCertificationAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'deleteSegmentCertification/loading', payload },
      )

      await removeSegment(payload.idSegment)

      const indexCertification = get().certifications.findIndex(
        ({ idCertification }) => idCertification === payload.idCertification,
      )
      let newSegmentsShort: SegmentShortType[] = []

      if (indexCertification >= 0) {
        newSegmentsShort = get().certifications[indexCertification]?.segmentsShort?.filter(
          ({ idSegment }) => idSegment !== payload.idSegment,
        )
      }

      await updateCertificationSegments({
        idCertification: payload.idCertification,
        segments: newSegmentsShort,
      })

      set(
        (state) => {
          state.deleteSegmentCertificationAction.status = 'SUCCEEDED'
          state.certifications[indexCertification].segmentsShort = newSegmentsShort
        },
        false,
        { type: 'deleteSegmentCertification/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.deleteSegmentCertificationAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'deleteSegmentCertification/failed' },
      )
    }
  }

/****************************************************************
 * UPDATE STATE CERTIFICATION
 *****************************************************************/

type UpdateStateCertificationActionStatusType =
  | 'updateStateCertification/loading'
  | 'updateStateCertification/succeeded'
  | 'updateStateCertification/failed'

export type UpdateStateCertificationActionPayloadType = {
  idCertification: string
  isDraftCertification: boolean
  isOnlineCertification: boolean
  isOfflineCertification: boolean
}

export const updateStateCertificationAction =
  (
    set: SetState<
      Partial<CertificationSliceType & StoreGlobalType>,
      UpdateStateCertificationActionStatusType,
      UpdateStateCertificationActionPayloadType
    >,
    get: () => StoreGlobalType & CertificationSliceType,
  ) =>
  async (payload: UpdateStateCertificationActionPayloadType) => {
    try {
      set(
        (state) => {
          state.updateStateCertificationAction.status = 'LOADING'
          state.error = undefined
          state.success = undefined
        },
        false,
        { type: 'updateStateCertification/loading', payload },
      )

      let certificationApi: CertificationApiType

      if (payload.isDraftCertification) {
        certificationApi = await publishCertification({
          idCertification: payload.idCertification,
        })
      } else if (payload.isOnlineCertification || payload.isOfflineCertification) {
        certificationApi = await updateOnlineState({
          idCertification: payload.idCertification,
        })
      }

      const idMe = get().me.userData.idUser
      const certification = certificationApiMapper(certificationApi, idMe)

      set(
        (state) => {
          state.updateStateCertificationAction.status = 'SUCCEEDED'

          const index = state.certifications.findIndex(
            ({ idCertification }) => idCertification === payload.idCertification,
          )
          if (index >= 0) {
            state.certifications[index] = certification
          }
        },
        false,
        { type: 'updateStateCertification/succeeded' },
      )
    } catch (e) {
      set(
        (state) => {
          state.updateStateCertificationAction.status = 'FAILED'
          state.error = getApiError(e)
        },
        false,
        { type: 'updateStateCertification/failed' },
      )
    }
  }
