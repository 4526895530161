/** @jsxImportSource @emotion/react */

import { useOutletContext } from 'react-router'
import { CreateSegmentCertification } from '@views/shared/certification'
import { Card } from 'common-components'
import { CertificationType } from '@pages/types'

const CreateSegmentCertificationPage = () => {
  const { certification } = useOutletContext<{ certification: CertificationType }>()

  return (
    <Card style={{ width: '100%', padding: 20, marginBottom: 20 }}>
      <CreateSegmentCertification certification={certification} />
    </Card>
  )
}

export default CreateSegmentCertificationPage
