import { Text } from 'common-components'
import { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { secondsToHumanReadable } from '@utils'

type CountdownPropsType = {
  date: string
  onTimeEnd: () => void
}

const Countdown = ({ date, onTimeEnd }: CountdownPropsType) => {
  const [secondsLeft, setSecondsLeft] = useState(dayjs(date).diff(new Date(), 'seconds'))

  useEffect(() => {
    const interval = setInterval(() => setSecondsLeft(secondsLeft - 1), 1000)
    if (!secondsLeft || secondsLeft < 0) onTimeEnd()
    return () => clearInterval(interval)
  }, [secondsLeft])

  return (
    <Text style={{ font: 'inherit', color: 'inherit' }}>{secondsToHumanReadable(secondsLeft)}</Text>
  )
}

export default Countdown
